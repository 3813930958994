<div class="allPages">
    <div class="pacagesNew">
        <div class="home-page">



            <div class="stayBox">
                <div class="container">
                    <div class="contents">


                        <div class=" destination-search-below justify-content-center">
                            <div class="tab-content bottomTab-Contents" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-Package" role="tabpanel"
                                    aria-labelledby="pills-Package-tab">
                                    <div class="visaa-content-below">
                                        <!-- <h5 class="Vbook-domestic">We make visas easy for you</h5> -->
                                        <div class="row justify-content-center g-0">
                                            <div class="col-md-4 margBtm  p-0">
                                                <div class="dropdown form-group Vform-boxes Vleft-borders">
                                                    <div (click)="setFocus('1')" class="dropdown-toggle Vd-toggle"
                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING
                                                            TO</label>
                                                        <h1 class="Vsearch-cities">
                                                            <img class="Vsearch-iccon"
                                                                src="./../../../assets/images/m/searrch.svg"
                                                                alt="" />{{visaOne}}
                                                        </h1>
                                                    </div>
                                                    <ul class="dropdown-menu VdDown-lists"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a class="dropdown-item Vd-item Vsearchbox-top"><img
                                                                    class="Vsrch-iccons"
                                                                    src="./../../../assets/images/m/searrch.svg"
                                                                    alt="" /><input #inputFocusVisaOne
                                                                    [(ngModel)]="searchText1" placeholder="Search here"
                                                                    class="Vsrch-box" type="text" /></a>
                                                        </li>
                                                        <li>
                                                            <ul class="Vciti-namess list-unstyled m-0">
                                                                <li *ngFor="let item of countryList | filter: searchText1"
                                                                    (click)="selectOneVisa(item)">
                                                                    <a class="dropdown-item Vd-item">{{item.name |
                                                                        slice:0:20}}</a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-3 margBtm p-0">
                                                <div class="dropdown form-group Vform-boxes">
                                                    <div (click)="setFocus('2')" class="dropdown-toggle Vd-toggle"
                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <label for="djfjsf" class="d-block Vcity-label">I AM A CITIZEN
                                                            OF</label>
                                                        <h1 #inputFocusVisaTwoDiv class="Vsearch-cities">
                                                            <img class="Vsearch-iccon"
                                                                src="./../../../assets/images/m/searrch.svg" alt="" />
                                                            {{ visaTwo.length <= 12 ? visaTwo : (visaTwo | slice:0:14)
                                                                + '...' }} </h1>
                                                    </div>
                                                    <ul class="dropdown-menu VdDown-lists"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a class="dropdown-item Vd-item Vsearchbox-top"><img
                                                                    class="Vsrch-iccons"
                                                                    src="./../../../assets/images/m/searrch.svg"
                                                                    alt="" /><input class="Vsrch-box"
                                                                    [(ngModel)]="searchText2" #inputFocusVisaTwo
                                                                    placeholder="Search here" type="text" /></a>
                                                        </li>
                                                        <li>
                                                            <ul class="Vciti-namess list-unstyled m-0">
                                                                <li *ngFor="let item of countryList | filter: searchText2"
                                                                    (click)="selectTwoVisa(item)">
                                                                    <a class="dropdown-item Vd-item">{{item.name |
                                                                        slice:0:20}}</a>
                                                                </li>

                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-5 margBtm  p-0">
                                                <div class="dropdown form-group Vform-boxes Vright-borders">
                                                    <div (click)="setFocus('3')" class="dropdown-toggle Vd-toggle"
                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING
                                                            FROM</label>
                                                        <h1 #inputFocusVisaThreeDiv class="Vsearch-cities">
                                                            <img class="Vsearch-iccon"
                                                                src="./../../../assets/images/m/searrch.svg" alt="" />{{
                                                            visaThree.length <= 14 ? visaThree : (visaThree |
                                                                slice:0:14) + '...' }} </h1>

                                                    </div>
                                                    <button (click)="submitVisaFor()"
                                                        class="VsrchBTN-inside d-none d-md-block">
                                                        Search
                                                    </button>
                                                    <ul class="dropdown-menu VdDown-lists"
                                                        aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <a class="dropdown-item Vd-item Vsearchbox-top"><img
                                                                    class="Vsrch-iccons"
                                                                    src="./../../../assets/images/m/searrch.svg"
                                                                    alt="" /><input #inputFocusVisaThree
                                                                    [(ngModel)]="searchText3" class="Vsrch-box"
                                                                    placeholder="Search here" type="text" /></a>
                                                        </li>
                                                        <li>
                                                            <ul class="Vciti-namess list-unstyled m-0">
                                                                <li *ngFor="let item of countryList | filter: searchText3"
                                                                    (click)="selectThreeVisa(item)">
                                                                    <a class="dropdown-item Vd-item">{{item.name |
                                                                        slice:0:20}}</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div (click)="submitVisaFor()"
                                            class="Vbtn-beloww d-flex justify-content-center d-md-none">
                                            <button class="VsrchBTN-outside">Search</button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="popularCards">

                <div class="container">
                    <h1 class="popularDestinations">Popular Destinations</h1>
                    <div class="row">
                        <div *ngFor="let item of visaList" class="col-md-3">
                            <div class="mainCard">
                                <div class="imgss">
                                    <img [src]="item.thumbnailImage" alt="">

                                </div>
                                <div class="locationDetals">
                                    <p class="location"><i class="fas fa-map-marker-alt iconsLocatin"></i> <span
                                            class="countryName">{{item.countryName}}</span></p>
                                </div>
                                <div class="processing">
                                    <p>Processing Time</p>
                                </div>
                                <ul class="proceesTime">
                                    <li>
                                        <p class="dayTIme">{{item?.processDaysStart}} to {{item?.processDaysEnd}}
                                            Bussiness Days</p>
                                    </li>
                                    <li>
                                        <p class="price"> {{item.visaInfo[0]?.price  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}  {{selectedCurrency}}</p>
                                    </li>
                                </ul>

                                <button (click)="getMoreDetails(item)" class="exPloreBtn"> Explore </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>