import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { inquiry } from 'src/app/models/model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.css']
})
export class OfferPreviewComponent implements OnInit {

  @ViewChild('openCalnder') openCalnder!: ElementRef;
  @ViewChild('CloseModels') CloseModels!: ElementRef;

  currentIndex = 0;
  totalMembets = 1;
  priceUpdate: any
  travelDate: any
  totalmembers: any
  offerDetails: any = []
  offerId: string
  images: HTMLCollectionOf<Element>;
  mainImg: HTMLImageElement;
  mainImg2: any
  inquiryform: FormGroup;
  inquiryObj: inquiry = new inquiry();
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  namePattern = '^[A-Za-z]+$';
  callingCode: string = '971';
  allMobileFlags: any = []
  enterMobile: string
  searchText: string
  countryIsSelected: boolean = false
  selectdImg: string = ''
  search: FormGroup;

  data = [
    // {id:1,img:'./../../../assets/images/offerPreview/card1-image.png'},
    // {id:2,img:'./../../../assets/images/offerPreview/card2-image.png'},
    // {id:3,img:'./../../../assets/images/offerPreview/card3-image.png'},
    // {id:4,img:'./../../../assets/images/offerPreview/card4-image.png'}
  ]
  classCount = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
    { id: '5', name: '5' },
    { id: '6', name: '6' },

  ];
  BsDatepickerConfig: Partial<BsDatepickerConfig>
  constructor(private serive: AuthService, private route: ActivatedRoute, private service: AuthService, private toastr: ToastrService) {
    this.BsDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-green',
      showWeekNumbers: false,
      minDate: new Date(),
      dateInputFormat: 'DD/MM/YYYY'
    });
    this.inquiryform = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      name: new FormControl('', [Validators.required, Validators.pattern(this.namePattern)],),
      mobileNo: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      countryCode: new FormControl('971'),

    });
  }

  ngOnInit() {
    this.route.params.subscribe(
      (res: any) => {

        this.offerId = res.id;
        this.getOfferById()
      }
    )
    window.scrollTo(0, 0);
    this.search = new FormGroup({

      searching: new FormControl('971'),

    });

    // this.contactus.value.countryCode = ''
    this.getCountryFlags()

  }
  showImage(element: HTMLImageElement, index: number) {
    // this.currentIndex = index;
    // this.mainImg.src = element.src;
    this.mainImg2 = element
  }

  previousImage() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.mainImg.src = (this.images[this.currentIndex] as HTMLImageElement).src;
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.mainImg.src = (this.images[this.currentIndex] as HTMLImageElement).src;
  }

  getmultiImages() {
    this.serive.getmultiImages(this.offerId).subscribe(
      (data: any) => {

      }
    )
  }

  loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  getOfferById() {
    this.serive.getOfferById(this.offerId).subscribe(
      (data: any) => {

        this.offerDetails = []
        this.data = []
        this.offerDetails = data
        this.priceUpdate = data.price

        this.data.push(data.image)
        this.images = document.getElementsByClassName("thumbnail") as HTMLCollectionOf<Element>;
        this.mainImg = document.getElementById("mainImg") as HTMLImageElement;
        this.mainImg2 = this.data[0]
      }
    )
  }
  createSelectedOffer() {
    if (this.totalmembers === '0') {
      return this.serive.error('Select Member Count')
    }
    let Access = this.serive.isLoggedin()


    if (Access === true) {
      let info = {
        _id: this.offerId,
        totalMembers: this.totalMembets,
        selectedDate: this.travelDate,
        offerPrice: this.offerDetails?.price,
        totalPrice: this.priceUpdate
      }
      this.serive.createSelectedOffer(info).subscribe(
        (data: any) => {
          this.serive.success(data.message)

        },
        (err) => {
          this.serive.error(err.error.message)
        }
      )
    }
    else {
      this.serive.error('Please Login')
    }

  }

  selectMember(e: any) {
    this.totalmembers = parseInt(e)

  }

  openCalnders() {
    this.openCalnder.nativeElement.click();
  }

  increseMembers() {
    if (this.totalMembets !== 8) {
      this.totalMembets++
      let info = this.offerDetails?.price * this.totalMembets
      this.priceUpdate = info
    }
  }

  decreseMembers() {
    if (this.totalMembets !== 1) {
      this.totalMembets--
      let info = this.offerDetails?.price * this.totalMembets
      this.priceUpdate = info
    }

  }


  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {

        // this.allMobileFlags =[]
        let allData =[]
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }

  getCountryID(data) {

    this.callingCode = data.callingCode
    this.selectdImg = data.flag
    this.countryIsSelected = true
  }
  inquiryForm() {
    this.inquiryObj.mobileNo = this.callingCode + '-' + this.enterMobile
    this.inquiryObj.country = this.callingCode
    this.service.inquiry(this.inquiryObj).subscribe(
      (data: any) => {
        this.toastr.success(data.message)
        this.inquiryform.reset()
        this.CloseModels.nativeElement.click()
        // this.openDialog()
        // setTimeout(() => {
        //   this.closeDialog()
        //   this.router.navigateByUrl('/home')
        // }, 10000)
      },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )
  }
}
