<div class="cars-cpmponent">


    <div class="carsBox">
        <div class="container">
            <div class="contents">
                <div class="row myrow justify-content-between">
                    <h1 class="find">Car rentals for any kind of trip</h1>
                    <p class="paras">Great deals at great prices, from the biggest car rental companies</p>
                    <div class="col-xl-3 col-md-12">
                        <div class="input-group mb-3">
                            <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                    src="./../../../assets/images/cars/car.png" alt=""></span>
                            <input type="text" class="form-control box-data" placeholder="Pick-up location"
                                aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-group mb-3">
                            <mat-form-field appearance="fill" class="test">
                                <input matInput [matDatepicker]="picker" placeholder="Pick-up date"
                                    matIconSuffix="picker" >
                                <mat-datepicker-toggle matIconSuffix [for]="picker">
                                    <mat-icon matDatepickerToggleIcon><img class="stay-icons5 calenderIcon"
                                            src="./../../../assets/images/stay/cal.png"
                                            alt=""></mat-icon></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>


                            <!-- <input type="date" class="form-control box-data date-input"  type="text" name="form" placeholder="Check In"> -->
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-group mb-3">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" >
                                    <!-- <img class="stay-icons"
                                        src="./../../../assets/images/cars/time.png"  alt=""> -->
                                    </span>
                                <input type="time" class="form-control box-data" id="timepicker" placeholder="Pick-up time"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                            <!-- <input type="date" class="form-control box-data date-input"  type="text" name="form" placeholder="Check In"> -->
                        </div>
                    </div>

                    <div class="col-xl-2">
                        <div class="input-group mb-3">
                            <mat-form-field appearance="fill" class="test">
                                <input matInput [matDatepicker]="picker22" placeholder="Drop date"
                                    matIconSuffix="picker">
                                <mat-datepicker-toggle matIconSuffix [for]="picker22">
                                    <mat-icon matDatepickerToggleIcon><img class="stay-icons5 calenderIcon"
                                            src="./../../../assets/images/stay/cal.png"
                                            alt=""></mat-icon></mat-datepicker-toggle>
                                <mat-datepicker #picker22></mat-datepicker>
                            </mat-form-field>


                            <!-- <input type="date" class="form-control box-data date-input"  type="text" name="form" placeholder="Check In"> -->
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <div class="input-group mb-3">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" >
                                    <!-- <img class="stay-icons"
                                        src="./../../../assets/images/cars/time.png"  alt=""> -->
                                    </span>
                                <input type="time" class="form-control box-data" id="timepicker" placeholder="Drop time"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                            <!-- <input type="date" class="form-control box-data date-input"  type="text" name="form" placeholder="Check In"> -->
                        </div>
                    </div>
              
             
                </div>
                <button class="searchBtn">Search</button>
            </div>
        </div>
    </div>

     <!-- Carousel Start -->
     <div class="container">
        <!-- Offer Cards Starts -->
        <div class="offers-cards">
            <p class="offers-cards-title">Offers</p>
            <div class="inside-offers-cards">
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-1.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-2.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-3.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-4.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Offer Cards End -->

        <div class="popular-locations">
            <p class="popular-locations-title">Popular Location</p>
            <p class="popular-locations-content">These popular destinations have a lot to offer</p>
            <div class="owl-carousel owl-popular-locations2 owl-theme">

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/japan.png" alt="">
                        <p class="popular-location-img-title">Japan</p>
                     
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/south-korea.png" alt="">
                        <p class="popular-location-img-title">South Korea</p>
                 
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/tokyo.png" alt="">
                        <p class="popular-location-img-title">Tokyo</p>
                  
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/australia.png" alt="">
                        <p class="popular-location-img-title">Australia</p>
               
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/bali.png" alt="">
                        <p class="popular-location-img-title">Bali</p>
                
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/india.png" alt="">
                        <p class="popular-location-img-title">India</p>
                     
                    </div>
                </div>

            </div>
        </div>


    </div>

</div>
<!-- Carousel End -->