<div class="visaBookingVIew">




    <div class="visa-FormData">
        <div class="container">
            <h1 class="visa-application">Visa Application Form</h1>
          
            <div class="row">
                <div class="col-lg-8">
                    <div  class="leftform-data">
                        <div class="row">
                            <div class="col-md-4">
                                <select (change)="changeGuestData($event)" class="form-select selectGuest form-select-lg mb-3" aria-label=".form-select-lg example">
                                    <!-- <option selected>Change guest</option> -->
                                    <option *ngFor="let item of guests" [value]="item?._id">{{item?.visaUserDetails?.personalDetails?.firstname}}</option>
                               
                                  </select>
                            </div>
                        </div>
                        <h1 class="general-details">General Details</h1>
                        <div class="row">
                            <div class="col-lg-11">
                                <div class="row g-2 g-md-3">
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Current
                                            address</label>
                                        <p>{{visaUserDetails?.generalDetails?.currentAddress}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">City</label>
                                        <p>{{visaUserDetails?.generalDetails?.city}}</p>
                                    </div>
                                    <div class="col-md-4">

                                        <label for="exampleInputEmail1"
                                            class="form-label titleTxt">State/province</label>
                                        <p>{{visaUserDetails?.generalDetails?.state}}</p>
                                    </div>
                                    <div class="col-md-4">

                                        <label for="exampleInputEmail1" class="form-label titleTxt">Zipcode /Pincode
                                            /Postal code</label>
                                        <p>{{visaUserDetails?.generalDetails?.pincode}}</p>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Contact
                                            number</label>
                                        <p>{{visaUserDetails?.generalDetails?.mobileCountryCode}} {{visaUserDetails?.generalDetails?.contactNumber}}</p>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Travel Date</label>
                                        <p>{{visaUserDetails?.generalDetails?.travelDate}}/{{visaUserDetails?.generalDetails?.travelMonth}}/{{visaUserDetails?.generalDetails?.travelYear}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Email
                                            address</label>
                                        <p>{{visaUserDetails?.generalDetails?.emailId}}</p>
                                    </div>
                               
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">First name</label>
                                        <p>{{visaUserDetails?.personalDetails?.firstname}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Last name</label>
                                        <p>{{visaUserDetails?.personalDetails?.lastname }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Nationality</label>
                                        <p>{{visaUserDetails?.personalDetails?.nationality }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Date of
                                            birth</label>
                                        <p>{{visaUserDetails?.personalDetails?.birthDay }}/{{visaUserDetails?.personalDetails?.birthMonth }}/{{visaUserDetails?.personalDetails?.birthYear }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Gender</label>
                                        <p>{{visaUserDetails?.personalDetails?.gender }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Passport No</label>
                                        <p>{{visaUserDetails?.personalDetails?.passportNumber }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Passport Expiry
                                            date</label>
                                        <p>{{visaUserDetails?.personalDetails?.passportExpDate }}/{{visaUserDetails?.personalDetails?.passportExpMonth }}/{{visaUserDetails?.personalDetails?.passportExpYear }}</p>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Scanned Passport
                                            Copy</label>
                                        <button (click)="downloadPassport(item.personalDetails.scannedPassportCopy)" class="downloadBtn">Download</button>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">
                                            Scanned pass photo Copy</label>
                                        <button (click)="downloadPassport(item.personalDetails.scannedphoto)" class="downloadBtn">Download</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="LinesData">
                        <p class="titleTxt" style="text-align: center;">Visa status</p>    
                         <p class="titleTxt">Application date {{visaDetails.createdDate | date:'dd/MM/yyyy'}}</p>
                          <p class="titleTxt">Last update {{visaDetails.lastUpdated | date:'dd/MM/yyyy'}}</p>
                          <p class="titleTxt">Transaction Id : {{visaDetails.refId}}</p>

                        <!-- <div  *ngIf="visaDetails.status === 0" class="StatusBtns">
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Rejected</button>
                         
                        </div> -->


                        <div  *ngIf="visaDetails.status === 0" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Rejected"><i class="fas fa-times-circle tick2"></i></div>
                          </div>






                        <div  *ngIf="visaDetails.status === 1" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Payment Done"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="step active" data-desc="Payment Not Done"><i class="fas fa-circle nptDone"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  " data-desc="Documents Verification"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  " data-desc="Sent to Embassy"><i class="fas fa-circle"></i></div>
                            <div  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'"class="step " data-desc="Completed"><i class="fas fa-circle"></i></div>
                          </div>
                          <div  *ngIf="visaDetails.status === 2" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Payment Done"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="step active" data-desc="Payment Not Done"><i class="fas fa-circle nptDone"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  active" data-desc="Documents Verification"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  " data-desc="Sent to Embassy"><i class="fas fa-circle"></i></div>
                            <div  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'"class="step " data-desc="Completed"><i class="fas fa-circle"></i></div>
                          </div>
                          <div  *ngIf="visaDetails.status ===3" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Payment Done"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="step active" data-desc="Payment Not Done"><i class="fas fa-circle nptDone"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  active" data-desc="Documents Verification"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active " data-desc="Sent to Embassy"><i class="fas fa-circle"></i></div>
                            <div  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'"class="step " data-desc="Completed"><i class="fas fa-circle"></i></div>
                          </div>
                          <div  *ngIf="visaDetails.status === 4" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Payment Done"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="step active" data-desc="Payment Not Done"><i class="fas fa-circle nptDone"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  active" data-desc="Documents Verification"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active " data-desc="Sent to Embassy"><i class="fas fa-circle"></i></div>
                            <div  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'"class="step active" data-desc="Completed"><i class="fas fa-circle"></i></div>
                            <button (click)="downloadVisa()" *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Download visa</button>

                          </div>

                          <div  *ngIf="visaDetails.status === 5" id="steps">
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Payment Done"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="step active" data-desc="Payment Not Done"><i class="fas fa-circle nptDone"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step  active" data-desc="Documents Verification"><i class="fas fa-circle"></i></div>
                            <div *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active " data-desc="Sent to Embassy"><i class="fas fa-circle"></i></div>
                            <div  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'"class="step active" data-desc="Completed"><i class="fas fa-circle"></i></div>
                            <button (click)="downloadVisa()" *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Download visa</button>

                          </div>
                        <!-- <div  *ngIf="visaDetails.status === 1" class="StatusBtns">
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Payment Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="StatBtnLeft " type="button">Payment Not Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Documents Verification</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Sent to Embassy</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Completed</button>
                        </div>
                        <div  *ngIf="visaDetails.status === 2" class="StatusBtns">
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Payment Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="StatBtnLeft " type="button">Payment Not Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Documents Verification</button>
                            <button  *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Sent to Embassy</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Completed</button>
                        </div>
                        <div  *ngIf="visaDetails.status === 3" class="StatusBtns">
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Payment Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="StatBtnLeft " type="button">Payment Not Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Documents Verification</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Sent to Embassy</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnRight" type="button">Completed</button>
                        </div>
                        <div  *ngIf="visaDetails.status === 4" class="StatusBtns">
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Payment Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'" class="StatBtnLeft " type="button">Payment Not Done</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Documents Verification</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Sent to Embassy</button>
                            <button *ngIf="visaDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Completed</button>
                        </div> -->
                        <div class="DottLins"></div>
                        <p *ngIf="visaDetails.remark" class="titleTxt">Reason : {{visaDetails.remark}}</p>

                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="right-inquery">
                        <div class="travel-box-below">
                            <h3 class="traveling-boxTitle">Travelling to</h3>
                            <p class="travel-place">{{visaDetails?.destinationCountry}}</p>
                            <h3 class="traveling-boxTitle">Travelling From</h3>
                            <p class="travel-place">{{visaDetails?.sourceCountry}}</p>
                            <h3 class="traveling-boxTitle">Applied Visa</h3>
                            <p class="travel-place">{{visaDetails?.appledFor}}</p>
                            <h3 (click)="getInvoiceForVIsa()" class="traveling-boxTitle donwilo">Email Invoice</h3>
                            <h3 (click)="getDownloadInvoiceForVIsa()" class="traveling-boxTitle donwilo">Download Invoice</h3>
                     
                        </div>
                        <div class="fare-box-below">
                            <h4 class="fare-sumary">Fare Summary</h4>
                         
                            <ul  class="fare-list-top">
                                <li class="fare-lists">
                                    <span class="plus-minusIcons"></span>Base fare
                                </li>
                                <li class="fare-lists">{{visaDetails?.baseFare   | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                            </ul>
                            <ul *ngFor="let price of visaDetails.additionalChargesIds" class="fare-list-top">
                                <li class="fare-lists">
                                    <span class="plus-minusIcons"></span>{{price.name}}
                                </li>
                                <li class="fare-lists">{{price.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                            </ul>
                            <ul class="fare-list-top amount-top">
                                <li class="total-amount">
                                    Total Amount
                                </li>
                                <li class="total-amount">{{visaDetails?.totalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2' }} {{selectedCurrency}}</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>







  