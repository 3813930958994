<div class="devotion-main-new">
    <div class="row">
        <div class="col-md-7">
            <div class="devotion-main-left">
                <img class="devotion-main-img" [src]="blogInfo.image" alt="nature-img">
                <p class="devotion-left-title">{{blogInfo.title}}</p>
                <p class="devotion-left-content">{{blogInfo.description}}</p>

                <!-- <ul class="like-share-inside">
                    <li><a class="like-share-content" ><i class="fas fa-heart like-share-icon"></i></a></li>
                    <li><a class="like-share-content" ><i class="fas fa-share-square like-share-icon"></i></a></li>
                </ul> -->
            </div>
        </div>
        <div class="col-md-5">
            <div class="devotion-main-right">
                <p class="devotion-recommended">Recommended</p>
                <ul class="recommended-inside">
                    <li *ngFor="let item of allBlogList">
                        <div (click)="getBlog(item)" style="cursor: pointer;" class="devotion-right-inside">
                            <div  class="row">
                                <div class="col-md-6">
                                    <div class="devotion-right-left">
                                        <img class="devotion-right-left-pic" [src]="item.image" alt="">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="devotion-right-right">
                                        <p class="devotion-right-title">{{item.title}}</p>
                                        <p class="devotion-right-content">{{item.date | date:'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                
                </ul>

             
            </div>
        </div>
    </div>
</div>