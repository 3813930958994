import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-travels',
  templateUrl: './corporate-travels.component.html',
  styleUrls: ['./corporate-travels.component.css']
})
export class CorporateTravelsComponent implements OnInit{
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
