import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  modalRef?: BsModalRef;
  loginOtp: FormGroup;
  
  requestForOtp:boolean =false
  constructor(private service:AuthService,private toastr: ToastrService,private router:Router,private modalService: BsModalService) { }

  ngOnInit(): void {

    this.loginform = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
    })
    this.loginOtp = new FormGroup({
      otp: new FormControl('', [Validators.required]),
    })
  }
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getDate();

      // Highlight the 1st and 20th day of each month.
      return date === 1 || date === 20 ? 'example-custom-date-class' : '';
    }

    return '';
  };
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  onSubmit() {
   
   let loginObj ={
    mobile :this.loginform.value.mobile.number,
    countryCode:this.loginform.value.mobile.dialCode
   }
    this.service.login(loginObj).subscribe(
      (data:any)=>{
        // this.toastr.success(data.message)
        this.toastr.success(data.message)
        this.requestForOtp = true
    
        // this.router.navigate(['/login']);

      },
      (err)=>{
         
        this.toastr.error(err.error.message)
      }
    )
    
  }
  submit(){
    
    let data ={
      mobile :this.loginform.value.mobile.number,
      countryCode:this.loginform.value.mobile.dialCode,
      otp:this.loginOtp.controls.otp.value
    }
    this.service.validateLoginUser(data).subscribe(
      (data:any)=>{
        // this.toastr.success(data.message)
        this.toastr.success(data.message)
        localStorage.setItem('token',data.token)
        
        this.router.navigateByUrl('/home')
        
        // window.location.reload()
        // this.router.navigate(['/login']);
        this.service.editUser(1);
      },
      (err)=>{
         
        this.toastr.error(err.error.message)
      }
    )
  }

 

  
}
