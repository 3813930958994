<div class="blog-data-update">
    <div class="section1-data">
      <div class="blog-content">
        <h3 class="blog-title">Blog</h3>
        <div class="edit-subscription">
          <input
            class="email-Edit" (keyup)="searchText($event)" type="email"placeholder="type something....."
          />
          <button (click)="searchSubmit()" class="search-edit" type="submit">
            <i class="fas fa-search edit-searchICN"></i>Search
          </button>
          <!-- <button class="search-edit" type="submit" , value="Su" /> -->
        </div>
      </div>
    </div>
    <div class="cards-Section">
      <div class="container">
        <div class="edit-card">
          <div class="row">
            <div *ngFor="let item of allBlogList | filter:searchData "  class="col-md-4">
              <div (click)="getBlog(item)"  class="card card-inside">
                <img 
                style="border-radius: 10px;"
                  [src]="item.image"
                  class="card-img-top cardImg-edit"
                  alt="..."
                />
                <div class="card-body cardBdy-edit">
                  <p class="inside-text">
                  {{item.title}}
                  </p>
                  <p class="date-edit">   {{item.date | date:'dd/MM/yyyy'}}</p>

                  <p>   {{item.description | slice : 0:130}}..</p>
                </div>
              </div>
            </div>
        
           
          </div>
        </div>
      </div>
    </div>
  </div>