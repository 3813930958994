import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Itinerary, Passenger, bookTicket } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent {
  ticketDetails :any =[]
  mealSelected =[]
  mealsDetails  =[
    {id:1,name:'chicken',price:200},
    {id:2,name:'veg',price:100},
    {id:3,name:'fish',price:300},
    {id:4,name:'pappu',price:200},
  ]
  myTitle:string
  dynamicForm:FormGroup
  bookTicketObj = new bookTicket()
  PassengerObj = new Passenger()
  ItineraryObj = new Itinerary()
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe,private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.dynamicForm = this.formBuilder.group({
      formFields: this.formBuilder.array([]) // Use a FormArray to dynamically add/remove form controls
    });
    this.addFormField()
    this.route.paramMap.subscribe((data: any) => {
       
      this.ticketDetails = JSON.parse(data.params.data)
      this.bookTicketObj.IPAddress = this.ticketDetails[1].ipaddress
      this.bookTicketObj.TokenId =this.ticketDetails[1].TokenId
      this.bookTicketObj.TrackingId =this.ticketDetails[1].TrackingId
      this.bookTicketObj.EndUserBrowserAgent =this.ticketDetails[1].EndUserBrowserAgent
      this.bookTicketObj.PointOfSale =this.ticketDetails[1].PointOfSale
      this.bookTicketObj.RequestOrigin =this.ticketDetails[1].RequestOrigin
      this.bookTicketObj.UserData =this.ticketDetails[1].UserData
      this.bookTicketObj.ResultId =this.ticketDetails[1].ResultId
      this.bookTicketObj.PNR =''

      const segments =[]; segments.push(this.ticketDetails[0].Segments)
      const FareRules =[]; FareRules.push(this.ticketDetails[0].FareRules[0])
       
    
  
       const datas = [
       {segments :segments}
       ]

    })


  

  }

  get formFields(): FormArray {
  
    return this.dynamicForm.get('formFields') as FormArray;
  }

  addFormField() {
    let formField = this.formBuilder.group({
      Type: [],
      Title: [''],
      FirstName: [''],
      LastName: [''],
      Mobile1: [''],
      Mobile1CountryCode: [''],
      DateOfBirth: [''],
      PassportNo: [''],
      CountryCode: [''],
      CountryName: [''],
      AddressLine1: [''],
      Gender: [''],
      Email: [''],
      IdType:[],
      IdNumber:[''],
      IssuedCountryCode:[],
      IssueDate:[''],
      ExpiryDate:[''],
      PassportExpiry:[''],
      Meal:[''],
      Fare:[''],
      FareBreakdown:['']

    });

    this.formFields.push(formField);

  }

  removeFormField(index: number) {
    this.formFields.removeAt(index);
  }

  check(){  
     
    this.bookTicketObj.Itinerary ={
      Segments:  this.ticketDetails[0].Segments[0],
      FareRules:this.ticketDetails[0].FareRules,
 
      Passenger:this.dynamicForm.value.formFields,
      Destination	:this.ticketDetails[0].Destination,
      Origin	:this.ticketDetails[0].Origin,
      ValidatingAirlineCode	:	this.ticketDetails[0].ValidatingAirline,
      NonRefundable	:	this.ticketDetails[0].NonRefundable,
      IsLcc	:	this.ticketDetails[0].IsLcc,
      AirlineRemark	:this.ticketDetails[0].AirlineRemark,
      TripIndicator	:this.ticketDetails[0].TripIndicator,
      ResponseTime	:this.ticketDetails[0].ResponseTime,
      JourneyType	:this.ticketDetails[0].JourneyType
    }
 
    this.service.Booking_Ticket(this.bookTicketObj).subscribe(
      (data:any)=>{
   
        for(let i =0; i<data.data.Errors.length; i++){
          if(data.data.Errors[i]){
            this.service.error(data.data.Errors[i].UserMessage)
          }
        }

      }
    )
  }
  addMeal(data){
    this.mealSelected = data
  }
 
  submit(){
    for (let i = 0; i < this.dynamicForm.value.formFields.length; i++) {
      let users1 = Object.assign([
        {IdType :this.dynamicForm.value.formFields[i].IdType,
        IdNumber :this.dynamicForm.value.formFields[i].IdNumber,
        IssuedCountryCode :this.dynamicForm.value.formFields[i].IssuedCountryCode,
        IssueDate :this.dynamicForm.value.formFields[i].IssueDate,
        ExpiryDate :this.dynamicForm.value.formFields[i].ExpiryDate},
      ])
      let Nationality = Object.assign(
        {CountryCode :this.dynamicForm.value.formFields[i].CountryCode,
        CountryName :this.dynamicForm.value.formFields[i].CountryName},
      )
      let Meal = Object.assign([
        {Meal :this.mealSelected},
      ])
      
      this.dynamicForm.value.formFields[i].IdDetails = users1
      this.dynamicForm.value.formFields[i].Nationality = Nationality
      this.dynamicForm.value.formFields[i].Country = Nationality
      this.dynamicForm.value.formFields[i].City = Nationality
      this.dynamicForm.value.formFields[i].Meal = Meal
      this.dynamicForm.value.formFields[i].Fare = this.ticketDetails[0].Fare
      this.dynamicForm.value.formFields[i].FareBreakdown = this.ticketDetails[0].FareBreakdown
        // this.dynamicForm.value.formFields
 

   
     
      // this.bookTicketObj.Itinerary ={
      //   Passenger:[this.dynamicForm.value.formFields]
      // }
    }

   
  }

  
  selectTitle(e:any){

  this.myTitle = e.target.value
  }
}
