import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stays',
  templateUrl: './stays.component.html',
  styleUrls: ['./stays.component.css']
})
export class StaysComponent implements OnInit{
  cars = [
    { id: 'HYD', name: 'Hyderabad' },
    { id: 'MAA', name: 'Chennai' },
    { id: 'DEL', name: 'Delhi' },
    { id: 'TIR', name: 'Tirupati' },
    { id: 'DXB', name: 'Dubai' },
    { id: 'SYD', name: 'Sydney' },
    { id: 'AMD', name: 'Ahmedabad' },
    { id: 'HBH', name: 'Egypt' },
    { id: 'MCT', name: 'Muscat' },
    { id: 'BOM', name: 'Mumbai' },
    { id: 'DOH', name: 'Doha' },
    { id: 'HBX', name: 'Hubli' },
    { id: 'GOI', name: 'Goa' },
    { id: 'PNQ', name: 'Pune' },
    { id: 'BAH', name: 'Bahrain' },
    { id: 'LHR', name: 'Heathrow' },
    { id: 'ATH', name: 'Athens' },
    { id: 'RUH', name: 'Riyadh' },
    { id: 'PAT', name: 'Patna' },
    { id: 'BKK', name: 'Bangkok' },
  ];
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadScripts()
  }

  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }
}
