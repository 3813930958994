import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { packagesEnquiry, selectedVIsa } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-global-visalist',
  templateUrl: './global-visalist.component.html',
  styleUrls: ['./global-visalist.component.css']
})
export class GlobalVisalistComponent {
  @ViewChild('inputFocusVisaOne') inputFocusVisaOne: ElementRef;
  @ViewChild('inputFocusVisaTwo') inputFocusVisaTwo: ElementRef;
  @ViewChild('inputFocusVisaThree') inputFocusVisaThree: ElementRef;
  @ViewChild('inputFocusVisaTwoDiv') inputFocusVisaTwoDiv: ElementRef;
  @ViewChild('inputFocusVisaThreeDiv') inputFocusVisaThreeDiv: ElementRef;
  visaOne: any = 'United Arab Emirates'
  
selectedCurrency:any
  visaTwo: any = 'India'
  visaThree: any = 'India'
  searchText1 = ''
  searchText2 = ''
  searchText3 = ''
  countryList: any = []
  visaList :any = []


  constructor(private service: AuthService, private router: Router, private route: ActivatedRoute) {
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
  }

  ngOnInit(): void {
   
    window.scrollTo(0, 0);

    this.selectCountry()
    this.getVisaList()

  }


  selectOneVisa(item) {
    this.visaOne = item.name
    setTimeout(() => {
      this.inputFocusVisaTwoDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaTwo.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }

  selectTwoVisa(item) {
    this.visaTwo = item.name
    setTimeout(() => {
      this.inputFocusVisaThreeDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaThree.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  selectThreeVisa(item) {
    this.visaThree = item.name
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  submitVisaFor() {

    if (this.visaOne === this.visaThree) {
      this.service.error('please check from country and to country should not be same')
      return;
    }
    this.service.getVisaByCountry(this.visaOne).subscribe(
      (data: any) => {

        if (data.response.length === 0) {
          // this.router.navigateByUrl('/home')
          this.service.error('Visa not found')
          return
        }
        else {
          let info = {
            iamTravelling: this.visaOne,
            iamCityzenOf: this.visaTwo,
            iamTravellingFrom: this.visaThree
          }
          let share = JSON.stringify(info)
          this.router.navigateByUrl(`/visa-overview/${share}`)
        }


      }
    )

  }

  submitVisaForTargetted(data, country) {
    let info = {
      iamTravelling: data,
      iamCityzenOf: 'United Arab Emirates',
      iamTravellingFrom: country
    }
    let share = JSON.stringify(info)
    this.router.navigateByUrl(`/visa-overview/${share}`)
  }

  selectCountry() {
    this.service.CountryLists().subscribe(
      (data: any) => {
        this.countryList = data
      }
    )
  }
  setFocus(count) {
    if (count === '1') {
      setTimeout(() => {
        this.inputFocusVisaOne.nativeElement.focus()

      }, 10)
    }
    if (count === '2') {
      setTimeout(() => {
        this.inputFocusVisaTwo.nativeElement.focus()

      }, 10)
    }
    if (count === '3') {
      setTimeout(() => {
        this.inputFocusVisaThree.nativeElement.focus()

      }, 10)
    }
  }

  getVisaList(){
    this.service.getVisaList().subscribe(
      (data:any) =>{
          
        this.visaList = data.responce
      }
    )
  }

  getMoreDetails(data:any){
      
    let visaname
    let visaname2
    let visaname3
    if(data.countryName ==='United Arab Emirates'){
      visaname = data.countryName
      visaname2 = 'United States'
      visaname3 = 'United States'
    }
    else{
      visaname = data.countryName
      visaname2 = 'United Arab Emirates'
      visaname3 = 'United Arab Emirates'
    }
    console.log('data :',data)
    let info = {
      iamTravelling:visaname,
      iamCityzenOf: visaname2,
      iamTravellingFrom:visaname3
    }
    let share = JSON.stringify(info)
    this.router.navigateByUrl(`/visa-overview/${share}`)
  }


}
