<div class="viewAllOffers">
    <div  class="container">
        <h1 class="ofrInfo">Local Offers</h1>
   <!-- Local -->

        <div   class="row">
            <div *ngFor="let item of allGlobalOffers" class="col-md-3">
                <div  class="section9-details">

                    <div   class="content">
                        <a (click)="navigate(item._id)" >
                            <div class="content-overlay"></div>
                            <img class="content-image" [src]="item.image" alt="">
                            <div class="content-details fadeIn-bottom">
                                <h3 class="content-title section9hedssd">{{item.title}}</h3>
                                <p class="content-text">{{item.description | slice : 0:160}}..</p>
                            </div>
                        </a>
                    </div>



                </div>
            </div>
      
        </div>


    </div>

</div>