<div class="contactus-section">
    <!-- <div class="contactus">
        <div class="container">
            <h1 class="contactus-title">Contact Us</h1>
        </div>
    </div> -->
    <div class="container">
        <!-- <p class="needhelp">NEED HELP?</p> -->
        <!-- <h6 class="getintouch" data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000"
        data-aos-mirror="true" data-aos-once="false">Get In Touch With us</h6> -->

        <div class="row justify-content-center">
            <div class="col-md-5">
                <ng-lottie class="scrlim" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>

            </div>
            <div class="col-md-5">
                <div class="contactright-sec">
                    <h1 class="formtit formtit2">Career</h1>
                    <form [formGroup]="carrers" (ngSubmit)="contactusForm()">
                        <div class="mb-3">
                            <input formControlName="name" [(ngModel)]="careerObj.name" type="text"
                                class="form-control contactusin" id="exampleFormControlInput1" placeholder="Name">
                            <div class="error-container">
                                <div class="error-message"
                                    *ngIf="carrers.get('name')?.invalid && (carrers.get('name')?.touched || carrers.get('name')?.dirty)">

                                    <div *ngIf="carrers.get('name').errors?.required">
                                        <i class="fas fa-info-circle error-icon"></i> Name is Required
                                    </div>
                                    <div *ngIf="carrers.get('name').errors?.noNumbers">
                                        <i class="fas fa-info-circle error-icon"></i>  Name should not include numbers & special characters.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <input formControlName="email" type="email" [(ngModel)]="careerObj.email"
                                class="form-control contactusin" id="exampleInputEmail1" aria-describedby="emailHelp"
                                placeholder="Email">

                            <div class="error-message" *ngIf="carrers.get('email')?.invalid && carrers.get('email')?.hasError('required') && (carrers.get('email')?.touched ||
                                carrers.get('email')?.dirty)">
                                <i class="fas fa-info-circle error-icon"></i>
                                Email is Required
                            </div>

                            <div class="error-message" *ngIf="carrers.get('email')?.hasError('pattern')">
                                <i class="fas fa-info-circle error-icon"></i>
                                Invalid Email
                            </div>


                        </div>
                        <div class="mb-3">
                            <!-- <input formControlName="phone" type="number" [(ngModel)]="careerObj.phone"
                                class="form-control contactusin" id="exampleFormControlInput1" placeholder="Phone No.">
                                <div class="error-container">
                                    <div class="error-message" *ngIf="carrers.get('phone')?.invalid && (carrers.get('phone')?.touched ||
                                    
                                    carrers.get('phone')?.dirty)">
    
                                        <i class="fas fa-info-circle error-icon"></i>
    
                                        Mobile number is Required
    
                                    </div>
    
                                </div> -->

                            <div class="MobileNumInside">
                                <div class="CountryCodeData">
                                    <div class="dropdown DropDown">
                                        <button class="btn FormSelect dropdown-toggle" type="button"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img *ngIf="!countryIsSelected" class="FlagImg"
                                                src="./../../../../assets/images/country/aue.webp" alt="no-img">
                                            <img *ngIf="countryIsSelected" class="FlagImg"
                                                [src]="'data:image/png;base64,' + selectdImg" alt="no-img">

                                            <!-- <p class="CallID"> {{callingCode}}</p> -->
                                        </button>
                                        <ul class="dropdown-menu DropDownMenu" aria-labelledby="dropdownMenuButton1">
                                            <li class="DropDownLi">
                                                <form [formGroup]="searching">
                                                    <div class="InputSearch">
                                                        <img class="SearchImgIcon"
                                                            src="./../../../../assets/images/search.png" alt="no-data">
                                                        <input formControlName="search" [(ngModel)]="searchText"
                                                            class="InputSearchData" type="search"
                                                            placeholder="Select Country Name or Code">
                                                    </div>
                                                </form>
                                            </li>
                                            <li *ngFor="let item of allMobileFlags | filter:searchText"
                                                (click)="getCountryID(item)" class="DropDownLi"><a
                                                    class="dropdown-item DropDownItem">
                                                    <img *ngIf="item.type === 'base'" class="FlagImg"
                                                        [src]="'data:image/png;base64,' + item.flag">
                                                    <img *ngIf="item.type === 'img'" class="FlagImg" [src]="item.flag">

                                                    {{item?.name}}<span
                                                        class="FlagSpan">{{item?.callingCode}}</span></a>
                                            </li>

                                        </ul>
                                    </div>

                                    <input formControlName="phone" [(ngModel)]="enterMobile"
                                        class="s-inputsss contactusin-data" type="number" placeholder="Mobile Number">

                                </div>
                                <div class="error-container">

                                    <div class="error-message" *ngIf="carrers.get('phone')?.invalid && 
                                        (carrers.get('phone')?.touched || carrers.get('phone')?.dirty)">
                                        <i class="fas fa-info-circle error-icon"></i>
                                        Mobile number is Required
                                    </div>
                                    <div class="error-message" *ngIf="carrers.get('phone')?.hasError('pattern')">
                                        <i class="fas fa-info-circle error-icon"></i>
                                        Mobile Number should be 5 to 15 Digits
                                    </div>
                                    <div class="error-message" *ngIf="carrers.get('phone')?.value?.length > 15">
                                        <i class="fas fa-info-circle error-icon"></i>
                                        Mobile Number should be 5 to 15 Digits
                                    </div>

                                </div>






                            </div>
                        </div>
                        <div class="mb-3">
                            <input type="text" formControlName="description" [(ngModel)]="careerObj.description"
                                class="form-control contactusin" id="exampleFormControlInput1"
                                placeholder="Designation">
                            <div class="error-container">
                                <div class="error-message"
                                    *ngIf="carrers.get('description')?.invalid && (carrers.get('description')?.touched || carrers.get('description')?.dirty)">

                                    <div *ngIf="carrers.get('description').errors?.required">
                                        <i class="fas fa-info-circle error-icon"></i> Designation is Required
                                    </div>
                                    <div *ngIf="carrers.get('description').errors?.noNumbers">
                                        <i class="fas fa-info-circle error-icon"></i> Numbers & Special Characters are
                                        not allowed in the input.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">

                            <input type="number" formControlName="totalexp" [(ngModel)]="careerObj.totalexp"
                                class="form-control contactusin" id="exampleFormControlInput1"
                                placeholder="Total experience">
                            <div class="error-container">
                                <div class="error-message" *ngIf="carrers.get('totalexp')?.invalid && (carrers.get('totalexp')?.touched ||
                                    
                                    carrers.get('totalexp')?.dirty)">

                                    <i class="fas fa-info-circle error-icon"></i>

                                    Experience Required

                                </div>

                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="formFile" class="form-label">Upload resume</label>
                            <input (change)="selectFile($event)" placeholder="upload resume" formControlName="resume"
                                class="form-control contactusin" type="file">

                        </div>
                        <button type="submit"  [disabled]="!carrers.valid" class="contactsubmit">Submit now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>