import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  allBlogList :any = []
  searchData:string
  searchData2:string
  constructor(private service: AuthService , private router:Router) { }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.allBlogs()
  }

  allBlogs() {
    this.service.allBlogs().subscribe(
      (data: any) => {
        this.allBlogList = data
      }
    )
  }

  getBlog(data){
      this.router.navigate(['/blog-post', data._id])
  }

  searchText(data){
    this.searchData2= data.target.value
  }


  searchSubmit(){
    this.searchData = this.searchData2
  }

}
