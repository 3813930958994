import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DatePipe } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { fareQuote, fareRule, searchFlight, searchMultiFlight } from 'src/app/models/model';
import { MatDatepicker } from '@angular/material/datepicker';
import { getISOWeek } from 'date-fns';

import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatButtonToggle } from '@angular/material/button-toggle';
interface FlightSegment {
  AirlineName: string;
  TotalFare: number;
}
@Component({
  selector: 'app-flights',
  templateUrl: './flights.component.html',
  styleUrls: ['./flights.component.css']
})
export class FlightsComponent implements OnInit {
  searchFlightObj = new searchFlight()
  html = `<span class="btn btn-danger">Never trust not sanitized HTML!!!</span>`;
  TrackingIdOne: any
  TrackingIdTwo: any
  isReturnTravell:boolean = false
  departurDate: any
  araivalDate: any
  selectedItemIndex1: number = 0;
  selectedItemIndex2: number = 0;
  mainList = []
  FirstID: string
  totalCountPassinger: number = 1
  @ViewChild('buttonToggle1') buttonToggle1: MatButtonToggle;
  @ViewChild('buttonToggle2') buttonToggle2: MatButtonToggle;
  @ViewChild('buttonToggle3') buttonToggle3: MatButtonToggle;
  @ViewChild('buttonToggle4') buttonToggle4: MatButtonToggle;
  isPageLoaded: boolean = false
  selectOne: any = []
  selectedOrigin: any
  SendReturnData: any = []
  SendReturnData2: any = []
  selectedDeparture: any = []
  finalPrice: number
  Source: string
  isHidden: boolean = false;
  journeyTypeDivision: number
  DestinationPrint: string
  displayedItems: any[]; // Array to hold displayed items
  returnFlight: any = []
  returnFlight2: any = []
  showMore: boolean = false; // Flag to track if "Show More" button is clicked
  fareRuleObj = new fareRule()
  fareQuoteObj = new fareQuote()
  howManyDirectFlights: number = 0
  flightNames: any = []
  FliterMinFareAndMaxFare: any
  isReturn: boolean = true
  OrginData: string
  DestinationData: string
  priceRange: number = 0
  minDate = new Date()
  minPrice: number = 0
  maxPrice: number = 0
  isReturnFn: boolean = false
  isReturnValue: string;
  journeyType: number = 1
  NJourneyType: number = 1
  Origin: string = 'HYD'
  Destination: string = 'DXB'
  fromDate: any = new FormControl();
  fromDates: any = new Date()
  Adults: number = 1
  DepartureTime: string
  childs: number = 0
  Infants: number = 0
  className: string = 'All Classes'
  classNo: number = 1
  @ViewChild('opneFlight') opneFlight!: ElementRef;
  @ViewChild('closePassingerDropdown') closePassingerDropdown!: ElementRef;
  @ViewChild('Classs') Classs!: ElementRef;
  @ViewChild('returnButton', { static: false }) returnButton: ElementRef;
  @ViewChild('OneWayButton', { static: false }) OneWayButton: ElementRef;

  @ViewChild('isTwoWay') isTwoWay!: ElementRef;
  @ViewChild('closeFlight') closeFlight!: ElementRef;
  @ViewChild('input1', { static: false }) input1!: ElementRef;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  @ViewChild('picker2') datePicker2: MatDatepicker<Date>;


  isDivActive: boolean = false
  selectedCar: number;
  flightInfo: any = []
  flightInfoResult: any = []
  flightUpdate: any 
  flightUpdate2: any = []
  flightSegments: any = []
  segements: any;
  segements2: any;
  TrackingId: string
  picker: any;
  picker2: any;
  selectedCarId: string;
  selectedDate: Date;
  class: number
  segmentList: any = []
  // MultiWay start /////
  dynamicForm!: FormGroup;
  searchMultiFlightObj = new searchMultiFlight()
  SubmitButton: boolean = true
  multiClass: number
  // MultiWay end  /////


  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe,
    private config: NgSelectConfig, private renderer: Renderer2,
    private ngxService: NgxUiLoaderService, private formBuilder: FormBuilder,
    private modalService: BsModalService) {

    // this.config.notFoundText = 'City not found';
    // this.config.bindValue = 'value';
  }
  ngOnDestroy(): void {
    this.ngxService.stop()
  }

  selectWay(data: any) {

    if (data.target.value == 'Return') {
      // this.NJourneyType = 2
      this.journeyType = 2
      this.isReturnTravell = true
      this.isReturnFn = true;
      this.isReturn = false;
      return
    }
    if (data.target.value == 'One-Way') {
      // this.NJourneyType = 1
      this.journeyType = 1
      // alert(this.journeyType)
      this.isReturnTravell = false
    }
    if (data.target.value == 'Multi-City') {
      this.journeyType = 3
      this.isReturnTravell = false
      // this.NJourneyType = 1
    }
    else {
      this.isReturnFn = false;
      this.isReturn = true;
    }
  }

  cars = [
    { id: 'HYD', name: 'Hyderabad' },
    { id: 'MAA', name: 'Chennai' },
    { id: 'DEL', name: 'Delhi' },
    { id: 'TIR', name: 'Tirupati' },
    { id: 'DXB', name: 'Dubai' },
    { id: 'SYD', name: 'Sydney' },
  ];

  ngOnInit(): void {
    this.getCountry()
    // Apply the CSS styles
    const css = `
      .ng-select .ng-select-container .ng-value-container .ng-input input {
        height: 38px !important;
        padding-left: 13px !important;
        border:none !important
      }
    `;
    const style = this.renderer.createElement('style');
    style.type = 'text/css';
    style.appendChild(this.renderer.createText(css));
    this.renderer.appendChild(document.head, style);


    // $('[data-bs-toggle="popover"]').popover();
    this.fromDate.setValue(new Date());
    this.loadScripts()
    setTimeout(() => {
      this.openModal()
    }, 6)



    this.dynamicForm = this.formBuilder.group({
      formFields: this.formBuilder.array([]) // Use a FormArray to dynamically add/remove form controls
    });
    this.addFormField('')
    this.searchMultiFlightObj.FlightCabinClass = 1
    this.searchMultiFlightObj.AdultCount = 1

    for(let i=0; i < 1; i++){
      this.addFormField('')
    }
  }



  // searchTwoway(dataObj:any) {
  //   this.isPageLoaded = true
  //   localStorage.setItem('SearchInfo',JSON.stringify(dataObj) )
  //   this.service.searchTwoway(dataObj).subscribe(
  //     (res: any) => {

  //       this.ngxService.stop();
  //       this.isPageLoaded = false

  //       if (res.data.IsSuccess == false) {

  //         if (res.data.Errors[0].UserMessage !== '') {
  //           this.closeFlight.nativeElement.click()
  //           this.ngxService.stop()
  //           setTimeout(() => {
  //             this.router.navigateByUrl('/home')
  //           }, 100)

  //           this.toastr.error(res.data.Errors[0].UserMessage)

  //         }
  //         else {
  //           this.closeFlight.nativeElement.click()
  //           this.ngxService.stop()
  //           setTimeout(() => {
  //             this.router.navigateByUrl('/home')
  //           }, 100)
  //           this.toastr.error('some error occured')

  //         }

  //       }
  //       else if (res.data.Results) {
  //         this.departurDate = res.data.Results[0][0].DepartureTime

  //         window.scrollTo(0, 0);
  //         this.ngxService.stop();
  //         this.flightInfo = [];
  //         this.mainList = [];

  //         this.mainList = res.data.Results[0]
  //         this.flightInfo = res.data.Results[0]
  //         if(res.data.Results[0][0].JourneyType == 1){
  //           this.NJourneyType = 2
  //         }


  //         this.Source = res.data.Results[0][0]?.Origin

  //         this.DestinationPrint = res.data.Results[0][0].Destination?.CityName
  //         this.filterFlightName(this.flightInfo)
  //          
  //         this.TrackingIdOne = res.data.TrackingId
  //         this.journeyTypeDivision = res.data.Results[0].JourneyType;
  //         localStorage.setItem('IsDomestic', res.data.IsDomestic)





  //       }
  //     },
  //     (err) => {

  //       this.ngxService.stop()
  //       for(let i=0; i< err.error.error.length; i++ ){
  //         this.toastr.error(err.error.error[i].UserMessage)
  //       }

  //     }
  //   )
  // }


  getBase64Image(base64String: string): string {
    return 'data:image/jpeg;base64,' + base64String;
  }
  getSegmentsInfo(id) {

    let searchData = {
      details: localStorage.getItem('SearchInfo'),
      id: id.ResultId
    }
    this.service.getSegmentsInfo(searchData).subscribe(
      (data: any) => {
   
        this.segmentList = data;
      }
    )
  }



  addTimeDurations(durations) {
    let totalSeconds = 0;

    // Convert each duration to seconds and sum them up
    for (let duration of durations) {
      const [hours, minutes, seconds] = duration.split(':');
      totalSeconds += (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
    }

    // Convert the total back to hh:mm:ss format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  // Helper function to add leading zeros
  padZero(value) {
    return value.toString().padStart(2, '0');
  }



  openModal() {

    this.opneFlight?.nativeElement.click()
    // this.modalRef = this.modalService.show(modal);
  }
  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flight_load.json',
  };

  getFlightInfo(data: any) {
    this.fareRuleObj.ResultId = data.ResultId
    this.fareRuleObj.TrackingId = this.TrackingId

    // this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])

    // this.service.FareQuote(this.fareRuleObj).subscribe(
    //   (res: any) => {

    //     if (res.data.IsSuccess == false) {
    //       this.service.error(res.data.Errors[0].UserMessage)
    //     }
    //     else {
    //       if (res.data.Result[0].IsLcc == true) {
    //         alert("is LCC true -->  Ticket LCC")
    //         this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])
    //       }
    //       if (res.data.Result[0].IsLcc == false) {
    //         alert("is LCC false go to book & -> ticket method")
    //         this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])
    //       }
    //     }
    //   }
    // )
    // localStorage.setItem('browser',JSON.stringify(this.fareRuleObj))
    let Access = this.service.isLoggedin()
    // this.service.bookFlightValidations(this.fareRuleObj).subscribe(
    //   (data:any)=>{
    
    //   }
    // )
 
    if (Access === true) {
      localStorage.setItem('myWay', 'Way1')
      this.router.navigate(['/flight-review', JSON.stringify(this.fareRuleObj)])
    }
    else {
      this.service.error('Please Login')
    }


  }

  mainDiv() {
    this.isDivActive = !this.isDivActive
  }

  loadScripts() {
    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js',
      '../../assets/js/script.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  selectNext(nextInput: ElementRef): void {
    nextInput.nativeElement.focus();
  }
  openSelectBox() {
    this.selectBox.open();
  }

  dataSelect(e: any) {
    this.openSelectBox()
  }

  dataSelect2(e: any) {
    this.datePicker.open();
  }

  returnCallender() {
    this.datePicker2.open();

  }

  closePasingerCOunt() {
    this.closePassingerDropdown?.nativeElement.click();
  }

  chooseReturn() {
    if (this.isReturnValue) {

    }

    else {
      if (this.isReturnFn == false) {
        if (confirm('are you choose return flight..?')) {
          this.isReturn = false;
          this.returnButton.nativeElement.click();


        }
        else {
          return
        }
      }

    }


  }
  adultsIncrese() {
    if (this.Adults < 10) {
      this.Adults++
    }
    else {

    }
  }
  adultsDescrese() {
    if (this.Adults <= 1) {
      // this.Adults --
    }
    else {
      this.Adults--
    }

  }

  selectAdults(adults) {
    this.Adults = adults
    this.totalCountPassingers()
  }
  selectChilds(childs) {
    this.childs = childs
    this.totalCountPassingers()
  }
  selectInfants(infants) {
    this.Infants = infants
    this.totalCountPassingers()
  }
  selectClass(number: any) {

    if (number == 1) {
      this.className = 'All Classes'
      this.classNo = number
    }
    if (number == 2) {
      this.className = 'Economy'
      this.classNo = number
    }
    if (number == 3) {
      this.className = 'PremiumE.'
      this.classNo = number
    }
    if (number == 4) {
      this.className = 'Business'
      this.classNo = number
    }
    if (number == 5) {
      this.className = 'PremiumBus'
      this.classNo = number
    }
    if (number == 6) {

      this.className = 'First'
      this.classNo = number
    }

  }



  selectAdults2(adults) {
    this.Adults = adults
    this.searchMultiFlightObj.AdultCount = adults
    this.totalCountPassingers()
  }
  selectChilds2(childs) {
    this.childs = childs
    this.searchMultiFlightObj.ChildCount = childs
    this.totalCountPassingers()
  }
  selectInfants2(infants) {
    this.Infants = infants
    this.searchMultiFlightObj.InfantCount = infants
    this.totalCountPassingers()
  }
  selectClass2(number: any) {

    if (number == 1) {
      this.className = 'All Classes'
      this.classNo = number
      this.class = number

    }
    if (number == 2) {
      this.className = 'Economy'
      this.classNo = number
      this.class = number
    }
    if (number == 3) {
      this.className = 'PremiumE.'
      this.classNo = number
      this.class = number
    }
    if (number == 4) {
      this.className = 'Business'
      this.classNo = number
      this.class = number
    }
    if (number == 5) {
      this.className = 'PremiumBus'
      this.classNo = number
      this.class = number
    }
    if (number == 6) {

      this.className = 'First'
      this.classNo = number
      this.class = number
    }
    this.searchMultiFlightObj.FlightCabinClass = this.classNo
  }



  totalCountPassingers() {
    let Adults = this.Adults
    let Children = this.childs
    let Infants = this.Infants
    if (typeof Adults === 'number' && typeof Children === 'number' && typeof Infants === 'number') {
      this.totalCountPassinger = Adults + Children + Infants;
    } else {

    }

  }

  updateFIlter() {



    this.searchFlightObj.TokenId = localStorage.getItem('TokenID')

    this.searchFlightObj.AdultCount = this.Adults
    this.searchFlightObj.ChildCount = this.childs
    this.searchFlightObj.InfantCount = this.Infants
    this.searchFlightObj.FlightCabinClass = this.classNo
    let FData: any = []

    const datePipe: DatePipe = new DatePipe('en-US');
    const formattedDate: string = datePipe.transform(this.fromDates, 'yyyy-MM-dd');
    const datePipe2: DatePipe = new DatePipe('en-US');
    const formattedDate2: string = datePipe2.transform(this.isReturnValue, 'yyyy-MM-dd');
    if (this.journeyType == 1) {

    
      this.searchFlightObj.Segment = FData
      this.NJourneyType = 1
      this.searchFlightObj.JourneyType = this.journeyType
      FData.push({
        Origin: this.Origin,
        Destination: this.Destination,
        PreferredDepartureTime: formattedDate,
        PreferredArrivalTime: formattedDate
      })
      localStorage.setItem('myWay', 'Way1')

      if (!this.searchFlightObj.AdultCount) {
        return this.toastr.error('At Least One Adult Required')
      }
      if (this.searchFlightObj.AdultCount < 0) {
        return this.toastr.error(`Negative Numbers are Not Allowed`)
      }
       
      if (this.searchFlightObj.ChildCount < 0) {
        return this.toastr.error(`Negative Numbers are Not Allowed`)
      }
      if (this.searchFlightObj.InfantCount < 0) {
        return this.toastr.error(`Negative Numbers are Not Allowed`)
      }
      if (this.searchFlightObj.AdultCount < this.searchFlightObj.InfantCount) {
        return this.toastr.error(`Infant Count cannot be more than Adult Count.`)
      }

      if(!FData[0].Origin){
        return this.toastr.error('From field is required')
      }
  
      if (!FData[0].Destination || FData[0].Destination === undefined) {
        return this.toastr.error('To field is required')
      }
      if (FData[0].Destination ===FData[0].Origin ) {
        return this.toastr.error(`Origin ${FData[0].Origin} and Destination ${FData[0].Destination} cannot be same. Please choose another Destination.`)
      }
  
      if (!FData[0].PreferredDepartureTime === undefined ||  !FData[0].PreferredDepartureTime) {
        return this.toastr.error('Departure Date field is required')
      }
      else{
        this.router.navigate(['/VIewFlightData',JSON.stringify(this.searchFlightObj)])
      }

    }
    if (this.journeyType == 2) {
       
      this.searchFlightObj.JourneyType = 2
      this.searchFlightObj.Segment = FData
      FData.push({
        Origin: this.Origin,
        Destination: this.Destination,
        PreferredDepartureTime: formattedDate,
        PreferredArrivalTime: formattedDate

      })

      FData.push({
        Origin: this.Destination,
        Destination: this.Origin,
        PreferredDepartureTime: formattedDate2,
        PreferredArrivalTime: formattedDate2
      })
      this.searchFlightObj.Segment  =FData


      
      // this.searchTwoway2(this.flightUpdate)

      // this.searchTwoway(firstData)
      // 
      localStorage.setItem('myWay', 'Way2')

      this.searchFlightObj.FlightCabinClass = this.classNo
   
      this.service.searchTwo(this.searchFlightObj).subscribe(
        (data:any)=>{
             this.router.navigate(['/VIewFlightData',JSON.stringify(this.searchFlightObj)])
        },
        (err)=>{
          this.service.error(err.error.error)
        }

      )
    }




 
  }




  getOriginData(data: any) {
     
    this.fareRuleObj.ResultId = data.ResultId
    this.fareRuleObj.TrackingId = this.TrackingId
    this.finalPrice = this.selectedDeparture?.Fare?.TotalFare + this.selectOne?.Fare?.TotalFare
    this.selectOne = data
  }
  getOriginData2(data: any) {
     
    this.selectedDeparture = data

    this.finalPrice = this.selectedDeparture?.Fare?.TotalFare + this.selectOne?.Fare?.TotalFare

    let Destination = {
      EndUserBrowserAgent: this.fareRuleObj.EndUserBrowserAgent,
      PointOfSale: this.fareRuleObj.PointOfSale,
      RequestOrigin: this.fareRuleObj.RequestOrigin,
      UserData: this.fareRuleObj.UserData,
      ipaddress: this.fareRuleObj.ipaddress,
      TokenId: this.fareRuleObj.TokenId,
      ResultId: data?.ResultId,
      TrackingId: this.TrackingIdTwo,

    }
    this.SendReturnData2 = Destination

  }














  multiCItyEnable() {
    // alert('enabled')
    this.SubmitButton = false
    this.isHidden = true
  }
  multiCItyDisbale() {
    // alert('enabled')
    this.isHidden = false
    this.SubmitButton = true
  }

  toggleDisplay(): void {
    this.isHidden = !this.isHidden;
  }


  TimeFlight(checked: boolean) {
  

  }










  // ==========Multi Functions start============
  multiflightForm() {

    let TokenID = localStorage.getItem('TokenID')
    this.searchMultiFlightObj.TokenId = TokenID
    let RData: any = []
    for (let i = 0; i < this.dynamicForm.value.formFields.length; i++) {
      const datePipe: DatePipe = new DatePipe('en-US');
      const formattedDate: string = datePipe.transform(this.dynamicForm.value.formFields[i].PreferredDepartureTime, 'yyyy-MM-dd');
      RData.push({
        Origin: this.dynamicForm.value.formFields[i].Origin,
        Destination: this.dynamicForm.value.formFields[i].Destination,
        PreferredDepartureTime: formattedDate,
        PreferredArrivalTime: formattedDate
      })
    }

    this.searchMultiFlightObj.Segment = RData
    // this.searchMultiFlightObj.PreferredArrivalTime = 
    this.searchMultiFlightObj.JourneyType = 3
    this.searchMultiFlightObj.DirectFlight = false
    this.searchMultiFlightObj.AdultCount = this.Adults
    this.searchMultiFlightObj.ChildCount = this.childs
    this.searchMultiFlightObj.InfantCount = this.Infants
    this.searchMultiFlightObj.FlightCabinClass = this.class




    this.service.multiWayValidations(this.searchMultiFlightObj).subscribe(
      (data:any)=>{
        
      localStorage.setItem('PreferredDepartureTime', RData[0].PreferredDepartureTime)
      this.router.navigate(['/VIewFlightData', JSON.stringify(this.searchMultiFlightObj)])

      },
      (err)=>{
        this.service.error(err.error.error)
      }
    )







   

    


  }

  get formFields(): FormArray {
    return this.dynamicForm.get('formFields') as FormArray;
  }


  addFormField(names: any) {

    if (this.dynamicForm.value.formFields.length < 5) {
      let formField = this.formBuilder.group({

        Origin: [],
        Destination: [],
        PreferredDepartureTime: [''],

      });

      this.formFields.push(formField);
    }
    else {
      this.toastr.error('Limit only 5')
    }
  }

  removeFormField(index: number) {

    if (this.dynamicForm.value.formFields.length > 1) {
      this.formFields.removeAt(index);
    }
    else {
      this.toastr.error('At least one flight details')
    }

  }
  // ==========Multi Functions end============

  formatDate(date: string): string {
    const dateObject = new Date(date);
    return this.datePipe.transform(dateObject, 'd MMMM');
  }




  getCountry() {
    this.service.autoDetectCountry().subscribe(
      (result: any) => {

        let myData = {
          UserName: "Devot",
          Password: "Dev13062023",
          BookingMode: "API",
          IPAddress: result.query
        }
   
         
        this.searchFlightObj.IPAddress = result.query
        // this.searchReturnFlightObj.IPAddress = result.query
        this.searchMultiFlightObj.IPAddress = result.query
        const userAgent = navigator.userAgent;
        this.searchFlightObj.EndUserBrowserAgent = navigator.userAgent
        this.searchFlightObj.PointOfSale = result.countryCode
        this.searchFlightObj.RequestOrigin = result.country
        this.searchFlightObj.UserData = null

        // this.searchReturnFlightObj.EndUserBrowserAgent = navigator.userAgent
        // this.searchReturnFlightObj.PointOfSale = result.countryCode
        // this.searchReturnFlightObj.RequestOrigin = result.country
        // this.searchReturnFlightObj.UserData = null



        this.searchMultiFlightObj.EndUserBrowserAgent = navigator.userAgent
        this.searchMultiFlightObj.PointOfSale = result.countryCode
        this.searchMultiFlightObj.RequestOrigin = result.country
        this.searchMultiFlightObj.UserData = null
        this.service.userLogin(myData).subscribe(
          (data: any) => {
            localStorage.setItem('TokenID', data.data.TokenId)
          }
        )



      }
    )
  }

}
