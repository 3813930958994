import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-authentication-component',
  templateUrl: './authentication-component.component.html',
  styleUrls: ['./authentication-component.component.css']
})
export class AuthenticationComponentComponent implements OnInit{
  constructor(private route:ActivatedRoute, private router:Router,private service:AuthService){}
  ngOnInit(): void {

    this.route.params.subscribe((data)=>{
          localStorage.setItem('token',data.id)
       this.service.tryGoogleLogin('loginDone')
          this.router.navigateByUrl('/home')

    })

    }

}
