import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { final, saveFailedTicketDetails, saveTicketDetails } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;
  isLCC: any = localStorage.getItem('isLCC')
  @ViewChild('CloseModels') CloseModels!: ElementRef;
  saveTicketDetailsObj = new saveTicketDetails()
  saveFailedTicketDetailsObj = new saveFailedTicketDetails()
  userId: any
  paymentObject: any = []
  paymentInfo: any
  eciDescription: any
  refNumberSave: any
  allPaymentInfo: any
  isNotRealPayment: boolean = true
  finalObj = new final()
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private service: AuthService, private ngxService: NgxUiLoaderService) { }
  ngOnDestroy(): void {
    // localStorage.removeItem('information');
    // localStorage.removeItem('information2');
  }
  ngOnInit(): void {



    this.userId = localStorage.getItem('_id')
    // this. Booking_Both()


    this.route.queryParams.subscribe(params => {

      const refNumber = params['ref'];
      this.refNumberSave = params['ref'];
      // this.orderVerification(this.refNumberSave)

      // alert(refNumber)

      let information = {
        number: refNumber,
        rf: localStorage.getItem('RF')
      }
      this.service.paymentCheck(information).subscribe(
        (result: any) => {

          // if(result.status === true){
          //   this.router.navigate(['/summury', JSON.stringify(result.pnrResponseList)])
          // }
          // else if(result.status === false){
          //   if(result.bankCode !== '00' || result.bankCode !=='08' || result.bankCode !=='10' || result.bankCode !=='11'){
          //     this.paymentInfo = result.bankMessage
          //     this.openDialog()
          //   }
          // }
          // else{
          //   let reviewObj = {
          //     review: true,
          //     data: result
          //   }
          //   this.router.navigate(['/flight-review', JSON.stringify(reviewObj)])
          // }
            debugger
          if(result.pnrResponseList[0].state === 100){
            this.paymentInfo =result.pnrResponseList[0].message
            this.openDialog()
          }
          if (result.response?.state === 'CAPTURED') {
            this.router.navigate(['/summury', JSON.stringify(result.pnrResponseList)])
          }
  
         
          else {
            this.paymentObject = result.response[0]

            // 
           
            if(result.bankMessage){
              this.paymentInfo = result.bankMessage
              this.openDialog()
              return
            }
            else{
              this.paymentInfo = result?.response[0]['3ds']['summaryText'];
              this.openDialog()
              return
            }
         
           
          }


        },
        (err) => {
          if (err.error?.errors) {
            this.openDialog()
            this.paymentInfo = err.error?.errors?.message
            this.service.error(err.error?.errors?.message)

            return;
          }
          if (err.error?.error) {
            this.openDialog()
            this.paymentInfo = err.error?.error?.message
            this.service.error(err.error?.error?.message)

            return;
          }
          if (err.error?.error) {
            this.openDialog()
            this.paymentInfo = err.error?.error.message
            this.paymentInfo = err.error?.error
            this.service.error(err.error?.error)
            return;
          }
          this.openDialog()
          // localStorage.removeItem('RF')
          localStorage.removeItem('information')
          // this.router.navigateByUrl('/home')
        }

      )
    });
  }


  saveTicket() {
    this.service.saveTicket(this.saveTicketDetailsObj).subscribe(
      (data: any) => {

      }
    )
  }

  failedBookings() {
    this.service.failedBookings(this.saveFailedTicketDetailsObj).subscribe(
      (data: any) => {

      }
    )
  }

  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flight_load.json',
  };

  tryAgain() {
    let data = (localStorage.getItem('spares'))
    this.router.navigate(['/flight-review', data])
  }

  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color',
      width: '600px',
      height: '300px'

    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  gotoHome() {
    this.closeDialog()
    this.router.navigateByUrl('/home')
  }


  async bookTickets() {
    const request1 = JSON.parse(localStorage.getItem('information'));
    const request2 = JSON.parse(localStorage.getItem('information2'));

    const requestsArray = [];

    if (request1 !== null) {
      requestsArray.push(request1);
    }

    if (request2 !== null) {
      requestsArray.push(request2);
    }

    const finalObjLength = requestsArray.length;
    const successfulBookings = [];

    for (let i = 0; i < finalObjLength; i++) {
      const obj = requestsArray[i];
      const isLastIndex = i === finalObjLength - 1;

      if (obj.Itinerary.IsLcc) {

        try {
          this.saveTicketDetailsObj.request = obj
          const data: any = await this.service.Booking_Ticket(obj).toPromise();
          this.saveTicketDetailsObj.result = data.data
          this.saveTicketDetailsObj.request = obj
          await this.saveTicket();
          if (data.data.IsSuccess || [1, 2, 5, 8].includes(data.data.Status)) {
            if (data.data.Status === 1) {
              this.service.success('Ticket booked successfully ');
            } else if (data.data.Status === 2) {
              this.service.success('Ticket Not Saved ');
            } else if (data.data.Status === 8) {
              this.service.success('Ticket Price Changed ');
            }


            // Store PNR and TokenId in the array for successful bookings
            successfulBookings.push({
              PNR: data.data.PNR,
              TokenId: data.data.TokenId
            });
          } else if (data.error) {
            throw new Error(data.data.Errors[0].UserMessage);
          } else {
            throw new Error(data.data.Errors[0].UserMessage);
          }
        } catch (error) {

          this.service.error(error.message);

        }
      } else {
        try {
          this.saveTicketDetailsObj.request = obj
          const data: any = await this.service.Booking_Book(obj).toPromise();
          this.saveTicketDetailsObj.result = data.data
          this.saveTicketDetailsObj.request = obj
          if (data.data.Errors.length > 0) {
            await this.saveTicket();
            throw new Error(data.data.Errors[0].UserMessage);
          } else {
            obj.PNR = data.data.PNR
            this.saveTicketDetailsObj.request = obj
            const ticketData: any = await this.service
              .Booking_Ticket(obj)
              .toPromise();
            this.saveTicketDetailsObj.result = ticketData.data
            this.saveTicketDetailsObj.request = obj
            await this.saveTicket();
            if (ticketData.data.IsSuccess || [1, 2, 5, 8].includes(ticketData.data.Status)) {
              this.service.success('Ticket booked successfully ');
              // localStorage.removeItem('RF');
              // await this.saveTicket();

              // Store PNR and TokenId in the array for successful bookings
              successfulBookings.push({
                PNR: ticketData.data.PNR,
                TokenId: ticketData.data.TokenId
              });
            } else if (ticketData.data.Errors) {
              throw new Error(ticketData.data.Errors[0].UserMessage);
            } else if (ticketData.data.Status === 2) {
              this.service.error('Booking has got failed due to any Technical / Supplier reason');
            } else if (ticketData.data.Status === 5) {
              this.service.error('are/Schedule has got changed');
            } else if (ticketData.data.Status === 9) {
              this.service.error('PNR has been created successfully but canceled automatically due to supplier response');
            }
          }
        } catch (error) {

          this.service.error(error.message);
          this.saveTicketDetailsObj.Status = error.message
          await this.saveTicket();
        }
      }
    }

    // If all iterations are completed, navigate to "summury" page.

    // Log the array of PNRs and TokenIds for successful bookings
    this.orderValidation(this.refNumberSave)
    if (successfulBookings.length > 0) {
      this.router.navigate(['/summury', JSON.stringify(successfulBookings)])
    }
    else {
      this.router.navigate(['/bookings'])
    }

    // Finally, remove the local storage items
    localStorage.removeItem('information');
    localStorage.removeItem('information2');
  }


  orderValidation(numbers: any) {

    let info = {
      orderNumber: numbers
    }
    this.service.orderValidation(info).subscribe(
      (data: any) => {

      }
    )
  }

  orderVerification(numbers) {
    let info = {
      orderNumber: numbers
    }
    this.service.orderVerification(info).subscribe(
      (data: any) => {

        this.isNotRealPayment = data.isInDatabase
      }
    )
  }


}
