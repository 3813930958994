<div class="loadPage">
  <div *ngIf="status"   class="flight-review-component">

    <div class="container">
      <div class="main-COntent">
        <h1 *ngIf="status"  class="complite-text">Your Flight booking has completed!, status
  
      
          <span style="color: rgb(227, 252, 115);" *ngIf="status === 1">Hold</span>
          <span style="color: rgb(227, 252, 115);" *ngIf="status === 2">Success</span>
          <span style="color: rgb(207, 31, 31);" *ngIf="status === 3">Cancelled</span>
          <span style="color: yellowgreen;" *ngIf="status === 4">In Progress</span>
          <span style="color: rgb(121, 182, 0);" *ngIf="status === 5">Ticketed</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 6">Void</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 11">Amendment Done</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 10">Reissue Request</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 12 ">Released</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 14 ">Booked</span>
          <span style="color: rgb(255, 0, 0);" *ngIf="status === 15 ">Failed</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 17 ">Void Request</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 18 ">Cancellation Request</span>
          <span style="color: rgb(180, 255, 82);" *ngIf="status === 21 ">Reissued</span>
        </h1>
        <div class="row">
          <div class="col-md-9">
  
            <div>
              <div *ngFor="let item of segments ; let i = index " class="Flight-infrmation">
                <div>
                  <ul class="FLighRoute">
                    <li>
                      <h1 class="areaText"><span>
                        <img class="IconFLight"
                            src="./../../../../assets/images/FlightIcon.svg" alt="">
                          
                          </span>
  
                        {{item?.Origin?.CityName}} - {{item?.Destination?.CityName}} , Airline PNR :
                        <b>{{oneWayPNR}}</b> </h1>
                    </li>
                    <li>
                      <!-- <p class="FareRule">View Fare Rules</p> -->
                    </li>
                  </ul>
                  <div class="tickets">
                    <ul class="fromsTicket">
                      <li class="LiInfo">
                        <img class="AriICons"  src="./../../../../assets/images/FLight-logos/airlineLogo/{{item.Airline}}.gif"
                                              alt="">
  <br>
                        <p class="FLight-data">{{item.AirlineName}}</p>
                        <p class="FlightNo">{{item.Airline}}
                          {{item.FlightNumber}}</p>
                      </li>
                      <li class="LiInfo">
                        <p class="Flight-Date">{{item.DepartureTime | date:'mediumDate'}}
                        </p>
                        <h1 class="StartTime">{{item.Origin.AirportCode}}
                          {{item.DepartureTime |date:
                          'HH:mm' : 'en-US'}}</h1>
                        <P class="Airports">{{item.Origin.AirportName}},
                          {{item.Origin.CityName}},
                          {{item.Origin.CountryName}}</P>
                          <p *ngIf="item?.Origin?.Terminal" class="Airports">Terminal : {{item?.Origin?.Terminal}} </p>
                      </li>
                      <li class="LiInfo">
                        <div class="directIons">
                          <div class="rounds"></div>
                          <p style="margin: 0; " class="DurayionData">
                            ---{{item.newDuration}}---</p>
                          <img class="img-go" style="height: 20px;" src="./../../../../assets/images/FlightIcon.svg"
                            alt="">
                        </div>
                      </li>
                      <li class="LiInfo">
                        <p class="Flight-Date">{{item.ArrivalTime | date:'mediumDate'}}</p>
                        <h1 class="StartTime">{{item.Destination.AirportCode}}
                          {{item.ArrivalTime
                          |date: 'HH:mm' : 'en-US'}}</h1>
                        <P class="Airports">{{item.Destination.AirportName}},
                          {{item.Destination.CityName}},
                          {{item.Destination.CountryName}}</P>
                          <p *ngIf="item?.Origin?.Terminal" class="Airports">Terminal : {{item?.Destination?.Terminal}} </p>

                      </li>
                    </ul>
                    <div class="lins">
                      <p class="TotalKg"> <span><i class="fas fa-suitcase mr-3"></i></span>{{item.IncludedBaggage}}
                        (1
                        piece
                        only) Check-In, {{item.CabinBaggage}} (1 piece only) Cabin
                        {{item.CabinClass}}
                      </p>
  
                    </div>
  
                  </div>
  
                </div>
                <div class="ContinueNext">
  
                </div>
              </div>
            </div>
  
            <div>
              <h1 *ngIf="returnStatus" class="complite-text">Your Return Flight booking has completed!, status
  
      
                <span style="color: rgb(227, 252, 115);" *ngIf="returnStatus === 1">Hold</span>
                <span style="color: rgb(227, 252, 115);" *ngIf="returnStatus === 2">Success</span>
                <span style="color: rgb(207, 31, 31);" *ngIf="returnStatus === 3">Cancelled</span>
                <span style="color: yellowgreen;" *ngIf="returnStatus === 4">In Progress</span>
                <span style="color: rgb(121, 182, 0);" *ngIf="returnStatus === 5">Ticketed</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 6">Void</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 11">Amendment Done</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 10">Reissue Request</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 12 ">Released</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 14 ">Booked</span>
                <span style="color: rgb(255, 0, 0);" *ngIf="returnStatus === 15 ">Failed</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 17 ">Void Request</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 18 ">Cancellation Request</span>
                <span style="color: rgb(180, 255, 82);" *ngIf="returnStatus === 21 ">Reissued</span>
              </h1>
              <div *ngFor="let items of segments2 ; let i = index " class="Flight-infrmation">
                <div>
                  <ul class="FLighRoute">
                    <li>
                      <h1 class="areaText"><span><img class="IconFLight"
                            src="./../../../../assets/images/FlightIcon.svg" alt=""></span>
  
                        {{items?.Origin?.CityName}} - {{items?.Destination?.CityName}} , Airline PNR :
                        <b>{{returnWayPNR}}</b> </h1>
                    </li>
                    <li>
                      <!-- <p class="FareRule">View Fare Rules</p> -->
                    </li>
                  </ul>
                  <div class="tickets">
                    <ul class="fromsTicket">
                      <li class="LiInfo">
                        <img class="AriICons"  src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.Airline}}.gif"
                        alt="">
                        
                        <br>
                        <p class="FLight-data">{{items.AirlineName}}</p>
                        <p class="FlightNo">{{items.Airline}}
                          {{items.FlightNumber}}</p>
                      </li>
                      <li class="LiInfo">
                        <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}
                        </p>
                        <h1 class="StartTime">{{items.Origin.AirportCode}}
                          {{items.DepartureTime |date:
                          'HH:mm' : 'en-US'}}</h1>
                        <P class="Airports">{{items.Origin.AirportName}},
                          {{items.Origin.CityName}},
                          {{items.Origin.CountryName}}</P>
                          <p *ngIf="items.Origin?.Terminal" class="Airports">Terminal : {{items?.Origin?.Terminal}} </p>

                      </li>
                      <li class="LiInfo">
                        <div class="directIons">
                          <div class="rounds"></div>
                          <p style="margin: 0; " class="DurayionData">
                            ---{{items.newDuration}}---</p>
                          <img class="img-go" style="height: 20px;" src="./../../../../assets/images/FlightIcon.svg"
                            alt="">
                        </div>
                      </li>
                      <li class="LiInfo">
                        <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                        <h1 class="StartTime">{{items.Destination.AirportCode}}
                          {{items.ArrivalTime
                          |date: 'HH:mm' : 'en-US'}}</h1>
                        <P class="Airports">{{items.Destination.AirportName}},
                          {{items.Destination.CityName}},
                          {{items.Destination.CountryName}}</P>
                          <p *ngIf="items?.Destination?.Terminal" class="Airports">Terminal : {{items?.Destination?.Terminal}} </p>

                      </li>
                    </ul>
                    <div class="lins">
                      <p class="TotalKg"> <span><i class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}}
                        (1
                        piece
                        only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                        {{items.CabinClass}}
                      </p>
  
                    </div>
  
                  </div>
  
                </div>
                <div class="ContinueNext">
  
                </div>
              </div>
            </div>
  
  
  
  
  
  
  
  
  
  
            <div *ngFor="let item of passingers" class="InportantInfo">
              <ul class="ImportantHeade">
                <li>
                  <h1 class="ImportantHead">Passinger Details</h1>
                </li>
                <!-- <li><i style="cursor: pointer;" class="fas fa-edit"></i></li> -->
              </ul>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">First Name</p>
                  <h1 class="Username">{{item?.FirstName }}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Last Name</p>
                  <h1 class="Username">{{item?.LastName}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Gender</p>
                  <h1 *ngIf="item?.Gender ==1" class="Username">Male</h1>
                  <h1 *ngIf="item?.Gender ==2" class="Username">Female</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Ticket Number</p>
                  <h1 class="Username">{{item?.Ticket?.TicketNumber}}</h1>
                </li>
              </ul>
  
              <h1 class="ImportantHead">Contact Details</h1>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">Country Code</p>
                  <h1 class="Username">{{item?.Mobile1CountryCode}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Contact No.</p>
                  <h1 class="Username">{{item?.Mobile1}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Email Address</p>
                  <h1 class="Username">{{item?.Email}}</h1>
                </li>
              </ul>
  
  
  
              <h1 class="ImportantHead">Passport Details</h1>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">Passport No.</p>
                  <h1 class="Username">{{item?.PassportNo}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Expire Date</p>
                  <h1 class="Username">{{item?.PassportExpiry | date:'mediumDate'}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Nationality</p>
                  <h1 class="Username">{{item?.Country?.CountryName}}</h1>
                </li>
  
                <!-- <li class="list-names">
                  <p class="firstName">PNR</p>
                  <h1 class="Username">{{item?.Country?.CountryName}}</h1>
                </li> -->
                <!-- <h3>Ticket Details</h3> -->
            
              </ul>
            </div>
  
  
            <button routerLink="/bookings" class="ContinueBtns">Go to Bookings</button>
          </div>
          <div class="col-md-3">
            <div class="fareDetails">
              <h1 class="summarys2">Ticket(s)</h1>
  
              <div class="coupanset2">
                <div  (click)="downloadPDF()" class="coopseee">
                  <img class="DownloadImg" src="./../../../../assets/images/downloads.svg" alt="">
                  <span  class="coop">Download E-ticket(s)</span>
                </div>
                <div (click)="downloadInvoice()" class="coopseee">
                  <img class="DownloadImg" src="./../../../../assets/images/downloads.svg" alt="">
                  <span class="coop">Download Invoice(s)</span>
                </div>
                <div (click)="emailToPdfFlight()" class="coopseee">
                  <img class="DownloadImg" src="./../../../../assets/images/downloads.svg" alt="">
                  <span class="coop">Email E-Ticket(s)</span>
                </div>
                <div (click)="generateInvoice()"  (click)="generateReturnInvoice()" class="coopseee">
                  <img class="DownloadImg" src="./../../../../assets/images/downloads.svg" alt="">
                  <span class="coop">Email Invoice(s)</span>
                </div>
         
                <!-- <div class="coopseee">
                  <img class="DownloadImg" src="./../../../../assets/images/downloads.svg" alt="">
                  <span class="coop">Download Invoice(s)</span>
                </div> -->
              </div>
  
            </div>
            <!-- <div class="fareDetails">
              <h1 class="summary">Fare Summary</h1>
           
              
              <div class="fareList">
                  <ul  class="farebreak">
                      <li>
                        <p class="basing">Base Fare</p>
                      </li>
                      <li><p class="childs">{{totalBaseFare | number:'1.0-2'}} {{Currency }}</p></li>
                  </ul>
                  <ul  class="farebreak">
                    <li>
                      <p class="basing">Tax</p>
                    </li>
                    <li><p class="childs">{{totalTax | number:'1.0-2'}} {{Currency }}</p></li>
                </ul>
                <ul  class="farebreak">
                  <li>
                    <p class="basing">Service fee</p>
                  </li>
                  <li><p class="childs">{{serviceFee | number:'1.0-2'}} {{Currency }}</p></li>
              </ul>
  
              <ul class="total">
                <li>
                    <h1 class="summary">Total Amount</h1>
                </li>
                <li>
                    <h1 class="summary">{{totalFee | number:'1.0-2'}} {{Currency}}</h1>
                </li>
            </ul>
          
              </div>
  
        
  
  
  
  
  
  
  
        
          </div>
          <div *ngIf="isReturn === 'Way2'" class="fareDetails">
            <h1 class="summary">Return Fare Summary</h1>
         
            
            <div class="fareList">
                <ul  class="farebreak">
                    <li>
                      <p class="basing">Base Fare</p>
                    </li>
                    <li><p class="childs">{{totalBaseFare2 | number:'1.0-2'}} {{Currency2 }}</p></li>
                </ul>
                <ul  class="farebreak">
                  <li>
                    <p class="basing">Tax</p>
                  </li>
                  <li><p class="childs">{{totalTax2 | number:'1.0-2'}} {{Currency2 }}</p></li>
              </ul>
              <ul  class="farebreak">
                <li>
                  <p class="basing">Service fee</p>
                </li>
                <li><p class="childs">{{serviceFee2 | number:'1.0-2'}} {{Currency2 }}</p></li>
            </ul>
  
            <ul class="total">
              <li>
                  <h1 class="summary">Total Amount</h1>
              </li>
              <li>
                  <h1 class="summary">{{totalFee2 | number:'1.0-2'}} {{Currency2}}</h1>
              </li>
          </ul>
        
            </div>
  
      
  
  
  
  
  
  
  
      
        </div> -->
  
          </div>
        </div>
      </div>
  
    </div>
  
  
  
    <!-- <div  #contentPPPPF class="payment">
      <div class="container">
          <section class="payment-receipt">
              <ul class="receipt-data-top">
                  <li class="receipt-top-right">
                      <img class="receipt-logo"  src="./../../../../assets/images/Logo.png" alt="no-data">
                  </li>
                  <li class="receipt-top-right">
                      <p class="receipt-content">Flight Ticket (One way)</p>
                      <p class="receipt-content">Booking ID:{{printDetails.BookingId }}, (Booked on {{printDetails.CreatedOn | date:'short'}})</p>
                  </li>
              </ul>
              <div class="onward-flight">
                  <p class="onward-title">Onward Flight</p>
                  <p class="onward-content">{{printDetails.Origin}} to {{printDetails.Destination }} | {{printDetails.TravelDate | date:'longDate'}}</p>
                  <div class="air-asia-data">
                      <p class="onward-inside-title">{{printDetails?.Segments[0]?.AirlineName}} ,{{printDetails?.Segments[0]?.Airline}} {{printDetails?.Segments[0]?.FlightNumber}}</p>
                      <div *ngFor="let item of printDetails.Segments" class="air-asia-inside">
                          <ul class="air-menu-data">
                              <li class="air-list-data">
                                  <p class="air-list-title">{{item.Origin.CityName}}   {{item.DepartureTime |date: 'HH:mm' : 'en-US'}} </p>
                                  <p class="air-list-content">{{item.DepartureTime | date:'longDate'}} </p>
                              </li>
                              <li class="air-list-data text-center">
                                  <p class="air-list-time">{{item.Duration}}</p>
                                  <p class="air-list-time">{{item.BookingClass}}</p>
                              </li>
                              <li class="air-list-data">
                                  <p class="air-list-title">{{item.Destination.CityName}}   {{item.ArrivalTime |date: 'HH:mm' : 'en-US'}}</p>
                                  <p class="air-list-content">{{item.ArrivalTime | date:'longDate'}}</p>
                              </li>
                          </ul>
                          <p class="baggage-info">Baggage Info - Check-in:{{item.IncludedBaggage}} , CabinBaggage:{{item.CabinBaggage}} | LowFare Fare</p>
                      </div>
                  </div>
              </div>
              <div class="traveller-details table-responsive">
                  <p class="traveller-details-title">Traveller Details</p>
                  <table class="tabular-data table-responsive">
                      <thead>
                          <tr class="tr-data">
                              <th class="passenger-heading">Passenger</th>
                              <th class="passenger-heading">AirlinePNR</th>
                              <th class="passenger-heading">Ticket Number</th>
                              <th class="passenger-heading">Travel Insurance</th>
                              <th class="passenger-heading">Class {{printDetails.Segments[0].CabinClass}} | Cabin</th>
                              <th class="passenger-heading">Status</th>
                              <th class="passenger-heading"></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let items of printDetails.Passenger" class="tr-data">
                              <td class="passenger-content">{{items.Title}} {{items.FirstName}} {{items.LastName}}</td>
                              <td class="passenger-content">{{printDetails.PNR}}</td>
                              <td class="passenger-content">{{items?.Ticket?.TicketNumber}}</td>
                              <td class="passenger-content">NotConfirmed</td>
                              <td class="passenger-content"> | Economy</td>
                              <td class="passenger-content">
  
  
                                <span style="color: yellowgreen;" *ngIf="status === 4">In Progress</span>
                                <span style="color: green;" *ngIf="status === 5">Success</span>
                                <span style="color: rgb(227, 252, 115);" *ngIf="status === 1">Hold</span>
                                <span style="color: rgb(227, 252, 115);" *ngIf="status === 2">Success</span>
                                <span style="color: rgb(207, 31, 31);" *ngIf="status === 3">Cancelled</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 6">Void</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 11">AmendmentDone</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 10">ReissueRequest</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 12 ">Released</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 14 ">Booked</span>
                                <span style="color: rgb(255, 0, 0);" *ngIf="status === 15 ">Failed</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 17 ">VoidRequest</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 18 ">CancellationRequest</span>
                                <span style="color: rgb(180, 255, 82);" *ngIf="status === 21 ">Reissued</span>
  
                              </td>
                              <td class="passenger-content">
                                  <img class="qr-image" src="/images/qr-code.png" alt="qr-code-img">
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div class="other-details">
                  <p class="other-details-title">Other Details</p>
                  <ul class="other-details-inside">
                      <li>
                          <p class="other-details-content">SEAT</p>
                          <p class="other-details-content-1">27B</p>
                      </li>
                      <li>
                          <p class="other-details-content">MEAL</p>
                          <p class="other-details-content-1">NA</p>
                      </li>
                      <li>
                          <p class="other-details-content">EXTRA BAGGAGE</p>
                          <p class="other-details-content-1">NA</p>
                      </li>
                  </ul>
              </div>
              <div class="important-information">
                  <p class="important-information-title">IMPORTANT INFORMATION</p>
                  <ul class="important-information-inside">
                      <li class="important-information-data"><span class="important-data-inside">Check-in Time : </span>Passenger to report 2 hours before departure. Check-in procedure and baggage drop will close 1 hour before departure.</li>
                      <li class="important-information-data"><span class="important-data-inside">DGCA passenger charter : </span>Please refer to passenger charter by clicking <a class="here-data" href="">Here</a></li>
                      <li class="important-information-data"><span class="important-data-inside">Check travel guidlines and baggage information below : </span>Wearing masks/face covers is no longer mandatory. However, all travellers are advised to wear them, in view of the threat posed by COVID-19.For the complete list of travel guidelines issued by Indian States and UTs,Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If violated, airline may levy extra charges</li>
                      <li class="important-information-data"><span class="important-data-inside">Valid ID proof needed : </span>Carry a valid photo identification proof (Driver Licence, Aadhar Card, Pan Card or any other Government recognised photo identification)</li>
                      <li class="important-information-data">Please do not share your personal banking and security details like passwords, CVV, etc. with any third person or party claiming to represent Devotion. For any query, please reach out to Devotion on our official customer care number.</li>
                  </ul>
              </div>
              
          </section>
      </div>
  
      <div  class="container">
          <div class="payment-data">
            <ul class="receipt-data-top">
              <li class="receipt-top-right">
                  <img class="receipt-logo" width="150px"  src="./../../../../assets/images/Logo.png" alt="no-data">
              </li>
              <li class="receipt-top-right">
                  <p class="receipt-content">Flight Ticket (One way)</p>
                  <p class="receipt-content">Booking ID:{{printDetails.BookingId }}, (Booked on {{printDetails.CreatedOn | date:'short'}})</p>
              </li>
          </ul>
              <div *ngFor="let items of printDetails.Passenger"  class="fare-payment">
                  <p class="fare-payment-title">Fare & Payment Details</p>
                  <ul class="base-fare">
                      <li class="base-fare-content">
                          <p class="base-fare-data">Base Fare</p>
                          <p class="base-fare-data">{{items.Fare.BaseFare}}</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Applicable charges and taxes collected on behalf of airline</p>
                          <p class="base-fare-data">{{items.Fare.Tax}}</p>
                      </li>
                      <li class="base-fare-content base-fare-content-1">
                          <p class="base-fare-data-2">Airline Fare</p>
                          <p class="base-fare-data-2"> {{items.Fare.TotalFare}}</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Seat(collected on behalf of airline)</p>
                          <p class="base-fare-data">₹ 0</p>
                      </li>
                      <li class="base-fare-content base-fare-content-2">
                          <p class="base-fare-data">Seat ( ₹ 0 x 1) </p>
                          <p class="base-fare-data">₹ 0</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Devotion Convenience Fees (Inclusive of GST)</p>
                          <p class="base-fare-data">₹ 275</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Discount on Devotion Convenience Fees</p>
                          <p class="base-fare-data">- ₹ 537</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Other Paytm Discount</p>
                          <p class="base-fare-data">- ₹ 11</p>
                      </li>
                      <li class="base-fare-content base-fare-content-3">
                          <p class="base-fare-data">Bank Discount</p>
                          <p class="base-fare-data">₹ 0</p>
                      </li>
                      <li class="base-fare-content base-fare-content-1">
                          <p class="base-fare-data-2">Total Booking Amount</p>
                          <p class="base-fare-data-2">₹ 6744</p>
                      </li>
                      <li class="base-fare-content base-fare-content-4">
                          <p class="base-fare-data-2">Tax Break up on Convenience Fees</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Devotion Convenience Fees including GST</p>
                          <p class="base-fare-data">₹ 275</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data">Discount on Devotion Convenience Fees</p>
                          <p class="base-fare-data">- ₹ 537</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data base-fare-data-4">Discount on Devotion Convenience Fees</p>
                          <p class="base-fare-data base-fare-data-4">- ₹ 537</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data base-fare-data-3">CGST @ 9%</p>
                          <p class="base-fare-data base-fare-data-3">₹ -20</p>
                      </li>
                      <li class="base-fare-content">
                          <p class="base-fare-data base-fare-data-3">SGST @ 9%</p>
                          <p class="base-fare-data base-fare-data-3">₹ -20</p>
                      </li>
                      <li class="base-fare-content base-fare-content-5">
                          <p class="base-fare-data base-fare-data-3">IGST @ 18%</p>
                          <p class="base-fare-data base-fare-data-3">₹ 0</p>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div> -->
  </div>
</div>







