export class newsLetter {
    email: string
}
export class contactus {
    email: string
    name: string
    mobileNo: any
    countryCode: any
    subject: string
    message: string
    type: string
}

export class packagesEnquiry {
    PackageName: string
    packageDate: string
    AdultsCount: any
}

export class packagesFilters {
    nightsCount: number
    data: any
    maxPrice: number
    recommendedFor: object
    holidayTypes: object
    cityTitle: object
    isFlightIncluded: object
}


export class addProfile {
    Title: any
    Type: any
    FirstName: number
    LastName: any
    DateOfBirth: any
    email: any
}

export class selectedVIsa {
    visaId: string
    suVisaId: string
    adSerices: Object
}
export class bookFlight {
    wayType: string
    from: string
    to: string
    leaveon: string
    return: string
    adults: string
    children: string
    infants: string
    class: string
    airLine: string
}

export class multiFlight {
    wayType: string
    f1from: string
    f1to: string
    f1Date: string
    f2from: string
    f2to: string
    f2Date: string
    f3from: string
    f3to: string
    f3Date: string
    adults: string
    children: string
    infants: string
    class: string
    airLine: string
}

export class hotels {
    location: string
    fromDate: string
    toDate: string
    adutls: string
    childrens: string
    infants: string

}

export class carBook {
    picLocation: string
    picDate: string
    picTime: string
    dropLocation: string
    dropTime: string

}

export class countryInfo {
    fullname: string
    email: string
    residence: string
    nationality: string
    visaType: string
    travelDestination: string
    intendedtravelDates: string
    additionalComments: string
    mobileNo: string


}

export class career {
    name: string
    email: string
    phone: any
    description: string
    totalexp: string
    resume: string
    resumeKey: string
}


export class register {
    name: string
    email: string
    password: string
    termsAndConditions: boolean

}

export class searchFlight {
    IPAddress: string
    TokenId: string
    EndUserBrowserAgent: string
    PointOfSale: any
    RequestOrigin: any
    UserData: boolean
    JourneyType: number
    AdultCount: number
    ChildCount: number
    InfantCount: number
    FlightCabinClass: number
    DirectFlight: any
    Segment: [
        {
            Origin: string,
        }
    ]
}
export class searchFlightData {
    Origin: string;
    Destination: string
    PreferredDepartureTime: string
    PreferredArrivalTime: string
}

export class searchReturnFlight {
    IPAddress: string
    TokenId: string
    EndUserBrowserAgent: string
    PointOfSale: any
    RequestOrigin: any
    UserData: boolean
    JourneyType: number
    AdultCount: number
    ChildCount: number
    InfantCount: number
    FlightCabinClass: number
    DirectFlight: boolean
    Segment: [
        {
            Origin: string
        }
    ]

}
export class searchMultiFlight {
    IPAddress: string
    TokenId: string
    EndUserBrowserAgent: string
    PointOfSale: any
    RequestOrigin: any
    UserData: boolean
    JourneyType: number
    AdultCount: number
    ChildCount: number
    InfantCount: number
    FlightCabinClass: any
    DirectFlight: boolean
    ipaddress: any
    Segment: [
        {
            Origin: string
        }
    ]

}

export class searchingFilter {
    AirlineNames: any[] = []; 
    DepartureTime:any[] =[]
    MaxFare: any; 
    directFlight:any[] =[]
}

export class ReturnsearchingFilter {
    AirlineNames: any[] = []; 
    DepartureTime:any[] =[]
    MaxFare: any; 
    directFlight:any[] =[]
}

export class enquiryForm {
    name:string
    email:string
    mobile:string
    countryCode:string
    description:string
    type:string
}

export class searchingReturnFilter {
    name: any[] = []; // Initialize with an empty array
    departureTime: any; // You can initialize this property if needed
    fare: any; // You can initialize this property if needed
    directFlight: boolean
}

export class fareRule {
    ResultId: string;
    TokenId: string;
    TrackingId: string;
    EndUserBrowserAgent: string;
    PointOfSale: string;
    RequestOrigin: string
    UserData: string;
    ipaddress: string;
}

export class fareQuote {
    ResultId: string;
    TokenId: string;
    TrackingId: string;
    EndUserBrowserAgent: string;
    PointOfSale: string;
    RequestOrigin: string
    UserData: string;
    ipaddress: string;
}

export class bookTicket {
    ResultId: string
    TokenId: string
    TrackingId: string
    finalPayment: number
    IPAddress: string
    EndUserBrowserAgent: string
    PNR: string
    PointOfSale: string
    RequestOrigin: string
    UserData: string
    Itinerary: any
    bookTicketReturnObj:any
    bookTicketObj: any;
    fareQuote:any
    fareQuoteResponce:any;
    isPriceChangedKey:any
    isPriceChangeObject:any
}
export class bookTicketReturn {
    ResultId: string
    TokenId: string
    TrackingId: string
    finalPayment: number
    IPAddress: string
    EndUserBrowserAgent: string
    PNR: string
    PointOfSale: string
    RequestOrigin: string
    UserData: string
    Itinerary: any
    fareQuote:any
    fareQuoteResponce:any;
    isPriceChangedKey:any
    isPriceChangeObject:any
}
export class final {
    ResultId: string
    TokenId: string
    TrackingId: string
    finalPayment: number
    IPAddress: string
    EndUserBrowserAgent: string
    PNR: string
    PointOfSale: string
    RequestOrigin: string
    UserData: string
    Itinerary: any
}

export class Itinerary {
    Segments: any
    Passenger: any
    FareRules: any

}

export class saveTicketDetails {
    paymentDetails: Object
    userId: String
    TokenId: String
    PNR: String
    Status: String
    ChangeInItinerary: Object
    Itinerary: Object
    TrackingId: String
    request: Object
    result: Object

}


export class saveFailedTicketDetails {
    paymentDetails: Object
    userId: String
    TokenId: String
    PNR: String
    Status: String
    ChangeInItinerary: Object
    Itinerary: Object
    TrackingId: String
    Errors: Object
    MethodName: string
    Request: Object
    result: Object
}


export class failedTicket {
    paymentDetails: Object
    userId: String
    TokenId: String
    PNR: String
    Status: String
    ChangeInItinerary: Object
    Itinerary: Object
    TrackingId: String
}


export class Passenger {
    Title: any
    FirstName: string
    LastName: string
    Mobile1: string
    Mobile1CountryCode: string
    IsLeadPax: string
    DateOfBirth: string
    Type: string
    IdDetails: any
    AddressLine1: string
    Email: string
    Gender: string
    PassportNo: string
    Nationality: any
    Country: any
    City: any
    PassportExpiry: string
    Meal: any
    Fare: any
    PaxBaggage: any
    PaxMeal: any
    PaxSeat: any

}


export class inquiry {
    email: string
    name: string
    mobileNo: any
    country: any
    subject: string
    message: string
    type: string
}
