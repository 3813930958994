<div>
  <section id="tranding" class="tourism-slide-main">
    <div class="tourism-slide-top">
      <p class="tourism-slide-top-title">Testimonials</p>
      <p class="tourism-slide-top-content">OUR DEVOTION IS ALL TOWARD PROVIDING OUR CUSTOMERS WITH THE BEST TIME OF THEIR LIFE</p>
    </div>
  
   <div class="">
    <div class="tourism-slide-bottom">
      <div class="swiper tourism-slider">
        <div class="swiper-wrapper">
          <div *ngFor="let item of allVideoFiles" class="swiper-slide tourism-slide">
            <div class="tourism-slide-img">
              <video class="tourism-view-image" (click)="getPlay(item)" data-bs-toggle="modal" data-bs-target="#staticBackdropTestM" class="tourism-view-image" [src]="item.link" mute></video>
              <!-- <img class="tourism-view-image"
                src="./../../../assets/images/testimonial/tourism-img-1.png" alt="tourism-img"> -->
            </div>
            <div class="tourism-bottom-slide-content">
              <div class="tourism-bottom-slide-content-inside">
                <p class="tourism-bottom-slide-title">Riya</p>
                <p class="tourism-bottom-slide-content">"It was a one of its kind experience."</p>
              </div>
            </div>
          </div>
       
      </div>
    </div>
    </div>
   </div>
  </section>
</div>




    <!-- Modal -->
    <div class="modal fade" id="staticBackdropTestM" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title videoVidess" id="staticBackdropLabel" >Video View  </h5>
                    <button type="button" (click)="closeplayer()" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body fullGalleryView">
                    <div class="row">

                        <div class="gallery">
                       
                            <div class="main-image">
                              
                                <!-- <img *ngIf="mainImage?.type==='image'" id="mainImg"  [src]="mainImage?.link" alt="Main Image" /> -->
                                <video #myVideo controls width="100%" height="340px" [src]="mainImage"></video>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>