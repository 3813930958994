import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-country-mobile',
  templateUrl: './country-mobile.component.html',
  styleUrls: ['./country-mobile.component.css']
})
export class CountryMobileComponent implements OnInit{
@Input() dataCass:string
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  phoneForm:FormGroup
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates, CountryISO.UnitedKingdom];
  ngOnInit(): void {
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });

   
  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}

  myInfo(){
     
    let phone = this.phoneForm.value.phone.nationalNumber
    let countryCode = this.phoneForm.value.phone.dialCode
    localStorage.setItem('homePhone',phone)
    localStorage.setItem('countryCode',countryCode)
  }
}
