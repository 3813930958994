import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit{
  allVideoFiles:any = []
  @ViewChild('myVideo') myVideo: any;
  mainImage:any =''
  constructor(private service:AuthService){}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getTestmonials()
  }
  getTestmonials(){
    this.service.getTestmonials().subscribe(
     (data:any)=>{
      this.allVideoFiles = data
     },(err)=>{
      console.log(err)
     }
    )
  }

  getPlay(item){
    console.log(item)
    this.mainImage = item.link
  }

  closeplayer(){
    const video: HTMLVideoElement = this.myVideo.nativeElement;
    video.pause();
  }


   loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      './../../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
