import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { fareRule } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-fare-quote',
  templateUrl: './fare-quote.component.html',
  styleUrls: ['./fare-quote.component.css']
})
export class FareQuoteComponent implements OnInit {
  fareRuleObj = new fareRule()
  IsLccUser: boolean
  fareDetails:any= []
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe((data: any) => {
       
      this.fareRuleObj = JSON.parse(data.params.data)
      this.getQuote()
    })
  }

  getQuote() {
    this.service.FareQuote(this.fareRuleObj).subscribe(
      (res: any) => {
         
     
        this.fareDetails = res.data.Result[0]
        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
        }
        else {
          if (res.data.Result[0].IsLcc == true) {
            this.IsLccUser = true
          }
          if (res.data.Result[0].IsLcc == false) {
            this.IsLccUser = false
          }
        }
      }
    )
    this.service.FareRule(this.fareRuleObj).subscribe(
      (res: any) => {
      
        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)

        }
      }
    )
  }

  proceed(){
    let mYarr = []
     
    mYarr.push(this.fareDetails)
    mYarr.push(this.fareRuleObj)
    if(this.IsLccUser == true){
      this.router.navigate(['/ticket',JSON.stringify(mYarr)])
    }
    else{
      this.router.navigate(['/book',JSON.stringify(mYarr)])
    }
  }

}
