<div  class="mainComming">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="comingBox">
                    <h1 class="cominText">
                        Coming Soon
                    </h1>
                    <!-- <p class="notice">Get Notified when we launched</p> -->
                    <!-- <form class="emailForm">
                        <div class="formINfo">
                            <input type="email" placeholder="Please enter email" class=" fomrInfo"
                               >

                        </div>

                        <button type="submit" class=" comingSubmit">Submit</button>
                    </form> -->
                    <!-- <div id="emailHelp" class="form-text">*Don't worry we will not spam you.</div> -->
                </div>
            </div>
            <div class="socials">
                <ul class="icons">
                    <li  class="spacing"><a  href="https://www.instagram.com/devotiontourism/" target="_blank" ><img class="iconImg" src="./../../../assets/images/visa-page/insta.png" alt=""></a></li>
                    <li class="spacing"><a href="https://www.linkedin.com/company/92558268/admin/" target="_blank"><img class="iconImg" src="./../../../assets/images/visa-page/linked.png" alt=""></a></li>
                    <li class="spacing"><a ><img class="iconImg" src="./../../../assets/images/visa-page/twitter.png" alt=""></a></li>
                    <li class="spacing"><a  href="https://www.youtube.com/@Devotiontravelandtourism" target="_blank"><img class="iconImg" src="./../../../assets/images/visa-page/youtube.png" alt=""></a></li>
                </ul>
            </div>
        </div>
    </div>

</div>