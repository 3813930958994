import { DatePipe } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-package-view',
  templateUrl: './package-view.component.html',
  styleUrls: ['./package-view.component.css']
})
export class PackageViewComponent {
  currentIndex = 0;
  selectedCurrency:any
  @ViewChild('openPasingers') openPasingers: ElementRef;
  @ViewChild('openEnq') openEnq: ElementRef;
  @ViewChild('closeModels') closeModels: ElementRef;

  selectedFlag = './../../../../assets/images/country/aue.webp'
  allMobileFlags: any = [];
  searchText: string
  reqform: FormGroup
  todayDate:any
  packageId: string;
  packageDetails: any = []
  cityDetails: any = []
  selectTravelDate:any  ='Travel Date'
  selectTravel:any
  travelDateBooliean:boolean = false
  iterationData: any = []
  accommodationData: any = []
  inclutionData: any = []
  exclutionData: any = []
  isLoginUser:boolean = false
  images: string[] = [];
  consitonData: string[] = [];
  mainImage: string = this.images[0];
  constructor(private route: ActivatedRoute, private service: AuthService, private router:Router) {
    this.isLoginUser = this.service.isLoggedin()
    
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.selectTravel = new Date()
    this.todayDate = new Date()
    this.CheckCurrency()
   }
   CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  showImage(image: string, index: number) {
    this.currentIndex = index;
    this.mainImage = image;
  }
  onDateSelected(event: MatDatepickerInputEvent<Date>) {
     
    const datePipe: DatePipe = new DatePipe('en-US');
    const formattedDate: string = datePipe.transform(this.selectTravel, 'dd-MM-yyyy');
    if (formattedDate) {

      this.selectTravelDate= formattedDate
      this.travelDateBooliean = false
      localStorage.setItem('selectdDate', JSON.stringify(formattedDate))
      this.openPasingers.nativeElement.click()
  
    }

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getParamsData()
    this.getPackageImagesByID()
    this.getCountryFlags()

    this.reqform = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      countryCode: new FormControl('971', [Validators.required]),
      emailId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      search: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      packageId: new FormControl('', [Validators.required]),

    })
   
  }

  getParamsData() {
    this.route.params.subscribe(
      (data: any) => {
        this.packageId = data.id
        this.service.getPackageByID(this.packageId).subscribe(
          (res: any) => {

            let dayNumber = 1;

            res.packageData[0].cities.forEach((value) => {
             
              value.days.forEach((day) => {
                day.dayNumber = dayNumber;
                dayNumber++;
              });
            });
            this.packageDetails = res.packageData[0]
              
       
            this.cityDetails = res.packageData[0].cities
            this.iterationData = res.packageData[0].itaration
            this.accommodationData = res.packageData[0].accommodation;


            let totalDaysCount = 0; // Initialize the total days count


            let inclutions = []
            let exclutions = []
            res.packageData[0].inclustion.forEach((values: any) => {
              inclutions.push(values.inclutions)
              exclutions.push(values.exclution)
            })
            this.inclutionData =  res.packageData[0].inclustion
            this.exclutionData =  res.packageData[0].exclustion
             
            this.consitonData =  res.packageData[0].termsAndConditions
          }
        )
      }
    )
  }
  getPackageImagesByID() {
    this.service.getPackageImagesByID(this.packageId).subscribe(
      (data: any) => {
        let images = []
        data.packageData.forEach((value) => {
          images.push(value.image)
        })
        this.images = images
        this.showImage(images[0], 0)
      }
    )
  }

  previousImage() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.mainImage = this.images[this.currentIndex];
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.mainImage = this.images[this.currentIndex];
  }



  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {
        let allData = []
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }

        }
          
        this.allMobileFlags = allData


      }
    )
  }

  onSubmit() {
    let access: any = this.service.isLoggedin()
    if(!access){
      this.openEnq.nativeElement.click()
      return
    }
    delete this.reqform.value.search
    this.reqform.value.packageId =this.packageDetails._id
    this.service.packageEnquiry(this.reqform.value).subscribe(
      (data:any)=>{
        this.reqform.reset()
        this.closeModels.nativeElement.click()
        this.service.success(data.message)
      },
      (err)=>{
          
        this.service.error(err.error.message)
      }
    )
  }

  selectCountryCode(data) {
    this.reqform.value.countryCode = data.callingCode
    this.selectedFlag = 'data:image/png;base64,' + data.flag;
 
  }

  gotoBook(){
    
    let access: any = this.service.isLoggedin()
    if(access){
      if(!this.selectTravel){
        // this.service.error('Please Select Travel Date')
      
        this.travelDateBooliean = true
        return
      }
      else{
        this.router.navigateByUrl(`/packageBook/${this.packageId}`)
  
      }
    }
    else{
      this.service.tryLogin('PleaseLogin')
    }
 
  }

  tryLogin(){
    let access: any = this.service.isLoggedin()
    if(!access){
      this.service.tryLogin('PleaseLogin')
      return
    }else{
      this.openEnq.nativeElement.click()
    }
  }

  onDropdownClickReturn(event: Event): void {
    // Add your logic for preventing dropdown closure here
    event.stopPropagation();
  }

  getFormattedDate(){

  }

}
