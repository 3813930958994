// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseURL: "https://testdemoapi.devotiontourism.com/v1/",
  // baseURL2: "https://testdemoapi.devotiontourism.com/",
  baseURL: "https://api.devotiontourism.com/v1/",
   baseURL2: "https://api.devotiontourism.com/",
  // baseURL: "https://testapi.devotiontourism.com/v1/",
  // baseURL2: "https://testapi.devotiontourism.com/",
  // baseURL: "http://192.168.6.234:4000/v1/",
  // baseURL2: "http://192.168.6.234:4000/",
  userKey:`7Jk&$oP@2h*3Ie#sTf4G5u^H6vL7mP8q*9Ri0XjYkZl1Mn2O3Qp4W5e6R7
  tS8u9V0wA1B2C3D4E5F6G7H8I9J0Kdevotion1L2M3N4O5P6Q7R8S9T0U1V2W3X4Y5Z6a7b8c9d0e
  1f2g3h4i5j6k7l8m9n0o1p2q3r4s5t6u7v8w9x0y1z2A3B4C5D6E7F8G9H0I1J2K3L4M5N6O7P8Q9
  R0S1T2U3V4W5X6Y7Z8!@#$%^&tourism*()_-+=,.<>?/:;{}[]|`,
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';
 // Included with Angular CLI.
