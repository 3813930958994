import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.css']
})
export class BlogViewComponent implements OnInit {
  blogId: any
  blogInfo: any = []
  allBlogList: any = []
  constructor(private service: AuthService, private route: ActivatedRoute,private router:Router) {

  }
  ngOnInit(): void {
    this.route.params.subscribe(
      (id: any) => {
        this.blogId = id.id
        this.getBlogbyId()
      }
    )
    window.scrollTo(0, 0);
this.allBlogs()
  }

  getBlogbyId() {

    this.service.getBlogbyId(this.blogId).subscribe(
      (data: any) => {
        this.blogInfo = data[0]
      }
    )
  }

  allBlogs() {
    this.service.allBlogs().subscribe(
      (data: any) => {
        this.allBlogList = data
      }
    )
  }

  getBlog(data){
    this.router.navigate(['/blog-post', data._id])
}
}
