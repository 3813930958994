import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { register } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  registerform: FormGroup;
  registerObj: register = new register()
  constructor(private service:AuthService,private toastr: ToastrService,private router:Router) { }

  ngOnInit(): void {
    this.registerform = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,
      Validators.pattern('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')
      ]),
      password: new FormControl('', [Validators.required]),
    })
  }

  onSubmit() {
    this.toastr.error('Coming soon !')
    // this.service.register(this.registerObj).subscribe(
    //   (data:any)=>{

    //     this.toastr.success(data.message)
    //     this.registerform.reset()
    //     this.router.navigate(['/login']);

    //   },
    //   (err)=>{
    //     this.toastr.error(err.error.message)
    //   }
    // )
    
  }

  onChange(isChecked) {
 
    this.registerObj.termsAndConditions = isChecked
  }

}
