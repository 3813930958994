import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-corporate-packages',
  templateUrl: './corporate-packages.component.html',
  styleUrls: ['./corporate-packages.component.css']
})
export class CorporatePackagesComponent implements OnInit {
  localPackages: any = []
  globalPackages: any = []
  selectedCurrency:any
  constructor(private service: AuthService, private router:Router) { 
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
  }
  ngOnInit(): void {
    this.getPackages()
  }

  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }

  getPackages(){
    this.service.getCorporatePackages().subscribe(
      (data: any) => {
        this.localPackages = data.localCorporatePackages
        this.globalPackages = data.globalCorporatePackages
      }, (err) => {
        console.log('error from getting Corporate Packages')
      }
    )
  }

  getMoreDetails(data:any){
    this.router.navigateByUrl(`/packageView/${data._id}`)
  }
  getMoreDetails2(items) {

    this.router.navigateByUrl(`/packagesFilter/${items}`)
  }
}
