<div class="allPages">
<div class="pacagesNew" >
    <div class="home-page">

        <div *ngIf="true" class="apply-visa">

            <div class="visa-FormData">
                <div class="container">
                    <h1 class="visa-application">Visa Application Form</h1>
                    <div class="row">
                        <div class="col-lg-8">
                            <form [formGroup]="applicantForm" (ngSubmit)="onSubmit()">



                                <div formArrayName="applicants">
                                    <div style="margin-bottom: 40px;"
                                        *ngFor="let applicant of applicantForm.get('applicants')['controls']; let i = index ;let isFirst = first">
                                        <div [formGroupName]="i">

                                            <div class="leftform-data">
                                                <p class="ApplicantName">Applicant {{i+1}}</p>

                                                <div formGroupName="personalDetails">
                                                    <h1 class="general-details">Personal Details</h1>
                                                    <div class="row">
                                                        <div class="col-lg-11">
                                                            <div class="row g-2 g-md-3">
                                                                <div class="col-md-4">
                                                                    <input type="text" formControlName="firstname"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom01" placeholder="First Name"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'firstname')}"
                                                                        required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'firstname')"
                                                                        class="invalid-feedback">
                                                                        First Name is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <input type="text" formControlName="lastname"
                                                                        class="form-control general-inputData"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'lastname')}"
                                                                        id="validationCustom02" placeholder="Last Name"
                                                                        required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'lastname')"
                                                                        class="invalid-feedback">
                                                                        Last Name is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <select id="inputState"
                                                                        formControlName="nationality"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'nationality')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Nationality
                                                                        </option>
                                                                        <option *ngFor="let item of allContries"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'nationality')"
                                                                        class="invalid-feedback">
                                                                        Nationality is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 class="travel-subTitles">Date of Birth</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthDay"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthDay')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Date
                                                                </option>
                                                                <option *ngFor="let item of travelDates"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthDay')"
                                                                class="invalid-feedback">
                                                                Date is required.
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthMonth"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthMonth')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Month
                                                                </option>
                                                                <option *ngFor="let item of travelMonths"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthMonth')"
                                                                class="invalid-feedback">
                                                                Month is required.
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthYear"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthYear')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Year
                                                                </option>
                                                                <option *ngFor="let item of birthYears"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthYear')"
                                                                class="invalid-feedback">
                                                                Year is required.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <select id="inputState" formControlName="gender"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'gender')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Gender
                                                                </option>
                                                                <option *ngFor="let item of genders"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'gender')"
                                                                class="invalid-feedback">
                                                                Gender is required.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <input type="text" formControlName="passportNumber"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportNumber')}"
                                                                class="form-control general-inputData"
                                                                id="validationCustom01" placeholder="Passport No."
                                                                required />
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportNumber')"
                                                                class="invalid-feedback">
                                                                Passport Number is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 class="travel-subTitles">Passport Expiry Date</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col-md-7">
                                                            <div class="row g-3">
                                                                <div class="col">


                                                                    <select id="inputState"
                                                                        formControlName="passportExpDate"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpDate')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Date
                                                                        </option>
                                                                        <option *ngFor="let item of travelDates"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>
                                                                    </select>

                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportExpDate')"
                                                                        class="invalid-feedback">
                                                                        Expiry Date is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col">


                                                                    <select id="inputState"
                                                                        formControlName="passportExpMonth"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpMonth')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Month
                                                                        </option>
                                                                        <option *ngFor="let item of travelMonths"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>
                                                                    </select>

                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportExpMonth')"
                                                                        class="invalid-feedback">
                                                                        Month is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <!-- <select id="inputState" formControlName="passportExpYear"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpYear')}"
                                                                        id="inputState"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Year
                                                                        </option>
                                                                        <option *ngFor="let tem of passportExpYear"
                                                                            class="date-Optionss-below" selected>
                                                                            {{tem}}
                                                                        </option>

                                                                    </select> -->

                                                                    <select id="inputState"
                                                                        formControlName="passportExpYear"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpYear')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Year
                                                                        </option>
                                                                        <option *ngFor="let item of passportExpYear"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportExpYear')"
                                                                        class="invalid-feedback">
                                                                        Year is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>

                                                <div formGroupName="generalDetails">

                                                    <h1 class="general-details">General Details</h1>
                                                    <div class="row">
                                                        <div class="col-lg-11">
                                                            <div class="row g-2 g-md-3">
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'currentAddress')}"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom01"
                                                                        formControlName="currentAddress"
                                                                        placeholder="Your current address" required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'currentAddress')"
                                                                        class="invalid-feedback">
                                                                        Address is required.
                                                                    </div>

                                                                </div>
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        class="form-control general-inputData"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'city')}"
                                                                        formControlName="city" id="validationCustom02"
                                                                        placeholder="City" required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'city')"
                                                                        class="invalid-feedback">
                                                                        City is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom01" formControlName="state"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'state')}"
                                                                        placeholder="State/province" required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'state')"
                                                                        class="invalid-feedback">
                                                                        State is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <input 
                                                                        type="number"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom02"
                                                                        formControlName="pincode"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'pincode')}"
                                                                        placeholder="Zipcode /Pincode /Postal code"
                                                                        required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'pincode')"
                                                                        class="invalid-feedback">
                                                                        Pincode is required.
                                                                    </div>
                                                                </div>



                                                                <div class="col-md-4">
                                                                    <select formControlName="mobileCountryCode"
                                                                        id="inputState"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'mobileCountryCode')}"
                                                                        class="form-select date-select-below">
                                                                        <option [selected]="true" disabled value="" class="date-Optionss-below">
                                                                            Country code
                                                                        </option>
                                                                        <option *ngFor="let item of allMobileFlags" class="date-Optionss-below" [value]="item.callingCode">
                                                                            {{item.name}}
                                                                        </option>

                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'mobileCountryCode')"
                                                                        class="invalid-feedback">
                                                                        Country Code is required.
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-4">
                                                                    <input type="number"
                                                                        class="form-control general-inputData"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'contactNumber')}"
                                                                        id="validationCustom02"
                                                                        formControlName="contactNumber"
                                                                        placeholder="Enter Mobile number" required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'contactNumber')"
                                                                        class="invalid-feedback">
                                                                        Mobile Number is required.
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 class="travel-subTitles">Travel Date</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col-md-5">
                                                            <div class="row g-3">
                                                                <div class="col">
                                                                    <select formControlName="travelDate" id="inputState"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'travelDate')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Date
                                                                        </option>
                                                                        <option *ngFor="let item of travelDates"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>

                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'travelDate')"
                                                                        class="invalid-feedback">
                                                                        Date is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <select formControlName="travelMonth"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'travelMonth')}"
                                                                        id="inputState"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Month
                                                                        </option>
                                                                        <option *ngFor="let item of travelMonths"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>


                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'travelMonth')"
                                                                        class="invalid-feedback">
                                                                        Month is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <select formControlName="travelYear" id="inputState"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'travelYear')}"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Year
                                                                        </option>
                                                                        <option *ngFor="let item of travelYears"
                                                                            class="date-Optionss-below">
                                                                            {{item}}
                                                                        </option>



                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'travelYear')"
                                                                        class="invalid-feedback">
                                                                        Year is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-7">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <input type="email" formControlName="emailId"
                                                                        class="form-control general-inputData"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'emailId')}"
                                                                        id="validationCustom01"
                                                                        formControlName="emailId"
                                                                        placeholder="Email address" required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'emailId')"
                                                                        class="invalid-feedback">
                                                                        Email is required.
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-6">
                                                                    <select formControlName="holidayType"
                                                                    [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'generalDetails', 'holidayType')}"

                                                                        id="inputState"
                                                                        class="form-select date-select-below">
                                                                        <option class="date-Optionss-below">
                                                                            Select Type
                                                                        </option>
                                                                        <option *ngFor="let item of visitporpuse"
                                                                            class="date-Optionss-below" value="1">
                                                                            {{item}}
                                                                        </option>

                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'generalDetails', 'holidayType')"
                                                                    class="invalid-feedback">
                                                                    Holiday type is required.
                                                                </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h1 class="general-details">Upload Documents</h1>
                                                <div class="row  g-md-3">

                                                    <div class="col-md-4">
                                                        <div class="mb-3">
                                                            <label for="exampleInputPassword1"
                                                                class="form-label scaned-PhotoCopy">Passport
                                                                Front</label>
                                                            <input type="file" style="line-height: 2.5;"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy')}"
                                                                formControlName="scannedPassportCopy"
                                                                (change)="selectFile( i,$event)"
                                                                class="form-control  general-inputData"
                                                                id="exampleInputPassword1">
                                                        </div>
                                                        <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy')"
                                                            class="invalid-feedback">
                                                            Passport front document required.
                                                        </div>
                                                        <div *ngIf="passportCopyuploaignSTart"
                                                            style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 20px;"
                                                            [src]="dataArray[i]" alt="">
                                                    </div>


                                                    <div class="col-md-4">
                                                        <div class="mb-3">
                                                            <label for="exampleInputPassword1"
                                                                class="form-label scaned-PhotoCopy">Passport
                                                                Back</label>
                                                            <input type="file" style="line-height: 2.5;"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'scannedphoto')}"
                                                                formControlName="scannedphoto"
                                                                (change)="selectFile3( i,$event)"
                                                                class="form-control  general-inputData"
                                                                id="exampleInputPassword1">
                                                        </div>
                                                        <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'scannedphoto')"
                                                            class="invalid-feedback">
                                                            Passport front document required.
                                                        </div>
                                                        <div *ngIf="PhotoCopyuploaignSTart3" style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray3[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 20px;"
                                                            [src]="dataArray3[i]" alt="">
                                                    </div>



                                                    <div class="col-md-4">
                                                        <div class="mb-3">
                                                            <label for="exampleInputPassword1"
                                                                class="form-label scaned-PhotoCopy">Passport Size
                                                                Photo</label>
                                                            <input type="file" style="line-height: 2.5;"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy2')}"
                                                                formControlName="scannedPassportCopy2"
                                                                (change)="selectFile2( i,$event)"
                                                                class="form-control  general-inputData"
                                                                id="exampleInputPassword1">
                                                        </div>
                                                        <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy2')"
                                                            class="invalid-feedback">
                                                            Passport front document required.
                                                        </div>
                                                        <p style="margin: 0;" class="specifiations">
                                                            {{visaDetails?.passphotoSpecifications}}</p>
                                                        <div *ngIf="PhotoCopyuploaignSTart" style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray2[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 6px;"
                                                            [src]="dataArray2[i]" alt="">

                                                    </div>















                                                    <!-- <div class="col-md-4">
                                                        <label for="exampleInputEmail1"
                                                            class="form-label scaned-PhotoCopy">Passport Front</label>
                                                        <div class="input-group ImageFile-input">
                                                            <label class="input-group-btn choose-btn-top">
                                                                <span class="btn btn-primary choose-file">
                                                                    Choose File
                                                                    <input (change)="selectFile( i,$event)" type="file"
                                                                    formControlName="scannedPassportCopy"
                                                                    [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy')}"
                                                                        style="display: none" required/>
                                                                        <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'scannedPassportCopy')"
                                                                        class="invalid-feedback">
                                                                        Passport front document required.
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                        <div *ngIf="passportCopyuploaignSTart"
                                                            style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 20px;"
                                                            [src]="dataArray[i]" alt="">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="exampleInputEmail1"
                                                            class="form-label scaned-PhotoCopy">Passport Back</label>
                                                        <div class="input-group ImageFile-input">
                                                            <label class="input-group-btn choose-btn-top">
                                                                <span class="btn btn-primary choose-file">
                                                                    Choose File
                                                                    <input type="file" (change)="selectFile3( i,$event)"
                                                                        formControlName="scannedphoto"
                                                                        style="display: none" />
                                                                </span>
                                                            </label>

                                                        </div>
                                                        <div *ngIf="PhotoCopyuploaignSTart3" style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray3[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 20px;"
                                                            [src]="dataArray3[i]" alt="">

                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="exampleInputEmail1"
                                                            class="form-label scaned-PhotoCopy">Passport Size
                                                            Photo</label>
                                                        <div class="input-group ImageFile-input">
                                                            <label class="input-group-btn choose-btn-top">
                                                                <span class="btn btn-primary choose-file">
                                                                    Choose File
                                                                    <input type="file" (change)="selectFile2( i,$event)"
                                                                      
                                                                        style="display: none" />
                                                                </span>
                                                            </label>

                                                        </div>
                                                        <p style="margin: 0;" class="specifiations">{{visaDetails?.passphotoSpecifications}}</p>
                                                        <div *ngIf="PhotoCopyuploaignSTart" style="margin-top: 30px;">
                                                            <div class="spinner-border" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <p>Image uploading...</p>
                                                        </div>
                                                        <img *ngIf="dataArray2[i]" width="60px" height="60px"
                                                            style="border-radius: 6px; margin-top: 6px;"
                                                            [src]="dataArray2[i]" alt="">

                                                    </div> -->
                                                </div>
                                                <button *ngIf="!isFirst" (click)="removeApplicant(i)"
                                                    class="deleteIcon"><i class="fas fa-trash-alt"></i> </button>

                                            </div>
                                        </div>
                                    </div>



                                    <p class="upload-note">
                                        Note: Upload of passport and photograph are not mandatory to
                                        complete the visa application form. You can provide us these
                                        documents later also on email: contact@instaglobaltravel.com or
                                        on WhatsApp +971-505863986
                                    </p>

                                    <div class="form-check terms-top">
                                        <input (change)="termsAndConditions($event)"
                                            class="form-check-input inputCheck-box" type="checkbox" value=""
                                            id="flexCheckDefault" />
                                        <label class="form-check-label read-agree" for="flexCheckDefault">
                                            I have read and agree with the
                                            <a class="terms-condition">terms and conditions</a>
                                        </label>
                                        <br>
                                        <label *ngIf="termsAndConditionValue" style="color: red;"
                                            class="form-check-label read-agree" for="flexCheckDefault">
                                            Please select terms-conditions

                                        </label>

                                    </div>
                                    <div class="butons-below">
                                        <button type="button" (click)="addApplicant()" class="submit-btns">
                                            <span class="plus-icon"><i class="fas fa-plus"></i></span>Add
                                            More Applicant</button>

                                        <button [disabled]="isSubmited" type="submit" class="submit-btns ">Submit
                                            Application

                                            <div *ngIf="isSubmited" class="spinner-border" style="padding-left: 3px;"
                                                role="status">
                                                <!-- <span class="visually-hidden">Loading...</span> -->
                                            </div>
                                        </button>

                                    </div>


                                </div>
                            </form>
                        </div>

                        <div class="col-lg-4">
                            <div class="right-inquery">
                                <div class="travel-box-below">
                                    <h3 class="traveling-boxTitle">Travelling to</h3>
                                    <p class="travel-place">{{isVisa.id}}</p>
                                    <h3 class="traveling-boxTitle">Travelling From</h3>
                                    <p class="travel-place">{{isVisa.name}}</p>
                                    <h3 class="traveling-boxTitle">Applied Visa</h3>
                                    <p class="travel-place">{{visaDetailsList?.title}}</p>
                                </div>
                                <div class="fare-box-below">
                                    <h4 class="fare-sumary">Fare Summary</h4>
                                    <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <p class=" visa-fee">
                                                <span class="plus-minusIcons"></span>Visa Fee
                                            </p>
                                            <p class="per-adult">Adult {{visaPassingerCount}}</p>
                                        </li>
                                        <li class="fare-lists">
                                            <p class=" visa-fee">{{visaDetailsList?.price  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                                            <p class="per-adult text-end">{{baseFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                                        </li>
                                    </ul>
                                    <ul *ngFor="let item of geFeeDetails" class="fare-list-top">
                                        <li class="fare-lists">
                                            <span class="plus-minusIcons"></span>Visa
                                            {{item.name}}
                                        </li>
                                        <li class="fare-lists"> {{item.price  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                                    </ul>
                                    <!-- <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <span class="plus-minusIcons"><i
                                                    class="fas fa-plus-circle"></i></span>Travel Insurance
                                        </li>
                                        <li class="fare-lists">$50</li>
                                    </ul> -->
                                    <ul class="fare-list-top amount-top">
                                        <li class="total-amount">
                                            Total Amount
                                        </li>
                                        <li class="total-amount">{{totalPrice  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</div>