import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-country-mobile-two',
  templateUrl: './country-mobile-two.component.html',
  styleUrls: ['./country-mobile-two.component.css']
})
export class CountryMobileTwoComponent implements OnInit{
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  phoneForm:FormGroup
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates, CountryISO.UnitedArabEmirates];
  ngOnInit(): void {
    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });


  }

  changePreferredCountries() {
		this.preferredCountries = [CountryISO.UnitedArabEmirates, CountryISO.Canada];
	}

  myInfo(){
     
    let phone = this.phoneForm.value.phone.nationalNumber
    let countryCode = this.phoneForm.value.phone.dialCode
    localStorage.setItem('homePhone',phone)
    localStorage.setItem('countryCode',countryCode)
  }
}
