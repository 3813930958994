<div class="userprofile-content">
  <div class="userprofile-data">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-3">
          <div class="userprofile-left">
            <div class="userporifle-card">
              <div class="userprofile-image">

                <img *ngIf="!imgProfile" class="image-data" src="./../../../assets/images/dummyPic.avif" alt="no-data">
                <img *ngIf="imgProfile" class="image-data" [src]="imgProfile" alt="no-data">
                <!-- <label class="filelabel penIcon-edit">
                  <input (change)="selectFile($event)" accept=".png, .jpg, .jpeg" class="FileUpload1" id="FileInput"
                    name="booking_attachment" type="file" />
                  <i style="
                          font-size: 19px; padding: 0;" class="fas fa-pen"></i>
                </label> -->

              </div>
              <h3 class="userporfile-title">{{ profieData?.firstname }} {{ profieData?.lastname }}</h3>
              <div class="task-progress">
                <p class="porgress-titles"><span *ngIf="profilePercentage !== 100">Your profile is incomplete</span>
                  <span *ngIf="profilePercentage === 100">Your profile is completed</span>
                  <span>{{profilePercentage | number:'1.0-2'}}%</span>
                </p>
                <div class="progress progress1">
                  <div class="progress-bar " role="progressbar" [attr.aria-valuenow]="profilePercentage"
                    [style.width.%]="profilePercentage" aria-valuemin="2" aria-valuemax="100"></div>

                </div>
                <!-- <progress class="progress progress1" [attr.aria-valuenow]="profilePercentage"
                [style.width.%]="profilePercentage" aria-valuemin="2" aria-valuemax="100"></progress> -->
              </div>

              <ul class="userprofile-details">
                <!-- <li class="userprofile-details-li">
                  <p class="userprofile-details-left">Gender</p>
                  <p class="userprofile-details-right">Male</p>
                </li>
                <li class="userprofile-details-li">
                  <p class="userprofile-details-left">City</p>
                  <p class="userprofile-details-right">Hyderabad</p>
                </li>
                <li class="userprofile-details-li">
                  <p class="userprofile-details-left">Zip Code</p>
                  <p class="userprofile-details-right">500032</p>
                </li> -->
                <li class="userprofile-details-li">
                  <p class="userprofile-details-left">Contact No.</p>
                  <p class="userprofile-details-right">{{profieData?.countryCode}}-{{profieData?.mobile}} <i
                      class="far fa-edit infoContactEdit" data-bs-toggle="modal"
                      data-bs-target="#staticBackdropMobileOtp"></i></p>
                </li>
                <li class="userprofile-details-li">
                  <p class="userprofile-details-left">Email ID</p>
                  <p class="userprofile-details-right">{{profieData.emailId}}</p>
                </li>
              </ul>

            </div>
            <button class="button-data" data-bs-toggle="modal" (click)="getInfo()" data-bs-target="#exampleMainModal"><i
                class="far fa-edit"></i></button>



            <div class="userprofile-traveldata">
              <ul class="person-details">
                <li class="person-details-inside">
                  <p class="person-inside-heading">Saved Traveller(s)</p>
                  <!-- <p class="person-inside-content">You have {{userProfileList?.length}} Traveller(s)</p> -->
                </li>
                <li class="person-details-inside">
                  <p class="person-details-edit" (click)="clearProfile()" data-bs-toggle="modal"
                    data-bs-target="#staticBackdropNewDesign">Add
                    Traveller</p>
                </li>
              </ul>


            </div>
            <p *ngIf="userProfileList.length<= 0" class="noTravellers">No travellers</p>
            <div *ngFor="let item of userProfileList" class="userprofile-travelers-list">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <img class="traveler-image" src="./../../../assets/images/bookings/localProfile.png" alt="no-data">
                </div>
                <div class="flex-grow-1 tarveller-para ms-3">
                  {{item.FirstName}} </div>
              </div>
              <button data-bs-toggle="modal" data-bs-target="#exampleEditModal" (click)="getUpdateProfile(item)"
                class="button-view">View</button>
            </div>

          </div>




        </div>
        <div class="col-md-9">
          <div class="userprofile-right">
            <div class="userprofile-logindata">
              <div class="row mb-4">
                <div class="col-md-6">
                  <div *ngIf="myFlightBooking" class="flightCard">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Latest Flight Booking</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <ul class="textSpredFLight">
                      <li>
                        <h1 class="startFrom">{{myFlightBooking?.origin}} <i class="fas fa-arrow-right"></i>
                          {{myFlightBooking?.destination}}</h1>
                      </li>
                      <!-- <li><h1 class="wayOfJou">One Way Flight</h1></li> -->
                    </ul>
                    <p class="BookingId">PNR- {{myFlightBooking?.result?.Itinerary?.PNR}}</p>
                    <ul class="formDetails">
                      <li>
                        <p class="fromTxt">Travel Date : {{myFlightBooking?.result?.Itinerary?.TravelDate | date:'
                          dd/MM/yyyy hh:mm'}}</p>
                        <!-- <p class="deptTmings"> </p> -->
                        <p class="flightDetails"> <img class="imgIcon"
                            src="./../../../assets/images/bookings/flight.svg"
                            alt="no-image">{{myFlightBooking?.result?.Itinerary?.Segments[0]?.AirlineName}}
                          {{myFlightBooking?.result?.Itinerary?.Segments[0]?.FlightNumber}}</p>
                      </li>

                    </ul>
                    <ul class="textSpred">
                      <li></li>
                      <li>
                        <h1 style="margin: 0;" class="allBookings" (click)="getStatuss()">View Details</h1>
                      </li>
                    </ul>
                  </div>
                  <div class="flightCard" style="min-height: 100%;" *ngIf="!myFlightBooking">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Flight Bookings</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <p style="text-align: center;">
                      <img width="75px" style="position: relative; top: 44px;"
                        src="./../../../assets/images/bookings/noBookings.png" alt="">
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div *ngIf="visaBookings" class="flightCard">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Latest Visa Booking</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <ul class="textSpredFLight">
                      <li>
                        <h1 class="startFrom">{{visaBookings.appledFor}} </h1>
                      </li>
                    </ul>
                    <p class="BookingId">Booking ID - {{visaBookings.refId}}</p>
                    <ul class="formDetails">
                      <li>
                        <p class="fromTxt">Travel Date:
                          {{visaBookings.visaUserDetails?.generalDetails?.travelDate}}/{{visaBookings.visaUserDetails?.generalDetails?.travelMonth}}/{{visaBookings.visaUserDetails?.generalDetails?.travelYear}}
                        </p>
                        <!-- <p class="deptTmings"></p> -->
                        <p class="flightDetails">Travelling To : {{visaBookings.destinationCountry}} </p>
                      </li>
                      <!-- <li>
                        <p class="fromTxt">To Country</p>
                        <p class="deptTmings">{{visaBookings.destinationCountry}}</p>
                        <p class="flightDetails"> <img class="imgIcon" src="./../../../assets/images/bookings/booking-1.png" alt="no-image">{{visaBookings.visaUserDetails?.personalDetails?.firstname}}</p>
                      </li> -->
                    </ul>
                    <ul class="textSpred">
                      <li></li>
                      <li>
                        <h1 style="margin: 0;" class="allBookings" (click)="visaBookingVIew()">View Details</h1>
                      </li>
                    </ul>
                  </div>

                  <div *ngIf="!visaBookings" class="flightCard" style="min-height: 100%;">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Visa Bookings</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <p style="text-align: center;">
                      <img width="75px" style="position: relative; top: 44px;"
                        src="./../../../assets/images/bookings/noBookings.png" alt="">
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div *ngIf="packageBookingDetails && packageBookings" class="flightCard">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Latest Package Booking</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <ul class="textSpredFLight">
                      <li>
                        <h1 class="startFrom">{{packageBookingDetails?.title}}</h1>
                      </li>
                    </ul>
                    <p class="BookingId">Booking ID - {{packageBookings?.refId}}</p>
                    <ul class="formDetails">
                      <li>
                        <p class="fromTxt">Package Date : {{packageBookings?.packageStartDate}}</p>
                        <p class="deptTmings">Country : {{packageBookingDetails?.countryName}}</p>
                      </li>
                      <!-- <li>
                        <p class="fromTxt">Country : {{packageBookingDetails?.countryName}}</p>
                        <p class="deptTmings">{{packageBookingDetails?.countryName}}</p>
                        <p class="flightDetails"> <img class="imgIcon" src="./../../../assets/images/bookings/booking-1.png" alt="no-image">{{packageBookings?.packagesUserDetails[0]?.personalDetails?.firstname}}</p>
                      </li> -->
                    </ul>
                    <ul class="textSpred">
                      <li></li>
                      <li>
                        <h1 style="margin: 0;" class="allBookings" (click)="packageBookingVIew()">View Details</h1>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="!packageBookingDetails && !packageBookings" class="flightCard" style="min-height: 100%;">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Package Bookings</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <p style="text-align: center;">
                      <img width="75px" style="position: relative; top: 44px;"
                        src="./../../../assets/images/bookings/noBookings.png" alt="">
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="flightCard" style="min-height: 100%;">
                    <ul class="textSpred">
                      <li>
                        <h1 class="flightBook">Latest Hotel Booking</h1>
                      </li>
                      <li>
                        <h1 class="allBookings" routerLink="/bookings">View All Bookings</h1>
                      </li>
                    </ul>
                    <div>
                      <p style="text-align: center;">
                        <img width="75px" style="position: relative; top: 44px;"
                          src="./../../../assets/images/bookings/noBookings.png" alt="">
                      </p>
                    </div>
                  </div>
                  <!-- <div class="flightCard">
                      <ul class="textSpred">
                        <li><h1 class="flightBook">Hotel Bookings</h1></li>
                        <li><h1 class="allBookings">View All Bookings</h1></li>
                      </ul>
                     <ul class="textSpredFLight">
                      <li><h1 class="startFrom">Jaipur <span>(Radisson Blu)</span></h1></li>
                     </ul>
                     <p class="BookingId">Booking ID - NF7QZD7</p>
                     <ul class="formDetails">
                      <li>
                        <p class="fromTxt">Check In</p>
                        <p class="deptTmings">10 July 2023 05:10 PM</p>
                        <p class="flightDetails">  2 Adult 1 Room</p>
                      </li>
                      <li>
                        <p class="fromTxt">Check Out</p>
                        <p class="deptTmings">10 July 2023 05:10 PM</p>
                        <p class="flightDetails"> <img class="imgIcon" src="./../../../assets/images/bookings/booking-1.png" alt="no-image">Murali</p>
                      </li>
                     </ul>
                     <ul class="textSpred">
                      <li></li>
                      <li><h1 style="margin: 0;" class="allBookings">View Details</h1></li>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>



          </div>

        </div>
      </div>
    </div>

  </div>

  <!-- <div class="booking-data">
   
    <div class="container">
      <div>
        <div  class="upcoming-flightdata">
          <div >
          <ul class="flight-toptitle">
            <li class="left-flightdatatitle">
              Flight Bookings
            </li>
            <li>
              <button class="viewallbookings-btn" routerLink="/bookings">View All Bookings</button>
            </li>
          </ul>
          <div  *ngFor="let data of myFlightBooking">

      
          <div  class="upcoming-trips">
           

            <div class="book-images">
              <img class="book-images-inside" src="./../../../assets/images/bookings/book-1.png" alt="no-data">
            </div>
            <img class="book-img-inside" src="./../../../assets/images/bookings/book-1.png" alt="no-data">

            <ul class="way-to-journey">
              <li>
                <p class="way-to-place">{{data?.result?.Itinerary?.Origin}} <img class="arrow-img"
                    src="./../../../assets/images/bookings/right-arrow.png" alt="no-data">{{data?.result?.Itinerary?.Destination}} </p>
                <p class="way-to-content">
                  Completed<span
                    class="way-last-para">Booking ID - {{data?.result?.Itinerary?.BookingId}}</span>
                </p>

              </li>
              <li>
                <button class="view-info"  (click)="getStatus(data)" type="button">VIEW BOOKING</button>
              </li>
            </ul>
            <div>

            </div>

          </div>
          <div *ngFor="let segments of data?.result?.Itinerary?.Segments ;let i = index" class="upcoming-trips-bottom">
            <div class="upcoming-bottom-left">
              <div class="upcoming-left-inside">
                <p class="july-content">From</p>
                <p class="july-date-content">{{segments?.DepartureTime |date:'mediumDate'}}</p>
                <p class="july-place-content">{{segments?.Origin?.CityName}} </p>
              </div>

              <div class="upcoming-left-inside">
                <p class="july-content">To</p>
                <p class="july-date-content">{{segments?.ArrivalTime |date:'mediumDate'}}</p>
                <p class="july-place-content">{{segments?.Destination?.CityName}}</p>
              </div>

              <div class="upcoming-left-inside">
                <p class="july-date-content air-asia-con"><img class="booking-icons"
                    src="./../../../assets/images/bookings/booking-2.png" alt="no-data">{{segments?.AirlineName}} {{segments?.Airline}}
                    {{segments?.FlightNumber}}
                </p>
                <p *ngFor="let passin of data.result?.Itinerary?.Passenger" class="july-place-content"><img class="booking-icons"
                    src="./../../../assets/images/bookings/booking-1.png" alt="no-data">{{passin?.FirstName}} {{passin?.LastName}}</p>
              </div>

            </div>

          </div>
        </div>
        </div>
      </div>
      </div>
    </div>

  </div> -->

</div>




















<!-- Modal -->
<div class="modal fade" id="exampleMainModal" data-bs-backdrop="static" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit profile</h5>
        <button type="button" (click)="closeModels()" #closePops class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="right-profileData">
          <div class="profile-updaate-box">

            <h2 class="profile-DetailTitle">Profile Details</h2>
            <div class="info-boxs">

              <img *ngIf="!imgProfile" class="img-prof" src="./../../../assets/images/dummyPic.avif" />
              <img *ngIf="imgProfile" class="img-prof" [src]="imgProfile" />
              <div *ngIf="isUploaded" class="spinner-border sspinner" role="status">
              </div>
              <span (click)="proUpload()" class="penciss"><i class="fas fa-pencil-alt"></i></span>
            </div>
            <!-- <p class="basicC-info">
              Basic info, for a faster booking experience
            </p> -->
            <form [formGroup]="updatesProfile">
              <div class="inp-boxesBelow mb-3">
                <label for="exampleFormControlInput1" class="form-label nameLabel-profile">First Name</label>
                <input formControlName="firstname" type="text" class="form-control inside-InptBox profileAddName"
                  id="exampleFormControlInput1" placeholder="First Name">
              </div>
              <div class="inp-boxesBelow  mb-3">
                <label for="exampleFormControlInput2" class="form-label nameLabel-profile">Last Name</label>
                <input formControlName="lastname" type="text" class="form-control inside-InptBox profileAddName
        
        " id="exampleFormControlInput2" placeholder="Last Name">
              </div>
              <div *ngIf="loginType" class="inp-boxesBelow  mb-3">
                <label for="exampleFormControlInput2" class="form-label nameLabel-profile">Mobile Country code</label>
                <select formControlName="countryCode" (change)="selectCountryCode($event)" class="form-select inside-InptBox profileAddName
                " aria-label="Default select example">
                  <option disabled selected>Select countryCode</option>
                  <option *ngFor="let item of allMobileFlags" [value]="item.callingCode">{{item.callingCode}}
                    {{item.name}}</option>

                </select>

              </div>

              <div *ngIf="loginType" class="inp-boxesBelow  mb-3">
                <label for="exampleFormControlInput2" class="form-label nameLabel-profile">Mobile number</label>
                <input formControlName="mobile" type="text" class="form-control inside-InptBox profileAddName
        
        " id="exampleFormControlInput2" placeholder="Mobile number">
              </div>


              <div class="inp-boxesBelow  mb-3">
                <label for="exampleFormControlInput2" class="form-label nameLabel-profile">Email</label>
                <input formControlName="emailId" type="text" class="form-control inside-InptBox profileAddName
        
        " id="exampleFormControlInput2" placeholder="Email id">
              </div>


              <div class="uppdateBtn-below"><button (click)=" edit_Profile()" type="submit"
                  class="updatee-Btn submitBtn">Update</button></div>

            </form>


          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<mat-menu #menu="matMenu" xPosition="before">
  <button (click)="proUpload()" mat-menu-item>Upload Profile</button>
  <button (click)="getInfo()" data-bs-toggle="modal" data-bs-target="#exampleMainModal" mat-menu-item>Edit
    Profile</button>

  <!-- <button mat-menu-item>Item 2</button> -->
</mat-menu>

<input #openInput (change)="selectFile($event)" accept=".png, .jpg, .jpeg" class="FileUpload1" id="FileInput"
  name="booking_attachment" type="file" />












<!-- Add Traveller new-->

<div class="modal fade" id="staticBackdropNewDesign" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content  mainAddtravellerPop">
      <div class="modal-header addTravellerPop">
        <h3 class="add-title" id="staticBackdropLabel">Add Traveller </h3>
        <button type="button" class="btn-close closed-button" #closeModal data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="get-in-touch">
          <label for="" class="type-select">Select Type</label>
          <div class="adult-button">
            <div class="btn-group adult-group-buttons" role="group" aria-label="Basic example">
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '1'"
                (click)="selectButton('1')">Adult</button>
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '2'"
                (click)="selectButton('2')">Children</button>
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '3'"
                (click)="selectButton('3')">Infant</button>
            </div>
          </div>

          <form [formGroup]="addProfile" (ngSubmit)="submitProfile()">
            <div class="get-in-touch-form">
              <div class="form-names">
                <div class="row">
                  <div class=" col-md-2">
                    <label class="type-select">Type</label>
                    <div class="registration-select">
                      <select (change)="selectAdulst($event)" class="form-select selectOptions">
                        <option selected value="Mr" class="select-options">Mr</option>
                        <option value="Mrs" class="select-options">Mrs</option>
                        <option value="Ms" class="select-options">Ms</option>

                      </select>
                    </div>

                  </div>
                  <div class=" col-md-5">
                    <label for="exampleInputEmail1" class="form-firstname">Firstname</label>
                    <input type="text" formControlName="FirstName" class="firstname" placeholder="Firstname" required>
                    <div class="error-container">
                      <div class="error-message" *ngIf="addProfile.get('FirstName')?.invalid && (addProfile.get('FirstName')?.touched ||
                    addProfile.get('FirstName')?.dirty)">
                        <i class="fas fa-info-circle error-icon"></i>
                        Firstname is Required
                      </div>
                    </div>
                  </div>
                  <div class=" col-md-5">
                    <label for="exampleInputEmail1" class="form-firstname">Lastname</label>
                    <input type="text" formControlName="LastName" class="firstname" placeholder=" Lastname" required>
                    <div class="error-container">
                      <div class="error-message" *ngIf="addProfile.get('LastName')?.invalid && (addProfile.get('LastName')?.touched ||
                    addProfile.get('LastName')?.dirty)">
                        <i class="fas fa-info-circle error-icon"></i>
                        LastName is Required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label for="exampleInputEmail1" class="form-contact">Contact No.</label>
              <div style="margin-bottom: 25px;">
                <div class="registration-flag">
                  <div class="registration-number">
                    <div class="dropdown dropdown">
                      <button class="dropdown-toggle dropdown-flag-button" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img [src]="selectedFlag" alt="" class="registration-img" />

                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="dropdown-flag-list">
                          <div class="dropdowninsideimage">
                            <i class="fas fa-search registration-img"></i>
                            <input type="text" (keyup)="Inssearch($event)" class="form-control"
                              placeholder="Select Country Name" id="" aria-describedby="Numberhelp">
                          </div>
                        </li>
                        <li *ngFor="let item of allMobileFlags" (click)="selectedCountry(item)"
                          class="dropdown-flag-list">
                          <a class="dropdown-item">
                            <img *ngIf="item.type === 'base'" class="registration-image"
                              [src]="'data:image/png;base64,' + item.flag">
                            <img *ngIf="item.type === 'img'" class="registration-image" [src]="item?.flag">

                            <span class="flag-span">{{item?.name}} {{item?.callingCode}}</span>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <input type="number" class="form-control-input" formControlName="mobileNumber"
                    placeholder="Enter Mobile Number" id="" aria-describedby="Numberhelp" required>


                </div>
                <div class="error-container">
                  <div class="error-message" *ngIf="addProfile.get('mobileNumber')?.invalid && (addProfile.get('mobileNumber')?.touched ||
                addProfile.get('mobileNumber')?.dirty)">
                    <i class="fas fa-info-circle error-icon"></i>
                    Mobile number is required
                  </div>
                </div>
              </div>
              <div class="form-email">
                <label for="exampleInputEmail1" class="email-label">Email ID</label>
                <input type="text" formControlName="Email" placeholder="info@gmail.com" class="form-email-name"
                  required />
                <div class="error-container">
                  <div class="error-message" *ngIf="addProfile.get('Email')?.invalid && (addProfile.get('Email')?.touched ||
                addProfile.get('Email')?.dirty)">
                    <i class="fas fa-info-circle error-icon"></i>
                    Email required
                  </div>
                </div>

              </div>
              <div class="traveller-button">
                <button type="submit" class="messagebutton">Add Traveller</button>
              </div>

            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>



<!-- Edit Traveller new-->

<div class="modal fade" id="exampleEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content  mainAddtravellerPop">
      <div class="modal-header addTravellerPop">
        <h3 class="add-title" id="staticBackdropLabel">Edit Traveller </h3>
        <button type="button" class="btn-close closed-button" #closeModal2 data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="get-in-touch">
          <label for="" class="type-select">Select Type</label>
          <div class="adult-button">
            <div class="btn-group adult-group-buttons" role="group" aria-label="Basic example">
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '1'"
                (click)="selectButton('1')">Adult</button>
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '2'"
                (click)="selectButton('2')">Children</button>
              <button type="button" class="btn Select-adult-button" [class.active]="selectedButton === '3'"
                (click)="selectButton('3')">Infant</button>
            </div>
          </div>

          <form [formGroup]="addProfile" (ngSubmit)="updateProfiles()">
            <div class="get-in-touch-form">
              <div class="form-names">
                <div class="row">
                  <div class=" col-md-2">
                    <label class="type-select">Type</label>
                    <div class="registration-select">
                      <select (change)="selectAdulst($event)" class="form-select selectOptions">
                        <option selected value="Mr" class="select-options">Mr</option>
                        <option value="Mrs" class="select-options">Mrs</option>
                        <option value="Ms" class="select-options">Ms</option>

                      </select>
                    </div>

                  </div>
                  <div class=" col-md-5">
                    <label for="exampleInputEmail1" class="form-firstname">Firstname</label>
                    <input type="text" formControlName="FirstName" class="firstname" placeholder="Firstname" required>
                    <div class="error-container">
                      <div class="error-message" *ngIf="addProfile.get('FirstName')?.invalid && (addProfile.get('FirstName')?.touched ||
                    addProfile.get('FirstName')?.dirty)">
                        <i class="fas fa-info-circle error-icon"></i>
                        Firstname is Required
                      </div>
                    </div>
                  </div>
                  <div class=" col-md-5">
                    <label for="exampleInputEmail1" class="form-firstname">Lastname</label>
                    <input type="text" formControlName="LastName" class="firstname" placeholder=" Lastname" required>
                    <div class="error-container">
                      <div class="error-message" *ngIf="addProfile.get('LastName')?.invalid && (addProfile.get('LastName')?.touched ||
                    addProfile.get('LastName')?.dirty)">
                        <i class="fas fa-info-circle error-icon"></i>
                        LastName is Required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <label for="exampleInputEmail1" class="form-contact">Contact No.</label>
              <div style="margin-bottom: 25px;">
                <div class="registration-flag">
                  <div class="registration-number">
                    <div class="dropdown dropdown">
                      <button class="dropdown-toggle dropdown-flag-button" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img [src]="selectedFlag" alt="" class="registration-img" />

                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li class="dropdown-flag-list">
                          <div class="dropdowninsideimage">
                            <i class="fas fa-search registration-img"></i>
                            <input type="text" (keyup)="Inssearch($event)" class="form-control"
                              placeholder="Select Country Name" id="" aria-describedby="Numberhelp">
                          </div>
                        </li>
                        <li *ngFor="let item of allMobileFlags" (click)="selectedCountry(item)"
                          class="dropdown-flag-list">
                          <a class="dropdown-item">
                            <img *ngIf="item.type === 'base'" class="registration-image"
                              [src]="'data:image/png;base64,' + item.flag">
                            <img *ngIf="item.type === 'img'" class="registration-image" [src]="item?.flag">

                            <span class="flag-span">{{item?.name}} {{item?.callingCode}}</span>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <input type="number" class="form-control-input" formControlName="mobileNumber"
                    placeholder="Enter Mobile Number" id="" aria-describedby="Numberhelp" required>


                </div>
                <div class="error-container">
                  <div class="error-message" *ngIf="addProfile.get('mobileNumber')?.invalid && (addProfile.get('mobileNumber')?.touched ||
                addProfile.get('mobileNumber')?.dirty)">
                    <i class="fas fa-info-circle error-icon"></i>
                    Mobile number is required
                  </div>
                </div>
              </div>
              <div class="form-email">
                <label for="exampleInputEmail1" class="email-label">Email ID</label>
                <input type="text" formControlName="Email" placeholder="info@gmail.com" class="form-email-name"
                  required />
                <div class="error-container">
                  <div class="error-message" *ngIf="addProfile.get('Email')?.invalid && (addProfile.get('Email')?.touched ||
                addProfile.get('Email')?.dirty)">
                    <i class="fas fa-info-circle error-icon"></i>
                    Email required
                  </div>
                </div>

              </div>
              <div class="traveller-button">
                <button type="submit" class="messagebutton">Update Traveller</button>
                <button type="button" (click)="deleteProfile()" class="message-button">Delete Traveller</button>
              </div>

            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>





<!-- Modal -->
<div class="modal fade" id="staticBackdropMobileOtp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content editContactNo">
      <div class="modal-header">
        <h5 class="modal-title contatEit" id="staticBackdropLabel">Edit Contact no.</h5>
        <button type="button" class="btn-close closeEndC" (click)="cancelUpdateMobile()" #closeNewNumber data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="mobleNoChangeStatus === 0" class="exitsitnNumber">
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label exitTxt">Your Existing contact number</label>
              <div class="contactNoExit">
                <img class="imgFlh" [src]="mobileFlag">
                <label class="mobileNoFor">{{profieData?.mobile}}</label>
              </div>
            </div>

            <button type="submit" (click)="sendRequestForMobileNoChange()" class=" getOtpBtnForOld">Send OTP</button>
          </form>
        </div>
        <div *ngIf="mobleNoChangeStatus === 1" class="firstOtp">
          <form>
            <label for="exampleInputEmail1" class="form-label exitTxt">Please Enter the 6-digit OTP sent to {{profieData?.mobile}}</label>

            <div class="allGroupText">
              <input class="otpBoxs" #selectFirstOTP type="text" maxlength="1" [(ngModel)]="otp[0]" (keyup)="onKeyUp($event, 0)" #otp0>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[1]" (keyup)="onKeyUp($event, 1)" #otp1>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[2]" (keyup)="onKeyUp($event, 2)" #otp2>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[3]" (keyup)="onKeyUp($event, 3)" #otp3>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[4]" (keyup)="onKeyUp($event, 4)" #otp4>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[5]" (keyup)="onKeyUp($event, 5)" #otp5>
            </div>
            <button (click)="verifyOtpFOrMobile()" type="submit" class=" getOtpBtnForOld">Next</button>
          </form>
        </div>
        <div *ngIf="mobleNoChangeStatus === 2"  class="newNumberForUser">
          <div class="mb-3">
            <h5 class="modal-title contatEit" id="staticBackdropLabel">Add Contact No.</h5>

          <div class="registration-flag">
            <div class="registration-number">
              <div class="dropdown dropdown">
                <button class="dropdown-toggle dropdown-flag-button" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <img [src]="selectedFlag" alt="" class="registration-img" />

                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li class="dropdown-flag-list">
                    <div class="dropdowninsideimage">
                      <i class="fas fa-search registration-img"></i>
                      <input type="text" (keyup)="Inssearch($event)" class="form-control"
                        placeholder="Select Country Name" id="" aria-describedby="Numberhelp">
                    </div>
                  </li>
                  <li *ngFor="let item of allMobileFlags" (click)="selectedCountry(item)"
                    class="dropdown-flag-list">
                    <a class="dropdown-item">
                      <img *ngIf="item.type === 'base'" class="registration-image"
                        [src]="'data:image/png;base64,' + item.flag">
                      <img *ngIf="item.type === 'img'" class="registration-image" [src]="item?.flag">

                      <span class="flag-span">{{item?.name}} {{item?.callingCode}}</span>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
            <input type="number" #inputSelects class="form-control-input" [(ngModel)]="newNumber" 
              placeholder="Enter Mobile Number" required>


          </div>

          <button type="submit" (click)="getNewNumberForOTP()"  class=" getOtpBtnForOld">Send OTP</button>

        </div>
        </div>
        <div *ngIf="mobleNoChangeStatus === 3" class="firstOtp">
          <form>
            <label for="exampleInputEmail1" class="form-label exitTxt">Please Enter the 6-digit OTP sent to New number</label>

            <div class="allGroupText">
              <input class="otpBoxs" #selectSecondOTP type="text" maxlength="1" [(ngModel)]="otp[0]" (keyup)="onKeyUp($event, 0)" #otp0>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[1]" (keyup)="onKeyUp($event, 1)" #otp1>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[2]" (keyup)="onKeyUp($event, 2)" #otp2>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[3]" (keyup)="onKeyUp($event, 3)" #otp3>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[4]" (keyup)="onKeyUp($event, 4)" #otp4>
              <input class="otpBoxs" type="text" maxlength="1" [(ngModel)]="otp[5]" (keyup)="onKeyUp($event, 5)" #otp5>
            </div>
            <button (click)="verifyOtpFOrNewMobile()" type="submit" class=" getOtpBtnForOld">Next</button>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>