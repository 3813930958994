import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO } from 'ngx-intl-tel-input';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates];
  private myBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Initial value');
  loginOtp: FormGroup;
  currencyList:any = []
  selectedCurrency: string;
  url2: string = `${environment.baseURL2}api/loginWithGoogle`;
  isDetailsRequired: boolean = true
  isDetailsbox: any
  enterMobile: string
  errorMessage:any
  callingCode: string = '971'
  searchText: string
  
  isDropDOwnIs: boolean = false
  countryIsSelected: boolean = false
  selectdImg: string = ''
  @ViewChild('CloseModels') CloseModels!: ElementRef;
  @ViewChild('tryToOpenLogin') tryToOpenLogin!: ElementRef;
  loginform: FormGroup;
  americaCountry: any = []
  allMobileFlags: any = []
  closeResendButton: boolean = false
  intervalData: any
  timeDuration: any
  allAccess: any;
  AsiaCountry: any = []
  CISCOuntry: any = []
  MiddleEast: any = []
  Oceania: any = []
  Europe: any = []
  @ViewChild('myInput') inputElement: ElementRef;
  @ViewChild('numberFocus') numberFocus: ElementRef;
  isCommingSoon: boolean = true
  isCommingSoon2: boolean = true
  isCommingSoon3: boolean = true
  flagImage: any;
  langText: any;
  asianList: any
  isLogin: boolean = false
  balance: number
  requestForOtp: boolean = false
  currency: any
  profileImg: any
  @ViewChild('close') close: ElementRef;
  language = [
    { id: 1, lang: 'English', img: './../../../../assets/images/country/usa.png' },
    { id: 2, lang: 'Hindi', img: './../../../../assets/images/country/usa.png' },
    { id: 3, lang: 'Germany', img: './../../../../assets/images/country/usa.png' },
    { id: 4, lang: 'Arabic', img: './../../../../assets/images/country/usa.png' }
  ]
  constructor(private service: AuthService, private router: Router) {
    
    this.selectedCurrency = localStorage.getItem('selectedCurrency') || 'AED';

    setInterval(()=>{
      this.getRefreshEachFiveMin(this.selectedCurrency)
    },50000)




   }

  async getRefreshEachFiveMin(selectedCurrency:any){
    let updatedPrice = await this.service.allCurrencyByCode(selectedCurrency).toPromise();
    localStorage.setItem('priceChange',JSON.stringify(updatedPrice))
   }

   async selectCurrency(currency: { symbol: string, currencyCode: string }) {
    debugger;
    this.selectedCurrency = currency.currencyCode;
    
    try {
        // Make the API call to get updated price
        let updatedPrice = await this.service.allCurrencyByCode(currency.currencyCode).toPromise();
        localStorage.setItem('priceChange',JSON.stringify(updatedPrice))
        // Update localStorage with selected currency
        localStorage.setItem('selectedCurrency', currency.currencyCode);
        this.service.getCurrency(currency.currencyCode)
        // You can perform further actions here using the updated price if needed
        
    } catch (error) {
        // Handle any errors that occur during the API call
        console.error("Error fetching updated price:", error);
    }
}


  autoFucos() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 100)
  }
  autoFucos2() {
    setTimeout(() => {
      this.numberFocus.nativeElement.focus();
    }, 100)
  }


  sendEmail() {
    const email = 'support@devotiontourism.com';
    const subject = 'Support Request';
    const body = 'Please help me with...';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  ngOnInit(): void {
    this.getipinfo()
    this.allCurrencyByCode()
    this.loginform = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      search: new FormControl('', [Validators.required]),

    })
    this.loginOtp = new FormGroup({
      otp: new FormControl('', [Validators.required]),
    })
    this.callAUto()
    this.profile()
    let token = {
      TokenId: localStorage.getItem('TokenID')
    }
    // this.service.GetAvailableBalance(token).subscribe(
    //   (data:any)=>{
    //     this.balance = data.data.Agency?.TotalAailableLimit
    //     this.currency = data.data.Agency.Currency

    //   }
    // )
    // this.getALlFlags()
    // this.HeaderFlag()

    this.expand()
    localStorage.setItem('flagImage', './../../../../assets/images/country/usa.png')
    localStorage.setItem('langText', 'English')

    this.flagImage = localStorage.getItem('flagImage')
    this.langText = localStorage.getItem('langText')

    let Access = this.service.isLoggedin()

    if (Access === true) {
      this.isLogin = true
    }
    else {
      this.isLogin = false
    }
    this.getCountryFlags()
    this.loginCheck()
    this.loginCheckGoogle()
  }

  setFlugIn() {
    localStorage.setItem('load', 'true')
  }


  closess() {
    this.close.nativeElement.click()
  }

  HeaderFlag() {
    this.service.headerFlags().subscribe(
      (data: any) => {
        data.forEach(element => {
          if (element.region === 'Asia') {
            this.asianList = data
          }
          else {
          }
        });

      }
    )
  }

  expand() {
    const id = document.getElementById('testing').getAttribute('aria-expanded')

  }

  makePhoneCall(phoneNumber:any) {
    window.location.href = `tel:${phoneNumber}`;
  }

  logOut() {
    this.service.logout()
    let Access = this.service.isLoggedin()
      this.requestForOtp = false
      this.isDetailsbox = false
      this.countryIsSelected = false
      this.callingCode = '971'


      this.loginform.reset()
      this.loginOtp.reset()
      let basic ={
        name:'aa'
      }
        this.service.Inssearch(basic).subscribe(
        (data: any) => {
          let allData = []
          
          for (const obj of data) {
            
            obj._id = null
            if (obj.flag.startsWith("http")) {
              obj.type = "img";
            } else {
              obj.type = "base";
              allData.push(obj)
            }
  
  
          }
     
          this.allMobileFlags = allData
  
  
        }
      )
    if (Access === true) {
      this.isLogin = true
    }
    else {
      this.isLogin = false
    }

    
  }

  callAUto() {
    this.service.castUser.subscribe(
      (data: any) => {

        if (data == 1) {
          let Access = this.service.isLoggedin()

          if (Access === true) {
            this.isLogin = true;
            this.profile()
          }
          else {
            this.isLogin = false
          }

        }
      }
    )
  }

  loginCheck() {
    this.service.loginCheck.subscribe(
      (data: any) => {
        if (data === 'PleaseLogin') {
          this.tryToOpenLogin.nativeElement.click()
        }
        if (data === 'loginDone') {
          let Access = this.service.isLoggedin()

          if (Access === true) {
            this.isLogin = true
  
          }
          else {
            this.isLogin = false
          }
        
        }
      }
    )
  }

  loginCheckGoogle() {
    this.service.loginCheckGoogle.subscribe(
      (data: any) => {
        if (data === 'loginDone') {
          let Access = this.service.isLoggedin()

          if (Access === true) {
            this.isLogin = true
            this.profile()
  
          }
          else {
            this.isLogin = false
          }
        }
      
      }
    )
  }

  onSubmit() {

    if (this.isDetailsRequired === true) {
      delete this.loginform.value.emailId
      delete this.loginform.value.lastname
      delete this.loginform.value.firstname
    }

    let loginObj = {
      mobile: this.enterMobile,
      countryCode: this.callingCode,
      emailId: this.loginform.value.emailId,
      firstname: this.loginform.value.firstname,
      lastname: this.loginform.value.lastname,
      requiredAll: this.isDetailsRequired
    }
    this.service.login(loginObj).subscribe(
      (data: any) => {
        // this.toastr.success(data.message)
        // this.service.success(data.message)
        this.requestForOtp = true;
        this.startTimer(60)
        this.closeResendButton = false
        this.errorMessage = undefined
        // this.router.navigate(['/login']);

      },
      (err) => {

        // this.service.error(err.error.message)
        this.errorMessage = err.error.message
        setTimeout(()=>{
          this.errorMessage = undefined
        },1000)
      }
    )

  }
  submit() {
    
    let data = {
      mobile: this.enterMobile,
      countryCode: this.callingCode,
      otp: this.loginOtp.controls.otp.value
    }
    this.service.validateLoginUser(data).subscribe(
      (data: any) => {
        // this.toastr.success(data.message)
        this.service.success(data.message)
        localStorage.setItem('token', data.token)
        // localStorage.setItem('token', data.token)
        localStorage.setItem('_id', data.id)
        this.CloseModels.nativeElement.click()
        this.requestForOtp = false
        this.errorMessage = undefined
        // this.router.navigateByUrl('/home')
        this.profile()
        let Access = this.service.isLoggedin()

        if (Access === true) {
          this.isLogin = true

        }
        else {
          this.isLogin = false
        }
      },
      (err) => {

        this.errorMessage = err.error.message
        setTimeout(()=>{
          this.errorMessage = undefined
        },1000)
      }
    )
  }


  profile() {
    this.service.getProfile().subscribe(
      (data: any) => {
        this.profileImg = data?.profileImage
      }
    )
  
  }

  // getALlFlags() {
  //   this.service.getCountries().subscribe(
  //     (data: any) => {
  //       this.americaCountry = []
  //       data.forEach((value) => {

  //         if (value.region == 'America') {
  //           this.americaCountry.push(value)
  //         }
  //         if (value.region == 'Asia') {
  //           this.AsiaCountry.push(value)
  //         }
  //         if (value.region == 'CIS') {
  //           this.CISCOuntry.push(value)
  //         }
  //         if (value.region == 'Europe') {
  //           this.Europe.push(value)
  //         }
  //         if (value.region == 'Middle East') {
  //           this.MiddleEast.push(value)
  //         }
  //         if (value.region == 'Oceania') {
  //           this.Oceania.push(value)
  //         }

  //       })
  //     }
  //   )
  // }

  selectCOuntry(item: any) {
    if (item.isActive === 'Enable') {
      this.router.navigate(['/visa-view', item._id])
    }
    else {

    }

  }


  startTimer(duration) {
    var timer = duration, minutes, seconds;
    this.intervalData = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.timeDuration = (minutes + ":" + seconds).toString()
      if (--timer < 0) {
        clearInterval(this.intervalData)
        this.closeResendButton = true
      }
    }, 1000);
  }


  searchCOde(event){
        let data ={
          name:event.target.value
        }
      this.service.Inssearch(data).subscribe(
        (data: any) => {
          let allData = []
          
          for (const obj of data) {
            
            obj._id = null
            if (obj.flag.startsWith("http")) {
              obj.type = "img";
            } else {
              obj.type = "base";
              allData.push(obj)
            }
  
  
          }
     
          this.allMobileFlags = allData
  
  
        }
      )

  }

  getCountryFlags() {
  
      // this.service.getCountryFlags().subscribe(
      //   (data: any) => {
      //     let allData = []
          
      //     for (const obj of data) {
            
      //       obj._id = null
      //       if (obj.flag.startsWith("http")) {
      //         obj.type = "img";
      //       } else {
      //         obj.type = "base";
      //         allData.push(obj)
      //       }
  
  
      //     }
     
      //     this.allMobileFlags = allData
  
  
      //   }
      // )
    
  
      this.service.Inssearch('').subscribe(
        (data: any) => {
          let allData = []
          
          for (const obj of data) {
            
            obj._id = null
            if (obj.flag.startsWith("http")) {
              obj.type = "img";
            } else {
              obj.type = "base";
              allData.push(obj)
            }
  
  
          }
     
          this.allMobileFlags = allData
  
  
        }
      )
  }

  getCountryID(data) {

    this.callingCode = data.callingCode
    this.selectdImg = data.flag
    this.countryIsSelected = true
    this.searchText = ''
    let info = {
      mobile: this.enterMobile,
      countryCode: data.callingCode
    }
    this.service.loginMobileCheck(info).subscribe(
      (data: any) => {
        this.isDetailsRequired = data.hasEmail
      }
    )
  }

  tryDiffNum() {
    this.requestForOtp = false
    clearInterval(this.intervalData)
  }

  getipinfo() {
    // this.service.getipinfo().subscribe(
    //   (data: any) => {

    //     let ipDetails = {
    //       ip: data.message
    //     }
    //     this.service.getAllIPDetails(ipDetails).subscribe(
    //       (data: any) => {

    //         localStorage.setItem("IPDetails", data?.message?.query)
    //       }
    //     )
    //   }
    // )
  }

  loginMobileCheck(event: any) {
    if (event.target.value.length > 4) {
      let info = {
        mobile: event.target.value,
        countryCode: this.callingCode
      }
      this.service.loginMobileCheck(info).subscribe(
        (data: any) => {
          this.isDetailsRequired = data.hasEmail
          if (data.hasEmail === true) {
            this.isDetailsbox = false
          }
        }
      )
    }

  }
  isPopData() {
    this.isDetailsbox = true
  }

  enterPakages() {
    this.isDropDOwnIs = true
  }
  leavePackages() {
    this.isDropDOwnIs = false
  }


  openVisaForm() {

    // this.router.navigateByUrl('/home')
    setTimeout(() => {
      this.service.tryVisa('openForm')
    }, 10)
  }

  allCurrencyByCode(){
    this.service.allCurrencies().subscribe(
      (data:any)=>{
        console.log(data)
        
        this.currencyList = data
      },
      (err)=>{
        console.log(err)
      }
    )
  }

  comingSooon(){
    this.router.navigateByUrl('/coming-soon')
  }
}
