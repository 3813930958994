import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-visa-view',
  templateUrl: './visa-view.component.html',
  styleUrls: ['./visa-view.component.css']
})
export class VisaViewComponent implements OnInit {
  countryId: string
  countryData: any
  cropImgPrevi: any
  contactList: any = []
  constructor(private service: AuthService, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) { }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.params.subscribe(
      (data: any) => {

        this.countryId = data.id
        this.getCountriesByID()
        this.getContact()
      }
    )
  }

  ngAfterViewInit() {
    const imageElements = this.el.nativeElement.querySelectorAll('img');

    imageElements.forEach((image) => {
      this.renderer.setStyle(image, 'width', '100%');
    });
  }

  getCountriesByID() {
    this.service.getCountriesByID(this.countryId).subscribe(
      (data: any) => {


        this.countryData = data[0]
        this.cropImgPrevi = data[0].backgroundImage
        const element = document.getElementById('visaPage-edit');
        const imageUrl = `url(${this.cropImgPrevi})`;
        // this.renderer.setStyle(element, 'background-image', imageUrl);
        this.renderer.setStyle(element, 'background-image', imageUrl);
        this.renderer.setStyle(element, 'background-size', '');
      }
    )
  }
  getContact() {

    this.service.getContact(this.countryId).subscribe(
      (data: any) => {

        this.contactList = []




        data.forEach(element => {



          if (element.name.length > 2) {
            this.contactList.push(element)
          }
          else {

          }
        });
      }
    )
  }

  makePhoneCall(phoneNumber: any) {
    // const phoneNumber = '+97144576077';
    window.location.href = `tel:${phoneNumber}`;
  }
}
