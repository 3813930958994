<div class="sliders-top">
    <div class="textBlock">
        <h1 class="googleHead">Video Testimonials</h1>
        <!-- <p>OUR DEVOTION IS ALL TOWARD PROVIDING OUR <br> CUSTOMERS WITH THE BEST TIME OF THEIR LIFE</p> -->
    </div>
    <!-- <div class="section-padding">
     
        <div class="swiper-container">
            <div class="swiper-wrapper" pagination="false">
                <div *ngFor="let item of allVideoFiles" class="swiper-slide" >
                    <video  >
                        <source [src]="item.link" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div> -->
    <!-- <owl-carousel-o class="sliderComponent" [options]="customOptions">
      <ng-template class="swiper-sliderNew" *ngFor="let item of allVideoFiles" carouselSlide>
        <div style="height: 300px !important;"> 
          <video class="videoData">
            <source class="video-inside" [src]="item.link" type="video/mp4" />
          </video>
        </div>
      </ng-template>    
    </owl-carousel-o> -->

    <!-- <div class="sliders-top">
      <div class="section-padding">
        <div id="screenshot_slider" class=" owl-carousel">
          <div  (click)="getVideoLink('./../../../assets/images/video/dubai.mp4')"  class="item" >
            <video >
              <source src="./../../../assets/images/video/dubai.mp4" type="video/mp4" />
            </video>
          </div>
          <div (click)="getVideoLink('./../../../assets/images/video/amz.mp4')" class="item">
            <video  >
              <source src="./../../../assets/images/video/amz.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="item" >
            <video (click)="getVideoLink('./../../../assets/images/video/burj.mp4')">
              <source src="./../../../assets/images/video/burj.mp4" type="video/mp4" />
            </video>
          </div>
          <div class="item" >
            <video (click)="getVideoLink('./../../../assets/images/video/lak.mp4')">
              <source src="./../../../assets/images/video/lak.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
<p #myDiv data-bs-toggle="modal" data-bs-target="#exampleModalVideois"></p>
   </div> -->
   <div class="owl-section">
 
    <owl-carousel-o class="sliderComponent" [options]="customOptions">
      <ng-template id="screenshot_slider" class="owl-carousel" *ngFor="let item of allVideoFiles" carouselSlide>
        <div (click)="getVideoLink(item.link)" class="item" >
          <img   [src]="item.img" alt="">
        </div>
      </ng-template>    
    </owl-carousel-o>
  </div>
</div>
  





<div class="modal fade" id="exampleModalVideois" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <div data-bs-dismiss="modal" class="CLoseBtn" (click)="playPauseVideo()"><img src="./../../../assets/images/icons-images/close-circle-svgrepo-com.png" alt=""></div>
        <video #myVideo controls [src]="videoLink" width="100%" height="100%"></video>
       </div>
    </div>
  </div>
</div>
<p #myDiv data-bs-toggle="modal" data-bs-target="#exampleModalVideois"></p>