import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-view-all-offers',
  templateUrl: './view-all-offers.component.html',
  styleUrls: ['./view-all-offers.component.css']
})
export class ViewAllOffersComponent implements OnInit{
  allOffers:any =[]
  allGlobalOffers:any =[]
  constructor(private service:AuthService, private router:Router){}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getOffers()
  }
  getOffers() {
    console.clear()
    this.allGlobalOffers= []
    this.service.allOffers().subscribe(
      (data: any) => {

        this.allOffers = data
        this.allOffers.forEach((value: any) => {
          if (value.position === 'global') {
            this.allGlobalOffers.push(value)
          }
          else{

          }
     
        });

      }
    )
  }

  navigate(id: any) {
    this.router.navigateByUrl(`/offerPreview/${id}`)
  }

}
