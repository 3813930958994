import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { packagesEnquiry } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css']
})
export class PackagesComponent implements OnInit {
  selectedCity: any = 'Delhi';
  allthePackages: any = []
  hotDeals: any = []
  localDeals: any = []
  globalDeals: any = []
  packagesEnquiryObj = new packagesEnquiry()
  @ViewChild('myInput') inputElement: ElementRef;
  minDate: any
  citynames: any = [
    { name: "Delhi" },
    { name: "Mumbai" },
    { name: "Bengalure" },
    { name: "Pune" },
    { name: "Goa" },
    { name: "Chennai" },
    { name: "Jaipur" },
    { name: "Hyderabad" },
    { name: "Singapore" },
  ]
  constructor(private service: AuthService , private router:Router) {

  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadScripts()
    this.getAllPackages()
  }

  getAllPackages() {
    this.service.getAllPackages().subscribe(
      (data: any) => {
        let hotDeals = []
        let localDeals = []
        let globalDeals = []
          
        this.allthePackages = data.sendingArray
        data.sendingArray.forEach((element: any) => {
          if (element.holidayType === '1') {
            hotDeals.push(element)
          }
          if (element.holidayType === '2') {
            localDeals.push(element)
          }
          if (element.holidayType === '3') {
            globalDeals.push(element)
          }
        })

        this.hotDeals = hotDeals
        this.localDeals = localDeals
        this.globalDeals = globalDeals
      }
    )
  }

  selectCitySearch(data: any) {

    this.citynames.filter((value) => {

      if (value.name === data.target.value) {
        this.citynames = value
      }
    })
  }
  selectedCityname(data: any) {
    this.selectedCity = data
    this.packagesEnquiryObj.PackageName = data
  }

  selectFliter() {

    setTimeout(() => {
      this.inputElement.nativeElement.focus()
    }, 100)

  }

  async submitData() {
    this.packagesEnquiryObj.packageDate = this.minDate
    if (!this.packagesEnquiryObj.AdultsCount) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.PackageName) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.packageDate) {
      this.service.error('All fieids are required')
      return
    }
    else {
      let access: any = this.service.isLoggedin()
      if (access) {
        try {
          let userSave: any = await this.service.Packagesenquiry(this.packagesEnquiryObj).toPromise()

          this.service.success(userSave.message)
          this.packagesEnquiryObj.AdultsCount = ''
          this.packagesEnquiryObj.PackageName = ''
          this.packagesEnquiryObj.packageDate = ''
          this.minDate = ''
        } catch (err) {
          this.service.error(err.error.message)
        }
      } else {
        // this.service.error('Please login')
        this.service.tryLogin('PleaseLogin')
      }
    }



  }


  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }


  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    margin: 6,
    autoWidth:false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-caret-right"></i>', '<i class="fas fa-caret-left"></i>'],

    center: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: false
  }
  international: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    margin: 6,
    autoWidth:false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fas fa-caret-right"></i>', '<i class="fas fa-caret-left"></i>'],

    center: false,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  }
routeTOView(data:any){
  this.router.navigateByUrl(`/packageView/${data.id}`)
}
}
