import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { CareerComponent } from './components/career/career.component';
import { ProfileComponent } from './components/profile/profile.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogViewComponent } from './components/blog-view/blog-view.component';
import { VisaViewComponent } from './components/visa-view/visa-view.component';
import { StaysComponent } from './components/stays/stays.component';
import { FlightsComponent } from './components/flights/flights.component';
import { PackagesComponent } from './components/packages/packages.component';
import { CarsComponent } from './components/cars/cars.component';
import { CruisesComponent } from './components/cruises/cruises.component';
import { ThingsComponent } from './components/things/things.component';
import { CorporateTravelsComponent } from './components/corporate-travels/corporate-travels.component';
import { ViewAllOffersComponent } from './components/view-all-offers/view-all-offers.component';
import { ViewLocalOffersComponent } from './components/view-local-offers/view-local-offers.component';
import { OfferPreviewComponent } from './components/offer-preview/offer-preview.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ViewFlightDataComponent } from './components/viewFlightData/view-flight-data/view-flight-data.component';
import { ReturnFlightDataComponent } from './components/viewFlightData/return-flight-data/return-flight-data.component';
import { FareQuoteComponent } from './components/viewFlightData/fare-quote/fare-quote.component';
import { TicketComponent } from './components/viewFlightData/ticket/ticket.component';
import { BookComponent } from './components/viewFlightData/book/book.component';
import { FlightReviewComponent } from './components/viewFlightData/flight-review/flight-review.component';
import { SummuryComponent } from './components/viewFlightData/summury/summury.component';
import { VerificationComponent } from './components/viewFlightData/verification/verification.component';
import { AuthGuard } from './auth/auth.guard';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MybookingsComponent } from './components/mybookings/mybookings.component';
import { ConfirmationComponent } from './components/viewFlightData/confirmation/confirmation.component';
import { PrivacyPolicyComponent } from './components/commonn/privacy-policy/privacy-policy.component';
import { CancelledComponent } from './components/cancelled/cancelled.component';
import { PackageViewComponent } from './components/package-view/package-view.component';
import { VisaOverviewComponent } from './components/visa-overview/visa-overview.component';
import { ApplyVisaComponent } from './components/apply-visa/apply-visa.component';
import { VisaConfirmationComponent } from './components/visa-confirmation/visa-confirmation.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PackageFiltersComponent } from './components/package-filters/package-filters.component';
import { PackageBookComponent } from './components/package-book/package-book.component';
import { PackageConfirmationComponent } from './components/package-confirmation/package-confirmation.component';
import { VisaBookingViewComponent } from './components/visa-booking-view/visa-booking-view.component';
import { AuthenticationComponentComponent } from './components/authentication-component/authentication-component.component';
import { PackageBookingViewComponent } from './components/package-booking-view/package-booking-view.component';
import { CorporatePackagesComponent } from './components/corporate-packages/corporate-packages.component';
import { GlobalPackagesComponent } from './components/global-packages/global-packages.component';
import { GlobalVisalistComponent } from './components/global-visalist/global-visalist.component';
import { GalleryComponent } from './components/gallery/gallery.component';

const routes: Routes = [
  { path: "", component: LandingPageComponent, pathMatch: "full" },
  // { path: "home", component: HomeComponent },
  { path: "", component: LandingPageComponent },
  { path: "login", component: LoginComponent },
  { path: "authentication/:id", component: AuthenticationComponentComponent },
  { path: "register", component: SignUpComponent },
  { path: "contactus", component: ContactUsComponent },
  { path: "aboutus", component: AboutUsComponent },
  { path: "carrer", component: CareerComponent },
  { path: "profile", component: ProfileComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog-post/:id", component: BlogViewComponent },
  { path: "Visaconfirmation", component: VisaConfirmationComponent },
  { path: "Packageconfirmation", component: PackageConfirmationComponent },
  { path: "visa-overview/:id", component: VisaOverviewComponent },
  { path: "applyVisa/:id", component: ApplyVisaComponent },
  { path: "packagesFilter/:id", component: PackageFiltersComponent },
  { path: "stays", component: StaysComponent },
  { path: "flights", component: FlightsComponent },
  { path: "packages", component: PackagesComponent },
  { path: "packageView/:id", component: PackageViewComponent },
  { path: "packageBook/:id", component: PackageBookComponent },
  { path: "cars", component: CarsComponent },
  { path: "cruises", component: CruisesComponent },
  { path: "things", component: ThingsComponent },
  { path: "corporate-travels", component: CorporateTravelsComponent },
  { path: "allOffers", component: ViewAllOffersComponent },
  { path: "viewLocalOffers", component: ViewLocalOffersComponent },
  { path: "offerPreview/:id", component: OfferPreviewComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  { path: "globalVisalist", component: GlobalVisalistComponent },
  { path: "Gallery", component: GalleryComponent },
  { path: "VIewFlightData/:data", component: ViewFlightDataComponent },
  { path: "corporatePackages", component: CorporatePackagesComponent },
  { path: "VIewReturnData/:data", component: ReturnFlightDataComponent },
  { path: "farQuote/:data", component: FareQuoteComponent, canActivate: [AuthGuard] },
  { path: "ticket/:data", component: TicketComponent, canActivate: [AuthGuard] },
  { path: "book/:data", component: BookComponent, canActivate: [AuthGuard] },
  { path: "flight-review/:data", component: FlightReviewComponent, canActivate: [AuthGuard] },
  { path: "summury/:data", component: SummuryComponent, canActivate: [AuthGuard] },
  { path: "verification/:data", component: VerificationComponent, canActivate: [AuthGuard] },
  { path: "userProfile", component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: "bookings", component: MybookingsComponent, canActivate: [AuthGuard] },
  { path: "confirmation", component: ConfirmationComponent, canActivate: [AuthGuard] },
  { path: "visaBookingVIew/:id", component: VisaBookingViewComponent, canActivate: [AuthGuard] },
  { path: "packageBookingVIew/:id", component: PackageBookingViewComponent, canActivate: [AuthGuard] },
  { path: "privacyPolicy", component: PrivacyPolicyComponent },
  { path: "cancel", component: CancelledComponent },
  { path: "**", component: LandingPageComponent },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled'
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
