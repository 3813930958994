import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { packagesEnquiry, packagesFilters, selectedVIsa } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {MatSliderModule} from '@angular/material/slider';
@Component({
  selector: 'app-package-filters',
  templateUrl: './package-filters.component.html',
  styleUrls: ['./package-filters.component.css']
})
export class PackageFiltersComponent {
  formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return `${value}`;
  }
  countryName: any = 'Dubai'
  packageCountry: any
  packageData: any
  selectedCurrency:any
  filterObject:any = []
  selectedVIsaObj = new selectedVIsa()
  packagesEnquiryObj = new packagesEnquiry()
  @ViewChild('myInput') inputElement: ElementRef;
  minDate: any
  @ViewChild('closeDate') closeDate: ElementRef;
  @ViewChild('inputFocus') inputFocus: ElementRef;

  packagesFiltersObj = new packagesFilters()
  selectedValues: string[] = [];
  selectedCities: string[] = [];
  selectedPrice: number;
  minPrice:number
  maxPrice:number
  selectedValue: number;
  minValue: number ;
  maxValue: number;
  constructor(private service: AuthService,  private datePipe: DatePipe,private router: Router, private route: ActivatedRoute) {
    this.selected = new Date()
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
  }
  
  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  ngOnInit(): void {
    this.route.params.subscribe(
      (data: any) => {
          
        this.packageCountry = data.id
        this.packagesFiltersObj.data = data.id
        let country =[]
        country.push(data.id)
        // this.packagesFiltersObj.targetCountries = country
        this.getPackageByCountry()
      }
    )
  
    window.scrollTo(0, 0);
    this.loadScripts()

      this.getAllNames()
  }

  getPackageByCountry(){
      let data ={
        data:this.packageCountry
      }
    this.service.searchPackage(data).subscribe(
      (responce:any)=>{
        debugger
        this.packageData = responce.data
        this.filterObject = responce.filters
        this.minValue = responce.filters.lowestNights
        this.selectedValue = responce.filters.lowestNights
        this.maxValue = responce.filters.highestNights
        this.maxPrice = responce.filters.highestPrice
        this.minPrice = responce.filters.lowestPrice
        this.selectedPrice = responce.filters.lowestPrice
      }  
    )
  }

  // getPackageByCountryFilters(){
  //   this.service.getPackageByCountryFilters(this.packageCountry).subscribe(
  //     (responce:any)=>{
  
  //     }  
  //   )
  // }

  


  selectFliter() {

    setTimeout(() => {
      this.inputElement.nativeElement.focus()
    }, 100)

  }



  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }


  routeTOView(data: any) {
    this.router.navigateByUrl(`/packageView/${data.id}`)
  }


  async submitData() {
    this.packagesEnquiryObj.packageDate = this.minDate
    if (!this.packagesEnquiryObj.AdultsCount) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.PackageName) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.packageDate) {
      this.service.error('All fieids are required')
      return
    }
    else {
      let access: any = this.service.isLoggedin()
      if (access) {
        try {
          let userSave: any = await this.service.Packagesenquiry(this.packagesEnquiryObj).toPromise()

          this.service.success(userSave.message)
          this.packagesEnquiryObj.AdultsCount = ''
          this.packagesEnquiryObj.PackageName = ''
          this.packagesEnquiryObj.packageDate = ''
          this.minDate = ''
        } catch (err) {
          this.service.error(err.error.message)
        }
      } else {
        // this.service.error('Please login')
        this.service.tryLogin('PleaseLogin')
      }
    }



  }
  onRangeChange() {
    this.packagesFiltersObj.nightsCount = this.selectedValue
    this.recordFIlters()
    // You can perform additional actions as needed
  }
  onRangePrice() {
    this.packagesFiltersObj.maxPrice = this.selectedPrice
    this.recordFIlters()
  }

  updateSelectedValues(value: string): void {

    if (this.selectedValues.includes(value)) {
      // If value exists, remove it
      this.selectedValues = this.selectedValues.filter(item => item !== value);
    } else {
      // If value doesn't exist, add it
      this.selectedValues.push(value);
    }

    this.packagesFiltersObj.recommendedFor = this.selectedValues
    this.recordFIlters()
  }


  updateSelectedValuesCity(value: string): void {

    if (this.selectedCities.includes(value)) {
      // If value exists, remove it
      this.selectedCities = this.selectedCities.filter(item => item !== value);
    } else {
      // If value doesn't exist, add it
      this.selectedCities.push(value);
    }

    this.packagesFiltersObj.cityTitle = this.selectedCities
    this.recordFIlters()
  }

  selectFlight(value:boolean){
    let isFLight = []
    isFLight.push(value)
    this.packagesFiltersObj.isFlightIncluded = isFLight
    this.recordFIlters()
  }

  recordFIlters(){
      
    this.service.recordFIlters( this.packagesFiltersObj).subscribe(
      (data:any)=>{
        
        this.packageData = data.filteredData

      }
    )
  }
getMoreDetails(items){
    
  this.router.navigateByUrl(`/packageView/${items.id}`)
}
selected: Date | null;
searchText:any

allCOuntryList:any = []
selectInout(){

}

gotoPackageByCOuntryName() {
    
  this.router.navigateByUrl(`/packagesFilter/${this.countryName}`)
  let dates = this.datePipe.transform(this.selected, 'dd-MM-yyyy');
  localStorage.setItem('selectdDate', JSON.stringify(dates))

}

onDropdownClick(event: Event): void {
  // Add your logic for preventing dropdown closure here
  event.stopPropagation();
}

getFormattedDate(): string {
  // Format the selected date
  return this.datePipe.transform(this.selected, 'dd MMMM yyyy');

}


getAllNames() {
  this.service.getAllNames().subscribe(
    (data: any) => {
      this.allCOuntryList = data
    }
  )
}
onDateSelected(event: any): void {
  this.closeDate.nativeElement.click()

  // this.selectedDate = event;
  // You can perform additional actions with the selected date if needed
}

selectCountryItem(data) {
  this.countryName = data.name
  this.searchText = ''
}
}
