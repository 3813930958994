import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent {
  privacys:boolean = true
  service:boolean = false
  cookies:boolean = false


  clickPrivacy(){
    this.privacys = true
    this.service = false
    this.cookies = false
  }
  clickService(){
    this.privacys = false
    this.service = true
    this.cookies = false
  }
  clickCookie(){
    this.privacys = false
    this.service = false
    this.cookies = true
  }
}
