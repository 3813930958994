import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  isStop:boolean =false
  isStop2:boolean =false
  constructor(private renderer: Renderer2){
    let currencies = localStorage.getItem('selectedCurrency')
    if(!currencies){
      localStorage.setItem('selectedCurrency','AED')
    }
  }
  ngOnInit(): void {
    const toTop = document.querySelector(".to-top");
    const element = document.getElementById('social');
    this.renderer.setStyle(element, 'opacity', '0');

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 700) {
        toTop.classList.add("active");
        this.isStop = true
        this.isStop2 = true
        this.renderer.setStyle(element, 'opacity', '1');
      } else {
        toTop.classList.remove("active");
        this.renderer.setStyle(element, 'opacity', '0');
        this.isStop = false
        this.isStop2 = false
       
      }
  
    })

  }
  title = 'devotiontourism';

  gotoTop(){
    // window.scrollTo(0, 0);
  }

  sendEmail(){
    const email = 'support@devotiontourism.com';
    const subject = 'Support Request';
    const body = 'Please help me with...';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  makePhoneCall() {
    const phoneNumber = '+97144576077';
    window.location.href = `tel:${phoneNumber}`;
  }
  openLinkedInPage() {
    window.open('https://www.linkedin.com/company/92558268/admin/', '_blank');
  }
  openFacebookPage() {
    window.open('https://www.facebook.com/profile.php?id=100091695764298', '_blank');
  }
  openYoutubePage() {
    window.open('https://www.youtube.com/@Devotiontravelandtourism', '_blank');
  }
  openPintrestPage() {
    window.open('https://pin.it/1O6HPbY', '_blank');
  }

  openWhatsApp() {
    // Replace '1234567890' with the desired phone number
    // and 'Hello' with the desired message
    const phoneNumber = '97144576077';
    const message = encodeURIComponent('Hello Devotion');
    
    // Create the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open the WhatsApp URL
    window.open(whatsappUrl, '_blank');
  }
}
