<div class="allPages">

<div  class="Imagesgallery">



    <div class="popularCards">
        <!-- <div>
            <video width="300px" height="300px" controls [src]="getCurrentVideo()" autoplay></video>
          </div>
          <div>
            <button (click)="previousVideo1()">Previous</button>
            <button (click)="nextVideo1()">Next</button>
          </div> -->
        <div class="container" style="margin-bottom: 40px;">
            <div class="row">
                <h3 class="headingTxt">Dubai</h3>
                <p class="photosTxt">Photos </p>
                <p class="noImtes" *ngIf="galleryItems?.length <= 0">No Local Gallery</p>
                <div *ngFor="let item of galleryItems" class="col-md-2" (click)="getImages(item)" >
                    <div *ngIf="item.isMoreImages ==true" class="moreImgs" >
                        <img class="iconSizes" src="./../../../assets/images/bookings/groupImgs2.png" alt="">
                    </div>
                    <img data-bs-toggle="modal" data-bs-target="#staticBackdrop" *ngIf="item?.galleryimages[0]?.type==='image'" class="imgeInfo"   [src]="item?.galleryimages[0]?.image" alt="No-Image">
                    <video data-bs-toggle="modal" data-bs-target="#staticBackdropVideos" style="width: 100%;" *ngIf="item?.galleryimages[0]?.type==='video'" [src]="item?.galleryimages[0]?.image"></video>
                    <p class="titlesImage">{{item.title}}</p>
                </div>

                <p class="photosTxt">Videos </p>
                <p class="noImtes" *ngIf="galleryVideosLocal?.length <= 0">No Local Videos</p>
                <div *ngFor="let item of galleryVideosLocal" class="col-md-2"  >
                    <div *ngIf="item.isMoreImages ==true" class="moreImgs" >
                        <img class="iconSizes" src="./../../../assets/images/bookings/groupImgs2.png" alt="">
                    </div>
                  <div class="playVideo">
                    <img data-bs-toggle="modal" data-bs-target="#staticBackdropVideos" class="playButns" src="./../../../assets/images/bookings/play-circle-svgrepo-com.svg" alt="no-image">

                    <img data-bs-toggle="modal" (click)="getVideos(item)" data-bs-target="#staticBackdropVideos"  class="imgeInfo"   [src]="item?.galleryimages[0]?.thumb" alt="No-Image">

                  </div>
                    <!-- <video data-bs-toggle="modal" data-bs-target="#staticBackdropVideos" style="width: 100%;" *ngIf="item?.galleryimages[0]?.type==='video'" [src]="item?.galleryimages[0]?.image"></video> -->
                    <p class="titlesImage">{{item.title}}</p>
                </div>

            </div>
        </div>
        <div class="container">
            <div class="row">
                <h3 class="headingTxt">Global gallery</h3>
                <p class="photosTxt">Photos </p>
                <p class="noImtes" *ngIf="galleryItemsGlobal?.length <= 0">No Global Gallery</p>
                <div *ngFor="let item of galleryItemsGlobal" class="col-md-2" (click)="getImages(item)" >
                    <div *ngIf="item.isMoreImages ==true" class="moreImgs" >
                        <img class="iconSizes" src="./../../../assets/images/bookings/groupImgs2.png" alt="">
                    </div>
                    <img data-bs-toggle="modal" data-bs-target="#staticBackdrop" *ngIf="item?.galleryimages[0]?.type==='image'" class="imgeInfo"   [src]="item?.galleryimages[0]?.image" alt="No-Image">
                    <video data-bs-toggle="modal" data-bs-target="#staticBackdropVideos" style="width: 100%;" *ngIf="item?.galleryimages[0]?.type==='video'" [src]="item?.galleryimages[0]?.image"></video>
                    <p class="titlesImage">{{item.title}}</p>
                </div>

                <p class="photosTxt">Videos </p>
                <p class="noImtes" *ngIf="galleryVideosGlobal?.length <= 0">No Global Videos</p>
                <div *ngFor="let item of galleryVideosGlobal" class="col-md-2" (click)="getVideos(item)" >
                    <div *ngIf="item.isMoreImages ==true" class="moreImgs" >
                        <img class="iconSizes" src="./../../../assets/images/bookings/groupImgs2.png" alt="">
                    </div>
                  <div class="playVideo">
                    <img class="playButns" src="./../../../assets/images/bookings/play-circle-svgrepo-com.svg" alt="no-image">

                    <img data-bs-toggle="modal" data-bs-target="#staticBackdropVideos"  class="imgeInfo"   [src]="item?.galleryimages[0]?.thumb" alt="No-Image">

                  </div>
                    <!-- <video data-bs-toggle="modal" data-bs-target="#staticBackdropVideos" style="width: 100%;" *ngIf="item?.galleryimages[0]?.type==='video'" [src]="item?.galleryimages[0]?.image"></video> -->
                    <p class="titlesImage">{{item.title}}</p>
                </div>

            </div>
        </div>
    </div>
    <!-- Button trigger modal -->



    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content popupSettings">
                <!-- <div class="modal-header" style="border: none;">
                    <h5 class="modal-title tiltes" id="staticBackdropLabel">Gallery View</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> -->
                <div class="modal-body fullGalleryView">
                    <div class="row">

                        <div class="gallery">
                        
                            <div class="main-image">
                                
                                <div (click)="previousImage()" class="letfBtn"> <i class="fas fa-chevron-left prev-icon" ></i> </div>
                                <div (click)="nextImage()" class="RightBtn"> <i class="fas fa-chevron-right next-icon" ></i> </div>
                               <div>
                                <!-- <div class="closeBtn"   data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div> -->
                                <div class="dotsBtn">
                                    <div *ngFor="let image of images; let i = index" 
                                         (click)="showImage(image, i)" 
                                         [ngClass]="{'mainDtols': true, 'active-dot': i === currentImageIndex}"></div>
                                  </div>
                               </div>
                                <img *ngIf="mainImage?.type==='image'" id="mainImg" [src]="mainImage?.image" alt="Main Image" />
                             
                                
                                <!-- <button class="closeBtn" ></button> -->
                                <img class="closeBtn" data-bs-dismiss="modal"  (click)="playPauseVideo()" aria-label="Close" src="./../../../assets/images/icons-images/close-circle-svgrepo-com.png" alt="">
                                <div class="thumbnails">
                                  
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>




       <!-- Modal Videos -->
       <div class="modal fade" id="staticBackdropVideos" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered modal-xl">
           <div class="modal-content popupSettings">
               <!-- <div class="modal-header" style="border: none;">
                   <h5 class="modal-title tiltes" id="staticBackdropLabel">Gallery View</h5>
                   <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
               <div class="modal-body fullGalleryView">
                   <div class="row">

                       <div class="gallery">
                       
                           <div class="main-image">
                               
                               <div (click)="previousVideo1()" class="letfBtn"> <i class="fas fa-chevron-left prev-icon" ></i> </div>
                               <div (click)="nextVideo1()" class="RightBtn"> <i class="fas fa-chevron-right next-icon" ></i> </div>
                              <div>
                               <!-- <div class="closeBtn"   data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></div> -->
                               <div class="dotsBtn">
                                   <div *ngFor="let image of images; let i = index" 
                                        (click)="showImage(image, i)" 
                                        [ngClass]="{'mainDtols': true, 'active-dot': i === currentImageIndex}"></div>
                                 </div>
                              </div>
                               <div  class="video-container">
                                <video #myVideo  width="100%" height="80%" controls [src]="getCurrentVideo()" autoplay></video>

                                   <!-- <button *ngIf="showPlayButton" class="play-pause-button" (click)="playPauseVideo()">
                                     <i class="fas fa-play"></i>
                                   </button> -->
                                 </div>
                               
                               <!-- <button class="closeBtn" ></button> -->
                               <img class="closeBtn" data-bs-dismiss="modal"  (click)="playPauseVideo()" aria-label="Close" src="./../../../assets/images/icons-images/close-circle-svgrepo-com.png" alt="">
                               <div class="thumbnails">
                                 
                               </div>
                           </div>
                       </div>


                   </div>
               </div>

           </div>
       </div>
   </div>
</div>


<!-- <div class="container">
    <div id="videoSlider" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div *ngFor="let video of videos; let i = index" class="carousel-item" [ngClass]="{ 'active': i === currentIndex }">
          <video controls>
            <source [src]="video.image" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
 
    </div>
  </div> -->



