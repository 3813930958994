<div class="privacy_Page">
    <div class="container">
        <!-- <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
            <label class="btn btn-outline-primary TersmsBtn" for="btnradio1">Privacy Policy</label>
          
            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
            <label class="btn btn-outline-primary TersmsBtn"  for="btnradio2">Terms of Service</label>
          
            <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
            <label class="btn btn-outline-primary TersmsBtn" for="btnradio3">Cookies Settings</label>
          </div> -->
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button (click)="clickPrivacy()" class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                    type="button" role="tab" aria-controls="nav-home" aria-selected="true">Privacy Policy</button>
                <button (click)="clickService()" class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                    type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Terms of Service</button>
                <button (click)="clickCookie()" class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact"
                    type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Cookies Settings</button>
            </div>
        </nav>
        <div *ngIf="privacys" class="privacys">


            <div class="PRIVACY-POLICY">
                <h1 class="privacy-text">PRIVACY POLICY</h1>
                <p class="paraType">At Devotion Travel and Tourism, we respect our valued customer's privacy and ensure
                    not to collect any information other than the information relevant to make a booking with us. As
                    such, we strive to take care of our customer’s right to privacy in connection with their interaction
                    with this website. Our scope of commitment as part of the use of your information is outlined
                    herein.</p>
            </div>

            <h3 class="sideHeadiing">Collection of Personal Information</h3>
            <p class="paraType">We collect your information mainly when you contact us to inquire about our products and
                services or make a booking with us. This usually includes your name, contact details, email ID, physical
                address, credit card or payment details, travel requisites, and referral source. Upon submission of
                information, you give consent to Devotion Travel and Tourism to use your information to process orders
                in an accurate and prompt manner.</p>


            <h3 class="sideHeadiing">Use of Information</h3>
            <p class="paraType">Any personal information of a client obtained through this website will be utilised by
                Devotion Travel and Tourism to process their booking, verify credit card details, and provide relevant
                information associated with their travel or any other subsidiary services they would like to avail of.
                This information will also be used for auditing, research, and activities focused on improving the
                performance of our website.</p>

            <h3 class="sideHeadiing">Privacy of Your Information</h3>
            <p class="paraType">All information provided by our customers during online booking, such as their name,
                address, email ID, and credit card details, is considered private and will not be disclosed or sold to
                anyone except for certain suppliers or third parties whose involvement in the loop is fundamental for
                the successful processing of your order. But before disclosing your information, we make sure that these
                third parties abide by our privacy policy and adhere to strict safety measures.</p>


            <h3 class="sideHeadiing">Legal Disclosure of Information</h3>
            <p class="paraType">We may disclose your information if we feel that such disclosure is pertinent to protect
                our company’s rights and/or abide by a court order or a legal proceeding.</p>

            <h3 class="sideHeadiing">Non-Personal Information</h3>
            <p class="paraType">Devotion Travel and Tourism consistently collects data from our website, with the help
                of patterns via web logs and third-party service providers. But this data is mostly deployed to evaluate
                the efficiency of our website’s content and features.</p>

            <h3 class="sideHeadiing">Opt-Out</h3>
            <p class="paraType">We provide options for visitors to our website to ‘opt-out’ of having their personal
                information used for certain purposes. For instance, if you don’t want to receive any marketing material
                by way of newsletters or promotional emails, you can choose to request us not to send advertising
                information from Devotion Travel and Tourism or our affiliate websites.</p>

            <h3 class="sideHeadiing">Contests and Surveys</h3>
            <p class="paraType">Devotion Travel and Tourism conducts contests, drawings, and surveys every now and then.
                Some contests are organised in collaboration with a third-party sponsor, and visitors to our websites
                will be informed at the time of the contest regarding the involvement of a particular third party and
                their extent of using your personal information. Since participating in these contests is voluntary,
                it’s solely at your discretion whether or not to partake in them and reveal your personal information.
            </p>

            <h3 class="sideHeadiing">Secured Transaction</h3>
            <p class="paraType">In order to maintain the accuracy of data and avoid unauthorised access to our clients’
                personal information, we make sure that all transactions are carried out through our secured server.
                Moreover, we utilise technical safeguard systems such as encryption, socket layers, and firewalls to
                secure your sensitive information like credit card details</p>
        </div>
        <div *ngIf="service" class="service">

            <div class="PRIVACY-POLICY">
                <h1 class="privacy-text">TERMS OF USE:</h1>
                <p class="paraType">Thank you for choosing our travel package(s). By booking a trip through our website,
                    you´redeemed to have agreed to its terms of use. Please read the following terms and procedures in
                    order to make sure that you've clearly understood the conditions of your preferred trip. All of the
                    below-mentioned terms and conditions are applicable for bookings made through ourwebsite Devotion
                    Travel and Tourism.</p>
            </div>

            <h3 class="sideHeadiing">Pricing</h3>
            <p class="paraType">Our policy assures you of an economic pricing, along with the convenience of making
                reservations online. Unless otherwise mentioned, prices quoted on our website are charged on a
                per-person basis and do not include tips given to tour guides or drivers, passport/visa fees, travel
                insurance, drinks or food, accommodation, room services, and laundry. The published rates may change
                without prior notice, particularly in case of any unforeseen circumstances, such as an increase in
                airline tickets, hotel rates, or transport expenses.</p>


            <h3 class="sideHeadiing">Methods of Payment</h3>
            <p class="paraType">We accept almost all major credit cards, such as Visa, MasterCard, American Express,
                Discover, and Diners. Full payment must be done by credit card, and guests are required to provide their
                credit card number to complete the transaction. This will be shown as a charge on your statement.</p>

            <h3 class="sideHeadiing">Confirmation of Payment</h3>
            <p class="paraType">Once payment is done, our travel consultants will send you a confirmation slip by email.
                Its print can be produced as evidence of payment to the service provider to redeem your tour package.
                But make sure that you´ve provided correct information pertaining to your travel requirements at the
                time of booking.</p>


            <h1 style="font-size: 26px;" class="sideHeadiing">Cancellation & No Show Policy</h1>
            <h3 class="sideHeadiing">Cancellation</h3>
            <p class="paraType">Every tour activity/attraction/hotel has its own cancellation policy. Before booking any
                activity with Devotion Travel and Tourism, the client must read the cancellation policy of that
                particular tour. Combo tours can be cancelled, but if anyone is cancelling any of the single tours in
                combo, then they will not be able to avail of the combo offer. In this case, Devotion Travel and Tourism
                will consider the individual tour price of the non-cancelled tour and refund the remaining amount.
                Tourist visa fees are not refundable once payment is done on the Devotion Travel and Tourism website.
                While booking a combo, please make sure that all combo tour options are available. If not, you would
                only be able to enjoy the tours and services that you have seen in the options.</p>

            <h3 class="sideHeadiing">No Show</h3>
            <p class="paraType">If you fail to turn up for the tour, no refunds in part or full can be provided. The
                same condition applies in the case of unused tickets, sightseeing tours, car-rental, or chauffeur-driven
                services. Likewise, rescheduling cannot be allowed for confirmed tours, transfers to and from airports,
                and other travel-related services. Cancellation Procedures Prior to making a cancellation, we recommend
                that you carefully read the cancellation rules that apply to your tour package. For cancellation of all
                or any part of your reservation, make sure that you make notification of cancellation to Devotion Travel
                and Tourism in writing. Upon receiving your cancellation request, we´ll let you know through email, fax,
                or telephone regarding the confirmation of booking cancellation as well as the fee that should be paid.
                Devotion Travel and Tourism cannot be held responsible for any cancellation that has not been received
                from you or not confirmed by us.</p>

            <h3 class="sideHeadiing">Itinerary Amendments</h3>
            <p class="paraType">Routings and services covered in your package are subject to change based on
                local/weather conditions, airway schedules, and other such several aspects. Should this transpire, we
                can provide suitable options of similar value, however depending on their availability. At most, we
                announce changes in itinerary, if any, before departure. Please note that Devotion Travel and Tourism
                reserves the complete right to implement minor amendments to the itinerary at any time due to unforeseen
                circumstances, but we will do our best to minimise any inconvenience caused.</p>

            <h3 class="sideHeadiing">Travel Insurance</h3>
            <p class="paraType">It is strongly recommended that you purchase travel insurance to cover yourself against
                accidents, illness, and cancellation or curtailment of your trip. Devotion Travel and Tourism does not
                provide travel insurance and will not be liable for any costs incurred as a result of failure to obtain
                adequate insurance coverage.</p>

            <h3 class="sideHeadiing">Passports and Visas</h3>
            <p class="paraType">It is your responsibility to ensure that you have valid travel documents such as
                passports, visas, and other permits necessary for travel to your destination. Devotion Travel and
                Tourism cannot be held responsible for any issues related to documentation and entry to your
                destination.</p>
            <h3 class="sideHeadiing">Liability</h3>
            <p class="paraType">Devotion Travel and Tourism acts only as an agent for the suppliers and contractors
                providing transportation, accommodation, and other related services. We do not own or operate any of
                these suppliers and contractors. As a result, we are not liable for any loss, injury, or damage to
                person, property, or otherwise in connection with any transportation, accommodation, or other services
                resulting directly or indirectly from any act of God, fire, war, civil disturbances, acts of governments
                or other authorities, accidents, thefts, cancellations or changes in itineraries or schedules, or from
                any causes beyond our control.</p>

            <h3 class="sideHeadiing">Disputes</h3>
            <p class="paraType">Any disputes arising from your use of our services shall be governed by the laws of the
                country where the tour takes place.</p>

            <h3 class="sideHeadiing">Miscellaneous</h3>
            <p class="paraType">Devotion Travel and Tourism reserves the right to amend these terms and conditions at
                any time without prior notice. Any such amendments will be effective immediately upon being posted on
                our website.</p>
            <p style="padding-top: 30px;" class="paraType">By booking a tour with Devotion Travel and Tourism, you
                acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>


        </div>
        <div *ngIf="cookies" class="cookies">

      
                <div class="container">
                    <div class="PRIVACY-POLICY">
                        <h1 class="privacy-text">COOKIES POLICY</h1>
                        <p class="paraType">By using our website or by providing your information, you hereby acknowledge that you have read, understood, and agree to be bound by all the website terms of use.</p>
                    </div>
            
                    <h3 class="sideHeadiing">What are Cookies?</h3>
                    <p  class="paraType">Devotion Travel and Tourism employs an industry-wide “cookie” technology that, in simple terms, are small data pieces placed on your hard disk when you access a particular part of the website. The entire purpose of installing cookies is just to understand which areas of our website are popular and where users are spending most of their time. It helps us to customize internet settings according to the user's tastes and preferences. Cookies are used to show relevant advertisements (including the third parties) as well.</p>
                    
                    
                    <h3 class="sideHeadiing">Cookies Features:</h3>
                    <p  class="paraType">We use cookies during your browsing session, and when you visit the website multiple times, we employ persistent cookies to offer the following features:</p>
                    
                    
                    <h3 class="sideHeadiing">Customization -  <span class="paraTypess" style="color: #171717 !important;">for an enhanced browsing experience.</span></h3>
                    <h3 class="sideHeadiing">Analytics -  <span class="paraTypess" style="color: #171717 !important;">to find areas for improved service by our website.</span></h3>
                    <h3 class="sideHeadiing">Third-Party Technical Cookies -  <span class="paraTypess" style="color: #171717 !important;"> These cookies are aimed at providing improved services to the consumers.</span></h3>
                    <h3 class="sideHeadiing">Google Analytics -   <span class="paraTypess" style="color: #171717 !important;">  A high-quality web analytics service offered by Google Inc., Google Analytics applies the data collected to track and study the website use, to create reports on the activities, and share the findings with other Google services. Google might use the data to customise and contextualise advertisements from its own network of ads.</span></h3>
                    <h3 class="sideHeadiing">External Social Networks and Other Platforms Interactions -    <span class="paraTypess" style="color: #171717 !important;"> These services allow collaboration with social networks or other external platforms directly from the website’s</span></h3>
                    <h3 class="sideHeadiing">pages -    <span class="paraTypess" style="color: #171717 !important;">The interaction and information gained through the use of these services are subjected to the privacy settings opted by the user. In cases where a service enabling interaction with social networks is installed, traffic data may still be collected for the pages with the installed service, even when browsing isn’t done.</span></h3>
                    <h3 class="sideHeadiing">Like Button of Facebook and its Social Widgets -    <span class="paraTypess" style="color: #171717 !important;">These services allow interaction with the Facebook social network provided by the company Facebook Inc.</span></h3>
                    <h3 class="sideHeadiing">Pinterest “Pin it” Button and its Social Widgets -   <span class="paraTypess" style="color: #171717 !important;">These services allow interaction with the Pinterest platform provided by Pinterest Inc.</span></h3>
                    <h3 class="sideHeadiing">Google+ +1 Button and its Social Widgets -    <span class="paraTypess" style="color: #171717 !important;">These services allow interaction with the Google+ social network provided by Google Inc.</span></h3>
                    <h3 class="sideHeadiing">Social Applications -    <span class="paraTypess" style="color: #171717 !important;">The social applications services allow our website to access the data of your social networks profile and maintain interaction through your posts. These services are not activated by default and require specific user authorization.</span></h3>
                    <h3 class="sideHeadiing">Access to Twitter Account -   <span class="paraTypess" style="color: #171717 !important;">This service allows this Application to connect with the Twitter social network account of the user, provided by Twitter Inc.</span></h3>
                    <h3 class="sideHeadiing">Access to Facebook Account  -   <span class="paraTypess" style="color: #171717 !important;">This service permits this Application to connect with the user's Facebook social network account, provided by Facebook Inc.</span></h3>
                  
                   
                    <h1 class="sideHeadiing">Cookies Policy:</h1>
                    <p class="paraType">Our website uses cookies to improve your browsing experience and to offer personalised services. By continuing to use our website, you consent to the use of cookies in accordance with our policy. If you do not agree with our cookies policy, please disable cookies in your browser settings or refrain from using our website. Please note that disabling cookies may affect your browsing experience and limit some of our website's features. We reserve the right to modify this cookie policy at any time. Any changes made to the policy will be posted on this page.</p>
                 
                </div>
            
    

        </div>

    </div>