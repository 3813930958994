<div class="allPages">

  <div *ng="false" class="devotion-contactUs">
    <div class="contact-usstop">
      <div class="container">
        <h2 class="contactus-deals">Contact us and get your deal of choice</h2>
      </div>
    </div>
    <div class="contact-anytime">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="left-contactBox">
              <p class="welcome-to">
                Welcome to Devotion! We're thrilled that you're reaching out to
                us. Whether you have a question, need assistance with your
                booking, or just want to share your travel experiences, we'd
                love to hear from you.
              </p>
              <p class="contact-247x">
                <img class="contact-icon" src="./../../../assets/images/m/headphone.svg" alt="contact-icon" />Contact us
                anytime, 24/7
              </p>
              <div class="icon-box">
                <img class="headphone-whatsupIcon" (click)="makePhoneCall()"
                  src="./../../../assets/images/m/headphon2.svg" alt="" />
                <img class="headphone-whatsupIcon" (click)="sendEmail()" src="./../../../assets/images/m/emailIcon.png"
                  alt="" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="rightContact-tble">
              <div class="dropdown">
                <button class="btn flightdrop-downBtn text-start" type="button" id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <img [src]="icons" alt="" class="flightt-icn" />
                  {{contactusObj.type}}
                </button>
                <img src="./../../../assets/images/m/drop-icon.png" class="dropdwn-icon" alt="down-arw" />
                <ul class="dropdown-menu FdrpDown-below w-100" aria-labelledby="dropdownMenuButton">
                  <li (click)="selectItem('FLIGHT','./../../../assets/images/m/flightIc.png')" class="packge-lists">
                    <a class="dropdown-item diff-ittems d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <img src="./../../../assets/images/m/flightIc.png" alt="" class="icons-leftEnd" />
                        FLIGHT
                      </div>
                    </a>
                  </li>
                  <li (click)="selectItem('PACKAGES','./../../../assets/images/m/packagesIc.png')" class="packge-lists">
                    <a class="dropdown-item diff-ittems d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <img src="./../../../assets/images/m/packagesIc.png" alt="" class="icons-leftEnd" />
                        PACKAGES
                      </div>
                    </a>
                  </li>
                  <li (click)="selectItem('VISA','./../../../assets/images/m/visaIc.png')" class="packge-lists">
                    <a class="dropdown-item diff-ittems d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <img src="./../../../assets/images/m/visaIc.png" alt="" class="icons-leftEnd" />
                        VISA
                      </div>
                    </a>
                  </li>

                </ul>
              </div>
              <div>
                <div *ngIf="currentStage === 1">
                  <div class="form-group">
                    <input [(ngModel)]="name" type="text" class="form-control name-input" placeholder="Name" />
                  </div>
                </div>

                <div *ngIf="currentStage === 2">
                  <div class="form-group">
                    <input [(ngModel)]="email" type="text" class="form-control name-input" placeholder="Email" />
                  </div>
                </div>

                <div *ngIf="currentStage === 3">
                  <div class="form-group">
                    <input [(ngModel)]="mobileNo" type="number" class="form-control name-input"
                      placeholder="Mobile Number" />
                  </div>
                </div>


                <div *ngIf="currentStage === 4">
                  <div class="form-group">
                    <textarea style="height: 130px;" [(ngModel)]="subject" type="text" class="form-control name-input"
                      placeholder="Subject "></textarea>
                  </div>
                </div>

                <nav *ngIf="currentStage === 1">
                  <ul class="pagination pagination-below justify-content-center">
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot2"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot3"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot4"></i></a>
                    </li>
                  </ul>
                </nav>

                <nav *ngIf="currentStage === 2">
                  <ul class="pagination pagination-below justify-content-center">
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot3"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot4"></i></a>
                    </li>
                  </ul>
                </nav>
                <nav *ngIf="currentStage === 3">
                  <ul class="pagination pagination-below justify-content-center">
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle" id="dot4"></i></a>
                    </li>
                  </ul>
                </nav>
                <nav *ngIf="currentStage === 4">
                  <ul class="pagination pagination-below justify-content-center">
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-links"><i class="fas fa-circle dot-circle active-dot" id="dot1"></i></a>
                    </li>
                  </ul>
                </nav>
                <div *ngIf="currentStage !== 4" class="nxtBtn-below ">
                  <button class="next-Btn" (click)="next()">Next</button>
                </div>

                <div *ngIf="currentStage === 4" class="submitBtn nxtBtn-below  ">
                  <button class="next-Btn" (click)="submitForm()">Submit</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="visitUs-section">
        <div class="container">
          <h1 class="visitt-title">Visit Us</h1>
          <div class="locationBox-below">
            <div class="left-card">
              <ul class="leftcard-top p-0">
                <li class="Lcard-list">
                  <ul class="AdressSub-top list-unstyled d-flex align-items-center p-0">
                    <li class="AdresSub-list1">
                      <div class="adressleft-images">
                        <img class="locattion-icons" src="./../../../assets/images/m/location.svg"
                          alt="location-icon" />
                      </div>
                    </li>
                    <li class="AdresSub-list2">
                      <div class="rightAdress-Data">
                        <a class="titlesDa" target="_blank"
                          href="https://www.google.com/maps/dir/25.1820753,55.2590815/devotion+tourism/@25.1714707,55.2688578,13.92z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5f69fff8df8c8f:0xf9c39d32654761bd!2m2!1d55.2602652!2d25.1859019?entry=ttu">
                          502 - Iris Bay, Opp. JW Marriott Hotel, Business Bay,
                          Dubai - UAE</a>

                      </div>
                    </li>
                  </ul>
                </li>
                <li class="Lcard-list">
                  <ul class="list-unstyled AdressSub-top d-flex align-items-center p-0">
                    <li class="AdresSub-list1">
                      <div class="adressleft-images">
                        <img class="locattion-icons" src="./../../../assets/images/m/call.svg" alt="location-icon" />
                      </div>
                    </li>
                    <li class="AdresSub-list2">
                      <div (click)="makePhoneCall()" class="rightAdress-Data">
                        <span>+971 58 577 5469 </span> <br />
                        <span>+971 4 457 6077</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="Lcard-list">
                  <ul class="list-unstyled AdressSub-top d-flex align-items-center p-0">
                    <li class="AdresSub-list1">
                      <div class="adressleft-images">
                        <img class="locattion-icons" src="./../../../assets/images/m/message.svg" alt="location-icon" />
                      </div>
                    </li>
                    <li class="AdresSub-list2">
                      <div (click)="sendEmail()" class="rightAdress-Data">
                        support@devotiontourism.com
                      </div>
                    </li>
                  </ul>
                </li>

              </ul>
              <div class="Adrssbottom-iccns">
                <div class="socialMdia-boxes">
                  <img class="social-mediaIcns" src="./../../../assets/images/m/instagram1.png" alt="insta" />
                </div>
                <div (click)="makeLinked()" class="socialMdia-boxes">
                  <img class="social-mediaIcns" src="./../../../assets/images/m/linkedin1.png" alt="linkdin" />
                </div>
                <div class="socialMdia-boxes">
                  <img class="social-mediaIcns" src="./../../../assets/images/m/twitter1.png" alt="twittr" />
                </div>

                <div (click)="makeYOutube()" class="socialMdia-boxes">
                  <img class="social-mediaIcns" src="./../../../assets/images/m/youtube1.png" alt="you tube" />
                </div>
              </div>
            </div>
            <div class="rightLocation-card">

              <video class="map-videos" autoplay muted loop playsinline>
                <source class="map-logo" src="/../../assets/images/aboutus/map.mp4" type="video/mp4" loading="lazy"
                  alt="devotion">
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

 


  </div>

  <div class="devotion-contactUs">
    <div class="contact-usstop">
      <div class="container">
        <h2 class="contactus-deals">Contact our team</h2>
        <p class="contact-text">
          We are a leading travel and tourism company that specializes in flight
          booking, hotel booking, and corporate travel services. With our
          unwavering commitment to excellence and personalized service, we strive
          to make your travel experiences truly memorable.
        </p>
      </div>
    </div>
    <div class="contact-anytime">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="rightContact-tble">
              <p class="fill-detail">Request Callback</p>
              <form [formGroup]="InputDataform" (ngSubmit)="dataForm()" class="row g-3 g-md-4">
                <div >
                  <label for="inputName4" class="form-label name-lebeles">Name</label>
                  <input type="text" formControlName="name" class="form-control contact-inputs contact-inputs"
                    placeholder="Full Name" aria-label="First name" id="inputNamee4" />
                  <div class="error-container">
                    <div class="error-message" *ngIf="InputDataform.get('name')?.invalid && (InputDataform.get('name')?.touched ||
                  InputDataform.get('name')?.dirty)">
                      <i class="fas fa-info-circle error-icon"></i>
                      Name is Required
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="inputEmail4" class="form-label name-lebeles">Email</label>
                  <input type="email" formControlName="email" class="form-control contact-inputs"
                    placeholder="Enter Email ID" id="inputEmail4" />
                  <div class="error-container">
                    <div class="error-message" *ngIf="InputDataform.get('email')?.invalid && (InputDataform.get('email')?.touched ||
                  InputDataform.get('email')?.dirty)">
                      <i class="fas fa-info-circle error-icon"></i>
                      Email is Required
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="inputEmail4" class="form-label name-lebeles">Contact Number</label>
                  <div class="MobileNumInside">
                    <div class="CountryCodeData">
                      <div class="dropdown DropDown">
                        <button style="border-top-right-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;" (click)="autoFucos()" class="btn FormSelect dropdown-toggle  contact-inputs" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <img *ngIf="!countryIsSelected" class="FlagImg" src="./../../../../assets/images/country/aue.webp" alt="no-img">
                          <img *ngIf="countryIsSelected" class="FlagImg" [src]="'data:image/png;base64,' + selectdImg" alt="no-img">
                  
                          <p class="CallID"> {{callingCode}}</p>
                        </button>
                        <ul class="dropdown-menu DropDownMenu" aria-labelledby="dropdownMenuButton1">
                          <li class="DropDownLi">
                  
                            <div class="InputSearch">
                              <img class="SearchImgIcon" src="./../../../../assets/images/search.png" alt="no-data">
                  
                  
                              <input #myInput formControlName="search" (keyup)="searchCOde($event)" [(ngModel)]="searchText"
                                class="InputSearchData" type="search" placeholder="Select Country Name or Code">
                  
                  
                            </div>
                          </li>
                          <li (click)="autoFucos2()" *ngFor="let item of allMobileFlags" (click)="getCountryID(item)" class="DropDownLi">
                            <a class="dropdown-item DropDownItem">
                              <img *ngIf="item.type === 'base'" class="FlagImg" [src]="'data:image/png;base64,' + item.flag">
                              <img *ngIf="item.type === 'img'" class="FlagImg" [src]="item.flag">
                  
                              {{item?.name}}<span class="FlagSpan">+{{item?.callingCode}}</span></a></li>
                  
                        </ul>
                      </div>
                  
                      <input style=" border-top-left-radius: 0px !important;
                      border-bottom-left-radius: 0px !important;" formControlName="mobileNo" #numberFocus [(ngModel)]="enterMobile" class="InputNumber  contact-inputs" type="number"
                        placeholder="Mobile Number">

                  
                    </div>
                  
                  </div>
                  <!-- <div class="input-group number-inp-top">
                    <div class="input-group-prepend country-code">
                      <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="../../../assets/images/country/aue.webp" class="country-flag" alt="" />
                      </button>
                      <ul class="dropdown-menu country-list-below">
                        <li>
                          <a class="dropdown-item country-lists" href="#">
                            <img src="../../../assets/images/m/india1.svg" class="country-icon" alt="" />
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item country-lists" href="#">
                            <img src="../../../assets/images/m/india1.svg" class="country-icon" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <input type="tel" formControlName="mobileNo" class="form-control number-input" placeholder="" />


                  </div> -->
                  <div class="error-container">
                    <div class="error-message" *ngIf="InputDataform.get('mobileNo')?.invalid && (InputDataform.get('mobileNo')?.touched ||
                  InputDataform.get('mobileNo')?.dirty)">
                      <i class="fas fa-info-circle error-icon"></i>
                      Mobile number is Required
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="exampleFormControlTextarea1" class="form-label name-lebeles">Message</label>
                  <textarea class="form-control contact-inputs text-inp" id="exampleFormControlTextarea1"
                    placeholder="Enter Message" formControlName="subject" rows="3"></textarea>

                  <div class="error-container">
                    <div class="error-message" *ngIf="InputDataform.get('subject')?.invalid && (InputDataform.get('subject')?.touched ||
                  InputDataform.get('subject')?.dirty)">
                      <i class="fas fa-info-circle error-icon"></i>
                      Message is Required
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <label for="exampleFormControlTextarea1" class="form-label name-lebeles">Services</label>
                  <ul class="check-boxes-below list-unstyled">
                    <li class="cheklists-top">
                      <ul class="list-lines list-unstyled">
                        <li class="check-lists">
                          <div (click)="checkType('Flight Bookings')" class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="flight" />
                            <label class="form-check-label chekbox-lebeles" for="flight">
                              Flight Bookings
                            </label>
                          </div>
                        </li>
                        <li class="check-lists">
                          <div (click)="checkType('Packages')" class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="carRental" />
                            <label class="form-check-label chekbox-lebeles" for="carRental">
                              Packages
                            </label>
                          </div>
                        </li>
                        <li class="check-lists">
                          <div (click)="checkType('Global Visa')" class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="visa" />
                            <label class="form-check-label chekbox-lebeles" for="visa">
                              Global Visa
                            </label>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li class="cheklists-top">
                      <ul class="list-lines list-unstyled">
                        <li class="check-lists">
                          <div (click)="checkType('Hotel Bookings')" class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="package" />
                            <label class="form-check-label chekbox-lebeles" for="package">
                              Hotel Bookings
                            </label>
                          </div>
                        </li>
                        <li class="check-lists">
                          <div (click)="checkType('Hotel')"  class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="hotel" />
                            <label class="form-check-label chekbox-lebeles" for="hotel">
                              Corporate Bookings
                            </label>
                          </div>
                        </li>
                        <!-- <li class="check-lists">
                          <div (click)="checkType('Holiday')"  class="form-check">
                            <input class="form-check-input chk-box" type="radio" name="service" id="holiday" />
                            <label class="form-check-label chekbox-lebeles" for="holiday">
                              Holiday
                            </label>
                          </div>
                        </li> -->
                      </ul>
                    </li>
                  </ul>
                </div>
                
                <div class="button-top">
                  <button  (click)="submitForm()" class="submit">Send Request</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6">
            <div class="left-contactBox">
              <p class="contact-247x">Location</p>
              <div *ngIf="false" class="icon-box">
                <!-- <img class="headphone-whatsupIcon" (click)="makePhoneCall()"
                  src="./../../../assets/images/m/headphon2.svg" alt="" /> -->
                <img class="headphone-whatsupIcon whatsapIcons"  (click)="openWhatsApp()" src="./../../../assets/images/bookings/whatsapLight.png"
                  alt="" />
                <!-- <img class="headphone-whatsupIcon" (click)="sendEmail()" src="./../../../assets/images/m/sms.svg"
                  alt="" /> -->
              </div>
              <!-- <p class="contact-247x contact-2471">Get in touch</p> -->
              <ul class="adress-data-top list-unstyled">
                <li class="adress-list">
                  <div class="adress-inside">
                    <div (click)="directLocation()" class="image-top">
                      <img src="./../../../assets/images/m/location.svg" alt="" class="left-adress-image" />
                    </div>

                    <div (click)="directLocation()" class="right-adress-text">
                      502 - Iris Bay, Opp. JW Marriott Hotel,<br />
                      Business Bay, Dubai - UAE
                    </div>
                  </div>
           
              </ul>
              <!-- <p class="contact-247x">Social Links</p>
              <div class="icon-box">
                <img (click)="openInsta()" class="headphone-whatsupIcon social-media-icon"
                  src="./../../../assets/images/m/insta.svg" alt="" />
                <img (click)="openLinkedInPage()" class="headphone-whatsupIcon social-media-icon" 
                  src="./../../../assets/images/m/linkdn.svg" alt="" />
                <img  class="headphone-whatsupIcon social-media-icon"
                  src="./../../../assets/images/m/twitter.svg" alt="" />
                <img (click)="openYoutubePage()" class="headphone-whatsupIcon social-media-icon" 
                  src="./../../../assets/images/m/youtub.svg" alt="" />
              </div> -->
            </div>
            <div class="left-contactBox">
              <p class="contact-247x">Get in Touch</p>
              <div *ngIf="false" class="icon-box">
                <!-- <img class="headphone-whatsupIcon" (click)="makePhoneCall()"
                  src="./../../../assets/images/m/headphon2.svg" alt="" /> -->
                <img class="headphone-whatsupIcon"  (click)="openWhatsApp()" src="./../../../assets/images/m/wtsp.png"
                  alt="" />
                <!-- <img class="headphone-whatsupIcon" (click)="sendEmail()" src="./../../../assets/images/m/sms.svg"
                  alt="" /> -->
              </div>
              <!-- <p class="contact-247x contact-2471">Get in touch</p> -->
              <ul class="adress-data-top list-unstyled">
             
                <li class="adress-list">
                  <div class="adress-inside">
                    <div  (click)="makePhoneCall()" class="image-top">
                      <img src="./../../../assets/images/m/call.svg" alt="" class="left-adress-image" />
                    </div>

                    <div  (click)="makePhoneCall()" class="right-adress-text">
                      +971 58 577 5469 
                    </div>
                  </div>
                </li>
                <li class="adress-list">
                  <div class="adress-inside">
                    <div (click)="sendEmail()" class="image-top">
                      <img src="./../../../assets/images/m/emil.svg" alt="" class="left-adress-image email-img" />
                    </div>

                    <div (click)="sendEmail()" class="right-adress-text">
                      support@devotiontourism.com
                    </div>
                  </div>
                </li>
                <li class="adress-list">
                  <div class="adress-inside">
                    <div  (click)="openWhatsApp()"  class="image-top">
                      <img  src="./../../../assets/images/bookings/whatsapPng.png" alt="" class="left-adress-image email-img whatsapImg" />
                    </div>

                    <div  (click)="openWhatsApp()"  class="right-adress-text">
                     Whatsapp
                    </div>
                  </div>
                </li>
              </ul>
              <p class="contact-247x">Social Links</p>
              <div class="icon-box">
                <img (click)="openInsta()" class="headphone-whatsupIcon social-media-icon"
                  src="./../../../assets/images/m/insta.svg" alt="" />
                <img (click)="openLinkedInPage()" class="headphone-whatsupIcon social-media-icon" 
                  src="./../../../assets/images/m/linkdn.svg" alt="" />
                <img  class="headphone-whatsupIcon social-media-icon"
                  src="./../../../assets/images/m/twitter.svg" alt="" />
                <img (click)="openYoutubePage()" class="headphone-whatsupIcon social-media-icon" 
                  src="./../../../assets/images/m/youtub.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>


  <ng-template style="display: flex; background-color: #fff !important;justify-content: center !important;
  display: flex !important;" #dialogContent>
        <div class="totalDiv">
          <div>
            <p class="THnkyouu">Thank you! Your submission has been received! Keep Travelling</p>
            <button (click)="closeDialog()" type="submit" class="search-hotels-btn"> OK</button>
          </div>
        </div>
      </ng-template>
</div>


