import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { addProfile } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],

})
export class UserProfileComponent implements OnInit {
  selectedButton: string = '1'; // Default selected button
  selectedFlag = './../../../../assets/images/country/aue.webp'
  selectedCountryCode = '971'
  newNumber = ''
  callingCode = '971'
  selectButton(buttonName: string) {
    this.selectedButton = buttonName;
    console.log(buttonName)
  }
  profieData: any = []
  mobleNoChangeStatus: number = 0
  @ViewChild('openInput') openInput!: ElementRef;
  @ViewChild('selectFirstOTP') selectFirstOTP!: ElementRef;
  @ViewChild('selectSecondOTP') selectSecondOTP!: ElementRef;
  @ViewChild('inputSelects') inputSelects!: ElementRef;
  @ViewChild('closePops') closePops!: ElementRef;
  @ViewChild('closeNewNumber') closeNewNumber!: ElementRef;
  myFlightBooking: any = []
  imgProfile: any
  mobileFlag: any
  isUploaded: boolean = false
  visaBookings: any = []
  packageBookings: any = []
  packageBookingDetails: any = []
  allMobileFlags: any
  type: any = 'Select Type';
  genders: any = 'Select gender';
  selected: any
  gender: string
  @ViewChild('closePop') closePop: ElementRef;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates
  ];
  ismale: boolean
  isfemale: boolean
  userProfileList: any = []
  profilePercentage: number = 0
  DateOfBirth: any
  addProfileObj = new addProfile()
  Mr: boolean
  Mrs: boolean
  Ms: boolean
  addProfile: FormGroup
  TitleCap: string = 'Mr'
  TypeCap: string
  Nationality1: string
  GenderCap: string
  mobileCodes: string

  updatesProfile: FormGroup
  updatedDate: any
  updateId: any
  dateofBirth = new Date()
  @ViewChild('openCalnder') openCalnder!: ElementRef;
  @ViewChild('closeModal') closeModal!: ElementRef;
  @ViewChild('closeModal2') closeModal2!: ElementRef;
  updateform: FormGroup
  updateform2: FormGroup
  isEdit = true
  isEdit2 = true
  isFormData: any
  loginType: any
  isContactVerified: boolean = false
  BsDatepickerConfig: Partial<BsDatepickerConfig>
  constructor(private service: AuthService, private datePipe: DatePipe, private router: Router) {
    this.BsDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-green',
      showWeekNumbers: false,
      dateInputFormat: 'DD/MM/YYYY'
    });
  }
  ngOnInit(): void {
    this.updateform = new FormGroup({
      name: new FormControl('', [Validators.required, this.noNumbersValidator]),
      gender: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required, this.noNumbersValidator]),
      middlename: new FormControl('', [Validators.required, this.noNumbersValidator]),
      lastname: new FormControl('', [Validators.required, this.noNumbersValidator]),
      dateOfBirth: new FormControl(''),
      type: new FormControl('')
    });
    this.updateform2 = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),

    });


    this.addProfile = new FormGroup({
      Title: new FormControl('', [Validators.required]),
      Type: new FormControl('', [Validators.required]),
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      DateOfBirth: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Gender: new FormControl('', [Validators.required]),
      Nationality1: new FormControl('', [Validators.required]),
      mobileCode: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', [Validators.required]),
    });

    this.updatesProfile = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      emailId: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
    });

    this.profile()
    this.getUserProfileList()
    this.getCountryFlags()
    this.addProfile.value.title = 'Mr'
    this.addProfile.value.Type = '1'
    // this.myBooking()
    this.latestBookings()
  }
  updateProfileData() {

  }

  onDropdownClick(event: Event): void {
    // Add your logic for preventing dropdown closure here
    event.stopPropagation();
  }
  noNumbersValidator(control: AbstractControl): ValidationErrors | null {

    const value = control.value;
    if (/[^a-zA-Z\s]/.test(value)) { // Check if value contains anything other than letters and spaces
      return { noNumbers: true };
    }
    return null;
  }
  profile() {

    this.service.getProfile().subscribe(
      (data: any) => {

        debugger
        this.mobileFlag = `data:image/png;base64,${data?.countryFlag}`
        this.imgProfile = data.profileImage
        this.profieData = data;
        if (this.profieData?.loginType === 'google') {
          this.loginType = true
        }
        if (this.profieData.mobile.length < 2) {
          this.isContactVerified = false
        }
        if (this.profieData.mobile.length > 2) {
          this.isContactVerified = true
        }
        localStorage.setItem('profile', data)
        this.calculate_profile_completion()
        if (data?.type?.length > 1) {
          this.type = data?.type
        }
        if (data?.gender?.length > 1) {
          this.genders = data?.gender
        }

        this.gender = data?.gender



        this.calculate_profile_completion()
        this.DateOfBirth = this.datePipe?.transform(this.profieData?.dateOfBirth, 'MM/dd/yyyy');


      }
    )
  }

  submit() {

    // const inputDate = 
    // 
    // const convertedDate = this.datePipe?.transform(inputDate, 'dd-MM-yyyy');
    this.updateform.value.dateOfBirth = this.updateform.value.dateOfBirth
    this.service.updateProfile(this.updateform.value).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.isEdit = true
        this.profile()

        this.service.editUser(1);
      },
      (err) => {

        this.service.error(err.error.message)
      }
    )
  }
  enableEdit() {
    this.isEdit = false
    this.service.success('Edit mode Enabled')
  }
  enableEdit2() {
    this.isEdit2 = false
    this.service.success('Edit mode Enabled')
  }
  submit2() {

    this.service.updateProfile(this.updateform2.value).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.isEdit2 = true
        this.service.editUser(1);
        this.profile()

      }, (err) => {

        this.service.error(err.message)
      }
    )
  }

  uploadProfile() {

    this.service.uploadProfile('').subscribe(
      (data: any) => {
        this.service.editUser(1);
        this.profile()
      },
      (err) => {
        this.service.error(err.message)
      }
    )
  }
  selectFile(e: any) {
    this.isUploaded = true
    let formData = new FormData();
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      formData.append('profile', e.target.files[0]);
      this.isFormData = formData
      this.service.uploadProfile(formData).subscribe(
        (data: any) => {


          let info = {
            profileImage: data.imagePath
          }
          this.imgProfile = data.imagePath
          this.isUploaded = false
          // this.service.updateProfile(info).subscribe(
          //   (data: any) => {
          //     this.service.success('profile image updated')
          //     this.service.editUser(1);
          //     this.profile()

          //   }
          // )
        }
      )
    }
  }

  openCalnders() {
    this.openCalnder.nativeElement.click();
  }

  calculate_profile_completion() {

    this.service.calculate_profile_completion(this.profieData).subscribe(
      (data: any) => {

        this.profilePercentage = data.completionPercentage
      }
    )
  }
  selectOrigin(e) {
    // this.searchFlightDataObj.Origin = e
    // this.selectBox.open();
  }

  classNames = [
    { id: 'Mr', name: 'Mr' },
    { id: 'Mrs', name: 'Mrs' },
    { id: 'Ms', name: 'Ms' },
  ];
  classGender = [
    { id: 'Male', name: 'Male' },
    { id: 'Female', name: 'Female' },

  ];

  selectType(type) {

    this.type = type
    this.updateform.value.type = type
  }
  selectGenders(gender) {
    this.genders = gender
    this.updateform.value.gender = gender
  }

  selectAdulst(data) {
    this.TitleCap = data.target.value
  }
  selectTypes(data) {
    this.TypeCap = data.target.value

  }
  NationalityType(data) {
    this.Nationality1 = data.target.value

  }
  selectGender(data) {

    this.GenderCap = data.target.value

  }
  CodeType(data) {

    this.mobileCodes = data.target.value

  }

  onDateSelected(data) {
    this.closePop.nativeElement.click()
    this.dateofBirth = this.selected
  }
  onDateSelected2(data) {
    this.closePop.nativeElement.click()
    this.updatedDate = this.selected
  }
  errorDesiplay: any
  submitProfile() {
    debugger
    this.addProfile.value.Title = this.TitleCap
    this.addProfile.value.Type = this.selectedButton
    this.addProfile.value.mobileCodeFlag = this.selectedFlag
    if (this.addProfile.value.Title === 'Mr') {
      this.addProfile.value.Gender = "1"
    }
    if (this.addProfile.value.Title !== 'Mr') {
      this.addProfile.value.Gender = "2"
    }

    this.addProfile.value.mobileCode = this.selectedCountryCode
    // this.addProfile.value.DateOfBirth = this.datePipe.transform(this.selected, 'MM/dd/yyyy');
    // this.addProfile.value.Nationality1 = this.Nationality1

    this.service.userProfiles(this.addProfile.value).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.closeModal.nativeElement.click()
        this.getUserProfileList()
        this.addProfile.reset()
      },
      (err) => {
        this.service.error(err.error.message)
        setTimeout(() => {
          this.errorDesiplay = ''

        }, 1000)
      }
    )
  }

  getUserProfileList() {
    this.service.getUserProfileList().subscribe(
      (data: any) => {

        this.userProfileList = data
      }
    )
  }

  getUpdateProfile(data) {
    const updatedData = {
      _id: data._id,
      Title: data.Title,
      Type: data.Type,
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email,
      Gender: data.Gender,
      Nationality1: data.Nationality1,
      mobileCode: data.mobileCode,
      mobileNumber: data.mobileNumber,
    };
    this.selectedButton = data.Type
    this.selectedFlag = data?.mobileCodeFlag
    this.updateId = data._id
    this.updatedDate = data.DateOfBirth
    this.addProfile.value.DateOfBirth = data.DateOfBirth
    this.addProfile.patchValue(updatedData);

  }
  clearProfile() {
    this.addProfile.reset()
  }

  getInfo() {
    let info: any
    if (!this.loginType) {
      info = {
        emailId: this.profieData.emailId,
        firstname: this.profieData.firstname,
        lastname: this.profieData.lastname,

      }
    }
    else {

      info = {
        emailId: this.profieData.emailId,
        firstname: this.profieData.firstname,
        lastname: this.profieData.lastname,
        mobile: this.profieData.mobile,
        countryCode: this.profieData.countryCode,
      }
    }

    this.updatesProfile.patchValue(info);
  }
  updateProfiles() {
    this.addProfile.value.Title = this.TitleCap
    this.addProfile.value.Type = this.selectedButton
    this.addProfile.value.mobileCodeFlag = this.selectedFlag
    if (this.addProfile.value.Title === 'Mr') {
      this.addProfile.value.Gender = "1"
    }
    if (this.addProfile.value.Title !== 'Mr') {
      this.addProfile.value.Gender = "2"
    }

    this.addProfile.value.mobileCode = this.selectedCountryCode
    this.addProfile.value._id = this.updateId
    this.addProfile.value.mobileCodeFlag = this.selectedFlag

    this.service.updateProfiles(this.addProfile.value).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.closeModal2.nativeElement.click()
        this.getUserProfileList()
      }, (err) => {
        this.service.error(err.error.message)

      }
    )
  }


  deleteProfile() {

    let info = {
      id: this.updateId
    }
    if (confirm("Are you sure to delete ?")) {
      this.service.deleteProfile(info).subscribe(
        (data: any) => {
          this.service.success(data.message)
          this.getUserProfileList()
          this.closeModal2.nativeElement.click()
        }
        , (err) => {
          this.service.error(err.error.message)

        }
      )
    }
  }

  edit_Profile() {
    if (this.loginType && !this.updatesProfile.value.mobile) {
      this.service.error('Mobile number required')
      return
    }
    if (this.loginType && !this.updatesProfile.value.countryCode) {
      this.service.error('Mobile country code required')
      return
    }
    if (this.imgProfile) {
      this.updatesProfile.value.profileImage = this.imgProfile
    }

    this.service.edit_Profile(this.updatesProfile.value).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.profile()
        this.closePops.nativeElement.click()
        this.getUserProfileList()

      }, (err) => {
        this.service.error(err.error.message)

      }
    )
  }
  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {
        let allData = []
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }

  selectCountryCode(data: any) {
    this.updatesProfile.value.countryCode = data.target.value
  }


  myBooking() {
    this.service.myBooking().subscribe(
      (data: any) => {

        let myFlightBooking = []
        data?.forEach((value: any) => {
          if (value?.result && value.result?.Status === 1 && value.result?.Status !== null && value?.state === "CAPTURED") {
            myFlightBooking?.push(value);

            this.myFlightBooking = myFlightBooking[0];
          }
        })
      }
    )
  }

  latestBookings() {
    this.service.latestBookings().subscribe(
      (data: any) => {
        console.log(data)
        debugger
        this.myFlightBooking = data.flightBooking[0]
        this.packageBookings = data.packageBookings[0]
        this.packageBookingDetails = data.packageBookingDetails
        this.visaBookings = data.visaBookings[0]
      },
      (err) => {
        console.log(err)
      }
    )
  }

  proUpload() {
    this.openInput.nativeElement.click()
  }


  getStatuss() {
    let successfulBookings = {
      TokenId: this.myFlightBooking.TokenId,
      PNR: this.myFlightBooking.PNR
    }
    let dataSummy = []
    dataSummy.push(successfulBookings)

    this.router.navigate(['/summury', JSON.stringify(dataSummy)])

  }

  visaBookingVIew() {
    this.router.navigateByUrl(`/visaBookingVIew/${this.visaBookings._id}`)
  }
  packageBookingVIew() {
    this.router.navigateByUrl(`/packageBookingVIew/${this.packageBookings._id}`)
  }



  Inssearch(event: any) {
    let data = {
      name: event.target.value
    }
    this.service.Inssearch(data).subscribe(
      (data: any) => {
        let allData = []

        for (const obj of data) {

          obj._id = null
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData

      }
    )
  }

  selectedCountry(item) {
    debugger
    this.selectedFlag = `data:image/png;base64,${item.flag}`
    this.selectedCountryCode = item.code
    this.callingCode = item.callingCode
  }

  closeModels() {
    this.profile()
    this.getUserProfileList()
  }

  otp: string[] = new Array(6).fill('');
  isOtpComplete: boolean = false;

  @ViewChildren('otp0, otp1, otp2, otp3, otp4, otp5') otpInputs: QueryList<ElementRef>;

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 0 && index < 6) {
      this.otp[index] = input.value; // Update the otp array with the entered digit
      this.otpInputs.toArray()[index + 1].nativeElement.focus();
    } else if (event.key === 'Backspace' && index > 0) {
      this.otpInputs.toArray()[index - 1].nativeElement.focus();
    } else {
      this.otp[index] = ''; // Clear the corresponding otp array element if backspace is pressed
    }
    this.checkOtpCompletion(); // Check if the OTP is complete
    if (index === 6) {
      console.log('Final OTP:', this.otp.join('')); // Log the final OTP after the last digit
    }
  }

  checkOtpCompletion(): void {
    this.isOtpComplete = this.otp.every(digit => digit !== '');
    if (this.isOtpComplete) {
      console.log('Final OTP:', this.otp.join(''));
    }
  }

  clearOtp(): void {
    this.otp = new Array(6).fill('');
    this.otpInputs.first.nativeElement.focus();
    this.isOtpComplete = false;
  }



  sendRequestForMobileNoChange() {
    this.service.getOtpToChangeMobileNo().subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.mobleNoChangeStatus = 1
        setTimeout(()=>{
          this.selectFirstOTP.nativeElement.focus()
        },2000)
      },
      (err) => {
        this.service.error(err.error.error)
      }
    )
    this.mobleNoChangeStatus = 1
  }

  verifyOtpFOrMobile() {
    debugger
    let otps = this.otp.join('')
    this.service.verifyOtpFOrMobile({ otp:otps }).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.mobleNoChangeStatus = 2
        setTimeout(()=>{
          this.inputSelects.nativeElement.focus()
        },1200)
        this.clearOtp()
      }, (err) => {
        this.service.error(err.error.error)
      }
    )
  }

  getNewNumberForOTP(){
    let sendObj = {
      countryCode :this.callingCode,
      mobile:this.newNumber
    }

    this.service.getOtpToAddNewMobileNo(sendObj).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.mobleNoChangeStatus = 3
        setTimeout(()=>{
            this.selectSecondOTP.nativeElement.focus()
        },1200)
      }, (err) => {
        this.service.error(err.error.error)
      }
    )
  }

  verifyOtpFOrNewMobile() {
    debugger
    let otps = this.otp.join('')
    this.service.verifyOtpFOrNewMobile({ otp:otps }).subscribe(
      (data: any) => {
        this.service.success(data.message)
        this.mobleNoChangeStatus = 0
        this.profile()
        this.getUserProfileList()
        this.closeNewNumber.nativeElement.click()
        this.clearOtp()
      }, (err) => {
        this.service.error(err.error.error)
      }
    )
  }



  cancelUpdateMobile(){
    this.mobleNoChangeStatus = 0
  }



}
