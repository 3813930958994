import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { bookTicket } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';

declare var bootstrap: any;

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent {
  form: FormGroup;
  isCOnfirm: boolean = false
  // isVerified:any
  passportDetails :any =[]
  editData :any =[]
  editDatas:FormGroup
  nationality: string
  @ViewChild('AddPassinger') AddPassinger!: ElementRef;
  dynamicControlNames: string[] = [];
  isPageLoaded: boolean = false
  @ViewChild('travellerDetails', { read: ElementRef }) travellerDetails: ElementRef;
  selectedDate: any
  bookTicketObj = new bookTicket()
  // sourceTo:any =[]
  mobileNumber: any
  fareRule: any = []
  PageInfo: any = []
  modifiedData: any = []
  ticketDetails: any = []
  fareRuleINfo: any = []
  fareBreak: any = []
  finalPrice: number = 0
  dynamicForm: FormGroup
  currencyTupe: string = 'USD'
  myGender: number = 1
  IsPassportFullInfoReq: boolean
  minDate = new Date()
  segmentDetails: any
  BsDatepickerConfig: Partial<BsDatepickerConfig>
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe,
    private config: NgSelectConfig, private renderer: Renderer2, private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService) {

    form: FormGroup;
    // this.form = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   countryCode: ['', Validators.required]
    // });
    // this.config.notFoundText = 'City not found';
    // this.config.bindValue = 'value';
    this.BsDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      minDate: new Date()
    })
  }
  ngOnDestroy(): void {
    this.ngxService.stop()
  }


  ngAfterViewInit() {
    // this.scrollToSection(this.travellerDetails.nativeElement);
    const buttons = document?.querySelectorAll('.accordion-button');

    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        button.querySelector('.accordion-icon').classList.toggle('collapsed');
      });
    });

    // Initialize Bootstrap accordion
    const accordion = new bootstrap.Accordion(document?.getElementById('accordionFlushExample'));
  }
  gstNo: boolean = false
  // Mobile start
  isClicked = false;
  finalData: any = []
  passingerDetails: any = []
  dataCass: string = 'my-filed2'
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  phoneForm: FormGroup
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates];


  changePreferredCountries() {
    this.preferredCountries = [CountryISO.UnitedArabEmirates];
  }

  myInfo() {
     
    this.isClicked = true;
    let phone = this.phoneForm.value.phone.nationalNumber
    let countryCode = this.phoneForm.value.phone.dialCode
    localStorage.setItem('homePhone', phone)
    localStorage.setItem('countryCode', countryCode)
  }


  // Mobile end

  // select Country start///
  cars = [
    { id: 'IN', name: 'INDIA' },
    { id: 'USA', name: 'AMERICA' },
    { id: 'DEL', name: 'Delhi' },
    { id: 'TIR', name: 'Tirupati' },
    { id: 'DXB', name: 'Dubai' },
    { id: 'SYD', name: 'Sydney' },
  ];
  // select Country end///
  ngOnInit(): void {
    // alert(this.isVerified)
    window.scrollTo(0, 0);
    // this.ngxService.start()
    this.isPageLoaded = true
    this.loadScripts()
    let Fares = localStorage.getItem('FareRule')
    this.route.paramMap.subscribe((data: any) => {
       
      let FinalINfo = JSON.parse(data.params.data)
   
      this.finalData = FinalINfo
      this.passingerDetails = this.finalData.Itinerary.Passenger
       
      this.passingerDetails?.forEach((ele)=>{
        ele.IdDetails?.forEach((info)=>{
          ele.IssuedCountryCode = info.IssuedCountryCode
          ele.ExpiryDate = info.ExpiryDate
        })
      })
      this.fareRule = JSON.parse(Fares)
       
      this.ticketDetails = JSON.parse(Fares)
      this.bookTicketObj.IPAddress = this.ticketDetails.ipaddress
      this.bookTicketObj.TokenId = this.ticketDetails.TokenId
      this.bookTicketObj.TrackingId = this.ticketDetails.TrackingId
      this.bookTicketObj.EndUserBrowserAgent = this.ticketDetails.EndUserBrowserAgent
      this.bookTicketObj.PointOfSale = this.ticketDetails.PointOfSale
      this.bookTicketObj.RequestOrigin = this.ticketDetails.RequestOrigin
      this.bookTicketObj.UserData = this.ticketDetails.UserData
      this.bookTicketObj.ResultId = this.ticketDetails.ResultId
      this.bookTicketObj.PNR = ''
      this.getFareData(this.fareRule)
      this.form = this.formBuilder.group({
        fields: this.formBuilder.array([])
      });

    })

    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });
    this.editDatas = new FormGroup({
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      Title: new FormControl('', [Validators.required]),
      Gender: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.required]),
      Mobile1CountryCode: new FormControl('', [Validators.required]),
      Mobile1: new FormControl('', [Validators.required]),
      IdType: new FormControl('', [Validators.required]),
      PassportNo: new FormControl('', [Validators.required]),
      IssueDate: new FormControl('', [Validators.required]),
      IssuedCountryCode: new FormControl('', [Validators.required]),
      Nationality1: new FormControl('', [Validators.required]),
      DateOfBirth: new FormControl('', [Validators.required]),
      ExpiryDate: new FormControl('', [Validators.required]),
    });

    this.dynamicForm = this.formBuilder.group({
      formFields: this.formBuilder.array([Validators.required]) // Use a FormArray to dynamically add/remove form controls
    });

    this.addForm();
    this.totalNoOfPasingers()
    // this.addFormField()
  }


  get fields() {
    return this.form.get('fields') as FormArray;
  }

  addForm() {
    const formGroup = this.formBuilder.group({
      Title: ['', Validators.required],
      Type: ['', Validators.required],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      Email: ['', Validators.required],
      Mobile1: ['', Validators.required],
      Contact: ['', Validators.required],
      Gender: ['', Validators.required],
      PassportNo: ['', Validators.required, Validators.pattern('^[0-9]{6,15}$')],
      Mobile1CountryCode: [''],
      Nationality: [],
      Country: [],
      IdDetails: [],
      City: [],
      Nationality1: [''],
      IssueDate: [''],
      ExpiryDate: [''],
      IdType: [''],
      IssuedCountryCode: [],
      PassportExpiry: []

    });
    this.fields.push(formGroup);
  }

  removeForm(index: number) {
    this.fields.removeAt(index);
  }

  national(e) {
    this.nationality = e.target.value
  }
  onDateChange(event: Date): void {

    // Perform additional logic or update component properties
  }
  issueDate(event: Date): void {
  
    // Perform additional logic or update component properties
  }

  onSubmit() {
     

    if (this.form.invalid) {
      // Mark all fields as touched to trigger validation and error messages
      this.form.markAllAsTouched();
      return;
    }

     
    for (let i = 0; i < this.form.value.fields.length; i++) {
       
      let number = this.form.value.fields[i]?.Contact.number
      this.form.value.fields[i].Mobile1 = this.form.value.fields[i]?.Contact.number
      this.form.value.fields[i].Mobile1CountryCode = this.form.value.fields[i]?.Contact.dialCode
      // this.form.value.fields[i].Fare = this.PageInfo.Fare
      // this.form.value.fields[i].FareBreakdown = this.PageInfo.FareBreakdown
      if (this.form.value.fields[i].Type === '1') {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType1[0]
      }
      if (this.form.value.fields[i].Type === '2') {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType2[0]
      }
      if (this.form.value.fields[i].Type === '3') {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType3[0]
      }
      this.form.value.fields[i].PassportExpiry = this.form.value.fields[i].ExpiryDate

      let Nationality = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      let Country = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      let City = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      const date = new Date(this.form.value.fields[i].IssueDate);
      const normalDate = date.toDateString();
      const date2 = new Date(this.form.value.fields[i].ExpiryDate);
      const normalDate2 = date2.toDateString();
      let IdDetails = Object.assign(
        [
          {
            IdType: this.form.value.fields[i].IdType,
            IdNumber: this.form.value.fields[i].PassportNo,
            IssuedCountryCode: this.form.value.fields[i].IssuedCountryCode,
            IssueDate: normalDate,
            ExpiryDate: normalDate2,
          }
        ]
      )

      this.form.value.fields[i].Nationality = Nationality
      this.form.value.fields[i].Country = Country
      this.form.value.fields[i].City = City
      this.form.value.fields[i].IdDetails = IdDetails


      if (this.form.value.fields[i].Type === "1") {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType1];
      }
      if (this.form.value.fields.Type === "2") {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType2];
      }
      if (this.form.value.fields.Type === "3") {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType3];
      }
  
    }

     

  }

  loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  totalNoOfPasingers() {
     
    let totalPassengerCount = 0;
    for (const fare of this.fareRule?.FareBreakdown) {
      totalPassengerCount += fare.PassengerCount;
    }

    for (let i = 0; i < totalPassengerCount - 1; i++) {

    }
    // alert(totalPassengerCount)
  }

  getFareData(data) {
     
    this.service.FareQuote(data).subscribe(
      (res: any) => {

        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
        }
        else {
           
          this.isPageLoaded = false
          this.IsPassportFullInfoReq = res.data.IsPassportFullInfoReq
          this.PageInfo = res.data.Result[0]
           
          this.fareBreak = res.data.Result[0].FareBreakdown
          this.finalPrice = res.data.Result[0].Fare.TotalFare
          this.currencyTupe = res.data.Result[0].Fare.AgentPreferredCurrency
          this.segmentDetails = this.PageInfo.Segments[0]
   
          let totalPassengerCount = 0
          res.data.Result[0].FareBreakdown.forEach((value) => {


            totalPassengerCount += value.PassengerCount

          })
          // alert(totalPassengerCount)
          this.fareDevideByPassinger()
          // this.fareRules()
          if (res.data.Result[0].IsLcc == true) {
            // alert("is LCC true -->  Ticket LCC")

          }
          if (res.data.Result[0].IsLcc == false) {

            // alert("is LCC false go to book & -> ticket method")
          }
        }
      }
    )
  }

  fareDevideByPassinger() {
     
    const modifiedData = {
      PassengerType1: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
      PassengerType2: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
      PassengerType3: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
    };
  
    this.modifiedData = modifiedData
  }



  divideTotalFareByPassengerCount(passengerData) {
    return passengerData.map(passenger => {
      return {
        ...passenger,
        Currency: passenger.Currency,
        PassengerType: passenger.PassengerType,
        PassengerCount: passenger.PassengerCount,
        TotalFare: passenger.TotalFare / passenger.PassengerCount,
        OtherCharges: passenger.OtherCharges / passenger.PassengerCount,
        AgentMarkup: passenger.AgentMarkup / passenger.PassengerCount,
        ServiceFee: passenger.ServiceFee / passenger.PassengerCount,
        BaseFare: passenger.BaseFare / passenger.PassengerCount,
        Tax: passenger.Tax / passenger.PassengerCount,
        PenaltyAmount: passenger.PenaltyAmount / passenger.PassengerCount,
        CreditCardCharge: passenger.CreditCardCharge / passenger.PassengerCount,

      };
    });

  }



  fareRules() {
    this.service.FareRule(this.fareRule).subscribe(
      (res: any) => {

        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
        }
        else {

        }
      }
    )
  }

  ihaveGst(number: boolean) {

    this.gstNo = number
  }


  get formFields(): FormArray {

    return this.dynamicForm?.get('formFields') as FormArray;
  }

  addFormField() {
    let formField = this.formBuilder.group({
      Type: ['', Validators.required],
      Title: [''],
      FirstName: ['', Validators.required],
      LastName: [''],
      Mobile1: [this.mobileNumber],
      Mobile1CountryCode: [''],
      DateOfBirth: [''],
      PassportNo: [''],
      CountryCode: [''],
      CountryName: [''],
      AddressLine1: [''],
      Gender: [''],
      Email: [''],
      IdType: [],
      IdNumber: [''],
      IssuedCountryCode: [],
      IssueDate: [''],
      ExpiryDate: [''],
      PassportExpiry: [''],
      Meal: [''],
      Fare: [''],
      FareBreakdown: ['']

    });

    this.formFields.push(formField);

  }

  removeFormField(index: number) {
    this.formFields.removeAt(index);
  }


  submit() {

    this.ngxService.start()
    // this.router.navigate(['/verification', JSON.stringify(this.bookTicketObj)])
     
    if (this.PageInfo.IsLcc == true) {
      this.service.Booking_Ticket(this.finalData).subscribe(
        (data: any) => {
      
          if (data.data.IsSuccess === true
            || data.data.Status == 1
            || data.data.Status == 2
            || data.data.Status == 5
            || data.data.Status == 8) {
             
            if (data.data.Status == 1) {
              this.service.success('Ticket booked successfully ')
            }
            if (data.data.Status == 2) {
              this.service.success('Ticket Not Saved ')
            }
            if (data.data.Status == 5) {
              this.service.success('Ticket In Progerss ')
              if (data.data.Status == 8) {
                this.service.success('Ticket Price Changed ')
              }
              this.service.success('Ticket booked successfully ')
              localStorage.setItem('newPNR', data.data.PNR)
              localStorage.setItem('newToken', data.data.TokenId)
              this.ngxService.stop()
              this.router.navigateByUrl('/summury')
            }

          }
          else if(data.error){
            for (let i = 0; i < data.data.Errors.length; i++) {
              if (data.data.Errors[i]) {
                this.service.error(data.data.Errors[i].UserMessage)
                this.ngxService.stop()
              }

            }
          }
          else {
            for (let i = 0; i < data.data.Errors.length; i++) {
              if (data.data.Errors[i]) {
                this.service.error(data.data.Errors[i].UserMessage)
                this.ngxService.stop()
              }

            }
          }
        }
      )
    }
    else {

      this.service.Booking_Book(this.finalData).subscribe(
        (data: any) => {
         
           
          if (data.data.Errors.length > 0) {
            for (let i = 0; i < data.data.Errors.length; i++) {
              if (data.data.Errors[i]) {
                this.service.error(data.data.Errors[i].UserMessage)
                this.ngxService.stop()
              }
            }
          }

          else {
            this.finalData.PNR = data.data.PNR

            this.service.Booking_Ticket(this.finalData).subscribe(
              (data: any) => {
         
                 
                if (data.data.IsSuccess === true || data.data.Status == 1 || data.data.Status == 2 || data.data.Status == 5 || data.data.Status == 8) {
                   
                  this.service.success('Ticket booked successfully ')
                  localStorage.setItem('newPNR', data.data.PNR)
                  localStorage.setItem('newToken', data.data.TokenId)
                  this.ngxService.stop()
                  this.router.navigateByUrl('/summury')
                }
                if (data.data.Errors) {
                  for (let i = 0; i < data.data.Errors.length; i++) {
                    if (data.data.Errors[i]) {
                      this.service.error(data.data.Errors[i].UserMessage)
                      this.ngxService.stop()
                    }
                  }
                }
                if (data.data.status === 2) {
                  this.service.error('Booking has got failed due to any Technical / Supplier reason')
                  this.ngxService.stop()

                }
                if (data.data.status === 5) {
                  this.service.error('are/Schedule has got changed')
                  this.ngxService.stop()

                }
                if (data.data.status === 9) {
                  this.service.error('PNR has been created successfully but canceled automatically due  to supplier response')
                  this.ngxService.stop()

                }



              }
            )



          }

        }
      )

    }


  }


  scrollToSection(element: HTMLElement) {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  CheckValue() {

    for (let i = 0; i < this.dynamicForm.value.formFields.length; i++) {
       
      let number = this.dynamicForm.value.formFields[i].Mobile1.number
      let objStr = this.dynamicForm.value.formFields[i].Mobile1.toString()
      let users1 = Object.assign([
        this.dynamicForm.value.formFields[i].Mobile1CountryCode = this.dynamicForm.value.formFields[i].Mobile1.dialCode,

        this.dynamicForm.value.formFields[i].Mobile1 = number,

      ])
     
    }


  }


  mobileNumbers(event) {
    this.mobileNumber = event.target.value
  }

  addDynamicControl(type: string) {
    const controlName = type + this.dynamicControlNames.length;
    const control = new FormControl('', Validators.required);
    this.form.addControl(controlName, control);
    this.dynamicControlNames.push(controlName);
  }

  onIntlTelInputChange(event: any) {
    const countryData = event?.value;
    if (countryData && countryData.dialCode) {
      this.form.patchValue({ countryCode: countryData.dialCode }, { emitEvent: false });
    }
  }

  consle() {
  

  }

  selectGender(e) {
    this.myGender = e
  }

  previeCheck() {
    this.isCOnfirm = true;
    // this.onSubmit()
    window.scrollTo(0, 0);
  
    // this.submit()
  }

  isIsEdit(data) {

  
     
    this.editData = data
     
    data.IdDetails?.forEach((value:any)=>{
      
      this.passportDetails =  value

    })
    
  }

  isIsConfirmed() {
    window.scrollTo(0, 0);
    this.isCOnfirm = false;
    this.submit()
  }

  handleVerification(isVerified) {
    // Handle the event logic here
  
    if (isVerified === 'true') {
      this.isCOnfirm = false
    }
  }
consl(){
   
if(!this.editDatas.value.Nationality1){
  this.service.error('Select Nationality')
}
if(!this.editDatas.value.Nationality1){
  this.service.error('Select Nationality')
}
else{
  
  this.finalData?.Itinerary.Passenger.forEach((value:any)=>{
    if(value?.FirstName === this.editDatas.value.FirstName){
      value.IdDetails?.forEach((element)=>{
        element.IdNumber = this.editDatas.value.PassportNo
        element.IdType = this.editDatas.value.IdType
        element.IssueDate = this.editDatas.value.IssueDate
        element.IssuedCountryCode = this.editDatas.value.IssuedCountryCode
        element.ExpiryDate = this.editDatas.value.ExpiryDate
        value.IssuedCountryCode = this.editDatas.value.IssuedCountryCode
        
        this.passportDetails =  element
      })
      value.Nationality1 = this.editDatas.value.Nationality1;
      if( value.City?.CountryCode ){
        value.City.CountryCode = this.editDatas.value.Nationality1
      }
      else{
        value.City.CountryCode = this.editDatas.value.Nationality1
      }
      value.City.CountryCode = this.editDatas.value.Nationality1
      value.City.CountryName = this.editDatas.value.Nationality1
      value.Country.CountryCode = this.editDatas.value.Nationality1
      value.Country.CountryName = this.editDatas.value.Nationality1
      value.Nationality.CountryCode = this.editDatas.value.Nationality1
      value.Nationality.CountryName = this.editDatas.value.Nationality1
    }
  })

  this.passingerDetails = this.finalData.Itinerary.Passenger

}
 
}

}
