import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.css']
})
export class CancelledComponent {
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private service: AuthService, private ngxService: NgxUiLoaderService) { }
  ngOnInit(): void {
    this.openDialog()
  }

  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color',
      width: '600px',
      height: '300px'

    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
  gotoHome() {
    this.closeDialog()
    this.router.navigateByUrl('/home')
  }

}
