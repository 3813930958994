import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  key = 'informa';
  isLoad: boolean = false;
  testmonialisLoding :boolean =false
  constructor() { }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.loadScripts()
    window.scrollTo(0, 0);
    AOS.init();
   
    setTimeout(() => {
      this.isLoad = true
    }, 1000)

    setTimeout(() => {
      this.testmonialisLoding = true
    }, 4000)
  }
  


  loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        // document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }



}
