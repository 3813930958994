import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-visa-booking-view',
  templateUrl: './visa-booking-view.component.html',
  styleUrls: ['./visa-booking-view.component.css']
})
export class VisaBookingViewComponent implements OnInit{
  visaId:any
  imageUrl:any
  guests:any = []
  selectedCurrency:any  
  visaDetails:any = []
  visaUserDetails:any = []
  constructor(private route:ActivatedRoute,private service:AuthService){
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
  }
  ngOnInit(): void {
    this.params()
  }

  

  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }

  params(){
    this.route.params.subscribe(
      (data:any)=>{
         
        this.visaId = data.id
        if(this.visaId){
          this. getVisaDetailsById()
          this.getRelatedGuests()
       
        }
        
      }
    )
  }

  getVisaDetailsById(){
    this.service.VisaBooking(this.visaId).subscribe(
      (data:any)=>{
        this.visaDetails= data[0]
        this.visaUserDetails = data[0]?.visaUserDetails
        if(this.visaDetails.status === 4){
          this.imageUrl = this.visaDetails.visaImg
        }
  
      }
    )
  }

  downloadPassport(data:any){
     
    window.open(data, '_blank');
  }
  downloadVisa(){
     
    window.open(this.imageUrl, '_blank');
  }
  getRelatedGuests(){
    let info ={
      visaId:this.visaId
    }
    this.service.getVisaBookigsByRefID(info).subscribe(
      (data:any)=>{
        this.guests = data
      }
    )
  }
  changeGuestData(data){
    this.visaId = data.target.value
    if(this.visaId){
      this. getVisaDetailsById()
    }
  }
  getInvoiceForVIsa(){
    let info = {
      visaId:this.visaId
    }
    this.service.getInvoiceForVIsa(info).subscribe(
      (data:any)=>{
        this.service.success(data.message)
      },
      (err)=>{
        this.service.error(err.error.message)
      }
    )
  }



  getDownloadInvoiceForVIsa(){
    let inputId ={
      visaId: this.visaId
    }
  this.service.getDownloadInvoiceForVIsa(inputId)
  .subscribe((response: Blob) => {
    // Check if the response is a valid Blob
    if (response instanceof Blob) {
      const blob = new Blob([response], { type: 'application/pdf' });

      // Create a blob URL for the PDF
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Visa invoice.pdf'; // Set the desired filename

      // Programmatically trigger the download
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Invalid response type. Expected a Blob.');
    }
  },
  error => {
    console.error('Error downloading the PDF:', error);
  });
}

}
