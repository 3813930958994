import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-package-booking-view',
  templateUrl: './package-booking-view.component.html',
  styleUrls: ['./package-booking-view.component.css']
})
export class PackageBookingViewComponent {
  visaId:any
  selectedCurrency:any
  imageUrl:any
  packageDetails:any = []
  status:any = []
  packageUserDetails:any = []
  PackageDetails:any = []
  constructor(private route:ActivatedRoute,private service:AuthService){
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
  }
  ngOnInit(): void {
    this.params()
  }

  params(){
    this.route.params.subscribe(
      (data:any)=>{
         
        this.visaId = data.id
        if(this.visaId){
          this. getVisaDetailsById()
        }
      }
    )
  }
  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  getVisaDetailsById(){
    this.service.getBookingByID(this.visaId).subscribe(
      (data:any)=>{
        this.packageDetails= data[0]
        this.status = data[0]?.status
        this.packageUserDetails =  data[0]?.packagesUserDetails
        this.PackageDetails =  data[0]?.PackageDetails[0]
   
      }
    )
  }

  packageInvoice(){
    let sendData = {
      Pid: this.visaId
    }
    this.service.getPackageInvoices(sendData).subscribe(
      (data:any)=>{
        this.service.success(data.message)
      },
      (err)=>{
        this.service.error(err.error.message)
      }
    )
  }

  downloadPassport(data:any){
     
    window.open(data, '_blank');
  }
  downloadVisa(){
     
    window.open(this.imageUrl, '_blank');
  }

  downloadPackageInvoice(){
      let inputId ={
        Pid: this.visaId
      }
    this.service.downloadPackageInvoice(inputId)
    .subscribe((response: Blob) => {
      // Check if the response is a valid Blob
      if (response instanceof Blob) {
        const blob = new Blob([response], { type: 'application/pdf' });
  
        // Create a blob URL for the PDF
        const url = window.URL.createObjectURL(blob);
  
        // Create an anchor element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'invoice.pdf'; // Set the desired filename
  
        // Programmatically trigger the download
        link.click();
  
        // Clean up resources
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Invalid response type. Expected a Blob.');
      }
    },
    error => {
      console.error('Error downloading the PDF:', error);
    });
  }


}
