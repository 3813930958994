<div class="allPages">
<div class="staticOfferPage">
    <div class="container">
        <div class="image-viewer-section">
            <div class="row">
                <div class="col-md-8">
                    <div class="gallery">
                        <div class="thumbnails">
                            <img class="thumbnail" *ngFor="let image of images; let i = index" [src]="image"
                                [alt]="'Thumbnail ' + (i + 1)" (click)="showImage(image, i)" />
                            <div class="bottom-arrows">
                                <span class="left-arrow">
                                    <i class="fas fa-chevron-left prev-icon" (click)="previousImage()"></i>
                                </span>
                                <span class="right-arrow">
                                    <i class="fas fa-chevron-right next-icon" (click)="nextImage()"></i>
                                </span>
                            </div>
                        </div>
                        <div class="main-image">
                            <img id="mainImg" [src]="mainImage" alt="Main Image" />
                        </div>
                    </div>
                </div>
                <div #openPasingers class="col-md-4">
                    <div class="PackagesCost-DataBelow">
                        <div class="packagesCost-content">
                            <p class="package-cost">Package Cost</p>
                            <ul class="Packageprice-guests">
                                <li class="price-lists">
                                    <span class="cost-data">{{selectedCurrency}} {{packageDetails.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}}</span>
                                </li>
                                <li class="price-lists">
                                    <p class="No-ofGuests">
                                        <img class="guest-icon" src="./../../../assets/staticImages/user.svg" alt="" />
                                        {{packageDetails.touristCount}} x Guests
                                    </p>
                                </li>
                            </ul>
                            <!-- <p class="taxes-fees">+₹780 taxes & fees</p> -->
                            <p class="dayNight-package">{{packageDetails.daysCount}}D | {{packageDetails.nightsCount}}N
                                Package</p>
                        </div>
                        <!-- <div class="dropdown">
                            <a class="btn btn-secondary dropdown-toggle toggleTravel"  role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"  aria-expanded="false">
                             {{selectTravelDate }}
                            </a>
                            <p  *ngIf="travelDateBooliean" class="selectPlease">Please Select Travel Date</p>

                            <ul (click)="onDropdownClickReturn($event)" class="dropdown-menu" style="padding: 0;" aria-labelledby="dropdownMenuLink"  >
                                <mat-card class="demo-inline-calendar-card">
                                    <mat-calendar [(selected)]="selectTravel" (selectedChange)="onDateSelected($event)"></mat-calendar>
                                  </mat-card>
                            </ul>
                          </div> -->
                          <div  class="Pform-boxes Pright-borders">
                            <div class="dropdown-toggle Pd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <label for="citizen" class="d-block Pcity-label">START DATE</label>
            
                              <h1 class="Psearch-cities">
                                <img class="Psearch-iccon" src="./../../../assets/images/m/calender.svg" alt="" />{{ selectTravel | date: 'dd' }}<small
                                  class="Pdate-data">{{ selectTravel | date: 'MMM' }},{{ selectTravel | date: 'yyyy' }}</small>
                                <span class="Pweek-days">{{ selectTravel | date: 'EEEE' }}</span>
                              </h1>
                            </div>
                          
                            <ul  id="dropdownContainer" (click)="onDropdownClickReturn($event)" class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">
            
            
                              <div class="packageCallender">
            
                                <mat-card (click)=" getFormattedDate()"  class="demo-inline-calendar-card">
                                  <mat-calendar [minDate]="todayDate" [(selected)]="selectTravel" (selectedChange)="onDateSelected($event)"></mat-calendar>
            
                                </mat-card>
            
            
                              </div>
            
            
            
                            </ul>
                          </div>
                        <button *ngIf="!packageDetails?.isCorporate" (click)="gotoBook()" class="QueryBtn-edit" type="button" >
                         BOOK NOW
                        </button>
                        <button  (click)="tryLogin()" class="QueryBtn-edit" type="button" >
                            <img class="query-icon" src="./../../../assets/staticImages/headphone.svg" alt=""
                                no-image />SEND QUERY
                        </button>
                  
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content addQueryForm">
                                    <div class="modal-header Header-top">
                                        <h5 class="modal-title inquiry-title title-forms" id="exampleModalLabel">
                                            Fill Inquiry Form
                                        </h5>
                                        <button #closeModels type="button" class="btn-close closeBtn-edit" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <form  [formGroup]="reqform"(ngSubmit)="onSubmit()" class="modal-body form-content-below">
                                        <div class="mb-1 mb-md-3 form-inpBoxes">
                                            <label for="exampleFormControlInput1"
                                                class="form-label BoxLavel-titles">Full Name</label>
                                            <input formControlName="name" type="text" class="form-control inputBox-insideText inp-box"
                                                id="exampleFormControlInput1" placeholder="Name" />
                                        </div>
                                        <div class="mb-1 mb-md-3 form-inpBoxes">
                                            <label for="exampleFormControlInput2"
                                                class="form-label BoxLavel-titles">Email ID</label>
                                            <input formControlName="emailId" type="email" class="form-control inputBox-insideText inp-box"
                                                id="exampleFormControlInput2" placeholder="Enter your mail id" />
                                        </div>
                                        <div class="mb-1 mb-md-3 form-inpBoxes">
                                            <label for="phoneNumber" class="form-label BoxLavel-titles">Contact
                                                No.</label>
                                            <div class="input-group phone-top mb-1 mb-md-3">
                                                <div class="dropdown dribFlag">
                                                    <button type="button " id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown" aria-expanded="false"
                                                        class="btn FormSelect dropdown-toggle countryFlag-btn">
                                                        <img alt="no-img" class="Flag-Images"
                                                            [src]="selectedFlag" />
                                                    </button>
                                                    <ul aria-labelledby="dropdownMenuButton1"
                                                        class="dropdown-menu DropDown-FlagMenu">
                                                        <li class="DropDownFlag-List">
                                                            <div class="input-group search-box mb-3">
                                                                <input type="text" formControlName="search" [(ngModel)]="searchText" class="form-control search-input inp-box"
                                                                    id="searchInput" placeholder="Search..." />
                                                                <a type="button" class="input-group-text search-icon"><i
                                                                        class="fas fa-search"></i></a>
                                                            </div>
                                                            <!-- <button type="submit" class="btn btn-primary">Search</button> -->
                                                        </li>
                                                        <li *ngFor="let num of allMobileFlags | filter:searchText" (click)="selectCountryCode(num)" class="DropDownFlag-List">
                                                            <a  class="dropdown-item"><img   style="padding-right:10px;"
                                                                    class="Flag-Images"
                                                                  
                                                                    [src]="'data:image/png;base64,' + num.flag" />
                                                                <span><b>{{num.callingCode }}</b></span>
                                                            </a>
                                                        </li>
                                              
                                                    </ul>
                                                </div>
                                                <input type="number" class="form-control phoneInput-number inp-box"
                                                    id="phoneNumber" formControlName="mobile" placeholder="Enter your phone number" />
                                            </div>
                                        </div>
                                        <div class="mb-1 mb-md-3 form-inpBoxes">
                                            <label for="exampleFormControlTextarea1"
                                                class="form-label BoxLavel-titles">Description</label>
                                            <textarea formControlName="description" class="form-control description-box inp-box"
                                                id="exampleFormControlTextarea1" placeholder="Description"
                                                rows="3"></textarea>
                                        </div>
                                        <div class="callbackBtn-below">
                                            <button type="submit" class="callback-btn">Get a Callback</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="destination-data-below">
            <h4 class="phuket-getaway">{{packageDetails.title}}</h4>

            <!-- <span class="N-Krabi">2N Krabi</span> -->
            <ul class="stayFor-dayNight d-flex">
                <li class="N-Krabi">{{packageDetails.daysCount}}D</li>
                <li class="right-phuket">{{packageDetails.nightsCount}}N</li>
            </ul>

            <div class="destinationOverview-data">
                <ul class="nav nav-tabs btnsTop-nav" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link edit-tabBtns active" id="overview-tab" data-bs-toggle="tab"
                            data-bs-target="#overview" type="button" role="tab" aria-controls="overview"
                            aria-selected="true">
                            OVERVIEW
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link edit-tabBtns" id="profile-tab" data-bs-toggle="tab"
                            data-bs-target="#itinerary" type="button" role="tab" aria-controls="itinerary"
                            aria-selected="false">
                            ITINERARY
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link edit-tabBtns" id="accomodation-tab" data-bs-toggle="tab"
                            data-bs-target="#accomodation" type="button" role="tab" aria-controls="accomodation"
                            aria-selected="false">
                            ACCOMMODATION
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link edit-tabBtns presentation-btn" id="inclusion-tab" data-bs-toggle="tab"
                            data-bs-target="#inclusion" type="button" role="tab" aria-controls="inclusion"
                            aria-selected="false">
                            INCLUSION & EXCLUSION
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        <div *ngFor="let item of cityDetails" class="destination1-detailsInside">
                            <div class="overviw-content-top">
                                <div class="destination-imgInside">
                                    <div class="image-contentnew">
                                        <img [src]="item.cityImage" alt="dubai-image" class="overview-bottom-image" />
                                        <div class="overlay"></div>
                                    </div>

                                    <div class="imageAbove-content">
                                        <h4 class="blisfulBali-title">{{item.cityTitle}}</h4>
                                        <p class="three-night">{{item.cityDays}}Days {{item.cityNights}}Nights</p>
                                    </div>
                                </div>
                                <div *ngFor="let day of item.days" class="Stay-dayCount-inside">
                                    <div class="day1box">
                                        <div class="day1-left day1box-left">
                                            <h4 class="day1-title">DAY {{day.dayNumber}}</h4>
                                        </div>
                                        <div *ngFor="let acti of day.activities" class="morning-right morning-time">
                                            <h4 class="morning-title">{{acti.activityTitle}}</h4>
                                            <h4 class="arrive-bali">
                                                {{acti.activityDescription}}
                                            </h4>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="itinerary" role="tabpanel" aria-labelledby="itinerary-tab">
                        <div class="iternary-data-top">
                            <div *ngFor="let daysData of iterationData; let j = index" class="iternary-content-inside">
                                <div class="day-data">
                                    <h4 class="multiple-DaysTitle">
                                        Day {{j+1}}
                                        <span class="destination-SubTitle">|{{daysData.cityName}}</span>
                                    </h4>
                                    <div class="d-md-flex iternary-BoxContent">
                                        <div class="flex-shrink-0 iternary-imgtop">
                                            <img class="iternaryDestination-images" [src]="daysData.image" alt="..." />
                                        </div>
                                        <div class="flex-grow-1 ms-md-3 iternaryImage-content">
                                            {{daysData.description}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="accomodation" role="tabpanel" aria-labelledby="accomodation-tab">
                        <div class="accomodation-top">
                            <div class="accomodation-data-inside">
                                <ul  *ngFor="let items of accommodationData"  class="service-types list-unstyled d-flex">
                                    <li class="left-ratings">
                                        <!-- <div class="rating-star"> -->
                                        <p class="ratings-dataInside">
                                            {{items.hotelType}} :
                                            <span *ngIf="items.starCount === 1" class="rating-star">&#9733;</span>
                                            <span *ngIf="items.starCount === 2" class="rating-star">&#9733;
                                                &#9733;</span>
                                            <span *ngIf="items.starCount === 3" class="rating-star">&#9733; &#9733;
                                                &#9733;</span>
                                            <span *ngIf="items.starCount === 4" class="rating-star">&#9733; &#9733;
                                                &#9733; &#9733;</span>
                                            <span *ngIf="items.starCount === 5" class="rating-star">&#9733; &#9733;
                                                &#9733; &#9733; &#9733;</span>
                                        </p>
                                        <!-- </div> -->
                                    </li>
                                    <li class="rightData">
                                        <p class="travelodge-data"> {{items.HotelName}}</p>
                                        <!-- <p class="travelodge-data">
                                      Bali: Travelodge Hotel Perth or similar
                                    </p> -->
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="inclusion" role="tabpanel" aria-labelledby="inclusion-tab">
                        <div class="inclusion-top">
                            <div class="inclusion-data-below">
                                <h4 class="inclusion-title">Inclusions</h4>
                                <!-- <p class="hotel-accomodate">
                                    6 nights’ hotel accommodation in selected Hotel Category
                                </p> -->
                                <ul class="inclusion-exclusionInside">
                                    <!-- <li class="IncExclusion-para">
                                    6 nights’ hotel accommodation in selected Hotel Category
                                  </li> -->

                                    <li *ngFor="let ite of inclutionData" class="IncExclusion-para">
                                        {{ite.inclusion}}
                                    </li>
                                </ul>
                            </div>
                            <div class="inclusion-data-below">
                                <h4 class="inclusion-title">Exclusions</h4>
                                <ul class="inclusion-exclusionInside">
                                    <li *ngFor="let ite of exclutionData" class="IncExclusion-para">
                                        {{ite.exclusion}}
                                    </li>

                                </ul>
                            </div>
                            <div class="inclusion-data-below">
                                <h4 class="inclusion-title">Cancellation Policy & Terms and Conditions</h4>
                                <ul class="inclusion-exclusionInside">
                                    <li *ngFor="let item of consitonData" class="IncExclusion-para">
                                        {{item?.condition}}
                                    </li>
                                 
                                </ul>
                            </div>
                            <div class="inclusion-data-below">
                                <p class="special-note">
                                    Special Note :- As of now we are not holding any hotel or
                                    flights for below. Once we receive the Final confirmation
                                    from your end, So we will process the bookings
                                    accordingly. if any case is given below hotel sold out
                                    meanwhile so we will provide you similar category hotel.
                                    Given rates are subject to availability at the time of
                                    booking.
                                </p>
                                <p class="special-note">
                                    Remarks :-Given rates are not valid in any type of event
                                    or exhibitions or festivals. If there will be any
                                    supplement charges so same will be applicable on given
                                    cost.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </div>
    </div>
</div>

<label #openEnq data-bs-toggle="modal"
data-bs-target="#exampleModal"></label>
</div>