import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { DatePipe } from '@angular/common';
import { fareQuote, fareRule } from 'src/app/models/model';

@Component({
  selector: 'app-return-flight-data',
  templateUrl: './return-flight-data.component.html',
  styleUrls: ['./return-flight-data.component.css']
})
export class ReturnFlightDataComponent {
  fareRuleObj = new fareRule()
  fareQuoteObj = new fareQuote()
  @ViewChild('opneFlight') opneFlight!: ElementRef;
  @ViewChild('closeFlight') closeFlight!: ElementRef;
  divVisibilityArray: boolean[] = [];
  isDivActive:boolean =false
  flightInfo: any = []
  flightSegments: any = []
  segements: any;
  TrackingId:string
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef,private datePipe: DatePipe,) {

  }
  ngOnInit(): void {
    setTimeout(() => {
      this.openModal()
    }, 6)

    this.route.paramMap.subscribe((data: any) => {
       
      this.flightInfo = JSON.parse(data.params.data)
      this.fareRuleObj.EndUserBrowserAgent =this.flightInfo.EndUserBrowserAgent
      this.fareRuleObj.PointOfSale =this.flightInfo.PointOfSale
      this.fareRuleObj.TokenId =this.flightInfo.TokenId
      this.fareRuleObj.RequestOrigin = this.flightInfo.RequestOrigin
      this.fareRuleObj.UserData = null
      this.fareRuleObj.ipaddress = this.flightInfo.IPAddress
    })
    
    if(this.flightInfo.JourneyType == 1 || this.flightInfo.JourneyType == 2){
      this.service.searchOneWayFlight(this.flightInfo).subscribe(
        (res: any) => {
  
          if (res.data.IsSuccess == false) {
            if (res.data.Errors[0].UserMessage !== '') {
              this.closeFlight.nativeElement.click()
              this.router.navigateByUrl('/home')
              this.toastr.error(res.data.Errors[0].UserMessage)
  
            }
            else {
              this.closeFlight.nativeElement.click()
              this.router.navigateByUrl('/home')
              this.toastr.error('some error occured')
  
            }
  
          }
          else if (res.data.Results) {
            window.scrollTo(0, 0);
            this.flightInfo = res.data.Results[0];
            this.TrackingId = res.data.TrackingId
            for (let i = 0; i < res.data.Results[0].length ; i++) {
              this.divVisibilityArray.push(false);
            }
          
            this.segements = {}
            this.flightInfo?.forEach((flight, index) => {
              this.segements[index] = {};
              this.segements[index]['totalTime'] = [];
              flight.Segments.forEach((element, juyh) => {
                this.segements[index]['totalTime'].push(element[juyh].Duration)
                this.segements[index]['totalTime'].push(element[juyh].Duration)
             
          
                if (juyh == 0) {
                  this.segements[index]['Origin'] = element[juyh].Origin.CityName
                }
             
                if (juyh !==0) {
                  this.segements[index]['MiddleOrigin'] = element[juyh].Origin.CityName
                
                }
                this.segements[index]['airLine'] = element[juyh].AirlineName
                this.segements[index]['Destination'] = element[juyh].Destination.CityName
                this.segements[index]['startTime'] = element[juyh].DepartureTime
                let time = this.datePipe.transform( this.segements[index]['startTime'],'HH:mm')!
                this.segements[index]['startTime'] = time
            
  
                this.segements[index]['arraival'] = element.ArrivalTime
                let time2 = this.datePipe.transform(  this.segements[index]['arraival'],'HH:mm')!
                this.segements[index]['arraival'] = time2
                
           
              
             
  
              });
              const totalTime = this.addTimeDurations(this.segements[index]['totalTime']);
              this.segements[index]['totalTime1'] = totalTime;
            });
  
       
  
            this.closeFlight?.nativeElement.click()
  
          }
        },
        (err) => {
  
          this.toastr.error(err.error.error.message)
        }
      )

    }
    if(this.flightInfo.JourneyType ==3){
      // alert('this is multi')
        this.service.searchOneWayFlight(this.flightInfo).subscribe(
        (res: any) => {
  
          if (res.data.IsSuccess == false) {
            if (res.data.Errors[0].UserMessage !== '') {
              this.closeFlight.nativeElement.click()
              this.router.navigateByUrl('/home')
              this.toastr.error(res.data.Errors[0].UserMessage)
  
            }
            else {
              this.closeFlight.nativeElement.click()
              this.router.navigateByUrl('/home')
              this.toastr.error('some error occured')
  
            }
  
          }
          else if (res.data.Results) {
            window.scrollTo(0, 0);
            this.flightInfo = res.data.Results[0];
            for (let i = 0; i < res.data.Results[0].length ; i++) {
              this.divVisibilityArray.push(false);
            }
         
            this.segements = {}
            this.flightInfo?.forEach((flight, index) => {
              this.segements[index] = {};
              this.segements[index]['totalTime'] = [];
              flight.Segments.forEach((element, juyh) => {
                this.segements[index]['totalTime'].push(element.Duration)
                this.segements[index]['totalTime'].push(element.Duration)
             
          
                if (juyh == 0) {
                  this.segements[index]['Origin'] = element.Origin.CityName
                }
             
                if (juyh !==0) {
                  this.segements[index]['MiddleOrigin'] = element.Origin.CityName
                
                }
                this.segements[index]['airLine'] = element.AirlineName
                this.segements[index]['Destination'] = element.Destination.CityName
                this.segements[index]['startTime'] = element.DepartureTime
                let time = this.datePipe.transform( this.segements[index]['startTime'],'HH:mm')!
                this.segements[index]['startTime'] = time
            
  
                this.segements[index]['arraival'] = element.ArrivalTime
                let time2 = this.datePipe.transform(  this.segements[index]['arraival'],'HH:mm')!
                this.segements[index]['arraival'] = time2
                
           
              
             
  
              });
              const totalTime = this.addTimeDurations(this.segements[index]['totalTime']);
              this.segements[index]['totalTime1'] = totalTime;
            });
  
          
  
            this.closeFlight?.nativeElement.click()
  
          }
        },
        (err) => {
  
          this.toastr.error(err.error.error.message)
        }
      )

    }
 
  }




  toggleDivVisibility(index: number) {
    this.divVisibilityArray[index] = !this.divVisibilityArray[index];
  }
  addTimeDurations(durations) {
    let totalSeconds = 0;

    // Convert each duration to seconds and sum them up
    for (let duration of durations) {
      const [hours, minutes, seconds] = duration.split(':');
      totalSeconds += (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
    }

    // Convert the total back to hh:mm:ss format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  // Helper function to add leading zeros
  padZero(value) {
    return value.toString().padStart(2, '0');
  }



  openModal() {
    
    this.opneFlight.nativeElement.click()
    // this.modalRef = this.modalService.show(modal);
  }
  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flighSearch.json',
  };

  getFlightInfo(data:any){
    this.fareRuleObj.ResultId = data.ResultId
    this.fareRuleObj.TrackingId = this.TrackingId

    this.service.FareQuote(this.fareRuleObj).subscribe(
      (res:any)=>{
         
    
        if(res.data.IsSuccess == false){
            this.service.error(res.data.Errors[0].UserMessage)
        }
        else{
          if(res.data.Result[0].IsLcc == true){
            // alert("is LCC true -->  Ticket LCC")
            // this.router.navigate(['/farQuote',JSON.stringify(this.fareRuleObj)])
          }
          if(res.data.Result[0].IsLcc == false){
            // alert("is LCC false go to book & -> ticket method")
            // this.router.navigate(['/farQuote',JSON.stringify(this.fareRuleObj)])
          }
        }
      }
    )
    
 
  }

  mainDiv(){
    this.isDivActive = !this.isDivActive 
  }
}
