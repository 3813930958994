<ng-template #dialogContent>
    <div>
      <span><i class="fas fa-times-circle closeIcon"></i></span> <br>
      <span class="failedTct"> {{eciDescription}}</span> <br>
  
      <p style="text-align: center;">{{paymentInfo}}</p>
      <button (click)="gotoHome()" type="submit" class="search-hotels-btn"> Try Again</button>
    </div>
  </ng-template>
  
  <!-- <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="dialog-static-name">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
  
        <div class="modal-body">
          <span><i class="fas fa-times-circle closeIcon"></i></span> <br>
          <span class="failedTct"> {{paymentInfo}}</span> <br>
          Authentication was attempted but was not or could not be completed; possible reasons being either the card or
          its Issuing Bank has yet to participate in 3DS.
  
  
          <button (click)="tryAgain()" type="submit" class="search-hotels-btn"> Try Again</button>
        </div>
      </div>
    </div>
  </div> -->
  
  
  
  
  <div class="pageLoad">
  
    <div>
      <ng-lottie class="scrlim2" width="250px" height="250px" [options]="options"
        (animationCreated)="animationCreated($event)"></ng-lottie>
      <p class="dont">Package booking...</p>
    </div>
  </div>
  
  
  <!-- <button (click)="openDialog()" type="submit" class="search-hotels-btn">OpenPop</button> -->
  