<div class="edit-page5">
  <div class="container">
    <h2 class="dubai-yatch">
      {{offerDetails?.title}}
    </h2>
    <div class="row">
      <div class="col-md-9">
        <div class="gallery">
          <div class="thumbnails">
            <img style="border-radius: 10px;" *ngFor="let item of data" class="thumbnail" [src]="item"
              (click)="showImage(item)" alt="Thumbnail 1" />
            <!-- <img
              class="thumbnail"
              src="./../../../assets/images/offerPreview/card2-image.png"
              onclick="showImage(this, 1)"
              alt="Thumbnail 2"
            />
            <img
              class="thumbnail"
              src="./../../../assets/images/offerPreview/card3-image.png"
              onclick="showImage(this, 2)"
              alt="Thumbnail 3"
            />
            <img
              class="thumbnail"
              src="./../../../assets/images/offerPreview/card1-image.png"
              onclick="showImage(this, 0)"
              alt="Thumbnail 4"
            /> -->

            <!-- Add more thumbnails here -->
          </div>
          <div class="main-image">
            <!-- <i class="fas fa-chevron-left prev-icon" (click)="previousImage()"></i> -->
            <img id="mainImg" style="border-radius: 10px;" [src]="mainImg2" alt="Main Image" />
            <!-- <i class="fas fa-chevron-right next-icon" (click)="nextImage()"></i> -->
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="editRight-Data">
          <span class="price-title">Price</span>
          <p class="price-money">${{priceUpdate}}</p>
          <!-- <p class="travelers-data">Date</p> -->
          <!-- <input class="form-control datSelect" [(ngModel)]="travelDate"  placeholder="Select Date" [bsConfig]="BsDatepickerConfig" type="text"placement="top"[readonly]="true" bsDatepicker > -->
          <div class="mb-3">


            <!-- <input style="margin-bottom: 0 !important;" #openCalnder
                
                [(ngModel)]="travelDate"
                 type="text"
                placeholder="Select Date"
                class="form-control datSelect"
                [bsConfig]="BsDatepickerConfig" 
                placement="left"[readonly]="true" bsDatepicker>
            <div  (click)="openCalnders()"
                class="input-group-append">
                <span class="input-group-text">
                    <i class="fas fa-calendar"></i>
                </span>
            </div> -->

          </div>
          <!-- <input [(ngModel)]="travelDate" class="form-control date-edit" type="date" /> -->

          <!-- <label class="travelers-data" style="padding-top: 6px;" for="">Travel Count</label> -->
          <!-- <select (change)="selectMember($event)" class="form-select drpdwn-top mmebrs" aria-label="Default select example">
            <option class="mmebrs" value="1">One</option>
            <option class="mmebrs" value="2">Two</option>
            <option class="mmebrs" value="3">Three</option>
            <option class="mmebrs" value="4">Four</option>
            <option class="mmebrs" value="5">Five</option>
            <option class="mmebrs" value="6">Six</option>
          </select> -->
          <!-- <ng-select formControlName="gender" [placeholder]="totalMembets" [(ngModel)]="totalMembets"
          class=" mmebrs"
          (change)="selectMember($event)">
          <ng-option *ngFor="let car of classCount" [value]="car.id">
              <p class="SelectedList" style="margin: 0;"> {{car.name}}
              </p>
          </ng-option>
      </ng-select> -->

          <!-- <br> -->
          <!-- <div class="btn-group" role="group" aria-label="Basic example">
          <button (click)="decreseMembers()" type="button" class="btn btn-light"><i class="fas fa-minus"></i></button>
          <button  type="button" class="btn btn-light">{{totalMembets}}</button>
          <button (click)="increseMembers()" type="button" class="btn btn-light"><i class="fas fa-plus"></i></button>
        </div> -->
          <button class="buyBtn-edit" data-bs-toggle="modal" data-bs-target="#exampleModal">Request</button>
        </div>
      </div>
    </div>
    <div class="bottomContent-section">
      <div style="padding: 0;padding-top: 30px;" class="col-md-8 col-lg-7">
        <h3 class="japan-tour">
          {{offerDetails?.title}}
        </h3>

        <p class="tourDetails ">
          <!-- <span class="quick-info">About the Leh Ladakh Tour Package from Delhi by Air:</span><br> -->
          {{offerDetails?.description}}
        </p>


        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Offer Details">


            <h1 class="commin">Coming Soon</h1>



          </mat-tab>
          <mat-tab label="Offer Fare">
            <h1 class="commin">Coming Soon</h1>
          </mat-tab>
          <mat-tab label="Cancelation">


            <h1 class="commin">Coming Soon</h1>

          </mat-tab>
          <mat-tab label="Date Change">
            <h1 class="commin">Coming Soon</h1>

          </mat-tab>
        </mat-tab-group>
        <!-- <p class="tourDetails">
          <span class="quick-info">Quick info: </span> <br> Route: Leh - Sham
          Valley - Khardungla- Nubra Valley - Pangong - Chang-la - Stok
          Village - Leh. <br />
          Duration: 7 Days & 6 Nights <br />
          Start point: Leh Airport <br />
          Endpoint: Leh Airport <br />
          Passes covered: Khardung-la (18,000 ft) and Chang-la (17,688 ft)
        </p>
        <p class="tourDetails ">
          <span class="quick-info">Note:</span> The mentioned tour package price is dynamic and will vary
          according to the Flight Charges and Date of Travel. In order to
          get the best price reach out to us by filling in the 'Send
          Enquiry' form.
        </p>
        <p class="tourDetails ">
          <span class="quick-info">Inclusions:</span> <br />
          Flight tickets from Delhi to Leh and back (Ex-Delhi) <br />
          Airport pick up and drop as per your flight timings<br />
          Comfortable and hygienic vehicle (Innova/Xylo/Similar) for
          sightseeing on all days as per the itinerary. <br />
          Stay on a double (couples and special requests) and triple sharing
          basis in hygienic and sanitized hotels/Camps <br />Buffet
          breakfast and dinner on all days <br />Assured Inner-Line Permit
          for all restricted areas <br />Wildlife Fee <br />and
          Environmental Fee Highly-Experienced driver cum guide
        </p> -->
      </div>
    </div>
  </div>
</div>



<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Fill Inquiry Form</h5>
        <button type="button" class="btn-close" #CloseModels data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="inquiryform" (ngSubmit)="inquiryForm()">

          <div class="mb-3">

            <input formControlName="name" [(ngModel)]="inquiryObj.name" type="text" class="form-control contact-link"
              placeholder="Name" id="exampleInputEmail1" aria-describedby="emailHelp" [pattern]="namePattern">
            <div class="error-message" *ngIf="inquiryform.get('name')?.invalid && inquiryform.get('name')?.hasError('required') &&
              (inquiryform.get('name')?.touched ||
                                inquiryform.get('name')?.dirty)">
              <i class="fas fa-info-circle error-icon"></i>
              Name is Required
            </div>

            <div class="error-message" *ngIf="inquiryform.get('name')?.hasError('pattern')">
              <i class="fas fa-info-circle error-icon"></i>
              Name should not include numbers & special characters
            </div>

          </div>
          <div class="mb-3">
            <input type="email" formControlName="email" [(ngModel)]="inquiryObj.email" class="form-control contact-link"
              placeholder='Email Id' id="exampleInputEmail1" aria-describedby="emailHelp">
            <div class="error-message" *ngIf="inquiryform.get('email')?.invalid && inquiryform.get('email')?.hasError('required') &&
              (inquiryform.get('email')?.touched ||
                                inquiryform.get('email')?.dirty)">
              <i class="fas fa-info-circle error-icon"></i>
              Email Id is Required
            </div>

            <div class="error-message" *ngIf="inquiryform.get('email')?.hasError('pattern')">
              <i class="fas fa-info-circle error-icon"></i>
              Invalid Email Id
            </div>

          </div>
          <div class="mb-3">

            <div class="MobileNumInside">
              <div class="CountryCodeData">
                <div class="dropdown DropDown">
                  <button class="btn FormSelect dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!countryIsSelected" class="FlagImg" src="./../../../../assets/images/country/aue.webp"
                      alt="no-img">
                    <img *ngIf="countryIsSelected" class="FlagImg" [src]="'data:image/png;base64,' + selectdImg"
                      alt="no-img">

                    <p class="CallID"> {{callingCode}}</p>
                  </button>
                  <ul class="dropdown-menu DropDownMenu" aria-labelledby="dropdownMenuButton1">
                    <li class="DropDownLi">

                      <form action="" [formGroup]="search">
                        <div class="InputSearch">
                          <img class="SearchImgIcon" src="./../../../../assets/images/search.png" alt="no-data">
                          <input [(ngModel)]="searchText" formControlName="searching" class="InputSearchData"
                            type="search" placeholder="Select Country Name or Code">
                        </div>
                      </form>
                    </li>
                    <li *ngFor="let item of allMobileFlags | filter:searchText" (click)="getCountryID(item)"
                      class="DropDownLi"><a class="dropdown-item DropDownItem">
                        <img *ngIf="item.type === 'base'" class="FlagImg" [src]="'data:image/png;base64,' + item.flag">
                        <img *ngIf="item.type === 'img'" class="FlagImg" [src]="item.flag">

                        {{item?.name}}<span class="FlagSpan">+{{item?.callingCode}}</span></a></li>

                  </ul>
                </div>

                <input formControlName="mobileNo" [(ngModel)]="enterMobile" class="InputNumber" type="number"
                  placeholder="Mobile Number">

              </div>
              <div class="error-message" *ngIf="inquiryform.get('mobileNo')?.invalid && (inquiryform.get('mobileNo')?.touched ||
                                
                                inquiryform.get('mobileNo')?.dirty)">

                <i class="fas fa-info-circle error-icon"></i>

                Mobile Number is required

              </div>
            </div>
          </div>
          <div class="mb-3">
            <textarea type="email" formControlName="subject" [(ngModel)]="inquiryObj.subject"
              class="form-control contact-link" placeholder="Message" id="exampleInputEmail1"
              aria-describedby="emailHelp"></textarea>
            <div class="error-message" *ngIf="inquiryform.get('subject')?.invalid && (inquiryform.get('subject')?.touched ||
                                
                                inquiryform.get('subject')?.dirty)">

              <i class="fas fa-info-circle error-icon"></i>

              Please enter your requirements

            </div>
          </div>
          <div class="mb-3">
            <button type="submit" [disabled]="inquiryform.invalid" class="form-control contact-link3">Get a
              Callback</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
