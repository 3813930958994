import { Component, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-new-slides',
  templateUrl: './new-slides.component.html',
  styleUrls: ['./new-slides.component.css']
})
export class NewSlidesComponent implements OnInit{
  allVideoFiles:any = []
  middleIndex: number;
  videoLink =''
  @ViewChild('myVideo') myVideo: any;
  @ViewChild('myDiv') myDiv: any;
  myImages = [
    {img:'./../../../assets/images/img-1.png'},
    {img:'./../../../assets/images/img-1.png'},
    {img:'./../../../assets/images/img-1.png'},
    {img:'./../../../assets/images/img-1.png'},
    {img:'./../../../assets/images/img-1.png'},
    {img:'./../../../assets/images/img-1.png'},
  ]
  mainImage:any =''
  videos = [
    { src: 'https://my-test-irak.s3.amazonaws.com/a4c0f76c-ebbb-4008-b02b-0a4b72c34eea.mp4' },
    { src: 'https://my-test-irak.s3.amazonaws.com/a4b02aa1-cefc-4cae-bb90-289d9ee8dcc8.mp4' },
    { src: './../../../assets/images/video/burj.mp4' }
  ];
  constructor(private service:AuthService){ this.middleIndex = Math.floor(this.allVideoFiles.length / 2);}
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getTestmonials()
  }
  getTestmonials(){
    this.service.getTestmonials().subscribe(
     (data:any)=>{
      this.allVideoFiles = data
     },(err)=>{
      console.log(err)
     }
    )
  }

  getPlay(item){
    console.log(item)
    this.mainImage = item.link
  }

  closeplayer(){
    const video: HTMLVideoElement = this.myVideo.nativeElement;
    video.pause();
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    center: true,
    dots: false,
    nav:true,
    margin: 20,
    navSpeed: 700,
    navText: ['<i class="fas fa-long-arrow-alt-left"></i>', '<i class="fas fa-long-arrow-alt-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
  
  };

getVideoLink(link){
  this.videoLink = link
  this.myDiv.nativeElement.click()
}

playPauseVideo() {
  const video: HTMLVideoElement = this.myVideo.nativeElement;
  video.pause();
  // if (video.paused) {
  //   video.play();
  // } else {
  //   video.pause();
  // }
}


}
