import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/commonn/header/header.component';
import { FooterComponent } from './components/commonn/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { GlobalVisaComponent } from './components/global-visa/global-visa.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CareerComponent } from './components/career/career.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MatMenuModule } from '@angular/material/menu';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CountryMobileComponent } from './components/country-mobile/country-mobile.component';
import { CountryMobileTwoComponent } from './components/country-mobile-two/country-mobile-two.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogViewComponent } from './components/blog-view/blog-view.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { VisaViewComponent } from './components/visa-view/visa-view.component';
import { StaysComponent } from './components/stays/stays.component';
import { FlightsComponent } from './components/flights/flights.component';
import { PackagesComponent } from './components/packages/packages.component';
import { CarsComponent } from './components/cars/cars.component';
import { CruisesComponent } from './components/cruises/cruises.component';
import { ThingsComponent } from './components/things/things.component';
import { CorporateTravelsComponent } from './components/corporate-travels/corporate-travels.component';
import { ViewAllOffersComponent } from './components/view-all-offers/view-all-offers.component';
import { ViewLocalOffersComponent } from './components/view-local-offers/view-local-offers.component';
import { OfferPreviewComponent } from './components/offer-preview/offer-preview.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ViewFlightDataComponent } from './components/viewFlightData/view-flight-data/view-flight-data.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatSliderModule } from '@angular/material/slider';
import { DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgToastModule } from 'ng-angular-popup'
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ReturnFlightDataComponent } from './components/viewFlightData/return-flight-data/return-flight-data.component';
import { FareQuoteComponent } from './components/viewFlightData/fare-quote/fare-quote.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TicketComponent } from './components/viewFlightData/ticket/ticket.component';
import { BookComponent } from './components/viewFlightData/book/book.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { FlightReviewComponent } from './components/viewFlightData/flight-review/flight-review.component';
import { SummuryComponent } from './components/viewFlightData/summury/summury.component';
import { VerificationComponent } from './components/viewFlightData/verification/verification.component';
import { AuthInterceptor } from './auth.interceptor';
import {MatSelectModule} from '@angular/material/select';

import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MybookingsComponent } from './components/mybookings/mybookings.component';
import { ConfirmationComponent } from './components/viewFlightData/confirmation/confirmation.component';
import { PrivacyPolicyComponent } from './components/commonn/privacy-policy/privacy-policy.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CancelledComponent } from './components/cancelled/cancelled.component';
import { PackageViewComponent } from './components/package-view/package-view.component';
import { VisaOverviewComponent } from './components/visa-overview/visa-overview.component';
import { ApplyVisaComponent } from './components/apply-visa/apply-visa.component';
import { VisaConfirmationComponent } from './components/visa-confirmation/visa-confirmation.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import {MatCardModule} from '@angular/material/card';
import { PackageFiltersComponent } from './components/package-filters/package-filters.component';
import { PackageBookComponent } from './components/package-book/package-book.component';
import { PackageConfirmationComponent } from './components/package-confirmation/package-confirmation.component';
import { VisaBookingViewComponent } from './components/visa-booking-view/visa-booking-view.component';
import { AuthenticationComponentComponent } from './components/authentication-component/authentication-component.component';
import { PackageBookingViewComponent } from './components/package-booking-view/package-booking-view.component';
import { CorporatePackagesComponent } from './components/corporate-packages/corporate-packages.component';
import { GlobalPackagesComponent } from './components/global-packages/global-packages.component';
import { GlobalVisalistComponent } from './components/global-visalist/global-visalist.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PriceExchangePipe } from './pipes/price-exchange.pipe';
import { GoogleReviewsComponent } from './components/google-reviews/google-reviews.component';
import { NewSlidesComponent } from './components/new-slides/new-slides.component';



export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    ContactUsComponent,
    GlobalVisaComponent,
    LoginComponent,
    SignUpComponent,
    CareerComponent,
    ProfileComponent,
    CountryMobileComponent,
    CountryMobileTwoComponent,
    BlogComponent,
    BlogViewComponent,
    TestimonialComponent,
    VisaViewComponent,
    StaysComponent,
    FlightsComponent,
    PackagesComponent,
    CarsComponent,
    CruisesComponent,
    ThingsComponent,
    CorporateTravelsComponent,
    ViewAllOffersComponent,
    ViewLocalOffersComponent,
    OfferPreviewComponent,
    ComingSoonComponent,
    ViewFlightDataComponent,
    ReturnFlightDataComponent,
    FareQuoteComponent,
    TicketComponent,
    BookComponent,
    FlightReviewComponent,
    SummuryComponent,
    VerificationComponent,
    UserProfileComponent,
    MybookingsComponent,
    ConfirmationComponent,
    PrivacyPolicyComponent,
    CancelledComponent,
    PackageViewComponent,
    VisaOverviewComponent,
    ApplyVisaComponent,
    VisaConfirmationComponent,
    LandingPageComponent,
    PackageFiltersComponent,
    PackageBookComponent,
    PackageConfirmationComponent,
    VisaBookingViewComponent,
    AuthenticationComponentComponent,
    PackageBookingViewComponent,
    CorporatePackagesComponent,
    GlobalPackagesComponent,
    GlobalVisalistComponent,
    GalleryComponent,
    PriceExchangePipe,
    GoogleReviewsComponent,
    NewSlidesComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    AppRoutingModule,
    CarouselModule,
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    MatButtonToggleModule,
    NgSelectModule,
    NgToastModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    MatTabsModule,
    MatExpansionModule,
    Ng2SearchPipeModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MatRadioModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatSelectModule,
    MatSliderModule,
    BrowserAnimationsModule,
   
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgxUiLoaderModule,
    MatInputModule,
    MatMenuModule,
    ModalModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, DatePipe, BsDatepickerConfig,
  { provide: MAT_DATE_LOCALE, useValue: 'en-US' },],
  bootstrap: [AppComponent]
})
export class AppModule { }
