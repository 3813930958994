import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ChangeDetectorRef, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

import { DatePipe } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { ReturnsearchingFilter, fareQuote, fareRule, searchMultiFlight, searchingFilter, searchingReturnFilter } from 'src/app/models/model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { getISOWeek } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { async } from 'rxjs/internal/scheduler/async';
import { Subscription } from 'rxjs';
interface FlightSegment {
  AirlineName: string;
  TotalFare: number;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface Flight {
  // Define the flight data structure based on your actual data
  AirlineName: string;
  Fare: {
    TotalFare: number;
  };
  DepartureTime: string; // You can use a proper date type here
  // ... other properties
}
@Component({
  selector: 'app-view-flight-data',
  templateUrl: './view-flight-data.component.html',
  styleUrls: ['./view-flight-data.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ViewFlightDataComponent implements OnInit, AfterViewInit, OnDestroy {
  private apiSubscription: Subscription;
  @ViewChild('lastDiv', { static: false }) lastDivRef!: ElementRef;
  @ViewChild('myDiv', { static: false }) divRef!: ElementRef;
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;
  @ViewChild('CloseModels') CloseModels!: ElementRef;
  @ViewChild('CloseOpenLogin') CloseOpenLogin!: ElementRef;
  @ViewChild('fliterPopOpen') fliterPopOpen!: ElementRef;
  @ViewChild('fliterPopClose') fliterPopClose!: ElementRef;

  html = `<span class="btn btn-danger">Never trust not sanitized HTML!!!</span>`;
  @ViewChild('staticModal') staticModal: ElementRef;
  isLogin: boolean
  // oneWayFilter:boolean = true
  returnAirlinesList: any = []
  allData: any = []
  selectedCurrency:any
  directFlight: string[] = [];
  isLoginOne: boolean = false
  oneWayFilter: boolean = true
  searchCountryList: any = []
  oneWayReturn: boolean = false
  requestForOtp: boolean = false
  loginform: FormGroup
  loginOtp: FormGroup
  TrackingIdOne: any
  TrackingIdTwo: any
  departurDate: any
  ArrailvaDate: any
  araivalDate: any
  showMore2: boolean = false
  selectedItemIndex1: number = 0;
  selectedItemIndex2: number = 0;
  mainList = []
  mainList2 = []
  itemsToShow = 4; // Number of items to display initially

  FirstID: string
  totalCountPassinger: number
  @ViewChild('buttonToggle1') buttonToggle1: MatButtonToggle;
  @ViewChild('buttonToggle2') buttonToggle2: MatButtonToggle;
  @ViewChild('buttonToggle3') buttonToggle3: MatButtonToggle;
  @ViewChild('buttonToggle4') buttonToggle4: MatButtonToggle;
  // @ViewChild('staticModal') staticModal: ModalDirective;
  isPageLoaded: boolean = false
  isLoaderActivate: boolean = false
  selectOne: any = []
  selectTwo: any = []
  OrgFare: any = []
  DeptFare: any = []
  selectedOrigin: any
  SendReturnData: any = []
  SendReturnData2: any = []
  selectedDeparture: any = []
  finalPrice: number
  Source: string
  isHidden: boolean = false;
  journeyTypeDivision: number
  DestinationPrint: string
  displayedItems: any[]; // Array to hold displayed items
  displayedItems2: any[]; // Array to hold displayed items
  returnFlight: any = []
  returnFlight2: any = []
  returnFlightInformation: any = []
  showMore: boolean = false; // Flag to track if "Show More" button is clicked
  fareRuleObj = new fareRule()
  fareQuoteObj = new fareQuote()
  howManyDirectFlights: number = 0
  flightNames: any = []
  FliterMinFareAndMaxFare: any
  pathData: any = []
  pathData2: any = []
  isReturn: boolean = true
  OrginData: string
  DestinationData: string
  priceRange: number = 0
  minDate = new Date()
  minPrice: number = 0
  maxPrice: number = 0
  isReturnFn: boolean = false
  isReturnValue: Date;
  journeyType: number = 1
  NJourneyType: number = 1
  Origin: string
  Destination: string
  fromDate: any = new FormControl();
  fromDates: any = new Date()
  toDates: any = new Date()
  Adults: number = 1
  DepartureTime: string
  childs: number
  Infants: number
  className: string
  classNo: number = 1;
  isLoading: boolean = false;
  routeFliter = [
    { id: 1, name: "Direct", displaName: 'Non Stop' },
    { id: 2, name: "OneStop", displaName: '1 Stop' },
    { id: 3, name: "TwoStop", displaName: '1+ Stop' },

  ]
  @ViewChild('opneFlight') opneFlight!: ElementRef;
  @ViewChild('OpenPop') OpenPop!: ElementRef;
  @ViewChild('closePassingerDropdown') closePassingerDropdown!: ElementRef;
  @ViewChild('Classs') Classs!: ElementRef;
  @ViewChild('returnButton', { static: true }) returnButton: ElementRef;
  @ViewChild('mutliButton', { static: true }) mutliButton: ElementRef;
  @ViewChild('OneWayButton', { static: false }) OneWayButton: ElementRef;

  @ViewChild('isTwoWay') isTwoWay!: ElementRef;
  @ViewChild('closeFlight') closeFlight!: ElementRef;
  @ViewChild('closeTwoFlight') closeTwoFlight!: ElementRef;
  @ViewChild('input1', { static: false }) input1!: ElementRef;
  @ViewChild('selectBox') selectBox: NgSelectComponent;
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  @ViewChild('picker2') datePicker2: MatDatepicker<Date>;


  isDivActive: boolean = false
  selectedCar: number;
  flightInfo: any = []
  displayFlightInfo: any = []
  displayFlightInfo2: any = []
  flightInfoFilterCopy: any = []
  flightInfoResult: any = []
  flightUpdate: any = []
  flightUpdate2: any = []
  flightSegments: any = []
  segements: any;
  segements2: any;
  TrackingId: string
  picker: any;
  picker2: any;
  closeSelection: boolean = true
  selectedCarId: string;
  selectedDate: Date;
  class: number
  segmentList: any = []
  searchWay: any = []
  searchWay2: any = []
  // MultiWay start /////
  dynamicForm!: FormGroup;
  searchMultiFlightObj = new searchMultiFlight()
  searchingFilterObj = new searchingFilter()
  ReturnsearchingFilterObj = new ReturnsearchingFilter()
  searchingReturnFilterObj = new searchingReturnFilter()
  SubmitButton: boolean = true
  multiClass: number
  noData: boolean;
  airlineFilters: any = []
  // MultiWay end  /////


  constructor(private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe,
    private config: NgSelectConfig, private renderer: Renderer2,
    private ngxService: NgxUiLoaderService, private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private dialog: MatDialog

  ) {
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
    // this.config.notFoundText = 'City not found';
    // this.config.bindValue = 'value';
  }

  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  ngAfterViewInit(): any {
    this.checkLastDivHeight()
    this.cdRef.detectChanges(); // Trigger change detection
    const divElement = document.getElementById('myDiv');

    const divHeight = this.divRef?.nativeElement.offsetHeight;

  }

  checkDiv() {
    const divElement = document.getElementById('myDiv');

    if (divElement) {
      const divHeight = divElement.offsetHeight;

    }
  }

  checkLastDivHeight() {
    const lastDivHeight = this.lastDivRef?.nativeElement.offsetHeight;
    const scrollThreshold = lastDivHeight; // Adjust this threshold as needed

    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition >= scrollThreshold) {
        // alert('Reached the last div height!');
        this.closeSelection = false
        // You might want to remove the scroll event listener here if needed
      }
      else {
        this.closeSelection = true
      }
    });
  }
  ngOnDestroy(): void {
    this.ngxService.stop()
    this.closeDialog()

  }

  selectWay(data: any) {

    if (data.target.value == 'Return') {
      // this.NJourneyType = 2
      this.journeyType = 2
      this.oneWayReturn = true
      this.isReturnFn = true;
      this.isReturn = false;
      return
    }
    if (data.target.value == 'One-Way') {
      // this.NJourneyType = 1
      this.journeyType = 1
      this.oneWayReturn = false
      // alert(this.journeyType)
    }
    if (data.target.value == 'Multi-City') {
      this.journeyType = 3
      this.oneWayReturn = false
      // this.NJourneyType = 1
    }
    else {
      this.isReturnFn = false;
      this.isReturn = true;
    }
  }


  ngOnInit(): void {
    // Apply the CSS styles
    window.scrollTo(0, 0);
    this.getsearchLocationsData()
      this.dynamicForm = this.formBuilder.group({
      formFields: this.formBuilder.array([]) 
    });
    const css = `
      .ng-select .ng-select-container .ng-value-container .ng-input input {
        height: 38px !important;
        padding-left: 13px !important;
        border:none !important
      }
    `;
    const style = this.renderer.createElement('style');
    style.type = 'text/css';
    style.appendChild(this.renderer.createText(css));
    this.renderer.appendChild(document.head, style);


    // $('[data-bs-toggle="popover"]').popover();
    this.fromDate.setValue(new Date());
    this.loadScripts()
    setTimeout(() => {
      this.openModal()
    }, 6)

    this.route.paramMap.subscribe((data: any) => {
        
      this.flightInfo = JSON.parse(data.params.data)
      this.pathData = JSON.parse(data.params.data)
      if (this.flightInfo.JourneyType == 1) {
        this.oneWayReturn = false
        this.isReturnValue = this.flightInfo.Segment[0]?.PreferredArrivalTime
        this.fromDates = this.flightInfo.Segment[0]?.PreferredDepartureTime

      }
      if (this.flightInfo.JourneyType == 2) {
        this.returnButton.nativeElement.click();
        this.oneWayReturn = true
        this.fromDates = new Date(this.flightInfo.Segment[0]?.PreferredArrivalTime)
        this.isReturnValue = new Date(this.flightInfo.Segment[1]?.PreferredArrivalTime)

      }
      if (this.flightInfo.JourneyType == 3) {
        this.oneWayReturn = false
        this.mutliButton.nativeElement.click();
        this.dynamicForm = this.formBuilder.group({
          formFields: this.formBuilder.array([]) // Use a FormArray to dynamically add/remove form controls
        });
        // this.fromDates = this.flightInfo.Segment[0]?.PreferredDepartureTime
        const formFieldsArray = this.dynamicForm.get('formFields') as FormArray;
        this.pathData.Segment.forEach((field: any) => {
          formFieldsArray.push(this.formBuilder.group({
            Origin: field.Origin,
            Destination: field.Destination,
            PreferredDepartureTime: field.PreferredDepartureTime,
            PreferredArrivalTime: field.PreferredArrivalTime
          }));
        });

      }
      // this.isReturnValue = this.flightInfo.Segment[0]?.PreferredArrivalTime
      this.fareRuleObj.EndUserBrowserAgent = this.flightInfo.EndUserBrowserAgent
      this.fareRuleObj.PointOfSale = this.flightInfo.PointOfSale
      this.fareRuleObj.TokenId = this.flightInfo.TokenId
      this.fareRuleObj.RequestOrigin = this.flightInfo.RequestOrigin
      this.fareRuleObj.UserData = null
      this.fareRuleObj.ipaddress = this.flightInfo.IPAddress

      // Multi

      this.searchMultiFlightObj.EndUserBrowserAgent = this.flightInfo.EndUserBrowserAgent
      this.searchMultiFlightObj.PointOfSale = this.flightInfo.PointOfSale
      this.searchMultiFlightObj.TokenId = this.flightInfo.TokenId
      this.searchMultiFlightObj.RequestOrigin = this.flightInfo.RequestOrigin
      this.searchMultiFlightObj.UserData = null
      this.searchMultiFlightObj.ipaddress = this.flightInfo.IPAddress





      this.Origin = this.flightInfo.Segment[0].Origin

      this.Destination = this.flightInfo.Segment[0].Destination
      // this.fromDate = new FormControl(this.flightInfo.Segment[0].PreferredDepartureTime);
      this.Adults = this.flightInfo.AdultCount
      this.childs = this.flightInfo.ChildCount
      this.Infants = this.flightInfo.InfantCount


      this.totalCountPassinger = this.Adults
      this.totalCountPassingers()
      if (this.childs == undefined) {
        this.childs = 0
      }
      if (this.Infants == undefined) {
        this.Infants = 0
      }

      this.class = this.flightInfo.FlightCabinClass
      this.multiClass = this.flightInfo.FlightCabinClass
      if (this.class == 1) {
        this.className = 'All Classes'
      }
      if (this.class == 2) {
        this.className = 'Economy'
      }
      if (this.class == 3) {
        this.className = 'PremiumE.'
      }
      if (this.class == 4) {
        this.className = 'Business'
      }
      if (this.class == 5) {
        this.className = 'PremiumBus'
      }
      if (this.class == 6) {
        this.className = 'First'
      }


      if (this.flightInfo.JourneyType === 1 || this.flightInfo.JourneyType === 3) {
        this.SearchAPI(this.flightInfo)
      }

      let oneSeg = [this.flightInfo.Segment[0]]
      let twoSeg = [this.flightInfo.Segment[1]]
      oneSeg.push()
      if (this.flightInfo.JourneyType === 2) {
        let origins = {
          AdultCount: this.flightInfo.AdultCount,
          ChildCount: this.flightInfo.ChildCount,
          InfantCount: this.flightInfo.InfantCount,
          DirectFlight: this.flightInfo.DirectFlight,
          EndUserBrowserAgent: this.flightInfo.EndUserBrowserAgent,
          FlightCabinClass: this.flightInfo.FlightCabinClass,
          IPAddress: this.flightInfo.IPAddress,
          JourneyType: 1,
          PointOfSale: this.flightInfo.PointOfSale,
          RequestOrigin: this.flightInfo.RequestOrigin,
          TokenId: this.flightInfo.TokenId,
          UserData: this.flightInfo.UserData,
          Segment: oneSeg,
        }
        let destinations = {
          AdultCount: this.flightInfo.AdultCount,
          ChildCount: this.flightInfo.ChildCount,
          InfantCount: this.flightInfo.InfantCount,
          DirectFlight: this.flightInfo.DirectFlight,
          EndUserBrowserAgent: this.flightInfo.EndUserBrowserAgent,
          FlightCabinClass: this.flightInfo.FlightCabinClass,
          IPAddress: this.flightInfo.IPAddress,
          JourneyType: 1,
          PointOfSale: this.flightInfo.PointOfSale,
          RequestOrigin: this.flightInfo.RequestOrigin,
          TokenId: this.flightInfo.TokenId,
          UserData: this.flightInfo.UserData,
          Segment: twoSeg,
        }
        debugger
        this.SearchAPI(origins)
        this.searchTwoway2(destinations)

      }
    })




    // Stoped temparary


    // this.dynamicForm = this.formBuilder.group({
    //   formFields: this.formBuilder.array([]) 
    // });


    this.addFormField('')
    this.searchMultiFlightObj.FlightCabinClass = 1
    this.searchMultiFlightObj.AdultCount = 1


    this.loginform = new FormGroup({
      mobile: new FormControl('', [Validators.required]),
    })
    this.loginOtp = new FormGroup({
      otp: new FormControl('', [Validators.required]),
    })

    setTimeout(() => {
      // this.loadmore();
    }, 20000)


    this.displayedItems = this.airlineFilters.slice(0, 4);

  }


  SearchAPI(dataObj: any) {

    // this.openDialog()

    // this.opneFlight?.nativeElement.click()
    this.OpenPop?.nativeElement.click()
    this.isPageLoaded = true
    this.isLoaderActivate = true
    this.noData = false
    localStorage.setItem('SearchInfo', JSON.stringify(dataObj))
    let offset = 0;
    localStorage.setItem('offset', JSON.stringify(offset))
    // this.router.navigate(['/VIewFlightData', JSON.stringify(dataObj)])
    localStorage.setItem('SearchInfo', JSON.stringify(dataObj))
    dataObj.offset = offset;
    this.searchOneWayFlight(dataObj);


  }

  searchTwoway2(dataObj) {
    this.isPageLoaded = true
    localStorage.setItem('SearchInfo', JSON.stringify(dataObj))
    this.searchTwoWayFlight(dataObj);

  }



  getBase64Image(base64String: string): string {
    return 'data:image/jpeg;base64,' + base64String;
  }
  getSegmentsInfo(id) {

    let searchData = {
      details: localStorage.getItem('SearchInfo'),
      id: id.ResultId
    }
    this.service.getSegmentsInfo(searchData).subscribe(
      (data: any) => {

        this.segmentList = data;
      }
    )
  }



  addTimeaddTimeDurationsDurations(durations) {
    let totalSeconds = 0;

    // Convert each duration to seconds and sum them up
    for (let duration of durations) {
      const [hours, minutes, seconds] = duration.split(':');
      totalSeconds += (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
    }

    // Convert the total back to hh:mm:ss format
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  // Helper function to add leading zeros
  padZero(value) {
    return value.toString().padStart(2, '0');
  }



  openModal() {

    this.opneFlight?.nativeElement.click()
    // this.modalRef = this.modalService.show(modal);
  }
  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flight_load.json',
  };

  getFlightInfo(data: any) {
    this.fareRuleObj.ResultId = data.ResultId
    this.fareRuleObj.TrackingId = this.TrackingId

    // this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])

    // this.service.FareQuote(this.fareRuleObj).subscribe(
    //   (res: any) => {

    //     if (res.data.IsSuccess == false) {
    //       this.service.error(res.data.Errors[0].UserMessage)
    //     }
    //     else {
    //       if (res.data.Result[0].IsLcc == true) {
    //         alert("is LCC true -->  Ticket LCC")
    //         this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])
    //       }
    //       if (res.data.Result[0].IsLcc == false) {
    //         alert("is LCC false go to book & -> ticket method")
    //         this.router.navigate(['/farQuote', JSON.stringify(this.fareRuleObj)])
    //       }
    //     }
    //   }
    // )
    // localStorage.setItem('browser',JSON.stringify(this.fareRuleObj))
    let Access = this.service.isLoggedin()
    // this.service.bookFlightValidations(this.fareRuleObj).subscribe(
    //   (data:any)=>{
    //
    //   }
    // )

    if (Access === true) {
      localStorage.setItem('myWay', 'Way1')
      data.request = this.fareRuleObj
      data.Adults = this.Adults
      data.childs = this.childs
      data.infants = this.Infants

      this.router.navigate(['/flight-review', JSON.stringify(this.fareRuleObj)])


    }
    else {
      this.CloseOpenLogin.nativeElement.click()
      // this.service.error('Please Login')
    }


  }

  mainDiv() {
    this.isDivActive = !this.isDivActive
  }

  loadScripts() {
    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js',
      '../../assets/js/script.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  selectNext(nextInput: ElementRef): void {
    nextInput.nativeElement.focus();
  }
  openSelectBox() {
    this.selectBox.open();
  }

  dataSelect(e: any) {
    this.openSelectBox()
  }

  dataSelect2(e: any) {
    this.datePicker.open();
  }

  returnCallender() {
    this.datePicker2.open();

  }

  closePasingerCOunt() {
    this.closePassingerDropdown?.nativeElement.click();
  }

  chooseReturn() {
    if (this.isReturnValue) {

    }

    else {
      if (this.isReturnFn == false) {
        if (confirm('are you choose return flight..?')) {
          this.isReturn = false;
          this.returnButton.nativeElement.click();


        }
        else {
          return
        }
      }

    }


  }
  adultsIncrese() {
    if (this.Adults < 10) {
      this.Adults++
    }
    else {

    }
  }
  adultsDescrese() {
    if (this.Adults <= 1) {
      // this.Adults --
    }
    else {
      this.Adults--
    }

  }

  selectAdults(adults) {
    this.Adults = adults
    this.totalCountPassingers()
  }
  selectChilds(childs) {
    this.childs = childs
    this.totalCountPassingers()
  }
  selectInfants(infants) {
    this.Infants = infants
    this.totalCountPassingers()
  }
  selectClass(number: any) {

    if (number == 1) {
      this.className = 'All Classes'
      this.classNo = number
    }
    if (number == 2) {
      this.className = 'Economy'
      this.classNo = number
    }
    if (number == 3) {
      this.className = 'PremiumE.'
      this.classNo = number
    }
    if (number == 4) {
      this.className = 'Business'
      this.classNo = number
    }
    if (number == 5) {
      this.className = 'PremiumBus'
      this.classNo = number
    }
    if (number == 6) {

      this.className = 'First'
      this.classNo = number
    }

  }



  selectAdults2(adults) {
    this.Adults = adults
    this.searchMultiFlightObj.AdultCount = adults
    this.totalCountPassingers()
  }
  selectChilds2(childs) {
    this.childs = childs
    this.searchMultiFlightObj.ChildCount = childs
    this.totalCountPassingers()
  }
  selectInfants2(infants) {
    this.Infants = infants
    this.searchMultiFlightObj.InfantCount = infants
    this.totalCountPassingers()
  }
  selectClass2(number: any) {

    if (number == 1) {
      this.className = 'All Classes'
      this.classNo = number
      this.class = number

    }
    if (number == 2) {
      this.className = 'Economy'
      this.classNo = number
      this.class = number
    }
    if (number == 3) {
      this.className = 'PremiumE.'
      this.classNo = number
      this.class = number
    }
    if (number == 4) {
      this.className = 'Business'
      this.classNo = number
      this.class = number
    }
    if (number == 5) {
      this.className = 'PremiumBus'
      this.classNo = number
      this.class = number
    }
    if (number == 6) {

      this.className = 'First'
      this.classNo = number
      this.class = number
    }
    this.searchMultiFlightObj.FlightCabinClass = this.classNo
  }



  totalCountPassingers() {
    let Adults = this.Adults
    let Children = this.childs
    let Infants = this.Infants
    if (typeof Adults === 'number' && typeof Children === 'number' && typeof Infants === 'number') {
      this.totalCountPassinger = Adults + Children + Infants;
    } else {

    }

  }

  updateFIlter() {
    this.isLoaderActivate = true
    this.searchingFilterObj.AirlineNames = []
    this.searchingFilterObj.DepartureTime = []
    delete this.searchingFilterObj.MaxFare
    this.searchingFilterObj.directFlight = []
    if (this.isReturnValue === undefined) {
      this.journeyType = 1
    }
    this.ngxService.start();
    this.route.paramMap.subscribe((data: any) => {

      this.flightUpdate = JSON.parse(data.params.data)
      this.flightUpdate.Segment = []
      this.flightUpdate.AdultCount = this.Adults
      this.flightUpdate.ChildCount = this.childs
      this.flightUpdate.InfantCount = this.Infants
      this.flightUpdate.FlightCabinClass = this.classNo
      let FData: any = []

      const datePipe: DatePipe = new DatePipe('en-US');
      const formattedDate: string = datePipe.transform(this.fromDates, 'yyyy-MM-dd');
      const datePipe2: DatePipe = new DatePipe('en-US');
      const formattedDate2: string = datePipe2.transform(this.isReturnValue, 'yyyy-MM-dd');
      if (this.journeyType == 1) {
        this.flightUpdate.Segment = FData
        this.NJourneyType = 1
        this.flightUpdate.JourneyType = this.journeyType
        FData.push({
          Origin: this.Origin,
          Destination: this.Destination,
          PreferredDepartureTime: formattedDate,
          PreferredArrivalTime: formattedDate
        })
        localStorage.setItem('myWay', 'Way1')
        this.router.navigate(['/VIewFlightData', JSON.stringify(this.flightUpdate)])
        // this.SearchAPI(this.flightUpdate)
      }
      if (this.journeyType == 2) {

        this.journeyType = 2
        this.NJourneyType = 2
        this.flightUpdate.JourneyType = 1
        this.flightUpdate.Segment = FData
        FData.push({
          Origin: this.Origin,
          Destination: this.Destination,
          PreferredDepartureTime: formattedDate,
          PreferredArrivalTime: formattedDate

        })

        const firstDepartureDate =  this.datePipe.transform(formattedDate, 'yyyy-MM-dd ');
        const returnDepartureDate =  this.datePipe.transform(formattedDate2, 'yyyy-MM-dd ')
        const firstDate = new Date(firstDepartureDate);
        const secondDate = new Date(returnDepartureDate);
        let dateINfo = firstDate <= secondDate;
    
        if(dateINfo === false){
          this.service.error("Return date cannot be before the departure date.")
          this.router.navigateByUrl('/landing')
        }

        this.router.navigate(['/VIewFlightData', JSON.stringify(this.flightUpdate)])
        // this.SearchAPI(this.flightUpdate)

        this.flightUpdate.Segment = []
        let DData: any = []

        DData.push({
          Origin: this.Destination,
          Destination: this.Origin,
          PreferredDepartureTime: formattedDate2,
          PreferredArrivalTime: formattedDate2
        })
        let finalSegment = []
        finalSegment.push(FData[0])
        finalSegment.push(DData[0])
        this.flightUpdate.Segment = finalSegment
        this.flightUpdate.JourneyType = 2
        debugger
        this.router.navigate(['/VIewFlightData', JSON.stringify(this.flightUpdate)])
        // this.searchTwoway2(this.flightUpdate)
        // this.searchTwoWayFlight(this.flightUpdate)

        // this.searchTwoway(firstData)
        // 
        localStorage.setItem('myWay', 'Way2')
      }

    })

  }


  flightSegments2 = []
  minPrice2: any





  toggleShowMore() {
    this.showMore = !this.showMore;

    if (this.showMore) {
      // Show all items
      this.displayedItems = this.airlineFilters;
    } else {
      // Show only the first four items
      this.displayedItems = this.airlineFilters.slice(0, 4);
    }
  }





  selectedAirlines: string[] = [];
  selectedAirlines2: string[] = [];

  FilterByFlightName(item: any, checked: boolean) {
    const airlineIndex = this.searchingFilterObj.AirlineNames.indexOf(item.name);

    if (checked && airlineIndex === -1) {
      // Add the airline name if it's not already in the array
      this.searchingFilterObj.AirlineNames.push(item.name);
    } else if (!checked && airlineIndex !== -1) {
      // Remove the airline name if it's in the array
      this.searchingFilterObj.AirlineNames.splice(airlineIndex, 1);
    }

    this.dynamicSearch()
    if (localStorage.getItem('myWay') === "Way2") {
      this.dynamicReturnSearch()
    }


  }






  async searchFLight() {
    // const filters = {
    //   name: "SpiceJet",
    //   departureTime: [19,20],
    //   fare: [100, 394],
    // };
    this.flightInfo = []


    // this.flightInfo = filteredFlights
  }


  async searchReturnFLight() {
    // const filters = {
    //   name: "SpiceJet",
    //   departureTime: [19,20],
    //   fare: [100, 394],
    // };
    this.returnFlight = []
    this.searchingReturnFilterObj.name = this.searchingFilterObj.AirlineNames
    // const filteredFlights = await this.filterReturnFlights(this.mainList2, this.searchingReturnFilterObj);

    // this.returnFlight = filteredFlights
  }




  PriceSlider(e: any) {

    this.priceRange = e.target.value
    this.searchingFilterObj.MaxFare = this.priceRange
    this.searchingReturnFilterObj.fare = this.priceRange
    this.dynamicSearch()

    if (localStorage.getItem('myWay') === "Way2") {
      this.dynamicReturnSearch()
    }
    // this.searchFLight()
    // this.searchReturnFLight()
    // this.mainList.forEach((element, index) => {
    //   if (this.priceRange >= element.Fare.TotalFare) {
    //     this.flightInfo.push(element)

    //   }
    // })
  }

  checkCheckbox(checked: boolean) {
    // You can perform any desired actions based on the checked value
  }

  DirectFlightFilter(checked: boolean, data: string) {
    if (checked) {
      if (!this.searchWay.includes(data)) {
        this.searchWay.push(data);
      }
    } else {
      const index = this.searchWay.indexOf(data);
      if (index !== -1) {
        this.searchWay.splice(index, 1);
      }
    }
    this.searchingFilterObj.directFlight = this.searchWay;

    this.dynamicSearch();
  }



  DirectFlightReturnFilter(checked: boolean, data: string) {
    if (checked) {
      if (!this.searchWay2.includes(data)) {
        this.searchWay2.push(data);
      }
    } else {
      const index = this.searchWay2.indexOf(data);
      if (index !== -1) {
        this.searchWay2.splice(index, 1);
      }
    }
    this.ReturnsearchingFilterObj.directFlight = this.searchWay2;
    this.dynamicReturnSearch()
  }

  onItemSelectionChange1(data) {

    this.selectedItemIndex1


  }

  onItemSelectionChange2(index: number) {

    // this.selectedItemIndex2 = index;
  }

  getOriginData(data: any) {

    this.fareRuleObj.ResultId = data?.ResultId
    this.fareRuleObj.TrackingId = this.TrackingId
    this.finalPrice = this.selectedDeparture?.Fare?.TotalFare + this.selectOne?.Fare?.TotalFare
    this.selectOne = data

    this.OrgFare = this.selectOne?.Fare

  }
  getOriginData2(data: any) {

    this.selectedDeparture = data
    this.DeptFare = this.selectedDeparture?.Fare
    this.finalPrice = this.selectedDeparture?.Fare?.TotalFare + this.selectOne?.Fare?.TotalFare
    this.selectTwo = data
    let Destination = {
      EndUserBrowserAgent: this.fareRuleObj.EndUserBrowserAgent,
      PointOfSale: this.fareRuleObj.PointOfSale,
      RequestOrigin: this.fareRuleObj.RequestOrigin,
      UserData: this.fareRuleObj.UserData,
      ipaddress: this.fareRuleObj.ipaddress,
      TokenId: this.fareRuleObj.TokenId,
      ResultId: data?.ResultId,
      TrackingId: this.TrackingIdTwo,

    }
    this.SendReturnData2 = Destination

  }


  bookReturn() {


    let FinalObj = []
    FinalObj.push(this.SendReturnData)
    // FinalObj.push(this.SendReturnData2)


    let Access = this.service.isLoggedin()
    if (Access === true) {

      let oneIsOk: boolean
      let twoIsOk: boolean
      this.selectOne.request = this.fareRuleObj
      this.selectOne.Adults = this.Adults
      this.selectOne.childs = this.childs
      this.selectOne.infants = this.Infants
      localStorage.setItem('spares', JSON.stringify(this.fareRuleObj))
      localStorage.setItem('second', JSON.stringify(this.SendReturnData2))
      localStorage.setItem('myWay', 'Way2')

      this.closeTwoFlight.nativeElement.click()

      this.router.navigate(['/flight-review', JSON.stringify(this.fareRuleObj)])



    }
    else {
      this.CloseOpenLogin.nativeElement.click()
      // this.service.error('Please Login')
    }

  }

  onRowClick(index: number) {
    // this.selectedItemIndex1 = index;
  }


  hydToDelFlights: any[] = [];
  delToHydFlights: any[] = [];




  checkAirLines() {

    this.flightInfo.forEach((value) => {

    })
  }

  filterMorningFlightsParameters(startTime: number, endTime: number, checked) {

    if (checked === true) {

      this.searchingFilterObj.DepartureTime = [startTime, endTime]
      this.searchFLight()
    }
    else {
      this.searchingFilterObj.DepartureTime = ["01:00:00", "24:00:00"]
      this.searchFLight()
    }
  }


  timeRanges = [
    { startTime: '01:00:00', endTime: '06:00:00', selected: false },
    { startTime: '06:00:00', endTime: '12:00:00', selected: false },
    { startTime: '12:00:00', endTime: '18:00:00', selected: false },
    { startTime: '18:00:00', endTime: '23:00:00', selected: false },
    // Add other time ranges similarly
  ];
  timeReturnRanges = [
    { startTime: '01:00:00', endTime: '06:00:00', selected: false },
    { startTime: '06:00:00', endTime: '12:00:00', selected: false },
    { startTime: '12:00:00', endTime: '18:00:00', selected: false },
    { startTime: '18:00:00', endTime: '23:00:00', selected: false },
    // Add other time ranges similarly
  ];
  toggleTimeRange(index: number) {

    this.timeReturnRanges[index].selected = !this.timeReturnRanges[index].selected;
    this.logSelectedTimeRanges();
  }


  toggleTimeReturnRange(index: number) {
    this.timeReturnRanges[index].selected = !this.timeReturnRanges[index].selected;
    this.logSelectedTimeReturnRanges();
  }



  logSelectedTimeRanges() {

    const selectedRanges = this.timeReturnRanges
      .filter(range => range.selected)
      .map(range => ({
        start: range.startTime,
        end: range.endTime
      }));

    this.searchingFilterObj.DepartureTime = selectedRanges;

    if (selectedRanges.length === 0) {
      selectedRanges.push({ start: '00:00:00', end: '24:00:00' });
    }

    this.dynamicSearch()
  }





  logSelectedTimeReturnRanges() {

    const selectedRanges = this.timeReturnRanges
      .filter(range => range.selected)
      .map(range => ({
        start: range.startTime,
        end: range.endTime
      }));



    this.ReturnsearchingFilterObj.DepartureTime = selectedRanges
    if (selectedRanges.length === 0) {
      selectedRanges.push({ start: '00:00:00', end: '24:00:00' });
    }
    this.dynamicReturnSearch()
  }


  filterReturnMorningFlightsParameters(startTime: number, endTime: number, checked) {

    if (checked === true) {

      // this.searchingReturnFilterObj.departureTime =[startTime,endTime]

      this.searchReturnFLight()
      // this.flightInfo = []
      // this.mainList
      // this.filterMorningFlights(this.flightInfo, startTime, endTime)
    }
    else {
      // this.flightInfo = this.mainList
      this.searchingReturnFilterObj.departureTime = ["01:00", "24:00"]
      this.searchReturnFLight()
    }
  }

  filterMorningFlights(flights, startTime, endTime) {
    const morningFlights = flights.filter(flight => {
      const departureTime = new Date(flight.Segments[0][0].DepartureTime);
      const flightTime = departureTime.toLocaleTimeString('en-US', { hour12: false });
      return flightTime >= startTime && flightTime <= endTime; // Filter flights with departure time between start and end times
    });

    // Sort the morning flights based on departure time
    morningFlights.sort((flightA, flightB) => {
      const departureTimeA: any = new Date(flightA.Segments[0][0].DepartureTime);
      const departureTimeB: any = new Date(flightB.Segments[0][0].DepartureTime);
      return departureTimeA - departureTimeB;
    });
    this.flightInfo = []
    this.flightInfo = morningFlights
    return morningFlights;
  }






  multiCItyEnable() {
    // alert('enabled')
    this.SubmitButton = false
    this.isHidden = true
  }
  multiCItyDisbale() {
    // alert('enabled')
    this.isHidden = false
    this.SubmitButton = true
  }

  toggleDisplay(): void {
    this.isHidden = !this.isHidden;
  }


  TimeFlight(checked: boolean) {


  }










  // ==========Multi Functions start============
  multiflightForm() {

    let TokenID = localStorage.getItem('TokenID')
    this.searchMultiFlightObj.TokenId = TokenID
    let RData: any = []
    for (let i = 0; i < this.dynamicForm.value.formFields.length; i++) {
      const datePipe: DatePipe = new DatePipe('en-US');
      const formattedDate: string = datePipe.transform(this.dynamicForm.value.formFields[i].PreferredDepartureTime, 'yyyy-MM-dd');
      RData.push({
        Origin: this.dynamicForm.value.formFields[i].Origin,
        Destination: this.dynamicForm.value.formFields[i].Destination,
        PreferredDepartureTime: formattedDate,
        PreferredArrivalTime: formattedDate
      })
    }

    this.searchMultiFlightObj.Segment = RData
    // this.searchMultiFlightObj.PreferredArrivalTime = 
    this.searchMultiFlightObj.JourneyType = 3
    this.searchMultiFlightObj.DirectFlight = false
    this.searchMultiFlightObj.AdultCount = this.Adults
    this.searchMultiFlightObj.ChildCount = this.childs
    this.searchMultiFlightObj.InfantCount = this.Infants
    this.searchMultiFlightObj.FlightCabinClass = this.class


    if (this.searchMultiFlightObj.FlightCabinClass === undefined || RData[0].Origin === undefined ||
      RData[0].Destination === undefined || RData[0].PreferredDepartureTime === undefined) {
      this.toastr.error('Some Fields are missing')
    }


    else {
      localStorage.setItem('PreferredDepartureTime', RData[0].PreferredDepartureTime)
      this.router.navigate(['/VIewFlightData', JSON.stringify(this.searchMultiFlightObj)])
      // this.SearchAPI(this.searchMultiFlightObj)
    }


  }

  get formFields(): FormArray {
    return this.dynamicForm.get('formFields') as FormArray;
  }


  addFormField(names: any) {

    if (this.dynamicForm.value.formFields.length < 5) {
      let formField = this.formBuilder.group({

        Origin: [],
        Destination: [],
        PreferredDepartureTime: [''],

      });

      this.formFields.push(formField);
    }
    else {
      this.toastr.error('Limit only 5')
    }
  }

  removeFormField(index: number) {

    if (this.dynamicForm.value.formFields.length > 1) {
      this.formFields.removeAt(index);
    }
    else {
      this.toastr.error('At least one flight details')
    }

  }
  // ==========Multi Functions end============

  formatDate(date: string): string {
    const dateObject = new Date(date);
    return this.datePipe.transform(dateObject, 'd MMMM');
  }

  handleFilters() {
    this.oneWayFilter = true

  }
  handleFilters2() {
    this.oneWayFilter = false

  }
  convertToCustomFormat(timeString: string): any {
    const [hours, minutes] = timeString?.split(':');
    const formattedTime = `${hours}h:${minutes}m`;
    return formattedTime;
  }


  loginSubmit() {

    let loginObj = {
      mobile: this.loginform.value.mobile.number,
      countryCode: this.loginform.value.mobile.dialCode
    }
    this.service.login(loginObj).subscribe(
      (data: any) => {
        // this.toastr.success(data.message)
        this.service.success(data.message)
        this.requestForOtp = true;

        // this.router.navigate(['/login']);

      },
      (err) => {

        this.service.error(err.error.message)
      }
    )
  }
  finalSubmitsubmit() {
    let data = {
      mobile: this.loginform.value.mobile.number,
      countryCode: this.loginform.value.mobile.dialCode,
      otp: this.loginOtp.controls.otp.value
    }
    this.service.validateLoginUser(data).subscribe(
      (data: any) => {
        // this.toastr.success(data.message)
        this.service.success(data.message)
        localStorage.setItem('token', data.token)
        localStorage.setItem('_id', data.id)
        this.CloseModels.nativeElement.click()
        this.isLoginOne = true
        // this.router.navigateByUrl('/home')
        this.requestForOtp = false
        let Access = this.service.isLoggedin()

        if (Access === true) {
          this.isLogin = true

        }
        else {
          this.isLogin = false
        }
      },
      (err) => {
        this.isLoginOne = false
        this.service.error(err.error.message)
      }
    )
  }


  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color'
    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  checkbox1Checked = false;
  checkbox2Checked = false;
  checkbox3Checked = false;
  checkbox4Checked = false;



  handleCheckboxChange(checkboxNumber: number): void {
    const checkboxes = [
      this.checkbox1Checked,
      this.checkbox2Checked,
      this.checkbox3Checked,
      this.checkbox4Checked
    ];

    checkboxes.forEach((checkbox, index) => {
      if (index !== checkboxNumber - 1) {
        checkboxes[index] = false;
      }
    });

    this.checkbox1Checked = checkboxes[0];
    this.checkbox2Checked = checkboxes[1];
    this.checkbox3Checked = checkboxes[2];
    this.checkbox4Checked = checkboxes[3];
  }

  checkbox11Checked = false;
  checkbox12Checked = false;
  checkbox13Checked = false;
  checkbox14Checked = false;
  handleReturnCheckboxChange(checkboxNumber: number): void {
    const checkboxes = [
      this.checkbox11Checked,
      this.checkbox12Checked,
      this.checkbox13Checked,
      this.checkbox14Checked
    ];

    checkboxes.forEach((checkbox, index) => {
      if (index !== checkboxNumber - 1) {
        checkboxes[index] = false;
      }
    });

    this.checkbox11Checked = checkboxes[0];
    this.checkbox12Checked = checkboxes[1];
    this.checkbox13Checked = checkboxes[2];
    this.checkbox14Checked = checkboxes[3];
  }

  getDivHeight() {
    const toTop = document.querySelector(".flightInfoReturn");
    const element = document.getElementById('#flightInfoReturn');
    this.renderer.setStyle(element, 'opacity', '0');

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 700) {
        toTop.classList.add("active");
        this.renderer.setStyle(element, 'opacity', '1');
      } else {
        toTop.classList.remove("active");
        this.renderer.setStyle(element, 'opacity', '0');


      }

    })
  }


  getReturnFlightList() {
    this.returnAirlinesList = []
    const airlines = {};
    this.returnFlight.forEach(flight => {
      const airlineName = flight.AirlineDetails.AirlineName;

      const uniqueIdentifier = `${airlineName}`;
      const totalFare = flight.Fare.TotalFare;

      if (!airlines[uniqueIdentifier]) {
        airlines[uniqueIdentifier] = {
          count: 0,
          minFare: totalFare,
          maxFare: totalFare,
        };
      } else {
        airlines[uniqueIdentifier].count += 1;
        airlines[uniqueIdentifier].minFare = Math.min(airlines[uniqueIdentifier].minFare, totalFare);
        airlines[uniqueIdentifier].maxFare = Math.max(airlines[uniqueIdentifier].maxFare, totalFare);
      }
    });

    const resultArray = Object.keys(airlines).map(identifier => ({
      airlineName: identifier,
      count: airlines[identifier].count + 1,
      minFare: airlines[identifier].minFare,
      maxFare: airlines[identifier].maxFare,
    }));



    this.returnAirlinesList = resultArray

  }



  getsearchLocationsData() {
    this.service.getsearchLocationsData().subscribe(
      (data: any) => {

        this.searchCountryList = data.data
      }
    )
  }


  loadmore() {
    this.isLoading = true;
    let offset: any;
    offset = localStorage.getItem('offset');
    offset = parseInt(offset) + 20;
    let dataObj: any;
    dataObj = localStorage.getItem('SearchInfo')
    dataObj = JSON.parse(dataObj);
    dataObj.offset = offset;
    localStorage.setItem('offset', offset);

    this.searchOneWayFlight(dataObj);
  }
  searchOneWayFlight(dataObj: any) {
    // this.isPageLoaded = true
    this.isLoaderActivate = true
    this.pathData = dataObj
    this.service.searchOneWayFlight(dataObj).subscribe(
      (res: any) => {
        this.displayFlightInfo = []
        this.closeFlight?.nativeElement.click()
        this.ngxService.stop();
        this.isPageLoaded = false
        this.isLoaderActivate = false


        if (res.data.IsSuccess == false) {

          if (res.data.Errors[0].UserMessage !== '') {
            this.closeFlight.nativeElement.click()
            this.ngxService.stop()
            this.closeDialog()
            this.toastr.error(res.data.Errors[0].UserMessage)

          }
          else {
            this.closeFlight.nativeElement.click()
            this.ngxService.stop()
            setTimeout(() => {
              this.router.navigateByUrl('/home')
            }, 100)
            this.toastr.error('some error occured')

          }

        }
        else if (res.data.Results) {
          //  window.scrollTo(0, 0);
          this.ngxService.stop();
          this.flightInfo = [];
          //  this.mainList = [];
          this.mainList = this.mainList.concat(res.data.Results[0])
          this.flightInfo = this.flightInfo.concat(res.data.Results[0])

          this.displayFlightInfo = this.displayFlightInfo.concat(res.data.Results[0]);
          this.displayFlightInfo2 = res.data.Results[0]
          this.airlineFilters = res.data.airelinesCounts
          this.displayedItems = res.data.airelinesCounts.slice(0, 4);

          this.TrackingId = res.data.TrackingId
          this.minPrice = res.data.minFare
          this.maxPrice = res.data.maxFare
          this.priceRange = res.data.maxFare
          this.isLoading = false;
          if (localStorage.getItem('myWay') === "Way2") {
            this.NJourneyType = 2
          }
          if (localStorage.getItem('myWay') === "Way1") {
            this.NJourneyType = 1
          }
          // this.NJourneyType = res.data.Results[0][0].JourneyType

          // alert(this.NJourneyType)

          this.Source = res.data.Results[0][0]?.Origins?.CityName
          this.FirstID = res.data.Results[0][0]
          this.DestinationPrint = res.data.Results[0][0].Destination?.CityName
          this.allData.push(this.flightInfo)
          this.journeyTypeDivision = res.data.Results[0].JourneyType;
          localStorage.setItem('IsDomestic', res.data.IsDomestic)

          this.getOriginData(res.data?.Results[0][0])
          this.departurDate = res.data.Results[0][0].DepartureTime
          setTimeout(() => {
            this.closeDialog()
          }, 1000)
        }
      },
      (err) => {
        this.closeDialog()
        this.ngxService.stop()

        this.noData = true;
        this.isLoaderActivate = false
        this.toastr.error(err.error?.error[0]?.UserMessage)
        // for (let i = 0; i < err.error.error.length; i++) {
        //   this.toastr.error(err.error.error[i].UserMessage)
        // }

      }
    )
  }
  searchTwoWayFlight(dataObj: any) {
    // this.isPageLoaded = true
    this.isLoaderActivate = true
    this.pathData2 = dataObj
    this.service.searchOneWayFlight(dataObj).subscribe(
      (res: any) => {
        this.displayFlightInfo = []
        this.closeFlight?.nativeElement.click()
        this.ngxService.stop();
        this.isPageLoaded = false
        this.isLoaderActivate = false


        if (res.data.IsSuccess == false) {

          if (res.data.Errors[0].UserMessage !== '') {
            this.closeFlight.nativeElement.click()
            this.ngxService.stop()
            this.closeDialog()
            this.toastr.error(res.data.Errors[0].UserMessage)

          }
          else {
            this.closeFlight.nativeElement.click()
            this.ngxService.stop()
            setTimeout(() => {
              this.router.navigateByUrl('/home')
            }, 100)
            this.toastr.error('some error occured')

          }

        }
        else if (res.data.Results) {
          //  window.scrollTo(0, 0);
          this.ngxService.stop();
          this.flightInfo = [];
          //  this.mainList = [];
          this.mainList = this.mainList.concat(res.data.Results[0])
          this.flightInfo = this.flightInfo.concat(res.data.Results[0])

          this.returnFlightInformation = res.data.Results[0];
          this.TrackingIdTwo = res.data.TrackingId
          this.airlineFilters = this.airlineFilters.concat(res.data.airelinesCounts)

          // this.minPrice =  res.data.minFare
          // this.maxPrice =  res.data.maxFare
          this.isLoading = false;
          if (localStorage.getItem('myWay') === "Way2") {
            this.NJourneyType = 2
          }
          if (localStorage.getItem('myWay') === "Way1") {
            this.NJourneyType = 1
          }
          // this.NJourneyType = res.data.Results[0][0].JourneyType

          // alert(this.NJourneyType)

          this.Source = res.data.Results[0][0]?.Origins?.CityName
          this.FirstID = res.data.Results[0][0]
          this.DestinationPrint = res.data.Results[0][0].Destination?.CityName
          this.allData.push(this.flightInfo)
          this.journeyTypeDivision = res.data.Results[0].JourneyType;
          localStorage.setItem('IsDomestic', res.data.IsDomestic)

          this.getOriginData2(res.data?.Results[0][0])
          this.ArrailvaDate = res.data.Results[0][0].DepartureTime
          setTimeout(() => {
            this.closeDialog()
          }, 1000)
        }
      },
      (err) => {
        this.closeDialog()
        this.ngxService.stop()

        this.noData = true;
        this.isLoaderActivate = false
        this.toastr.error(err.error?.error[0]?.UserMessage)
        // for (let i = 0; i < err.error.error.length; i++) {
        //   this.toastr.error(err.error.error[i].UserMessage)
        // }

      }
    )
  }
  // Load more items when the user scrolls to the bottom
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom + 1200 >= docHeight && !this.isLoading) {
      // this.loadmore();
      // console.log("done")
    }
  }
  async dynamicSearch() {

    // this.fliterPopOpen.nativeElement.click();

    this.openDialog()
    this.displayFlightInfo = [];
    this.displayFlightInfo2 = [];


    this.pathData.airlineDetails = this.searchingFilterObj;

    try {
      let flightInfos: any = await this.service.dynamicSearch(this.pathData).toPromise();


      this.closeDialog()

      this.displayFlightInfo = flightInfos.data;
      this.displayFlightInfo2 = flightInfos.data;
      // this.getOriginData( flightInfos.data[0])
    } catch (error) {
      // Handle errors
    }
  }
  async dynamicReturnSearch() {

    this.openDialog()
    this.ReturnsearchingFilterObj.AirlineNames = this.searchingFilterObj.AirlineNames
    this.ReturnsearchingFilterObj.MaxFare = this.searchingFilterObj.MaxFare
    this.pathData2.airlineDetails = this.ReturnsearchingFilterObj;

    try {
      let flightInfos: any = await this.service.dynamicSearch(this.pathData2).toPromise();

      this.closeDialog()

      this.returnFlightInformation = flightInfos.data;
      // this.getOriginData2(flightInfos.data[0])
    } catch (error) {
      // Handle errors
    }
  }




  formatDuration(duration: string): string {
    if (!duration) return '';

    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    let result = '';
    if (hours > 0) {
      result += `${hours}hrs  `;
    }
    if (minutes > 0) {
      result += `${minutes}mins`;
    }

    return result.trim();
  }
}


