<div class="stay-cpmponent">


    <div class="stayBox">
        <div class="container">
            <div class="contents">
                <div class="row myrow justify-content-center">
                    <h1 class="find">Find your next stay</h1>
                    <p class="paras">Search deals on hotels, homes, and much more...</p>
                    <div class="col-xl-3 col-md-12">
                        <div class="input-group mb-3">
                            <!-- <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                    src="./../../../assets/images/stay/stay-icon.png" alt=""></span> -->
                            <!-- <input type="text" class="form-control box-data" placeholder="Where are you going?"
                                aria-label="Username" aria-describedby="basic-addon1"> -->

                                <ng-select [placeholder]="'Where to Go'" class="my-ng-select" [ngStyle]="{ 'color':'red' }">
                                <ng-option *ngFor="let car of cars" [value]="car.id">
                                    <p class="SelectedList" style="margin: 0;"> {{car.name}}
                                        ({{car.id}} )</p>
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-xl-2">
                        <input type="text" placeholder="Check-in" 
                        class="form-control box-data intheSpace" bsDatepicker>
                        <!-- <svg viewBox="0 0 24 24" width="24px" height="24px" fill="currentColor" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted CallendersLink"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg> -->
                        <!-- <mat-icon >calendar_today</mat-icon> -->
                        <img class="CallendersLink"
                                            src="./../../../assets/images/stay/cal.png"
                                            alt="">
                </div>
                    <div class="col-xl-2">
                        <input type="text" placeholder="Check-out" 
                        class="form-control box-data intheSpace" bsDatepicker>
                        <!-- <svg viewBox="0 0 24 24" width="24px" height="24px" fill="currentColor" focusable="false" class="mat-datepicker-toggle-default-icon ng-star-inserted CallendersLink"><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg> -->
                        <!-- <mat-icon >calendar_today</mat-icon> -->
                        <img class="CallendersLink"
                                            src="./../../../assets/images/stay/cal.png"
                                            alt="">
                    </div>
                    <div class="col-xl-2">

                        <div class="input-group mb-3">
                            <span class="input-group-text inp-sybl"><img class="stay-icons3"
                                    src="./../../../assets/images/stay/profile.png" alt=""></span>
                            <input type="number" placeholder="Adults" class="form-control box-data"
                                aria-label="Amount (to the nearest dollar)">
                            <span class="input-group-text inp-sybl"></span>
                        </div>
                    </div>
        
                </div>
                <button class="searchBtn">Search</button>
            </div>
        </div>
    </div>

    <!-- Carousel Start -->
    <div class="container">
        <div class="popular-locations">
            <p class="popular-locations-title">Explore United Arab Emirates</p>
            <p class="popular-locations-content">These popular destinations have a lot to offer</p>
            <div class="owl-carousel owl-theme">

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/japan.png" alt="">
                        <p class="popular-location-img-title">Japan</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/south-korea.png" alt="">
                        <p class="popular-location-img-title">South Korea</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/tokyo.png" alt="">
                        <p class="popular-location-img-title">Tokyo</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/australia.png" alt="">
                        <p class="popular-location-img-title">Australia</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/bali.png" alt="">
                        <p class="popular-location-img-title">Bali</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/india.png" alt="">
                        <p class="popular-location-img-title">India</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

            </div>
        </div>

        <div class="popular-locations2">
            <p class="popular-locations-title">Trending destinations</p>
            <p class="popular-locations-content">Travelers searching for United Arab Emirates also booked these</p>
            <div class="row">
                <div class="col-xl-6">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img src="./../../../assets/images/stay/dubai.png" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>Dubai
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            $1,521.56
                        </p>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img src="./../../../assets/images/stay/city.png" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>Dubai
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            $1,521.56
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img src="./../../../assets/images/stay/burge.png" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>Dubai
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            $1,521.56
                        </p>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img src="./../../../assets/images/stay/night.png" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>Dubai
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            $1,521.56
                        </p>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img src="./../../../assets/images/stay/water.png" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>Dubai
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            $1,521.56
                        </p>
                    </div>
                </div>
            </div>

            <div class="hotelsData-section">
                <div class="row">
                    <p style="padding: 16px 0px;" class="popular-locations-title">Homes guests love </p>
                  <div class="col-xl-3 col-md-6">
                    <div class="hotels-below">
                      <div class="imageTop-data">
                        <img src="./../../../assets/images/stay/chair.png" alt="" class="hotel-images" />
                      </div>
                      <p class="hotel-name">Aparthotel Stare Miasto</p>
                      <p class="adress-edit">Old Town, Poland, Kraków</p>
                      <p class="hotel-price">Starting from <span class="price-inside">₹ 14,753</span></p>
                      <p class="ratings-para">
                        <label for="ratings" class="rating-value">7.5</label
                        >Excellent 2,293 review
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="hotels-below">
                      <div class="imageTop-data">
                        <img src="./../../../assets/images/stay/cot.png" alt="" class="hotel-images" />
                      </div>
                      <p class="hotel-name">Aparthotel Stare Miasto</p>
                      <p class="adress-edit">Old Town, Poland, Kraków</p>
                      <p class="hotel-price">Starting from <span class="price-inside">₹ 14,753</span></p>
                      <p class="ratings-para">
                        <label for="ratings" class="rating-value">7.5</label
                        >Excellent 2,293 review
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="hotels-below">
                      <div class="imageTop-data">
                        <img src="./../../../assets/images/stay/lights.png" alt="" class="hotel-images" />
                      </div>
                      <p class="hotel-name">Aparthotel Stare Miasto</p>
                      <p class="adress-edit">Old Town, Poland, Kraków</p>
                      <p class="hotel-price">Starting from <span class="price-inside">₹ 14,753</span></p>
                      <p class="ratings-para">
                        <label for="ratings" class="rating-value">7.5</label
                        >Excellent 2,293 review
                      </p>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="hotels-below">
                      <div class="imageTop-data">
                        <img src="./../../../assets/images/stay/chair.png" alt="" class="hotel-images" />
                      </div>
                      <p class="hotel-name">Aparthotel Stare Miasto</p>
                      <p class="adress-edit">Old Town, Poland, Kraków</p>
                      <p class="hotel-price">Starting from <span class="price-inside">₹ 14,753</span></p>
                      <p class="ratings-para">
                        <label for="ratings" class="rating-value">7.5</label
                        >Excellent 2,293 review
                      </p>
                    </div>
                  </div>
                </div>
              </div>
        </div>

    </div>

</div>
<!-- Carousel End -->