import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { final, saveFailedTicketDetails, saveTicketDetails } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-package-confirmation',
  templateUrl: './package-confirmation.component.html',
  styleUrls: ['./package-confirmation.component.css']
})
export class PackageConfirmationComponent {
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;
  isLCC: any = localStorage.getItem('isLCC')
  @ViewChild('CloseModels') CloseModels!: ElementRef;
  saveTicketDetailsObj = new saveTicketDetails()
  saveFailedTicketDetailsObj = new saveFailedTicketDetails()
  userId: any
  paymentObject: any = []
  paymentInfo: any
  eciDescription: any
  refNumberSave: any
  allPaymentInfo: any
  isNotRealPayment: boolean = true
  finalObj = new final()
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private service: AuthService, private ngxService: NgxUiLoaderService) { }
  ngOnDestroy(): void {
    // localStorage.removeItem('information');
    // localStorage.removeItem('information2');
  }
  ngOnInit(): void {



    this.userId = localStorage.getItem('_id')
    // this. Booking_Both()


    this.route.queryParams.subscribe(params => {

      const refNumber = params['ref'];
      this.refNumberSave = params['ref'];
      // this.orderVerification(this.refNumberSave)

      // alert(refNumber)

      let information = {
        number: refNumber,
        rf: localStorage.getItem('RF')
      }
      this.service.PackagepaymentCheck(information).subscribe(
        (result: any) => {
            
          if (result.response[0].state === 'CAPTURED') {
              // this.router.navigateByUrl('/bookings')
              this.router.navigateByUrl(`/packageBookingVIew/${result.packageId}`)
          }
  
          else {
            this.paymentObject = result.response[0]

           
            if(result.bankMessage){
              this.paymentInfo = result.bankMessage
              this.openDialog()
              return
            }
            else{
              this.paymentInfo = result.response[0]['3ds']['summaryText'];
              this.openDialog()
              return
            }
         
           
          }


        },
        (err) => {
          if (err.error?.errors) {
            this.openDialog()
            this.paymentInfo = err.error?.errors?.message
            this.service.error(err.error?.errors?.message)

            return;
          }
          if (err.error?.error) {
            this.openDialog()
            this.paymentInfo = err.error?.error?.message
            this.service.error(err.error?.error?.message)

            return;
          }
          if (err.error?.error) {
            this.openDialog()
            this.paymentInfo = err.error?.error.message
            this.paymentInfo = err.error?.error
            this.service.error(err.error?.error)
            return;
          }
          this.openDialog()
          // localStorage.removeItem('RF')
          localStorage.removeItem('information')
          // this.router.navigateByUrl('/home')
        }

      )
    });
  }



  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flight_load.json',
  };


  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color',
      width: '600px',
      height: '300px'

    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  gotoHome() {
    this.closeDialog()
    this.router.navigateByUrl('/home')
  }


}
