<div class="flight-component">

    <div class="search-box">

        <div class="container containerAdjest">
            <ul class="nav nav-tabs flight-sec" id="myTab" role="tablist">
                <li (click)="multiCItyDisbale()" class="nav-item" role="presentation">
                    <button #OneWayButton (click)="selectWay($event)" value="One-Way"
                        class="nav-link active flightsec-custmbt" id="return-tab" data-bs-toggle="tab"
                        data-bs-target="#return" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <span>
                            <div class="circ"></div>
                        </span> One-Way</button>
                </li>
                <li (click)="multiCItyDisbale()" class="nav-item" role="presentation">
                    <button #returnButton (click)="selectWay($event)" value="Return" class="nav-link flightsec-custmbt"
                        id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway" type="button" role="tab"
                        aria-controls="profile" aria-selected="false"><span>
                            <div class="circ"></div>
                        </span>Return</button>
                </li>
                <li (click)="multiCItyEnable()" class="nav-item" role="presentation">
                    <button (click)="selectWay($event)" value="Multi-City" class="nav-link flightsec-custmbt"
                        id="multicity-tab" data-bs-toggle="tab" data-bs-target="#multicity" type="button" role="tab"
                        aria-controls="contact" aria-selected="false"><span>
                            <div class="circ"></div>
                        </span> Multi-City</button>
                </li>
            </ul>
            <div class="data-collect">
                <div [style.display]="isHidden ? 'none' : 'block'" class="data-aligh">
                    <div class="row justify-content-center">
                        <div class="col-md-3 mb-2">
                            <div class="fromBox">
                                <span class="formText">From</span>
                                <div>
                                    <ng-select class="my-ng-select" [ngStyle]="{ 'color':'red' }" [(ngModel)]="Origin"
                                        [clearable]="false" (change)="dataSelect($event)">
                                        <ng-option *ngFor="let car of cars" [value]="car.id">
                                            <p class="SelectedList" style="margin: 0;"> {{car.name}}
                                                ({{car.id}} )</p>
                                        </ng-option>
                                    </ng-select>
                                    <!-- <input type="email" class="form-control main-input" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-2">
                            <div class="fromBox">
                                <span class="formText">To</span>
                                <div>
                                    <ng-select [clearable]="false" [(ngModel)]="Destination"
                                        (change)="dataSelect2($event)" #selectBox>
                                        <ng-option *ngFor="let car of cars selectedPlane " [value]="car.id">
                                            <p class="SelectedList" style="margin: 0;"> {{car.name}}
                                                ({{car.id}} )</p>
                                        </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-2 mb-2">
                            <div (click)="picker.open()" class="fromBox">
                                <span class="formText2">Departure</span>
                                <mat-form-field appearance="fill">
                                    <input [(ngModel)]="fromDates" matInput [matDatepicker]="picker"
                                        [formControl]="fromDate" placeholder="Leave on" matIconSuffix="picker">
                                    <!-- <mat-datepicker-toggle matIconSuffix [for]="picker">
                   
                      </mat-datepicker-toggle> -->
                                    <img style="width: 25px; height: 25px;     position: absolute;
                      right: 10px;
                      top: 23%;
                      bottom: 50%;" src="./../../../../assets/images/stay/cal.png" alt="">
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="isReturnTravell"  class="col-md-2 mb-2">

                            <div (click)="chooseReturn()" (click)="picker2.open()" class="fromBox">
                                <span class="formText2">Return</span>
                                <mat-form-field appearance="fill">
                                    <input [(ngModel)]="isReturnValue" matInput [matDatepicker]="picker2"
                                        placeholder="Leave on" matIconSuffix="picker2">
                                    <!-- <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle> -->
                                    <img style="width: 25px; height: 25px;     position: absolute;
                      right: 10px;
                      top: 23%;
                      bottom: 50%;" src="./../../../../assets/images/stay/cal.png" alt="">
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="col-md-2 mb-2">
                            <div class="btn-group">
                                <div class="btn btn-success dropdown-toggle " type="button" data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside" #closePassingerDropdown aria-expanded="false"
                                    class="fromBox formText">
                                    <span style="position: absolute;" class="formText">Passenger & Class</span>
                                    <span class="countOfMembers">{{Adults}},{{childs}},{{Infants}} -{{className}}</span>
                                    <div>
                                    </div>
                                </div>

                                <ul class="dropdown-menu  PassingerSelectBox">
                                    <div class="passingersListss">
                                        <p class="passingersAdult">ADULTS (12Y +)</p>
                                        <p class="ontheTravel">On the day of travel</p>
                                        <div class="row">
                                            <div class="col-md-10">
                                                <div class="btn-group CountOfGroup" role="group"
                                                    aria-label="Basic radio toggle button group ">
                                                    <input type="radio" class="btn-check" (click)="selectAdults(1)"
                                                        name="adults" id="adults1" autocomplete="off" checked>
                                                    <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                        for="adults1">1</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(2)"
                                                        name="adults" id="adults2" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults2">2</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(3)"
                                                        name="adults" id="adults3" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults3">3</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(4)"
                                                        name="adults" id="adults4" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults4">4</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(5)"
                                                        name="adults" id="adults5" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults5">5</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(6)"
                                                        name="adults" id="adults6" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults6">6</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(7)"
                                                        name="adults" id="adults7" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults7">7</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(8)"
                                                        name="adults" id="adults8" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults8">8</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(9)"
                                                        name="adults" id="adults9" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="adults9">9</label>

                                                    <input type="radio" class="btn-check" (click)="selectAdults(10)"
                                                        name="adults" id="adults10" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber CountOfNRadius2"
                                                        for="adults10">10</label>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <p class="passingersAdult2">CHILDREN (2y - 12y)</p>
                                                <p class="ontheTravel">On the day of travel</p>
                                                <div class="btn-group CountOfGroup" role="group2"
                                                    aria-label="Basic radio toggle button group2 ">
                                                    <input type="radio" class="btn-check" (click)="selectChilds(0)"
                                                        name="children" id="children1" autocomplete="off" checked>
                                                    <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                        for="children1">0</label>

                                                    <input type="radio" class="btn-check" (click)="selectChilds(1)"
                                                        name="children" id="children2" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="children2">1</label>

                                                    <input type="radio" class="btn-check" (click)="selectChilds(2)"
                                                        name="children" id="children3" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="children3">2</label>

                                                    <input type="radio" class="btn-check" (click)="selectChilds(3)"
                                                        name="children" id="children4" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="children4">3</label>

                                                    <input type="radio" class="btn-check" (click)="selectChilds(4)"
                                                        name="children" id="children5" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="children5">4</label>

                                                    <input type="radio" class="btn-check" (click)="selectChilds(5)"
                                                        name="children" id="children6" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="children6">5</label>

                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="passingersAdult2">INFANTS (below 2y)</p>
                                                <p class="ontheTravel">On the day of travel</p>
                                                <div class="btn-group CountOfGroup" role="group3"
                                                    aria-label="Basic radio toggle button group3 ">
                                                    <input type="radio" class="btn-check" (click)="selectInfants(0)"
                                                        name="infants" id="infants1" autocomplete="off" checked>
                                                    <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                        for="infants1">0</label>

                                                    <input type="radio" class="btn-check" (click)="selectInfants(1)"
                                                        name="infants" id="infants2" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="infants2">1</label>

                                                    <input type="radio" class="btn-check" (click)="selectInfants(2)"
                                                        name="infants" id="infants3" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="infants3">2</label>

                                                    <input type="radio" class="btn-check" (click)="selectInfants(3)"
                                                        name="infants" id="infants4" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="infants4">3</label>

                                                    <input type="radio" class="btn-check" (click)="selectInfants(4)"
                                                        name="infants" id="infants5" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="infants5">4</label>

                                                    <input type="radio" class="btn-check" (click)="selectInfants(5)"
                                                        name="infants" id="infants6" autocomplete="off">
                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                        for="infants6">5</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="passingersAdult3">CHOOSE TRAVEL CLASS</p>

                                            <div class="btn-group CountOfGroup" role="group3"
                                                aria-label="Basic radio toggle button group3">
                                                <input (click)="selectClass('1')" type="radio" class="btn-check"
                                                    name="classse" id="infants111" autocomplete="off" checked>
                                                <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                    for="infants111">All
                                                    Classes</label>

                                                <input (click)="selectClass('2')" type="radio" class="btn-check"
                                                    name="classse" id="infants222" autocomplete="off">
                                                <label class="btn btn-outline-primary CountOfNumber"
                                                    for="infants222">Economy</label>

                                                <input (click)="selectClass(3)" type="radio" class="btn-check"
                                                    name="classse" id="infants333" autocomplete="off">
                                                <label class="btn btn-outline-primary CountOfNumber"
                                                    for="infants333">Premium Economy</label>

                                                <input (click)="selectClass(4)" type="radio" class="btn-check"
                                                    name="classse" id="infants444" autocomplete="off">
                                                <label class="btn btn-outline-primary CountOfNumber"
                                                    for="infants444">Business</label>

                                                <input (click)="selectClass(5)" type="radio" class="btn-check"
                                                    name="classse" id="infants555" autocomplete="off">
                                                <label class="btn btn-outline-primary CountOfNumber"
                                                    for="infants555">Premium Business</label>

                                                <input (click)="selectClass(6)" type="radio" class="btn-check"
                                                    name="classse" id="infants666" autocomplete="off">
                                                <label class="btn btn-outline-primary CountOfNumber"
                                                    for="infants666">First</label>
                                            </div>


                                            <button data-bs-dismiss="dropdown" (click)="closePasingerCOunt()"
                                                type="button" class="btn btn-secondary DoneSelection">Done</button>
                                        </div>
                                    </div>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div [style.display]="isHidden ? 'block' : 'none'" class="data-aligh">
                    <div>
                        <form [formGroup]="dynamicForm">
                            <div formArrayName="formFields">

                                <div *ngFor="let field of formFields.controls; let isLast = last let i = index" [formGroupName]="i">
                                    <!-- <h6 class="flight-number">Flight {{i+1}}</h6> -->
                                    <div>
                                        <div class="row">

                                            <div class="col-md-3 mb-2">
                                                <div class="fromBox">
                                                    <span class="formText">From</span>
                                                    <div>
                                                        <ng-select [clearable]="false" formControlName="Origin"
                                                            placeholder="Origin">
                                                            <ng-option *ngFor="let car of cars " [value]="car.id"><i
                                                                    class="fas fa-plane selectedPlane"></i>
                                                                {{car.name}} ({{car.id}})</ng-option>
                                                        </ng-select>
                                                        <!-- <input type="email" class="form-control main-input" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 mb-2">
                                                <div class="fromBox">
                                                    <span class="formText">To</span>
                                                    <div>
                                                        <ng-select [clearable]="false" formControlName="Destination"
                                                            placeholder="Destination">
                                                            <ng-option *ngFor="let car of cars" [value]="car.id"> <i
                                                                    class="fas fa-plane selectedPlane"></i>
                                                                {{car.name}} ({{car.id}})</ng-option>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2 mb-2">
                                                <div class="fromBox">
                                                    <span class="formText2">Departure</span>


                                                    <mat-form-field appearance="fill">
                                                        <input [min]="minDate" formControlName="PreferredDepartureTime"
                                                            matInput [matDatepicker]="picker4" placeholder="Leave on"
                                                            matIconSuffix="picker4">
                                                        <mat-datepicker-toggle matIconSuffix
                                                            [for]="picker4"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker4 [min]="minDate"></mat-datepicker>
                                                    </mat-form-field>

                                                </div>
                                            </div>
                                            
                                            <div  *ngIf="isLast" class="col-md-2 mb-2">
                                                <div class="btn-group">
                                                    <div class="btn btn-success dropdown-toggle " type="button"
                                                        data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                                        #closePassingerDropdown aria-expanded="false" class="fromBox formText">
                                                        <span style="position: absolute;" class="formText">Passenger &
                                                            Class</span>
                                                        <span
                                                            class="countOfMembers">{{totalCountPassinger}}-{{className}}</span>
                                                        <div>
                                                        </div>
                                                    </div>
        
                                                    <ul class="dropdown-menu  PassingerSelectBox">
                                                        <div class="passingersListss">
                                                            <p class="passingersAdult">ADULTS (12Y +)</p>
                                                            <p class="ontheTravel">On the day of travel</p>
                                                            <div class="row">
                                                                <div class="col-md-10">
                                                                    <div class="btn-group CountOfGroup" role="group"
                                                                        aria-label="Basic radio toggle button group ">
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(1)" name="adults"
                                                                            id="aadults1" autocomplete="off" checked>
                                                                        <label
                                                                            class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                                            for="aadults1">1</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(2)" name="adults"
                                                                            id="aadults2" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults2">2</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(3)" name="adults"
                                                                            id="aadults3" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults3">3</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(4)" name="adults"
                                                                            id="aadults4" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults4">4</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(5)" name="adults"
                                                                            id="aadults5" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults5">5</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(6)" name="adults"
                                                                            id="aadults6" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults6">6</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(7)" name="adults"
                                                                            id="aadults7" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults7">7</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(8)" name="adults"
                                                                            id="aadults8" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults8">8</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(9)" name="adults"
                                                                            id="aadults9" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="aadults9">9</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectAdults2(10)" name="adults"
                                                                            id="aadults10" autocomplete="off">
                                                                        <label
                                                                            class="btn btn-outline-primary CountOfNumber CountOfNRadius2"
                                                                            for="aadults10">10</label>
        
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <p class="passingersAdult2">CHILDREN (2y - 12y)</p>
                                                                    <p class="ontheTravel">On the day of travel</p>
                                                                    <div class="btn-group CountOfGroup" role="group2"
                                                                        aria-label="Basic radio toggle button group2 ">
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(0)" name="children"
                                                                            id="achildren1" autocomplete="off" checked>
                                                                        <label
                                                                            class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                                            for="achildren1">0</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(1)" name="children"
                                                                            id="achildren2" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="achildren2">1</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(2)" name="children"
                                                                            id="achildren3" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="achildren3">2</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(3)" name="children"
                                                                            id="achildren4" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="achildren4">3</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(4)" name="children"
                                                                            id="achildren5" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="achildren5">4</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectChilds2(5)" name="children"
                                                                            id="achildren6" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="achildren6">5</label>
        
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <p class="passingersAdult2">INFANTS (below 2y)</p>
                                                                    <p class="ontheTravel">On the day of travel</p>
                                                                    <div class="btn-group CountOfGroup" role="group3"
                                                                        aria-label="Basic radio toggle button group3 ">
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(0)" name="infants"
                                                                            id="ainfants1" autocomplete="off" checked>
                                                                        <label
                                                                            class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                                            for="ainfants1">0</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(1)" name="infants"
                                                                            id="ainfants2" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="ainfants2">1</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(2)" name="infants"
                                                                            id="ainfants3" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="ainfants3">2</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(3)" name="infants"
                                                                            id="ainfants4" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="ainfants4">3</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(4)" name="infants"
                                                                            id="ainfants5" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="ainfants5">4</label>
        
                                                                        <input type="radio" class="btn-check"
                                                                            (click)="selectInfants2(5)" name="infants"
                                                                            id="ainfants6" autocomplete="off">
                                                                        <label class="btn btn-outline-primary CountOfNumber"
                                                                            for="ainfants6">5</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p class="passingersAdult3">CHOOSE TRAVEL CLASS</p>
        
                                                                <div class="btn-group CountOfGroup" role="group3"
                                                                    aria-label="Basic radio toggle button group3">
                                                                    <input type="radio" (click)="selectClass2(1)"
                                                                        class="btn-check" name="classse" id="ainfants111"
                                                                        autocomplete="off" checked>
                                                                    <label
                                                                        class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                                                        for="ainfants111">All
                                                                        Classes</label>
        
                                                                    <input type="radio" (click)="selectClass2(2)"
                                                                        class="btn-check" name="classse" id="ainfants222"
                                                                        autocomplete="off">
                                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                                        for="ainfants222">Economy</label>
        
                                                                    <input type="radio" (click)="selectClass2(3)"
                                                                        class="btn-check" name="classse" id="ainfants333"
                                                                        autocomplete="off">
                                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                                        for="ainfants333">Premium
                                                                        Economy</label>
        
                                                                    <input type="radio" (click)="selectClass2(4)"
                                                                        class="btn-check" name="classse" id="ainfants444"
                                                                        autocomplete="off">
                                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                                        for="ainfants444">Business</label>
        
                                                                    <input type="radio" (click)="selectClass2(5)"
                                                                        class="btn-check" name="classse" id="ainfants555"
                                                                        autocomplete="off">
                                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                                        for="ainfants555">Premium
                                                                        Business</label>
        
                                                                    <input type="radio" (click)="selectClass2(6)"
                                                                        class="btn-check" name="classse" id="ainfants666"
                                                                        autocomplete="off">
                                                                    <label class="btn btn-outline-primary CountOfNumber"
                                                                        for="ainfants666">First</label>
                                                                </div>
        
        
                                                                <button data-bs-dismiss="dropdown"
                                                                    (click)="closePasingerCOunt()" type="button"
                                                                    class="btn btn-secondary DoneSelection">Done</button>
                                                            </div>
                                                        </div>
        
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-1 minusBx">
                                                <img  style="width: 30px ; height: 30px;" tooltip="Remove" (click)="removeFormField(i)"
                                                *ngIf="i >= 2"
                                                        class="form-group myButton"
                                                        src="./../../../assets/images/aboutus/minus.png" alt="">
                                            </div>
                                        
                                            <a *ngIf="isLast"  class="addText" (click)="addFormField('')">Add +</a>


                                        </div>


                                    </div>
                                </div>
                                <div class="row justify-content-center">
                              







                                    <div class="col-md-3 mb-2 p-0"></div>
                                    <div class="col-md-2 mb-2 p-0"></div>
                                    <div class="col-md-2 mb-2 p-0"></div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>



            </div>
            <button type="submit" *ngIf="SubmitButton" (click)="updateFIlter()"
                class="search-hotels-btn">Search</button>
            <button type="submit" *ngIf="!SubmitButton" (click)="multiflightForm()" class="search-hotels-btn">Search
                Multi-City</button>
        </div>
    </div>

    <!-- Carousel Start -->
    <div class="container">
        <!-- Offer Cards Starts -->
        <div class="offers-cards">
            <p class="offers-cards-title">Offers</p>
            <div class="inside-offers-cards">
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-1.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-2.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-3.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-4.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Offer Cards End -->

        <div class="popular-locations">
            <p class="popular-locations-title">Popular Location</p>
            <p class="popular-locations-content">These popular destinations have a lot to offer</p>
            <div class="owl-carousel owl-popular-locations owl-theme">

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/japan.png" alt="">
                        <p class="popular-location-img-title">Japan</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/south-korea.png" alt="">
                        <p class="popular-location-img-title">South Korea</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/tokyo.png" alt="">
                        <p class="popular-location-img-title">Tokyo</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/australia.png" alt="">
                        <p class="popular-location-img-title">Australia</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/bali.png" alt="">
                        <p class="popular-location-img-title">Bali</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/india.png" alt="">
                        <p class="popular-location-img-title">India</p>
                        <p class="popular-location-img-property">4,285 Properties</p>
                    </div>
                </div>

            </div>
        </div>


    </div>

</div>
<!-- Carousel End -->