<div *ngIf="!isCOnfirm" class="flight-review-component">

    <div class="container">
        <div class="main-COntent">
            <h1 class="complite-text">Complete Your Booking</h1>
            <div class="row">
                <div class="col-md-9">
                    <ngx-skeleton-loader *ngIf="isPageLoaded" count="20" [theme]="{ 
                      'border-radius': '5px',
                      height: '50px',
                    }"></ngx-skeleton-loader>
                    <div *ngIf="!isPageLoaded">


                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Flights Summary <br>
                                      
                                    </mat-panel-title>
                        
                                </mat-expansion-panel-header>
    
                         
                        <div *ngFor="let item of PageInfo.Segments ; let i = index " class="Flight-infrmation">
                            <div *ngFor="let items of item ">
                                <ul class="FLighRoute">
                                    <li>
                                        <h1 class="areaText"><span><img class="IconFLight"
                                                    src="./../../../../assets/images/FlightIcon.svg" alt=""></span>

                                            {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                                    </li>
                                    <li>
                                        <!-- <p class="FareRule">View Fare Rules</p> -->
                                    </li>
                                </ul>
                                <div class="tickets">
                                    <ul class="fromsTicket">
                                        <li class="LiInfo">
                                            <img class="AriICons" src="./../../../../assets/images/airLine.svg"
                                                alt=""><br>
                                            <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                                            <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                                                {{items.AirlineDetails.FlightNumber}}</p>
                                        </li>
                                        <li class="LiInfo">
                                            <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}</p>
                                            <h1 class="StartTime">{{items.Origin.AirportCode}} {{items.DepartureTime
                                                |date:
                                                'HH:mm' : 'en-US'}}</h1>
                                            <P class="Airports">{{items.Origin.AirportName}}, {{items.Origin.CityName}},
                                                {{items.Origin.CountryName}}</P>
                                        </li>
                                        <li class="LiInfo">
                                            <div class="directIons">
                                                <div class="rounds"></div>
                                                <p style="margin: 0; " class="DurayionData"> ---{{items.Duration}}---
                                                </p>
                                                <img class="img-go" style="height: 20px;"
                                                    src="./../../../../assets/images/FlightIcon.svg" alt="">
                                            </div>
                                        </li>
                                        <li class="LiInfo">
                                            <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                                            <h1 class="StartTime">{{items.Destination.AirportCode}} {{items.ArrivalTime
                                                |date: 'HH:mm' : 'en-US'}}</h1>
                                            <P class="Airports">{{items.Destination.AirportName}},
                                                {{items.Destination.CityName}}, {{items.Destination.CountryName}}</P>
                                        </li>
                                    </ul>
                                    <div class="lins">
                                        <p class="TotalKg"> <span><i
                                                    class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}} (1
                                            piece
                                            only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                                            {{items.CabinClass}}
                                        </p>

                                    </div>

                                </div>

                            </div>
                            <div class="ContinueNext">
                                <button (click)="scrollToSection(travellerDetails)"
                                    class="ContinueBtns2">Continue</button>
                            </div>
                        </div>
    
                            </mat-expansion-panel>
    
                        </mat-accordion>

                    </div>



                    <div *ngFor="let item of passingerDetails" class="InportantInfo">
                        <ul class="ImportantHeade">
                            <li>
                                <h1 class="ImportantHead">Passinger Details</h1>
                            </li>
                            <li><i style="cursor: pointer;" (click)="isIsEdit(item)"  data-bs-toggle="modal" data-bs-target="#exampleModal"  class="fas fa-edit"></i></li>
                        </ul>
                        <ul class="list-data-prev">
                            <li class="list-names">
                                <p class="firstName">First Name</p>
                                <h1 class="Username">{{item?.FirstName }}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Last Name</p>
                                <h1 class="Username">{{item?.LastName}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Gender</p>
                                <h1 *ngIf="item?.Gender ==1" class="Username">Male</h1>
                                <h1 *ngIf="item?.Gender ==2" class="Username">Female</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Date of Birth</p>
                                <h1 class="Username">{{item.DateOfBirth | date:'mediumDate'}}</h1>
                       
                            </li>
                        </ul>

                        <h1 class="ImportantHead">Contact Details</h1>
                        <ul class="list-data-prev">
                            <li class="list-names">
                                <p class="firstName">Country Code</p>
                                <h1 class="Username">{{item?.Mobile1CountryCode}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Contact No.</p>
                                <h1 class="Username">{{item?.Mobile1}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Email Address</p>
                                <h1 class="Username">{{item?.Email}}</h1>
                            </li>
                        </ul>



                        <h1 class="ImportantHead">Passport Details</h1>
                        <ul class="list-data-prev">
                            <li class="list-names">
                                <p class="firstName">Passport No.</p>
                                <h1 class="Username">{{item?.PassportNo}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Expire Date</p>
                                <h1 class="Username">{{item?.PassportExpiry | date:'mediumDate'}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Issued Country</p>
                                <h1 class="Username">{{item?.IssuedCountryCode}}</h1>
                            </li>
                            <li class="list-names">
                                <p class="firstName">Nationality</p>
                                <h1 class="Username">{{item?.Nationality?.CountryName}}</h1>
                            </li>
                        </ul>
                    </div>


                    <div class="InportantInfo">
                        <h1 class="ImportantHead">Important Information</h1>
                        <ul class="list-data">
                            <li class="list-info">
                                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a
                                    printed or soft copy of the boarding pass.</p>
                            </li>
                            <li class="list-info">
                                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a
                                    printed or soft copy of the boarding pass.</p>
                            </li>
                            <li class="list-info">
                                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a
                                    printed or soft copy of the boarding pass.</p>
                            </li>
                            <li class="list-info">
                                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a
                                    printed or soft copy of the boarding pass.</p>
                            </li>
                            <li class="list-info">
                                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a
                                    printed or soft copy of the boarding pass.</p>
                            </li>

                        </ul>

                        <p #travellerDetails class="content-para">DISCLAIMER: The information provided above is only for
                            ready reference
                            and convenience of customers, and may not be exhaustive.
                            We strongly recommend that you check the full text of the guidelines issued by the State
                            Governments and Airlines before traveling to ensure smooth travel. We accept no liability in
                            this regard. In case you do not meet the required guidelines, the airline or state
                            authorities can stop you from traveling.</p>
                    </div>
                    <div >


                      
                    </div>
                    <button (click)="submit()" class="ContinueBtns">Continues</button>
                    <!-- <button (click)="previeCheck()" class="ContinueBtns">Continue</button> -->
                </div>
                <div class="col-md-3">
                    <div class="fareDetails">
                        <h1 class="summary">Fare Summary</h1>
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        <!-- <span class="accordion-icon"></span> -->
                                        <ul class="BaseINfo">
                                            <li>
                                                <p class="basing">Base Fare</p>
                                            </li>
                                            <li>
                                                <p class="basing"></p>
                                            </li>
                                        </ul>
                                    </button>

                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">

                                        <div class="fareList">
                                            <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                    <span *ngIf="fare.PassengerType == 2">Child </span>
                                                    <span *ngIf="fare.PassengerType == 3">Infant </span>

                                                    ({{fare.PassengerCount}})
                                                </li>
                                                <!-- <li ><span *ngIf="fare.PassengerType == 2">Child </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li>
                                                <li ><span *ngIf="fare.PassengerType == 3">Infant </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li> -->
                                                <!-- <li *ngIf="fare.PassengerCount == 2">Child ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li>
                                                <li *ngIf="fare.PassengerCount == 2">Infant ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li> -->

                                                <li>{{fare.TotalFare | number:'1.0-2'}} {{fare.Currency }}</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                        aria-controls="flush-collapseTwo">
                                        <!-- <span class="accordion-icon"></span> -->
                                        <ul class="BaseINfo">
                                            <li>
                                                <p class="basing">Taxes and Surcharges</p>
                                            </li>
                                            <li>
                                                <p class="basing"></p>
                                            </li>
                                        </ul>
                                    </button>

                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">

                                        <ul *ngFor="let fare of fareBreak" class="farebreak">
                                            <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                <span *ngIf="fare.PassengerType == 2">Child </span>
                                                <span *ngIf="fare.PassengerType == 3">Infant </span>

                                                ({{fare.PassengerCount}})
                                            </li>
                                            <!-- <li ><span *ngIf="fare.PassengerType == 2">Child </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li>
                                            <li ><span *ngIf="fare.PassengerType == 3">Infant </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li> -->
                                            <!-- <li *ngIf="fare.PassengerCount == 2">Child ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li>
                                            <li *ngIf="fare.PassengerCount == 2">Infant ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li> -->

                                            <li>{{fare.Tax | number:'1.0-2'}} {{fare.Currency }}</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree" aria-expanded="false"
                                        aria-controls="flush-collapseThree">
                                        <!-- <span class="accordion-icon"></span> -->
                                        <ul class="BaseINfo">
                                            <li>
                                                <p class="basing">Other Charges</p>
                                            </li>
                                            <li>
                                                <p class="basing"></p>
                                            </li>
                                        </ul>
                                    </button>

                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">

                                        <ul *ngFor="let fare of fareBreak" class="farebreak">
                                            <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                <span *ngIf="fare.PassengerType == 2">Child </span>
                                                <span *ngIf="fare.PassengerType == 3">Infant </span>

                                                ({{fare.PassengerCount}})
                                            </li>
                                            <!-- <li ><span *ngIf="fare.PassengerType == 2">Child </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li>
                                            <li ><span *ngIf="fare.PassengerType == 3">Infant </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li> -->
                                            <!-- <li *ngIf="fare.PassengerCount == 2">Child ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li>
                                            <li *ngIf="fare.PassengerCount == 2">Infant ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li> -->

                                            <li>{{fare.ServiceFee | number:'1.0-2'}} {{fare.Currency}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- Repeat the same structure for other accordion items -->
                        </div>
                        <ul class="total">
                            <li>
                                <h1 class="summary">Total Amount</h1>
                            </li>
                            <li>
                                <h1 class="summary">{{finalPrice | number:'1.0-2'}} {{currencyTupe}}</h1>
                            </li>
                        </ul>
                    </div>
                    <div class="fareDetails">
                        <h1 class="summarys">Devotion Offers</h1>

                        <form action="">
                            <input type="text" class="ApplyCo" placeholder="Apply Coupon">
                            <button class="AppyBtns">Apply</button>
                        </form>
                        <div class="coupanset">
                            <mat-radio-button value="1">
                                <p class="coupanName">GIRUSH</p>
                            </mat-radio-button>
                            <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                            </p>
                        </div>
                        <div class="coupanset">
                            <mat-radio-button value="1">
                                <p class="coupanName">GIRUSH</p>
                            </mat-radio-button>
                            <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                            </p>
                        </div>
                        <div class="coupanset">
                            <mat-radio-button value="1">
                                <p class="coupanName">GIRUSH</p>
                            </mat-radio-button>
                            <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<div  *ngIf="isCOnfirm">
    <div class="flight-review-component">

        <div class="container">
            <div class="main-COntent">
                <!-- <h1 class="complite-text">Complete Your Booking</h1> -->
                <div class="row">
                    <div class="col-md-9">
    
    
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Flights Summary <br>
                                        Hyderabad
                                    </mat-panel-title>
                        
                                </mat-expansion-panel-header>
    
                                <div *ngIf="!isPageLoaded">
                                    <div *ngFor="let item of PageInfo.Segments ; let i = index " class="Flight-infrmation">
                                        <div *ngFor="let items of item ">
                                            <ul class="FLighRoute">
                                                <li>
                                                    <h1 class="areaText"><span><img class="IconFLight"
                                                                src="./../../../../assets/images/FlightIcon.svg"
                                                                alt=""></span>
    
                                                        {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                                                </li>
                                                <li>
                                                    <!-- <p class="FareRule">View Fare Rules</p> -->
                                                </li>
                                            </ul>
                                            <div class="tickets">
                                                <ul class="fromsTicket">
                                                    <li class="LiInfo">
                                                        <img class="AriICons" src="./../../../../assets/images/airLine.svg"
                                                            alt=""><br>
                                                        <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                                                        <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                                                            {{items.AirlineDetails.FlightNumber}}</p>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}
                                                        </p>
                                                        <h1 class="StartTime">{{items.Origin.AirportCode}}
                                                            {{items.DepartureTime |date:
                                                            'HH:mm' : 'en-US'}}</h1>
                                                        <P class="Airports">{{items.Origin.AirportName}},
                                                            {{items.Origin.CityName}},
                                                            {{items.Origin.CountryName}}</P>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <div class="directIons">
                                                            <div class="rounds"></div>
                                                            <p style="margin: 0; " class="DurayionData">
                                                                ---{{items.Duration}}---</p>
                                                            <img class="img-go" style="height: 20px;"
                                                                src="./../../../../assets/images/FlightIcon.svg" alt="">
                                                        </div>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                                                        <h1 class="StartTime">{{items.Destination.AirportCode}}
                                                            {{items.ArrivalTime
                                                            |date: 'HH:mm' : 'en-US'}}</h1>
                                                        <P class="Airports">{{items.Destination.AirportName}},
                                                            {{items.Destination.CityName}},
                                                            {{items.Destination.CountryName}}</P>
                                                    </li>
                                                </ul>
                                                <div class="lins">
                                                    <p class="TotalKg"> <span><i
                                                                class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}}
                                                        (1
                                                        piece
                                                        only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                                                        {{items.CabinClass}}
                                                    </p>
    
                                                </div>
    
                                            </div>
    
                                        </div>
                                        <div class="ContinueNext">
                                            
                                        </div>
                                    </div>
                                </div>
    
                            </mat-expansion-panel>
    
                        </mat-accordion>
    
    
    
    
    
    
    
    
    
    
                        <div *ngFor="let item of this.form.value.fields" class="InportantInfo">
                            <ul class="ImportantHeade">
                                <li>
                                    <h1 class="ImportantHead">Passinger Details</h1>
                                </li>
                                <li><i style="cursor: pointer;" (click)="isIsEdit()"  class="fas fa-edit"></i></li>
                            </ul>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">First Name</p>
                                    <h1 class="Username">{{item?.FirstName }}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Last Name</p>
                                    <h1 class="Username">{{item?.LastName}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Gender</p>
                                    <h1 *ngIf="item?.Gender ==1" class="Username">Male</h1>
                                    <h1 *ngIf="item?.Gender ==2" class="Username">Female</h1>
                                </li>
                            </ul>
    
                            <h1 class="ImportantHead">Contact Details</h1>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">Country Code</p>
                                    <h1 class="Username">{{item?.Mobile1CountryCode}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Contact No.</p>
                                    <h1 class="Username">{{item?.Mobile1}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Email Address</p>
                                    <h1 class="Username">{{item?.Email}}</h1>
                                </li>
                            </ul>
    
    
    
                            <h1 class="ImportantHead">Passport Details</h1>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">Passport No.</p>
                                    <h1 class="Username">{{item?.PassportNo}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Expire Date</p>
                                    <h1 class="Username">{{item?.PassportExpiry}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Issued Country</p>
                                    <h1 class="Username">{{item?.Nationality?.CountryName}}</h1>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="false" class="travelDetails">
                            <h1 class="travelr-Head">Passinger Details</h1>
                            <p>Add Details</p>
                            <form *ngIf="false" [formGroup]="dynamicForm" class="g-3 needs-validation">
                                <div formArrayName="formFields">
                                    <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select formControlName="Type" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value=1>Adult</option>
                                                    <option value=2>Child</option>
                                                    <option value=3>Infant</option>
                                                    <option value=4>Senior</option>
                                                    <option value=3>Youth</option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <select formControlName="Title" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value="Mr">Mr</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="FirstName" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="First Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="LastName" type="email"
                                                        class="form-control my-filed" placeholder="Last Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                     
                                                <select formControlName="Gender" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value=1>Male</option>
                                                    <option value=2>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <input formControlName="Email" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="Email" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <div class="mb-3">
                                                        <input formControlName="Mobile1" type="email"
                                                            id="validationCustom01" class="form-control my-filed"
                                                            placeholder="Mobile Number" required>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
    
                                                    <select formControlName="Mobile1CountryCode"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected value="1">Mobile Country Code</option>
                                                        <option value="91">IND</option>
                                                        <option value="92">PAK</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="date" formControlName="DateOfBirth"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
    
    
                                        </div>
                                        <div class="row">
                                            <h1 class="travelr-Head">Document Details</h1>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IdType" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>ID Type</option>
                                                        <option value="2">Passport</option>
                                                        <option value="3">Nation ID</option>
                                                        <option value="3">Iqama</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IssuedCountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>Issued Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected> Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryName" placeholder="f"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected>Country Name</option>
                                                        <option value="India">india</option>
                                                        <option value="dubai">dubai</option>
                                                        <option value="pakistan">pakistab</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="PassportNo" type=" " id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="IdNumber" type="number" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Passport
                                                        Expiry</label>
                                                    <input type="date" formControlName="PassportExpiry"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">ExpiryDate</label>
                                                    <input type="date" formControlName="ExpiryDate"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">IssueDate</label>
                                                    <input type="date" formControlName="IssueDate"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-3 form-check">
                                                <input (click)="ihaveGst($event.target.checked)" type="checkbox"
                                                    class="form-check-input " id="exampleCheck1">
                                                <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                                                    GST
                                                    number (Optional)</label>
                                            </div>
    
    
    
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Company Name</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Select State</label>
                                                    <select class="form-select my-filed"
                                                        aria-label="Default select example">
    
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form *ngIf="true" [formGroup]="dynamicForm" class="g-3 needs-validation">
                                <div formArrayName="formFields">
                                    <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select formControlName="Type" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option [value]="null" disabled selected>Select type</option>
                                                    <option selected value=1>Adult</option>
                                                    <option value=2>Child</option>
                                                    <option value=3>Infant</option>
                                                    <option value=4>Senior</option>
                                                    <option value=3>Youth</option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <select formControlName="Title" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option [value]="null" disabled selected>Select Title</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="FirstName" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="First Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="LastName" type="email"
                                                        class="form-control my-filed" placeholder="Last Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                       
                                                    <mat-button-toggle-group formControlName="Gender" name="fontStyle"
                                                        aria-label="Font Style">
                                                        <mat-button-toggle value=1>Male</mat-button-toggle>
                                                        <mat-button-toggle value=2>Female</mat-button-toggle>
                                                    </mat-button-toggle-group>
    
                                                </div>
    
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <input formControlName="Email" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="Email" required>
                                                </div>
                                            </div>
    
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <div class="mb-3">
                                                      
                                                        <ngx-intl-tel-input formControlName="Mobile1"
                                                            [preferredCountries]="['us', 'gb']"></ngx-intl-tel-input>
                                                    </div>
    
                                                </div>
                                            </div>
    
                                            <div class="col-md-3">
    
                                                <input type="text" placeholder="Date Of Birth" formControlName="DateOfBirth Ex: 10/20/2023"
                                                    class="form-control my-filed" bsDatepicker>
                                            </div>
    
    
    
                                        </div>
                                        <div class="row">
                                            <h1 class="travelr-Head">Document Details</h1>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IdType" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>ID Type</option>
                                                        <option value="2">Passport</option>
                                                        <option value="3">Nation ID</option>
                                                        <option value="3">Iqama</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IssuedCountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>Issued Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected> Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryName" placeholder="f"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected>Country Name</option>
                                                        <option value="India">india</option>
                                                        <option value="dubai">dubai</option>
                                                        <option value="pakistan">pakistab</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="PassportNo" type=" " id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="IdNumber" type="number" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <input type="text" placeholder="Passport Expiry Ex: 10/20/2023"
                                                        formControlName="PassportExpiry" class="form-control my-filed"
                                                        bsDatepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input type="text" placeholder="ExpiryDate" formControlName="ExpiryDate Ex: 10/20/2023"
                                                        class="form-control my-filed" bsDatepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <input type="text" placeholder="IssueDate" formControlName="IssueDate"
                                                        class="form-control my-filed" bsDatepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-3 form-check">
                                                <input (click)="ihaveGst($event.target.checked)" type="checkbox"
                                                    class="form-check-input " id="exampleCheck1">
                                                <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                                                    GST
                                                    number (Optional)</label>
                                            </div>
    
    
    
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Company Name</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Select State</label>
                                                    <select class="form-select my-filed"
                                                        aria-label="Default select example">
    
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger" (click)="addFormField()">Add Form</button>
                                <button class="btn btn-success" (click)="CheckValue()">Save Details</button>
                            </form>
    
    
                        </div>
                        <button (click)="isIsConfirmed()" class="ContinueBtns">Procced to Pay</button>
                    </div>
                    <div class="col-md-3">
                        <div class="fareDetails">
                            <h1 class="summary">Fare Summary</h1>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="false"
                                            aria-controls="flush-collapseOne">
                                            <!-- <span class="accordion-icon"></span> -->
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Base Fare</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <div class="fareList">
                                                <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                    <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                        <span *ngIf="fare.PassengerType == 2">Child </span>
                                                        <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                        ({{fare.PassengerCount}})
                                                    </li>
                                                 
                                                    <li>{{fare.TotalFare | number:'1.0-2'}} {{fare.Currency }}</li>
                                                </ul>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
    
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                            aria-controls="flush-collapseTwo">
                                            <!-- <span class="accordion-icon"></span> -->
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Taxes and Surcharges</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                    <span *ngIf="fare.PassengerType == 2">Child </span>
                                                    <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                    ({{fare.PassengerCount}})
                                                </li>
                                      
                                                <li>{{fare.Tax | number:'1.0-2'}} {{fare.Currency }}</li>
                                            </ul>
    
                                        </div>
                                    </div>
                                </div>
    
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseThree" aria-expanded="false"
                                            aria-controls="flush-collapseThree">
                                            <!-- <span class="accordion-icon"></span> -->
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Other Charges</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                    <span *ngIf="fare.PassengerType == 2">Child </span>
                                                    <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                    ({{fare.PassengerCount}})
                                                </li>
                                          
                                                <li>{{fare.ServiceFee | number:'1.0-2'}} {{fare.Currency}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="total">
                                <li>
                                    <h1 class="summary">Total Amount</h1>
                                </li>
                                <li>
                                    <h1 class="summary">{{finalPrice | number:'1.0-2'}} {{currencyTupe}}</h1>
                                </li>
                            </ul>
                        </div>
    
                        <div class="fareDetails">
                            <h1 class="summarys">Devotion Offers</h1>
    
                            <form action="">
                                <input type="text" class="ApplyCo" placeholder="Apply Coupon">
                                <button class="AppyBtns">Apply</button>
                            </form>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    
    
    <div>
    
    </div>
</div>




<!-- <app-verification *ngIf="isCOnfirm"[fareRuleData]="fareRule"  (isVerified)="handleVerification($event)">


    <div class="flight-review-component">

        <div class="container">
            <div class="main-COntent">
                <h1 class="complite-text">Complete Your Booking</h1>
                <div class="row">
                    <div class="col-md-9">
    
    
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Flights Summary <br>
                                        Hyderabad
                                    </mat-panel-title>
                        
                                </mat-expansion-panel-header>
    
                                <div *ngIf="!isPageLoaded">
                                    <div *ngFor="let item of PageInfo.Segments ; let i = index " class="Flight-infrmation">
                                        <div *ngFor="let items of item ">
                                            <ul class="FLighRoute">
                                                <li>
                                                    <h1 class="areaText"><span><img class="IconFLight"
                                                                src="./../../../../assets/images/FlightIcon.svg"
                                                                alt=""></span>
    
                                                        {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                                                </li>
                                                <li>
                                                    <p class="FareRule">View Fare Rules</p>
                                                </li>
                                            </ul>
                                            <div class="tickets">
                                                <ul class="fromsTicket">
                                                    <li class="LiInfo">
                                                        <img class="AriICons" src="./../../../../assets/images/airLine.svg"
                                                            alt=""><br>
                                                        <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                                                        <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                                                            {{items.AirlineDetails.FlightNumber}}</p>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}
                                                        </p>
                                                        <h1 class="StartTime">{{items.Origin.AirportCode}}
                                                            {{items.DepartureTime |date:
                                                            'HH:mm' : 'en-US'}}</h1>
                                                        <P class="Airports">{{items.Origin.AirportName}},
                                                            {{items.Origin.CityName}},
                                                            {{items.Origin.CountryName}}</P>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <div class="directIons">
                                                            <div class="rounds"></div>
                                                            <p style="margin: 0; " class="DurayionData">
                                                                ---{{items.Duration}}---</p>
                                                            <img class="img-go" style="height: 20px;"
                                                                src="./../../../../assets/images/FlightIcon.svg" alt="">
                                                        </div>
                                                    </li>
                                                    <li class="LiInfo">
                                                        <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                                                        <h1 class="StartTime">{{items.Destination.AirportCode}}
                                                            {{items.ArrivalTime
                                                            |date: 'HH:mm' : 'en-US'}}</h1>
                                                        <P class="Airports">{{items.Destination.AirportName}},
                                                            {{items.Destination.CityName}},
                                                            {{items.Destination.CountryName}}</P>
                                                    </li>
                                                </ul>
                                                <div class="lins">
                                                    <p class="TotalKg"> <span><i
                                                                class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}}
                                                        (1
                                                        piece
                                                        only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                                                        {{items.CabinClass}}
                                                    </p>
    
                                                </div>
    
                                            </div>
    
                                        </div>
                                        <div class="ContinueNext">
                                            
                                        </div>
                                    </div>
                                </div>
    
                            </mat-expansion-panel>
    
                        </mat-accordion>
    
    
    
    
    
    
    
    
    
    
                        <div *ngFor="let item of this.form.value.fields" class="InportantInfo">
                            <ul class="ImportantHeade">
                                <li>
                                    <h1 class="ImportantHead">Traveller Details</h1>
                                </li>
                                <li><i style="cursor: pointer;" class="fas fa-edit"></i></li>
                            </ul>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">First Name</p>
                                    <h1 class="Username">{{item?.FirstName }}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Last Name</p>
                                    <h1 class="Username">{{item?.LastName}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Gender</p>
                                    <h1 *ngIf="item?.Gender ==1" class="Username">Male</h1>
                                    <h1 *ngIf="item?.Gender ==2" class="Username">Female</h1>
                                </li>
                            </ul>
    
                            <h1 class="ImportantHead">Contact Details</h1>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">Country Code</p>
                                    <h1 class="Username">{{item?.Mobile1CountryCode}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Contact No.</p>
                                    <h1 class="Username">{{item?.Mobile1}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Email Address</p>
                                    <h1 class="Username">{{item?.Email}}</h1>
                                </li>
                            </ul>
    
    
    
                            <h1 class="ImportantHead">Passport Details</h1>
                            <ul class="list-data-prev">
                                <li class="list-names">
                                    <p class="firstName">Passport No.</p>
                                    <h1 class="Username">{{item?.PassportNo}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Expire Date</p>
                                    <h1 class="Username">{{item?.PassportExpiry}}</h1>
                                </li>
                                <li class="list-names">
                                    <p class="firstName">Issued Country</p>
                                    <h1 class="Username">{{item?.Nationality?.CountryName}}</h1>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="false" class="travelDetails">
                            <h1 class="travelr-Head">Traveller Details</h1>
                            <p>Add Details</p>
                            <form *ngIf="false" [formGroup]="dynamicForm" class="g-3 needs-validation">
                                <div formArrayName="formFields">
                                    <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select formControlName="Type" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value=1>Adult</option>
                                                    <option value=2>Child</option>
                                                    <option value=3>Infant</option>
                                                    <option value=4>Senior</option>
                                                    <option value=3>Youth</option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <select formControlName="Title" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value="Mr">Mr</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="FirstName" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="First Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="LastName" type="email"
                                                        class="form-control my-filed" placeholder="Last Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                     
                                                <select formControlName="Gender" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option selected value=1>Male</option>
                                                    <option value=2>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <input formControlName="Email" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="Email" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <div class="mb-3">
                                                        <input formControlName="Mobile1" type="email"
                                                            id="validationCustom01" class="form-control my-filed"
                                                            placeholder="Mobile Number" required>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
    
                                                    <select formControlName="Mobile1CountryCode"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected value="1">Mobile Country Code</option>
                                                        <option value="91">IND</option>
                                                        <option value="92">PAK</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="date" formControlName="DateOfBirth"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
    
    
                                        </div>
                                        <div class="row">
                                            <h1 class="travelr-Head">Document Details</h1>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IdType" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>ID Type</option>
                                                        <option value="2">Passport</option>
                                                        <option value="3">Nation ID</option>
                                                        <option value="3">Iqama</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IssuedCountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>Issued Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected> Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryName" placeholder="f"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected>Country Name</option>
                                                        <option value="India">india</option>
                                                        <option value="dubai">dubai</option>
                                                        <option value="pakistan">pakistab</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="PassportNo" type=" " id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="IdNumber" type="number" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Passport
                                                        Expiry</label>
                                                    <input type="date" formControlName="PassportExpiry"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">ExpiryDate</label>
                                                    <input type="date" formControlName="ExpiryDate"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">IssueDate</label>
                                                    <input type="date" formControlName="IssueDate"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-3 form-check">
                                                <input (click)="ihaveGst($event.target.checked)" type="checkbox"
                                                    class="form-check-input " id="exampleCheck1">
                                                <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                                                    GST
                                                    number (Optional)</label>
                                            </div>
    
    
    
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Company Name</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Select State</label>
                                                    <select class="form-select my-filed"
                                                        aria-label="Default select example">
    
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <form *ngIf="true" [formGroup]="dynamicForm" class="g-3 needs-validation">
                                <div formArrayName="formFields">
                                    <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <select formControlName="Type" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option [value]="null" disabled selected>Select type</option>
                                                    <option selected value=1>Adult</option>
                                                    <option value=2>Child</option>
                                                    <option value=3>Infant</option>
                                                    <option value=4>Senior</option>
                                                    <option value=3>Youth</option>
                                                </select>
                                            </div>
                                            <div class="col-md-2">
                                                <select formControlName="Title" class="form-select my-filed"
                                                    aria-label="Default select example">
                                                    <option [value]="null" disabled selected>Select Title</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Mrs">Mrs</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="FirstName" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="First Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="LastName" type="email"
                                                        class="form-control my-filed" placeholder="Last Name" required>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                       
                                                    <mat-button-toggle-group formControlName="Gender" name="fontStyle"
                                                        aria-label="Font Style">
                                                        <mat-button-toggle value=1>Male</mat-button-toggle>
                                                        <mat-button-toggle value=2>Female</mat-button-toggle>
                                                    </mat-button-toggle-group>
    
                                                </div>
    
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <input formControlName="Email" type="email" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="Email" required>
                                                </div>
                                            </div>
    
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <div class="mb-3">
                                                      
                                                        <ngx-intl-tel-input formControlName="Mobile1"
                                                            [preferredCountries]="['us', 'gb']"></ngx-intl-tel-input>
                                                    </div>
    
                                                </div>
                                            </div>
    
                                            <div class="col-md-3">
    
                                                <input type="text" placeholder="Date Of Birth" formControlName="DateOfBirth"
                                                    class="form-control my-filed" bsDatepicker>
                                            </div>
    
    
    
                                        </div>
                                        <div class="row">
                                            <h1 class="travelr-Head">Document Details</h1>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IdType" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>ID Type</option>
                                                        <option value="2">Passport</option>
                                                        <option value="3">Nation ID</option>
                                                        <option value="3">Iqama</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="IssuedCountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected>Issued Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryCode" class="form-select my-filed"
                                                        aria-label="Default select example">
                                                        <option selected> Country Code</option>
                                                        <option value="IN">INDIA</option>
                                                        <option value="PAK">PAKISTAN</option>
                                                        <option value="DXB">DUBAI</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <select formControlName="CountryName" placeholder="f"
                                                        class="form-select my-filed" aria-label="Default select example">
                                                        <option selected>Country Name</option>
                                                        <option value="India">india</option>
                                                        <option value="dubai">dubai</option>
                                                        <option value="pakistan">pakistab</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="PassportNo" type=" " id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="IdNumber" type="number" id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <input type="text" placeholder="Passport Expiry"
                                                        formControlName="PassportExpiry" class="form-control my-filed"
                                                        bsDatepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input type="text" placeholder="ExpiryDate" formControlName="ExpiryDate"
                                                        class="form-control my-filed" bsDatepicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <input type="text" placeholder="IssueDate" formControlName="IssueDate"
                                                        class="form-control my-filed" bsDatepicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="mb-3 form-check">
                                                <input (click)="ihaveGst($event.target.checked)" type="checkbox"
                                                    class="form-check-input " id="exampleCheck1">
                                                <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                                                    GST
                                                    number (Optional)</label>
                                            </div>
    
    
    
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Company Name</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Select State</label>
                                                    <select class="form-select my-filed"
                                                        aria-label="Default select example">
    
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div *ngIf="gstNo" class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">Address</label>
                                                    <input type="email" class="form-control my-filed"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp">
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger" (click)="addFormField()">Add Form</button>
                                <button class="btn btn-success" (click)="CheckValue()">Save Details</button>
                            </form>
    
    
                        </div>
                        <button (click)="isIsConfirmed()" class="ContinueBtns">Procced to Pay</button>
                    </div>
                    <div class="col-md-3">
                        <div class="fareDetails">
                            <h1 class="summary">Fare Summary</h1>
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseOne" aria-expanded="false"
                                            aria-controls="flush-collapseOne">
                                            <span class="accordion-icon"></span>
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Base Fare</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <div class="fareList">
                                                <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                    <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                        <span *ngIf="fare.PassengerType == 2">Child </span>
                                                        <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                        ({{fare.PassengerCount}})
                                                    </li>
                                                 
                                                    <li>{{fare.TotalFare | number:'1.0-2'}} {{fare.Currency }}</li>
                                                </ul>
                                            </div>
    
                                        </div>
                                    </div>
                                </div>
    
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                            aria-controls="flush-collapseTwo">
                                            <span class="accordion-icon"></span>
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Taxes and Surcharges</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                    <span *ngIf="fare.PassengerType == 2">Child </span>
                                                    <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                    ({{fare.PassengerCount}})
                                                </li>
                                      
                                                <li>{{fare.Tax | number:'1.0-2'}} {{fare.Currency }}</li>
                                            </ul>
    
                                        </div>
                                    </div>
                                </div>
    
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#flush-collapseThree" aria-expanded="false"
                                            aria-controls="flush-collapseThree">
                                            <span class="accordion-icon"></span>
                                            <ul class="BaseINfo">
                                                <li>
                                                    <p class="basing">Other Charges</p>
                                                </li>
                                                <li>
                                                    <p class="basing"></p>
                                                </li>
                                            </ul>
                                        </button>
    
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
    
                                            <ul *ngFor="let fare of fareBreak" class="farebreak">
                                                <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                                                    <span *ngIf="fare.PassengerType == 2">Child </span>
                                                    <span *ngIf="fare.PassengerType == 3">Infant </span>
    
                                                    ({{fare.PassengerCount}})
                                                </li>
                                          
                                                <li>{{fare.ServiceFee | number:'1.0-2'}} {{fare.Currency}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="total">
                                <li>
                                    <h1 class="summary">Total Amount</h1>
                                </li>
                                <li>
                                    <h1 class="summary">{{finalPrice | number:'1.0-2'}} {{currencyTupe}}</h1>
                                </li>
                            </ul>
                        </div>
    
                        <div class="fareDetails">
                            <h1 class="summarys">Devotion Offers</h1>
    
                            <form action="">
                                <input type="text" class="ApplyCo" placeholder="Apply Coupon">
                                <button class="AppyBtns">Apply</button>
                            </form>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                            <div class="coupanset">
                                <mat-radio-button value="1">
                                    <p class="coupanName">GIRUSH</p>
                                </mat-radio-button>
                                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    
    
    <div>
    
    </div>











</app-verification> -->

<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
  </button>
  

<!-- Button trigger modal -->

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
    
<form [formGroup]="editDatas">
<div class="row">

    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">First Name</label>
            <input [(ngModel)]="editData.FirstName" formControlName="FirstName" type="email" class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Last Name</label>
            <input [(ngModel)]="editData.LastName" formControlName="LastName" type="email" class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Gender</label>
            <select [(ngModel)]="editData.Gender" formControlName="Gender" class="form-select  my-filed" data-live-search="true" aria-label="Default select example">
                <option value="1">Male</option>
                <option value="2">Female</option>
          
              </select>
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email address</label>
            <input  [(ngModel)]="editData.Email" type="email" class="form-control my-filed" formControlName="Email" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Date of Birth</label>
            <input type="text" [(ngModel)]="editData.DateOfBirth" placeholder="Date Of Birth" formControlName="DateOfBirth"
            class="form-control my-filed" bsDatepicker>
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Mobile Country Code</label>
            <input type="email" [(ngModel)]="editData.Mobile1CountryCode" formControlName="Mobile1CountryCode" class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Mobile Number</label>
            <input type="email" [(ngModel)]="editData.Mobile1"  formControlName="Mobile1"  class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
    
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Documents</label>
            <select formControlName="IdType" [(ngModel)]="passportDetails.IdType"  class="form-select  my-filed" aria-label="Default select example">
                <option value="1">Passport</option>
                <option value="2">National ID</option>
                <option value="3">Iqama</option>
          
              </select>
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Passport Number</label>
            <input type="email" [(ngModel)]="passportDetails.PassportNo"  class="form-control my-filed" [(ngModel)]="editData.PassportNo" formControlName="PassportNo" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Issue Date</label>
            <input type="email" [(ngModel)]="passportDetails.IssueDate"   class="form-control my-filed" [(ngModel)]="passportDetails.IssueDate"  formControlName="IssueDate" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1"class="form-label">Issue Country</label>
            <input type="email" [(ngModel)]="passportDetails.IssuedCountryCode"    formControlName="IssuedCountryCode"  class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1"class="form-label">Expiry Date</label>
            <input type="email" [(ngModel)]="passportDetails.ExpiryDate"    formControlName="ExpiryDate"  class="form-control my-filed" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
    </div>
    <div class="col-md-3">
        <div class="mb-3">
            <label for="exampleInputEmail1"class="form-label">Select Nationality</label>

    <select  [(ngModel)]="passportDetails.Nationality1"  formControlName="Nationality1"
    class="form-select my-filed"
    aria-label="Default select example">
    <option value="AF">Afghan</option>
    <option value="AL">Albanian</option>
    <option value="DZ">Algerian</option>
    <option value="AS">American Samoan</option>
    <option value="US">American; US citizen</option>
    <option value="AD">Andorran</option>
    <option value="AO">Angolan</option>
    <option value="AI">Anguillan</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antiguan; Barbudian</option>
    <option value="AN">Antillean</option>
    <option value="AR">Argentinian</option>
    <option value="AM">Armenian</option>
    <option value="AW">Aruban</option>
    <option value="AU">Australian</option>
    <option value="AT">Austrian</option>
    <option value="AZ">Azerbaijani</option>
    <option value="BS">Bahamian</option>
    <option value="BH">Bahraini</option>
    <option value="BD">Bangladeshi</option>
    <option value="BB">Barbadian</option>
    <option value="LS">Basotho</option>
    <option value="BY">Belarusian</option>
    <option value="BE">Belgian</option>
    <option value="BZ">Belizean</option>
    <option value="BJ">Beninese</option>
    <option value="BM">Bermudian</option>
    <option value="BT">Bhutanese</option>
    <option value="BO">Bolivian</option>
    <option value="BW">Botswanan</option>
    <option value="BV">Bouvet Islands</option>
    <option value="BR">Brazilian</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="VG">British Virgin Islander; BV Islander
    </option>
    <option value="BN">Bruneian</option>
    <option value="BG">Bulgarian</option>
    <option value="BF">Burkinabe</option>
    <option value="MM">Burmese</option>
    <option value="BI">Burundian</option>
    <option value="KH">Cambodian</option>
    <option value="CM">Cameroonian</option>
    <option value="CB">Canada Buffer</option>
    <option value="CA">Canadian</option>
    <option value="CV">Cape Verdean</option>
    <option value="KY">Caymanian</option>
    <option value="CF">Central African</option>
    <option value="TD">Chadian</option>
    <option value="CL">Chilean</option>
    <option value="CN">Chinese</option>
    <option value="CX">Christmas Islander</option>
    <option value="CC">Cocos Islander;Cocos (Keeling) Islands
    </option>
    <option value="CO">Colombian</option>
    <option value="KM">Comorian</option>
    <option value="CD">congo</option>
    <option value="CG">Congolese</option>
    <option value="CK">Cook Islander</option>
    <option value="CR">Costa Rican</option>
    <option value="HR">Croat</option>
    <option value="CU">Cuban</option>
    <option value="CY">Cypriot</option>
    <option value="CZ">Czech</option>
    <option value="DK">Dane</option>
    <option value="DJ">Djiboutian</option>
    <option value="DO">Dominican</option>
    <option value="DM">Dominicana ; Dominica</option>
    <option value="NL">Dutchman; Dutchwoman; Netherlander
    </option>
    <option value="TP">East Timor</option>
    <option value="EC">Ecuadorian</option>
    <option value="EG">Egyptian</option>
    <option selected="selected" value="AE">Emirian</option>
    <option value="GQ">Equatorial Guinean</option>
    <option value="ER">Eritrean</option>
    <option value="EE">Estonian</option>
    <option value="ET">Ethiopian</option>
    <option value="EU">European Monetary Union</option>
    <option value="FO">Faeroese</option>
    <option value="FK">Falkland Islander</option>
    <option value="FJ">Fiji Islander</option>
    <option value="PH">Filipino</option>
    <option value="FI">Finn</option>
    <option value="TF">French Southern Territories</option>
    <option value="FR">Frenchman; Frenchwoman</option>
    <option value="GA">Gabonese</option>
    <option value="GM">Gambian</option>
    <option value="GE">Georgian</option>
    <option value="DE">German</option>
    <option value="GH">Ghanaian</option>
    <option value="GI">Gibraltarian</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenlander</option>
    <option value="GD">Grenadian</option>
    <option value="GP">Guadeloupean</option>
    <option value="GU">Guamanian</option>
    <option value="GT">Guatemalan</option>
    <option value="GF">Guianese</option>
    <option value="GW">Guinea-Bissau national</option>
    <option value="GN">Guinean</option>
    <option value="GY">Guyanese</option>
    <option value="HT">Haitian</option>
    <option value="HM">Heard &amp; Mcdonald Islands</option>
    <option value="HN">Honduran</option>
    <option value="HK">Hong Kong Chinese</option>
    <option value="HU">Hungarian</option>
    <option value="IS">Icelander</option>
    <option value="IN">Indian</option>
    <option value="ID">Indonesian</option>
    <option value="IR">Iranian</option>
    <option value="IQ">Iraqi</option>
    <option value="IE">Irishman; Irishwoman</option>
    <option value="IL">Israeli</option>
    <option value="IT">Italian</option>
    <option value="CI">Ivorian</option>
    <option value="JM">Jamaican</option>
    <option value="JP">Japanese</option>
    <option value="JO">Jordanian</option>
    <option value="KZ">Kazakh</option>
    <option value="KE">Kenyan</option>
    <option value="KI">Kiribatian</option>
    <option value="KN">Kittsian; Nevisian</option>
    <option value="XK">Kosovo</option>
    <option value="KW">Kuwaiti</option>
    <option value="KG">Kyrgyz</option>
    <option value="LA">Lao</option>
    <option value="LV">Latvian</option>
    <option value="LB">Lebanese</option>
    <option value="LR">Liberian</option>
    <option value="LY">Libyan</option>
    <option value="LI">Liechtensteiner</option>
    <option value="QL">Lithuania (Dummy Code)</option>
    <option value="LT">Lithuanian</option>
    <option value="LU">Luxembourger</option>
    <option value="MO">Macanese</option>
    <option value="MK">Macedonia</option>
    <option value="YT">Mahorais</option>
    <option value="MG">Malagasy</option>
    <option value="MW">Malawian</option>
    <option value="MY">Malaysian</option>
    <option value="MV">Maldivian</option>
    <option value="ML">Malian</option>
    <option value="MT">Maltese</option>
    <option value="MH">Marshallese</option>
    <option value="MQ">Martinican</option>
    <option value="MR">Mauritanian</option>
    <option value="MU">Mauritian</option>
    <option value="MX">Mexican</option>
    <option value="MB">Mexico Buffer</option>
    <option value="FM">Micronesian</option>
    <option value="MD">Moldovan</option>
    <option value="MC">Monegasque</option>
    <option value="MN">Mongolian</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserratian</option>
    <option value="MA">Moroccan</option>
    <option value="MZ">Mozambican</option>
    <option value="NA">Namibian</option>
    <option value="NR">Nauruan</option>
    <option value="NP">Nepalese</option>
    <option value="NC">New Caledonian</option>
    <option value="NZ">New Zealander</option>
    <option value="NI">Nicaraguan</option>
    <option value="NG">Nigerian</option>
    <option value="NE">Nigerien</option>
    <option value="NU">Niuean</option>
    <option value="NF">Norfolk Islander</option>
    <option value="KP">North Korean</option>
    <option value="MP">Northern Mariana Islander</option>
    <option value="NO">Norwegian</option>
    <option value="BA">of Bosnia and Herzegovina</option>
    <option value="VA">of the Holy See (of the Vatican)
    </option>
    <option value="OM">Omani</option>
    <option value="PK">Pakistani</option>
    <option value="PW">Palauan</option>
    <option value="PS">Palestinian Occ. Territories</option>
    <option value="PA">Panamanian</option>
    <option value="PG">Papua New Guinean</option>
    <option value="PY">Paraguayan</option>
    <option value="PE">Peruvian</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Pole</option>
    <option value="PF">Polynesian</option>
    <option value="PT">Portuguese</option>
    <option value="PR">Puerto Rican</option>
    <option value="QA">Qatari</option>
    <option value="RE">Reunionese</option>
    <option value="RO">Romanian</option>
    <option value="RU">Russian</option>
    <option value="RW">Rwandan; Rwandese;Rwanda</option>
    <option value="EH">Sahrawi</option>
    <option value="SH">Saint Helenian</option>
    <option value="LC">Saint Lucian</option>
    <option value="SV">Salvadorian; Salvadoran</option>
    <option value="WS">Samoan</option>
    <option value="SM">San Marinese</option>
    <option value="ST">São Toméan</option>
    <option value="SA">Saudi Arabian</option>
    <option value="SN">Senegalese</option>
    <option value="RS">Serbian</option>
    <option value="SC">Seychellois</option>
    <option value="SL">Sierra Leonean</option>
    <option value="SG">Singaporean</option>
    <option value="SK">Slovak</option>
    <option value="SI">Slovene</option>
    <option value="SB">Solomon Islander</option>
    <option value="SO">Somali</option>
    <option value="ZA">South African</option>
    <option value="GS">South Georgia &amp; South Sandwich
    </option>
    <option value="KR">South Korean</option>
    <option value="SU">Soviet Union</option>
    <option value="ES">Spaniard</option>
    <option value="LK">Sri Lankan</option>
    <option value="PM">St-Pierrais; Miquelonnais;St. Pierre And
        Miquelon</option>
    <option value="SD">Sudanese</option>
    <option value="SR">Surinamer</option>
    <option value="SJ">Svalbard &amp; Jan Mayen Islands;Svalbard
        And
        Jan Mayen Islands</option>
    <option value="SZ">Swazi</option>
    <option value="SE">Swede</option>
    <option value="CH">Swiss</option>
    <option value="SY">Syrian</option>
    <option value="TW">Taiwanese</option>
    <option value="TJ">Tajik</option>
    <option value="TZ">Tanzanian</option>
    <option value="TH">Thai</option>
    <option value="TG">Togolese</option>
    <option value="TK">Tokelauan</option>
    <option value="TO">Tongan</option>
    <option value="TT">Trinidadian; Tobagonian</option>
    <option value="TN">Tunisian</option>
    <option value="TR">Turk</option>
    <option value="TM">Turkmen</option>
    <option value="TC">Turks and Caicos Islander</option>
    <option value="TV">Tuvaluan</option>
    <option value="UM">U.S. Minor Outlaying Islands</option>
    <option value="UG">Ugandan</option>
    <option value="UA">Ukrainian</option>
    <option value="GB">United Kingdom</option>
    <option value="UY">Uruguayan</option>
    <option value="VI">US Virgin Islander</option>
    <option value="UZ">Uzbek</option>
    <option value="VU">Vanuatuan</option>
    <option value="VE">Venezuelan</option>
    <option value="VN">Vietnamese</option>
    <option value="VC">Vincentian;St Vincent And The Grenadines
    </option>
    <option value="WF">Wallisian; Futunan; Wallis and Futuna
        Islander</option>
    <option value="YE">Yemeni</option>
    <option value="YU">Yugoslavia</option>
    <option value="ZM">Zambian</option>
    <option value="ZW">Zimbabwean</option>
</select>
</div>
</div>
</div>
 
</form>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button (click)="consl()" type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>