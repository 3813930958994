import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cruises',
  templateUrl: './cruises.component.html',
  styleUrls: ['./cruises.component.css']
})
export class CruisesComponent implements OnInit{
  returnData: boolean = true
  oneWay: boolean = false
  MultiWay: boolean = false
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadScripts()
  }

  isReturn() {
    this.returnData = true
    this.oneWay = false
    this.MultiWay = false
  }
  isOneWay() {
    this.returnData = false
    this.oneWay = true
    this.MultiWay = false
  }
  isMultiWay() {
    this.returnData = false
    this.oneWay = false
    this.MultiWay = true
  }

  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }
}
