<div class="footer-part">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2 justify-content-center justify-content-md-start col-lg-2 d-flex p-0">
                <h2 class="footer-logo">
                    <a ><img class="logo-img" src="./../../../../assets/images/footer-logo.png" alt="" /></a>
                </h2>
            </div>
            <div
                class="col-lg-8 col-md-8 justify-content-center align-items-md-center justify-content-md-center d-flex p-0">
                <div class="middle-content">
                    <ul class="footer-ul list-unstyled m-0">
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" routerLink="/">Home</a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" routerLink="/aboutus">About US</a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" routerLink="/contactus">Contact Us</a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" routerLink="/carrer">Career</a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" routerLink="/blog">Blog</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="line-edit" />
<ul class="ulList">
    <li class="itemImage">
        <img class="logo-img222 " src="./../../../../assets/images/fav3.png" alt="" />

    </li>
    <li>
        <div class="row justify-content-center">

            <div
                class="col-lg-8 col-md-8 justify-content-center align-items-md-center justify-content-md-center d-flex p-0">

               
                <div class="middle-content">

                    <ul class="footer-ul list-unstyled m-0">

                        <li class="nav-item list-edit  list-edit-2">
                            <a class="nav-link anchor-edit" href="tel:+ 97144576077"> <span><img
                                        class="reachicon"
                                        src="./../../../../assets/images/icons-images/phone-bg.png"
                                        alt="no-image"></span> Call Us</a>
                        </li>
                        <li class="nav-item list-edit list-edit-2">
                            <a class="nav-link anchor-edit" href="mailto:support@devotiontourism.com">
                                <span><img class="reachicon" src="./../../../../assets/images/Group3.png"
                                        alt="no-image"></span> Email us</a>
                        </li>
                        <li class="nav-item list-edit list-edit-2">
                            <a class="nav-link anchor-edit" target="_blank"
                                href="https://www.google.com/maps/dir/25.1820753,55.2590815/devotion+tourism/@25.1714707,55.2688578,13.92z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e5f69fff8df8c8f:0xf9c39d32654761bd!2m2!1d55.2602652!2d25.1859019?entry=ttu">
                                <span><img class="reachicon" src="./../../../../assets/images/Group4.png"
                                        alt="no-image"></span> Location</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="row justify-content-center">
            <div
                class="col-lg-8 col-md-8 justify-content-center align-items-md-center justify-content-md-center d-flex p-0">
                <div class="middle-content">
                    <ul class="footer-ul list-unstyled m-0">
                        <li class="nav-item list-edit">
                            <a href="https://www.facebook.com/profile.php?id=100091695764298" target="_blank"
                                class="nav-link anchor-edit"> <span><img class="reachicon"
                                        src="./../../../../assets/images/icons-images/facebook-icon.png"
                                        alt="no-image"></span></a>
                        </li>
                        <li class="nav-item list-edit">
                            <a href="https://www.instagram.com/devotiontourism/" target="_blank"
                                class="nav-link anchor-edit"> <span><img class="reachicon"
                                        src="./../../../../assets/images/icons-images/insta-icon.png"
                                        alt="no-image"></span></a>
                        </li>
                        <li class="nav-item list-edit">
                            <a href="https://www.linkedin.com/company/92558268/admin/" target="_blank"
                                class="nav-link anchor-edit"> <span><img class="reachicon"
                                        src="./../../../../assets/images/icons-images/linkedin-icon.png"
                                        alt="no-image"></span> </a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit"
                                href="https://www.youtube.com/@Devotiontravelandtourism" target="_blank">
                                <span><img class="reachicon"
                                        src="./../../../../assets/images/icons-images/youtube-icon.png"
                                        alt="no-image"></span> </a>
                        </li>
                        <li class="nav-item list-edit">
                            <a class="nav-link anchor-edit" href="https://pin.it/1O6HPbY" target="_blank">
                                <span><img class="reachicon" src="./../../../../assets/images/pintrest.png"
                                        alt="no-image"></span> </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </li>
</ul>
   
   




        <!-- <ul class="FooterIcons">
            <li>
                <img class="imgIFooter" width="30px" height="30px" src="./../../../../assets/images/Devotion icon D.png" alt="">
            </li>
            <li>
                
            </li>
        </ul> -->





        <div class="editFtr-content">
            <ul class="content-ul list-unstyled">
                <!-- <li class="footer-list">
                    <a class="edit-links" href="#">2023 Devotion. All right reserved.</a>
                </li> -->
                <li class="footer-list">
                    <a class="edit-links " routerLink="/privacyPolicy"><span class="edit-links22">Privacy
                            Policy</span></a>
                </li>
                <li class="footer-list">
                    <a class="edit-links " routerLink="/privacyPolicy"><span class="edit-links22">Terms of
                            Service</span></a>
                </li>
                <li style="border: none !important;" class="footer-list">
                    <a class="edit-links " routerLink="/privacyPolicy"><span class="edit-links22">Cookies
                            Settings</span></a>
                </li>
                <br>
                <a class="edit-linksCopy">2023 Devotion. All rights reserved.</a>
            </ul>
        </div>
    </div>

</div>
<div class="footersection2">
    <img class="footeri" src="./../../../../assets/images/footerBG.png" alt="">
</div>