<ng-template style="background-color: transparent !important " #dialogContent>
  <div>
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <p style="text-align: center; margin-top: 20px;">Finding Flights</p>
    <!-- <ng-lottie class="scrlim2" width="250px" height="250px" [options]="options"
      (animationCreated)="animationCreated($event)"></ng-lottie> -->
  </div>
</ng-template>

<div class="flight_component-design">
  <div class="search-box">

    <div class="container containerAdjest">
      <ul class="nav nav-tabs flight-sec" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button (click)="multiCItyDisbale()" (click)="selectWay($event)" #OneWayButton value="One-Way"
            class="nav-link active flightsec-custmbt" id="return-tab" data-bs-toggle="tab" data-bs-target="#return"
            type="button" role="tab" aria-controls="home" aria-selected="true">
            One-Way
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button (click)="multiCItyDisbale()" (click)="selectWay($event)" #returnButton value="Return"
            class="nav-link flightsec-custmbt" id="oneway-tab" data-bs-toggle="tab" data-bs-target="#oneway"
            type="button" role="tab" aria-controls="profile" aria-selected="false">
            Return
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button #mutliButton value="Multi-City" (click)="multiCItyEnable()" (click)="selectWay($event)"
            class="nav-link flightsec-custmbt" id="multicity-tab" data-bs-toggle="tab" data-bs-target="#multicity"
            type="button" role="tab" aria-controls="contact" aria-selected="false">
            Multi-City
          </button>
        </li>
      </ul>






      <div class="data-collect">
        <div [style.display]="isHidden ? 'none' : 'block'" class="data-aligh">
          <div class="row justify-content-center">
            <div class="col-md-3 mb-2">
              <div class="fromBox">
                <span class="formText">From</span>
                <div>
                  <ng-select class="my-ng-select" [ngStyle]="{ 'color':'red' }" [(ngModel)]="Origin" [clearable]="false"
                    (change)="dataSelect($event)">
                    <ng-option *ngFor="let car of searchCountryList" [value]="car.iata">
                      <p class="SelectedList" style="margin: 0;"> {{car.cityName}}
                        ({{car.iata}})</p>
                    </ng-option>
                  </ng-select>
                  <!-- <input type="email" class="form-control main-input" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                </div>
              </div>
            </div>
            <div class="col-md-3 mb-2">
              <div class="fromBox">
                <span class="formText">To</span>
                <div>
                  <ng-select [clearable]="false" [(ngModel)]="Destination" (change)="dataSelect2($event)" #selectBox>
                    <ng-option *ngFor="let car of searchCountryList" [value]="car.iata">
                      <p class="SelectedList" style="margin: 0;"> {{car.cityName}}
                        ({{car.iata}})</p>
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="col-md-2 mb-2">
              <div (click)="picker.open()" class="fromBox">
                <span class="formText2">Departure</span>
                <mat-form-field appearance="fill">
                  <input [(ngModel)]="fromDates" matInput [matDatepicker]="picker" [formControl]="fromDate"
                    placeholder="Leave on" matIconSuffix="picker">
                  <!-- <mat-datepicker-toggle matIconSuffix [for]="picker">
               
                  </mat-datepicker-toggle> -->
                  <img style="width: 25px; height: 25px;     position: absolute;
                  right: 10px;
                  top: 23%;
                  bottom: 50%;" src="./../../../../assets/images/stay/cal.png" alt="">
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="oneWayReturn" class="col-md-2 mb-2">

              <div (click)="chooseReturn()" (click)="picker2.open()" class="fromBox">
                <span class="formText2">Return</span>
                <mat-form-field appearance="fill">
                  <input [(ngModel)]="isReturnValue" matInput [matDatepicker]="picker2" placeholder="Leave on"
                    matIconSuffix="picker2">
                  <!-- <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle> -->
                  <img style="width: 25px; height: 25px;     position: absolute;
                  right: 10px;
                  top: 23%;
                  bottom: 50%;" src="./../../../../assets/images/stay/cal.png" alt="">
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>

            </div>
            <div class="col-md-2 mb-2">
              <div class="btn-group">
                <div class="btn btn-success dropdown-toggle " type="button" data-bs-toggle="dropdown"
                  data-bs-auto-close="outside" #closePassingerDropdown aria-expanded="false" class="fromBox formText">
                  <span style="position: absolute;" class="formText">Passengers & Class</span>
                  <span class="countOfMembers">{{totalCountPassinger}}-{{className}}</span>
                  <div>
                  </div>
                </div>

                <ul class="dropdown-menu  PassingerSelectBox">
                  <div class="passingersListss">
                    <p class="passingersAdult">ADULTS (12Y +)</p>
                    <p class="ontheTravel">On the day of travel</p>
                    <div class="row">
                      <div class="col-md-10">
                        <div class="btn-group CountOfGroup" role="group" aria-label="Basic radio toggle button group ">
                          <input type="radio" class="btn-check" (click)="selectAdults(1)" name="adults" id="adults1"
                            autocomplete="off" checked>
                          <label class="btn btn-outline-primary CountOfNumber CountOfNRadius" for="adults1">1</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(2)" name="adults" id="adults2"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults2">2</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(3)" name="adults" id="adults3"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults3">3</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(4)" name="adults" id="adults4"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults4">4</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(5)" name="adults" id="adults5"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults5">5</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(6)" name="adults" id="adults6"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults6">6</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(7)" name="adults" id="adults7"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults7">7</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(8)" name="adults" id="adults8"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults8">8</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(9)" name="adults" id="adults9"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="adults9">9</label>

                          <input type="radio" class="btn-check" (click)="selectAdults(10)" name="adults" id="adults10"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber CountOfNRadius2" for="adults10">10</label>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <p class="passingersAdult2">CHILDREN (2y - 12y)</p>
                        <p class="ontheTravel">On the day of travel</p>
                        <div class="btn-group CountOfGroup" role="group2"
                          aria-label="Basic radio toggle button group2 ">
                          <input type="radio" class="btn-check" (click)="selectChilds(0)" name="children" id="children1"
                            autocomplete="off" checked>
                          <label class="btn btn-outline-primary CountOfNumber CountOfNRadius" for="children1">0</label>

                          <input type="radio" class="btn-check" (click)="selectChilds(1)" name="children" id="children2"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="children2">1</label>

                          <input type="radio" class="btn-check" (click)="selectChilds(2)" name="children" id="children3"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="children3">2</label>

                          <input type="radio" class="btn-check" (click)="selectChilds(3)" name="children" id="children4"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="children4">3</label>

                          <input type="radio" class="btn-check" (click)="selectChilds(4)" name="children" id="children5"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="children5">4</label>

                          <input type="radio" class="btn-check" (click)="selectChilds(5)" name="children" id="children6"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="children6">5</label>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <p class="passingersAdult2">INFANTS (below 2y)</p>
                        <p class="ontheTravel">On the day of travel</p>
                        <div class="btn-group CountOfGroup" role="group3"
                          aria-label="Basic radio toggle button group3 ">
                          <input type="radio" class="btn-check" (click)="selectInfants(0)" name="infants" id="infants1"
                            autocomplete="off" checked>
                          <label class="btn btn-outline-primary CountOfNumber CountOfNRadius" for="infants1">0</label>

                          <input type="radio" class="btn-check" (click)="selectInfants(1)" name="infants" id="infants2"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="infants2">1</label>

                          <input type="radio" class="btn-check" (click)="selectInfants(2)" name="infants" id="infants3"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="infants3">2</label>

                          <input type="radio" class="btn-check" (click)="selectInfants(3)" name="infants" id="infants4"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="infants4">3</label>

                          <input type="radio" class="btn-check" (click)="selectInfants(4)" name="infants" id="infants5"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="infants5">4</label>

                          <input type="radio" class="btn-check" (click)="selectInfants(5)" name="infants" id="infants6"
                            autocomplete="off">
                          <label class="btn btn-outline-primary CountOfNumber" for="infants6">5</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p class="passingersAdult3">CHOOSE TRAVEL CLASS</p>

                      <div class="btn-group CountOfGroup" role="group3" aria-label="Basic radio toggle button group3">
                        <input (click)="selectClass('1')" type="radio" class="btn-check" name="classse" id="infants111"
                          autocomplete="off" checked>
                        <label class="btn btn-outline-primary CountOfNumber CountOfNRadius" for="infants111">All
                          Classes</label>

                        <input (click)="selectClass('2')" type="radio" class="btn-check" name="classse" id="infants222"
                          autocomplete="off">
                        <label class="btn btn-outline-primary CountOfNumber" for="infants222">Economy</label>

                        <input (click)="selectClass(3)" type="radio" class="btn-check" name="classse" id="infants333"
                          autocomplete="off">
                        <label class="btn btn-outline-primary CountOfNumber" for="infants333">Premium Economy</label>

                        <input (click)="selectClass(4)" type="radio" class="btn-check" name="classse" id="infants444"
                          autocomplete="off">
                        <label class="btn btn-outline-primary CountOfNumber" for="infants444">Business</label>

                        <!-- <input (click)="selectClass(5)" type="radio" class="btn-check" name="classse" id="infants555"
                          autocomplete="off">
                        <label class="btn btn-outline-primary CountOfNumber" for="infants555">Premium Business</label>

                        <input (click)="selectClass(6)" type="radio" class="btn-check" name="classse" id="infants666"
                          autocomplete="off">
                        <label class="btn btn-outline-primary CountOfNumber" for="infants666">First</label> -->
                      </div>


                      <button data-bs-dismiss="dropdown" (click)="closePasingerCOunt()" type="button"
                        class="btn btn-secondary DoneSelection">Done</button>
                    </div>
                  </div>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div [style.display]="isHidden ? 'block' : 'none'" class="data-aligh">
          <div>
            <form [formGroup]="dynamicForm">
              <div formArrayName="formFields">

                <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                  <!-- <h6 class="flight-number">Flight {{i+1}}</h6> -->
                  <div>
                    <div class="row justify-content-center">

                      <div class="col-md-3 mb-2">
                        <div class="fromBox">
                          <span class="formText">From</span>
                          <div>
                            <ng-select [clearable]="false" formControlName="Origin" placeholder="Origin">
                              <ng-option *ngFor="let car of searchCountryList" [value]="car.iata">
                                <p class="SelectedList" style="margin: 0;"> {{car.cityName}}
                                  ({{car.iata}})</p>
                              </ng-option>
                            </ng-select>
                            <!-- <input type="email" class="form-control main-input" id="exampleInputEmail1" aria-describedby="emailHelp"> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-2">
                        <div class="fromBox">
                          <span class="formText">To</span>
                          <div>
                            <ng-select [clearable]="false" formControlName="Destination" placeholder="Destination">
                              <ng-option *ngFor="let car of searchCountryList" [value]="car.iata">
                                <p class="SelectedList" style="margin: 0;"> {{car.cityName}}
                                  ({{car.iata}})</p>
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-2 mb-2">
                        <div class="fromBox">
                          <span class="formText2">Departure</span>


                          <mat-form-field appearance="fill">
                            <input [min]="minDate" formControlName="PreferredDepartureTime" matInput
                              [matDatepicker]="picker4" placeholder="Leave on" matIconSuffix="picker4">
                            <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4 [min]="minDate"></mat-datepicker>
                          </mat-form-field>

                        </div>
                      </div>
                      <div class="col-md-1 minusBx">
                        <img tooltip="Remove" (click)="removeFormField(i)"
                          *ngIf="dynamicForm.value.formFields.length > 1" class="form-group myButton"
                          src="./../../../assets/images/aboutus/minus.png" alt="">
                        <img tooltip="Add" (click)="addFormField('')"
                          *ngIf="i == dynamicForm.value.formFields.length-1 && dynamicForm.value.formFields.length < 5"
                          class="form-group myButton" src="./../../../assets/images/aboutus/plus.png" alt="">
                      </div>



                    </div>


                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-md-2 mb-2">
                    <div class="btn-group">
                      <div class="btn btn-success dropdown-toggle " type="button" data-bs-toggle="dropdown"
                        data-bs-auto-close="outside" #closePassingerDropdown aria-expanded="false"
                        class="fromBox formText">
                        <span style="position: absolute;" class="formText">Passenger & Class</span>
                        <span class="countOfMembers">{{totalCountPassinger}}-{{className}}</span>
                        <div>
                        </div>
                      </div>

                      <ul class="dropdown-menu  PassingerSelectBox">
                        <div class="passingersListss">
                          <p class="passingersAdult">ADULTS (12Y +)</p>
                          <p class="ontheTravel">On the day of travel</p>
                          <div class="row">
                            <div class="col-md-10">
                              <div class="btn-group CountOfGroup" role="group"
                                aria-label="Basic radio toggle button group ">
                                <input type="radio" class="btn-check" (click)="selectAdults2(1)" name="adults"
                                  id="aadults1" autocomplete="off" checked>
                                <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                  for="aadults1">1</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(2)" name="adults"
                                  id="aadults2" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults2">2</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(3)" name="adults"
                                  id="aadults3" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults3">3</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(4)" name="adults"
                                  id="aadults4" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults4">4</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(5)" name="adults"
                                  id="aadults5" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults5">5</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(6)" name="adults"
                                  id="aadults6" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults6">6</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(7)" name="adults"
                                  id="aadults7" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults7">7</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(8)" name="adults"
                                  id="aadults8" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults8">8</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(9)" name="adults"
                                  id="aadults9" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="aadults9">9</label>

                                <input type="radio" class="btn-check" (click)="selectAdults2(10)" name="adults"
                                  id="aadults10" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber CountOfNRadius2"
                                  for="aadults10">10</label>

                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <p class="passingersAdult2">CHILDREN (2y - 12y)</p>
                              <p class="ontheTravel">On the day of travel</p>
                              <div class="btn-group CountOfGroup" role="group2"
                                aria-label="Basic radio toggle button group2 ">
                                <input type="radio" class="btn-check" (click)="selectChilds2(0)" name="children"
                                  id="achildren1" autocomplete="off" checked>
                                <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                  for="achildren1">0</label>

                                <input type="radio" class="btn-check" (click)="selectChilds2(1)" name="children"
                                  id="achildren2" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="achildren2">1</label>

                                <input type="radio" class="btn-check" (click)="selectChilds2(2)" name="children"
                                  id="achildren3" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="achildren3">2</label>

                                <input type="radio" class="btn-check" (click)="selectChilds2(3)" name="children"
                                  id="achildren4" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="achildren4">3</label>

                                <input type="radio" class="btn-check" (click)="selectChilds2(4)" name="children"
                                  id="achildren5" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="achildren5">4</label>

                                <input type="radio" class="btn-check" (click)="selectChilds2(5)" name="children"
                                  id="achildren6" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="achildren6">5</label>

                              </div>
                            </div>
                            <div class="col-md-6">
                              <p class="passingersAdult2">INFANTS (below 2y)</p>
                              <p class="ontheTravel">On the day of travel</p>
                              <div class="btn-group CountOfGroup" role="group3"
                                aria-label="Basic radio toggle button group3 ">
                                <input type="radio" class="btn-check" (click)="selectInfants2(0)" name="infants"
                                  id="ainfants1" autocomplete="off" checked>
                                <label class="btn btn-outline-primary CountOfNumber CountOfNRadius"
                                  for="ainfants1">0</label>

                                <input type="radio" class="btn-check" (click)="selectInfants2(1)" name="infants"
                                  id="ainfants2" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="ainfants2">1</label>

                                <input type="radio" class="btn-check" (click)="selectInfants2(2)" name="infants"
                                  id="ainfants3" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="ainfants3">2</label>

                                <input type="radio" class="btn-check" (click)="selectInfants2(3)" name="infants"
                                  id="ainfants4" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="ainfants4">3</label>

                                <input type="radio" class="btn-check" (click)="selectInfants2(4)" name="infants"
                                  id="ainfants5" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="ainfants5">4</label>

                                <input type="radio" class="btn-check" (click)="selectInfants2(5)" name="infants"
                                  id="ainfants6" autocomplete="off">
                                <label class="btn btn-outline-primary CountOfNumber" for="ainfants6">5</label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p class="passingersAdult3">CHOOSE TRAVEL CLASS</p>

                            <div class="btn-group CountOfGroup" role="group3"
                              aria-label="Basic radio toggle button group3">
                              <input type="radio" (click)="selectClass2(1)" class="btn-check" name="classse"
                                id="ainfants111" autocomplete="off" checked>
                              <label class="btn btn-outline-primary CountOfNumber CountOfNRadius" for="ainfants111">All
                                Class</label>

                              <input type="radio" (click)="selectClass2(2)" class="btn-check" name="classse"
                                id="ainfants222" autocomplete="off">
                              <label class="btn btn-outline-primary CountOfNumber" for="ainfants222">Economy</label>

                              <input type="radio" (click)="selectClass2(3)" class="btn-check" name="classse"
                                id="ainfants333" autocomplete="off">
                              <label class="btn btn-outline-primary CountOfNumber" for="ainfants333">Premium
                                Economy</label>

                              <input type="radio" (click)="selectClass2(4)" class="btn-check" name="classse"
                                id="ainfants444" autocomplete="off">
                              <label class="btn btn-outline-primary CountOfNumber" for="ainfants444">Business</label>

                              <!-- <input type="radio" (click)="selectClass2(5)" class="btn-check" name="classse"
                                id="ainfants555" autocomplete="off">
                              <label class="btn btn-outline-primary CountOfNumber" for="ainfants555">Premium
                                Business</label>

                              <input type="radio" (click)="selectClass2(6)" class="btn-check" name="classse"
                                id="ainfants666" autocomplete="off">
                              <label class="btn btn-outline-primary CountOfNumber" for="ainfants666">First</label> -->
                            </div>


                            <button data-bs-dismiss="dropdown" (click)="closePasingerCOunt()" type="button"
                              class="btn btn-secondary DoneSelection">Done</button>
                          </div>
                        </div>

                      </ul>
                    </div>
                  </div>







                  <div class="col-md-3 mb-2 p-0"></div>
                  <div class="col-md-2 mb-2 p-0"></div>
                  <div class="col-md-2 mb-2 p-0"></div>

                </div>
              </div>
            </form>
          </div>
        </div>



      </div>
      <button type="submit" *ngIf="SubmitButton" (click)="updateFIlter()" class="search-hotels-btn">Search</button>
      <button type="submit" *ngIf="!SubmitButton" (click)="multiflightForm()" class="search-hotels-btn">Search
        Multi-City</button>
    </div>
  </div>
  <div class="search-Results" (scroll)="onScroll($event)">
    <div class="container-pad">
      <div class="row" >

        <ng-lottie *ngIf="isLoaderActivate" class="scrlim2" width="250px" height="250px"
          style="margin: auto; display: flex; justify-content: center;" [options]="options"
          (animationCreated)="animationCreated($event)"></ng-lottie>


        <div class="col-md-3 fiterOff">
  

          <div *ngIf="!isPageLoaded" class="filterBox ">
            <!-- {{segements | json}} -->
            <!-- <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>
              <label (click)="handleFilters()" class="btn btn-outline oneWaycolor" for="btnradio1">One-Way</label>
            
              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
              <label (click)="handleFilters2()" class="btn btn-outline returnBtnss" for="btnradio2">Return</label>
   
            </div> -->
            <!-- One way Filter -->
            <span>
              <div class="airLineData ">
              
                <h1 class="AirlinesList">Airlines</h1>
                <ul *ngFor="let item of displayedItems; let i = index" class="FlightSort">
                  <li class="FlightSortLi">
                    <div style="cursor: pointer !important;" class="mb-3 form-check">
                      <input (change)="FilterByFlightName(item, $event.target.checked)"
                        style="cursor: pointer !important;" type="checkbox" class="form-check-input"
                        [id]="'exampleCheck' + i" [(ngModel)]="item.checked">
                      <label style="cursor: pointer !important;" class="form-check-label" [for]="'exampleCheck' + i">
                        <p class="filterByName">{{ item?.name }} ({{ item?.count }})</p>
                      </label>
                    </div>
                  </li>
                  <li>
                    <p class="filterByName"> 
                      {{ (item?.minFare | priceExchange:selectedCurrency | async) | number:'1.0-2' }}
                      {{selectedCurrency}}</p>
                  </li>
                </ul>
                <p (click)="toggleShowMore()" class="reminingList">{{ showMore ? 'Show Less' : 'Show More' }}</p>
              </div>

              <div class="PriceData">
                <h1 class="One-Price"> Price Range > ({{priceRange | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}})</h1>

                <div class="water-slider">
                  <div class="range-slider">

                    <input (change)="PriceSlider($event)" [tooltip]="priceRange | priceExchange:selectedCurrency | async   | number:'1.0-2'" [(ngModel)]="priceRange"
                      id="range-slide" class="range-slider__range browser-default" type="range" 
                                            [min]="minPrice" [max]="maxPrice">
                    <span style="display: none;" class="range-slider__value">2000</span>
                  </div>
                </div>
                <ul class="rangeValues">
                  <li> <span class="range-slider__value2">{{(minPrice  | priceExchange:selectedCurrency | async)   | number:'1.0-2'}} {{selectedCurrency}}</span></li>
                  <li> <span class="range-slider__value">{{(maxPrice  | priceExchange:selectedCurrency | async)   | number:'1.0-2'}} {{selectedCurrency}}</span> </li>
                </ul>
              </div>

              <div class="airLineFromData">
                <h1 class="AirlinesList">Departure From {{Origin}}</h1>
             
                <!-- <div *ngFor="let range of timeRanges; let i = index" class="form-check" (click)="toggleTimeRange(i)">
                  <input class="form-check-input" type="checkbox" [checked]="range.selected">
                  <label class="form-check-label filterByName" [for]="'timeRange' + i">{{ range.startTime }} to {{
                    range.endTime }}</label>
                </div> -->
                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                  <input  type="checkbox" class="btn-check" id="btncheck1" autocomplete="off">
                  <label id="housrClass" (click)="toggleTimeRange(0)" class="btn btn-outline-primary " for="btncheck1">

                    <div>
                      <img style="width: 27px;" src="./../../../../assets/images/newImages/morning_inactive.webp" alt=""><br>
                  <p>  Before 6AM</p>
                    </div>
                  </label>
                
                  <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off">
                  <label (click)="toggleTimeRange(1)" class="btn btn-outline-primary" for="btncheck2">
                    <div>
                      <img style="width: 27px;" src="./../../../../assets/images/newImages/noon_inactive.png" alt=""><br>
                  <p>6AM - 12PM </p>
                    </div>
                  </label>
                
                  <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off">
                  <label (click)="toggleTimeRange(2)" class="btn btn-outline-primary" for="btncheck3">
                    <div>
                      <img style="width: 27px;" src="./../../../../assets/images/newImages/evening_inactive.png" alt=""><br>
                  <p>12PM - 06PM </p>
                    </div>
                  </label>
                  
                  <input type="checkbox" class="btn-check" id="btncheck4" autocomplete="off">
                  <label (click)="toggleTimeRange(3)" class="btn btn-outline-primary" for="btncheck4">
                    <div>
                      <img style="width: 27px;" src="./../../../../assets/images/newImages/night_inactive.png" alt=""><br>
                  <p> After 6PM</p>
                    </div>  
                  </label>
                </div>
              </div>

              <div *ngIf="NJourneyType == 2 " class="airLineFromData">
                <h1 class="AirlinesList">Departure From {{Destination}}</h1>
               
                <!-- <div *ngFor="let returnRange of timeRanges; let i = index" class="form-check" (click)="toggleTimeReturnRange(i)">
                  <input class="form-check-input" type="checkbox" [checked]="returnRange.selected" id="'timeReturnRanges' + i">
                  <label class="form-check-label filterByName" [for]="'timeReturnRanges' + i">
                      {{ returnRange.startTime }} to {{ returnRange.endTime }}
                  </label>
              </div> -->

              <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                <input  type="checkbox" class="btn-check" id="btncheck11" autocomplete="off">
                <label (click)="toggleTimeReturnRange(0)" class="btn btn-outline-primary" for="btncheck11">

                  <div>
                    <img style="width: 27px;" src="./../../../../assets/images/newImages/morning_inactive.webp" alt=""><br>
                <p>  Before 6AM</p>
                  </div>
                </label>
              
                <input type="checkbox" class="btn-check" id="btncheck22" autocomplete="off">
                <label (click)="toggleTimeReturnRange(1)" class="btn btn-outline-primary" for="btncheck22">
                  <div>
                    <img style="width: 27px;" src="./../../../../assets/images/newImages/noon_inactive.png" alt=""><br>
                <p>6AM - 12PM </p>
                  </div>
                </label>
              
                <input type="checkbox" class="btn-check" id="btncheck33" autocomplete="off">
                <label (click)="toggleTimeReturnRange(2)" class="btn btn-outline-primary" for="btncheck33">
                  <div>
                    <img style="width: 27px;" src="./../../../../assets/images/newImages/evening_inactive.png" alt=""><br>
                <p>12PM - 06PM </p>
                  </div>
                </label>
                
                <input type="checkbox" class="btn-check" id="btncheck44" autocomplete="off">
                <label (click)="toggleTimeReturnRange(3)" class="btn btn-outline-primary" for="btncheck44">
                  <div>
                    <img style="width: 27px;" src="./../../../../assets/images/newImages/night_inactive.png" alt=""><br>
                <p> After 6PM</p>
                  </div>  
                </label>
              </div>



              </div>



              <div class="airLineFromData">
                <h1 class="AirlinesList">Stops From {{Origin}}</h1>
                <ul class="FlightSort">
                  <li>
                    <div *ngFor="let item of routeFliter" style="padding: 0;" class="mb-3 form-check form-checkss">
                      <label >
                        <input type="checkbox" (change)="DirectFlightFilter($event.target.checked,item.name)" >
                       {{item.displaName}}
                      </label> <br>
                
                    </div>
                  </li>
                  <li>
                    <!-- <p> ₹5,198</p> -->
                  </li>
                </ul>


              </div>
              <div *ngIf="NJourneyType == 2" class="airLineFromData">
                <h1 class="AirlinesList">Stops From {{Destination}}</h1>
                <ul class="FlightSort">
                  <li>
                    <div class="mb-3 form-check">
                      <input (click)="DirectFlightReturnFilter($event.target.checked,'Direct')" type="checkbox"
                        class="form-check-input" id="exampleCheck34">
                      <label class="form-check-label filterByName" for="exampleCheck34">Non Stop</label>
                    </div>
                    <div class="mb-3 form-check">
                      <input (click)="DirectFlightReturnFilter($event.target.checked,'TwoStop')" type="checkbox"
                        class="form-check-input" id="exampleCheck34">
                      <label class="form-check-label filterByName" for="exampleCheck34">1 Stop</label>
                    </div>
                    <div class="mb-3 form-check">
                      <input (click)="DirectFlightReturnFilter($event.target.checked,'2+ Stop')" type="checkbox"
                        class="form-check-input" id="exampleCheck34">
                      <label class="form-check-label filterByName" for="exampleCheck34">1+ Stop</label>
                    </div>
                  </li>
                  <li>
                    <!-- <p> ₹5,198</p> -->
                  </li>
                </ul>


              </div>
            </span>


            <!-- Two way Filter -->








          </div>
        </div>

        <div class="col-md-9" >
   
          <div *ngIf="!isPageLoaded" class="SearchList" >

            <button type="button" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
              aria-controls="offcanvasExample" class="btn btn-light filterButnn">Fliters <i
                class="fas fa-filter"></i></button>
            <h1 *ngIf="NJourneyType == 1">Flights from {{Source}} to {{DestinationPrint}}</h1>
            <div *ngIf="NJourneyType == 1 || NJourneyType === 3">
              <div class="mainBg-flgt">


                <div  class="linesData">
                  <ul class="lineAlight">
                    <li class="marginData">
                      <p class="sortLine">Sorted By:</p>
                    </li>
                    <li class="marginData">
                      <p class="sortLine">Departure</p>
                    </li>
                    <li class="marginData">
                      <p class="sortLine">Duration</p>
                    </li>
                    <li class="marginData">
                      <p class="sortLine">Arrival</p>
                    </li>
                    <li class="marginData">
                      <p class="sortLine">Price</p>
                    </li>
                    <li class="marginData">
                      <p class="sortLine"></p>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngFor="let item of displayFlightInfo; let i = index">
                <div class="flightInfo">
                  <ul class="ulList">
                    <li class="itemLi">
                      <h5 class="FlightName mt-0">
                        <img style="padding-right: 4px;" width="30px" height="30px"
                          src="./../../../../assets/images/FLight-logos/airlineLogo/{{item.AirlineDetails?.AirlineCode}}.gif">
                        {{item?.AirlineDetails?.AirlineName}} {{item?.AirlineDetails?.AirlineCode}}
                        {{item?.AirlineDetails?.FlightNumber}}
                        <!-- {{item?.IsLcc}} -->
                      </h5>
                      <!-- <span class="FlightCode">
                        </span> -->
                    </li>
                    <li class="itemLi">
                      <div class="OriginTIme">
                        <h1 class="OrignTimes">{{ item?.DepartureTime |date:'HH:mm' : 'en-US'}}</h1>
                        <span class="cityName">{{item?.Origins.CityName}}</span>
                      </div>
                    </li>
                    <li class="itemLi">
                      <div class="Timmingss">
                        <div class="stopings">
                          <span class="TImes">{{item.newAccumulatedDuration}}</span>
                          <!-- <span class="TImes">overyLaysTime</span> -->
                          <div *ngIf="item.eStops.length === 0" class="Bar"></div>
                          <div *ngIf="item.eStops.length !== 0" class="Bar2">
                            <div *ngFor="let orgs of item.eStops">
                              <ng-template #popTemplate>
                                <span style="font-size: 14px;">
                                  <p style="text-align: center;">Plane change</p>
                                  {{orgs.AirportName}},
                                  {{orgs.CityCode}}
                                  {{orgs.CountryCode}},
                                </span>
                              </ng-template>
                              <img width="10px" [popover]="popTemplate" triggers="mouseenter:mouseleave" height="10px"
                                src="./../../../../assets/images/pinBar.svg" alt="">


                            </div>


                          </div>


                          <span style="display: flex;
                          justify-content: center;" *ngIf="item.eStops.length === 0" class="TImes">Non Stop</span>
                          <span style="display: flex;
                          justify-content: center;
                          padding-top: 2px;" *ngIf="item.eStops.length !== 0" class="TImes">Stop
                            {{item.eStops.length}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="itemLi">
                      <div class="DestinamtionTIme">
                        <h1 class="DestinaTimes">{{item.ArrivalTime |date:
                          'HH:mm' : 'en-US' }}</h1>
                        <span class="cityName">{{item.Destination.CityName}}</span>
                      </div>
                    </li>
                    <li class="itemLi">
                      <div class="DestinamtionTIme">
                        <h1 class="DestinaTimes">{{  (item?.Fare.TotalFare | priceExchange:selectedCurrency | async) | number:'1.0-2'}}
                          <!-- {{ item?.Fare.AgentPreferredCurrency }} -->
                          {{selectedCurrency}}

                        </h1>
                        <span *ngIf="item?.passengersInfoCount === 1" class="cityName">Per Passenger</span>
                        <span *ngIf="item?.passengersInfoCount !== 1" class="cityName">Per {{
                          item?.passengersInfoCount}} Passengers</span>
                      </div>
                    </li>
                    <li class="itemLi">
                      <!-- <h2 class="accordion-header bookMethods" [id]="'flush-heading' + i">
                        <button [ngClass]="{ 'collapsed': activeIndex !== i }" [attr.data-bs-target]="'#collapse' + i"
                          (click)="toggleCollapse(i)" class="accordion-button ExpandNext" type="button"
                          data-bs-toggle="collapse" [attr.aria-controls]="'collapse' + i"
                          [attr.aria-expanded]="activeIndex === i ? 'true' : 'false'">
                          Show Price
                        </button>
                      </h2> -->
                      <h2 class="accordion-header bookMethods">
                        <a target="_blank" (click)="getFlightInfo(item)" class=" ExpandNext" type="button"
                          data-bs-toggle="collapse">
                          Book now
                        </a>
                      </h2>
                    </li>
                  </ul>
                  <p *ngIf="item?.AirlineRemark !== '--.'" class="airlineRemark">{{ item?.AirlineRemark}}</p>

                  <div style="text-align: end; padding: 8px 8px; z-index: 999; position: relative;"
                    class="accordion accordion-flush" id="accordionFlushExample">
                    <a (click)="getSegmentsInfo(item)" class="viewData collapsed" type="button"
                      data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseThree' + i"
                      [attr.aria-expanded]="i === 0 ? 'true' : 'false'" [attr.aria-controls]="'collapseThree' + i">
                      View Flight Details
                    </a>
                    <!-- <button   (click)="getFlightInfo(item)" class="BookNow">Book Now</button> -->
                    <div style="border: none;" class="accordion-item">
                      <div [id]="'collapse' + i" [ngClass]="{}" [attr.aria-labelledby]="'flush-heading' + i"
                        class="accordion-collapse collapse" [attr.aria-labelledby]="'flush-heading' + i"
                        [attr.data-bs-parent]="'#accordionFlushExample'">
                        <div style="text-align: start;" class="accordion-body infoTable">
                          <div class="table-responsive">
                            <table class="table borderless-table">
                              <thead>
                                <tr>
                                  <th class="tblWIdth">FARES</th>
                                  <!-- <th class="tblWIdth">CABIN BAG</th>
                                  <th class="tblWIdth">CHECK-IN</th> -->
                                  <th class="tblWIdth">CANCELLATION</th>
                                  <th class="tblWIdth">DATE CHANGE</th>
                                  <th class="tblWIdth">SEAT</th>
                                  <th class="tblWIdth">MEAL</th>
                                  <th class="tblWIdth"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Fare offered by {{item?.AirlineDetails?.AirlineName}} .</td>
                                  <!-- <td>-</td>
                                  <td>-</td> -->
                                  <td>Cancellation Fee Starting - </td>
                                  <td>Date Change fee starting -</td>
                                  <td>Middle Seat Free, Window/Aisle Chargeable</td>
                                  <td>Meal Type</td>
                                  <td>
                                    <h1 class="finalPrice">{{ item?.Fare.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                      <!-- {{ item?.Fare.AgentPreferredCurrency }} -->
                                      {{selectedCurrency}}
                                    </h1>
                                    <button (click)="getFlightInfo(item)" class="BookNow">Book Now</button>

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style="border: none;" class="accordion-item">
                      <div [id]="'collapseThree' + i" class="accordion-collapse collapse"
                        [attr.aria-labelledby]="'flush-heading' + i" [attr.data-bs-parent]="'#accordionFlushExample'">
                        <div class="accordion-body">
                          <div *ngIf="false" class="TtoalFares">
                            <div>
                              <ul style="border: none;" class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    [attr.data-bs-target]="'#home' + i" type="button" role="tab"
                                    [attr.aria-controls]="'home' + i" aria-selected="true">FLIGHT DETAILS</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                    [attr.data-bs-target]="'#profile' + i" type="button" role="tab"
                                    [attr.aria-controls]="'profile' + i" aria-selected="false">FARE SUMMARY</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                    [attr.data-bs-target]="'#contact' + i" type="button" role="tab"
                                    [attr.aria-controls]="'contact' + i" aria-selected="false">CANCELLATION</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="Galee-tab" data-bs-toggle="tab"
                                    [attr.data-bs-target]="'#Galee' + i" type="button" role="tab"
                                    [attr.aria-controls]="'Galee' + i" aria-selected="false">CANCELATION</button>
                                </li>

                              </ul>




                              <div class="tab-content" id="myTabContent">
                                <div>
                                  <div class="tab-pane fade show active" [id]="'home' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'home-tab' + i">

                                    <div>
                                      <div>
                                        <div *ngFor="let items of segmentList" class="SegmentFLight">
                                          <div class="Flight-Content">
                                            <h1 class="fName">{{items.Origin?.CityName}} to s
                                              {{items.Destination?.CityName}},
                                              {{items.DepartureTime | date: 'HH:mm' : 'en-US'}}</h1>
                                          </div>
                                          <div class="main-Daata">
                                            <div class="BrnadLogo">

                                              <h1 class="AirName">{{items?.AirlineDetails?.AirlineName}} <span
                                                  class="NumberFlight">{{items.AirlineDetails.AirlineCode}} |
                                                  {{items.AirlineDetails.FlightNumber}}</span></h1>
                                            </div>
                                            <ul class="Fdata-content">
                                              <li class="fDataFrom">
                                                <h1 class="FTimes">{{items.DepartureTime}}</h1>
                                                <p class="FDateWithMonth">{{items.DepartureTime |date: 'HH:mm' :
                                                  'en-US'}}</p>
                                                <p class="FDateWithMonth">Terminal: {{items.Origin?.Terminal}}</p>
                                                <p class="FDateWithMonth">{{items.Origin?.CityName}},
                                                  {{items.Origin?.CountryName}}</p>
                                              </li>
                                              <li class="fDataFrom">
                                                <p class="timsFlightss"><span
                                                    style="border-bottom: 2px solid #00d47d;">{{items.newDuration}}</span>
                                                </p>
                                                <!-- <p class="timsFlightss">{{convertToCustomFormat(items.Duration)}}  </p> -->
                                              </li>
                                              <li class="fDataFrom">
                                                <h1 class="FTimes">{{items.ArrivalTime |date: 'HH:mm' : 'en-US'}}</h1>
                                                <p class="FDateWithMonth">{{items.ArrivalTime |date: 'HH:mm' : 'en-US'}}
                                                </p>
                                                <p class="FDateWithMonth">Terminal: {{items.Destination?.Terminal}}
                                                </p>
                                                <p class="FDateWithMonth">{{items.Destination?.CityName}},
                                                  {{items.Destination?.CountryName}}</p>

                                              </li>
                                              <li class="fDataFrom">
                                                <h1 class="FTimes2">BAGGAGE:</h1>
                                                <p class="FDateWithMonth">Check-In: {{items.IncludedBaggage}}
                                                </p>
                                                <p class="FDateWithMonth"> Cabin Baggage: {{items.CabinBaggage}}
                                                </p>


                                              </li>
                                              <!-- <li class="fDataFrom">
                                                <h1 class="FTimes2">CHECK IN</h1>
                                              </li>
                                              <li class="fDataFrom">
                                                <h1 class="FTimes2">CABIN</h1>
                                               <p  class="FDateWithMonth"> {{items.CabinClass}}</p>
                                              </li> -->
                                            </ul>
                                          </div>

                                        </div>
                                      </div>


                                    </div>

                                  </div>
                                  <div class="tab-pane fade" [id]="'profile' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'profile-tab' + i">

                                    <div class="SegmentFLight2">
                                      <div class="Flight-Content">
                                        <h1 class="fName">Fare breakup</h1>
                                      </div>

                                      <div class="main-Daata">
                                        <ul class="Fdata-content">
                                          <li class="fDataFrom">
                                            <p class=" FDateWithTotalw"><b>TotalFare :</b></p>
                                          </li>
                                          <li class="fDataFrom">
                                            <p class=" FDateWithTotalw"><b>{{ item?.Fare.TotalFare}}</b></p>
                                          </li>

                                        </ul>
                                        <ul class="Fdata-content">
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">Base Fare :</p>
                                          </li>
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">{{ item?.Fare.BaseFare}}</p>
                                          </li>

                                        </ul>
                                        <ul class="Fdata-content">
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">ServiceFee :</p>
                                          </li>
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">{{ item?.Fare.ServiceFee}}</p>
                                          </li>

                                        </ul>
                                        <ul class="Fdata-content">
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">Tax :</p>
                                          </li>
                                          <li class="fDataFrom">
                                            <p class="FDateWithMonth">{{ item?.Fare.Tax}}</p>
                                          </li>

                                        </ul>

                                      </div>
                                    </div>


                                    <!-- Pill ONE END -->
                                    <!-- Segment pill 2 end -->
                                  </div>
                                  <div class="tab-pane fade" [id]="'contact' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'contact-tab' + i">
                                    .3..
                                  </div>
                                  <div class="tab-pane fade" [id]="'Galee' + i" role="tabpanel"
                                    [attr.aria-labelledby]="'Galee-tab' + i">
                                    .4..
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <mat-tab-group animationDuration="0ms">
                            <mat-tab label="FLIGHT DETAILS">
                              <!-- TAB 11111  start -->
                              <div class="TtoalFares">
                                <div *ngFor="let flight of  item?.Segments">
                                  <div *ngFor="let items of flight" class="SegmentFLight">
                                    <p class="GRTTIME" *ngIf="items?.GroundTime !== '00:00:00'"><span
                                        class="grondsMargin"> {{convertToCustomFormat(items.GroundTime)}} Layover</span>
                                    </p>
                                    <div class="Flight-Content">
                                      <h1 class="fName">{{items.Origin?.CityName}} to {{items.Destination?.CityName}},
                                        {{ formatDate(items.DepartureTime) }}</h1>
                                    </div>
                                    <div class="main-Daata">
                                      <div class="BrnadLogo">
                                        <img class="BrnadLogoImg"
                                          src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.AirlineDetails.AirlineCode}}.gif"
                                          alt="">
                                        <h1 class="AirName">{{items?.AirlineDetails?.AirlineName}} <span
                                            class="NumberFlight">{{items.AirlineDetails.AirlineCode}} |
                                            {{items.AirlineDetails.FlightNumber}}</span></h1>
                                      </div>
                                      <ul class="Fdata-content">
                                        <li class="fDataFrom">
                                          <h1 class="FTimes">{{items.DepartureTime |date: 'HH:mm' : 'en-US'}}</h1>
                                          <!-- <p class="FDateWithMonth">{{items.DepartureTime |date: 'HH:mm' : 'en-US'}}</p> -->
                                          <p class="FDateWithMonth">Terminal: {{items.Origin?.Terminal}}</p>
                                          <p class="FDateWithMonth">{{items.Origin?.CityName}},
                                            {{items.Origin?.CountryName}}</p>
                                        </li>
                                        <li class="fDataFrom">
                                          <!-- <p class="timsFlightss">{{convertToCustomFormat(items.Duration)}}</p> -->
                                          <p class="timsFlightss"><span
                                              style="border-bottom: 2px solid #00d47d;">{{items.newDuration}}</span>
                                          </p>

                                        </li>
                                        <li class="fDataFrom">
                                          <h1 class="FTimes">{{items.ArrivalTime |date: 'HH:mm' : 'en-US'}}</h1>
                                          <!-- <p class="FDateWithMonth">{{items.ArrivalTime |date: 'HH:mm' : 'en-US'}}</p> -->
                                          <p class="FDateWithMonth">Terminal: {{items.Destination?.Terminal}}</p>
                                          <p class="FDateWithMonth">{{items.Destination?.CityName}},
                                            {{items.Destination?.CountryName}}</p>

                                        </li>
                                        <li class="fDataFrom">
                                          <h1 class="FTimes2">BAGGAGE:</h1>
                                          <p class="FDateWithMonth">Check-In: {{items.IncludedBaggage}}
                                          </p>
                                          <p class="FDateWithMonth"> Cabin Baggage: {{items.CabinBaggage}}
                                          </p>


                                        </li>
                                        <!-- <li class="fDataFrom">
                                          <h1 class="FTimes2">CHECK IN</h1>
                                        </li>
                                        <li class="fDataFrom">
                                          <h1 class="FTimes2">CABIN</h1>
                                          {{items.CabinClass}}
                                        </li> -->
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- TAB 11111  end -->



                            </mat-tab>
                            <mat-tab label="FARE SUMMARY">
                              <div class="TtoalFares">
                                <div class="SegmentFLight2">
                                  <div class="Flight-Content">
                                    <h1 class="fName">Fare breakup</h1>
                                  </div>

                                  <div class="main-Daata">
                                    <ul class="Fdata-content">
                                      <li class="fDataFrom">
                                        <p class=" FDateWithTotalw">TotalFare :</p>
                                      </li>
                                      <li class="fDataFrom">
                                        <p class=" FDateWithTotalw">{{ item?.Fare.TotalFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                          <!-- {{ item?.Fare.AgentPreferredCurrency}} -->
                                          {{selectedCurrency}}
                                        </p>
                                      </li>

                                    </ul>
                                    <ul class="Fdata-content">
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">Base Fare :</p>
                                      </li>
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">{{ item?.Fare.BaseFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                          <!-- {{ item?.Fare.AgentPreferredCurrency}} -->
                                          {{selectedCurrency}}
                                        </p>
                                      </li>

                                    </ul>
                                    <ul class="Fdata-content">
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">ServiceFee :</p>
                                      </li>
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">{{ item?.Fare.ServiceFee | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                          <!-- {{ item?.Fare.AgentPreferredCurrency}} -->
                                          {{selectedCurrency}}
                                        </p>
                                      </li>

                                    </ul>
                                    <ul class="Fdata-content">
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">Tax :</p>
                                      </li>
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">{{ item?.Fare.Tax | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                          <!-- {{ item?.Fare.AgentPreferredCurrency}}</p> -->
                                          {{selectedCurrency}}
                                      </li>

                                    </ul>
                                    <ul class="Fdata-content">
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">Other fee :</p>
                                      </li>
                                      <li class="fDataFrom">
                                        <p class="FDateWithMonth">{{ item?.Fare?.newAgentMarkup | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                                          <!-- {{ item?.Fare.AgentPreferredCurrency}}</p> -->
                                          {{selectedCurrency}}
                                      </li>

                                    </ul>

                                  </div>
                                </div>
                              </div>
                            </mat-tab>
                            <!-- <mat-tab label="CANCELLATION">


                              <div class="TtoalFares">
                                <div class="SegmentFLight">
                                  <div class="Flight-Content">
                                    <h1 class="fName">Cancellation</h1>
                                  </div>

                                  <div class="main-Daata">

                                    <div style="padding-top: 25px;">
                                      <img class="FareRuleImg" src="./../../../../assets/images/FreRuleImae.svg" alt="">
                                      <p class="sorryRule">Sorry! Fare rules could not be <br> fetched at the moment.
                                      </p>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </mat-tab>
                            <mat-tab label="DATE CHANGE">
                              <div class="TtoalFares">
                                <div class="SegmentFLight">
                                  <div class="Flight-Content">
                                    <h1 class="fName">Date Change</h1>
                                  </div>

                                  <div class="main-Daata">

                                    <div style="padding-top: 25px;">
                                      <img class="FareRuleImg" src="./../../../../assets/images/FreRuleImae.svg" alt="">
                                      <p class="sorryRule">Sorry! Fare rules could not be <br> fetched at the moment.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </mat-tab> -->
                          </mat-tab-group>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-lottie *ngIf="isLoaderActivate" class="scrlim2" width="250px" height="250px"
                style="margin: auto; display: flex; justify-content: center;" [options]="options"
                (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>


            <div *ngIf="NJourneyType == 2 ">

              <!-- *ngIf="flightInfo.length > 0 && returnFlight.length > 0" id="flightInfoReturn" -->
              <div #lastDiv class="flightInfoReturn">
                <div *ngIf="closeSelection" class="finaFare">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="leftFLight">
                        <ul class="flightDep">
                          <li class="deps">
                            <!-- <button (click)="checkLastDivHeight()">Chekkk</button> -->
                            <!-- <button (click)="filterFlightName()">CLick</button> -->
                            <p class="Departure">Departure</p>
                          </li>
                          <li class="deps">

                            <p class="airNamei">{{selectOne?.AirlineDetails?.AirlineName}}</p>
                          </li>
                        </ul>
                        <ul class="fly-info">
                          <li class="underFly"><img class="logoIcon"
                              src="./../../../../assets/images/FLight-logos/airlineLogo/{{selectOne?.AirlineDetails?.AirlineCode}}.gif"
                              alt="" alt="">
                          </li>
                          <li class="underFly">
                            <p class="times">{{selectOne?.DepartureTime | date: 'HH:mm' : 'en-US'}}</p>
                          </li>
                          <li class="underFly">
                            <p class="times"><i class="fas fa-arrow-right"></i></p>
                          </li>
                          <li class="underFly">
                            <p class="times">{{selectOne?.ArrivalTime | date: 'HH:mm' : 'en-US' }}</p>
                          </li>
                          <li class="underFly">
                            <p class="Pricesss">{{selectOne?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} <span class="priceAED">{{selectedCurrency}}</span></p>
                          </li>
                        </ul>
                        <!-- <p data-bs-toggle="modal"  data-bs-target="#exampleModalFlight" class="DetailFare">View Flight Details</p> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="leftFLight">
                        <ul class="flightDep">
                          <li class="deps">
                            <p class="Departure">Return</p>
                          </li>
                          <li class="deps">
                            <p class="airNamei">{{selectedDeparture?.AirlineDetails?.AirlineName}}</p>
                          </li>
                        </ul>
                        <ul class="fly-info">
                          <li class="underFly"><img class="logoIcon"
                              src="./../../../../assets/images/FLight-logos/airlineLogo/{{selectedDeparture?.AirlineDetails?.AirlineCode}}.gif"
                              alt="" alt="">
                          </li>
                          <li class="underFly">
                            <p class="times">{{selectedDeparture?.DepartureTime | date: 'HH:mm' : 'en-US'}}</p>
                          </li>
                          <li class="underFly">
                            <p class="times"><i class="fas fa-arrow-right"></i></p>
                          </li>
                          <li class="underFly">
                            <p class="times">{{selectedDeparture?.ArrivalTime | date: 'HH:mm' : 'en-US' }}</p>
                          </li>
                          <li class="underFly">
                            <p class="Pricesss">{{selectedDeparture?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} <span class="priceAED">{{selectedCurrency}}</span></p>
                          </li>
                        </ul>
                        <!-- <p data-bs-toggle="modal"  data-bs-target="#exampleModalFlight" class="DetailFare">View Flight Details</p> -->
                      </div>
                    </div>
                    <div class="col-md-4">
                      <ul class="finalRate">
                        <li class="final_data">
                          <h1 class="Cost">{{finalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</h1>
                          <p class="asPer">Per Passenger</p>
                        </li>
                        <li>
                          <button (click)="bookReturn()" class="bookNow">Book Now</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="OrginFlights">
                      <div class="place-to-place">
                        <div class="inside-place-to-place">
                          <p class="first-place">{{Origin}}</p>
                          <i class="fas fa-arrow-right"></i>
                          <p class="second-place"> {{Destination}}</p>
                          <p class="calender-date">{{departurDate | date:'mediumDate'}}</p>
                        </div>
                        <div class="departure-arival">
                          <a class="departure-content">Departure</a>
                          <a class="departure-content">Duration</a>
                          <a class="departure-content">Arrival</a>
                          <a class="departure-content">Price<i class="fa-solid fa-arrow-up-long"></i></a>
                        </div>
                      </div>
                      <p *ngIf="displayFlightInfo2.length <= 0" style="text-align: center; margin: 30px; color: #AB823E;">No
                        flights here..</p>
                      <div (click)="onRowClick(i)" *ngFor="let item of displayFlightInfo2; let i = index"
                        class="devotional-booking-right-inside">
                        <div class="booking-top-data">
                          <img class="air-india-img"
                            src="./../../../../assets/images/FLight-logos/airlineLogo/{{item.AirlineDetails.AirlineCode}}.gif"
                            alt="">
                          <p class="air-india-title"> {{item?.AirlineDetails?.AirlineName}}
                            {{item?.AirlineDetails?.AirlineCode}} {{item?.AirlineDetails?.FlightNumber}}
                            <!-- {{item?.IsLcc}} -->
                          </p>
                        </div>

                        <ul class="devotional-airlines-inside">
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-time"> {{ item?.DepartureTime | date:'HH:mm' : 'en-US' }}
                              </p>
                              <p class="flight-booking-time-content">{{ item?.Origins.CityName }}</p>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <div class="stopings">
                                <span class="TImes">{{item?.newAccumulatedDuration}}</span>
                                <!-- <span class="TImes">overyLaysTime</span> -->
                                <div *ngIf="item.eStops.length === 0" class="Bar"></div>
                                <div *ngIf="item.eStops.length !== 0" class="Bar2">
                                  <div *ngFor="let orgs of item.eStops">
                                    <ng-template #popTemplate>
                                      <span style="font-size: 14px;">
                                        <p style="text-align: center;">Plane change</p>
                                        {{orgs.AirportName}},
                                        {{orgs.CityCode}}
                                        {{orgs.CountryCode}},
                                      </span>
                                    </ng-template>
                                    <img width="10px" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                      height="10px" src="./../../../../assets/images/pinBar.svg" alt="">


                                  </div>


                                </div>


                                <span style="display: flex;
                              justify-content: center;" *ngIf="item.eStops.length === 0" class="TImes">Non Stop</span>
                                <span style="display: flex;
                              justify-content: center;
                              padding-top: 2px;" *ngIf="item.eStops.length !== 0" class="TImes">Stop
                                  {{item.eStops.length}}</span>
                              </div>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-hour">{{ item.ArrivalTime | date: 'HH:mm' : 'en-US' }}</p>
                              <p class="flight-booking-hour-content">{{ item.Destination.CityName }}</p>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-timer">{{ item?.Fare.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} <span
                                  class="Curreyns">
                                  {{selectedCurrency}}</span></p>
                              <!-- {{ item?.Fare.AgentPreferredCurrency }} -->
                              <p class="flight-booking-timer-content">Per Passenger</p>
                              <!-- <p class="flight-booking-timer-content">Per {{itempassingersInfo.}} Passenger</p> -->
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data radio-button-data">
                            <div class="form-check">
                              <!-- Make sure to replace 'getOriginData(origin)' and 'onItemSelectionChange1(selectedItemIndex1)' with correct event handlers -->
                              <mat-radio-group (change)="getOriginData(item)"
                                (click)="onItemSelectionChange1(selectedItemIndex1)" [(ngModel)]="selectedItemIndex1">
                                <!-- Make sure to replace '[value]="i"' with the appropriate value for the radio button -->
                                <mat-radio-button [value]="i"></mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </li>
                          <!-- Make sure to replace 'Flight Details' with the appropriate content for the collapsible section -->
                          <p [ngClass]="{ 'collapsed': i != 0 }" [attr.data-bs-target]="'#collapse' + i"
                          [attr.aria-controls]="'collapse' + i" class="expBtun collapsed" data-bs-toggle="collapse"
                          aria-expanded="false" aria-controls="flush-collapseOne">
                          Flight Details
                        </p>
                        </ul>

                        <p style="padding-left: 15px;" class="airlineRemark">{{ item?.AirlineRemark}}</p>


                        
                      <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">

                          <div [id]="'collapse'+i" [ngClass]="" [attr.aria-labelledby]="'heading'+i"
                            id="flush-collapseOne33" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne33" data-bs-parent="#accordionFlushExample">
                            <div style="z-index:999 !important;" class="accordion-body">


                              <div *ngFor="let items of item.Segments[0]" style="background-color: #F0EBE7;"
                                (click)="onRowClick(i)" class="devotional-booking-right-inside">
                                <p *ngIf="items?.GroundTime !=='00:00:00'" class="groundingTIme">Change of planes : {{formatDuration(items?.GroundTime)}}</p>
                                <div class="booking-top-data">
                                  <img class="air-india-img"
                                    src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.AirlineDetails.AirlineCode}}.gif"
                                    alt="">
                                  <p class="air-india-title">{{items.AirlineDetails?.AirlineName}} -
                                    {{items.AirlineDetails?.FlightNumber}}</p>

                                </div>
                                <ul class="devotional-airlines-inside">
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-time">{{items?.DepartureTime |date: 'HH:mm' :
                                        'en-US'}}</p>
                                      <p class="flight-booking-time-content">{{items?.Origin?.CityName}}</p>
                                      <p style="font-size: 10px;" class="flight-booking-time-content">
                                        {{items?.Origin?.CityName}},{{items?.Origin?.CountryName}}</p>
                                        <p *ngIf="items.Origin?.Terminal" class="terminals">Terminal : {{items.Origin?.Terminal}} </p>

                                    </div>
                                  </li>
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-clock"> {{formatDuration(items?.Duration)}}</p>
                                      <p class="flight-booking-clock-content">Flight Duration</p>

                                    </div>
                                  </li>
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-hour">{{items?.ArrivalTime |date: 'HH:mm' :
                                        'en-US'}}</p>
                                      <p class="flight-booking-hour-content">{{items?.Destination?.CityName}}</p>
                                      <p style="font-size: 10px;" class="flight-booking-hour-content">
                                        {{items?.Destination?.CityName}},{{items?.Destination?.CountryName}}</p>
                                        <p *ngIf="items.Destination?.Terminal" class="terminals">Terminal : {{items.Destination?.Terminal}} </p>

                                    </div>
                                  </li>

                                </ul>

                              </div>



                            </div>
                          </div>
                        </div>

                      </div>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="OrginFlights">
                      <div class="place-to-place">
                        <div class="inside-place-to-place">
                          <p class="first-place">{{ Destination}}</p>
                          <i class="fas fa-arrow-right"></i>
                          <p class="second-place"> {{Origin}}</p>
                          <p class="calender-date">{{ArrailvaDate | date:'mediumDate'}}</p>
                        </div>
                        <div class="departure-arival">
                          <a class="departure-content">Departure</a>
                          <a class="departure-content">Duration</a>
                          <a class="departure-content">Arrival</a>
                          <a class="departure-content">Price<i class="fa-solid fa-arrow-up-long"></i></a>
                        </div>
                      </div>
                      <p *ngIf="returnFlightInformation.length <= 0" style="text-align: center; margin: 30px; color: #AB823E;">No
                        flights here..</p>
                      <div (click)="getOriginData2(origin)" (click)="onRowClick(i)"
                        *ngFor="let origin of returnFlightInformation; let i = index" class="devotional-booking-right-inside">
                        <div class="booking-top-data">
                          <img class="air-india-img"
                            src="./../../../../assets/images/FLight-logos/airlineLogo/{{origin.AirlineDetails.AirlineCode}}.gif"
                            alt="">
                          <p class="air-india-title">{{origin?.AirlineDetails?.AirlineName}}
                            {{origin?.AirlineDetails.AirlineCode}} {{origin?.AirlineDetails.FlightNumber}}
                            <!-- {{origin?.IsLcc}} -->
                          </p>
                        </div>
                        <ul class="devotional-airlines-inside">
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-time"> {{ origin?.DepartureTime |date:'HH:mm' : 'en-US'}}
                              </p>
                              <p class="flight-booking-time-content">{{ origin?.Origins.CityName}}</p>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <div class="stopings">
                                <span class="TImes">{{origin.newAccumulatedDuration}}</span>
                                <!-- <span class="TImes">overyLaysTime</span> -->
                                <div *ngIf="origin.eStops.length === 0" class="Bar"></div>
                                <div *ngIf="origin.eStops.length !== 0" class="Bar2">
                                  <div *ngFor="let orgs of origin.eStops">
                                    <ng-template #popTemplate>
                                      <span style="font-size: 14px;">
                                        <p style="text-align: center;">Plane change</p>
                                        {{orgs.AirportName}},
                                        {{orgs.CityCode}}
                                        {{orgs.CountryCode}},
                                      </span>
                                    </ng-template>
                                    <img width="10px" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                      height="10px" src="./../../../../assets/images/pinBar.svg" alt="">


                                  </div>


                                </div>


                                <span style="display: flex;
                              justify-content: center;" *ngIf="origin.eStops.length === 0" class="TImes">Non
                                  Stop</span>
                                <span style="display: flex;
                              justify-content: center;
                              padding-top: 2px;" *ngIf="origin.eStops.length !== 0" class="TImes">Stop
                                  {{origin.eStops.length}}</span>
                              </div>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-hour">{{origin.ArrivalTime |date:'HH:mm' : 'en-US' }}
                              </p>
                              <p class="flight-booking-hour-content">{{origin.Destination.CityName}}</p>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data">
                            <div class="flight-checking-content">
                              <p class="flight-booking-title-timer">{{ origin?.Fare.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}<span
                                  class="Curreyns">
                                  <!-- {{origin?.Fare.AgentPreferredCurrency}} -->
                                  {{selectedCurrency}}
                                </span></p>
                              <p class="flight-booking-timer-content">per Passenger</p>
                            </div>
                          </li>
                          <li class="devotional-airlines-inside-data radio-button-data">
                            <div class="form-check">
                              <mat-radio-group (click)="getOriginData2(origin)" [(ngModel)]="selectedItemIndex2">
                                <mat-radio-button [value]="i"></mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </li>


                          <div class="accordion-header" [id]="'heading2-' + i">
                          <p class="expBtun collapsed" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse2-' + i" aria-expanded="{{ i === 0 ? 'true' : 'false' }}"
                            [attr.aria-controls]="'collapse2-' + i">
                            Flight Details
                          </p>
                        </div>
                          <p class="airlineRemark">{{ origin?.AirlineRemark}}</p>
                        </ul>
                        
                      <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">

                          <div [id]="'collapse2-' + i" class="accordion-collapse collapse" [ngClass]="{}"
                            [attr.aria-labelledby]="'heading2-' + i">
                            <div style="z-index:999 !important;" class="accordion-body">


                              <div *ngFor="let items of origin.Segments[0]" style="background-color: #F0EBE7;"
                                (click)="onRowClick(i)" class="devotional-booking-right-inside">
                                <p *ngIf="items?.GroundTime !=='00:00:00'" class="groundingTIme">Change of planes : {{formatDuration(items?.GroundTime)}}</p>
                                <div class="booking-top-data">
                                  <img class="air-india-img"
                                    src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.AirlineDetails.AirlineCode}}.gif"
                                    alt="">
                                  <p class="air-india-title">{{items.AirlineDetails?.AirlineName}} -
                                    {{items.AirlineDetails?.FlightNumber}}</p>

                                </div>
                                <ul class="devotional-airlines-inside">
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-time">{{items?.DepartureTime |date: 'HH:mm' :
                                        'en-US'}}</p>
                                      <p class="flight-booking-time-content">{{items?.Origin?.CityName}}</p>
                                      <p style="font-size: 10px;" class="flight-booking-time-content">
                                        {{items?.Origin?.CityName}},{{items?.Origin?.CountryName}}</p>
                                        <p *ngIf="items.Origin?.Terminal" class="terminals">Terminal : {{items.Origin?.Terminal}} </p>
                                    </div>
                                  </li>
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-clock">{{formatDuration(items?.Duration)}}</p>
                                      <p class="flight-booking-clock-content">Flight Duration</p>

                                    </div>
                                  </li>
                                  <li style="width: 33%;" class="devotional-airlines-inside-data">
                                    <div class="flight-checking-content">
                                      <p class="flight-booking-title-hour">{{items?.ArrivalTime |date: 'HH:mm' :
                                        'en-US'}}</p>
                                      <p class="flight-booking-hour-content">{{items?.Destination?.CityName}}</p>
                                      <p style="font-size: 10px;" class="flight-booking-hour-content">
                                        {{items?.Destination?.CityName}},{{items?.Destination?.CountryName}}</p>
                                        <p *ngIf="items.Destination?.Terminal" class="terminals">Terminal : {{items.Destination?.Terminal}} </p>

                                    </div>
                                  </li>

                                </ul>

                              </div>



                            </div>
                          </div>
                        </div>

                      </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div *ngIf="noData">
            <p style=" text-align: center;margin: 30px; color: #AB823E;"><img
                src="./../../../../assets/images/no-flights.png" alt="no-data"></p>
            <p style=" text-align: center;margin: 30px; color: #292827;">Sorry, Flights Not Found.</p>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>


<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-md-12">
        <!-- <ngx-skeleton-loader *ngIf="isPageLoaded" count="20" [theme]="{ 
      'border-radius': '5px',
      height: '50px',
    }"></ngx-skeleton-loader> -->
        <div class="mobileFilter">


          <div *ngIf="!isPageLoaded" class="filterBox">
            <!-- {{segements | json}} -->
            <div class="airLineData">
              <h1 class="AirlinesList">Airlines</h1>
              <ul *ngFor="let item of displayedItems ;  let i= index" class="FlightSort">
                <li class="FlightSortLi">
                  <div style="cursor: pointer !important;" class="mb-3 form-check">
                    <input (click)="FilterByFlight(item,$event.target.checked)" style="cursor: pointer !important;"
                      type="checkbox" class="form-check-input  " id="exampleCheck1">
                    <label style="cursor: pointer !important;" class="form-check-label" for="exampleCheck1">


                      <p class="filterByName">{{item?.airline}} ({{ item?.count}})</p>
                    </label>
                  </div>
                </li>
                <li>
                  <p class="filterByName"> {{ item?.minFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </p>
                </li>
              </ul>
              <p (click)="toggleShowMore()" class="reminingList">{{ showMore ? 'Show Less' : 'Show More' }}</p>

            </div>

            <div class="PriceData">
              <h1 class="One-Price">One Way Price ({{priceRange | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}})</h1>

              <div class="water-slider">
                <div class="range-slider">

                  <input (change)="PriceSlider($event)" [tooltip]="priceRange" [(ngModel)]="priceRange" id="range-slide"
                    class="range-slider__range browser-default" type="range" value="2000" [min]="minPrice"
                    [max]="maxPrice">
                  <span style="display: none;" class="range-slider__value">2000</span>
                </div>
              </div>
              <ul class="rangeValues">
                <li> <span class="range-slider__value2">{{minPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</span></li>
                <li> <span class="range-slider__value">{{maxPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</span> </li>
              </ul>
            </div>
     
            <div class="airLineFromData">
              <h1 class="AirlinesList">Departure From {{Origin}}</h1>
              <ul class="FlightSort2">
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="filterMorningFlightsParameters('06:00','12:00',$event.target.checked)"
                      type="checkbox" class="form-check-input" id="exampleCheck3">
                    <label class="form-check-label filterByName" for="exampleCheck3">06:00 to 12:00</label>
                  </div>
                </li>
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="filterMorningFlightsParameters('12:00','18:00',$event.target.checked)"
                      type="checkbox" class="form-check-input" id="exampleCheck3">
                    <label class="form-check-label filterByName" for="exampleCheck3">12:00 to 18:00</label>
                  </div>
                </li>
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="filterMorningFlightsParameters('18:00','00:00',$event.target.checked)"
                      type="checkbox" class="form-check-input" id="exampleCheck3">
                    <label class="form-check-label filterByName" for="exampleCheck3">18:00 to 00:00</label>
                  </div>
                </li>
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="filterMorningFlightsParameters('00:00','06:00',$event.target.checked)"
                      type="checkbox" class="form-check-input" id="exampleCheck3">
                    <label class="form-check-label filterByName" for="exampleCheck3">00:00 to 06:00</label>
                  </div>
                </li>

              </ul>


            </div>
            <div class="airLineFromData">
              <h1 class="AirlinesList">Stops From {{Origin}}</h1>
              <ul class="FlightSort">
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="DirectFlightFilter($event.target.checked)" type="checkbox" class="form-check-input"
                      id="exampleCheck3">
                    <label class="form-check-label filterByName" for="exampleCheck3">Direct Flights</label>
                  </div>
                </li>
                <li>
                  <!-- <p> ₹5,198</p> -->
                </li>
              </ul>


            </div>

            <div class="airLineFromData">
              <h1 class="AirlinesList">Stops From {{Destination}}</h1>
              <ul class="FlightSort">
                <li>
                  <div class="mb-3 form-check">
                    <input (click)="DirectFlightReturnFilter($event.target.checked)" type="checkbox"
                      class="form-check-input" id="exampleCheck33">
                    <label class="form-check-label filterByName" for="exampleCheck33">Direct Flights</label>
                  </div>
                </li>
                <li>
                  <!-- <p> ₹5,198</p> -->
                </li>
              </ul>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






















<div class="return-flightPage">
  <!-- Button trigger modal -->
  <!-- <button type="button" class="btn editMoodal-butn btn-primary" data-bs-toggle="modal"
    data-bs-target="#exampleModalFlight">
    Launch demo modal
  </button> -->

  <!-- Modal -->
  <div class="modal fade edit-MOOdal" id="exampleModalFlight" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-below modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div *ngIf="false" class="modal-header modaal_hedr-edit">
          <div class="flight-BookingBody">
            <div class="body-top-section">
              <div class="row">
                <ul class="maiN_uL list-unstyled">
                  <li class="box-list">
                    <ul class="departUl1 list-unstyled d-flex">
                      <li class="depart-title">Departure</li>
                      <li class="flight-NAME">{{selectOne?.AirlineDetails?.AirlineName}}</li>
                    </ul>
                    <ul class="departUl2 list-unstyled d-flex">
                      <li class="departTime">
                        <img class="editAir-logo"
                          src="./../../../../assets/images/FLight-logos/airlineLogo/{{selectOne?.AirlineDetails?.AirlineCode}}.gif"
                          alt="" /><span class="time-edit">
                          {{selectOne?.DepartureTime | date: 'HH:mm' : 'en-US'}}
                          <span class="icnright"><i
                              class="fas fa-long-arrow-alt-right"></i></span>{{selectOne?.ArrivalTime | date: 'HH:mm' :
                          'en-US' }}</span>
                      </li>
                      <li class="fare-price">{{selectOne?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                        <!-- {{selectOne?.Fare?.AgentPreferredCurrency}} -->
                        {{selectedCurrency}}
                      </li>
                    </ul>
                    <!-- <a class="view-detail">View Flight Details</a> -->
                  </li>
                  <li class="box-list">
                    <ul class="departUl1 list-unstyled d-flex">
                      <li class="depart-title">Return</li>
                      <li class="flight-NAME">{{selectedDeparture?.AirlineDetails?.AirlineName}}</li>
                    </ul>
                    <ul class="departUl2 list-unstyled d-flex">
                      <li class="departTime">
                        <img class="editAir-logo"
                          src="./../../../../assets/images/FLight-logos/airlineLogo/{{selectedDeparture?.AirlineDetails?.AirlineCode}}.gif"
                          alt="" /><span class="time-edit">
                          {{selectedDeparture?.DepartureTime | date: 'HH:mm' : 'en-US'}}
                          <span class="icnright"><i
                              class="fas fa-long-arrow-alt-right"></i></span>{{selectedDeparture?.ArrivalTime | date:
                          'HH:mm' : 'en-US' }}</span>
                      </li>
                      <li class="fare-price">{{selectedDeparture?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                        <!-- {{selectedDeparture?.Fare?.AgentPreferredCurrency}} -->
                        {{selectedCurrency}}
                      </li>
                    </ul>
                    <!-- <a href="" class="view-detail">View Flight Details</a> -->
                  </li>
                  <li class="box-list box-lst1">
                    <ul class="personUl3 list-unstyled d-flex">
                      <li class="person-price">
                        <p class="pricei-title">{{finalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                        <p class="per-person">Per Passenger</p>
                      </li>
                      <li class="btn-list">
                        <a (click)="bookReturn()" target="_blank" class="book-nowbtn text-decoration-none">Book now</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
            </div>
          </div>

        </div>
        <div class="modal-body Mbdy-edit">
          <nav>
            <div class="nav nav-tabs edit-pillss" id="nav-tab" role="tablist">
              <button class="nav-link active flight-PillBtns" id="nav-flight-tab" data-bs-toggle="tab"
                data-bs-target="#nav-flight" type="button" role="tab" aria-controls="nav-flight" aria-selected="true">
                FLIGHT DETAILS
              </button>
              <button class="nav-link flight-PillBtns" id="nav-fare-tab" data-bs-toggle="tab" data-bs-target="#nav-fare"
                type="button" role="tab" aria-controls="nav-fare" aria-selected="false">
                FARE SUMMARY
              </button>
              <!-- <button class="nav-link flight-PillBtns" id="nav-summary-tab" data-bs-toggle="tab"
                data-bs-target="#nav-summary" type="button" role="tab" aria-controls="nav-summary"
                aria-selected="false">
                CANCELATION
              </button>
              <button class="nav-link flight-PillBtns" id="nav-date-tab" data-bs-toggle="tab" data-bs-target="#nav-date"
                type="button" role="tab" aria-controls="nav-date" aria-selected="false">
                DATE CHANGE
              </button> -->

            </div>
          </nav>
          <div class="tab-content btn-content" id="nav-tabContent">
            <div class="tab-pane btn-Content_inside fade show active" id="nav-flight" role="tabpanel"
              aria-labelledby="nav-flight-tab">
              <button type="button" #closeTwoFlight style="position: absolute; opacity: 0;" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button>
              <div class="row">
                <div *ngIf="selectOne?.Segments && selectOne.Segments.length > 0" class="col-md-6">
                  <div *ngFor="let org of selectOne?.Segments[0]" class="flight-dtail">
                    <ul class="dtail-content-below list-unstyled d-flex">
                      <li class="left-contentBox">
                        <!-- <div class="midTimesss">
                      <p class="midTimesssDuration" *ngIf="org?.GroundTime !== '00:00:00'"> Layover: {{org?.GroundTime}} </p>
                      <p class="midTimesssDuration" *ngIf="org?.GroundTime === '00:00:00'"> No Waiting </p>
                    </div> -->
                        <p class="destination-detail">
                          {{org?.Origin?.CityName}} to {{org?.Destination?.CityName}} ,{{org?.ArrivalTime |
                          date:'mediumDate' }}
                        </p>
                        <div class="lisst-body">
                          <h2 class="flight_name">
                            <img class="f-icn"
                              src="./../../../../assets/images/FLight-logos/airlineLogo/{{org.AirlineDetails?.AirlineCode}}.gif">
                            {{org?.AirlineDetails?.AirlineName}} <span
                              class="flight-no">{{org?.AirlineDetails?.AirlineCode}} |
                              {{org?.AirlineDetails?.FlightNumber}}</span>
                          </h2>
                          <ul class="Ftdata-content list-unstyled d-flex">
                            <li class="ftData_Below">
                              <h1 class="FtTime">
                                {{org?.DepartureTime | date: 'HH:mm' : 'en-US'}}
                              </h1>
                              <p class="FtDate-Month">
                                {{org?.DepartureTime | date: 'mediumDate'}}
                              </p>
                              <p class="Ftlocation">
                                Terminal : {{org?.Origin?.Terminal}} <br> {{org?.Origin?.CityName}},
                                {{org?.Origin?.CountryName}}
                              </p>
                            </li>
                            <li class="ftData_Below">
                              <p class="timsFlightss">

                                {{org?.Duration}}
                              </p>
                            </li>
                            <li class="ftData_Below">
                              <h1 class="FtTime">
                                {{org?.ArrivalTime | date: 'HH:mm' : 'en-US'}}
                              </h1>
                              <p class="FtDate-Month">
                                {{org?.ArrivalTime | date: 'mediumDate'}}
                              </p>
                              <p class="Ftlocation">
                                Terminal : {{org?.Destination?.Terminal}} <br> {{org?.Destination?.CityName}},
                                {{org?.Destination?.CountryName}}
                              </p>

                            </li>

                          </ul>
                          <ul class="Ftdata-content list-unstyled d-flex">
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                BAGGAGE:
                              </h1>
                              <p class="Ftweight">
                                Adult
                              </p>

                            </li>
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                CHECK IN
                              </h1>
                              <p class="Ftweight">
                                {{org?.IncludedBaggage}} <br>(1 piece only)
                              </p>

                            </li>
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                CABIN
                              </h1>
                              <p class="Ftweight">
                                {{org?.CabinBaggage}} <br>(1 piece only)
                              </p>


                            </li>
                          </ul>
                        </div>
                      </li>


                    </ul>

                  </div>
                </div>
                <div *ngIf="selectedDeparture?.Segments && selectedDeparture.Segments.length > 0" class="col-md-6">
                  <div *ngFor="let dep of selectedDeparture?.Segments[0]" class="flight-dtail">
                    <ul class="dtail-content-below list-unstyled d-flex">
                      <li class="left-contentBox">
                        <!-- <div class="midTimesss">
                          <p class="midTimesssDuration" *ngIf="dep?.GroundTime !== '00:00:00'"> Layover: {{dep?.GroundTime}} </p>
                        </div> -->
                        <p class="destination-detail">
                          {{dep?.Origin?.CityName}} to {{dep?.Destination?.CityName}} ,{{dep?.ArrivalTime |
                          date:'mediumDate' }}
                        </p>
                        <div class="lisst-body">
                          <h2 class="flight_name">
                            <img class="f-icn"
                              src="./../../../../assets/images/FLight-logos/airlineLogo/{{dep.AirlineDetails?.AirlineCode}}.gif">
                            {{dep?.AirlineDetails?.AirlineName}} <span
                              class="flight-no">{{dep?.AirlineDetails?.AirlineCode}} |
                              {{dep?.AirlineDetails?.FlightNumber}}</span>
                          </h2>
                          <ul class="Ftdata-content list-unstyled d-flex">
                            <li class="ftData_Below">
                              <h1 class="FtTime">
                                {{dep?.DepartureTime | date: 'HH:mm' : 'en-US'}}
                              </h1>
                              <p class="FtDate-Month">
                                {{dep?.DepartureTime | date: 'mediumDate'}}
                              </p>
                              <p class="Ftlocation">
                                Terminal : {{dep?.Origin?.Terminal}} <br> {{dep?.Origin?.CityName}},
                                {{dep?.Origin?.CountryName}}
                              </p>
                            </li>
                            <li class="ftData_Below">
                              <p class="timsFlightss">
                                {{dep?.Duration}}
                              </p>
                            </li>
                            <li class="ftData_Below">
                              <h1 class="FtTime">
                                {{dep?.ArrivalTime | date: 'HH:mm' : 'en-US'}}
                              </h1>
                              <p class="FtDate-Month">
                                {{dep?.ArrivalTime | date: 'mediumDate'}}
                              </p>
                              <p class="Ftlocation">
                                Terminal : {{dep?.Destination?.Terminal}} <br> {{dep?.Destination?.CityName}},
                                {{dep?.Destination?.CountryName}}
                              </p>

                            </li>

                          </ul>
                          <ul class="Ftdata-content list-unstyled d-flex">
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                BAGGAGE:
                              </h1>
                              <p class="Ftweight">
                                Adult
                              </p>

                            </li>
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                CHECK IN
                              </h1>
                              <p class="Ftweight">
                                {{dep?.IncludedBaggage}} <br>(1 piece only)
                              </p>

                            </li>
                            <li class="ftData_Below">
                              <h1 class="Ft2Time">
                                CABIN
                              </h1>
                              <p class="Ftweight">
                                {{dep?.CabinBaggage}} <br>(1 piece only)
                              </p>


                            </li>
                          </ul>
                        </div>
                      </li>


                    </ul>

                  </div>
                </div>
              </div>

            </div>
            <div class="tab-pane btn-Content_inside fade" id="nav-fare" role="tabpanel" aria-labelledby="nav-fare-tab">
              <div class="fare-SUMary">
                <p class="fare-brkup">Fare breakup</p>
                <div style="display: flex; justify-content: space-between;">
                  <ul class="fareprices list-unstyled mb-0">
                    <li class="fare-dTop">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="totalprice-list">TOTAL</li>
                        <li class="totalprice-list">{{OrgFare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{OrgFare?.AgentPreferredCurrency }} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </li>
                    <li class="fare-dTop">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="fareprice-list">Base Fare</li>
                        <li class="fareprice-list">{{OrgFare?.BaseFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{OrgFare?.AgentPreferredCurrency }} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </li>
                    <li class="fare-dTop mb-0">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="fareprice-list">Surcharges</li>
                        <li class="fareprice-list">{{OrgFare?.ServiceFee + OrgFare?.Tax + OrgFare?.PenaltyAmount  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          {{selectedCurrency}}
                          <!-- {{OrgFare?.AgentPreferredCurrency}} -->
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="fareprices list-unstyled mb-0">
                    <li class="fare-dTop">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="totalprice-list">TOTAL</li>
                        <li class="totalprice-list">{{DeptFare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{DeptFare?.AgentPreferredCurrency }} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </li>
                    <li class="fare-dTop">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="fareprice-list">Base Fare</li>
                        <li class="fareprice-list">{{DeptFare?.BaseFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{DeptFare?.AgentPreferredCurrency }} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </li>
                    <li class="fare-dTop mb-0">
                      <ul class="fare-Btom list-unstyled d-flex">
                        <li class="fareprice-list">Surcharges</li>
                        <li class="fareprice-list">{{DeptFare?.ServiceFee + DeptFare?.Tax + DeptFare?.PenaltyAmount | priceExchange:selectedCurrency | async   | number:'1.0-2' }}
                          <!-- {{DeptFare?.AgentPreferredCurrency}} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
            <div class="tab-pane btn-Content_inside fade" id="nav-summary" role="tabpanel"
              aria-labelledby="nav-summary-tab">
              ..ndb.
            </div>
            <div class="tab-pane btn-Content_inside fade" id="nav-date" role="tabpanel" aria-labelledby="nav-date-tab">
              ..jhbbjh.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>



<a style="opacity: 0;" #CloseOpenLogin class="nav-link nav-link3 logins-link" data-bs-toggle="modal"
  data-bs-target="#exampleModal33"><button class="btlog">Log In</button></a>

<div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
      </div> -->
      <button type="button" #CloseModels22 style="display: none;" class="btn-close" data-bs-dismiss="modal"
        aria-label="Close"></button>

      <div class="modal-body">

        <div class="login-component">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div *ngIf="!requestForOtp" class="loginsection">

                  <p class="textsign">Sign in or create an account</p>
                  <form [formGroup]="loginform" (ngSubmit)="loginSubmit()">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label conta-text">Contact
                        number</label>
                      <ngx-intl-tel-input [enablePlaceholder]="true" customPlaceholder="Mobile Number"
                        formControlName="mobile"
                        [ngClass]="{ 'is-invalid': loginform.controls.mobile.invalid && loginform.controls.mobile.touched }"
                        [preferredCountries]="['ae']"></ngx-intl-tel-input>
                      <div class="invalid-feedback"
                        *ngIf="loginform.controls.mobile.invalid && loginform.controls.mobile.touched">
                        Mobile number is required.
                      </div>
                    </div>

                    <button type="submit" class="btlog">Continue</button>
                  </form>

                  <!-- <div class="option-data">
                                      <hr class="line-data">
                                      <p class="para-data"><span class="option-paras">or use one of these
                                              options</span></p>
                                  </div>

                                  <div class="social-info">
                                      <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                                      <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                                  </div> -->

                  <p class="para-content">By signing in or creating an account, you agree with our
                    <span class="terms" routerLink="/privacyPolicy">Terms & Conditions</span> and <span
                      routerLink="/privacyPolicy" class="terms">Privacy
                      Statement</span>
                  </p>

                </div>
                <div *ngIf="requestForOtp" class="loginsection">

                  <p class="textsign">Enter OTP</p>
                  <form [formGroup]="loginOtp" (ngSubmit)="submit()">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label conta-text">We sent OTP to
                        your contact number.</label>
                      <div class="mb-3">
                        <input placeholder="Enter OTP"
                          [ngClass]="{ 'is-invalid': loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched }"
                          type="email" formControlName="otp" class="form-control otpFOrm" id="exampleInputEmail1"
                          aria-describedby="emailHelp">
                        <div class="invalid-feedback"
                          *ngIf="loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched">
                          OTP is required.
                        </div>
                      </div>
                      <div class="invalid-feedback"
                        *ngIf="loginform.controls.mobile.invalid && loginform.controls.mobile.touched">
                        Mobile number is required.
                      </div>
                      <!-- <span class="onSubmit()">resend Otp</span> -->

                    </div>

                    <button type="submit" class="btlog">Continue</button>
                  </form>

                  <!-- <div class="option-data">
                                      <hr class="line-data">
                                      <p class="para-data"><span class="option-paras">or use one of these
                                              options</span></p>
                                  </div>

                                  <div class="social-info">
                                      <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                                      <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                                  </div> -->

                  <p class="para-content">By signing in or creating an account, you agree with our
                    <span routerLink="/privacyPolicy" class="terms" routerLink="/privacyPolicy">Terms &
                      Conditions</span> and <span class="terms">Privacy
                      Statement</span>
                  </p>

                </div>
              </div>
            </div>


          </div>
        </div>




      </div>

    </div>
  </div>
</div>



<!-- <button (click)="loadmore()">Loadmore</button> -->