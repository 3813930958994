<div id="visaPage-edit" class="visaPage-edit">
  <div class="">
    <div class="visa-data">
      <div class="container">
        <div class="row d-flex justify-content-between">
          <div class="col-md-10">
            <div class="leftData-section">

              <h1 class="appHeading-edit">{{countryData?.title}}</h1>
              <p class="apply-edit">
                {{countryData?.description}}
              </p>
            </div>
          </div>
          <div class="col-md-2">
            <div class="imageEdit">
              <img class="country-image" [src]="countryData?.flag" alt="no-image" />
            </div>
          </div>
        </div>
        <div class="UserBox-section">
          <h3 class="callNow-edit">What are you waiting for? Call now!</h3>
          
          <div class="userContent">
            <ul *ngFor="let contact of contactList" class="userBelow-data list-unstyled">
              <li class="leftUser-data">
                <span class="icon-edit"><i class="far fa-user"></i></span>
              </li>
              <li class="rightUser-data">
                <ul (click)="makePhoneCall(contact?.number)" class="rightData-edit list-unstyled mb-2 mb-sm-0">
                  <li  class="numers-edit">{{contact?.number}}</li>
                  <li class="names-edit">{{contact?.name}}</li>
                </ul>
              </li>
              
            </ul>
         
          </div>
            
        
    
          <!-- Livewire Component wire-end:740OBDlDukoysA6Y0z7i -->
        </div>
      </div>
   
    </div>

    <!-- <div class="heading-section block sm:flex justify-between mt-12">
      

      
    </div> -->


  </div>
</div>

<div class="tourist-section">
  <div class="container">
<div  [innerHTML]="countryData?.description2" ></div>
    <!-- <p class="touristBlog-content">{{countryData?.description2}}</p> -->
  </div>
</div>