import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/services/auth.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-summury',
  templateUrl: './summury.component.html',
  styleUrls: ['./summury.component.css']
})
export class SummuryComponent implements OnInit, OnDestroy {
  @ViewChild('contentPPPPF', { static: true }) contentPPPPF: ElementRef;
  oneWayPNR: any
  returnWayPNR: any
  returnStatus: any
  data: any
  isReturn = localStorage.getItem('myWay')
  fareRuleDetail: string = '';
  pdfOff: boolean = false
  passingers: any = []
  segments: any = []
  fareBreak: any = []
  id: any
  status: any
  printDetails: any = []
  oneWayOrigin: any
  oneWayDestination: any
  twoWayOrigin: any
  twoWayDestination: any
  data2: any
  printDetails2: any = []
  status2: any
  passingers2: any = []
  segments2: any = []

  totalBaseFare = 0;
  totalTax = 0;
  serviceFee = 0
  totalFee = 0
  Currency: any

  totalBaseFare2 = 0;
  totalTax2 = 0;
  serviceFee2 = 0
  totalFee2 = 0
  Currency2: any
  constructor(private service: AuthService, private route: ActivatedRoute, private ngxService: NgxUiLoaderService, private http: HttpClient, private router: Router) { }
  ngOnDestroy(): void {
    // localStorage.removeItem('newToken'),
    //   localStorage.removeItem('newPNR')
    //  localStorage.removeItem('newPNR2')
  }
  ngOnInit(): void {
    // this.ngxService.start()
    let oneWayData = []
    let twoWayData = []

    this.route.params.subscribe(
      (data: any) => {

        oneWayData = JSON.parse(data.data)
      }
    )


    let newBoj = {
      // TokenId: localStorage.getItem('newToken'),
      // PNR: localStorage.getItem('newPNR')
    }
    let newBoj2 = {
      TokenId: localStorage.getItem('newToken'),
      PNR: localStorage.getItem('newPNR2')
    }
    this.service.GetBookingDetails(oneWayData[0]).subscribe(
      (data: any) => {
         
        if (data.data.Status == 0) {
          this.service.error('Ticket Details Not Found')
          this.router.navigateByUrl('/bookings')
          return
        }
        if (data.data.state == 100) {
          this.service.error(data.data.message)
          this.router.navigateByUrl('/bookings')
          return
        }


        this.data = data
        this.printDetails = data.data?.Itinerary
        this.status = data.data.Status

        this.oneWayOrigin = data.data?.Itinerary?.Origin
        this.oneWayDestination = data?.data?.Itinerary?.Destination


        // this.fareRuleDetail = data.data.Itinerary.FareRules[1].FareRuleDetail
        this.passingers = data.data.Itinerary?.Passenger
        this.segments = data.data.Itinerary?.Segments
         
        this.oneWayPNR = data.data.Itinerary?.PNR
        this.ngxService.stop()



        this.passingers?.forEach((passengerData) => {
          this.totalBaseFare += passengerData.Fare.BaseFare;
          this.totalTax += passengerData.Fare.Tax;
          this.serviceFee += passengerData.Fare.ServiceFee;
          this.totalFee += passengerData.Fare.TotalFare;
          this.Currency = passengerData.Fare.AgentPreferredCurrency;
        });
        // alert('data is ready')
      },

    )
    if (oneWayData[1]) {
      this.service.GetBookingDetails(oneWayData[1]).subscribe(
        (data: any) => {

          if (data.data.Status == 0) {
            this.service.error('Ticket Details Not Found')
            this.router.navigateByUrl('/bookings')
            return
          }

          this.data2 = data
          this.printDetails2 = data.data.Itinerary
          this.status2 = data.data.Status
          this.twoWayOrigin = data.data.Itinerary.Origin
          this.twoWayDestination = data.data.Itinerary.Destination

          // this.fareRuleDetail = data.data.Itinerary.FareRules[1].FareRuleDetail
          this.passingers2 = data.data.Itinerary.Passenger
          this.segments2 = data.data.Itinerary.Segments
           
          this.returnStatus = data.data.Status
          this.returnWayPNR = data.data?.Itinerary?.PNR
          if (data.data?.Itinerary?.PNR) {
           
          }
          this.ngxService.stop()

          this.passingers2.forEach((passengerData) => {
            this.totalBaseFare2 += passengerData.Fare.BaseFare;
            this.totalTax2 += passengerData.Fare.Tax;
            this.serviceFee2 += passengerData.Fare.ServiceFee;
            this.totalFee2 += passengerData.Fare.TotalFare;
            this.Currency2 = passengerData.Fare.AgentPreferredCurrency;
          });
          // alert('data is ready')
        },

      )
    }


  }
  // generatePDF() {

  //   const pdf = new jsPDF();

  //   const options = {
  //     logging: true,
  //     useCORS: true,
  //     allowTaint: true,
  //     scale: 2,
  //   };

  //   html2canvas(this.contentPPPPF.nativeElement, options).then((canvas) => {
  //     setTimeout(() => {
  //       const contentDataURL = canvas.toDataURL('image/png');
  //       pdf.addImage(contentDataURL, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), 0, '', 'FAST');
  //       pdf.save('Devotion FlightTicket.pdf');

  //     }, 2000);
  //   });
  // }

  DirectDownloadgenerateReport() {
    this.service.DirectDownloadgenerateReport(this.data).subscribe(
      (data: any) => {
        this.service.success(data.message)
      },
      (err) => {
        this.service.error(err.error.error)
      }
    )
  }
  downloadPDF() {
    this.downloadPDFs()
    // this.service.downloadPDF(this.data).subscribe(
    //   (data:any)=>{
    //     this.service.success(data.message)
    //   },
    //   (err)=>{
    //     this.service.error(err.error.error)
    //   }
    // )
  }
  downloadPDFs() {
      
    this.service.downloadPDFs(this.data.data)
      .subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'ticket.pdf';
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      });

    if (this.data2.data) {
      this.service.downloadPDFs(this.data2.data)
        .subscribe((response: Blob) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'Return ticket.pdf';
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        });
    }
  }




  downloadPDFTicket() {
    // Replace 'flightData' with the actual data you want to pass to the backend
    const flightData = {
      // Your data properties here...
    };

    this.service.downloadPDFTicket(this.data).subscribe(
      (pdfBlob: Blob) => {
        // Create a Blob URL for the PDF data
        const pdfUrl = URL.createObjectURL(pdfBlob);
        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Ticket.pdf';
        document.body.appendChild(link);
        link.click();
        // Clean up the Blob URL after the download
        URL.revokeObjectURL(pdfUrl);
      },
      (error) => {
        console.error('Error downloading PDF:', error);
        // Handle the error, e.g., show an error message to the user
      }
    );
  }

  emailToPdfFlight() {
    this.service.
      emailToPdfFlight(this.data.data).subscribe(
        (data: any) => {
          this.service.success(data.message)
        },
        (err) => {

          this.service.error(err.error.message)
        }
      )
  }


  async generateInvoice() {
    let details = {
      id: this.oneWayPNR
    }
     
    let ticketDetails: any = await this.service.getTicketByPNR(details).toPromise()
    let inputId = {
      id: ticketDetails.ticketDetails[0]._id
    }
    this.service.generateInvoice(inputId).subscribe(
      (responce: any) => {
        this.service.success(responce.message)
      }
    )
  }


  async downloadInvoice() {
    let details = {
      id: this.oneWayPNR
    }
     
    let ticketDetails: any = await this.service.getTicketByPNR(details).toPromise()
    let inputId = {
      id: ticketDetails.ticketDetails[0]._id
    }
 

    this.service.downloadInvoice(inputId)
    .subscribe((response: Blob) => {
      // Check if the response is a valid Blob
      if (response instanceof Blob) {
        const blob = new Blob([response], { type: 'application/pdf' });
  
        // Create a blob URL for the PDF
        const url = window.URL.createObjectURL(blob);
  
        // Create an anchor element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'invoice.pdf'; // Set the desired filename
  
        // Programmatically trigger the download
        link.click();
  
        // Clean up resources
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Invalid response type. Expected a Blob.');
      }
    },
    error => {
      console.error('Error downloading the PDF:', error);
    });



  }


  async generateReturnInvoice() {
    if (this.returnWayPNR) {
      let details = {
        id: this.returnWayPNR
      }
       
      let ticketDetails:any =  await this.service.getTicketByPNR(details).toPromise()
      let inputId ={
        id: ticketDetails.ticketDetails[0]._id
      }
      this.service.generateInvoice(inputId).subscribe(
        (responce:any)=>{
          this.service.success(responce.message)
        }
      )
    }

  }

}
