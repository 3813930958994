<div class="viewAllOffers">
    <div  class="container">
        <h1 class="ofrInfo">Global Offers</h1>
        
        <div  class="row mb-4">
            <div *ngFor="let item of allGlobalOffers" class="col-md-4 ">
                <div class="offerInfo">
                    <div routerLink="/offerPreview" class="item">
                        <div (click)="navigate(item._id)" class="client-box">
                            <img class="boxim" [src]="item.image" alt="no-image">
                            <div class="boxtext-sec">
                                <h6 class="boxtext">{{item.title}}</h6>
                                <p class="boxtext2">{{item.description | slice : 0:160}}..</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      


        </div>
   

    </div>

</div>