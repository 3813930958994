import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-mybookings',
  templateUrl: './mybookings.component.html',
  styleUrls: ['./mybookings.component.css'],
})
export class MybookingsComponent implements OnInit {
  myFlightBooking: any = []
  myPackageBookingsSuccess:any = []
  completedBookings:any = []
  CompleteduserVisa:any = []
  myVisaBookingsSuccess:any = []
  myPackageBookingsFailure:any = []
  myVisaBookingsFailure:any = []
  myFailureFlightBooking: any = []
  isFlightSection:boolean = true
  isPackagesSection:boolean = false
  isVisaSection:boolean = false
  constructor(private service: AuthService, private router: Router) {

  }
  ngOnInit(): void {
    this.myBooking()
    this.getAllFailureTickets()
    this.getAllPackageBooking()
  }

  myBooking() {
    this.service.myBooking().subscribe(
      (data: any) => {
        let myFlightBooking = []
        let myFailureFlightBooking = []
        this.myFlightBooking = []


        data?.forEach((value: any) => {

          if (value?.result && value.result?.Status !== 0 && value.result?.Status !== null && value?.state === "CAPTURED") {
            myFlightBooking?.push(value);
            this.myFlightBooking = myFlightBooking.reverse();
          }
          else if (value.result?.PNR === '-' && value.result?.Errors?.length > 0) {
            myFailureFlightBooking?.push(value);
          }
          else {
            myFailureFlightBooking?.push(value);
            this.myFailureFlightBooking = myFailureFlightBooking.reverse();

          }

        })


      }
    )
  }
  getAllFailureTickets() {
    this.service.getAllFailureTickets().subscribe(
      (data: any) => {


        // this.myFailureFlightBooking = data.data.reverse()
      }
    )
  }
  getStatus(data: any) {
    let successfulBookings = {
      TokenId: data.result.TokenId,
      PNR: data.result.PNR
    }
    let dataSummy = []
    dataSummy.push(successfulBookings)

    this.router.navigate(['/summury', JSON.stringify(dataSummy)])

  }

  generatePDF(data) {

    let dataObj = {
      id: data._id
    }
    this.service.GetPDFwithID(dataObj).subscribe(
      (info: any) => {
        this.service.success(info.message)
      },
      (err) => {

        this.service.error(err.error.error)
      }
    )
  }

  selectTab(e) {
    if(e.target.value === 'Flight'){
      this.isFlightSection = true
      this.isPackagesSection = false
      this.isVisaSection = false
    }
    if(e.target.value === 'Package'){
      this.isFlightSection = false
      this.isPackagesSection = true
      this.isVisaSection = false
    }
    if(e.target.value === 'Visa'){
      this.isFlightSection = false
      this.isPackagesSection = false
      this.isVisaSection = true
    }

  }

  getAllPackageBooking(){
    this.service.getAllPackageBooking().subscribe(
      (data:any)=>{
          
        this.myPackageBookingsSuccess = data.PackagesPaymentSuccess.reverse()
        this.completedBookings = data.completedPackages.reverse()
        this.CompleteduserVisa = data.CompleteduserVisa.reverse()
        this.myPackageBookingsFailure = data.failurePackages.reverse()
        this.myVisaBookingsSuccess = data.VisaPaymentSuccess.reverse()
        this.myVisaBookingsFailure = data.failureVisa.reverse()
      }
    )
  }

  visaBookingVIew(id){
    this.router.navigateByUrl(`/visaBookingVIew/${id}`)
  }
  packageBookingVIew(id){
    this.router.navigateByUrl(`/packageBookingVIew/${id}`)
  }
}
