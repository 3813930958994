import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { packagesEnquiry } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-package-book',
  templateUrl: './package-book.component.html',
  styleUrls: ['./package-book.component.css']
})
export class PackageBookComponent {
  separateDialCode = false;
  selectedCurrency:any
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  selectedCity: any = 'Delhi';
  travelDate:any 
  formLoader:boolean = false
  formSubmitted = false;
  selectTravelDate:any
  passportCopyuploaignSTart: boolean = false
  PhotoCopyuploaignSTart: boolean = false
  activeTabIndex: number = 0;
  packageId: any
 
  allMobileFlags: any = []
  visaDetails: any = []
  submitted = false;
  visaPassingerCount: number = 1
  sectionDetails: any = []
  allthePackages: any = []
  hotDeals: any = []
  onlyFee: number
  baseFare: number
  localDeals: any = []
  geFeeDetails: any
  totalPrice: number
  FinalPrice: number
  allContries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia",
    "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
    "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi",
    "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
    "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia", "Denmark", "Djibouti", "Dominica",
    "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini",
    "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
    "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati",
    "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia",
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
    "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger",
    "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea",
    "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia",
    "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia",
    "South Africa", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan",
    "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey",
    "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay",
    "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ];
  travelDates: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  travelMonths: any = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ]


  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {
        let allData = []
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }

  getPackageByID() {
    this.service.getPackageByID(this.packageId).subscribe(
      (data: any) => {
        this.packageData = data.packageData[0]
        this.totalPrice = data.packageData[0].price
         
        this.FinalPrice  = this.totalPrice * this.packageData.touristCount
        this.membetsCount  = this.packageData.touristCount
        for (let i = 1; i < this.packageData.touristCount; i++) {
          this.addApplicant()
        }
      }
    )
  }

  travelYears = []
  packageData: any = []
  safePrice: number
  visaDetailsList: any
  birthYears = []
  membetsCount:number;
  passportExpYear = []
  visitporpuse = ["business", "holiday", "official"]
  globalDeals: any = []
  applicantForm: FormGroup;
  packagesEnquiryObj = new packagesEnquiry()
  @ViewChild('myInput') inputElement: ElementRef;
  genders = ['Male', 'Female', 'Other']
  minDate: any
  citynames: any = [
    { name: "Delhi" },
    { name: "Mumbai" },
    { name: "Bengalure" },
    { name: "Pune" },
    { name: "Goa" },
    { name: "Chennai" },
    { name: "Jaipur" },
    { name: "Hyderabad" },
    { name: "Singapore" },
  ]
  constructor(private service: AuthService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    let travelDate =JSON.parse(localStorage.getItem('selectdDate'))
    this.travelDate = travelDate
    this.CheckCurrency()
  }

  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }

  getCurrentYear() {

    var currentYear = new Date().getFullYear();
    var birthYears = [];

    for (var year = 1960; year <= currentYear; year++) {
      birthYears.push(String(year));
    }
    this.birthYears = birthYears
  }

  getFeatureYears() {
    var currentYear = new Date().getFullYear();
    var futureYears = [];

    for (var year = currentYear; year <= currentYear + 5; year++) {
      futureYears.push(String(year));
    }
    this.travelYears = futureYears
  }

  getFeatureYears60() {
    var currentYear = new Date().getFullYear();
    var futureYears = [];

    for (var year = currentYear; year <= currentYear + 60; year++) {
      futureYears.push(String(year));
    }
    this.passportExpYear = futureYears
  }
  ngOnInit(): void {
    this.route.params.subscribe(
      (data: any) => {
        this.packageId = data.id
        this.getCurrentYear()
        this.getFeatureYears()
        this.getFeatureYears60()
        this.getPackageByID()
        this.getCountryFlags()

      }
    )

    window.scrollTo(0, 0);
    this.getSubVisaId()
    this.loadScripts()


    this.applicantForm = this.formBuilder.group({
      applicants: this.formBuilder.array([this.createApplicant()]),
    });
  }

  createApplicant(): FormGroup {
    return this.formBuilder.group({
      personalDetails: this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        nationality: ['', Validators.required],
        birthDay: ['', Validators.required],
        birthMonth: ['', Validators.required],
        birthYear: ['', Validators.required],
        gender: ['', Validators.required],
        passportNumber: ['', Validators.required],
        mobileCountryCode: ['', Validators.required],
        contactNumber: ['', Validators.required],
        emailId: ['', Validators.required],
      }),
    });
  }

  onSubmit() {

    let Access = this.service.isLoggedin()
    
    if (Access === true) {
      this.formSubmitted = true;
      if (this.applicantForm.valid) {
        this.applicantForm.value.packageId = this.packageId
        this.applicantForm.value.packageStartDate = JSON.parse(localStorage.getItem('selectdDate'))
        // this.applicantForm.value.applicants.forEach((value:any,i)=>{
        //   this.applicantForm.value.applicants[i].personalDetails.mobileCountryCode = value.personalDetails.Contact.dialCode
        //   this.applicantForm.value.applicants[i].personalDetails.contactNumber = value.personalDetails.Contact.number
        // })
        this.formLoader = true
         
        this.service.getPayForPackage(this.applicantForm.value).subscribe(
          (data:any)=>{
            localStorage.setItem('RF', data.rf)
            window.location.href = data.message._links.payment.href
          },
          (err)=>{
            this.formLoader = false
            this.service.error(err.error.message)
          }
        )
      }
      else{
        // this.service.error('all fileds required')
      }
    }
    else {
      this.service.error('login required')
    }

  }
  isInvalid(arrayName: string, index: number, groupName: string, controlName: string): boolean {
    const control = this.applicantForm.get(arrayName).get(index.toString()).get(groupName).get(controlName);
    return control.invalid && control.touched;
  }

  shouldShowError(arrayName: string, index: number, groupName: string, controlName: string): boolean {
    const control = this.applicantForm.get(arrayName).get(index.toString()).get(groupName).get(controlName);
    return control.invalid && (control.touched || this.formSubmitted);
  }
  addApplicant() {
     
    const applicants = this.applicantForm.get('applicants') as FormArray;
    applicants.push(this.createApplicant());
    // alert(applicants.value.length)
    let sendData = JSON.parse(localStorage.getItem('selectedVisa'))
    this.membetsCount += 1
    this.geFeeDetails = sendData?.adSerices

    this.visaPassingerCount = applicants.length
    let price = this.totalPrice * applicants.length

    this.FinalPrice = price
    // this.baseFare =  this.onlyFee  * applicants.length
    // this.totalPrice = this.safePrice * applicants.length
    // for (let i = 0; i < this.geFeeDetails.length; i++) {
    //   this.geFeeDetails[i].price = this.geFeeDetails[i].price * applicants.length;
    // }

  }

  removeApplicant(index: number) {
     
    const applicants = this.applicantForm.get('applicants') as FormArray;
    applicants.removeAt(index);
    // alert(applicants.value.length)
    // this.totalPrice =  this.totalPrice * applicants.length
    this.visaPassingerCount = applicants.length
    let sendData = JSON.parse(localStorage.getItem('selectedVisa'))
    this.membetsCount -= 1
    this.baseFare = this.onlyFee * applicants.length
    // this.totalPrice =  this.safePrice * applicants.length
    let price = this.totalPrice * applicants.length
  
    this.FinalPrice = price
    this.geFeeDetails = sendData.adSerices
    for (let i = 0; i < this.geFeeDetails.length; i++) {
      // Multiply the count with the price for each object
      this.geFeeDetails[i].price = this.geFeeDetails[i].price * applicants.length;
    }

  }

  selectCitySearch(data: any) {

    this.citynames.filter((value) => {

      if (value.name === data.target.value) {
        this.citynames = value
      }
    })
  }
  selectedCityname(data: any) {
    this.selectedCity = data
    this.packagesEnquiryObj.PackageName = data
  }

  selectFliter() {

    setTimeout(() => {
      this.inputElement.nativeElement.focus()
    }, 100)

  }

  async submitData() {
    this.packagesEnquiryObj.packageDate = this.minDate
    if (!this.packagesEnquiryObj.AdultsCount) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.PackageName) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.packageDate) {
      this.service.error('All fieids are required')
      return
    }
    else {
      let access: any = this.service.isLoggedin()
      if (access) {
        try {
          let userSave: any = await this.service.Packagesenquiry(this.packagesEnquiryObj).toPromise()

          this.service.success(userSave.message)
          this.packagesEnquiryObj.AdultsCount = ''
          this.packagesEnquiryObj.PackageName = ''
          this.packagesEnquiryObj.packageDate = ''
          this.minDate = ''
        } catch (err) {
          this.service.error(err.error.message)
        }
      } else {
        // this.service.error('Please login')
        this.service.tryLogin('PleaseLogin')
      }
    }



  }


  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }


  routeTOView(data: any) {
    this.router.navigateByUrl(`/packageView/${data.id}`)
  }


  setActiveTab(index: number, item: any): void {
    this.activeTabIndex = index;
    this.sectionDetails = item
  }

  navigate() {
    this.router.navigate(['/dashboard/editVisa', this.packageId])
  }


  dataArray = []
  dataArray2 = []
  replaceValuesAtIndex(index, newValuePassport) {

    this.dataArray[index] = newValuePassport;
    this.applicantForm.value.applicants[index].personalDetails.scannedPassportCopy = newValuePassport
    this.passportCopyuploaignSTart = false
    // this.dataArray[index].Photocopy = newValuePhotocopy;
  }
  replaceValuesAtIndex2(index, newValuePassport) {

    this.dataArray2[index] = newValuePassport;
    this.applicantForm.value.applicants[index].personalDetails.scannedphoto = newValuePassport
    this.PhotoCopyuploaignSTart = false
    // this.dataArray[index].Photocopy = newValuePhotocopy;
  }



  selectFile(index, e: any) {

    this.passportCopyuploaignSTart = true
    let formData = new FormData();
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      formData.append('userImages', e.target.files[0]);
      this.service.UseruploadVisaFlag(formData).subscribe(
        (data: any) => {
          this.replaceValuesAtIndex(index, data.imagePath)
        }
      )
    }
  }
  selectFile2(index, e: any) {

    this.PhotoCopyuploaignSTart = true
    let formData = new FormData();
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      formData.append('userImages', e.target.files[0]);
      this.service.UseruploadVisaFlag(formData).subscribe(
        (data: any) => {
          this.replaceValuesAtIndex2(index, data.imagePath)
        }
      )
    }
  }

  getSubVisaId() {

    let sendData = JSON.parse(localStorage.getItem('selectedVisa'))

    if (sendData?.adSerices) {
      this.geFeeDetails = sendData?.adSerices
    }


  }


}
