<div class="googleReviews">
    <div class="textBlockItems">


        <div class="textBlock">
            <h1 class="googleHead">Google Reviews</h1>
            <!-- <p>OUR DEVOTION IS ALL TOWARD PROVIDING OUR <br> CUSTOMERS WITH THE BEST TIME OF THEIR LIFE</p> -->
        </div>
        <div class="cardsSection">
            <div class="row">
                <div *ngFor="let item of revideItems" (click)="openMaps(item.redirectionLink)" class="col-md-3">
                    <div class="mainCardsList mb-4">
                        <div class="profileSection">
                            <img class="imagWidh" [src]="item.image" />
                            <div class="contentName">
                                <p class="nameItem">{{item.name}}</p>
                                <div *ngIf="item.rating === 1">
                                    <i class="fas fa-star startsList"></i>
                                </div>
                                <div *ngIf="item.rating === 2">
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                </div>
                                <div *ngIf="item.rating === 3">
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>

                                </div>
                                <div *ngIf="item.rating === 4">
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>


                                </div>
                                <div *ngIf="item.rating ===5">
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>
                                    <i class="fas fa-star startsList"></i>


                                </div>
                            </div>
                        </div>

                        <p class="descriptions">{{item.comment | slice:0:100}}...</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>