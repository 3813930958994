


<div class="flight_component">

  <div class="container">
    <button type="button" style="opacity: 0;" #opneFlight class="btn btn-primary" data-bs-toggle="modal"
    data-bs-target="#exampleModal">
    Launch demo modal
  </button>
    <div class="row justify-content-center">
      <!-- <div class="col-md-2">
        <div class="main-data2">

        </div>
      </div> -->
      <div class="col-md-10">

        <div data-aos="fade-right" *ngFor="let item of flightInfo; let i =index" class="main-data">
          <div class="row">
            <!-- {{segements[i] | json}} -->
            <div class="col-md-3">
              <div class="fromDetails">
                <p class="airlinesName">{{segements[i]?.airLine}}</p>
                <p class="fromData">{{segements[i]?.Origin}}</p>
                <h1 class="fromTIme">{{segements[i]?.startTime}}</h1>
              </div>
            </div>
            <div class="col-md-2">
              <div class="middleInfo">
                <p style="opacity: 0;" class="middleSTation">India</p>
                <p *ngIf="segements[i]?.MiddleOrigin" class="middleSTation">{{segements[i]?.MiddleOrigin}}</p>
                <p *ngIf="!segements[i]?.MiddleOrigin" class="middleSTation">Direct flight</p>
                <h1 class="midle-Time">{{segements[i]?.totalTime1}}</h1>
              </div>
            </div>
            <div class="col-md-3">
              <div class="ToDetails">
                <p style="opacity: 0;" class="airlinesName">Akasa Air</p>
                <p class="fromData">{{segements[i]?.Destination}}</p>
                <h1 class="fromTIme">{{segements[i]?.arraival}}</h1>
              </div>
            </div>
            <div class="col-md-2">
              <div class="ToDetails">
                <p style="opacity: 0;" class="airlinesName">Akasa Air</p>
                <p style="opacity: 0;" class="fromData">DEL New Delhi, India</p>
                <h1 class="fromTIme">{{item.Fare.TotalFare | number:'1.0-2'}} {{item.Fare.AgentPreferredCurrency }} </h1>
              </div>
            </div>
            <div class="col-md-2" style="display: flex;justify-content: center;align-items: center;">
              <div class="fairDetails">
                <button (click)="mainDiv()" class="fareBtn">VIEW FARE</button>
              </div>
            </div>
          </div>
          <div *ngIf="isDivActive" class="downBox">
            <h1 class="saver">SAVER</h1>
            <div class="row">
              <div class="col-md-6">
                <ul class="baggage">
                  <li class="cabin">Cabin baggage</li>
                  <li class="cabinKg"> 7 KGS </li>
                </ul>
                <ul class="baggage">
                  <li class="cabin">Check-in baggage</li>
                  <li class="cabinKg"> 7 KGS </li>
                </ul>
                <ul class="baggage">
                  <li class="cabin">Date change fee, per passenger</li>
                  <li class="cabinKg">Starting from ₹2850 </li>
                </ul>
                <ul class="baggage">
                  <li class="cabin">Cancellation fee, per passenger</li>
                  <li class="cabinKg">Starting from ₹2850 </li>
                </ul>
                <ul class="baggage">
                  <li class="cabin">Seat</li>
                  <li class="cabinKg">Paid seats only </li>
                </ul>
                <ul class="baggage">
                  <li class="cabin">Meal</li>
                  <li class="cabinKg">chargeable </li>
                </ul>
              </div>
              <div style="display: flex; justify-content: end;" class="col-md-6">
                <button (click)="getFlightInfo(item)" class="bookNow">Book</button>
              </div>
            </div>
          </div>

          <div *ngIf="isDivActive" class="fareBox">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne"  [id]="'heading'+i">
                  <button [ngClass]="{ 'collapsed': i != 0 }" [attr.data-bs-target]="'#collapse'+i"
                  class="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target=`{$i+1}` aria-expanded="true" aria-controls="collapseOne"
                  [attr.aria-controls]="'collapse'+i">
               
              </button>
                </h2>
                <div [id]="'collapse'+i" [ngClass]="{ 'show': i == 0 }"
                [attr.aria-labelledby]="'heading'+i" class="accordion-collapse collapse " aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation" [class.active]="activePill === 'pill1'" (click)="activePill = 'pill1'">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                          type="button" role="tab" aria-controls="home" aria-selected="true">FLIGHT INFORMATION</button>
                      </li>
                      <li class="nav-item" role="presentation" [class.active]="activePill === 'pill2'" (click)="activePill = 'pill2'">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                          type="button" role="tab" aria-controls="profile" aria-selected="false">FARE DETAILS</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                          type="button" role="tab" aria-controls="contact" aria-selected="false">BAGGAGE RULES</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="cancel-tab" data-bs-toggle="tab" data-bs-target="#cancel"
                          type="button" role="tab" aria-controls="cancel" aria-selected="false">CANCELLATION
                          RULES</button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div  class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="flightSegment">
                          <div *ngFor="let seg of item.Segments[0]" class="row" style="margin-top: 4px;
                          background-color: rgb(114, 114, 114);
                          padding: 10px;
                          color: #fff;">
                            <div class="col-md-2">
                              <p>{{seg.Origin.CityName}} - {{seg.Origin.AirportCode}} -{{seg.Origin.CountryName}} </p>
                              <p>{{seg.Origin.AirportName}}</p>
                              <p>FlightNumber : {{seg.FlightNumber}}</p>
                            </div>
                            <div class="col-md-3">
                              <h1>{{seg.Origin.AirportCode}} - {{seg.DepartureTime | date:'shortTime'}} </h1>
                              <!-- <p>{{seg.Origin.AirportName}},
                                India</p> -->
                            </div>
                            <div class="col-md-3">
                              <p>{{seg.Duration}}</p>
                              <p>Flight Duration</p>
                              <h3>No Of Seat Available :{{seg.NoOfSeatAvailable}} </h3>

                            </div>
                            <div class="col-md-3">
                              <h1>{{seg.Destination.AirportCode}} - {{seg.ArrivalTime | date:'shortTime'}}</h1>
                              <p>{{seg.Destination.AirportName}}</p>
                            </div>
                          </div>
                        </div>







                      </div>
                      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">


                        <div class="fareRule">
                          <ul class="basee"><li class="basing">Base Fare (1 Adult)</li><li class="basing">{{item.Fare.BaseFare}}</li></ul>
                          <ul class="basee"><li class="basing">Taxes and Fees (1 Adult)</li><li class="basing">{{item.Fare.Tax}}</li></ul>
                          <ul class="basee"><li class="basing">Service Fees (1 Adult)</li><li class="basing">{{item.Fare.ServiceFee}}</li></ul>
                          <ul class="basee"><li class="basing">Penalty Amount (1 Adult)</li><li class="basing">{{item.Fare.PenaltyAmount}}</li></ul>
                          <ul class="basee"><li class="basing"><b>Total Fare (1 Adult)</b></li><li class="basing">{{item.Fare.TotalFare}}</li></ul>
                        </div>



                      </div>
                      <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                        <div class="fareRule">
                          <h1>BOM-HYD (SG-269)</h1>
                          <ul class="basee"><li class="basing">Baggage Type</li><li class="basing">	Check-In</li><li class="basing">	Cabin</li></ul>
                          <ul class="basee"><li class="basing">ADULT (1 Adult)</li><li class="basing">25 Kgs (1 piece only)</li><li class="basing">7 Kgs (1 piece only)</li></ul>
                          <ul class="basee"><li class="basing"><b>Total Fare (1 Adult)</b></li><li class="basing">₹10,997</li><li class="basing">₹10,997</li></ul>
                        </div>

                      </div>
                      <div class="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="cancel-tab">

                        <div class="row">
                          <div class="col-md-6">
                            <div class="fareRule">
                              <p>Cancellation Charges</p>
                              <ul class="basee"><li class="basing">Baggage Type</li><li class="basing">	Check-In</li><li class="basing">	Cabin</li></ul>
                              <ul class="basee"><li class="basing">ADULT (1 Adult)</li><li class="basing">25 Kgs (1 piece only)</li><li class="basing">7 Kgs (1 piece only)</li></ul>
                              <ul class="basee"><li class="basing"><b>Total Fare (1 Adult)</b></li><li class="basing">₹10,997</li><li class="basing">₹10,997</li></ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="fareRule">
                              <p>Reschedule Charges</p>
                              <ul class="basee"><li class="basing">Baggage Type</li><li class="basing">	Check-In</li><li class="basing">	Cabin</li></ul>
                              <ul class="basee"><li class="basing">ADULT (1 Adult)</li><li class="basing">25 Kgs (1 piece only)</li><li class="basing">7 Kgs (1 piece only)</li></ul>
                              <ul class="basee"><li class="basing"><b>Total Fare (1 Adult)</b></li><li class="basing">₹10,997</li><li class="basing">₹10,997</li></ul>
                            </div>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



        
      </div>




    </div>
    <!-- <div *ngFor="let show of divVisibilityArray; let i = index">
      <button (click)="toggleDivVisibility(i)">Toggle Div {{ i }}</button>
      <div *ngIf="show">Content of Div {{ i }}</div>
    </div> -->
  </div>




















</div>









<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div style="opacity: 0;" class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" #closeFlight class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-lottie class="scrlim2"  [options]="options"
        (animationCreated)="animationCreated($event)"></ng-lottie>
      </div>

    </div>
  </div>
</div>



