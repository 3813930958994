<div class="pacagesNew">
    <div style="padding-top: 40px;" class="home-page">

        <div *ngIf="true" class="apply-visa">

            <div class="visa-FormData">
                <div class="container">
                    <h1 class="visa-application">{{packageData.title}} Application Form</h1>
                    <div class="row">
                        <div class="col-lg-8">
                            <form [formGroup]="applicantForm" (ngSubmit)="onSubmit()">
                                <div formArrayName="applicants">
                                    <div style="margin-bottom: 40px;"
                                        *ngFor="let applicant of applicantForm.get('applicants')['controls']; let i = index">
                                        <div [formGroupName]="i">

                                            <div class="leftform-data">
                                                <p class="ApplicantName">Applicant {{i+1}}</p>




                                                <div formGroupName="personalDetails">
                                                    <h1 class="general-details">Personal Details</h1>
                                                    <div class="row">
                                                        <div class="col-lg-11">
                                                            <div class="row g-2 g-md-3">
                                                                <div class="col-md-4">
                                                                    <input type="text" formControlName="firstname"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom01" placeholder="First Name"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'firstname')}"
                                                                        required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'firstname')"
                                                                        class="invalid-feedback">
                                                                        First Name is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <input type="text" formControlName="lastname"
                                                                        class="form-control general-inputData"
                                                                        id="validationCustom02" placeholder="Last Name"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'lastname')}"
                                                                        required />
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'lastname')"
                                                                        class="invalid-feedback">
                                                                        Last Name is required.
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                   

                                                                    <select id="inputState" formControlName="nationality"
                                                                    [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthDay')}"
                                                                    class="form-select date-select-below">
                                                                    <option class="date-Optionss-below">
                                                                        Select Nationality
                                                                    </option>
                                                                    <option *ngFor="let item of allContries"
                                                                        class="date-Optionss-below">
                                                                        {{item}}
                                                                    </option>
                                                                </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'nationality')"
                                                                        class="invalid-feedback">
                                                                        Nationality is required.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h1 class="travel-subTitles">Date of Birth</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthDay"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthDay')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Date
                                                                </option>
                                                                <option *ngFor="let item of travelDates"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthDay')"
                                                                class="invalid-feedback">
                                                                 Date is required.
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthMonth"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthMonth')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Month
                                                                </option>
                                                                <option *ngFor="let item of travelMonths"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthMonth')"
                                                                class="invalid-feedback">
                                                                 Month is required.
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <select id="inputState" formControlName="birthYear"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'birthYear')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Year
                                                                </option>
                                                                <option *ngFor="let item of birthYears"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'birthMonth')"
                                                                class="invalid-feedback">
                                                                 Year is required.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <select id="inputState" formControlName="gender"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'gender')}"
                                                                class="form-select date-select-below">
                                                                <option class="date-Optionss-below">
                                                                    Select Gender
                                                                </option>
                                                                <option *ngFor="let item of genders"
                                                                    class="date-Optionss-below">
                                                                    {{item}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'gender')"
                                                                class="invalid-feedback">
                                                                Gender is required.
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <input type="text" formControlName="passportNumber"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportNumber')}"
                                                                class="form-control general-inputData"
                                                                id="validationCustom01" placeholder="Passport No."
                                                                required />
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportNumber')"
                                                                class="invalid-feedback">
                                                                Passport number is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <h1 class="travel-subTitles">Passport Expiry Date</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col-md-8">
                                                            <div class="row g-3">
                                                                <div class="col">
                                                                    <select formControlName="passportExpDate"
                                                                    [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpDate')}"
                                                                        id="inputState"
                                                                        class="form-select date-select-below">
                                                                        <option *ngFor="let tem of travelDates"
                                                                            class="date-Optionss-below" selected>
                                                                            {{tem}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportNumber')"
                                                                    class="invalid-feedback">
                                                                    Passport Exp date required.
                                                                  </div>
                                                                </div>
                                                                <div class="col">
                                                                    <select formControlName="passportExpMonth"
                                                                    [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpMonth')}"
                                                                        id="inputState"
                                                                        class="form-select date-select-below">
                                                                        <option *ngFor="let tem of travelMonths"
                                                                            class="date-Optionss-below" selected>
                                                                            {{tem}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportExpMonth')"
                                                                    class="invalid-feedback">
                                                                    Passport Exp month required.
                                                                  </div>
                                                                </div>
                                                                <div class="col">
                                                                    <select formControlName="passportExpYear"
                                                                        id="inputState"
                                                                        [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'passportExpYear')}"

                                                                        class="form-select date-select-below">
                                                                        <option *ngFor="let tem of passportExpYear"
                                                                            class="date-Optionss-below" selected>
                                                                            {{tem}}
                                                                        </option>

                                                                    </select>
                                                                    <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'passportExpYear')"
                                                                    class="invalid-feedback">
                                                                    Passport Exp year required.
                                                                  </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->

                                                    <h1 class="travel-subTitles">Booking details will be sent to</h1>
                                                    <div class="row g-2 g-md-3">
                                                        <div class="col-md-2">
                                                            <select formControlName="mobileCountryCode" id="inputState"
                                                            [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'mobileCountryCode')}"
                                                            class="form-select date-select-below" [(ngModel)]="selectedCountryCode">
                                                        <option [value]="countrycode" [selected]="true" disabled>
                                                            Country Code
                                                        </option>
                                                        <option *ngFor="let item of allMobileFlags" class="date-Optionss-below" [value]="item.callingCode">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'mobileCountryCode')"
                                                                class="invalid-feedback">
                                                                Country Code required.
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control general-inputData"
                                                                id="validationCustom02" type="number"
                                                                formControlName="contactNumber"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'contactNumber')}"
                                                                placeholder="Enter Mobile number" required />
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'contactNumber')"
                                                                class="invalid-feedback">
                                                                Mobile number required.
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control general-inputData"
                                                                id="validationCustom02" formControlName="emailId"
                                                                [ngClass]="{'is-invalid': shouldShowError('applicants', i, 'personalDetails', 'emailId')}"
                                                                placeholder="Enter Email" required />
                                                            <div *ngIf="shouldShowError('applicants', i, 'personalDetails', 'emailId')"
                                                                class="invalid-feedback">
                                                                Email is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <button class="DELETEpAS" >Delete Member</button> -->
                                                    <i *ngIf="i+1 > packageData?.touristCount"
                                                        (click)="removeApplicant(i)"
                                                        class="fas fa-trash-alt removed"></i>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <p>Minimum {{packageData?.touristCount}} Guests required for this package bookings</p>

                                    <button type="button" class="ADDpAS" (click)="addApplicant()">Add Member</button>
                                    <div>
                                    </div>
                                </div>
                            </form>

                        </div>

                        <div class="col-lg-4">
                            <div class="right-inquery">
                                <!-- <div class="travel-box-below">
                                    <h3 class="traveling-boxTitle">Travelling to</h3>
                                    <p class="travel-place">United Arab Emirates (UAE)</p>
                                    <h3 class="traveling-boxTitle">Travelling From</h3>
                                    <p class="travel-place">India</p>
                                    <h3 class="traveling-boxTitle">Applied Visa</h3>
                                    <p class="travel-place">{{visaDetailsList?.title}}</p>
                                </div> -->
                                <div class="fare-box-below">
                                    <h4 class="fare-sumary">Travel Information</h4>
                                    <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <p class=" visa-fee">
                                                <span class="plus-minusIcons"></span>Travel Date
                                            </p>
                                        </li>
                                        <li class="fare-lists">
                                            <p class=" visa-fee">{{travelDate}}</p>
                                            <!-- <p class="per-adult text-end">{{baseFare}}</p> -->
                                        </li>
                                    </ul>
                                    <h4 class="fare-sumary">Fare Summary</h4>
                                    <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <p class=" visa-fee">
                                                <span class="plus-minusIcons"></span>Package Fee
                                            </p>
                                        </li>
                                        <li class="fare-lists">
                                            <p class=" visa-fee">{{packageData.price}}</p>
                                            <!-- <p class="per-adult text-end">{{baseFare}}</p> -->
                                        </li>
                                    </ul>
                                    <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <p class=" visa-fee">
                                                <span class="plus-minusIcons"></span>Minimum Tourists
                                            </p>
                                        </li>
                                     

                                        <li class="fare-lists">
                                            <p class=" visa-fee">{{membetsCount}}</p>
                                           
                                        </li>
                                    </ul>
                                    <ul *ngFor="let item of geFeeDetails" class="fare-list-top">
                                        <li class="fare-lists">
                                            <span class="plus-minusIcons"></span>
                                            {{item.name}}
                                        </li>
                                        <li class="fare-lists"> {{item.price  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}</li>
                                    </ul>
                                    <!-- <ul class="fare-list-top">
                                        <li class="fare-lists">
                                            <span class="plus-minusIcons"><i
                                                    class="fas fa-plus-circle"></i></span>Travel Insurance
                                        </li>
                                        <li class="fare-lists">$50</li>
                                    </ul> -->
                                    <ul class="fare-list-top amount-top">
                                        <li class="total-amount">
                                            Total Amount
                                        </li>
                                        <li class="total-amount">{{FinalPrice  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                                        <!-- visaPassingerCount -->
                                    </ul>

                                </div>
                                <div class="butons-below">
                                
                                    <button (click)="onSubmit()" [disabled]="formLoader" type="submit"
                                        class="submit-btns">Continue to pay

                                        <div *ngIf="formLoader" class="spinner-border " style="margin-left: 5px;"
                                            role="status">
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>