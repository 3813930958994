import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent {

  galleryItems: any
  galleryVideosLocal:any
  galleryVideosGlobal:any
  currentImageIndex: number = 0; // Default index
  galleryItemsGlobal: any
  imagesView: any = []
  images: string[] = [];
  selectedImage: any;
  selectedImages =''
  currentIndex = 0;
  mainImage: any = this.images[0];
  constructor(private service: AuthService, private router:Router) { }

  ngOnInit(): void {
    this.getGal()
  }

 getGal(){
  this.service.getGalleryItems().subscribe(
    (data: any) => {
        

      let LocalImages =[]
      let GlobalImages = []
      let LocalVideos =[]
      let GlobalVideos = []
      data.forEach((value)=>{
        if(value.region ==='1' && value.type ==='image'){
          LocalImages.push(value)
        }
        if(value.region ==='2' && value.type ==='image'){
          GlobalImages.push(value)
        }
        if(value.region ==='1' && value.type ==='video'){
          LocalVideos.push(value)
        }
        if(value.region ==='2' && value.type ==='video'){
          GlobalVideos.push(value)
        }


      })
      debugger
      this.galleryItems = LocalImages
      this.galleryItemsGlobal = GlobalImages
      this.galleryVideosLocal = LocalVideos
      this.galleryVideosGlobal = GlobalVideos
    }
  )
  this.selectedImage = this.imagesView[0];
 }

 getImages(data) {
  console.log(data);
  this.imagesView = data.galleryimages;
  let images = [];
  this.imagesView.forEach((value) => {
      images.push({ image: value.image, type: value.type });
  });
  this.images = images;
  this.showImage(images[0], 0);
}

  previousImage() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.currentImageIndex =  this.currentIndex;
    this.mainImage = this.images[this.currentIndex];
  }
  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.currentImageIndex =  this.currentIndex;
    this.mainImage = this.images[this.currentIndex];
  }


  showImage(image: any, index: number) {
    
    this.currentIndex = index;
    this.currentImageIndex = index;
    this.mainImage = image;
  }


 
  showPlayButton: boolean = true;
  @ViewChild('myVideo') myVideo: any;

  playPauseVideo() {
    const video: HTMLVideoElement = this.myVideo.nativeElement;
    video.pause();
    // if (video.paused) {
    //   video.play();
    // } else {
    //   video.pause();
    // }
  }

  onPlay() {
    this.showPlayButton = false;
  }

  onPause() {
    this.showPlayButton = true;
  }




  videos = [];

  getVideos(data){
    console.log(data)
    this.videos = data.galleryimages
    this.videosList =[]
    this.videosList =data.galleryimages
  }


  currentsIndex = 0;

  nextVideo() {
    this.currentIndex = (this.currentsIndex + 1) % this.videos.length;
  }

  prevVideo() {
    this.currentsIndex = (this.currentsIndex - 1 + this.videos.length) % this.videos.length;
  }

  currentIndex1 = 0;
  videosList = [];

  getCurrentVideo() {
    return this.videosList[this.currentIndex1]?.image;
  }

  nextVideo1() {
    this.currentIndex1 = (this.currentIndex1 + 1) % this.videosList.length;
    console.log("Next Video URL:", this.getCurrentVideo());
  }

  previousVideo1() {
    this.currentIndex1 = (this.currentIndex1 - 1 + this.videosList.length) % this.videosList.length;
    console.log("Previous Video URL:", this.getCurrentVideo());
  }

}
