<div class="allPages">
    <div *ngIf="false" class="devotion-corporatePackages">
        <div class="contact-usstop">
            <div class="container">
                <h2 class="contactus-deals">Corporate Packages</h2>
            </div>
        </div>
        <div class="contact-anytime">
            <div class="container">
                <div class="row">
                    <ul class="localButtons">
                        <li>
                            <h2 class="contactus-deals">Local Packages</h2>
                        </li>
                        <li>
                            <h2 *ngIf="localPackages.length > 0" class="contactus-deals"><button
                                    (click)="getMoreDetails2('isCorporateLocal')" class="localbtn">View all</button>
                            </h2>
                        </li>
                    </ul>
                    <div *ngFor="let item of localPackages" class="col-md-3">
                        <div class="">
                            <div class="imageSlides">
                                <div (click)="getMoreDetails(item)" class="popular-location-inside">
                                    <img class="popular-location-img" width="100%" height="auto"
                                        [src]="item?.images[0]?.image" alt="">
                                    <p class="PopularImgTitle">{{item.countryName}}</p>
                                    <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice">
                                            {{item.price | priceExchange:selectedCurrency | async | number:'1.0-2'}}
                                            {{selectedCurrency}}
                                        </span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="noPkgs" *ngIf="localPackages.length <= 0">No Packages</p>


                </div>
            </div>

        </div>
        <div class="contact-anytime">
            <div class="container">
                <div class="row">
                    <ul class="localButtons">
                        <li>
                            <h2 class="contactus-deals">Global Packages</h2>
                        </li>
                        <li>
                            <h2 *ngIf="globalPackages.length > 0" class="contactus-deals"><button
                                    (click)="getMoreDetails2('isCorporateGlobal')" class="localbtn">View all</button>
                            </h2>
                        </li>
                    </ul>
                    <div *ngFor="let item of globalPackages" class="col-md-3">
                        <div class="">
                            <div class="imageSlides">
                                <div (click)="getMoreDetails(item)" class="popular-location-inside">
                                    <img class="popular-location-img" width="100%" height="auto"
                                        [src]="item?.images[0]?.image" alt="">
                                    <p class="PopularImgTitle">{{item.countryName}}</p>
                                    <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice">
                                            {{item.price | priceExchange:selectedCurrency | async | number:'1.0-2'}}
                                            {{selectedCurrency}}</span></p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="noPkgs" *ngIf="globalPackages.length <= 0">No Packages</p>


                </div>
            </div>

        </div>
    </div>
    <div class="devotion-corporateNew">
        <div class="container">
            <p class="paraGraph_dev">UAE Business Study Tours for the Corporate and Business People, which is a Unique
                travel plan
                brought to you by Devotion Tourism. </p>
            <p class="paraGraph_dev">Where you can send your representative to gain practical knowledge about the UAE
                Industry and
                its way of working. Yes, what you have heard is perfect practical knowledge, where we will take
                your people to Government offices ,Legal Department, Industry leading Business Houses,
                Logistic Department, Tourism Industry, Real Estate Sector and different Zone to gain there way
                of working and Get firsthand experience of UAE business culture. The tour fosters cross industry
                learning and social entrepreneurship.
            </p>

            <h4>Benefits of Knowledge Tours</h4>
            <p class="paraGraph_dev">Knowledge tours are an excellent medium for both Businesspeople and Corporates to
                get firsthand industry-specific learnings. Such trips help open the dialogue about various educative
                genres by leveraging the available intelligence and creativity and applying those learnings to
                new and unique situations. The tour ensures you learn through exploration and active
                participation. It helps participants develop new skills, learn about the changing market
                /business trends and become aware of the best of innovations. There will be a session on
                Theory where our expert will give you knowledge about the Business and Industry such as
                Corporate Services, Immigration, Labour , Export / Import, Tourism, Real Estate, Freezone, Legal
                & compliance etc. process and then we will take you to the relevant place to have the practical
                viewing of the same.
            </p>
            <p class="paraGraph_dev">At Devotion Tourism, you can opt for knowledge tours that not only help you expand
                your
                horizons but also ensures that you gain knowledge on your line of industry and learn the way it
                functions. The specially designed itineraries of Devotion Tourism knowledge tours focus on
                interactive learning with prominent industry professionals with sessions taken by industry
                experts.</p>
            <p class="paraGraph_dev">Also, by visiting the different Zone you can take a practical decision that which
                zones is best for
                your Business. </p>
            <p class="paraGraph_dev">This package will be designed to handle a group of 6 people, so each person has
                been handled
                personally and their query are addressed which will be for 6 days. This tour plan can also be
                taken by any individual who would like to start a new Business in Dubai or UAE.</p>
            <p class="paraGraph_dev">You can even customise your knowledge tour packages in case you have any particular
                requirement. A group of professionals ensures you have the best time on your tour as they take
                complete care of planning, execution and organisation. It is a mix of knowledge and leisure
                tapping on aspiration of going on trips with friends and colleagues.</p>
            <p class="paraGraph_dev">We also organize the complete tour for the exhibition which takes place in UAE,
                whether you are
                exhibitor or a visitor. In this we will organize the Visa, Flight Tickets, Hotel Stay, Exhibition
                passes, travelling to venue and Food on demand. If you are the exhibitor than we can also
                arrange for the logistic for your product to be delivered to the exhibition venue and back to your
                country. To check the list of exhibition going to take place in UAE click the link for information. </p>
        </div>
    </div>
</div>