<ng-template style="background-color: transparent !important " #dialogContent>
  <div>
 
  </div>
</ng-template>

<div class="modal fade pull-left" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
  role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-lg">
    <!-- Updated modal-dialog class to modal-lg -->
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">Proceed to Pay</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        To ensure your seat reservation and finalize the booking, we kindly request you to proceed with the payment as
        soon as possible. The total amount due for your flight is
        {{finalPrice + finalPrice2}}
        <br>
        <button (click)="isIsConfirmed()" class="ProceedPay">Procced to Pay </button>
      </div>
    </div>
  </div>
</div>
<!-- Button trigger modal -->
<button type="button" style="opacity: 0;" #fareUpdate class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalPrice">
</button>
<button type="button" style="opacity: 0;"  #fareUpdateReturn class="btn btn-primary" data-bs-toggle="modal"
  data-bs-target="#staticDataBackdrop">

</button>
<button type="button" style="opacity: 0;" #closebuyyer class="btn btn-primary" data-bs-toggle="modal"
  data-bs-target="#staticBackdrop">

</button>

<div *ngIf="isGettingResponce" style="width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center;">
<ng-lottie *ngIf="isGettingResponce" class="scrlim2" width="250px" height="250px" style="display: flex; justify-content: center;" [options]="options"
(animationCreated)="animationCreated($event)"></ng-lottie>
</div>
<div *ngIf="!isGettingResponce"> 
<div *ngIf="!isCOnfirm" id="topScroll" class="flight-review-component">
  <div class="container">
    <div class="main-COntent">
      <h1 class="complite-text">Complete Your Booking</h1>
      <div class="row">
        <div class="col-md-9">
          <!-- <ngx-skeleton-loader *ngIf="isPageLoaded" count="20" [theme]="{ 
                      'border-radius': '5px',
                      height: '50px',
                    }"></ngx-skeleton-loader> -->
          <div *ngIf="!isPageLoaded" class="Flight-infrmation">
            <div *ngFor="let item of PageInfo?.Segments ; let isFirst = first ; let i = index ">
              <div *ngFor="let items of item ">
                <p  data-bs-toggle="popover" data-bs-trigger="focus"
                 [title]="items.Origin.CityName" *ngIf="items.newGroundDuration !== '00h:00min'" 
                 class="groudTImes">Ground Time {{items.newGroundDuration}} <br>
                {{items.Origin.CityName}} - {{items.Origin.AirportName}}
                </p>
                <!-- <p class="groudTImes">Ground Time {{items.newGroundDuration}}</p> -->
                <ul class="FLighRoute">
              
                  <li>
                    <h1 class="areaText"><span>
                        <!-- <img class="IconFLight"
                                                    src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.Airline}}.gif" alt=""> -->
                      </span>
                   
                      {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                  </li>
                  <li>
                    <p class="FareRule" *ngIf="isFirst" data-bs-toggle="modal" data-bs-target="#exampleModalFareRule">
                      View Fare Rules</p>
                  </li>
                </ul>
                <div class="tickets">
                  <ul class="fromsTicket">
                    <li class="LiInfo">
                      <img class="AriICons"
                        src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.Airline}}.gif" alt=""><br>
                      <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                      <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                        {{items.AirlineDetails.FlightNumber}}</p>
                    </li>
                    <li class="LiInfo">
                      <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}</p>
                      <h1 class="StartTime">{{items.Origin.AirportCode}} {{items.DepartureTime
                        |date:
                        'HH:mm' : 'en-US'}}</h1>
                      <P class="Airports">{{items.Origin.AirportName}}, {{items.Origin.CityName}},
                        {{items.Origin.CountryName}}</P>
                        <p *ngIf="items?.Origin.Terminal" class="Airports">Terminal : {{items.Origin.Terminal}}</p>
                    </li>
                    <li class="LiInfo">
                      <div class="directIons">
                        <div class="rounds"></div>
                        <p style="margin: 0; " class="DurayionData">


                          <span class="customTime"> --- {{ (items.Duration) }}---</span>
                        </p>
                        <img class="img-go" style="height: 20px;" src="./../../../../assets/images/FlightIcon.svg"
                          alt="">
                      </div>
                    </li>
                    <li class="LiInfo">
                      <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                      <h1 class="StartTime">{{items.Destination.AirportCode}} {{items.ArrivalTime
                        |date: 'HH:mm' : 'en-US'}}</h1>
                      <P class="Airports">{{items.Destination.AirportName}},
                        {{items.Destination.CityName}}, {{items.Destination.CountryName}}</P>
                        <p *ngIf="items?.Destination.Terminal" class="Airports">Terminal : {{items.Destination.Terminal}}</p>
                    </li>
                  </ul>
                  <div class="lins">
                    <p class="TotalKg"> <span><i class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}} (1
                      piece
                      only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                      {{items.CabinClass}}
                    </p>

                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngFor="let item of Pageinfos?.Segments ; let i = index ">
              <div *ngFor="let items of item ">
                <p *ngIf="items.newGroundDuration !== '00h:00min'" class="groudTImes">Ground Time {{items.newGroundDuration}}</p>

                <ul class="FLighRoute">
                  <li>
                    <h1 class="areaText"><span>
                        <!-- <img class="IconFLight"
                                                    src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.Airline}}.gif" alt=""> -->
                      </span>

                      {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                  </li>
                  <li>
                    <p class="FareRule" data-bs-toggle="modal" data-bs-target="#exampleModalFareRuleReturn">View Fare
                      Rules</p>
                  </li>
                </ul>
                <div class="tickets">
                  <ul class="fromsTicket">
                    <li class="LiInfo">
                      <img class="AriICons"
                        src="./../../../../assets/images/FLight-logos/airlineLogo/{{items.Airline}}.gif" alt=""><br>
                      <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                      <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                        {{items.AirlineDetails.FlightNumber}}</p>
                    </li>
                    <li class="LiInfo">
                      <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}</p>
                      <h1 class="StartTime">{{items.Origin.AirportCode}} {{items.DepartureTime
                        |date:
                        'HH:mm' : 'en-US'}}</h1>
                      <P class="Airports">{{items.Origin.AirportName}}, {{items.Origin.CityName}},
                        {{items.Origin.CountryName}}</P>
                        <p *ngIf="items?.Origin.Terminal" class="Airports">Terminal : {{items.Origin.Terminal}}</p>

                    </li>
                    <li class="LiInfo">
                      <div class="directIons">
                        <div class="rounds"></div>
                        <p style="margin: 0; " class="DurayionData"> ---{{items.Duration}}---
                        </p>
                        <img class="img-go" style="height: 20px;" src="./../../../../assets/images/FlightIcon.svg"
                          alt="">
                      </div>
                    </li>
                    <li class="LiInfo">
                      <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                      <h1 class="StartTime">{{items.Destination.AirportCode}} {{items.ArrivalTime
                        |date: 'HH:mm' : 'en-US'}}</h1>
                      <P class="Airports">{{items.Destination.AirportName}},
                        {{items.Destination.CityName}}, {{items.Destination.CountryName}}</P>
                        <p *ngIf="items?.Destination.Terminal" class="Airports">Terminal : {{items.Destination.Terminal}}</p>

                    </li>
                  </ul>
                  <div class="lins">
                    <p class="TotalKg"> <span><i class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}} (1
                      piece
                      only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                      {{items.CabinClass}}
                    </p>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
            <div class="ContinueNext">
              <button #travellerDetails (click)="scrollToSection(travellerDetails)"
                class="ContinueBtns2">Continue</button>
            </div>
          </div>
          <div>
       
            <div *ngFor="let field of fields.controls; let isFirst = first let i = index;" class="travelDetails">
          <ul class="profileReview">
            <li>
              <h1 class="travelr-Head">Passinger Details ({{i+1}}) </h1>
            </li>
            <li *ngIf="userProfileList.length > 0" [matMenuTriggerFor]="menu" class="PickProfile">
              <i class="fas fa-ellipsis-v"></i>
            </li>
            <mat-menu #menu="matMenu">
              <button *ngFor="let item of userProfileList" (click)="updateUserDetails(i,item)" mat-menu-item>{{item.FirstName}} {{item.LastName}}</button>
             
            </mat-menu>
          </ul>
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div formArrayName="fields">
                  <div class="form-group">
                    <div [formGroupName]="i">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="mb-3">
                            <select [attr.disabled]="true" formControlName="Type2" class="form-select my-filed"
                              [disabled]=true>
                              <option value="1">Adult</option>
                              <option value="2">Child</option>
                              <option value="3">Infant</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">
                            <select formControlName="Title" class="form-select my-filed"
                              [ngClass]="{ 'is-invalid': field.get('Title').invalid && field.get('Title').touched }"
                              aria-label="Default select example" required>
                              <!-- <option [selected]=true>Select Title</option> -->
                              <option value='Mr'>Mr</option>
                              <option value='Mrs'>Mrs</option>
                              <option value='Ms'>Ms</option>
                              <!-- <option value='Miss'>Miss</option> -->
                            </select>
                            <div class="invalid-feedback"
                              *ngIf="field.get('Title').invalid && field.get('Title').touched">
                              Title is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">
                            <input type="text" placeholder="First Name" class="form-control my-filed" id="field1"
                              formControlName="FirstName"
                              [ngClass]="{ 'is-invalid': field.get('FirstName').invalid && field.get('FirstName').touched }">
                            <div class="invalid-feedback"
                              *ngIf="field.get('FirstName').invalid && field.get('FirstName').touched">
                              FirstName is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">
                            <input type="text" placeholder="Last Name" class="form-control my-filed" id="field1"
                              formControlName="LastName"
                              [ngClass]="{ 'is-invalid': field.get('LastName').invalid && field.get('LastName').touched }">
                            <div class="invalid-feedback"
                              *ngIf="field.get('LastName').invalid && field.get('LastName').touched">
                              Lastname is required.
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="mb-3">

                            <div class="input-group">
                              <input type="text" style="border-radius: 8px !important;" #openCalnder placeholder="DOB"
                                [bsConfig]="BsDatepickerConfig2" formControlName="DateOfBirth"
                                class="form-control my-filed"
                                [ngClass]="{'is-invalid': field.get('DateOfBirth').invalid && field.get('DateOfBirth').touched}"
                                bsDatepicker>

                              <div (click)="openCalnders()" class="input-group-append">
                                <span class="input-group-text">
                                  <i class="fas fa-calendar"></i>
                                </span>
                              </div>
                            </div>

                            <div class="invalid-feedback"
                              *ngIf="field.get('DateOfBirth').invalid && field.get('DateOfBirth').touched">
                              Date of Birth is required.
                            </div>

                          </div>
                        </div>
                        <div *ngIf="isFirst" class="col-md-3">
                          <div class="mb-3">
                            <input type="text" placeholder="Email" class="form-control my-filed" id="field1"
                              formControlName="Email"
                              [ngClass]="{ 'is-invalid': field.get('Email').invalid && field.get('Email').touched }">
                            <div class="invalid-feedback"
                              *ngIf="field.get('Email').invalid && field.get('Email').touched">
                              Email is required.
                            </div>

                          </div>
                        </div>
                        <div *ngIf="isFirst" class="col-md-3">
                          <div class="mb-3">
                            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                            customPlaceholder="Mobile Number" [searchCountryFlag]="true"
                            
                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="Contact">
        </ngx-intl-tel-input>
        <!-- <ngx-intl-tel-input
        [cssClass]="'custom'"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.India"
        [maxLength]="15"
        [phoneValidation]="true"
        [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National"
        name="phone"
        formControlName="phone"
      >
      </ngx-intl-tel-input> -->
                            <div class="invalid-feedback"
                              *ngIf="field.get('Contact').invalid && field.get('Contact').touched">
                              Mobile number is required.
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">
                            <mat-button-toggle-group formControlName="Gender" name="fontStyle" aria-label="Font Style">
                              <mat-button-toggle value=1>Male</mat-button-toggle>
                              <mat-button-toggle value=2>Female</mat-button-toggle>
                            </mat-button-toggle-group>
                            <div class="invalid-feedback"
                              *ngIf="field.get('Gender').invalid && field.get('Gender').touched">
                              Gender No is required.
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isFirst" class="col-md-3">
                          <div class="mb-3">
                            <select
                              [ngClass]="{ 'is-invalid': field.get('Nationality1').invalid && field.get('Nationality1').touched }"
                              formControlName="Nationality1" (change)="national($event)" class="form-select my-filed"
                              aria-label="Default select example">
                              <option [selected]=true>Select Nationality</option>
                              <option value="AF">Afghan</option>
                              <option value="AL">Albanian</option>
                              <option value="DZ">Algerian</option>
                              <option value="AS">American Samoan</option>
                              <option value="US">American; US citizen</option>
                              <option value="AD">Andorran</option>
                              <option value="AO">Angolan</option>
                              <option value="AI">Anguillan</option>
                              <option value="AQ">Antarctica</option>
                              <option value="AG">Antiguan; Barbudian</option>
                              <option value="AN">Antillean</option>
                              <option value="AR">Argentinian</option>
                              <option value="AM">Armenian</option>
                              <option value="AW">Aruban</option>
                              <option value="AU">Australian</option>
                              <option value="AT">Austrian</option>
                              <option value="AZ">Azerbaijani</option>
                              <option value="BS">Bahamian</option>
                              <option value="BH">Bahraini</option>
                              <option value="BD">Bangladeshi</option>
                              <option value="BB">Barbadian</option>
                              <option value="LS">Basotho</option>
                              <option value="BY">Belarusian</option>
                              <option value="BE">Belgian</option>
                              <option value="BZ">Belizean</option>
                              <option value="BJ">Beninese</option>
                              <option value="BM">Bermudian</option>
                              <option value="BT">Bhutanese</option>
                              <option value="BO">Bolivian</option>
                              <option value="BW">Botswanan</option>
                              <option value="BV">Bouvet Islands</option>
                              <option value="BR">Brazilian</option>
                              <option value="IO">British Indian Ocean Territory</option>
                              <option value="VG">British Virgin Islander; BV Islander
                              </option>
                              <option value="BN">Bruneian</option>
                              <option value="BG">Bulgarian</option>
                              <option value="BF">Burkinabe</option>
                              <option value="MM">Burmese</option>
                              <option value="BI">Burundian</option>
                              <option value="KH">Cambodian</option>
                              <option value="CM">Cameroonian</option>
                              <option value="CB">Canada Buffer</option>
                              <option value="CA">Canadian</option>
                              <option value="CV">Cape Verdean</option>
                              <option value="KY">Caymanian</option>
                              <option value="CF">Central African</option>
                              <option value="TD">Chadian</option>
                              <option value="CL">Chilean</option>
                              <option value="CN">Chinese</option>
                              <option value="CX">Christmas Islander</option>
                              <option value="CC">Cocos Islander;Cocos (Keeling) Islands
                              </option>
                              <option value="CO">Colombian</option>
                              <option value="KM">Comorian</option>
                              <option value="CD">congo</option>
                              <option value="CG">Congolese</option>
                              <option value="CK">Cook Islander</option>
                              <option value="CR">Costa Rican</option>
                              <option value="HR">Croat</option>
                              <option value="CU">Cuban</option>
                              <option value="CY">Cypriot</option>
                              <option value="CZ">Czech</option>
                              <option value="DK">Dane</option>
                              <option value="DJ">Djiboutian</option>
                              <option value="DO">Dominican</option>
                              <option value="DM">Dominicana ; Dominica</option>
                              <option value="NL">Dutchman; Dutchwoman; Netherlander
                              </option>
                              <option value="TP">East Timor</option>
                              <option value="EC">Ecuadorian</option>
                              <option value="EG">Egyptian</option>
                              <option selected="selected" value="AE">Emirian</option>
                              <option value="GQ">Equatorial Guinean</option>
                              <option value="ER">Eritrean</option>
                              <option value="EE">Estonian</option>
                              <option value="ET">Ethiopian</option>
                              <option value="EU">European Monetary Union</option>
                              <option value="FO">Faeroese</option>
                              <option value="FK">Falkland Islander</option>
                              <option value="FJ">Fiji Islander</option>
                              <option value="PH">Filipino</option>
                              <option value="FI">Finn</option>
                              <option value="TF">French Southern Territories</option>
                              <option value="FR">Frenchman; Frenchwoman</option>
                              <option value="GA">Gabonese</option>
                              <option value="GM">Gambian</option>
                              <option value="GE">Georgian</option>
                              <option value="DE">German</option>
                              <option value="GH">Ghanaian</option>
                              <option value="GI">Gibraltarian</option>
                              <option value="GR">Greece</option>
                              <option value="GL">Greenlander</option>
                              <option value="GD">Grenadian</option>
                              <option value="GP">Guadeloupean</option>
                              <option value="GU">Guamanian</option>
                              <option value="GT">Guatemalan</option>
                              <option value="GF">Guianese</option>
                              <option value="GW">Guinea-Bissau national</option>
                              <option value="GN">Guinean</option>
                              <option value="GY">Guyanese</option>
                              <option value="HT">Haitian</option>
                              <option value="HM">Heard &amp; Mcdonald Islands</option>
                              <option value="HN">Honduran</option>
                              <option value="HK">Hong Kong Chinese</option>
                              <option value="HU">Hungarian</option>
                              <option value="IS">Icelander</option>
                              <option value="IN">Indian</option>
                              <option value="ID">Indonesian</option>
                              <option value="IR">Iranian</option>
                              <option value="IQ">Iraqi</option>
                              <option value="IE">Irishman; Irishwoman</option>
                              <option value="IL">Israeli</option>
                              <option value="IT">Italian</option>
                              <option value="CI">Ivorian</option>
                              <option value="JM">Jamaican</option>
                              <option value="JP">Japanese</option>
                              <option value="JO">Jordanian</option>
                              <option value="KZ">Kazakh</option>
                              <option value="KE">Kenyan</option>
                              <option value="KI">Kiribatian</option>
                              <option value="KN">Kittsian; Nevisian</option>
                              <option value="XK">Kosovo</option>
                              <option value="KW">Kuwaiti</option>
                              <option value="KG">Kyrgyz</option>
                              <option value="LA">Lao</option>
                              <option value="LV">Latvian</option>
                              <option value="LB">Lebanese</option>
                              <option value="LR">Liberian</option>
                              <option value="LY">Libyan</option>
                              <option value="LI">Liechtensteiner</option>
                              <option value="QL">Lithuania (Dummy Code)</option>
                              <option value="LT">Lithuanian</option>
                              <option value="LU">Luxembourger</option>
                              <option value="MO">Macanese</option>
                              <option value="MK">Macedonia</option>
                              <option value="YT">Mahorais</option>
                              <option value="MG">Malagasy</option>
                              <option value="MW">Malawian</option>
                              <option value="MY">Malaysian</option>
                              <option value="MV">Maldivian</option>
                              <option value="ML">Malian</option>
                              <option value="MT">Maltese</option>
                              <option value="MH">Marshallese</option>
                              <option value="MQ">Martinican</option>
                              <option value="MR">Mauritanian</option>
                              <option value="MU">Mauritian</option>
                              <option value="MX">Mexican</option>
                              <option value="MB">Mexico Buffer</option>
                              <option value="FM">Micronesian</option>
                              <option value="MD">Moldovan</option>
                              <option value="MC">Monegasque</option>
                              <option value="MN">Mongolian</option>
                              <option value="ME">Montenegro</option>
                              <option value="MS">Montserratian</option>
                              <option value="MA">Moroccan</option>
                              <option value="MZ">Mozambican</option>
                              <option value="NA">Namibian</option>
                              <option value="NR">Nauruan</option>
                              <option value="NP">Nepalese</option>
                              <option value="NC">New Caledonian</option>
                              <option value="NZ">New Zealander</option>
                              <option value="NI">Nicaraguan</option>
                              <option value="NG">Nigerian</option>
                              <option value="NE">Nigerien</option>
                              <option value="NU">Niuean</option>
                              <option value="NF">Norfolk Islander</option>
                              <option value="KP">North Korean</option>
                              <option value="MP">Northern Mariana Islander</option>
                              <option value="NO">Norwegian</option>
                              <option value="BA">of Bosnia and Herzegovina</option>
                              <option value="VA">of the Holy See (of the Vatican)
                              </option>
                              <option value="OM">Omani</option>
                              <option value="PK">Pakistani</option>
                              <option value="PW">Palauan</option>
                              <option value="PS">Palestinian Occ. Territories</option>
                              <option value="PA">Panamanian</option>
                              <option value="PG">Papua New Guinean</option>
                              <option value="PY">Paraguayan</option>
                              <option value="PE">Peruvian</option>
                              <option value="PN">Pitcairn</option>
                              <option value="PL">Pole</option>
                              <option value="PF">Polynesian</option>
                              <option value="PT">Portuguese</option>
                              <option value="PR">Puerto Rican</option>
                              <option value="QA">Qatari</option>
                              <option value="RE">Reunionese</option>
                              <option value="RO">Romanian</option>
                              <option value="RU">Russian</option>
                              <option value="RW">Rwandan; Rwandese;Rwanda</option>
                              <option value="EH">Sahrawi</option>
                              <option value="SH">Saint Helenian</option>
                              <option value="LC">Saint Lucian</option>
                              <option value="SV">Salvadorian; Salvadoran</option>
                              <option value="WS">Samoan</option>
                              <option value="SM">San Marinese</option>
                              <option value="ST">São Toméan</option>
                              <option value="SA">Saudi Arabian</option>
                              <option value="SN">Senegalese</option>
                              <option value="RS">Serbian</option>
                              <option value="SC">Seychellois</option>
                              <option value="SL">Sierra Leonean</option>
                              <option value="SG">Singaporean</option>
                              <option value="SK">Slovak</option>
                              <option value="SI">Slovene</option>
                              <option value="SB">Solomon Islander</option>
                              <option value="SO">Somali</option>
                              <option value="ZA">South African</option>
                              <option value="GS">South Georgia &amp; South Sandwich
                              </option>
                              <option value="KR">South Korean</option>
                              <option value="SU">Soviet Union</option>
                              <option value="ES">Spaniard</option>
                              <option value="LK">Sri Lankan</option>
                              <option value="PM">St-Pierrais; Miquelonnais;St. Pierre And
                                Miquelon</option>
                              <option value="SD">Sudanese</option>
                              <option value="SR">Surinamer</option>
                              <option value="SJ">Svalbard &amp; Jan Mayen Islands;Svalbard
                                And
                                Jan Mayen Islands</option>
                              <option value="SZ">Swazi</option>
                              <option value="SE">Swede</option>
                              <option value="CH">Swiss</option>
                              <option value="SY">Syrian</option>
                              <option value="TW">Taiwanese</option>
                              <option value="TJ">Tajik</option>
                              <option value="TZ">Tanzanian</option>
                              <option value="TH">Thai</option>
                              <option value="TG">Togolese</option>
                              <option value="TK">Tokelauan</option>
                              <option value="TO">Tongan</option>
                              <option value="TT">Trinidadian; Tobagonian</option>
                              <option value="TN">Tunisian</option>
                              <option value="TR">Turk</option>
                              <option value="TM">Turkmen</option>
                              <option value="TC">Turks and Caicos Islander</option>
                              <option value="TV">Tuvaluan</option>
                              <option value="UM">U.S. Minor Outlaying Islands</option>
                              <option value="UG">Ugandan</option>
                              <option value="UA">Ukrainian</option>
                              <option value="GB">United Kingdom</option>
                              <option value="UY">Uruguayan</option>
                              <option value="VI">US Virgin Islander</option>
                              <option value="UZ">Uzbek</option>
                              <option value="VU">Vanuatuan</option>
                              <option value="VE">Venezuelan</option>
                              <option value="VN">Vietnamese</option>
                              <option value="VC">Vincentian;St Vincent And The Grenadines
                              </option>
                              <option value="WF">Wallisian; Futunan; Wallis and Futuna
                                Islander</option>
                              <option value="YE">Yemeni</option>
                              <option value="YU">Yugoslavia</option>
                              <option value="ZM">Zambian</option>
                              <option value="ZW">Zimbabwean</option>
                            </select>
                            <div class="invalid-feedback"
                              *ngIf="field.get('Nationality1').invalid && field.get('Nationality1').touched">
                              Nationality is required
                            </div>

                          </div>
                        </div>
                        <!-- <div *ngIf="isFirst" class="col-md-3"> -->
                        <div *ngIf="false" class="col-md-3">
                          <div class="mb-3">
                            <input type="text" placeholder="Address" class="form-control my-filed" id="field1"
                              formControlName="AddressLine1"
                              [ngClass]="{ 'is-invalid': field.get('AddressLine1').invalid && field.get('AddressLine1').touched }">
                            <div class="invalid-feedback"
                              *ngIf="field.get('AddressLine1').invalid && field.get('AddressLine1').touched">
                              Address is required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isNeedPassportDetailsOneWay ||  isNeedPassportDetailsTwoWay" class="row">
                        <p class="travelr-Head">Document Details</p>

                        <!-- <div class="col-md-3">
                                                    <div class="mb-3">
                                                        <select formControlName="IdType" class="form-select my-filed"
                                                            [ngClass]="{ 'is-invalid': field.get('IdType').invalid && field.get('IdType').touched }"
                                                            aria-label="Default select example" required>
                                                            <option value="1">Passport</option>
                                                            <option value="2">Nation ID</option>
                                                            <option value="3">Iqama</option>
                                                        </select>
                                                        <div class="invalid-feedback"
                                                            *ngIf="field.get('IdType').invalid && field.get('IdType').touched">
                                                            IdType is required.
                                                        </div>
                                                    </div>
                                                </div> -->
                        <div class="col-md-3">
                          <div class="mb-3">
                            <input type="text" placeholder="Passport Number" class="form-control my-filed" id="field1"
                              formControlName="PassportNo"
                              [ngClass]="{ 'is-invalid': field.get('PassportNo').invalid && field.get('PassportNo').touched }">

                            <div class="invalid-feedback"
                              *ngIf="field.get('PassportNo').invalid && field.get('PassportNo').touched">
                              Passport Number
                            </div>


                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">

                            <!-- <input type="text" formControlName="ExpiryDate"
                                                            placeholder="Passport ExpiryDate"
                                                            class="form-control my-filed" [(bsValue)]="selectedDatess"
                                                            (bsValueChange)="onDateChange($event)"
                                                            [ngClass]="{ 'is-invalid': field.get('ExpiryDate').invalid && field.get('ExpiryDate').touched }"
                                                            bsDatepicker> -->
                            <input type="text" #openCalnder2 placeholder="Expiry Date" formControlName="PassportExpiry"
                              class="form-control my-filed" [bsConfig]="BsDatepickerConfig"
                              [ngClass]="{ 'is-invalid': field.get('PassportExpiry').invalid && field.get('PassportExpiry').touched }"
                              bsDatepicker>
                            <!-- <div (click)="openCalnders2()" class="input-group-append2">
                                                                <span class="input-group-text">
                                                                  <i class="fas fa-calendar"></i>
                                                                </span>
                                                              </div> -->
                            <div class="invalid-feedback"
                              *ngIf="field.get('PassportExpiry').invalid && field.get('PassportExpiry').touched">
                              Expiry Date is required.
                            </div>

                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-3">

                            <!-- <input type="text" formControlName="IssueDate "
                                                            placeholder="Issue Date" class="form-control my-filed"
                                                            [(bsValue)]="selectedDatew"
                                                            bsDatepicker> -->
                            <input type="text" placeholder="Passport Issued Date" [bsConfig]="BsDatepickerConfig2"
                              [maxDate]="today" formControlName="IssueDate" class="form-control my-filed" bsDatepicker>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="mb-3">
                            <select id="dropdowns"
                              [ngClass]="{ 'is-invalid': field.get('IssuedCountryCode').invalid && field.get('IssuedCountryCode').touched }"
                              formControlName="IssuedCountryCode" (change)="national($event)"
                              class="form-select my-filed" aria-label="Default select example">
                              <option [selected]=true>Passport Issued Country</option>
                              <option value="DZ" label="Algeria">Algeria</option>
                              <option value="AO" label="Angola">Angola</option>
                              <option value="BJ" label="Benin">Benin</option>
                              <option value="BW" label="Botswana">Botswana</option>
                              <option value="BF" label="Burkina Faso">Burkina Faso</option>
                              <option value="BI" label="Burundi">Burundi</option>
                              <option value="CM" label="Cameroon">Cameroon</option>
                              <option value="CV" label="Cape Verde">Cape Verde</option>
                              <option value="CF" label="Central African Republic">Central African Republic</option>
                              <option value="TD" label="Chad">Chad</option>
                              <option value="KM" label="Comoros">Comoros</option>
                              <option value="CG" label="Congo - Brazzaville">Congo - Brazzaville</option>
                              <option value="CD" label="Congo - Kinshasa">Congo - Kinshasa</option>
                              <option value="CI" label="Côte d’Ivoire">Côte d’Ivoire</option>
                              <option value="DJ" label="Djibouti">Djibouti</option>
                              <option value="EG" label="Egypt">Egypt</option>
                              <option value="GQ" label="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="ER" label="Eritrea">Eritrea</option>
                              <option value="ET" label="Ethiopia">Ethiopia</option>
                              <option value="GA" label="Gabon">Gabon</option>
                              <option value="GM" label="Gambia">Gambia</option>
                              <option value="GH" label="Ghana">Ghana</option>
                              <option value="GN" label="Guinea">Guinea</option>
                              <option value="GW" label="Guinea-Bissau">Guinea-Bissau</option>
                              <option value="KE" label="Kenya">Kenya</option>
                              <option value="LS" label="Lesotho">Lesotho</option>
                              <option value="LR" label="Liberia">Liberia</option>
                              <option value="LY" label="Libya">Libya</option>
                              <option value="MG" label="Madagascar">Madagascar</option>
                              <option value="MW" label="Malawi">Malawi</option>
                              <option value="ML" label="Mali">Mali</option>
                              <option value="MR" label="Mauritania">Mauritania</option>
                              <option value="MU" label="Mauritius">Mauritius</option>
                              <option value="YT" label="Mayotte">Mayotte</option>
                              <option value="MA" label="Morocco">Morocco</option>
                              <option value="MZ" label="Mozambique">Mozambique</option>
                              <option value="NA" label="Namibia">Namibia</option>
                              <option value="NE" label="Niger">Niger</option>
                              <option value="NG" label="Nigeria">Nigeria</option>
                              <option value="RW" label="Rwanda">Rwanda</option>
                              <option value="RE" label="Réunion">Réunion</option>
                              <option value="SH" label="Saint Helena">Saint Helena</option>
                              <option value="SN" label="Senegal">Senegal</option>
                              <option value="SC" label="Seychelles">Seychelles</option>
                              <option value="SL" label="Sierra Leone">Sierra Leone</option>
                              <option value="SO" label="Somalia">Somalia</option>
                              <option value="ZA" label="South Africa">South Africa</option>
                              <option value="SD" label="Sudan">Sudan</option>
                              <option value="SZ" label="Swaziland">Swaziland</option>
                              <option value="ST" label="São Tomé and Príncipe">São Tomé and Príncipe</option>
                              <option value="TZ" label="Tanzania">Tanzania</option>
                              <option value="TG" label="Togo">Togo</option>
                              <option value="TN" label="Tunisia">Tunisia</option>
                              <option value="UG" label="Uganda">Uganda</option>
                              <option value="EH" label="Western Sahara">Western Sahara</option>
                              <option value="ZM" label="Zambia">Zambia</option>
                              <option value="ZW" label="Zimbabwe">Zimbabwe</option>
                              <option value="AI" label="Anguilla">Anguilla</option>
                              <option value="AG" label="Antigua and Barbuda">Antigua and Barbuda</option>
                              <option value="AR" label="Argentina">Argentina</option>
                              <option value="AW" label="Aruba">Aruba</option>
                              <option value="BS" label="Bahamas">Bahamas</option>
                              <option value="BB" label="Barbados">Barbados</option>
                              <option value="BZ" label="Belize">Belize</option>
                              <option value="BM" label="Bermuda">Bermuda</option>
                              <option value="BO" label="Bolivia">Bolivia</option>
                              <option value="BR" label="Brazil">Brazil</option>
                              <option value="VG" label="British Virgin Islands">British Virgin Islands</option>
                              <option value="CA" label="Canada">Canada</option>
                              <option value="KY" label="Cayman Islands">Cayman Islands</option>
                              <option value="CL" label="Chile">Chile</option>
                              <option value="CO" label="Colombia">Colombia</option>
                              <option value="CR" label="Costa Rica">Costa Rica</option>
                              <option value="CU" label="Cuba">Cuba</option>
                              <option value="DM" label="Dominica">Dominica</option>
                              <option value="DO" label="Dominican Republic">Dominican Republic</option>
                              <option value="EC" label="Ecuador">Ecuador</option>
                              <option value="SV" label="El Salvador">El Salvador</option>
                              <option value="FK" label="Falkland Islands">Falkland Islands</option>
                              <option value="GF" label="French Guiana">French Guiana</option>
                              <option value="GL" label="Greenland">Greenland</option>
                              <option value="GD" label="Grenada">Grenada</option>
                              <option value="GP" label="Guadeloupe">Guadeloupe</option>
                              <option value="GT" label="Guatemala">Guatemala</option>
                              <option value="GY" label="Guyana">Guyana</option>
                              <option value="HT" label="Haiti">Haiti</option>
                              <option value="HN" label="Honduras">Honduras</option>
                              <option value="JM" label="Jamaica">Jamaica</option>
                              <option value="MQ" label="Martinique">Martinique</option>
                              <option value="MX" label="Mexico">Mexico</option>
                              <option value="MS" label="Montserrat">Montserrat</option>
                              <option value="AN" label="Netherlands Antilles">Netherlands Antilles</option>
                              <option value="NI" label="Nicaragua">Nicaragua</option>
                              <option value="PA" label="Panama">Panama</option>
                              <option value="PY" label="Paraguay">Paraguay</option>
                              <option value="PE" label="Peru">Peru</option>
                              <option value="PR" label="Puerto Rico">Puerto Rico</option>
                              <option value="BL" label="Saint Barthélemy">Saint Barthélemy</option>
                              <option value="KN" label="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                              <option value="LC" label="Saint Lucia">Saint Lucia</option>
                              <option value="MF" label="Saint Martin">Saint Martin</option>
                              <option value="PM" label="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                              <option value="VC" label="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                              <option value="SR" label="Suriname">Suriname</option>
                              <option value="TT" label="Trinidad and Tobago">Trinidad and Tobago</option>
                              <option value="TC" label="Turks and Caicos Islands">Turks and Caicos Islands</option>
                              <option value="VI" label="U.S. Virgin Islands">U.S. Virgin Islands</option>
                              <option value="US" label="United States">United States</option>
                              <option value="UY" label="Uruguay">Uruguay</option>
                              <option value="VE" label="Venezuela">Venezuela</option>
                              <option value="AF" label="Afghanistan">Afghanistan</option>
                              <option value="AM" label="Armenia">Armenia</option>
                              <option value="AZ" label="Azerbaijan">Azerbaijan</option>
                              <option value="BH" label="Bahrain">Bahrain</option>
                              <option value="BD" label="Bangladesh">Bangladesh</option>
                              <option value="BT" label="Bhutan">Bhutan</option>
                              <option value="BN" label="Brunei">Brunei</option>
                              <option value="KH" label="Cambodia">Cambodia</option>
                              <option value="CN" label="China">China</option>
                              <option value="GE" label="Georgia">Georgia</option>
                              <option value="HK" label="Hong Kong SAR China">Hong Kong SAR China</option>
                              <option value="IN" label="India">India</option>
                              <option value="ID" label="Indonesia">Indonesia</option>
                              <option value="IR" label="Iran">Iran</option>
                              <option value="IQ" label="Iraq">Iraq</option>
                              <option value="IL" label="Israel">Israel</option>
                              <option value="JP" label="Japan">Japan</option>
                              <option value="JO" label="Jordan">Jordan</option>
                              <option value="KZ" label="Kazakhstan">Kazakhstan</option>
                              <option value="KW" label="Kuwait">Kuwait</option>
                              <option value="KG" label="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="LA" label="Laos">Laos</option>
                              <option value="LB" label="Lebanon">Lebanon</option>
                              <option value="MO" label="Macau SAR China">Macau SAR China</option>
                              <option value="MY" label="Malaysia">Malaysia</option>
                              <option value="MV" label="Maldives">Maldives</option>
                              <option value="MN" label="Mongolia">Mongolia</option>
                              <option value="MM" label="Myanmar [Burma]">Myanmar [Burma]</option>
                              <option value="NP" label="Nepal">Nepal</option>
                              <option value="NT" label="Neutral Zone">Neutral Zone</option>
                              <option value="KP" label="North Korea">North Korea</option>
                              <option value="OM" label="Oman">Oman</option>
                              <option value="PK" label="Pakistan">Pakistan</option>
                              <option value="PS" label="Palestinian Territories">Palestinian Territories</option>
                              <option value="YD" label="People's Democratic Republic of Yemen">People's Democratic Republic of Yemen</option>
                              <option value="PH" label="Philippines">Philippines</option>
                              <option value="QA" label="Qatar">Qatar</option>
                              <option value="SA" label="Saudi Arabia">Saudi Arabia</option>
                              <option value="SG" label="Singapore">Singapore</option>
                              <option value="KR" label="South Korea">South Korea</option>
                              <option value="LK" label="Sri Lanka">Sri Lanka</option>
                              <option value="SY" label="Syria">Syria</option>
                              <option value="TW" label="Taiwan">Taiwan</option>
                              <option value="TJ" label="Tajikistan">Tajikistan</option>
                              <option value="TH" label="Thailand">Thailand</option>
                              <option value="TL" label="Timor-Leste">Timor-Leste</option>
                              <option value="TR" label="Turkey">Turkey</option>
                              <option value="TM" label="Turkmenistan">Turkmenistan</option>
                              <option value="AE" label="United Arab Emirates">United Arab Emirates</option>
                              <option value="UZ" label="Uzbekistan">Uzbekistan</option>
                              <option value="VN" label="Vietnam">Vietnam</option>
                              <option value="YE" label="Yemen">Yemen</option>
                              <option value="AL" label="Albania">Albania</option>
                              <option value="AD" label="Andorra">Andorra</option>
                              <option value="AT" label="Austria">Austria</option>
                              <option value="BY" label="Belarus">Belarus</option>
                              <option value="BE" label="Belgium">Belgium</option>
                              <option value="BA" label="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                              <option value="BG" label="Bulgaria">Bulgaria</option>
                              <option value="HR" label="Croatia">Croatia</option>
                              <option value="CY" label="Cyprus">Cyprus</option>
                              <option value="CZ" label="Czech Republic">Czech Republic</option>
                              <option value="DK" label="Denmark">Denmark</option>
                              <option value="DD" label="East Germany">East Germany</option>
                              <option value="EE" label="Estonia">Estonia</option>
                              <option value="FO" label="Faroe Islands">Faroe Islands</option>
                              <option value="FI" label="Finland">Finland</option>
                              <option value="FR" label="France">France</option>
                              <option value="DE" label="Germany">Germany</option>
                              <option value="GI" label="Gibraltar">Gibraltar</option>
                              <option value="GR" label="Greece">Greece</option>
                              <option value="GG" label="Guernsey">Guernsey</option>
                              <option value="HU" label="Hungary">Hungary</option>
                              <option value="IS" label="Iceland">Iceland</option>
                              <option value="IE" label="Ireland">Ireland</option>
                              <option value="IM" label="Isle of Man">Isle of Man</option>
                              <option value="IT" label="Italy">Italy</option>
                              <option value="JE" label="Jersey">Jersey</option>
                              <option value="LV" label="Latvia">Latvia</option>
                              <option value="LI" label="Liechtenstein">Liechtenstein</option>
                              <option value="LT" label="Lithuania">Lithuania</option>
                              <option value="LU" label="Luxembourg">Luxembourg</option>
                              <option value="MK" label="Macedonia">Macedonia</option>
                              <option value="MT" label="Malta">Malta</option>
                              <option value="FX" label="Metropolitan France">Metropolitan France</option>
                              <option value="MD" label="Moldova">Moldova</option>
                              <option value="MC" label="Monaco">Monaco</option>
                              <option value="ME" label="Montenegro">Montenegro</option>
                              <option value="NL" label="Netherlands">Netherlands</option>
                              <option value="NO" label="Norway">Norway</option>
                              <option value="PL" label="Poland">Poland</option>
                              <option value="PT" label="Portugal">Portugal</option>
                              <option value="RO" label="Romania">Romania</option>
                              <option value="RU" label="Russia">Russia</option>
                              <option value="SM" label="San Marino">San Marino</option>
                              <option value="RS" label="Serbia">Serbia</option>
                              <option value="CS" label="Serbia and Montenegro">Serbia and Montenegro</option>
                              <option value="SK" label="Slovakia">Slovakia</option>
                              <option value="SI" label="Slovenia">Slovenia</option>
                              <option value="ES" label="Spain">Spain</option>
                              <option value="SJ" label="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                              <option value="SE" label="Sweden">Sweden</option>
                              <option value="CH" label="Switzerland">Switzerland</option>
                              <option value="UA" label="Ukraine">Ukraine</option>
                              <option value="SU" label="Union of Soviet Socialist Republics">Union of Soviet Socialist Republics</option>
                              <option value="GB" label="United Kingdom">United Kingdom</option>
                              <option value="VA" label="Vatican City">Vatican City</option>
                              <option value="AX" label="Åland Islands">Åland Islands</option>
                              <option value="AS" label="American Samoa">American Samoa</option>
                              <option value="AQ" label="Antarctica">Antarctica</option>
                              <option value="AU" label="Australia">Australia</option>
                              <option value="BV" label="Bouvet Island">Bouvet Island</option>
                              <option value="IO" label="British Indian Ocean Territory">British Indian Ocean Territory</option>
                              <option value="CX" label="Christmas Island">Christmas Island</option>
                              <option value="CC" label="Cocos [Keeling] Islands">Cocos [Keeling] Islands</option>
                              <option value="CK" label="Cook Islands">Cook Islands</option>
                              <option value="FJ" label="Fiji">Fiji</option>
                              <option value="PF" label="French Polynesia">French Polynesia</option>
                              <option value="TF" label="French Southern Territories">French Southern Territories</option>
                              <option value="GU" label="Guam">Guam</option>
                              <option value="HM" label="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                              <option value="KI" label="Kiribati">Kiribati</option>
                              <option value="MH" label="Marshall Islands">Marshall Islands</option>
                              <option value="FM" label="Micronesia">Micronesia</option>
                              <option value="NR" label="Nauru">Nauru</option>
                              <option value="NC" label="New Caledonia">New Caledonia</option>
                              <option value="NZ" label="New Zealand">New Zealand</option>
                              <option value="NU" label="Niue">Niue</option>
                              <option value="NF" label="Norfolk Island">Norfolk Island</option>
                              <option value="MP" label="Northern Mariana Islands">Northern Mariana Islands</option>
                              <option value="PW" label="Palau">Palau</option>
                              <option value="PG" label="Papua New Guinea">Papua New Guinea</option>
                              <option value="PN" label="Pitcairn Islands">Pitcairn Islands</option>
                              <option value="WS" label="Samoa">Samoa</option>
                              <option value="SB" label="Solomon Islands">Solomon Islands</option>
                              <option value="GS" label="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                              <option value="TK" label="Tokelau">Tokelau</option>
                              <option value="TO" label="Tonga">Tonga</option>
                              <option value="TV" label="Tuvalu">Tuvalu</option>
                              <option value="UM" label="U.S. Minor Outlying Islands">U.S. Minor Outlying Islands</option>
                              <option value="VU" label="Vanuatu">Vanuatu</option>
                              <option value="WF" label="Wallis and Futuna">Wallis and Futuna</option>
                            </select>
                            <div class="invalid-feedback"
                              *ngIf="field.get('Nationality1').invalid && field.get('Nationality1').touched">
                              Paasport Issued COde is required.
                            </div>
                          </div>
                        </div>
                      </div>



                      <!-- <div class="minusPassinger2">
                                            <i style="color: #AB823E; cursor: pointer;" (click)="removeForm(i)" class="fas fa-trash-alt"></i>
                                          
                                        
                                          </div> -->
                    </div>
                  </div>
                </div>
                <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
              </form>
            </div>
            <!-- <button  (click)="addForm()" class="ContinueBtnsss">+ Add User</button> -->


            <button *ngIf="!isPageLoaded" (click)="validationFunction()" class="ContinueBtns">Proceed to Pay</button>

          </div>
          <div *ngIf="!isPageLoaded" class="InportantInfo">
            <h1 class="ImportantHead">Important Information</h1>
            <ul class="list-data">
              <li class="list-info">
                <p class="bullet-para">Booking fare is subject to change unless the booking has been ticketed. </p>
              </li>
              <li class="list-info">
                <p class="bullet-para">Ticket changes may incur penalties and/or increased fares. </p>
              </li>
              <li class="list-info">
                <p class="bullet-para">Tickets are nontransferable and name changes are not allowed. </p>
              </li>
              <li class="list-info">
                <p class="bullet-para">Read the complete penalty rules for changes and cancellations applicable to this
                  fare. </p>
              </li>
              <li class="list-info">
                <p class="bullet-para">It's suggested to arrive at the airport 3 hours prior to the departure to avoid
                  no-shows and last-minute chaos for international and domestic flights.

                </p>
              </li>
              <li class="list-info">
                <p class="bullet-para">Remember to web check-in before arriving at the airport; carry a printed or soft
                  copy of the boarding pass.
                </p>
              </li>

            </ul>

            <p class="content-para">(Please ensure the accurate contact details of the passenger (as per passport or
              local country government-authorized ID proof), so that the Airline can update the passenger about any
              changes and request to verify the Visa and airline transit information before proceeding with the booking
              to avoid any inconvenience/ADM from the respective carrier. Please note that several countries have
              imposed entry restrictions because of the novel coronavirus (COVID-19) outbreak, kindly verify the
              checklist on the airline's official website.) </p>
            <p class="content-para">DISCLAIMER: The information provided above is only for ready reference and
              convenience of customers, and may not be exhaustive. We strongly recommend you check the full text of the
              guidelines issued by the State Governments and Airlines before traveling to ensure smooth travel. We
              accept no liability in this regard. If you do not meet the required guidelines, the airline or state
              authorities can stop you from traveling.

            </p>
          </div>
          <!-- <button (click)="previeCheck()" class="ContinueBtns">Continue</button> -->
        </div>
        <div class="col-md-3">
          <div class="fareDetails">
            <h1 class="summary">Fare Summary</h1>
            <p class="basing">Base Fare</p>

            <div class="fareList">
              <ul *ngFor="let fare of fareBreak" class="farebreak">
                <li>
                  <span class="childs" *ngIf="fare.PassengerType == 1">Adult ({{fare.PassengerCount}})</span>
                  <span class="childs" *ngIf="fare.PassengerType == 2">Child ({{fare.PassengerCount}})</span>
                  <span class="childs" *ngIf="fare.PassengerType == 3">Infant ({{fare.PassengerCount}})</span>

                  <p class="childs"> </p>
                </li>
                <!-- <li ><span *ngIf="fare.PassengerType == 2">Child </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li>
                                <li ><span *ngIf="fare.PassengerType == 3">Infant </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li> -->
                <!-- <li *ngIf="fare.PassengerCount == 2">Child ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li>
                                <li *ngIf="fare.PassengerCount == 2">Infant ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li> -->

                <li>
                  <p class="childs">{{fare.NewBaseFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                    <!-- {{fare.Currency }} -->
                    {{selectedCurrency}}
                  </p>
                </li>
              </ul>
            </div>

            <ul class="farebreak">
              <li>
                <p class="basing">Taxes</p>

                <!-- <span class="childs" *ngIf="fare.PassengerType == 1">Adult ({{fare.PassengerCount}})</span>
                                <span class="childs" *ngIf="fare.PassengerType == 2">Child ({{fare.PassengerCount}})</span>
                                <span class="childs" *ngIf="fare.PassengerType == 3">Infant ({{fare.PassengerCount}})</span> -->

                <!-- <p class="childs"> ({{fare.PassengerCount}})</p> -->
              </li>
              <!-- <li ><span *ngIf="fare.PassengerType == 2">Child </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li>
                            <li ><span *ngIf="fare.PassengerType == 3">Infant </span>  (  {{fare.BaseFare | number:'1.0-2'}})</li> -->
              <!-- <li *ngIf="fare.PassengerCount == 2">Child ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li>
                            <li *ngIf="fare.PassengerCount == 2">Infant ({{fare.PassengerCount}}x {{fare.BaseFare  | number:'1.0-2' }})</li> -->

              <li>
                <p class="childs">{{PageInfo?.Fare?.NewTax  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>

            <!-- <ul class="farebreak">
              <li>
                <p class="basing">Other Charges</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{PageInfo?.Fare?.NewTax  | number:'1.0-2'}}
                  AED
                  {{currencyType}}

                </p>
              </li>
            </ul> -->
            <ul class="farebreak">
              <li>
                <p class="basing">Service Fee</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{PageInfo?.Fare?.NewServiceFee  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>
            <ul class="farebreak">
              <li>
                <p class="basing">Other Fee</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{PageInfo?.Fare?.NewAgentMarkup | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType2}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>
            <ul class="total">
              <li>
                <h1 class="summary">Total Amount</h1>
              </li>
              <li>
                <h1 class="summary">{{PageInfo?.Fare?.NewTotalFare  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyTupe}} -->
                  {{selectedCurrency}}
                </h1>
              </li>
            </ul>
          </div>
          <div *ngIf="isReturn === 'Way2'" class="fareDetails">
            <h1 class="summary">Return Fare Summary</h1>
            <p class="basing">Base Fare</p>

            <div class="fareList">
              <ul *ngFor="let fares of fareBreakReturn" class="farebreak">
                <li><span class="childs" *ngIf="fares?.PassengerType == 1">Adult ({{fares?.PassengerCount}})</span>
                  <span class="childs" *ngIf="fares?.PassengerType == 2">Child ({{fares?.PassengerCount}})</span>
                  <span class="childs" *ngIf="fares?.PassengerType == 3">Infant ({{fares?.PassengerCount}})</span>

                  <p class="childs"> </p>
                </li>

                <li>
                  <p class="childs">{{fares?.NewBaseFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                    {{selectedCurrency}}
                    <!-- {{fares?.Currency }} -->
                  </p>
                </li>
              </ul>
            </div>

            <ul class="farebreak">
              <li>
                <p class="basing">Taxes</p>

              </li>

              <li>
                <p class="childs">{{Pageinfos?.Fare?.NewTax  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>

            <!-- <ul class="farebreak">
              <li>
                <p class="basing">Other Charges</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{OtherCharges2 | number:'1.0-2'}}
                  AED
                  {{currencyType}}

                </p>
              </li>
            </ul> -->
            <ul class="farebreak">
              <li>
                <p class="basing">Service Fee</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{Pageinfos?.Fare?.NewServiceFee | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType2}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>
            <ul class="farebreak">
              <li>
                <p class="basing">Other Fee</p>

                <p class="childs"> </p>
              </li>


              <li>
                <p class="childs">{{Pageinfos?.Fare?.NewAgentMarkup | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  <!-- {{currencyType2}} -->
                  {{selectedCurrency}}
                </p>
              </li>
            </ul>
            <ul class="total">
              <li>
                <h1 class="summary">Total Amount</h1>
              </li>
              <li>
                <h1 class="summary">{{Pageinfos?.Fare?.NewTotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                  {{selectedCurrency}}
                  <!-- {{currencyTupe}} -->
                </h1>
              </li>
            </ul>
          </div>
          <div *ngIf="false" class="fareDetails">
            <h1 class="summarys">Devotion Offers</h1>

            <form action="">
              <input type="text" class="ApplyCo" placeholder="Apply Coupon">
              <button class="AppyBtns">Apply</button>
            </form>
            <div class="coupanset">
              <mat-radio-button value="1">
                <p class="coupanName">GIRUSH</p>
              </mat-radio-button>
              <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
              </p>
            </div>
            <div class="coupanset">
              <mat-radio-button value="1">
                <p class="coupanName">GIRUSH</p>
              </mat-radio-button>
              <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
              </p>
            </div>
            <div style="border: none;" class="coupanset">
              <mat-radio-button value="1">
                <p class="coupanName">GIRUSH</p>
              </mat-radio-button>
              <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<div id="topScroll" *ngIf="isCOnfirm">
  <div class="flight-review-component">

    <div class="container">
      <div class="main-COntent">
        <h1 class="complite-text">Complete Your Booking</h1>
        <div class="row">
          <div class="col-md-9">


            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Flights Summary <br>
                    Hyderabad
                  </mat-panel-title>

                </mat-expansion-panel-header>

                <div *ngIf="!isPageLoaded">
                  <div *ngFor="let item of PageInfo.Segments ; let i = index " class="Flight-infrmation">
                    <div *ngFor="let items of item ">
                      <ul class="FLighRoute">
                        <li>
                          <h1 class="areaText"><span><img class="IconFLight"
                                src="./../../../../assets/images/FlightIcon.svg" alt=""></span>

                            {{items.Origin.CityName}} - {{items.Destination.CityName}} </h1>
                        </li>
                        <!-- <li>
                                                    <p class="FareRule">View Fare Rules</p>
                                                </li> -->
                      </ul>
                      <div class="tickets">
                        <ul class="fromsTicket">
                          <li class="LiInfo">
                            <img class="AriICons" src="./../../../../assets/images/airLine.svg" alt=""><br>
                            <p class="FLight-data">{{items.AirlineDetails.AirlineName}}</p>
                            <p class="FlightNo">{{items.AirlineDetails.AirlineCode}}
                              {{items.AirlineDetails.FlightNumber}}</p>
                          </li>
                          <li class="LiInfo">
                            <p class="Flight-Date">{{items.DepartureTime | date:'mediumDate'}}
                            </p>
                            <h1 class="StartTime">{{items.Origin.AirportCode}}
                              {{items.DepartureTime |date:
                              'HH:mm' : 'en-US'}}</h1>
                            <P class="Airports">{{items.Origin.AirportName}},
                              {{items.Origin.CityName}},
                              {{items.Origin.CountryName}}</P>
                          </li>
                          <li class="LiInfo">
                            <div class="directIons">
                              <div class="rounds"></div>
                              <p style="margin: 0; " class="DurayionData">
                                --- <span class="DurationData">{{items.Duration}}</span> ---</p>
                              <img class="img-go" style="height: 20px;" src="./../../../../assets/images/FlightIcon.svg"
                                alt="">
                            </div>
                          </li>
                          <li class="LiInfo">
                            <p class="Flight-Date">{{items.ArrivalTime | date:'mediumDate'}}</p>
                            <h1 class="StartTime">{{items.Destination.AirportCode}}
                              {{items.ArrivalTime
                              |date: 'HH:mm' : 'en-US'}}</h1>
                            <P class="Airports">{{items.Destination.AirportName}},
                              {{items.Destination.CityName}},
                              {{items.Destination.CountryName}}</P>
                          </li>
                        </ul>
                        <div class="lins">
                          <p class="TotalKg"> <span><i class="fas fa-suitcase mr-3"></i></span>{{items.IncludedBaggage}}
                            (1
                            piece
                            only) Check-In, {{items.CabinBaggage}} (1 piece only) Cabin
                            {{items.CabinClass}}
                          </p>

                        </div>

                      </div>

                    </div>
                    <div class="ContinueNext">

                    </div>
                  </div>
                </div>

              </mat-expansion-panel>

            </mat-accordion>










            <div *ngFor="let item of this.form.value.fields" class="InportantInfo">
              <ul class="ImportantHeade">
                <li>
                  <h1 class="ImportantHead">Passinger Details</h1>
                </li>
                <li><i style="cursor: pointer;" (click)="isIsEdit()" class="fas fa-edit"></i></li>
              </ul>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">First Name</p>
                  <h1 class="Username">{{item?.FirstName }}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Last Name</p>
                  <h1 class="Username">{{item?.LastName}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Gender</p>
                  <h1 *ngIf="item?.Gender ==1" class="Username">Male</h1>
                  <h1 *ngIf="item?.Gender ==2" class="Username">Female</h1>
                </li>
              </ul>

              <h1 class="ImportantHead">Contact Details</h1>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">Country Code</p>
                  <h1 class="Username">{{item?.Mobile1CountryCode}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Contact No.</p>
                  <h1 class="Username">{{item?.Mobile1}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Email Address</p>
                  <h1 class="Username">{{item?.Email}}</h1>
                </li>
              </ul>



              <h1 class="ImportantHead">Passport Details</h1>
              <ul class="list-data-prev">
                <li class="list-names">
                  <p class="firstName">Passport No.</p>
                  <h1 class="Username">{{item?.PassportNo}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Expire Date</p>
                  <h1 class="Username">{{item?.PassportExpiry}}</h1>
                </li>
                <li class="list-names">
                  <p class="firstName">Issued Country</p>
                  <h1 class="Username">{{item?.Nationality?.CountryName}}</h1>
                </li>
              </ul>
            </div>

            <div *ngIf="false" class="travelDetails">
              <h1 class="travelr-Head">Passinger Details</h1>
              <p>Add Details</p>
              <form *ngIf="false" [formGroup]="dynamicForm" class="g-3 needs-validation">
                <div formArrayName="formFields">
                  <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-2">
                        <select formControlName="Title" class="form-select my-filed"
                          aria-label="Default select example">
                          <option selected value="Mr">Mr</option>
                          <option value="Miss">Miss</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select formControlName="Type" class="form-select my-filed" aria-label="Default select example">
                          <option selected value=1>Adult</option>
                          <option value=2>Child</option>
                          <option value=3>Infant</option>
                          <option value=4>Senior</option>
                          <option value=3>Youth</option>
                        </select>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="FirstName" type="email" id="validationCustom01"
                            class="form-control my-filed" placeholder="First Name" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="LastName" type="email" class="form-control my-filed"
                            placeholder="Last Name" required>
                        </div>
                      </div>
                      <div class="col-md-2">

                        <select formControlName="Gender" class="form-select my-filed"
                          aria-label="Default select example">
                          <option selected value=1>Male</option>
                          <option value=2>Female</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <input formControlName="Email" type="email" id="validationCustom01"
                            class="form-control my-filed" placeholder="Email" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <div class="mb-3">
                            <input formControlName="Mobile1" type="email" id="validationCustom01"
                              class="form-control my-filed" placeholder="Mobile Number" required>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">

                          <select formControlName="Mobile1CountryCode" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected value="1">Mobile Country Code</option>
                            <option value="91">IND</option>
                            <option value="92">PAK</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Address</label>
                          <input type="date" formControlName="DateOfBirth" class="form-control my-filed"
                            id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                      </div>



                    </div>
                    <div class="row">
                      <h1 class="travelr-Head">Document Details</h1>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="IdType" class="form-select my-filed"
                            aria-label="Default select example">
                            <!-- <option selected>ID Type</option> -->
                            <option value="2">Passport</option>
                            <option value="3">Nation ID</option>
                            <option value="3">Iqama</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select id="dropdowns" formControlName="IssuedCountryCode" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected>Issued Country Code</option>
                            <option value="IN">INDIA</option>
                            <option value="PAK">PAKISTAN</option>
                            <option value="DXB">DUBAI</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="CountryCode" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected> Country Code</option>
                            <option value="IN">INDIA</option>
                            <option value="PAK">PAKISTAN</option>
                            <option value="DXB">DUBAI</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="CountryName" placeholder="f" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected>Country Name</option>
                            <option value="India">india</option>
                            <option value="dubai">dubai</option>
                            <option value="pakistan">pakistab</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="PassportNo" type=" " id="validationCustom01"
                            class="form-control my-filed" placeholder="ID Number" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="IdNumber" type="number" id="validationCustom01"
                            class="form-control my-filed" placeholder="ID Number" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Passport
                            Expiry</label>
                          <input type="date" formControlName="PassportExpiry" class="form-control my-filed"
                            id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">ExpiryDate</label>
                          <input type="date" formControlName="ExpiryDate" class="form-control my-filed"
                            id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label">IssueDate</label>
                                                    <input type="date" formControlName="IssueDate"
                                                        class="form-control my-filed" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp">
                                                </div>
                                            </div> -->
                    </div>
                    <div class="row">
                      <div class="mb-3 form-check">
                        <input (click)="ihaveGst($event.target.checked)" type="checkbox" class="form-check-input "
                          id="exampleCheck1">
                        <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                          GST
                          number (Optional)</label>
                      </div>



                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Company Name</label>
                          <input type="email" class="form-control my-filed" id="exampleInputEmail1"
                            aria-describedby="emailHelp">
                        </div>
                      </div>
                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Select State</label>
                          <select class="form-select my-filed" aria-label="Default select example">

                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Address</label>
                          <input type="email" class="form-control my-filed" id="exampleInputEmail1"
                            aria-describedby="emailHelp">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
              <form *ngIf="true" [formGroup]="dynamicForm" class="g-3 needs-validation">
                <div formArrayName="formFields">
                  <div *ngFor="let field of formFields.controls; let i = index" [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-2">
                        <select formControlName="Type" class="form-select my-filed" aria-label="Default select example">
                          <option [value]="null" disabled selected>Select type</option>
                          <option selected value=1>Adult</option>
                          <option value=2>Child</option>
                          <option value=3>Infant</option>
                          <option value=4>Senior</option>
                          <option value=3>Youth</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select formControlName="Title" class="form-select my-filed"
                          aria-label="Default select example">
                          <option [value]="null" disabled selected>Select Title</option>
                          <option value="Mr">Mr</option>
                          <option value="Miss">Miss</option>
                          <option value="Mrs">Mrs</option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="FirstName" type="email" id="validationCustom01"
                            class="form-control my-filed" placeholder="First Name" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="LastName" type="email" class="form-control my-filed"
                            placeholder="Last Name" required>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">

                          <mat-button-toggle-group formControlName="Gender" name="fontStyle" aria-label="Font Style">
                            <mat-button-toggle value=1>Male</mat-button-toggle>
                            <mat-button-toggle value=2>Female</mat-button-toggle>
                          </mat-button-toggle-group>

                        </div>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="mb-3">
                          <input formControlName="Email" type="email" id="validationCustom01"
                            class="form-control my-filed" placeholder="Email" required>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="mb-3">

                          <div class="mb-3">

                            <ngx-intl-tel-input formControlName="Mobile1" [preferredCountries]="['us', 'gb']">
                            </ngx-intl-tel-input>
                          </div>

                        </div>
                      </div>

                      <div class="col-md-3">

                        <input type="text" [bsConfig]="BsDatepickerConfig" placeholder="Date Of Birth"
                          formControlName="DateOfBirth Ex: 10/20/2023" class="form-control my-filed" bsDatepicker>
                      </div>



                    </div>
                    <div class="row">
                      <h1 class="travelr-Head">Document Details</h1>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="IdType" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected>ID Type</option>
                            <option value="2">Passport</option>
                            <option value="3">Nation ID</option>
                            <option value="3">Iqama</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="IssuedCountryCode" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected>Issued Country Code</option>
                            <option value="IN">INDIA</option>
                            <option value="PAK">PAKISTAN</option>
                            <option value="DXB">DUBAI</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="CountryCode" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected> Country Code</option>
                            <option value="IN">INDIA</option>
                            <option value="PAK">PAKISTAN</option>
                            <option value="DXB">DUBAI</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <select formControlName="CountryName" placeholder="f" class="form-select my-filed"
                            aria-label="Default select example">
                            <option selected>Country Name</option>
                            <option value="India">india</option>
                            <option value="dubai">dubai</option>
                            <option value="pakistan">pakistab</option>
                          </select>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                                                <div class="mb-3">
                                                    <input formControlName="PassportNo" type=" " id="validationCustom01"
                                                        class="form-control my-filed" placeholder="ID Number" required>
                                                </div>
                                            </div> -->
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input formControlName="IdNumber" type="number" id="validationCustom01"
                            class="form-control my-filed" placeholder="ID Number" required>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">

                          <input type="text" placeholder="Passport Expiry Ex: 10/20/2023"
                            [bsConfig]="BsDatepickerConfig" formControlName="PassportExpiry"
                            class="form-control my-filed" bsDatepicker>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <input type="text" placeholder="ExpiryDatess" [bsConfig]="BsDatepickerConfig"
                            formControlName="ExpiryDate Ex: 10/20/2023" class="form-control my-filed" bsDatepicker>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                                                <div class="mb-3">
    
                                                    <input type="text" placeholder="IssueDate" formControlName="IssueDate"
                                                        class="form-control my-filed" bsDatepicker>
                                                </div>
                                            </div> -->
                    </div>
                    <div class="row">
                      <div class="mb-3 form-check">
                        <input (click)="ihaveGst($event.target.checked)" type="checkbox" class="form-check-input "
                          id="exampleCheck1">
                        <label class="form-check-label travelr-Head" for="exampleCheck1">I have a
                          GST
                          number (Optional)</label>
                      </div>



                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Company Name</label>
                          <input type="email" class="form-control my-filed" id="exampleInputEmail1"
                            aria-describedby="emailHelp">
                        </div>
                      </div>
                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Select State</label>
                          <select class="form-select my-filed" aria-label="Default select example">

                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div>
                      <div *ngIf="gstNo" class="col-md-3">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">Address</label>
                          <input type="email" class="form-control my-filed" id="exampleInputEmail1"
                            aria-describedby="emailHelp">
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <button class="btn btn-danger" (click)="addFormField()">Add Form</button>
                <button class="btn btn-success" (click)="CheckValue()">Save Details</button>
              </form>


            </div>
            <!-- <button (click)="isIsConfirmed()" class="ContinueBtns">Procced to Pay</button> -->
          </div>
          <div class="col-md-3">
            <div class="fareDetails">
              <h1 class="summary">Fare Summary</h1>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      <!-- <span class="accordion-icon"></span> -->
                      <ul class="BaseINfo">
                        <li>
                          <p class="basing">Base Fare</p>
                        </li>
                        <li>
                          <p class="basing"></p>
                        </li>
                      </ul>
                    </button>

                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">

                      <div class="fareList">
                        <ul *ngFor="let fare of fareBreak" class="farebreak">
                          <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                            <span *ngIf="fare.PassengerType == 2">Child </span>
                            <span *ngIf="fare.PassengerType == 3">Infant </span>

                            ({{fare.PassengerCount}})
                          </li>

                          <li>{{fare.TotalFare | number:'1.0-2'}} {{fare.Currency }}</li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      <!-- <span class="accordion-icon"></span> -->
                      <ul class="BaseINfo">
                        <li>
                          <p class="basing">Taxes and Surcharges</p>
                        </li>
                        <li>
                          <p class="basing"></p>
                        </li>
                      </ul>
                    </button>

                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">

                      <ul *ngFor="let fare of fareBreak" class="farebreak">
                        <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                          <span *ngIf="fare.PassengerType == 2">Child </span>
                          <span *ngIf="fare.PassengerType == 3">Infant </span>

                          ({{fare.PassengerCount}})
                        </li>

                        <li>{{fare.Tax | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{fare.Currency }} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>

                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      <!-- <span class="accordion-icon"></span> -->
                      <ul class="BaseINfo">
                        <li>
                          <p class="basing">Other Charges</p>
                        </li>
                        <li>
                          <p class="basing"></p>
                        </li>
                      </ul>
                    </button>

                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">

                      <ul *ngFor="let fare of fareBreak" class="farebreak">
                        <li><span *ngIf="fare.PassengerType == 1">Adult </span>
                          <span *ngIf="fare.PassengerType == 2">Child </span>
                          <span *ngIf="fare.PassengerType == 3">Infant </span>

                          ({{fare.PassengerCount}})
                        </li>

                        <li>{{fare.ServiceFee  | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                          <!-- {{fare.Currency}} -->
                          {{selectedCurrency}}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="total">
                <li>
                  <h1 class="summary">Total Amount</h1>
                </li>
                <li>
                  <h1 class="summary">{{finalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                    <!-- {{currencyTupe}} -->
                    {{selectedCurrency}}

                  </h1>
                </li>
              </ul>
            </div>

            <div class="fareDetails">
              <h1 class="summarys">Devotion Offers</h1>

              <form action="">
                <input type="text" class="ApplyCo" placeholder="Apply Coupon">
                <button class="AppyBtns">Apply</button>
              </form>
              <div class="coupanset">
                <mat-radio-button value="1">
                  <p class="coupanName">GIRUSH</p>
                </mat-radio-button>
                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                </p>
              </div>
              <div class="coupanset">
                <mat-radio-button value="1">
                  <p class="coupanName">GIRUSH</p>
                </mat-radio-button>
                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                </p>
              </div>
              <div class="coupanset">
                <mat-radio-button value="1">
                  <p class="coupanName">GIRUSH</p>
                </mat-radio-button>
                <p class="useCoupan">Use this coupon and get Rs 224 instant discount on your flight booking.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>



  <div>

  </div>
</div>
</div>



<!-- Fare Rule -->

<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModalFareRule" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Fare rules</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div class="fareRuleStyle">
      
        <div [innerHTML]="fareRuleInfo?.FareRuleDetail"></div>
      </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="exampleModalFareRuleReturn" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Fare rules</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div [innerHTML]="fareRuleInfoReturn?.FareRuleDetail"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>








<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div style="opacity: 0;" class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" #closebuyyer22 class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="padding: 5rem">
        <p class="textFare">The selected fare has expired. Please choose another fare</p>
        <button (click)="back()" class="fareExpButtn">OK</button>
      </div>

    </div>
  </div>
</div>





<!-- Modal -->
<div class="modal fade" id="exampleModalPrice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Fare updated</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <!-- <div class="modal-body">
        {{bookTicketObj?.Itinerary?.Origin }}  ->   
        {{finalPrice | number:'1.0-2'}} -> {{finalPriceModifyied  | number:'1.0-2' }}

      </div> -->
      <div class="modal-body Mbdy-edit">
        <div class="flight-BookingBody">
          <div class="body-top-section">
            <h4 class="flight-header">Flight</h4>
            <ul class="depart-topSection list-unstyled d-flex justify-content-between">
              <li class="Depart-destinationLists">
                <h2 class="depart-place"> {{bookTicketObj?.Itinerary?.Origin }}
                  <!-- <span class="start-time">14:35</span -->
                </h2>
              </li>
              <li class=" Depart-destinationLists ">
                <div class="center-section"><img class="dot-flightImage"
                    src="./../../../../assets/images/to-destination.png" alt=""><span class="to-edit">To</span></div>
              </li>
              <li class="Depart-destinationLists">
                <h2 class="depart-place"> {{bookTicketObj?.Itinerary?.Destination }}
                  <!-- <span class="start-time">14:35</span -->
                </h2>
              </li>

            </ul>
            <p class="changed-fare">Flight Fare will be changed</p>
            <h4 class="price-change">{{finalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} <span class="to-spacing">to</span>
              {{bookTicketObj?.isPriceChangeObject?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </h4>
            <div class="bottom-buttons"><button (click)="agreeWithNewPrice()" class="proceed-btn">Proceed</button>
              <button class="cancel-btn"  routerLink="/home" data-bs-dismiss="modal">Cancel</button>
            </div>

          </div>
        </div>


      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Proceed</button>
      </div> -->
    </div>
  </div>
</div>



<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="staticDataBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <div class="modal-body Mbdy-edit">
        <div class="flight-BookingBody">
          <div class="body-top-section">
      

            <div *ngIf="bookTicketObj?.isPriceChangedKey" id="oneWay">
              <h4 class="flight-header">Flight</h4>
              <ul class="depart-topSection list-unstyled d-flex justify-content-between">
                <li class="Depart-destinationLists">
                  <h2 class="depart-place"> {{bookTicketObj?.Itinerary?.Origin }}
                    <!-- <span class="start-time">14:35</span -->
                  </h2>
                </li>
                <li class=" Depart-destinationLists ">
                  <div class="center-section"><img class="dot-flightImage"
                      src="./../../../../assets/images/to-destination.png" alt=""><span class="to-edit">To</span></div>
                </li>
                <li class="Depart-destinationLists">
                  <h2 class="depart-place"> {{bookTicketObj?.Itinerary?.Destination }}
                    <!-- <span class="start-time">14:35</span -->
                  </h2>
                </li>

              </ul>

              <p class="changed-fare">Flight Fare will be changed</p>
              <h4 class="price-change">{{finalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} <span class="to-spacing">to</span>
                {{bookTicketObj?.isPriceChangeObject?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </h4>
            </div>
            <div *ngIf="bookTicketReturnObj?.isPriceChangedKey" id="twoWay" class="bottom-buttons">

                <h4 class="flight-header">Return</h4>
              <ul class="depart-topSection list-unstyled d-flex justify-content-between">
                <li class="Depart-destinationLists">
                  <h2 class="depart-place"> {{bookTicketReturnObj?.Itinerary?.Origin }}
                    <!-- <span class="start-time">14:35</span -->
                  </h2>
                </li>
                <li class=" Depart-destinationLists ">
                  <div class="center-section"><img class="dot-flightImage"
                      src="./../../../../assets/images/to-destination.png" alt=""><span class="to-edit">To</span></div>
                </li>
                <li class="Depart-destinationLists">
                  <h2 class="depart-place"> {{bookTicketReturnObj?.Itinerary?.Destination }}
                    <!-- <span class="start-time">14:35</span -->
                  </h2>
                </li>

              </ul>
              <p class="changed-fare">Flight Fare will be changed</p>
              <h4 class="price-change">{{finalPrice2 | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} <span class="to-spacing">to</span>
                {{bookTicketReturnObj?.isPriceChangeObject?.Fare?.TotalFare | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </h4>
            
            </div>
            <div class="bottom-buttons">
            <button (click)="agreeWithNewPriceTwo()" (click)="agreeWithNewPriceReturn()"
            class="proceed-btn">Proceed</button>
          <button routerLink="/home" class="cancel-btn" data-bs-dismiss="modal">Cancel</button></div>
          </div>
        </div>


      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button  type="button" class="btn btn-primary">Understood</button>
      </div> -->
    </div>
  </div>
</div>


