<div class="allPages">

<div class="pacagesNew">
    <div class="home-page">
        <div class="stayBox">
            <div class="container">
                <div class="contents">


                    <div class=" destination-search-below justify-content-center">
                        <div class="tab-content bottomTab-Contents" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-Package" role="tabpanel" aria-labelledby="pills-Package-tab">
                              <div class="package-content">
                               
                                <div class="row justify-content-center g-0">
                                  <div class="col-md-6 ">
                                    <div class="dropdown form-group Pform-boxes Pleft-borders">
                                      <div (click)="selectInout()" class="dropdown-toggle Pd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <label for="djfjsf" class="d-block Pcity-label">FIND HOLIDAYS BY DESTINATION</label>
                                        <h1 class="Psearch-cities">
                                          <img class="Psearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{countryName}}
                                        </h1>
                                      </div>
                                      <ul #closeDate class="dropdown-menu PdDown-lists" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                          <a class="dropdown-item Pd-item Psearchbox-top"><img class="Psrch-iccons"
                                              src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocus placeholder="Search here" [(ngModel)]="searchText" class="Psrch-box"
                                              type="text" /></a>
                                        </li>
                                        <li>
                                          <ul class="Pciti-namess list-unstyled m-0">
                                            <li (click)="selectCountryItem(item)" *ngFor="let item of allCOuntryList | filter: searchText">
                                              <a class="dropdown-item Pd-item">{{item.name}}</a>
                                            </li>
                                            
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div  class="Pform-boxes Pright-borders">
                                      <div class="dropdown-toggle Pd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <label for="citizen" class="d-block Pcity-label">SELECT DATE</label>
                      
                                        <h1 class="Psearch-cities">
                                          <img class="Psearch-iccon" src="./../../../assets/images/m/calender.svg" alt="" />{{ selected | date: 'dd' }}<small
                                            class="Pdate-data">{{ selected | date: 'MMM' }},{{ selected | date: 'yyyy' }}</small>
                                          <span class="Pweek-days">{{ selected | date: 'EEEE' }}</span>
                                        </h1>
                                      </div>
                                      <button (click)="gotoPackageByCOuntryName()" class="PsrchBTN-inside d-none d-md-block">
                                        Search
                                      </button>
                                      <ul  id="dropdownContainer" (click)="onDropdownClick($event)" class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">
                      
                      
                                        <div class="packageCallender">
                      
                                          <mat-card (click)=" getFormattedDate()"  class="demo-inline-calendar-card">
                                            <mat-calendar [(selected)]="selected" (selectedChange)="onDateSelected($event)"></mat-calendar>
                      
                                          </mat-card>
                      
                      
                                        </div>
                      
                      
                      
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="Pbtn-beloww d-flex justify-content-center d-md-none">
                                  <button  class="PsrchBTN-outside">Search</button>
                                </div>
                              </div>
                            </div>
                          
                          </div>

                    </div>
                </div>
            </div>
        </div>




        
        <div class="Devotion2Bottom">
            <div class="container">
                <div class="row">

                  <!-- <p  class="noPckgs"  *ngIf="packageData.length <=0"> No Packages</p> -->
                    <div   class="col-lg-4">
                        <!-- -- Flight Search Left Part Start  -- -->
                        <div class="OffCanvasData">
                            <button class="btn btn-primary FilterBtn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                <img src="/images/filter.png" alt=""><span style="color: #000;">Filters</span>
                              </button>
                              
                              <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                <div class="offcanvas-header">
                                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                  <!-- <div>
                                    Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                                  </div> -->
                          
                                  <div class="SearchFlightsLeft">
                                    <div class="FlightTop">
                                        <p class="FlightTopTitle">FLIGHTS</p>
                                        <!-- <label class="WithFlight">
                                            <input (click)="selectFlight(true)" class="WithFlightInput" type="radio" name="flightOption" value="withFlight"> With Flight
                                        </label>
                                        
                                        <label class="WithFlight">
                                            <input  (click)="selectFlight(false)" class="WithFlightInput" type="radio" name="flightOption" value="withoutFlight"> Without Flight
                                        </label> -->
                                        <label class="radio">
                                          <input (click)="selectFlight(true)" name="radio" type="radio" checked>
                                          <span>With Flight</span>
                                        </label>
                                        <label class="radio">
                                          <input (click)="selectFlight(false)" name="radio" type="radio">
                                          <span>Without Flight</span>
                                        </label>
                                    </div>
               <!--  range 2  -->
               
                                    <div class="BudgetData">
                                        <p class="BudgetDataTitle">Budget below {{selectedPrice   | priceExchange:selectedCurrency | async   | number:'1.0-2'}} </p>
                                        <input  
                                        
                                        [(ngModel)]="selectedPrice"
                                        (input)="onRangePrice()"
                                        [min]="minPrice"
                                        [max]="maxPrice"
                                         class=" range-input" type="range" id="range2" name="priceRange">
                                        <p class="BudgetDataPrice"><span id="selectedPrice">{{filterObject.lowestPrice}} </span>{{filterObject.highestPrice   | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                                    </div>
            
                                    <div class="BudgetData">
                                        <p class="BudgetDataTitle">Time  Below {{selectedValue}} N</p>
                                        <input  
                                        [(ngModel)]="selectedValue" 
                                        (input)="onRangeChange()" 
                                        class=" range-input" 
                                        type="range" id="range2" name="priceRange" 
                                        [min]="minValue"
                                        [max]="maxValue">
                                        <p class="BudgetDataPrice"><span id="selectedPrice">{{filterObject.lowestNights}}N</span> {{filterObject.highestNights}}N</p>
                                    </div>
            
            
                                    <div class="FlightTop HolidayType">
                                        <p class="FlightTopTitle">RECOMMENDED FOR</p>
            
                                        <label *ngFor="let item of filterObject.recommendedForValues" class="WithFlight">
                                            <input (change)="updateSelectedValues(item)" class="WithFlightInput" type="checkbox" name="flightOption" value="withFlight"> {{item}}
                                        </label>
                                     
                                    </div>
            
                                
                                    <div class="FlightTop HolidayType">
                                        <p class="FlightTopTitle">CITIES THE TRIP SHOULD INCLUDE</p>
            
                                        <label *ngFor="let item of filterObject.cityTitleValues" class="WithFlight">
                                            <input (change)="updateSelectedValuesCity(item)" class="WithFlightInput" type="checkbox" name="flightOption" value="withFlight">{{item}}
                                        </label>
                                          
                                     
                                    </div>
                                </div>
                                </div>
                              </div>
                        </div>


                        <!-- <div class="FilterSubmit">

                            <div class="FilterSubmitInside">
                                <img class="FilterImg" src="/images/filter.png" alt="Filter">
                                <button class="DoneButton" type="button">Done</button>
                            </div>
                        </div> -->
                      
                        <div class="SearchFlightsLeft">
                            <div class="FlightTop">
                                <p class="FlightTopTitle">FLIGHTS</p>
                                <!-- <label class="WithFlight">
                                    <input (click)="selectFlight(true)" class="WithFlightInput" type="radio" name="flightOption" value="withFlight"> With Flight
                                </label>
                                
                                <label class="WithFlight">
                                    <input  (click)="selectFlight(false)" class="WithFlightInput" type="radio" name="flightOption" value="withoutFlight"> Without Flight
                                </label> -->
                                <label class="radio">
                                  <input (click)="selectFlight(true)" name="radio" type="radio" checked>
                                  <span>With Flight</span>
                                </label>
                                <label class="radio">
                                  <input (click)="selectFlight(false)" name="radio" type="radio">
                                  <span>Without Flight</span>
                                </label>
                            </div>
       <!--  range 2  -->
       
                            <div class="BudgetData">
                                <p class="BudgetDataTitle">Budget below {{selectedPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                                <input  
                                
                                [(ngModel)]="selectedPrice"
                                (input)="onRangePrice()"
                                [min]="minPrice"
                                [max]="maxPrice"
                                 class=" range-input" type="range" id="range2" name="priceRange">
                                <p class="BudgetDataPrice"><span id="selectedPrice">{{filterObject.lowestPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</span>{{filterObject.highestPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                            </div>
    
                            <div class="BudgetData">
                                <p class="BudgetDataTitle">Time  Below {{selectedValue}} N</p>
                                <input  
                                [(ngModel)]="selectedValue" 
                                (input)="onRangeChange()" 
                                class=" range-input" 
                                type="range" id="range2" name="priceRange" 
                                [min]="minValue"
                                [max]="maxValue">
                                <p class="BudgetDataPrice"><span id="selectedPrice">{{filterObject.lowestNights}}N</span> {{filterObject.highestNights}}N</p>
                            </div>
    
    
                            <div class="FlightTop HolidayType">
                                <p class="FlightTopTitle">RECOMMENDED FOR</p>
    
                                <label *ngFor="let item of filterObject.recommendedForValues" class="WithFlight">
                                    <input (change)="updateSelectedValues(item)" class="WithFlightInput" type="checkbox" name="flightOption" value="withFlight"> {{item}}
                                </label>
                             
                            </div>
    
                        
                            <div class="FlightTop HolidayType">
                                <p class="FlightTopTitle">CITIES THE TRIP SHOULD INCLUDE</p>
    
                                <label *ngFor="let item of filterObject.cityTitleValues" class="WithFlight">
                                    <input (change)="updateSelectedValuesCity(item)" class="WithFlightInput" type="checkbox" name="flightOption" value="withFlight">{{item}}
                                </label>
                                  
                             
                            </div>
                        </div>
                        <!-- -- Flight Search Left Part End  -- -->
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <!-- -- Flight Search Left Part Start  -- -->
                        <p class="noPckgs" *ngIf="packageData.length <=0">No Packages</p>
                        <div *ngFor="let item of packageData" class="SearchFlightsRight" id="SearchFlightsRight1">
                            <ul class="FreeAndEasy">
                                <li class="FreeEasyLI">
                                    <div id="DivCarouselA1" class="DivCarousel">
                                        <div class="DivCarouselTop">
                                            <img class="DivCarouselTopImg" [src]="item.thumbnail" alt="no-data">
                                        </div>
                                        <!-- <div class="DivCarouselBottom">
                                            <img *ngFor="let img of item.images" class="DivCarouselTopImgs" [src]="img.image" alt="no-data">
                                      
                                        </div> -->
                                    </div>
                                </li>
                                <li class="FreeEasyLI">
                                    <div class="BaliMiddleContent">
                                        <p class="BaliContentA">{{item.title}}</p>
                                        <p class="BaliContentB">{{item.nightsCount}} Nights & {{item.daysCount}} Days</p>
                                        <p class="BaliContentC"><i class="fas fa-map-marker-alt BaliContentLocation"></i>{{item.country}}</p>
                                        <ul class="IconULData">
                                            <li class="IconLIData">
                                                <img class="IconLiImage" src="./../../../assets/images/m/IconA.png" alt="no-data">
                                                <p class="IconLiContent">Transfer</p>
                                            </li>
                                            <li class="IconLIData">
                                                <img class="IconLiImage" src="./../../../assets/images/m/IconB.png" alt="no-data">
                                                <p class="IconLiContent">Hotel</p>
                                            </li>
                                            <li class="IconLIData">
                                                <img class="IconLiImage" src="./../../../assets/images/m/IconC.png" alt="no-data">
                                                <p class="IconLiContent">Meal</p>
                                            </li>
                                            <li class="IconLIData">
                                                <img class="IconLiImage" src="./../../../assets/images/m/IconD.png" alt="no-data">
                                                <p class="IconLiContent">Sightseeing</p>
                                            </li>
                                        </ul>
                                        <ul class="DefaultUlText">
                                            <li class="DefaultLiText">Recommended For :  {{item.recommendedFor}}</li>
                                           
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <div class="BaliEndContent">
                                <div class="BaliEndContentTop">
                                    <p class="BaliEndContentTitle">Starting Price Per Adult</p>
                                    <p class="BaliEndContentPrice">{{item.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</p>
                                </div>
                                <button (click)="getMoreDetails(item)" class="BaliEndContentButton" type="button">View Details</button>
                            </div>
                        </div>

                       
                        <!-- -- Flight Search Left Part End  -- -->
                    </div>
                </div>
            </div>
        </div>

    </div>

  </div>