<button style="opacity: 0;" #tryToOpenVisa data-bs-toggle="modal" data-bs-target="#exampleModal"></button>

<div class="allPages">
  <div class="slidemain-section">

    <div class="owl-carousel owl-theme" id="slidemain">
      <div class="item active">
        <img class="imgsss" src="./../../../assets/images/desert.png" alt="no-image">
      </div>
      <div class="item">
        <img class="imgsss" src="./../../../assets/images/landingpagesliders/2.png" alt="no-image">
      </div>
      <div class="item">
        <img class="imgsss" src="./../../../assets/images/landingpagesliders/4.png" alt="no-image">
      </div>
      <div class="item">
        <img class="imgsss" src="./../../../assets/images/landingpagesliders/5.png" alt="no-image">
      </div>
    </div>
  
  
    <div  class="maintext-sec">
      <div  class="maintext">
        <div class="maintext-clk">
          <ul  class="clk-lis">
            <li class="clk-sp">
              <div class="clock-sec">
                <div class="clock">
                  <div class="indicator">
                    <span class="hand hour5"><img class="hourNiddile" src="./../../../assets/images/watch/hour niddel.svg"
                        alt=""></span>
                    <span class="hand minute5"><img class="minuteNiddle"
                        src="./../../../assets/images/watch/Group 13778.svg" alt=""></span>
                    <span class="hand second5"><img class="sceondsNiddle"
                        src="./../../../assets/images/watch/secNiddle.svg" alt=""></span>
                  </div>
                </div>
                <h6 class="country-name">SINGAPORE</h6>
              </div>
            </li>
            <li class="clk-sp">
              <div  class="clock-sec">
                <div class="clock">
                  <div class="indicator">
                    <span class="hand hour"><img class="hourNiddile" src="./../../../assets/images/watch/hour niddel.svg"
                        alt=""></span>
                    <span class="hand minute"><img class="minuteNiddle"
                        src="./../../../assets/images/watch/Group 13778.svg" alt=""></span>
                    <span class="hand second"><img class="sceondsNiddle"
                        src="./../../../assets/images/watch/secNiddle.svg" alt=""></span>
                  </div>
                </div>
                <h6 class="country-name">NEW DELHI</h6>
              </div>
            </li>
            <li class="clk-sp">
              <div class="clock-sec">
                <div class="clock">
                  <div class="indicator">
                    <span class="hand2 hour2"><img class="hourNiddile"
                        src="./../../../assets/images/watch/hour niddel.svg" alt=""></span>
                    <span class="hand2 minute2"><img class="minuteNiddle"
                        src="./../../../assets/images/watch/Group 13778.svg" alt=""></span>
                    <span class="hand2 second2"><img class="sceondsNiddle"
                        src="./../../../assets/images/watch/secNiddle.svg" alt=""></span>
                  </div>
                </div>
                <h6 class="country-name">DUBAI</h6>
              </div>
            </li>
            <li class="clk-sp">
              <div class="clock-sec">
                <div class="clock">
                  <div class="indicator">
                    <span class="hand hour3"><img class="hourNiddile" src="./../../../assets/images/watch/hour niddel.svg"
                        alt=""></span>
                    <span class="hand minute3"><img class="minuteNiddle"
                        src="./../../../assets/images/watch/Group 13778.svg" alt=""></span>
                    <span class="hand second3"><img class="sceondsNiddle"
                        src="./../../../assets/images/watch/secNiddle.svg" alt=""></span>
                  </div>
                </div>
                <h6 class="country-name">LONDON</h6>
              </div>
            </li>
            <li class="clk-sp">
              <div class="clock-sec">
                <div class="clock">
                  <div class="indicator">
                    <span class="hand hour4"><img class="hourNiddile" src="./../../../assets/images/watch/hour niddel.svg"
                        alt=""></span>
                    <span class="hand minute4"><img class="minuteNiddle"
                        src="./../../../assets/images/watch/Group 13778.svg" alt=""></span>
                    <span class="hand second4"><img class="sceondsNiddle"
                        src="./../../../assets/images/watch/secNiddle.svg" alt=""></span>
                  </div>
                </div>
                <h6 class="country-name">NEW YORK</h6>
              </div>
            </li>
  
          </ul>
        </div>
        <h1 class="mainslide-head" data-aos="zoom-in" data-aos-duration="1200">Your Premier Sightseeing Expert in
          Dubai</h1>
          <div class="scrollbottom">
            <div class="scrlimdi">
              <ng-lottie class="scrlim" (click)="scrollToSection(travellerDetails)" [options]="options"
                (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
    
          </div>
      </div>
  
    </div>
  
  </div>
</div>


<div id="travellerDetails" class="container">

  <div class="diff-tabsTop">
    <ul class="nav nav-pills bookingPillss-top" id="pills-tab" role="tablist">
      <li class="nav-item packagesTop-items" role="presentation">
        <button (click)="getCountry()" id="pills-Flight-tab" data-bs-toggle="pill"
        data-bs-target="#pills-Flight"  class="nav-link packages-pillss package-border active"  type="button" role="tab" aria-controls="pills-Package" aria-selected="true">
    

          <img class="linked-images" src="./../../../assets/images/m/actflight1.png" alt="packge" />
          <div class="act-images">
            <img class="linked-images1" src="./../../../assets/images/m/flight1.png" alt="packge" />
          </div>

           Flight
        </button>
      </li>
 
      <!-- <li class="nav-item packagesTop-items" role="presentation">
        <button class="nav-link packages-pillss" id="pills-Hotel-tab" data-bs-toggle="pill"
          data-bs-target="#pills-Hotel" type="button" role="tab" aria-controls="pills-Hotel" aria-selected="false">
          <img class="linked-images" src="./../../../assets/images/m/acthotel1.png" alt="packge" />
          <div class="act-images">
            <img class="linked-images1" src="./../../../assets/images/m/hotel1.png" alt="packge" />
          </div>

          Hotel
        </button>
      </li> -->
      <li class="nav-item packagesTop-items" role="presentation">
        <button class="nav-link packages-pillss" id="pills-Visa-tab" data-bs-toggle="pill" data-bs-target="#pills-Visa"
          type="button" role="tab" aria-controls="pills-Visa" aria-selected="false">
          <img class="linked-images" src="./../../../assets/images/m/actvisa1.png" alt="packge" />
          <div class="act-images">
            <img class="linked-images1" src="./../../../assets/images/m/visa1.png" alt="packge" />
          </div>

          Visa
        </button>
      </li>
      <li class="nav-item packagesTop-items" role="presentation">
        <button  id="pills-Package-tab" data-bs-toggle="pill"
        data-bs-target="#pills-Package"  class="nav-link packages-pillss"  type="button" role="tab" aria-controls="pills-Flight" aria-selected="false">
        <img class="linked-images linkedPckage-image" src="./../../../assets/images/m/actpackage1.png" alt="packge" />
        <div class="act-images">
          <img class="linked-images1 linkedPckage-image" src="./../../../assets/images/m/package1.png" alt="packge" />
        </div>
          Package
          
        </button>
      </li>
      <li class="nav-item packagesTop-items" role="presentation">
        <button class="nav-link packages-pillss right-borderr" id="pills-Car-tab" data-bs-toggle="pill"
          data-bs-target="#pills-Car" type="button" role="tab" aria-controls="pills-Car" aria-selected="false">
          <img class="linked-images linkedCar-image" src="./../../../assets/images/m/actcar1.png" alt="packge" />
          <div class="act-images">
            <img class="linked-images1 linkedCar-image" src="./../../../assets/images/m/car1.png" alt="packge" />
          </div>

          Car
        </button>
      </li>
    </ul>
    
    <div class="tab-content bottomTab-Contents" id="pills-tabContent">
      <div class="tab-pane fade " id="pills-Package" role="tabpanel" aria-labelledby="pills-Package-tab">
        <div class="package-content">
          <h5 class="Pbook-domestic">
            Book Domestic and International Holiday Packages
          </h5>
          <div class="row justify-content-center g-0">
            <div class="col-md-6 mb-2 p-0">
              <div class="dropdown form-group Pform-boxes Pleft-borders">
                <div (click)="selectInout()" class="dropdown-toggle Pd-toggle" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <label for="djfjsf" class="d-block Pcity-label">FIND HOLIDAYS BY DESTINATION</label>
                  <h1 class="Psearch-cities">
                    <img class="Psearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{countryName}}
                  </h1>
                </div>
                <ul #closeDate class="dropdown-menu PdDown-lists" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item Pd-item Psearchbox-top"><img class="Psrch-iccons"
                        src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocus
                        [(ngModel)]="searchText" placeholder="Search Country or City" class="Psrch-box"
                        type="text" /></a>
                  </li>
                  <li>
                    <ul class="Pciti-namess list-unstyled m-0">
                      <li (click)="selectCountryItem(item)" *ngFor="let item of allCOuntryList | filter: searchText">
                        <a class="dropdown-item Pd-item">{{item.name}}</a>
                      </li>

                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mb-2 p-0">
              <div class="Pform-boxes Pright-borders">
                <div class="dropdown-toggle Pd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <label for="citizen" class="d-block Pcity-label">SELECT DATE</label>

                  <h1 class="Psearch-cities">
                    <img class="Psearch-iccon" src="./../../../assets/images/m/calender.svg" alt="" />{{ selected |
                    date: 'dd' }}<small class="Pdate-data">{{ selected | date: 'MMM' }},{{ selected | date: 'yyyy'
                      }}</small>
                    <span class="Pweek-days">{{ selected | date: 'EEEE' }}</span>
                  </h1>
                </div>
                <button (click)="gotoPackageByCOuntryName()" class="PsrchBTN-inside d-none d-md-block">
                  Search
                </button>
                <ul id="dropdownContainer" (click)="onDropdownClick($event)"
                  class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">


                  <div class="packageCallender">

                    <mat-card (click)=" getFormattedDate()" class="demo-inline-calendar-card">
                      <mat-calendar [minDate]="minDates" [(selected)]="selected" (selectedChange)="onDateSelected($event)"></mat-calendar>

                    </mat-card>


                  </div>



                </ul>
              </div>
            </div>
          </div>
          <div class="Pbtn-beloww d-flex justify-content-center d-md-none">
            <button (click)="gotoPackageByCOuntryName()" class="PsrchBTN-outside">Search</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade show active" id="pills-Flight" role="tabpanel" aria-labelledby="pills-Flight-tab">
        <div class="flightData-content">
          <ul class="nav nav-pills flightPils-top mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="selectWay('1')" class="nav-link one-wayBtn active" id="pills-one-tab"
                data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one"
                aria-selected="true">
                <input autofocus class="form-check-input round-checkk" checked type="radio" name="flexRadioDefault"
                  id="flexRadioDefault1" /><span class="cityTys">One Way</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button #roundTripsSelected (click)="selectWay('2')" class="nav-link one-wayBtn" id="pills-round-tab" data-bs-toggle="pill"
                data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="false">
                <input class="form-check-input round-checkk" type="radio" name="flexRadioDefault"
                  id="flexRadioDefault1" /><span class="cityTys">Round trip</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link one-wayBtn" id="pills-multi-tab" data-bs-toggle="pill"
                data-bs-target="#pills-multi" type="button" role="tab" aria-controls="pills-multi"
                aria-selected="false">
                <input class="form-check-input round-checkk" type="radio" name="flexRadioDefault"
                  id="flexRadioDefault1" /><span class="cityTys">Multi City</span>
              </button>
            </li>
          </ul>
          <div class="tab-content subTab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
              <div class="oneWay-dataBelow">
                <div class="oneWay-box">
                  <div class="row justify-content-center g-0">
                    <div class="col-6 mb-2 mb-lg-0 p-0 col-lg-2">
                      <div class="dropdown form-group oneform-boxes left-borders">
                        <div (click)="oroingFocus()" class="dropdown-toggle d-toggle" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">FROM</label>
                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{origin
                            }}<span class="week-days d-none d-md-block">{{originAirport}}</span>
                          </h1>
                        </div>
                        <span><img (click)="swap()" src="./../../../assets/images/m/swap.svg" alt="swap"
                            class="swap-image" /></span>
                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input #originFocus
                                [(ngModel)]="searchFlight"  (keyup)="getKeypData($event)" class="srch-box" placeholder="Search here" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li *ngFor="let item of searchCountryList"
                                (click)="selectOrign(item)">
                                <a class="dropdown-item d-item"><span
                                    class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}} <br>
                                  <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                              </li>

                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-6 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="dropdown form-group oneform-boxes extra-border">
                        <div class="dropdown-toggle d-toggle ps-2" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">TO</label>
                          <h1 (click)="distinationFocuss()" #distinationSelect class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg"
                              alt="" />{{destnation}}<span
                              class="week-days d-none d-md-block">{{destinatinnAirport}}</span>
                          </h1>
                        </div>
                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input #distinationFocusss
                                (keyup)="getKeypData($event)" [(ngModel)]="searchFlight" placeholder="Search here" class="srch-box" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li *ngFor="let item of searchCountryList"
                                (click)="selectDestination(item)">
                                <a class="dropdown-item d-item"><span
                                    class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}} <br>
                                  <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                              </li>

                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-6 mb-2 mb-lg-0 p-0 col-lg-2">
                      <div class="oneform-boxes left-xtra-border oneway-departBorder">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">DEPART</label>

                          <h1 #selectDate class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/calender.svg" alt="" />{{
                            Originselected | date: 'dd' }}
                            <small class="date-data">{{ Originselected | date: 'MMM' }},{{ Originselected | date: 'yyyy'
                              }}</small>
                            <span class="week-days">{{ Originselected | date: 'EEEE' }}</span>
                          </h1>
                        </div>
                        <ul id="dropdownContainer" (click)="onDropdownClick($event)"
                          class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">


                          <div class="packageCallender">

                            <mat-card  (click)=" getOriginselectedFormattedDate()" class="demo-inline-calendar-card">
                              <mat-calendar [minDate]="minDates" [(selected)]="Originselected"
                                (selectedChange)="onDateSelected($event)"></mat-calendar>

                            </mat-card>


                          </div>



                        </ul>
                      </div>
                    </div>
                    <div *ngIf="return" class="col-6 mb-2 mb-lg-0 p-0 col-lg-2">
                      <div class="oneform-boxes left-xtra-border oneway-departBorder SmallReturn-border">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">RETURN</label>

                          <h1 #openReturnDate class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/calender.svg" alt="" />{{
                            returnSelected | date: 'dd' }}
                            <small class="date-data">{{ returnSelected | date: 'MMM' }},{{ Originselected | date: 'yyyy'
                              }}</small>
                            <span class="week-days">{{ returnSelected | date: 'EEEE' }}</span>
                          </h1>
                        </div>
                        <ul id="dropdownContainer" (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">


                          <div class="packageCallender">

                            <mat-card   [min]="neDatess"  (click)=" getOriginselectedFormattedDate()" class="demo-inline-calendar-card">
                              <mat-calendar #calendar [minDate]="Originselected" [(selected)]="returnSelected"
                                (selectedChange)="onDateSelectedReturn($event)" 
                                
                                ></mat-calendar>

                            </mat-card>


                          </div>



                        </ul>
                      </div>
                    </div>
                    <div *ngIf="!return" class="col-6 mb-2 mb-lg-0 p-0 col-lg-2">
                      <div class="oneform-boxes left-xtra-border oneway-departBorder SmallReturn-border">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">RETURN</label>
                          <p class="routeTxt" #openReturnDate>Book a round trip to save more</p>
                          <!-- <h1  class="search-cities">
               
                          </h1> -->
                        </div>
                        <ul id="dropdownContainer" (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu PdDown-lists Pdatepickr-box" aria-labelledby="dropdownMenuButton1">


                          <div class="packageCallender">

                            <mat-card (click)=" getOriginselectedFormattedDate()" class="demo-inline-calendar-card">
                              <mat-calendar [(selected)]="returnSelected"
                                (selectedChange)="onDateSelectedReturn($event)" 
                                [minDate]="Originselected"
                                ></mat-calendar>

                            </mat-card>


                          </div>



                        </ul>
                      </div>
                    </div>
                    <div  #closeAll class="col mb-2 mb-lg-0 p-0 col-lg-3" >
                      <div class="oneform-boxes searchBtn-box right-borders">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">TRAVELLER</label>

                          <h1 #openPasingers class="search-cities">
                            {{totalNmber}}
                            <small class="date-data">Traveller</small>
                            <span class="week-days ms-0">{{className}}</span>
                          </h1>
                        </div>
                        <button (click)="submit()" class="srchBTN-inside d-none d-lg-block">
                          Search
                        </button>
                        <ul (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu dDown-lists datepickr-box travel-drobbox"
                          aria-labelledby="dropdownMenuButton1">
                          <div class="traveller-bottombox" id="dropdownContainer">
                            <ul class="traveler-top list-unstyled">
                              <!-- Your list items go here -->
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Adult</p>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{audlt}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseAdult()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseAdult()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">
                                        Children
                                      </p>
                                      <span class="age-group">2-12 Years</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{child}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseChild()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseChild()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Infants</p>
                                      <span class="age-group">0-23 Months</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{infant}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseInfant()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseInfant()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Economy')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1" />
                              <label class="form-check-label" for="flexRadioDefault1">Economy </label>
                            </div>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Business')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2" />
                              <label class="form-check-label" for="flexRadioDefault2">Business</label>
                            </div>
                            <button (click)="sendTotalCount()" class="apply-Btn" onclick="applyChanges()">
                              Apply
                            </button>
                          </div>
                        </ul>


                      </div>
                    </div>
                  </div>
                  <div class="btn-beloww d-flex justify-content-center d-lg-none">
                    <button (click)="submit()" class="srchBTN-outside">Search</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="pills-multi" role="tabpanel" aria-labelledby="pills-multi-tab">
              <div class="oneWay-dataBelow">
                <div class="oneWay-box mb-3">
                  <div class="row g-0">
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="dropdown form-group oneform-boxes left-borders">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">FROM</label>
                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg"
                              alt="" />{{origin}}<span class="week-days d-none d-md-block">{{originCode}}
                              {{originAirport}}</span>
                          </h1>
                        </div>

                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input [(ngModel)]="searchFlight"
                                (keyup)="getKeypData($event)" placeholder="Search here" class="srch-box" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li *ngFor="let item of searchCountryList | filter: searchFlight"
                                (click)="selectOrign(item)">
                                <a class="dropdown-item d-item"><span
                                    class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}} <br>
                                  <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="dropdown form-group oneform-boxes">
                        <div class="dropdown-toggle d-toggle ps-lg-2" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">TO</label>
                          <h1 (click)="selectMultiS()" #distinationSelect class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg"
                              alt="" />{{destnation}}<span class="week-days d-none d-md-block">{{destinationCode}}
                              {{destinatinnAirport}}
                              Airport</span>
                          </h1>
                        </div>
                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input #multiSource
                                [(ngModel)]="searchFligh2"  (keyup)="getKeypData($event)" placeholder="Search here" class="srch-box" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li *ngFor="let item of searchCountryList "
                                (click)="selectDestination(item)">
                                <a class="dropdown-item d-item"><span
                                    class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}} <br>
                                  <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="oneform-boxes left-xtra-border bordr-small">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">DEPART</label>

                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/calender.svg"
                              alt="" />{{Originselected | date: 'dd' }}
                            <small class="date-data">{{Originselected | date: 'MMM' }} {{Originselected | date: 'yyyy'
                              }}</small>
                            <span class="week-days">{{Originselected | date: 'EEEE' }}</span>
                          </h1>
                        </div>
                        <ul class="dropdown-menu dDown-lists datepickr-box calender-box2"
                          aria-labelledby="dropdownMenuButton1">
                          <mat-card (click)=" getOriginselectedFormattedDate()" class="demo-inline-calendar-card">
                            <mat-calendar [minDate]="minDates" [(selected)]="Originselected"
                              (selectedChange)="onDateSelected($event)"></mat-calendar>

                          </mat-card>
                        </ul>
                      </div>
                    </div>

                    <div class="d-none d-lg-block mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="oneform-boxes searchBtn-box right-borders">
                        <div class="dropdown-toggle d-toggle ps-2 ps-lg-0" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <label for="citizen" class="d-block city-label">TRAVELLER</label>

                          <h1 class="search-cities">
                            {{totalNmber}}
                            <small class="date-data">Traveller</small>
                            <span class="week-days ms-0">{{className}}</span>
                          </h1>
                        </div>

                        <ul (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu dDown-lists datepickr-box travel-drobbox"
                          aria-labelledby="dropdownMenuButton1">
                          <div class="traveller-bottombox">
                            <ul class="traveler-top list-unstyled">
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Adult</p>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{audlt}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseAdult()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseAdult()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">
                                        Children
                                      </p>
                                      <span class="age-group">2-12 Years</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{child}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseChild()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseChild()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Infants</p>
                                      <span class="age-group">0-23 Months</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{infant}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseInfant()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseInfant()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Economy')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1" />
                              <label class="form-check-label" for="flexRadioDefault1">Economy </label>
                            </div>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Business')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2" />
                              <label class="form-check-label" for="flexRadioDefault2">Business</label>
                            </div>
                            <button (click)="sendTotalCount()" class="apply-Btn" onclick="applyChanges()">
                              Apply
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>




                <!--  MultiCity -->
                <div class="oneWay-box">
                  <div *ngFor="let city of cities; let i = index ;  let isLast = last" class="row g-0">
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="dropdown form-group oneform-boxes left-borders">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">FROM</label>
                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{
                            city.Origin }}<span class="week-days d-none d-md-block">{{city.airpotCode}} {{
                              city.airportName }}</span>
                          </h1>
                        </div>

                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input [(ngModel)]="searchFlight"
                                (keyup)="getKeypData($event)" placeholder="Search here" class="srch-box" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li>
                                <ul class="citi-namess list-unstyled m-0">
                                  <li *ngFor="let item of searchCountryList | filter: searchFlight"
                                    (click)="selectMultiOrign(item,i)">
                                    <a class="dropdown-item d-item"><span
                                        class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}}
                                      <br>
                                      <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                                  </li>

                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="dropdown form-group oneform-boxes">
                        <div class="dropdown-toggle d-toggle ps-lg-2" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="djfjsf" class="d-block city-label">TO</label>
                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{
                            city.Destination }}<span class="week-days d-none d-md-block">{{city.DairpotCode}} {{
                              city.DairportName }}</span>
                          </h1>
                        </div>
                        <ul class="dropdown-menu dDown-lists" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a class="dropdown-item d-item searchbox-top"><img class="srch-iccons"
                                src="./../../../assets/images/m/searrch.svg" alt="" /><input  [(ngModel)]="searchFlight"
                                (keyup)="getKeypData($event)"  placeholder="Search here" class="srch-box" type="text" /></a>
                          </li>
                          <li>
                            <ul class="citi-namess list-unstyled m-0">
                              <li *ngFor="let item of searchCountryList | filter: searchFlight"
                                (click)="selectMultiDestination(item,i)">
                                <a class="dropdown-item d-item"><span
                                    class="airpotyCodes">{{item.AirportCode}}</span>{{item.CityName | slice:0:25}} <br>
                                  <span class="aitpoty">{{item.AirportName | slice:0:25}}</span></a>

                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-4 mb-2 mb-lg-0 p-0 col-lg-6">
                      <div class="oneform-boxes left-xtra-border bordr-small multi-depart-border">
                        <div class="dropdown-toggle d-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <label for="citizen" class="d-block city-label">DEPART</label>

                          <h1 class="search-cities">
                            <img class="search-iccon" src="./../../../assets/images/m/calender.svg"
                              alt="" />{{cities[i].PreferredArrivalTime | date: 'dd' }}
                            <small class="date-data">{{cities[i].PreferredArrivalTime | date: 'MMM' }}
                              {{cities[i].PreferredArrivalTime | date: 'yyyy' }}</small>
                            <span class="week-days">{{cities[i].PreferredArrivalTime | date: 'EEEE' }}</span>
                          </h1>
                        </div>

                        <button *ngIf="isLast" (click)="serchMulti()" class="srchBTN-2nd d-none d-lg-block">
                          Search
                        </button>
                        <button *ngIf="isLast" (click)="addCity()" class="add-city d-none d-lg-block">
                          Add City
                        </button>

                        <button *ngIf="!isLast" class="btn btn-danger deleteBnt add-city delete-city" (click)="removeCity(i)">
                          <i class="fas fa-trash-alt"></i>
                        </button>

                        <ul (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu dDown-lists datepickr-box calender-box2"
                          aria-labelledby="dropdownMenuButton1">

                          <mat-card class="demo-inline-calendar-card">
                            <mat-calendar [minDate]="minDates" [(selected)]="selectedDates"
                              (selectedChange)="onDateSelectedMulti($event,i)"></mat-calendar>
                          </mat-card>


                        </ul>

                      </div>
                    </div>
                    <!-- <div class="d-lg-none d-block col-block mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="oneform-boxes searchBtn-box right-borders">
                        <div class="dropdown-toggle d-toggle ps-2 ps-lg-0" id="dropdownMenuButtons1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <label for="citizen" class="d-block city-label">TRAVELLER</label>

                          <h1 class="search-cities">
                            1
                            <small class="date-data">Traveller</small>
                            <span class="week-days ms-0">Economy</span>
                          </h1>
                        </div>

                       
                      </div>
                    </div> -->

                    <div *ngIf="isLast" class="d-lg-none d-block col-block mb-2 mb-lg-0 p-0 col-lg-3">
                      <div class="oneform-boxes searchBtn-box right-borders">
                        <div class="dropdown-toggle d-toggle ps-2 ps-lg-0" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <label for="citizen" class="d-block city-label">TRAVELLER</label>

                          <h1 class="search-cities">
                            {{totalNmber}}
                            <small class="date-data">Traveller</small>
                            <span class="week-days ms-0">{{className}}</span>
                          </h1>
                        </div>
                        <button class="add-city" (click)="addCity()">Add City</button>

                        <ul (click)="onDropdownClickReturn($event)"
                          class="dropdown-menu dDown-lists datepickr-box travel-drobbox"
                          aria-labelledby="dropdownMenuButton1">
                          <div class="traveller-bottombox">
                            <ul class="traveler-top list-unstyled">
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Adult</p>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{audlt}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseAdult()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseAdult()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">
                                        Children
                                      </p>
                                      <span class="age-group">2-12 Years</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{child}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseChild()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseChild()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="person-list">
                                <div class="row align-items-center">
                                  <div class="col-5">
                                    <div>
                                      <p class="adult-title">Infants</p>
                                      <span class="age-group">0-23 Months</span>
                                    </div>
                                  </div>
                                  <div class="col-2">
                                    <p class="numberr">{{infant}}</p>
                                  </div>
                                  <div class="col-5">
                                    <div class="btn-increase">
                                      <div (click)="increaseInfant()" class="increaseBtn">
                                        <i class="fas fa-plus"></i>
                                      </div>
                                      <div (click)="decreaseInfant()" class="reduceBtn">
                                        <i class="fas fa-minus"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Economy')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1" />
                              <label class="form-check-label" for="flexRadioDefault1">Economy </label>
                            </div>
                            <div class="form-check checkbox-Lavell">
                              <input (click)="selectClass('Business')" class="form-check-input" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2" />
                              <label class="form-check-label" for="flexRadioDefault2">Business</label>
                            </div>
                            <button (click)="sendTotalCount()" class="apply-Btn" onclick="applyChanges()">
                              Apply
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>


                  </div>
                  <div class="btn-beloww d-flex justify-content-center d-lg-none">
                    <button class="srchBTN-outside">Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-Hotel" role="tabpanel" aria-labelledby="pills-Hotel-tab">
        <div class="hotel-contntBelow">
          <h4 class="Hbooking-Hotelss">
            Book Domestic and International Hotels
          </h4>
          <div class="row g-0">
            <div class="col-12 mb-2 mb-lg-0 col-lg-4 p-0">
              <div class="dropdown form-group hotelsform-boxes Hleft-borders">
                <div class="dropdown-toggle Hd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <label for="djfjsf" class="d-block Hcity-label">ENTER YOUR DESTINATION OR PROPERTY</label>
                  <h1 class="Hsearch-cities">
                    <img class="Hsearch-iccon" src="/images/searrch.svg" alt="" />Dubai
                  </h1>
                </div>
                <ul class="dropdown-menu HdDown-lists" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item Hd-item Hsearchbox-top"><img class="Hsrch-iccons" src="/images/searrch.svg"
                        alt="" /><input class="Hsrch-box" type="text" /></a>
                  </li>
                  <li>
                    <ul class="Hciti-namess list-unstyled m-0">
                      <li (click)="selectMultiOrigin()">
                        <a class="dropdown-item Hd-item">India</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Afganistan</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Albania</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Angola</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">America</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Afganistan</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Albania</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">Angola</a>
                      </li>
                      <li>
                        <a class="dropdown-item Hd-item">America</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-4 col-lg-2 p-0">
              <div class="hotelsform-boxes Hsmall-brderrs">
                <div class="dropdown-toggle Hd-toggle" id="dateDropdown" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <label for="citizen" class="d-block Hcity-label">CHECK IN</label>

                  <h1 class="Hsearch-cities">
                    <img class="Hsearch-iccon" src="./../../../assets/images/m/calender.svg" alt="" />25 <small
                      class="Hdate-data">Oct,2024</small>
                    <span class="Hweek-days">wednesday</span>
                  </h1>
                </div>

                <ul class="dropdown-menu HdDown-lists Hdatepickr-box Hcalender-box2" aria-labelledby="dateDropdown">
                </ul>
              </div>
            </div>
            <div class="col-4 col-lg-2 p-0">
              <div class="hotelsform-boxes">
                <div class="dropdown-toggle Hd-toggle" id="dateDropdown" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <label for="citizen" class="d-block Hcity-label">CHECK OUT
                  </label>

                  <h1 class="Hsearch-cities">
                    <img class="Hsearch-iccon" src="./../../../assets/images/m/calender.svg" alt="" />25 <small
                      class="Hdate-data">Oct,2024</small>
                    <span class="Hweek-days">wednesday</span>
                  </h1>
                </div>

                <ul class="dropdown-menu HdDown-lists Hdatepickr-box Hcalender-box2" aria-labelledby="dateDropdown">
                </ul>
              </div>
            </div>
            <div class="col-4 col-lg-4 p-0">
              <div class="hotelsform-boxes HsearchBtn-box Hright-borders">
                <div class="dropdown-toggle Hd-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <label for="citizen" class="d-block Hcity-label">ROOMS & GUESTS</label>

                  <h1 class="Hsearch-cities">
                    1
                    <small class="Hdate-data">Room</small>
                    2
                    <span class="Hdate-data">Guests</span>
                  </h1>
                </div>
                <button class="HsrchBTN-inside d-none d-lg-block">
                  Search
                </button>
                <ul class="dropdown-menu HdDown-lists Hdatepickr-box Htravel-drobbox"
                  aria-labelledby="dropdownMenuButton1">
                  <div class="Htraveller-bottombox">
                    <h2 class="Room-title">Room 1</h2>
                    <ul class="Htraveler-top list-unstyled">
                      <li class="Hperson-list">
                        <div class="row align-items-center">
                          <div class="col-5">
                            <div>
                              <p class="Hadult-title">Adult</p>
                            </div>
                          </div>
                          <div class="col-2">
                            <p class="Hnumberr">0</p>
                          </div>
                          <div class="col-5">
                            <div class="Hbtn-increase">
                              <div class="HincreaseBtn">
                                <i class="fas fa-plus"></i>
                              </div>
                              <div class="HreduceBtn">
                                <i class="fas fa-minus"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="Hperson-list">
                        <div class="row align-items-center">
                          <div class="col-5">
                            <div>
                              <p class="Hadult-title">Children</p>
                              <span class="Hage-group">2-12 Years</span>
                            </div>
                          </div>
                          <div class="col-2">
                            <p class="Hnumberr">0</p>
                          </div>
                          <div class="col-5">
                            <div class="Hbtn-increase">
                              <div class="HincreaseBtn">
                                <i class="fas fa-plus"></i>
                              </div>
                              <div class="HreduceBtn">
                                <i class="fas fa-minus"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="addRoom-box d-flex justify-content-between">
                      <button class="another-room">
                        <span class="add-Plus">
                          <i class="fas fa-plus"></i></span>Add Another Room
                      </button>
                      <button class="done-Btn">Done</button>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-Visa" role="tabpanel" aria-labelledby="pills-Visa-tab">
        <div class="visaa-content-below">
          <h5 class="Vbook-domestic">We make visas easy for you</h5>
          <div class="row justify-content-center g-0">
            <div class="col-md-4 mb-2 p-0">
              <div class="dropdown form-group Vform-boxes Vleft-borders">
                <div (click)="setFocus('1')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING TO</label>
                  <h1 class="Vsearch-cities">
                    <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{visaOne}}
                  </h1>
                </div>
                <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                        src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocusVisaOne
                        [(ngModel)]="searchText1" placeholder="Search here" class="Vsrch-box" type="text" /></a>
                  </li>
                  <li>
                    <ul class="Vciti-namess list-unstyled m-0">
                      <li *ngFor="let item of countryList | filter: searchText1" (click)="selectOneVisa(item)">
                        <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                      </li>

                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 mb-2 p-0">
              <div class="dropdown form-group Vform-boxes">
                <div (click)="setFocus('2')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <label for="djfjsf" class="d-block Vcity-label">I AM A CITIZEN OF</label>
                  <h1 #inputFocusVisaTwoDiv class="Vsearch-cities">
                    <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />
                    {{ visaTwo.length <= 10 ? visaTwo : (visaTwo | slice:0:14) + '...' }}
                  </h1>
                </div>
                <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                        src="./../../../assets/images/m/searrch.svg" alt="" /><input class="Vsrch-box"
                        [(ngModel)]="searchText2" #inputFocusVisaTwo placeholder="Search here" type="text" /></a>
                  </li>
                  <li>
                    <ul class="Vciti-namess list-unstyled m-0">
                      <li *ngFor="let item of countryList | filter: searchText2" (click)="selectTwoVisa(item)">
                        <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                      </li>

                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-5 mb-2 p-0">
              <div class="dropdown form-group Vform-boxes Vright-borders">
                <div (click)="setFocus('3')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING FROM</label>
                  <h1 #inputFocusVisaThreeDiv class="Vsearch-cities">
                    <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />
                    {{ visaThree.length <= 14 ? visaThree : (visaThree | slice:0:14) + '...' }}

                  </h1>

                </div>
                <button (click)="submitVisaFor()" class="VsrchBTN-inside d-none d-md-block">
                  Search
                </button>
                <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                        src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocusVisaThree
                        [(ngModel)]="searchText3" class="Vsrch-box" placeholder="Search here" type="text" /></a>
                  </li>
                  <li>
                    <ul class="Vciti-namess list-unstyled m-0">
                      <li *ngFor="let item of countryList | filter: searchText3" (click)="selectThreeVisa(item)">
                        <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div (click)="submitVisaFor()" class="Vbtn-beloww d-flex justify-content-center d-md-none">
            <button class="VsrchBTN-outside">Search</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-Car" role="tabpanel" aria-labelledby="pills-Car-tab">
        <div class="car-content">
          <h5 class="book-cab">Book your Cab</h5>
          <div class="car-box">
            <h1 class="coming-title">Coming Soon</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Numbers Dynamic Section start -->

<div id="counter" class="dynamicNumrs">
  <div class="row justify-content-center">
    <div class="col-md-12" style="padding: 0;">
      <h1 class="headins">
        Begin Your Travel <br> Plans With The Best Travel Agency
      </h1>
      <p class="dymainc-para">While we’re happy to accommodate 11th-hour travel plans, you don’t really have to
        wait until the last minute. Call our travel experts to find the perfect itinerary for you.</p>
      <ul class="numbersList">
        <li class="numberGroup">
          <h1 class="numberr"><span data-count="50" class="numberr count"></span><span>+</span></h1>
          <p class="text-data">Sandy Beaches</p>
        </li>
        <li class="numberGroup">
          <h1 class="numberr"><span data-count="90" class="numberr count"></span><span>+</span></h1>
          <p class="text-data">Destinations</p>
        </li>
        <li class="numberGroup">
          <h1 class="numberr"><span data-count="90" class="numberr count"></span><span>%</span></h1>
          <p class="text-data">Thrilled Customers</p>
        </li>
        <li class="numberGroup">
          <h1 class="numberr"><span data-count="100" class="numberr count"></span><span>%</span></h1>
          <p class="text-data">Best Price Guarantee</p>
        </li>

        <li class="numberGroup2">
          <h1 class="numberr"><span data-count="24" class="numberr count">0</span><span>/7</span></h1>
          <p class="text-data">Support</p>
        </li>

      </ul>
    </div>
  </div>
</div>
<!-- Numbers Dynamic Section end -->
<div  #travellerDetails>

</div>
<!-- Exclusive start  -->
<div class="newHomePge">


  <div  class="container">
    <!-- --  Exclusive Deals Start  -- -->
    <div class="ExclusiveDeals">
      <div class="ExclusiveDelasInside">

        <p  class="ExclusiveDataTitle">Deals</p>

        <ul class="nav nav-pills NavData mb-3" id="pills-tab" role="tablist">
          <li class="nav-item NavDataItem" role="presentation">
            <button class="nav-link NavDataLink active" id="pills-home-tab" data-bs-toggle="pill"
              data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">BEST
              DEALS</button>
          </li>
          <li (click)="getCaroFilter('1')" class="nav-item NavDataItem" role="presentation">
            <button class="nav-link NavDataLink" id="pills-profile-tab" data-bs-toggle="pill"
              data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
              aria-selected="false">LOCAL DEALS</button>
          </li>
          <li (click)="getCaroFilter('2')" class="nav-item NavDataItem" role="presentation">
            <button class="nav-link NavDataLink" id="pills-contact-tab" data-bs-toggle="pill"
              data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
              aria-selected="false">GLOBAL DEALS</button>
          </li>
        </ul>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <!-- Carousel Start -->
          <div class="popular-locations">
            <div class="row">
              <div *ngFor="let item of bestDeals" class="col-md-3">
                <div class="imageSlides">
                  <div (click)="getMoreDetails(item)" class="popular-location-inside">
                    <img class="popular-location-img" [src]="item?.images[0]?.image" alt="">
                    <p class="PopularImgTitle">{{item.countryName}}</p>
                    <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice"> {{item.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                        {{selectedCurrency}}</span></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Carousel End -->
        </div>

        <div class="tab-pane fade show " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <!-- Carousel Start -->
          <div class="popular-locations">
            <div class="row">
              <div *ngFor="let item of localBestDeals" class="col-md-3">
                <div class="imageSlides">
                  <div (click)="getMoreDetails(item)" class="popular-location-inside">
                    <img class="popular-location-img" [src]="item?.images[0]?.image" alt="">
                    <p class="PopularImgTitle">{{item.countryName}}</p>
                    <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice"> {{item.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                        {{selectedCurrency}}</span></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Carousel End -->
        </div>
        <div class="tab-pane fade show " id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <!-- Carousel Start -->
          <div class="popular-locations">
            <div class="row">
              <div *ngFor="let item of globalBestDeals" class="col-md-3">
                <div class="imageSlides">
                  <div (click)="getMoreDetails(item)" class="popular-location-inside">
                    <img class="popular-location-img" [src]="item?.images[0]?.image" alt="">
                    <p class="PopularImgTitle">{{item.countryName}}</p>
                    <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice"> {{item.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}}
                        {{selectedCurrency}}</span></p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Carousel End -->
        </div>
      </div>
    </div>
    <!-- --  Exclusive Deals End  -- -->

  </div>






  <div class="section2">
    <div class="container-fluid">
      <div class="section2txt-sec">
        <!-- <p class="section2t">Tailor-made Corporate Travel Inclusions</p> -->
        <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" data-aos-duration="1000"
          data-aos-mirror="false">
          <h2 class="sectxt3" data-aos-once="true">International Packages</h2>
          <!-- <h2 class="sectxt3" data-aos="zoom-in" data-aos-mirror="false" data-aos-once="true">That Stands Apart
                </h2> -->
        </div>
        <p class="secttxt12">
          Business travel doesn't have to be a hassle. Our best-in-the-industry travel advisors provide a
          complete suite of travel services for discerning corporate clients, whether individual travelers or
          groups of any size.
          From exclusively negotiated airfares & custom travel arrangements, to transfers, visas, and other
          privileges, we strive to create authentic and personalized travel experiences.
        </p>
      </div>

      <div class="section3-main">

        <div class="container">
          <div class="text-right">
            <button (click)="getMoreDetails2(2)" class="viewallbt">View All</button>
          </div>


          <div class="row">

            <owl-carousel-o [options]="customOptions">
              <ng-template *ngFor="let item of InternationalList" carouselSlide>
                <div class="item">
                  <div (click)="getMoreDetails(item)" class="client-box">
                    <img class="boxim" [src]="item?.images[0]?.image" alt="no-image">
                    <ul class="PackagePrice">
                      <li>
                        <p class="itemPackageName">{{item.title}} </p>
                      </li>
                      <li>
                        <p class="itemPackagePrice">{{item.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </p>
                      </li>
                    </ul>
                  
                  </div>
                </div>
              </ng-template>

            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>
  </div>








  <!-- Flages Component start-->


  <div (click)="changeCss()" class="explorenearby">
    <h2 class="explorenearbyhed" data-aos="zoom-in" data-aos-duration="1000" data-aos-mirror="false"
      data-aos-once="true">
      Explore The World</h2>


    <div class="container">
      <ul class="flags-list">
        <li class="flag-list-inside">
          <!-- data-bs-toggle="modal" data-bs-target="#exampleModal"  -->
          <a (click)="changeCss()" (click)="getMoreDetails2('United Arab Emirates')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/uae.svg" alt="no-image">
              <h2 class="exploresectitle">UAE</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('United States')"  style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/usa.svg" alt="no-image">
              <h2 class="exploresectitle">USA</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Canada')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/Canada.svg" alt="no-image">
              <h2 class="exploresectitle">CANADA</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Australia')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/AUSTRALIA.svg" alt="no-image">
              <h2 class="exploresectitle">AUSTRALIA</h2>
            </div>
          </a>
        </li>
        <!-- <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('India')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/INDIA.svg" alt="no-image">
              <h2 class="exploresectitle">INDIA</h2>
            </div>
          </a>
        </li> -->
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Europe')"style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/Europe.png" alt="no-image">
              <h2 class="exploresectitle">EUROPE</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Indonasia')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/indonasia.png" alt="no-image">
              <h2 class="exploresectitle">INDONESIA</h2>
            </div>
          </a>
        </li>



        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Japan')"style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/japan.png" alt="no-image">
              <h2 class="exploresectitle">JAPAN</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Koria')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/Korea.png" alt="no-image">
              <h2 class="exploresectitle">KORIA</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('London')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/London.png" alt="no-image">
              <h2 class="exploresectitle">LONDON</h2>
            </div>
          </a>
        </li>

        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('Maldives')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/Maldives.png" alt="no-image">
              <h2 class="exploresectitle">MALDIVES</h2>
            </div>
          </a>
        </li>
        <li class="flag-list-inside">
          <a (click)="changeCss()" (click)="getMoreDetails2('China')" style="text-decoration: none;">
            <div class="exploresec">
              <img class="exploresecim" src="./../../../assets/images/explore/China.svg" alt="no-image">
              <h2 class="exploresectitle">CHINA</h2>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>


  <!-- Flages Component end-->

  <div class="section9">
    <div class="container">
      <h2 class="section9hed" data-aos="zoom-in" data-aos-duration="1200" data-aos-mirror="false" data-aos-once="true">
        Domestic Packages</h2>
      <p class="section9txt1">At Devotion, our team of local travel aficionados handpick the creme de la creme of
        destinations, hotels
        and experiences to create highly customized packages
        Our knowledge, experience, and passion for this incredible city are apparent in all that we do, right
        down to the finest details. From crafting unique experiences tailored to your requirements to providing
        insights into local customs and traditions, we are your gateway to an unforgettable Dubai trip.
      </p>
    </div>
    <div class="container">
      <div class="text-right">
        <!-- <button (click)="isShow()" *ngIf="viewAll" class="viewallbt">View All</button>
            <button (click)="ishide()" *ngIf="!viewAll" class="viewallbt">Hide </button> -->
        <button (click)="getMoreDetails2(1)" class="viewallbt">View All</button>

      </div>
      <div class="row">




        <div *ngFor="let item of nationalList" (click)="getMoreDetails(item)" class="col-md-4">
          <div class="section9-details">

            <div class="content">
              <a>
                <div class="content-overlay"></div>
                <img class="content-image" [src]="item?.images[0]?.image" alt="">
                <div class="content-details fadeIn-bottom">
                  <h3 class="content-title section9hedssd">{{item.title}}</h3>
                  <p class="content-text">{{item.subtitle | slice : 0:200 }}</p>
                </div>
              </a>

            </div>
            <ul class="PackagePrice">
              <li>
                <p class="itemPackageName">{{item.title}} </p>
              </li>
              <li>
                <p class="itemPackagePrice">{{item.price  | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}  </p>
              </li>
            </ul>


          </div>
        </div>


      </div>



    </div>
  </div>








  <div class=" container222">
    <app-profile></app-profile>
  </div>
 
  <app-new-slides></app-new-slides>

  <div class="container">
    <app-google-reviews></app-google-reviews>

  </div>

  <div class="faqsection">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10  justify-content-center">
          <h2  class="faqtitle">FAQs </h2>
          <!-- <p>{{ 100 | priceExchange:selectedCurrency | async }}</p> -->
          <ul class="nav nav-tabs faq-main" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button (click)="getFaq('airTravelBooking')" class="nav-link active" id="profile-tab" data-bs-toggle="tab"
                data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Flight
                Booking</button>
            </li>
            <li class="nav-item" role="presentation">
              <button (click)="getFaq('Packages')" class="nav-link " id="corporate-tab" data-bs-toggle="tab"
                data-bs-target="#corporate" type="button" role="tab" aria-controls="corporate"
                aria-selected="false">Packages</button>
            </li>
           
            <li class="nav-item" role="presentation">
              <button (click)="getFaq('Visa')" class="nav-link " id="home-tab" data-bs-toggle="tab"
                data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Visa</button>
            </li>

            <li class="nav-item" role="presentation">
              <button (click)="getFaq('hotelBooking')" class="nav-link" id="contact-tab" data-bs-toggle="tab"
                data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Hotel
                Booking</button>
            </li>
         
          </ul>

        </div>
      </div>
      <div class="row ">
        <div class="col-md-12">
          <div class="tab-content" id="myTabContent">
            <div class="accordion" id="accordionExample">
              <div *ngFor="let item of carRental2 ; let i = index " class="accordion-item">
                <h2 class="accordion-header" id="headingOne" [id]="'heading'+i">
                  <button [ngClass]="{ 'collapsed': i != 0 }" [attr.data-bs-target]="'#collapse'+i"
                    class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target=`{$i+1}`
                    aria-expanded="true" aria-controls="collapseOne" [attr.aria-controls]="'collapse'+i">
                    {{item.title}}
                  </button>
                </h2>
                <div [id]="'collapse'+i" [ngClass]="{ 'show': i == 0 }" [attr.aria-labelledby]="'heading'+i"
                  class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    {{item.description}}
                  </div>
                </div>
              </div>

            </div>
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

              <!-- FAQ CAR SECTION START -->


              <!-- FAQ CAR SECTION END -->







            </div>

          </div>
        </div>
      </div>
    </div>

  </div>





  <div class="exclusive-newsletter">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="exclusive-newsletter-left">
            <p class="exclusive-newsletter-title">NEWSLETTER</p>
            <p class="exclusive-newsletter-content">Exclusive travel tips, giveaways, and more!</p>
            <!-- <form action="">
                    <div class="inside-email">
                        <input type="email" class="email-content form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email..">
                    </div>
                    <div class="inside-join-devotion">
                        <button type="submit" class="join-devotion">Join Devotion</button>
                    </div>
                </form> -->
            <form class="form-inline join-dev-form" [formGroup]="newsLetter" (ngSubmit)="SubmitNews()">
              <div class="form-group forming-group mb-2">
                <input formControlName="email" [(ngModel)]="newsLetterObj.email" type="email"
                  class="form-control-plaintext devotionEmail" placeholder="Enter Email">
              </div>

              <button type="submit" class="btn btn-primary join-btnss mb-2">Join Devotion</button>
            </form>
            <div class="location-address-inside">
              <ul class="exclusive-newsletter-inside">
                <li class="exclusive-newsletter-inside-content">
                  <a class="exclusive-location"><img width="20px" src="./../../../assets/images/loc.svg" alt=""></a>
                </li>
                <li class="exclusive-newsletter-inside-content">
                  <a target="_blank"
                    href="https://www.google.com/maps/place/Iris+Bay+-+Business+Bay+-+Dubai+-+United+Arab+Emirates/@25.1858723,55.2582335,17.11z/data=!4m6!3m5!1s0x3e5f69d09681df43:0xc222838ff425a54!8m2!3d25.1859019!4d55.2602652!16s%2Fm%2F03d0mmb"
                    class="exclusive-address">
                    <p class="exclusive-addr-contents">502-Iris Bay, Opp. JW Marriott Hotel,</p>
                    <p class="exclusive-address-contents">Business Bay - Dubai - UAE</p>
                  </a>
                </li>
              </ul>
              <ul class="exclusive-newsletter-inside">
                <li class="exclusive-newsletter-inside-content">
                  <a class="exclusive-location"><img width="20px" src="./../../../assets/images/ph.svg" alt=""></a>
                </li>
                <li class="exclusive-newsletter-inside-content">
                  <a href="tel:+971 585775469" class="exclusive-address">+971 585775469</a>
                </li>
              </ul>
              <ul class="exclusive-newsletter-inside">
                <li class="exclusive-newsletter-inside-content">
                  <a class="exclusive-location"><img width="20px" src="./../../../assets/images/em.svg" alt=""></a>
                </li>
                <li class="exclusive-newsletter-inside-content">
                  <a href="mailto:support@devotiontourism.com" class="exclusive-address">support@devotiontourism.com</a>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-6">
              <img width="100%" style="margin: 6px 2px; border-radius: 10px;" class="exclusive-newsletter-img"
                src="./../../../assets/images/newImages/333.jpg" alt="">

            </div>
            <div class="col-md-6">
              <img style="margin: 6px 2px; border-radius: 10px;" width="100%" class="exclusive-newsletter-img"
                src="./../../../assets/images/newImages/flightG.png" alt="">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Useful Links -->





  <div class="footerLinks">
    <a href="" data-bs-toggle="modal" data-bs-target="#examplComingeModal" #commingSoonPop></a>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <ul class="footerList">
          <h1 class="footerHead">UAE activities</h1>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DESERT SAFARI.pdf">Desert Safari Dubai</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DUBAI CITY TOUR.pdf">Dubai City Tours</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DHOW CRUISE.pdf">Dhow Cruise Dubai</a></li>
          <li class="listFooter"><a (click)="comingInfo('Ras Al Khaimah Desert safari')" class="targetLinks">Ras Al
              Khaimah Desert safari</a></li>
          <li class="listFooter"><a (click)="comingInfo('Dubai Water Parks')" class="targetLinks">Dubai Parks</a></li>
          <li class="listFooter"><a (click)="comingInfo('Burj Khalifa Tours')" class="targetLinks">Burj Khalifa
              Tours</a>
          </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/ABU DHABI CITY TOUR.pdf">Abu Dhabi City Tours</a> </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DUBAI THEME PARKS.pdf"> Dubai Theme Park</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DUBAI WATER ACTIVITIES.pdf"> Dubai Water Activities</a>
          </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/ABU DHABI WATER PARK.pdf">Abu Dhabi Water Parks</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Dubai adventure tours.pdf">Dubai Adventure Tours</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/ABU DHABI THEME PARKS.pdf">Abu Dhabi Theme Parks</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DUBAI HELICOPTER TOUR.pdf">Dubai Helicopter Tours</a>
          </li>
        </ul>
      </div>
      <div *ngIf="false" class="col-md-2">
        <ul class="footerList">
          <h1 class="footerHead">Services</h1>
          <li class="listFooter"> <a (click)="comingInfo('Dubai Tours')" class="targetLinks">Dubai Tours</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Singapore Tours')" class="targetLinks">Singapore Tours</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('Dubai Visa')" class="targetLinks">Dubai Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Dubai Visa Extension')" class="targetLinks">Dubai Visa
              Extension</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Kuala Lumpur Tours')" class="targetLinks">Kuala Lumpur
              Tours</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('Dubai Packages')" class="targetLinks"> Dubai Packages</a></li>
          <li class="listFooter"> <a (click)="comingInfo('Canada Visa')" class="targetLinks">Canada Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Upcoming Tours')" class="targetLinks">Upcoming Tours</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Singapore Packages')" class="targetLinks">Singapore
              Packages</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('Pars Tours')" class="targetLinks">Pars Tours</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Georgia Packages')" class="targetLinks">Georgia Packages</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('USA Visa')" class="targetLinks">USA Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Holiday packages +')" class="targetLinks">Holiday packages
              +</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('Super Saver +')" class="targetLinks">Super Saver +</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Turkey Visa')" class="targetLinks">Turkey Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Egypt Visa')" class="targetLinks">Egypt Visa</a> </li>
        </ul>
      </div>
      <div class="col-md-3">
        <ul class="footerList">
          <h1 class="footerHead"> Best Selling Activities</h1>
          <li class="listFooter"> <a (click)="comingInfo('Desert Safari')" class="targetLinks">Desert Safari</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Abu Dhabi City Tour')" class="targetLinks">Abu Dhabi City
              Tour</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Dubai Burj Khalifa')" class="targetLinks">Dubai Burj
              Khalifa</a>
          </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/GLOBAL VILLAGE.pdf">Global Village Dubai</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/ATLANTIS AQUAVENTURE.pdf">Atlantis Aquaventure</a></li>

          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/GREEN PLANET.pdf">The Green Planet Dubai</a></li>
          <li class="listFooter"><a (click)="comingInfo('Dhow Cruise Dinner Marina')" class="targetLinks">Dhow Cruise
              Dinner Marina</a> </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/AIN DUBAI.pdf">Ain Dubai</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/IMG World of Adventure.pdf">IMG Worlds of Adventure</a>
          </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Private Limousine Rental Dubai.pdf">Private Limousine
              Rental Dubai</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Luxury Yacht Rental Dubai.pdf">Luxury Yacht Rental
              Dubai</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <ul class="footerList">
          <h1 class="footerHead">Thrilling Activities</h1>
          <li class="listFooter"><a (click)="comingInfo('Private Limo')" class="targetLinks"> Private Limo</a> </li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/DUBAI MALL ACQUARIUM.pdf">Dubai Mall Aquarium</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Ski Dubai Tickets.pdf">Ski Dubai Tickets</a></li>

          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Motion gate Dubai.pdf">Motion gate Dubai</a></li>
          <li class="listFooter"><a (click)="comingInfo('Dhow Cruise Dinner Creek')" class="targetLinks"> Dhow Cruise
            </a>
          </li>

          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/LA PERLE BY DRAGONE DUBAI.pdf">La Perle by Dragone
              Dubai</a></li>
          <li class="listFooter"><a class="targetLinks" target="_blank"
              href="./../../../assets/files/Local Sight seeing/Ferrari World Abu Dhabi.pdf">Ferrari World Abu Dhabi</a>
          </li>
        </ul>
      </div>
      <div class="col-md-2">

        <ul class="footerList">
          <h1 class="footerHead"> <a class="footerHead"> International Visa</a> </h1>
          <li class="listFooter"> <a (click)="comingInfo(' UK Visa')" (click)="submitVisaForTargetted('United Kingdom','United Arab Emirates')" class="targetLinks"> UK Visa</a></li>
          <li class="listFooter"> <a (click)="comingInfo('  Dubai Visa')" (click)="submitVisaForTargetted('United Arab Emirates','United Arab Emirates')" class="targetLinks"> UAE Visa</a></li>
          <li class="listFooter"> <a (click)="comingInfo('Singapore Visa')" (click)="submitVisaForTargetted('United Arab Emirates','United States')" class="targetLinks"> UAE Visa
              Extension</a>
          </li>
       
          <li class="listFooter"> <a (click)="comingInfo(' Canada Visa')" (click)="submitVisaForTargetted('Canada','United Arab Emirates')" class="targetLinks"> Canada Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('USA Visa')" class="targetLinks" (click)="submitVisaForTargetted('United States','United Arab Emirates')"> USA Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Turkey Visa')" class="targetLinks" (click)="submitVisaForTargetted('Turkey','United Arab Emirates')"> Turkey Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Egypt Visa')" class="targetLinks" (click)="submitVisaForTargetted('Egypt','United Arab Emirates')"> Egypt Visa</a> </li>
          <!-- <li class="listFooter"> <a (click)="comingInfo('Schengen Visa')" class="targetLinks" (click)="submitVisaForTargetted('Egypt','United Arab Emirates')"> Schengen Visa</a> </li> -->
          <li class="listFooter"> <a (click)="comingInfo('Singapore Visa')" class="targetLinks" (click)="submitVisaForTargetted('Singapore','United Arab Emirates')"> Singapore Visa</a>
          </li>
          <li class="listFooter"> <a (click)="comingInfo('Thailand Visa')" class="targetLinks" (click)="submitVisaForTargetted('Thailand','United Arab Emirates')"> Thailand Visa </a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Malaysia Visa')" class="targetLinks" (click)="submitVisaForTargetted('Malaysia','United Arab Emirates')"> Malaysia Visa</a> </li>
          <li class="listFooter"> <a (click)="comingInfo('Sri Lanka Visa')" class="targetLinks" (click)="submitVisaForTargetted('Sri Lanka','United Arab Emirates')"> Sri Lanka Visa </a>
          </li>
        </ul>
      </div>
    </div>
  </div>










  <!-- Button trigger modal -->




  <div class="devo_content">
    <div class="modal fade" id="exampleModal" style="z-index:1045;" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <form [formGroup]="Countryform" (ngSubmit)="countrylogin()">
            <div class="modal-header" style="border: none;">
              <h5 class="modal-title" style="display: none;" id="exampleModalLabel">Modal title</h5>
              <!-- <button class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
              <i data-bs-dismiss="modal" #CloseModels aria-label="Close"
                class="far fa-times-circle fa-2x close_Box"></i>
            </div>
            <div class="modal-body">


              <div class="row mb-4 m-2">
                <div class="col-md-4">

                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Full name</label>
                    <input formControlName="fullname" type="text" class="form-control where-to-inputs" />
                    <!-- <div *ngIf="Countryform.get('fullname').errors?.required">
                                            <i class="fas fa-info-circle error-icon"></i> <span style="color: red;">Name is Required</span>
                                        </div> -->
                    <div *ngIf="Countryform.get('fullname').errors?.noNumbers">
                      <i class="fas fa-info-circle error-icon"></i>
                      <span style="color: red;"> Name should not include numbers & special
                        characters</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labelsss destination-labels44">Phone number
                      :</label>
                    <!-- <input placeholder="phone number" formControlName="mobileNo" type="text"
                                        class="form-control where-to-inputs" /> -->
                    <!-- <app-country-mobile [dataCass]="dataOne"></app-country-mobile> -->

                    <div class="MobileNumInside">
                      <div class="CountryCodeData">
                        <div class="dropdown DropDown">
                          <button class="btn FormSelect dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img *ngIf="!countryIsSelected" class="FlagImg"
                              src="./../../../../assets/images/country/aue.webp" alt="no-img">
                            <img *ngIf="countryIsSelected" class="FlagImg" [src]="'data:image/png;base64,' + selectdImg"
                              alt="no-img">

                            <!-- <p class="CallID"> {{callingCode}}</p> -->
                          </button>
                          <ul class="dropdown-menu DropDownMenu" aria-labelledby="dropdownMenuButton1">
                            <li class="DropDownLi">
                              <form [formGroup]="searching">
                                <div class="InputSearch">
                                  <img class="SearchImgIcon" src="./../../../../assets/images/search.png" alt="no-data">
                                  <input formControlName="search" [(ngModel)]="searchText" class="InputSearchData"
                                    type="search" placeholder="Select Country Name or Code">
                                </div>
                              </form>
                            </li>
                            <li *ngFor="let item of allMobileFlags | filter:searchText" (click)="getCountryID(item)"
                              class="DropDownLi"><a class="dropdown-item DropDownItem">
                                <img *ngIf="item.type === 'base'" class="FlagImg"
                                  [src]="'data:image/png;base64,' + item.flag">
                                <img *ngIf="item.type === 'img'" class="FlagImg" [src]="item.flag">

                                {{item?.name}}<span class="FlagSpan">{{item?.callingCode}}</span></a>
                            </li>

                          </ul>
                        </div>

                        <input formControlName="mobileNo" [(ngModel)]="enterMobile" class="s-inputsss where-to-inputs"
                          type="number" placeholder="Mobile Number">
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Email </label>
                    <input formControlName="email" type="text" class="form-control where-to-inputs" />
                  </div>
                </div>

              </div>

              <div class="row mb-4 m-2">

                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Travelling From</label>
                    <input formControlName="residence" type="text" class="form-control where-to-inputs" />
                    <div *ngIf="Countryform.get('travelDestination').errors?.noNumbers">
                      <i class="fas fa-info-circle error-icon"></i>
                      <span style="color: red;"> Should not include numbers & special
                        characters</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Travelling To</label>
                    <input formControlName="travelDestination" type="text" class="form-control where-to-inputs" />
                    <div *ngIf="Countryform.get('travelDestination').errors?.noNumbers">
                      <i class="fas fa-info-circle error-icon"></i>
                      <span style="color: red;"> Should not include numbers & special
                        characters</span>
                    </div>
                  </div>
                </div>




                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Intended Travel Date: </label>
                    <input [bsConfig]="BsDatepickerConfig" formControlName="intendedtravelDates" type="text"
                      class="form-control where-to-inputs" bsDatepicker>
                  </div>
                </div>
              </div>

              <div class="row m-2">
                <div class="col-md-4">

                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Reason For Travel</label>
                    <select formControlName="visaType" style="width: 100%;
                    height: 50px;
                    border: 1px solid #AB823E;
                    border-radius: 8px;
                    padding: 0px 10px 0px 15px;
                    font-size: 14px;
                    color: #000;
                    font-family: Mona-Sans-Regular;
                    background-color: #fff;" class="form-select country-selection mb-3"
                      aria-label=".form-select-lg example">

                      <!-- <option selected>Reason For Travel</option> -->
                      <option value="30 Days Visa">Internal Field Business</option>
                      <option value="60 Days Visa">Tourism</option>

                    </select>
                  </div>
                </div>
                <div class="col-md-4">

                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Nationality:</label>
                    <select formControlName="nationality" style="width: 100%;
                    height: 50px;
                    border: 1px solid #AB823E;
                    border-radius: 8px;
                    padding: 0px 10px 0px 15px;
                    font-size: 14px;
                    color: #000;
                    font-family: Mona-Sans-Regular;
                    background-color: #fff;" class="form-select country-selection mb-3"
                      aria-label=".form-select-lg example">

                      <option selected>Country of Residence:</option>
                      <option value="AF">Afghan</option>
                      <option value="AL">Albanian</option>
                      <option value="DZ">Algerian</option>
                      <option value="AS">American Samoan</option>
                      <option value="US">American; US citizen</option>
                      <option value="AD">Andorran</option>
                      <option value="AO">Angolan</option>
                      <option value="AI">Anguillan</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antiguan; Barbudian</option>
                      <option value="AN">Antillean</option>
                      <option value="AR">Argentinian</option>
                      <option value="AM">Armenian</option>
                      <option value="AW">Aruban</option>
                      <option value="AU">Australian</option>
                      <option value="AT">Austrian</option>
                      <option value="AZ">Azerbaijani</option>
                      <option value="BS">Bahamian</option>
                      <option value="BH">Bahraini</option>
                      <option value="BD">Bangladeshi</option>
                      <option value="BB">Barbadian</option>
                      <option value="LS">Basotho</option>
                      <option value="BY">Belarusian</option>
                      <option value="BE">Belgian</option>
                      <option value="BZ">Belizean</option>
                      <option value="BJ">Beninese</option>
                      <option value="BM">Bermudian</option>
                      <option value="BT">Bhutanese</option>
                      <option value="BO">Bolivian</option>
                      <option value="BW">Botswanan</option>
                      <option value="BV">Bouvet Islands</option>
                      <option value="BR">Brazilian</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="VG">British Virgin Islander; BV Islander
                      </option>
                      <option value="BN">Bruneian</option>
                      <option value="BG">Bulgarian</option>
                      <option value="BF">Burkinabe</option>
                      <option value="MM">Burmese</option>
                      <option value="BI">Burundian</option>
                      <option value="KH">Cambodian</option>
                      <option value="CM">Cameroonian</option>
                      <option value="CB">Canada Buffer</option>
                      <option value="CA">Canadian</option>
                      <option value="CV">Cape Verdean</option>
                      <option value="KY">Caymanian</option>
                      <option value="CF">Central African</option>
                      <option value="TD">Chadian</option>
                      <option value="CL">Chilean</option>
                      <option value="CN">Chinese</option>
                      <option value="CX">Christmas Islander</option>
                      <option value="CC">Cocos Islander;Cocos (Keeling) Islands
                      </option>
                      <option value="CO">Colombian</option>
                      <option value="KM">Comorian</option>
                      <option value="CD">congo</option>
                      <option value="CG">Congolese</option>
                      <option value="CK">Cook Islander</option>
                      <option value="CR">Costa Rican</option>
                      <option value="HR">Croat</option>
                      <option value="CU">Cuban</option>
                      <option value="CY">Cypriot</option>
                      <option value="CZ">Czech</option>
                      <option value="DK">Dane</option>
                      <option value="DJ">Djiboutian</option>
                      <option value="DO">Dominican</option>
                      <option value="DM">Dominicana ; Dominica</option>
                      <option value="NL">Dutchman; Dutchwoman; Netherlander
                      </option>
                      <option value="TP">East Timor</option>
                      <option value="EC">Ecuadorian</option>
                      <option value="EG">Egyptian</option>
                      <option selected="selected" value="AE">Emirian</option>
                      <option value="GQ">Equatorial Guinean</option>
                      <option value="ER">Eritrean</option>
                      <option value="EE">Estonian</option>
                      <option value="ET">Ethiopian</option>
                      <option value="EU">European Monetary Union</option>
                      <option value="FO">Faeroese</option>
                      <option value="FK">Falkland Islander</option>
                      <option value="FJ">Fiji Islander</option>
                      <option value="PH">Filipino</option>
                      <option value="FI">Finn</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="FR">Frenchman; Frenchwoman</option>
                      <option value="GA">Gabonese</option>
                      <option value="GM">Gambian</option>
                      <option value="GE">Georgian</option>
                      <option value="DE">German</option>
                      <option value="GH">Ghanaian</option>
                      <option value="GI">Gibraltarian</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenlander</option>
                      <option value="GD">Grenadian</option>
                      <option value="GP">Guadeloupean</option>
                      <option value="GU">Guamanian</option>
                      <option value="GT">Guatemalan</option>
                      <option value="GF">Guianese</option>
                      <option value="GW">Guinea-Bissau national</option>
                      <option value="GN">Guinean</option>
                      <option value="GY">Guyanese</option>
                      <option value="HT">Haitian</option>
                      <option value="HM">Heard &amp; Mcdonald Islands</option>
                      <option value="HN">Honduran</option>
                      <option value="HK">Hong Kong Chinese</option>
                      <option value="HU">Hungarian</option>
                      <option value="IS">Icelander</option>
                      <option value="IN">Indian</option>
                      <option value="ID">Indonesian</option>
                      <option value="IR">Iranian</option>
                      <option value="IQ">Iraqi</option>
                      <option value="IE">Irishman; Irishwoman</option>
                      <option value="IL">Israeli</option>
                      <option value="IT">Italian</option>
                      <option value="CI">Ivorian</option>
                      <option value="JM">Jamaican</option>
                      <option value="JP">Japanese</option>
                      <option value="JO">Jordanian</option>
                      <option value="KZ">Kazakh</option>
                      <option value="KE">Kenyan</option>
                      <option value="KI">Kiribatian</option>
                      <option value="KN">Kittsian; Nevisian</option>
                      <option value="XK">Kosovo</option>
                      <option value="KW">Kuwaiti</option>
                      <option value="KG">Kyrgyz</option>
                      <option value="LA">Lao</option>
                      <option value="LV">Latvian</option>
                      <option value="LB">Lebanese</option>
                      <option value="LR">Liberian</option>
                      <option value="LY">Libyan</option>
                      <option value="LI">Liechtensteiner</option>
                      <option value="QL">Lithuania (Dummy Code)</option>
                      <option value="LT">Lithuanian</option>
                      <option value="LU">Luxembourger</option>
                      <option value="MO">Macanese</option>
                      <option value="MK">Macedonia</option>
                      <option value="YT">Mahorais</option>
                      <option value="MG">Malagasy</option>
                      <option value="MW">Malawian</option>
                      <option value="MY">Malaysian</option>
                      <option value="MV">Maldivian</option>
                      <option value="ML">Malian</option>
                      <option value="MT">Maltese</option>
                      <option value="MH">Marshallese</option>
                      <option value="MQ">Martinican</option>
                      <option value="MR">Mauritanian</option>
                      <option value="MU">Mauritian</option>
                      <option value="MX">Mexican</option>
                      <option value="MB">Mexico Buffer</option>
                      <option value="FM">Micronesian</option>
                      <option value="MD">Moldovan</option>
                      <option value="MC">Monegasque</option>
                      <option value="MN">Mongolian</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserratian</option>
                      <option value="MA">Moroccan</option>
                      <option value="MZ">Mozambican</option>
                      <option value="NA">Namibian</option>
                      <option value="NR">Nauruan</option>
                      <option value="NP">Nepalese</option>
                      <option value="NC">New Caledonian</option>
                      <option value="NZ">New Zealander</option>
                      <option value="NI">Nicaraguan</option>
                      <option value="NG">Nigerian</option>
                      <option value="NE">Nigerien</option>
                      <option value="NU">Niuean</option>
                      <option value="NF">Norfolk Islander</option>
                      <option value="KP">North Korean</option>
                      <option value="MP">Northern Mariana Islander</option>
                      <option value="NO">Norwegian</option>
                      <option value="BA">of Bosnia and Herzegovina</option>
                      <option value="VA">of the Holy See (of the Vatican)
                      </option>
                      <option value="OM">Omani</option>
                      <option value="PK">Pakistani</option>
                      <option value="PW">Palauan</option>
                      <option value="PS">Palestinian Occ. Territories</option>
                      <option value="PA">Panamanian</option>
                      <option value="PG">Papua New Guinean</option>
                      <option value="PY">Paraguayan</option>
                      <option value="PE">Peruvian</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Pole</option>
                      <option value="PF">Polynesian</option>
                      <option value="PT">Portuguese</option>
                      <option value="PR">Puerto Rican</option>
                      <option value="QA">Qatari</option>
                      <option value="RE">Reunionese</option>
                      <option value="RO">Romanian</option>
                      <option value="RU">Russian</option>
                      <option value="RW">Rwandan; Rwandese;Rwanda</option>
                      <option value="EH">Sahrawi</option>
                      <option value="SH">Saint Helenian</option>
                      <option value="LC">Saint Lucian</option>
                      <option value="SV">Salvadorian; Salvadoran</option>
                      <option value="WS">Samoan</option>
                      <option value="SM">San Marinese</option>
                      <option value="ST">São Toméan</option>
                      <option value="SA">Saudi Arabian</option>
                      <option value="SN">Senegalese</option>
                      <option value="RS">Serbian</option>
                      <option value="SC">Seychellois</option>
                      <option value="SL">Sierra Leonean</option>
                      <option value="SG">Singaporean</option>
                      <option value="SK">Slovak</option>
                      <option value="SI">Slovene</option>
                      <option value="SB">Solomon Islander</option>
                      <option value="SO">Somali</option>
                      <option value="ZA">South African</option>
                      <option value="GS">South Georgia &amp; South Sandwich
                      </option>
                      <option value="KR">South Korean</option>
                      <option value="SU">Soviet Union</option>
                      <option value="ES">Spaniard</option>
                      <option value="LK">Sri Lankan</option>
                      <option value="PM">St-Pierrais; Miquelonnais;St. Pierre And
                        Miquelon</option>
                      <option value="SD">Sudanese</option>
                      <option value="SR">Surinamer</option>
                      <option value="SJ">Svalbard &amp; Jan Mayen Islands;Svalbard
                        And
                        Jan Mayen Islands</option>
                      <option value="SZ">Swazi</option>
                      <option value="SE">Swede</option>
                      <option value="CH">Swiss</option>
                      <option value="SY">Syrian</option>
                      <option value="TW">Taiwanese</option>
                      <option value="TJ">Tajik</option>
                      <option value="TZ">Tanzanian</option>
                      <option value="TH">Thai</option>
                      <option value="TG">Togolese</option>
                      <option value="TK">Tokelauan</option>
                      <option value="TO">Tongan</option>
                      <option value="TT">Trinidadian; Tobagonian</option>
                      <option value="TN">Tunisian</option>
                      <option value="TR">Turk</option>
                      <option value="TM">Turkmen</option>
                      <option value="TC">Turks and Caicos Islander</option>
                      <option value="TV">Tuvaluan</option>
                      <option value="UM">U.S. Minor Outlaying Islands</option>
                      <option value="UG">Ugandan</option>
                      <option value="UA">Ukrainian</option>
                      <option value="GB">United Kingdom</option>
                      <option value="UY">Uruguayan</option>
                      <option value="VI">US Virgin Islander</option>
                      <option value="UZ">Uzbek</option>
                      <option value="VU">Vanuatuan</option>
                      <option value="VE">Venezuelan</option>
                      <option value="VN">Vietnamese</option>
                      <option value="VC">Vincentian;St Vincent And The Grenadines
                      </option>
                      <option value="WF">Wallisian; Futunan; Wallis and Futuna
                        Islander</option>
                      <option value="YE">Yemeni</option>
                      <option value="YU">Yugoslavia</option>
                      <option value="ZM">Zambian</option>
                      <option value="ZW">Zimbabwean</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group destination-groups">
                    <label class="label-forms destination-labels">Additional Comments</label>
                    <input formControlName="additionalComments" type="text" class="form-control where-to-inputs" />
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div class="col-md-4">
                  <button type="submit" class="submit-btn">Submit</button>
                </div>




              </div>
              <div class="row m-2">
                <div class="col-md-10">
                  <!-- <ul class="footerLinks">
                                    <li>
                                        <p><img class="footerImg" src="./../../../assets/images/phone.png" alt=""><span
                                                style="padding-left: 10px;" class="footerText">+
                                                971 44576077 l +971 58 577 5469</span></p>
                                    </li>
                                    <li>
                                        <p><img class="footerImg" width="20px" src="./../../../assets/images/email.png"
                                                alt=""> <span class="footerText"
                                                style="padding-left: 10px;">mailto:support@devotiontourism.com</span>
                                        </p>
                                    </li>
                                </ul> -->
                </div>
              </div>


            </div>
            <div class="modal-footer" style="border: none ; width: 100%;">


            </div>
          </form>
        </div>
      </div>
    </div>
  </div>