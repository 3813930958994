<div class="cruise-component">

    <div class="cruiseBox">
        <div class="container">
            <div class="contents">
                <div class="row  justify-content-center ">
                    <h1 class="find">Cruises</h1>
                    <div class="col-xl-4 radio_bx">
                        <div class="form-check">
                            <input (click)="isReturn()" [checked]="true" class="form-check-input checks" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault3">
                            <label class="form-check-label" for="flexRadioDefault3">
                                Return
                            </label>
                        </div>
                        <div class="form-check">
                            <input (click)="isOneWay()" class="form-check-input checks" checked="checked" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                One-way
                            </label>
                        </div>
                        <div class="form-check">
                            <input (click)="isMultiWay()" class="form-check-input checks" type="radio"
                                name="flexRadioDefault" id="flexRadioDefault2" checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                Multicity
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngIf="returnData" class="return">
                    <div class="row myrow ">
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="From"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="To" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill" class="test">
                                    <input matInput [matDatepicker]="picker1" placeholder="Leave on"
                                        matIconSuffix="picker1">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker1">
                                        <mat-icon matDatepickerToggleIcon><img class="stay-icons5 calenderIcon"
                                                src="./../../../assets/images/stay/cal.png"
                                                alt=""></mat-icon></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill" class="test">
                                    <input matInput [matDatepicker]="picker2" placeholder="Return on"
                                        matIconSuffix="picker2">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker2">
                                        <mat-icon matDatepickerToggleIcon><img class="stay-icons5 calenderIcon"
                                                src="./../../../assets/images/stay/cal.png"
                                                alt=""></mat-icon></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="row myrow ">
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons6"
                                        src="./../../../assets/images/stay/profile.png" alt=""></span>
                                <input type="number" placeholder="Adults" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons7"
                                        src="./../../../assets/images/stay/child.png" alt=""></span>
                                <input type="number" placeholder="Children" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/flights/diamond.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="Class"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-12">
                            <button class="searchBtn">Search</button>
                        </div>




                    </div>
                </div>

                <div *ngIf="oneWay" class="return">
                    <div class="row myrow ">
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="From"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="To" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill" class="test">
                                    <input matInput [matDatepicker]="picker3" placeholder="Leave on"
                                        matIconSuffix="picker3" formControlName="Leave on">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker3">
                                        <mat-icon matDatepickerToggleIcon><img class="stay-icons5"
                                                src="./../../../assets/images/stay/cal.png"
                                                alt=""></mat-icon></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row myrow ">
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons6"
                                        src="./../../../assets/images/stay/profile.png" alt=""></span>
                                <input type="number" placeholder="Adults" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons7"
                                        src="./../../../assets/images/stay/child.png" alt=""></span>
                                <input type="number" placeholder="Children" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/flights/diamond.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="Class"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-12">
                            <button class="searchBtn">Search</button>
                        </div>




                    </div>
                </div>

                <div *ngIf="MultiWay" class="return">
                    <div class="row myrow justify-content-center">
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="From"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="To" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill" class="test">
                                    <input matInput [matDatepicker]="picker4" placeholder="Leave on"
                                        matIconSuffix="picker4" formControlName="Leave on">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker4">
                                        <mat-icon matDatepickerToggleIcon><img class="stay-icons5"
                                                src="./../../../assets/images/stay/cal.png"
                                                alt=""></mat-icon></mat-datepicker-toggle>
                                    <mat-datepicker #picker4></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="row myrow justify-content-center">
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="From"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/cruise/cruise-ship-icon.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="To" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-3 ">
                            <div class="input-group mb-3">
                                <mat-form-field appearance="fill" class="test">
                                    <input matInput [matDatepicker]="picker3" placeholder="Leave on"
                                        matIconSuffix="picker3" formControlName="Leave on">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker3">
                                        <mat-icon matDatepickerToggleIcon><img class="stay-icons5"
                                                src="./../../../assets/images/stay/cal.png"
                                                alt=""></mat-icon></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                    <div class="row myrow justify-content-center ">

                        <div class="col-xl-2">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons6"
                                        src="./../../../assets/images/stay/profile.png" alt=""></span>
                                <input type="number" placeholder="Adults" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-2 ">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl"><img class="stay-icons7"
                                        src="./../../../assets/images/stay/child.png" alt=""></span>
                                <input type="number" placeholder="Children" class="form-control box-data"
                                    aria-label="Amount (to the nearest dollar)">
                                <span class="input-group-text inp-sybl"></span>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-12">
                            <div class="input-group mb-3">
                                <span class="input-group-text inp-sybl" id="basic-addon1"><img class="stay-icons"
                                        src="./../../../assets/images/flights/diamond.png" alt=""></span>
                                <input type="text" class="form-control box-data" placeholder="Class"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-12">
                            <button class="searchBtn">Search</button>
                        </div>




                    </div>
                </div>
           
            </div>
        </div>
    </div>

    <!-- Carousel Start -->
    <div class="container">
        <!-- Offer Cards Starts -->
        <div class="offers-cards">
            <p class="offers-cards-title">Offers</p>
            <div class="inside-offers-cards">
                <div class="row">
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-1.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-2.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-3.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="offers-cards-main">
                            <img class="cards-offers-img" src="./../../../assets/images/flights/offers-4.png" alt="">
                            <div class="offers-inside-card-content">
                                <p class="offers-cards-inside-title">Upto 20% OFF</p>
                                <p class="offers-cards-inside-content">Lorem ipsum is a type of placeholder or dummy
                                    text used in typesetting and graphic design for previewing layouts. It features
                                    scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder
                                    or dummy text used in typesetting and graphic design</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Offer Cards End -->

        <div class="popular-locations">
            <p class="popular-locations-title">Popular Location</p>
            <p class="popular-locations-content">These popular destinations have a lot to offer</p>
            <div class="owl-carousel owl-popular-locations owl-theme">

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/japan.png" alt="">
                        <p class="popular-location-img-title">Japan</p>
                    
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/south-korea.png" alt="">
                        <p class="popular-location-img-title">South Korea</p>
              
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/tokyo.png" alt="">
                        <p class="popular-location-img-title">Tokyo</p>
                       
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/australia.png" alt="">
                        <p class="popular-location-img-title">Australia</p>
                  
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/bali.png" alt="">
                        <p class="popular-location-img-title">Bali</p>
                     
                    </div>
                </div>

                <div class="item">
                    <div class="popular-location-inside">
                        <img class="popular-location-img" src="./../../../assets/images/stay/india.png" alt="">
                        <p class="popular-location-img-title">India</p>
                   
                    </div>
                </div>

            </div>
        </div>


    </div>

</div>
<!-- Carousel End -->