import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatCalendar, MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { bookFlight, carBook, countryInfo, enquiryForm, hotels, multiFlight, newsLetter, searchFlight, searchFlightData, searchMultiFlight, searchReturnFlight } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Moment } from 'moment';
import * as _moment from 'moment';
import { DatePipe } from '@angular/common';
import { MatStartDate } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import * as intlTelInput from 'intl-tel-input';
import { ChangeDetectorRef } from '@angular/core';
import * as AOS from 'aos';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { Subject } from 'rxjs';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LandingPageComponent implements OnInit, AfterViewInit {
  exchangeRates: any;
  presentCurrency: number = 303
  selectedCurrency: any
  dataOne: string = 'where-to-inputsCountry';
  countryInfoObj: countryInfo = new countryInfo()
  @ViewChild('travellerDetails', { read: ElementRef }) travellerDetails: ElementRef;
  Countryform: FormGroup
  callingCode: string = '971'
  @ViewChild('CloseModels') CloseModels!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  searchControl = new FormControl('');

  allMobileFlags: any = []
  getEnquiryName: any
  comingInfoTxt: any;
  origin: any = 'Hyderabad'
  originCode: any = 'HYD'
  destinationCode: any = 'BLR'
  originAirport = 'Shamshabad airport'
  className = 'Economy'
  totalNmber: number = 1
  searching: FormGroup
  audlt: number = 1
  searchFlightObj = new searchFlight()
  child: number = 0
  infant: number = 0
  destnation: any = 'Bangalore'
  destinatinnAirport = 'Bengaluru International Airport'
  searchText1 = ''
  searchFlight: any
  searchFligh2: any
  searchText2 = ''
  searchText3 = ''
  getEnquiryDesc: any
  getEnquiryEmail: any
  enterMobile2: any

  visaOne: any = 'Dubai'
  visaTwo: any = 'India'
  visaThree: any = 'India'
  dataList: { country: string, thumbnail: string }[] = [];
  countryName: any = 'Dubai'
  countryIsSelected: boolean = false
  uniqueCountries: any = []
  countrysByName: any = []
  allOffersList: any = []
  searchText: string
  internationalList: any = []
  countryList: any = []
  twoItems: any = []
  return: boolean = false
  FlightCabinClass: number = 1
  selectdImg: string = ''
  selected: Date | null;
  selectedDates: Date | null;
  Originselected: Date | null;
  returnSelected: Date | any;
  minDate = new Date(2023, 10, 11);
  countryPackage: any = []
  monthPackage: any = []
  searchCountryList: any = []
  neDatess: Date
  minDates: Date
  bestDeals: any = []
  localBestDeals: any = []
  globalBestDeals: any = []
  InternationalList: any = []
  nationalList: any = []



  newsLetterObj: newsLetter = new newsLetter()
  @ViewChild('calendar') calendar: MatCalendar<Date>;

  @ViewChild('commingSoonPop') commingSoonPop: ElementRef;
  @ViewChild('closeDate') closeDate: ElementRef;
  @ViewChild('openReturnDate') openReturnDate: ElementRef;
  @ViewChild('openPasingers') openPasingers: ElementRef;
  @ViewChild('inputFocus') inputFocus: ElementRef;
  @ViewChild('inputFocusVisaOne') inputFocusVisaOne: ElementRef;
  @ViewChild('inputFocusVisaTwo') inputFocusVisaTwo: ElementRef;
  @ViewChild('inputFocusVisaThree') inputFocusVisaThree: ElementRef;
  @ViewChild('originFocus') originFocus: ElementRef;
  @ViewChild('distinationSelect') distinationSelect: ElementRef;
  @ViewChild('distinationFocus') distinationFocus: ElementRef;
  @ViewChild('roundTripsSelected') roundTripsSelected: ElementRef;
  @ViewChild('selectDate') selectDate: ElementRef;
  @ViewChild('closeAll') closeAll: ElementRef;
  enquiryFormObj = new enquiryForm()
  @ViewChild('inputFocusVisaTwoDiv') inputFocusVisaTwoDiv: ElementRef;
  @ViewChild('inputFocusVisaThreeDiv') inputFocusVisaThreeDiv: ElementRef;
  @ViewChild('multiSource') multiSource: ElementRef;
  @ViewChild('distinationFocusss') distinationFocusss: ElementRef;
  @ViewChild('inputElement') inputElement: ElementRef;
  newsLetter: FormGroup
  isHotDeal: any = []
  allPackages: any = []
  lastTwo: any = []
  enterMobile: string
  allPackages2: any = []
  allCOuntryList: any = []
  carRental2 = []
  BsDatepickerConfig: { containerClass: string; minDate: Date; dateInputFormat: string; };
  constructor(private service: AuthService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private router: Router,
    private renderer: Renderer2, private el: ElementRef,
    private dialog: MatDialog,
    private ngxService: NgxUiLoaderService) {
    this.priceChange()
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
    this.selected = new Date()
    this.Originselected = new Date()
    this.returnSelected = new Date()
    this.neDatess = new Date()
    this.minDates = new Date()
    this.BsDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-green',
      minDate: new Date(),
      dateInputFormat: 'DD/MM/YYYY'
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(300), // Adjust debounce time as needed
      distinctUntilChanged(),
      switchMap(searchTerm => this.service.searchLocationsData({ id: searchTerm })),
      takeUntil(this.unsubscribe$)
    ).subscribe((data: any) => {
      this.searchCountryList = data.data;
    });


  }

  async priceChange() {
    let data = [
      {
        "_id": "63f45519653130c8270a8d6f",
        "currencyCode": "AED",
        "price": 1,
        "symbol": "د.إ"
      }
    ]
    // let updatedPrice = await this.service.allCurrencyByCode("AED").toPromise();
    // localStorage.setItem("selectedCurrency","AED")
    // console.log(updatedPrice)
    localStorage.setItem('priceChange', JSON.stringify(data))
  }
  ngOnInit(): void {
    AOS.init();
    this.allCurrencyByCode('INR')
    this.loadScripts()
    this.getCountry()
    this.defaultKeys()

    this.newsLetter = new FormGroup({
      email: new FormControl('', [Validators.required]),
    });

    this.searching = new FormGroup({
      search: new FormControl('', [Validators.required]),

    });

    this.Countryform = new FormGroup({

      fullname: new FormControl('', [Validators.required, this.noNumbersValidator]),
      email: new FormControl('', [Validators.required]),
      residence: new FormControl('', [Validators.required]),
      nationality: new FormControl('', [Validators.required]),
      visaType: new FormControl('', [Validators.required]),
      travelDestination: new FormControl('', [Validators.required, this.noNumbersValidator]),
      intendedtravelDates: new FormControl('', [Validators.required]),
      additionalComments: new FormControl('', [Validators.required]),
      mobileNo: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
    });

    this.getAllPackages()
    // this.allOffers()
    this.selectCountry()
    this.getAllNames()
    // this.getPackageByCountry('United Arab Emirates')

    this.addCity()
    this.getFaq('airTravelBooking')
    this.getCountryFlags()
    this.commonFunctionAuto('AA')
    // setTimeout(()=>{

    // },4000)
  }

  allCurrencyByCode(code) {
    this.service.allCurrencyByCode(code).subscribe(
      (data: any) => {
        console.log(data)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getExchangeRates() {
    this.service.getExchangeRates().subscribe(
      (data: any) => {
        console.log(data)
      }
    )
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngAfterViewInit() {
    if (this.calendar) {
      // Set minDate to today's date
      const today = new Date();
      this.renderer.setProperty(this.calendar, 'minDate', today);
    }
  }
  noNumbersValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (/[^a-zA-Z\s]/.test(value)) { // Check if value contains anything other than letters and spaces
      return { noNumbers: true };
    }
    return null;
  }
  getFaq(name: any) {
    console.clear()

    this.service.getFaq(name).subscribe(
      (data: any) => {
        this.carRental2 = data
      }
    )
  }
  swap() {
    const temp = this.origin;
    this.origin = this.destnation;
    this.destnation = temp;

    const airpotName = this.originAirport;
    this.originAirport = this.destinatinnAirport;
    this.destinatinnAirport = airpotName;

    const airportCode = this.originCode;
    this.originCode = this.destinationCode;
    this.destinationCode = airportCode;
  }
  getFormattedDate(): string {
    // Format the selected date
    return this.datePipe.transform(this.selected, 'dd MMMM yyyy');

  }
  getOriginselectedFormattedDate(): string {
    // Format the selected date
    this.neDatess = this.Originselected
    return this.datePipe.transform(this.Originselected, 'dd MMMM yyyy');


  }

  onDateSelected(event: any): void {
    this.closeDate.nativeElement.click()
    if (this.return === false) {
      this.openPasingers.nativeElement.click()

    }
    else {
      this.openReturnDate.nativeElement.click()

    }
    this.neDatess = new Date()
    // this.selectedDate = event;
    // You can perform additional actions with the selected date if needed
  }
  onDateSelectedReturn(event: any): void {
    this.closeDate.nativeElement.click()
    this.openPasingers.nativeElement.click()
    this.selecteds()
    // this.selectedDate = event;
    // You can perform additional actions with the selected date if needed
  }

  comingInfo(data) {
    // this.commingSoonPop.nativeElement.click()
    this.comingInfoTxt = data

  }


  onDropdownClick(event: Event): void {
    // Add your logic for preventing dropdown closure here
    event.stopPropagation();
  }
  onDropdownClickReturn(event: Event): void {
    // Add your logic for preventing dropdown closure here
    event.stopPropagation();
  }

  applyChanges(event: Event): void {
    // Add your logic for applying changes here

    // Optionally, you can stop the event propagation to prevent closing the dropdown
    event.stopPropagation();
  }
  SubmitNews() {
    this.service.newsLetter(this.newsLetterObj).subscribe(
      (data: any) => {

        // this.newsLetter.reset()
        this.service.success(data.message)

      },
      (err) => {

        this.service.error(err.error.message);
      }
    )
  }

  getAllPackages() {
    this.service.getPackagesSegrigated().subscribe(
      (data: any) => {
        this.bestDeals = data[0]?.bestDeals.slice(0, 4)
        this.localBestDeals = data[0]?.localBestDeals
        this.globalBestDeals = data[0]?.globalBestDeals
        this.InternationalList = data[0]?.InternationalList
        this.nationalList = data[0]?.nationalList.slice(0, 3)



        // this.uniqueCountries.forEach(packages => {
        //   const country = packages.country;

        //   if (!this.dataList.some(item => item.country === country)) {
        //     this.dataList.push({ country: country, thumbnail: packages.thumbnail });
        //   }
        // });


        // this.allPackages2 = data.sendingArray
        // this.twoItems = this.allPackages.slice(0, 2);
        //  
        // this.lastTwo = this.allPackages.slice(-2);
        // // this.getCountryByPakcage('')
        // // this.getMonthByPakcage('Jan')
        // let hotDeal = []
        //  
        // data.sendingArray.forEach((value) => {
        //   if (value.holidayType === '1') {
        //     nationalList.push(value)
        //   }
        //   if (value.holidayType === '2') {
        //     internationaliList.push(value)
        //   }
        //   if (value.isHotDeal === true) {
        //     hotDeal.push(value)
        //   }
        // })
        // this.isHotDeal = hotDeal.slice(0, 4)
        // this.nationalList = nationalList.slice(0, 3)
        //  
        // this.internationalList = internationaliList

        this.getPackageByCountry(this.InternationalList[0]?.countryName)
        this.getCaroFilter('1')
      }
    )
  }

  selectedCountry: any; // Variable to keep track of the selected item

  selectItem(item: any): void {
    this.selectedCountry = item;
    // Add your additional logic or function calls here
  }

  getCaroFilter(data) {
    let hotDetals = []
    this.allPackages.forEach(element => {
      if (element.holidayType === data) {

        hotDetals.push(element)
      }
    });
    // this.isHotDeal = hotDetals.slice(0, 4)
  }




  getCountryByPakcage(data) {

    let countryPackage = []
    this.allPackages.forEach((value: any) => {
      if (value.country === data) {
        countryPackage.push(value)
      }
    })

    this.countryPackage = countryPackage.slice(0, 4);
  }

  getMonthByPakcage(data) {

    let monthPackage = []
    this.allPackages.forEach((value: any) => {
      if (value.packageMonth === data) {
        monthPackage.push(value)
      }
    })
    this.monthPackage = monthPackage.slice(0, 4);
  }
  allOffers() {
    this.service.allOffers().subscribe(
      (data: any) => {

        this.allOffersList = data.slice(0, 4)
      }
    )
  }

  selectCountry() {
    this.service.CountryLists().subscribe(
      (data: any) => {
        this.countryList = data
      }
    )
  }

  removeCity(index: number): void {
    // Remove the city at the specified index
    this.cities.splice(index, 1);
  }

  selectCountryItem(data) {
    this.countryName = data.name
    this.searchText = ''
  }

  selectInout() {
    setTimeout(() => {
      this.inputFocus.nativeElement.focus()
    }, 10)
  }

  gotoPackageByCOuntryName() {

    this.router.navigateByUrl(`/packagesFilter/${this.countryName}`)
    let dates = this.datePipe.transform(this.selected, 'dd-MM-yyyy');
    localStorage.setItem('selectdDate', JSON.stringify(dates))

  }

  getMoreDetails(items) {

    this.router.navigateByUrl(`/packageView/${items._id}`)
  }
  getMoreDetails2(items) {

    this.router.navigateByUrl(`/packagesFilter/${items}`)
  }
  getAllNames() {
    this.service.getAllNames().subscribe(
      (data: any) => {
        this.allCOuntryList = data
      }
    )
  }

  getPackageByCountry(data) {

    let datas = {
      data: data
    }
    this.service.searchPackage(datas).subscribe(
      (data: any) => {

        this.countrysByName = data.data.slice(0, 1)
        this.lastTwo = data.data.slice(2, 4)
      }
    )
  }


  setFocus(count) {
    if (count === '1') {
      setTimeout(() => {
        this.inputFocusVisaOne.nativeElement.focus()

      }, 10)
    }
    if (count === '2') {
      setTimeout(() => {
        this.inputFocusVisaTwo.nativeElement.focus()

      }, 10)
    }
    if (count === '3') {
      setTimeout(() => {
        this.inputFocusVisaThree.nativeElement.focus()

      }, 10)
    }
  }


  selectOneVisa(item) {
    this.visaOne = item.name
    setTimeout(() => {
      this.inputFocusVisaTwoDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaTwo.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }

  selectTwoVisa(item) {
    this.visaTwo = item.name
    setTimeout(() => {
      this.inputFocusVisaThreeDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaThree.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  selectThreeVisa(item) {
    this.visaThree = item.name
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  submitVisaFor() {

    if (this.visaOne === this.visaThree) {
      this.service.error('please check from country and to country should not be same')
      return;
    }
    this.service.getVisaByCountry(this.visaOne).subscribe(
      (data: any) => {

        if (data.response.length === 0) {
          // this.router.navigateByUrl('/home')
          this.service.error('This visa not found')
          return
        }
        else {
          let info = {
            iamTravelling: this.visaOne,
            iamCityzenOf: this.visaTwo,
            iamTravellingFrom: this.visaThree
          }
          let share = JSON.stringify(info)
          this.router.navigateByUrl(`/visa-overview/${share}`)
        }


      }
    )

  }

  submitVisaForTargetted(data, country) {
    let info = {
      iamTravelling: data,
      iamCityzenOf: 'United Arab Emirates',
      iamTravellingFrom: country
    }
    let share = JSON.stringify(info)
    this.router.navigateByUrl(`/visa-overview/${share}`)
  }

  selectedCity = 'Dubai';

  onCitySelect(city: string) {
    this.selectedCity = city;
  }


  commonFunctionAuto(data: any) {

    // this.service.searchLocationsListItems().subscribe(
    //   (data: any) => {

    //     this.searchCountryList = data.data

    //   }
    // )
  }


  getKeypData(data: any) {
    let search = {
      id: data.target.value
    }
    // this.searchCountryList = []
    this.service.searchLocationsData(search).subscribe(
      (data: any) => {

        this.searchCountryList = data.data

      }
    )

  }


  selectOrign(item) {

    this.origin = item.CityName
    this.originAirport = item.AirportName
    this.originCode = item.AirportCode
    this.searchFlight = ''
    this.defaultKeys()
    setTimeout(() => {
      this.distinationSelect.nativeElement.click()
      this.distinationFocus.nativeElement.focus()
    }, 10)
  }

  defaultKeys() {
    let search = {
      id: 'aa'
    }
    // this.searchCountryList = []
    this.service.searchLocationsData(search).subscribe(
      (data: any) => {

        this.searchCountryList = data.data

      }
    )
  }

  selectDestination(item) {

    this.destnation = item.CityName
    this.destinatinnAirport = item.AirportName
    this.destinationCode = item.AirportCode
    this.searchFlight = ''
    this.searchFligh2 = ''
    this.defaultKeys()
    setTimeout(() => {
      this.selectDate.nativeElement.click()
    }, 10)
  }

  oroingFocus() {
    setTimeout(() => {
      this.originFocus.nativeElement.focus()
      this.searchFligh2 = ''
    }, 10);
  }
  increaseAdult() {
    if (this.audlt >= 0) {
      this.audlt++;
    }
  }

  decreaseAdult() {
    if (this.audlt > 1) {
      this.audlt--;
    }
  }


  increaseChild() {
    if (this.child >= 0) {
      this.child++;
    }
  }

  decreaseChild() {
    if (this.child > 0) {
      this.child--;
    }
  }

  increaseInfant() {
    if (this.infant >= 0) {
      this.infant++;
    }
  }

  decreaseInfant() {
    if (this.infant > 0) {
      this.infant--;
    }
  }

  selectClass(data) {
    this.className = data
    if (this.className === 'Economy') {
      this.FlightCabinClass = 1
    }
    else {
      this.FlightCabinClass = 2
    }
  }


  sendTotalCount() {
    this.totalNmber = this.audlt + this.child + this.infant
    this.closeAll.nativeElement.click()
  }

  selectWay(data) {
    if (data === '1') {
      this.return = false
    }
    else {
      this.return = true
    }
  }





  async getCountry() {
    // let IPAddress: any = localStorage.getItem('IPDetails')
    let IPAddress: any = '192.168.0.60'
    let myData = {
      UserName: "test",
      Password: "test",
      BookingMode: "API",
      IPAddress: IPAddress
    }


    // this.searchFlightObj.IPAddress = IPAddress
    // this.searchFlightObj.EndUserBrowserAgent = navigator.userAgent
    // this.searchFlightObj.PointOfSale = 'IN'
    // this.searchFlightObj.RequestOrigin = 'IN'
    this.searchFlightObj.UserData = null


    this.service.userLogin(myData).subscribe(
      async (data: any) => {

        localStorage.setItem('TokenID', data.data.TokenId)
        let result: any = await this.service.decryption(data.data)

      }
    )
  }


  submit() {

    this.searchFlightObj.AdultCount = this.audlt
    this.searchFlightObj.ChildCount = this.child
    this.searchFlightObj.InfantCount = this.infant
    this.searchFlightObj.FlightCabinClass = this.FlightCabinClass
    this.searchFlightObj.TokenId = localStorage.getItem('TokenID')


    let FData: any = []

    this.searchFlightObj.DirectFlight = false



    if (this.return === false) {
      FData.push({
        Origin: this.originCode,
        Destination: this.destinationCode,
        PreferredDepartureTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd'),
        PreferredArrivalTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd')
      })
      this.searchFlightObj.JourneyType = 1
      this.searchFlightObj.Segment = FData
      localStorage.setItem('PreferredDepartureTime', this.datePipe.transform(this.Originselected, 'yyyy-MM-dd'))
      debugger
      localStorage.setItem('SearchInfo', JSON.stringify(this.searchFlightObj))
      localStorage.setItem('myWay', 'Way1')
      this.router.navigate(['/VIewFlightData', JSON.stringify(this.searchFlightObj)])
    }
    else {
      this.searchFlightObj.JourneyType = 2
      this.searchFlightObj.Segment = FData
      FData.push({
        Origin: this.originCode,
        Destination: this.destinationCode,
        PreferredDepartureTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd '),
        PreferredArrivalTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd ')
      })

      FData.push({
        Origin: this.destinationCode,
        Destination: this.originCode,
        PreferredDepartureTime: this.datePipe.transform(this.returnSelected, 'yyyy-MM-dd '),
        PreferredArrivalTime: this.datePipe.transform(this.returnSelected, 'yyyy-MM-dd')
      })

      debugger


      const firstDepartureDate = this.datePipe.transform(this.Originselected, 'yyyy-MM-dd ');
      const returnDepartureDate = this.datePipe.transform(this.returnSelected, 'yyyy-MM-dd ')
      const firstDate = new Date(firstDepartureDate);
      const secondDate = new Date(returnDepartureDate);
      let dateINfo = firstDate <= secondDate;

      if (dateINfo === false) {
        this.service.error("Return date cannot be before the departure date.")
        return
      }
      this.service.searchTwo(this.searchFlightObj).subscribe(
        (data: any) => {
          localStorage.setItem('PreferredDepartureTime', this.datePipe.transform(this.returnSelected, 'yyyy-MM-dd'))
          localStorage.setItem('myWay', 'Way2')
          localStorage.setItem('SearchInfo', JSON.stringify(this.returnSelected))
          debugger
          this.router.navigate(['/VIewFlightData', JSON.stringify(this.searchFlightObj)])
        },
        (err) => {

          this.service.error(err.error.error)
        }
      )

    }



  }

  boxes = []; // assuming boxes is an array

  // Arrays to store origin, destination, and depart values
  origins = [];
  destinations = [];
  departs = [];

  cloneElement(index: number) {
    // Implement the logic to clone the element and update the values
    // For example, you can push default values to the arrays
    this.origins[index] = 'Delhi';
    this.destinations[index] = 'Hyd';
    this.departs[index] = 'Default Depart';

    // Clone the element or perform other necessary operations
  }

  selectMultiOrigin() { }
  cities = [
    { Origin: 'Delhi', airpotCode: '', airportName: '', DairpotCode: '', DairportName: '', Destination: 'DEL', PreferredDepartureTime: new Date(), PreferredArrivalTime: new Date() },
  ];


  citiesCodes = [
    { Origin: 'Delhi', Destination: '', PreferredDepartureTime: '', PreferredArrivalTime: '' },
  ];

  addCity() {
    // Add a new city object to the array
    this.cities.push({ Origin: 'Select city', airpotCode: '', airportName: '', DairpotCode: '', DairportName: '', Destination: 'Select city', PreferredDepartureTime: new Date(), PreferredArrivalTime: new Date() });
    this.citiesCodes.push({ Origin: 'Select city', Destination: 'Select city', PreferredDepartureTime: '', PreferredArrivalTime: '' });
  }

  selectMultiOrign(item, i) {

    this.citiesCodes[i].Origin = item.AirportCode
    this.cities[i].Origin = item.CityName
    this.cities[i].airpotCode = item.AirportCode
    this.cities[i].airportName = item.AirportName
    this.searchFlight = ''
    this.defaultKeys()
  }

  selectMultiS() {
    setTimeout(() => {
      this.multiSource.nativeElement.focus()

    }, 10)
  }
  distinationFocuss() {
    setTimeout(() => {
      this.distinationFocusss.nativeElement.focus()

    }, 10)
  }

  onDateSelectedMulti(event: any, i): void {
    this.closeDate.nativeElement.click()
    this.openPasingers.nativeElement.click()
    this.cities[i].PreferredArrivalTime = event._d

    const dateObj = new Date(this.selectedDates);
    let formattedDate = this.datePipe.transform(dateObj, 'yyyy-MM-dd');
    this.citiesCodes[i].PreferredDepartureTime = formattedDate
    this.citiesCodes[i].PreferredArrivalTime = formattedDate


  }

  serchMulti() {
    // 
    let firtsData = {
      Origin: this.originCode,
      Destination: this.destinationCode,
      PreferredDepartureTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd'),
      PreferredArrivalTime: this.datePipe.transform(this.Originselected, 'yyyy-MM-dd'),
    }
    if (this.citiesCodes.length < 2) {
      return this.service.error('Choose at least three cities')
    }
    // var index = this.citiesCodes.findIndex(flight => flight.Origin === firtsData.Origin && flight.Destination === firtsData.Destination);

    // // If the object is found, replace it; otherwise, push the new object to the beginning
    // if (index !== -1) {
    //     this.citiesCodes.splice(index, 1); // Remove the existing object
    // }

    // // Add the new object to the beginning of the array
    // this.citiesCodes.unshift(firtsData);

    // // Now the 'citiesCodes' array contains the updated or new object at the beginning




    this.searchFlightObj.AdultCount = this.audlt
    this.searchFlightObj.ChildCount = this.child
    this.searchFlightObj.InfantCount = this.infant
    this.searchFlightObj.FlightCabinClass = this.FlightCabinClass
    this.searchFlightObj.TokenId = localStorage.getItem('TokenID')
    this.searchFlightObj.JourneyType = 3
    this.searchFlightObj.DirectFlight = false
    let FData: any = []
    FData = this.citiesCodes
    this.searchFlightObj.Segment = FData


    this.service.multiWayValidations(this.searchFlightObj).subscribe(
      (data: any) => {
        this.citiesCodes.unshift(firtsData);
        let FData: any = []
        FData = this.citiesCodes
        this.searchFlightObj.Segment = FData
        localStorage.setItem('myWay', 'Way1')
        localStorage.setItem('SearchInfo', JSON.stringify(this.searchFlightObj))
        localStorage.setItem('PreferredDepartureTime', FData[0].searchFlightObj)
        this.router.navigate(['/VIewFlightData', JSON.stringify(this.searchFlightObj)])
      },
      (err) => {
        this.service.error(err.error.error)
      }
    )
  }

  selectMultiDestination(item, i) {

    this.citiesCodes[i].Destination = item.AirportCode
    this.cities[i].Destination = item.CityName
    this.cities[i].DairpotCode = item.AirportCode
    this.cities[i].DairportName = item.AirportName
    this.searchFlight = ''
    this.defaultKeys()
  }



  changeCss() {
    this.dataOne = 'where-to-inputsCountry'
  }

  countrylogin() {
    let phone = localStorage.getItem('homePhone')
    let countryCode = localStorage.getItem('countryCode')

    // this.Countryform.value.mobileNo = countryCode + phone

    this.countryInfoObj.fullname = this.Countryform.value.fullname
    this.Countryform.value.countryCode = this.callingCode
    this.service.countryInfo(this.Countryform.value)
      .subscribe(
        (res: any) => {
          this.service.success(res.message)
          this.CloseModels.nativeElement.click()
          this.Countryform.reset()
        },
        (err) => {

          this.service.error(err.error.error)
        }
      )
  }

  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {

        // this.allMobileFlags =[]
        let allData = []
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }

  getCountryID(data) {
    this.callingCode = data.callingCode
    this.selectdImg = data.flag
    this.countryIsSelected = true
  }
  getEnquiryCountryID(data) {
    this.callingCode = data.callingCode
    this.enquiryFormObj.countryCode = data.callingCode
    this.selectdImg = data.flag
    this.countryIsSelected = true
  }

  submitEnquiry() {
    // this.enquiryFormObj.name = this.getEnquiryName
    // this.enquiryFormObj.email = this.getEnquiryEmail
    // this.enquiryFormObj.description = this.getEnquiryDesc
    // this.enquiryFormObj.mobile = this.enterMobile2

    this.service.createEnquiry(this.enquiryFormObj).subscribe(
      (data: any) => {
        this.service.success(data.message)
        // this.getEnquiryName = ''
        // this.getEnquiryEmail = ''
        // this.getEnquiryDesc = ''
        // this.enterMobile2 = ''
        // this.closebuyyer.nativeElement.click()
        this.router.navigateByUrl('/home')
      },
      (err) => {

        this.service.error(err.error.message)
      }
    )

  }
  focusInput() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 100)
  }


  loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        // document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    dots: false,
    navText: ["<i class='fas fa-chevron-right'></i>", "<i class='fas fa-chevron-left'></i>"],
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      }
    }
  }
  // scrolltoSection(e: any) {
  //    
  //   this.router.navigateByUrl('home#section2Id')
  // }

  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: '/../../../assets/images/ScrollHome2.json',
  };

  scrollToSection(element: HTMLElement) {

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  customOptionsData: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  selecteds() {
    setTimeout(() => {
      this.roundTripsSelected.nativeElement.click()
    }, 10)
  }



  CheckCurrency() {
    this.service.CheckCurrency.subscribe(
      (data: any) => {
        // alert(data)
        this.selectedCurrency = data
      }
    )
  }



}
