import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-things',
  templateUrl: './things.component.html',
  styleUrls: ['./things.component.css']
})
export class ThingsComponent implements OnInit{
  dataform:FormGroup
  AllActivities:any =[]
  reminingActivities:any =[]
  constructor(private service:AuthService){}
  ngOnInit(): void {
  
    window.scrollTo(0, 0);
    this.allActivities()
    this.dataform = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
    });
    this.loadScripts()
 
  }

  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }


  createThings(){
    this.service.createThings(this.dataform.value).subscribe(
      (data:any)=>{
this.service.success(data.message)
this.dataform.reset()
      },(err)=>{
        this.service.error(err.error.message)
      }
    )
  }

  allActivities(){
    this.service.allActivities().subscribe(
      (data: any) => {
       this.AllActivities = []
       let info = data.reverse()
       this.AllActivities = info.slice(0, 2)
       this.reminingActivities = info.slice(2)
      },
      (err) => {
        this.service.error(err.error.message)
      }
    )
  }
}
