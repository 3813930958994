<!-- <ngx-ui-loader fgsColor="#aa803" bgsColor="#aa803"></ngx-ui-loader> -->
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<lib-ng-toast></lib-ng-toast>

<a (click)="gotoTop()" class="to-top">
  <i class="fas fa-chevron-up"></i>
</a>




<!-- Social Media Icons -->

<!-- <nav *ngIf="isStop" class="social-media-icons-section">
  <ul class="social-icon-list">
    <li class="social_li"><a class="social-title" routerLink="/home">Flights <i class="fa fa-plane side-icon"></i></a>
    </li>
    <li class="social_li"><a class="social-title" routerLink="/home">Hotels <i class="fa fa-bed side-icon"></i></a></li>
    <li class="social_li"><a class="social-title" routerLink="/home">Car Rentals
        <i class="fa fa-car side-icon"></i></a></li>

  </ul>
</nav> -->

<div id="social" class="sticky-container">
  <ul *ngIf="isStop" class="sticky">
    <li (click)="sendEmail()" style="display: flex; align-items: center;">
      <img src="./../assets/images/Group3.png" width="32" height="32">
      <p style="display: flex; text-align: center;"><a href="mailto:support@devotiontourism.com">Email</a></p>
    </li>
    <li (click)="makePhoneCall()">
      <img src="./../assets/images/icons-images/phone-bg.png" width="32" height="32">
      <p><a href="tel:+ 97144576077" target="_blank">Call Us<br>+ 97144576077 </a></p>
    </li>
    <li (click)="openLinkedInPage()">
      <img src="./../assets/images/icons-images/linkedin-icon.png" width="32" height="32">
      <p><a href="https://www.linkedin.com/company/92558268/admin/" target="_blank">Follow Us on<br>LinkedIn</a></p>
    </li>
    <li (click)="openWhatsApp()">
      <img src="./../assets/images/whatsapp.png" width="32" height="32">
      <p><a  >Chat on<br>Whatsapp</a></p>
    </li>
    <li (click)="openFacebookPage()">
      <img src="./../assets/images/icons-images/facebook-icon.png" width="32" height="32">
      <p><a href="https://www.facebook.com/profile.php?id=100091695764298" target="_blank">Follow Us on<br>Facebook</a>
      </p>
    </li>
    <li (click)="openYoutubePage()">
      <img src="./../assets/images/icons-images/youtube-icon.png" width="32" height="32">
      <p><a href="https://www.youtube.com/@Devotiontravelandtourism" target="_blank">Subscribe on<br>YouYube</a></p>
    </li>
    <li (click)="openPintrestPage()">
      <img src="./../assets/images/pintrest.png" width="32" height="32">
      <p><a href="https://pin.it/1O6HPbY" target="_blank">Follow Us on<br>Pinterest</a></p>
    </li>
  </ul>
</div>
