<div class="allPages">

  <div class="pacagesNew">
    <div class="home-page">



      <div class="stayBox">
        <div class="container">
          <div class="contents">


            <div class=" destination-search-below justify-content-center">
              <div class="tab-content bottomTab-Contents" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-Package" role="tabpanel"
                  aria-labelledby="pills-Package-tab">
                  <div class="visaa-content-below">
                    <!-- <h5 class="Vbook-domestic">We make visas easy for you</h5> -->
                    <div class="row justify-content-center g-0">
                      <div class="col-md-4  p-0">
                        <div class="dropdown form-group Vform-boxes Vleft-borders">
                          <div (click)="setFocus('1')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING TO</label>
                            <h1 class="Vsearch-cities">
                              <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg"
                                alt="" />{{visaOne}}
                            </h1>
                          </div>
                          <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                                  src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocusVisaOne
                                  [(ngModel)]="searchText1" placeholder="Search here" class="Vsrch-box"
                                  type="text" /></a>
                            </li>
                            <li>
                              <ul class="Vciti-namess list-unstyled m-0">
                                <li *ngFor="let item of countryList | filter: searchText1"
                                  (click)="selectOneVisa(item)">
                                  <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                                </li>

                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-3 p-0">
                        <div class="dropdown form-group Vform-boxes">
                          <div (click)="setFocus('2')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <label for="djfjsf" class="d-block Vcity-label">I AM A CITIZEN OF</label>
                            <h1 #inputFocusVisaTwoDiv class="Vsearch-cities">
                              <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />
                              {{ visaTwo.length <= 10 ? visaTwo : (visaTwo | slice:0:14) + '...' }} </h1>
                          </div>
                          <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                                  src="./../../../assets/images/m/searrch.svg" alt="" /><input class="Vsrch-box"
                                  [(ngModel)]="searchText2" #inputFocusVisaTwo placeholder="Search here"
                                  type="text" /></a>
                            </li>
                            <li>
                              <ul class="Vciti-namess list-unstyled m-0">
                                <li *ngFor="let item of countryList | filter: searchText2"
                                  (click)="selectTwoVisa(item)">
                                  <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                                </li>

                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-5 p-0">
                        <div class="dropdown form-group Vform-boxes Vright-borders">
                          <div (click)="setFocus('3')" class="dropdown-toggle Vd-toggle" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <label for="djfjsf" class="d-block Vcity-label">I AM TRAVELLING FROM</label>
                            <h1 #inputFocusVisaThreeDiv class="Vsearch-cities">
                              <img class="Vsearch-iccon" src="./../../../assets/images/m/searrch.svg" alt="" />{{
                              visaThree.length <= 14 ? visaThree : (visaThree | slice:0:14) + '...' }} </h1>

                          </div>
                          <button (click)="submitVisaFor()" class="VsrchBTN-inside d-none d-md-block">
                            Search
                          </button>
                          <ul class="dropdown-menu VdDown-lists" aria-labelledby="dropdownMenuButton1">
                            <li>
                              <a class="dropdown-item Vd-item Vsearchbox-top"><img class="Vsrch-iccons"
                                  src="./../../../assets/images/m/searrch.svg" alt="" /><input #inputFocusVisaThree
                                  [(ngModel)]="searchText3" class="Vsrch-box" placeholder="Search here"
                                  type="text" /></a>
                            </li>
                            <li>
                              <ul class="Vciti-namess list-unstyled m-0">
                                <li *ngFor="let item of countryList | filter: searchText3"
                                  (click)="selectThreeVisa(item)">
                                  <a class="dropdown-item Vd-item">{{item.name | slice:0:20}}</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div (click)="submitVisaFor()" class="Vbtn-beloww d-flex justify-content-center d-md-none">
                      <button class="VsrchBTN-outside">Search</button>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>


      <div class="Overview-visa">
        <div class="uae-VisaData">
          <div *ngIf="isVisaRequired" class="container">
            <div class="uaeVisa-dataBelow">
              <h1 class="uae-title">
                {{visaDetails.countryName}}
              </h1>

              <div class="Evisa-dataBox">
                <select class="form-select form-select-lg visaSelectBtns-mobile d-block d-md-none"
                  aria-label=".form-select-lg example"
                  (change)="setActiveTab($event.target.selectedIndex, visaDetails.visaInfo[$event.target.selectedIndex])">
                  <option *ngFor="let item of visaDetails.visaInfo; let i = index">{{item.title}}</option>
                </select>

                <div class="nav left-BtnTop flex-column nav-pills d-none d-md-block" id="v-pills-tab" role="tablist"
                  aria-orientation="vertical">
                  <h2 class="evisaTOP-left">Choose your VISA</h2>
                  <button *ngFor="let item of visaDetails.visaInfo; let i = index" class="nav-link left-EvisaBtns"
                    [ngClass]="{'active': i === activeTabIndex}" (click)="setActiveTab(i,item)">
                    {{item.title}}
                  </button>

                </div>
                <div class="tab-content right-contentTop" id="v-pills-tabContent">
                  <div class="tab-pane EvisaContent-box fade show active" id="v-pills-7d-evisa" role="tabpanel"
                    aria-labelledby="v-pills-7d-evisa-tab">
                    <div class="visaInside-content">
                      <h1 class="transit-visaTile">
                        {{sectionDetails.title}}
                      </h1>
                      <p class="Single-entry-content">
                        {{sectionDetails.description}}
                      </p>


                      <div class="visa-type-below">
                        <table class="service-Tble">
                          <tr class="visa-Trow">
                            <th class="visa-Theader left-top-border">
                              Service Type
                            </th>
                            <th class="visa-Theader right-bottom-border">
                              Regular
                            </th>
                          </tr>
                          <tr *ngFor="let data of sectionDetails.flags" class="visa-Trow">
                            <td class="visa-Tdata-left">{{data.serviceType}}</td>
                            <td class="visa-Tdata">
                              {{data.serviceDetails}}
                            </td>

                          </tr>

                          <tr class="visa-Trow">
                            <td class="visa-Tdata-left visa-Tdata-left2"> Price</td>
                            <td class="visa-Tdata-left visa-Tdata-left2"> {{sectionDetails.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</td>


                          </tr>

                        </table>
                        <table class="additional-Tble">
                          <tr class="visa-Trow">
                            <th colspan="2" class="visa-Theader table2-topBorder">
                              Additional Services
                            </th>
                          </tr>
                          <tr *ngFor="let info of sectionDetails.addtionalService" class="visa-Trow">
                            <td class="visa-Tdata-left">
                              <div class="form-check m-0">
                                <input
                                  (change)="toggleService(sectionDetails.price,info._id,info.serviceName,info.serviceDescription,$event)"
                                  class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                  {{info.serviceName}}
                                </label>
                              </div>
                            </td>
                            <td class="visa-Tdata">{{info.serviceDescription | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</td>
                          </tr>
                          <tr class="visa-Trow">
                            <td class="visa-Tdata-left visa-Tdata-left2">
                              Total price
                            </td>
                            <td class="visa-Tdata visa-Tdata-left2">{{totalPrice | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}} </td>
                          </tr>
                        </table>
                        <div class="btn-below">
                          <button (click)="navigate()" class="apply-nowBtn">Apply visa</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="insurence-BelowData">
                <p class="travel-insurence-para">
                  * {{sectionDetails.description}}
                </p>
                <p class="travel-insurence-para">** All prices are in AED</p>
                <h5 class="require-docs-title">Required Documents</h5>
                <ul>
                  <li class="document-list-top">
                    <p class="documentList-data">
                      {{sectionDetails.reqDocuments}}
                    </p>
                  </li>


                  <h5 class="additional-title">Additional Notes</h5>
                  <li class="document-list-top">
                    <p class="travel-insurence-para">
                      {{sectionDetails.reqNotes}}
                    </p>
                  </li>
                </ul>
              </div>
              <div class="applying-DevotionBox">
                <h5 class="benefits-title">Benefits of applying with Devotion</h5>
                <div class="applyBox-top list-unstyled">
                  <div class="applyBox-left">
                    <ul class="subList-top">
                      <li class="applyLisst-items">
                        Easy to fill one page application form
                      </li>
                      <li class="applyLisst-items">
                        Apply from the comfort of your home
                      </li>
                      <li class="applyLisst-items">
                        Convenient Payment Options - Credit and Debit Cards accepted
                      </li>
                      <li class="applyLisst-items">
                        Notification via email on each and every stage of document
                        processing
                      </li>
                    </ul>
                  </div>
                  <div class=" applyBox-left">
                    <ul class="subList-top">
                      <li class="applyLisst-items">
                        More than 2 million applications issued and counting
                      </li>
                      <li class="applyLisst-items">
                        Quick processing & turnaround time – one of the best in the
                        industry
                      </li>
                      <li class="applyLisst-items">
                        Application handling by trained specialists
                      </li>
                      <li class="applyLisst-items">
                        24X7 chat support from visa experts.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="faqData-below">
              <h1 class="faq-title">FAQ</h1>
              <div class="accordion accordion-flush faqAcordion-top" id="accordionFlushExample">
                <div class="accordion-item faq-items">
                  <h2 class="accordion-header faq-InsideHeader" id="flush-headingOne">
                    <button class="accordion-button acordionFaq-btns collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      What is car rental?
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse faq-bodyTop collapse"
                    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body faq-body">
                      Placeholder content for this accordion, which is intended to
                      demonstrate the <code>.accordion-flush</code> class. This is
                      the first item's accordion body.
                    </div>
                  </div>
                </div>
                <div class="accordion-item faq-items">
                  <h2 class="accordion-header faq-InsideHeader" id="flush-headingTwo">
                    <button class="accordion-button acordionFaq-btns collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      How do I rent a car?
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse faq-bodyTop collapse"
                    aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body faq-body">
                      Placeholder content for this accordion, which is intended to
                      demonstrate the <code>.accordion-flush</code> class. This is
                      the second item's accordion body. Let's imagine this being
                      filled with some actual content.
                    </div>
                  </div>
                </div>
                <div class="accordion-item faq-items">
                  <h2 class="accordion-header faq-InsideHeader" id="flush-headingThree">
                    <button class="accordion-button acordionFaq-btns collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      What types of cars can I rent?
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse faq-bodyTop collapse"
                    aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body faq-body">
                      Placeholder content for this accordion, which is intended to
                      demonstrate the <code>.accordion-flush</code> class. This is
                      the third item's accordion body. Nothing more exciting
                      happening here in terms of content, but just filling up the
                      space to make it look, at least at first glance, a bit more
                      representative of how this would look in a real-world
                      application.
                    </div>
                  </div>
                </div>
                <div class="accordion-item faq-items">
                  <h2 class="accordion-header faq-InsideHeader" id="flush-headingFour">
                    <button class="accordion-button acordionFaq-btns collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      What is the difference between budget car rental and luxury
                      car rental?
                    </button>
                  </h2>
                  <div id="flush-collapseFour" class="accordion-collapse faq-bodyTop collapse"
                    aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body faq-body">
                      Placeholder content for this accordion, which is intended to
                      demonstrate the <code>.accordion-flush</code> class. This is
                      the third item's accordion body. Nothing more exciting
                      happening here in terms of content, but just filling up the
                      space to make it look, at least at first glance, a bit more
                      representative of how this would look in a real-world
                      application.
                    </div>
                  </div>
                </div>
                <div class="accordion-item faq-items">
                  <h2 class="accordion-header faq-InsideHeader" id="flush-headingFive">
                    <button class="accordion-button acordionFaq-btns collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      Can I rent a car near me?
                    </button>
                  </h2>
                  <div id="flush-collapseFive" class="accordion-collapse faq-bodyTop collapse"
                    aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body faq-body">
                      Placeholder content for this accordion, which is intended to
                      demonstrate the <code>.accordion-flush</code> class. This is
                      the third item's accordion body. Nothing more exciting
                      happening here in terms of content, but just filling up the
                      space to make it look, at least at first glance, a bit more
                      representative of how this would look in a real-world
                      application.
                    </div>
                  </div>
                </div>
              </div>
            </div> -->


              <div class="faqsection">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-10  justify-content-center">
                      <h2 class="faqtitle">FAQs</h2>


                    </div>
                  </div>
                  <div class="row ">
                    <div class="col-md-12">
                      <div class="tab-content" id="myTabContent">
                        <div class="accordion" id="accordionExample">
                          <div *ngFor="let item of carRental2 ; let i = index " class="accordion-item">
                            <h2 class="accordion-header" id="headingOne" [id]="'heading'+i">
                              <button [ngClass]="{ 'collapsed': i != 0 }" [attr.data-bs-target]="'#collapse'+i"
                                class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target=`{$i+1}`
                                aria-expanded="true" aria-controls="collapseOne" [attr.aria-controls]="'collapse'+i">
                                {{item.title}}
                              </button>
                            </h2>
                            <div [id]="'collapse'+i" [ngClass]="{ 'show': i == 0 }" [attr.aria-labelledby]="'heading'+i"
                              class="accordion-collapse collapse" aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                {{item.description}}
                              </div>
                            </div>
                          </div>

                        </div>
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                          <!-- FAQ CAR SECTION START -->


                          <!-- FAQ CAR SECTION END -->







                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div *ngIf="!isVisaRequired">

            <div class="container">
              <div class="applying-DevotionBox">
                <h5 class="benefits-title">Visa not required to <b>{{isVisaLany.id}}</b> for <b>{{isVisaLany.name}}</b>
                  passport holders, Please keep required documents</h5>
                <div class="applyBox-top list-unstyled">
                  <div class="applyBox-left">
                    <ul class="subList-top">
                      <li class="applyLisst-items">
                        Easy to fill one page application form
                      </li>
                      <li class="applyLisst-items">
                        Apply from the comfort of your home
                      </li>
                      <li class="applyLisst-items">
                        Convenient Payment Options - Credit and Debit Cards accepted
                      </li>
                      <li class="applyLisst-items">
                        Notification via email on each and every stage of document
                        processing
                      </li>
                    </ul>
                  </div>
                  <div class=" applyBox-left">
                    <ul class="subList-top">
                      <li class="applyLisst-items">
                        More than 2 million applications issued and counting
                      </li>
                      <li class="applyLisst-items">
                        Quick processing & turnaround time – one of the best in the
                        industry
                      </li>
                      <li class="applyLisst-items">
                        Application handling by trained specialists
                      </li>
                      <li class="applyLisst-items">
                        24X7 chat support from visa experts.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

    </div>

  </div>