import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { career } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import * as AOS from 'aos';
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent {
  allMobileFlags:any = []
  enterMobile:string
  callingCode:string = '971'
  searchText:string
  countryIsSelected:boolean =false
  selectdImg:string =''
  carrers: FormGroup;
  searching: FormGroup;
  blockColor = '#b9975d';
  blockMargin =0
  emailBg = '#fff'
  locationBg  = '#fff'
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  calBg = '#fff'
  formContent ='Website'
  careerObj: career = new career()
  constructor(private service: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    AOS.init();
    this.carrers = new FormGroup({
    
      name: new FormControl('', [Validators.required,this.noNumbersValidator]),
      email: new FormControl('', [Validators.required ,Validators.pattern(this.emailPattern)]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{5,15}$')]),
      description: new FormControl('', [Validators.required ,this.noNumbersValidator]),
      totalexp: new FormControl('', [Validators.required]),
      resume: new FormControl('', [Validators.required]),
    });
    this.searching = new FormGroup({
      search: new FormControl('', [Validators.required]),

    });
    this.getCountryFlags()
  }
  contactusForm() {
    this.careerObj.phone = this.callingCode + '-' + this.enterMobile
    this.service.careerForm(this.careerObj).subscribe(
      (data: any) => {
        this.toastr.success(data.message)
        this.carrers.reset()
      },
      (err) => {
         
        this.toastr.error(err.error.error)
      }
    )

  }

  noNumbersValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (/[^a-zA-Z\s]/.test(value)) { // Check if value contains anything other than letters and spaces
      return { noNumbers: true };
    }
    return null;
  }
  onWeb() {
    this.blockColor = '#b9975d'
    this.calBg = '#fff'
    this.emailBg ='#fff'
    this.locationBg ='#fff'
    this.formContent = 'Flight'

  }
  CallUs() {
    this.calBg = '#b9975d'
    this.blockColor = '#fff'
    this.emailBg ='#fff'
    this.locationBg ='#fff'
    this.formContent = 'Hotel'
  }
  emailUs(){
    this.emailBg ='#b9975d'
    this.blockColor = '#fff'
    this.calBg = '#fff'
    this.locationBg ='#fff'
    this.formContent = 'Car Rental'
  }
  locationBgUs(){
    this.locationBg ='#b9975d'
    this.emailBg ='#fff'
    this.blockColor = '#fff'
    this.calBg = '#fff'
    this.formContent = 'General'
  }

  selectFile(e: any) {
     
    let formData = new FormData();
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      formData.append('resume', e.target.files[0]);
      this.service.UploadResume(formData).subscribe(
        (data:any)=>{
           
          this.careerObj.resume = data.imagePath
          this.careerObj.resumeKey =  data.key
        }
      )
    }
  }
  options: AnimationOptions = {
    path: './../../../assets/images/m/careers.json',
  };
  animationCreated(animationItem: AnimationItem): void {

  }



  getCountryID(data){
    this.callingCode = data.callingCode
    this.selectdImg =data.flag
    this.countryIsSelected = true
  }
  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {

        // this.allMobileFlags =[]
        let allData =[]
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }
  
}
