<div class="visaBookingVIew">




    <div class="visa-FormData">
        <div class="container">
            <h1 class="visa-application">Package booking view</h1>
            <div class="row">
                <div class="col-lg-8">
                    <div *ngFor="let item of packageUserDetails" class="leftform-data">
                        <h1 class="general-details">Personal Details</h1>
                        <div class="row">
                            <div class="col-lg-11">
                                <div class="row g-2 g-md-3">
                              

                               
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">First name</label>
                                        <p>{{item?.personalDetails?.firstname}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Last name</label>
                                        <p>{{item?.personalDetails?.lastname }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Email
                                            address</label>
                                        <p>{{item?.personalDetails?.emailId}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Contact
                                            number</label>
                                        <p>{{item?.personalDetails?.mobileCountryCode}} {{item?.personalDetails?.contactNumber}}</p>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Travel Date</label>
                                        <p>{{packageDetails?.packageStartDate}}</p>
                                    </div>
                                  
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Nationality</label>
                                        <p>{{item?.personalDetails?.nationality }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Date of
                                            birth</label>
                                        <p>{{item?.personalDetails?.birthDay }}/{{item?.personalDetails?.birthMonth }}/{{item?.personalDetails?.birthYear }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Gender</label>
                                        <p>{{item?.personalDetails?.gender }}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="exampleInputEmail1" class="form-label titleTxt">Passport No</label>
                                        <p>{{item?.personalDetails?.passportNumber }}</p>
                                    </div>
                               
                                  
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="LinesData">
                        <!-- <p class="titleTxt" style="text-align: center;">Package status</p>     -->
                         <p class="titleTxt">Application date : {{packageDetails.createdDate | date:'dd/MM/yyyy'}}</p>
                          <p *ngIf="packageDetails.lastUpdated" class="titleTxt">Last update {{packageDetails.lastUpdated | date:'dd/MM/yyyy'}}</p>
                          <p class="titleTxt">Transaction Id : {{packageDetails.refId}}</p>

                          <p *ngIf="packageDetails?.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED' && status ==='1'" class="titleTxt succesPa">Payment success</p>
                          <p *ngIf="packageDetails?.paymentDetails?._embedded?.payment[0]?.state !== 'CAPTURED'  && status ==='0'" class="titleTxt fails">Payment Failed</p>
                          <p *ngIf="packageDetails?.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED' && status ==='2'" class="titleTxt succesPa">Package Completed</p>

                        <!-- <div  *ngIf="packageDetails.status === 0" class="StatusBtns">
                            <button *ngIf="packageDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="StatBtnLeft" type="button">Rejected</button>
                         
                        </div> -->


                        <div  *ngIf="packageDetails.status === 0" id="steps">
                            <div *ngIf="packageDetails.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" class="step active" data-desc="Rejected"><i class="fas fa-times-circle tick2"></i></div>
                          </div>





                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="right-inquery">
                        <div class="travel-box-below">
                            <h3 class="traveling-boxTitle">Package title </h3>
                            <p class="travel-place">{{PackageDetails?.title}}</p>
                            <p class="travel-place">{{PackageDetails?.daysCount}} Days {{PackageDetails?.nightsCount}} Nights</p>
                            <!-- <h3 class="traveling-boxTitle">Days & Nights</h3> -->
                            <!-- <h3 class="traveling-boxTitle">Subtitle</h3> -->
                            <p *ngIf="packageDetails?.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" aria-disabled="true" (click)="packageInvoice()" class="travel-place donloInvo">Email Invoice</p>
                            <p *ngIf="packageDetails?.paymentDetails?._embedded?.payment[0]?.state === 'CAPTURED'" aria-disabled="true" (click)="downloadPackageInvoice()" class="travel-place donloInvo">Download Invoice</p>
                        </div>
                        <div class="fare-box-below">
                            <h4 class="fare-sumary">Fare Summary</h4>
                         
                            <ul  class="fare-list-top">
                                <li class="fare-lists">
                                    <span class="plus-minusIcons"></span>Base fare
                                </li>
                                <li class="fare-lists">{{packageDetails?.totalPrice / packageUserDetails?.length | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                            </ul>
                            <ul *ngFor="let price of packageDetails.additionalChargesIds" class="fare-list-top">
                                <li class="fare-lists">
                                    <span class="plus-minusIcons"></span>{{price.name}}
                                </li>
                                <li class="fare-lists">{{price.price | priceExchange:selectedCurrency | async   | number:'1.0-2'}} {{selectedCurrency}}</li>
                            </ul>
                            <ul class="fare-list-top amount-top">
                                <li class="total-amount">
                                    Total Amount
                                </li>
                                <li class="total-amount">{{packageDetails?.totalPrice  | priceExchange:selectedCurrency | async   | number:'1.0-2' }} {{selectedCurrency}}</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>







  