<div class="things-cpmponent ">


    <div class="thingsBox">
        <div class="container">
            <div class="contents">
                <div class="edit-subscription">
                    <input
                      class="email-Edit"  type="email"placeholder="type something....."
                    />
                    <button  class="search-edit" type="submit">
                      <i class="fas fa-search edit-searchICN"></i>Search
                    </button>
                  </div>
               
            </div>
        </div>
    </div>

    <!-- Carousel Start -->
    <div class="container">
    
        <div style="margin-top: 30px;" class="popular-locations2">
            <p class="popular-locations-title">Trending destinations</p>
            <p class="popular-locations-content">Travelers searching for United Arab Emirates also booked these</p>
            <div class="row">
                <div *ngFor="let item of AllActivities" class="col-xl-6">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img [src]="item.image" alt="" class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>{{item.countryName}}
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            ${{item.price}}
                        </p>
                    </div>
                </div>
            
            </div>
            <div class="row">
                <div *ngFor="let items of reminingActivities" class="col-xl-4">
                    <div class="city-locations">
                        <div class="imge-below1">
                            <img [src]="items.image" alt=""  class="city-images card-im" />
                        </div>

                        <p class="location-edit">
                            <span class="loc-icon"><i class="fas fa-map-marker-alt"></i></span>{{items.countryName}}
                        </p>
                        <p class="deal-price">
                            Deal Start at <br />
                            ${{items.price}}
                        </p>
                    </div>
                </div>
              
            </div>

        </div>

    </div>

</div>
<!-- Carousel End -->