import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  counterSubject = new BehaviorSubject<number>(0);
  constructor(private injector: Injector, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let tokenizeReq = req
    let authservice = this.injector.get(AuthService)
    const token = localStorage.getItem('token')


    if (token != null) {
      tokenizeReq = req.clone({
        headers: req.headers.set('Authorization', token)
      })
    }



    return next.handle(tokenizeReq).pipe(tap(() => (error: any) => {
    
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          console.log('Session Error');
        }

        if (error.status === 402) {
          console.log('Session Error');
    

        }

        if (authservice.isLoggedin() === false) {
          console.log('Session Error');
        

        }

        if (error.status === undefined) {
          console.log('Session Error');

        }

        if (error.status === null) {
          console.log('Session Error');
    

        }
      }
    }

    )
    );

  }
}
