
<!-- Data Caro -->
<div class="embedsocial-hashtag" data-ref="12949d4cb2a7aac905aa934c4cb9935eecfc928d"><a
    class="feed-powered-by-es feed-powered-by-es-slider-new" href="https://embedsocial.com/social-media-aggregator/"
    target="_blank" title="Widget by EmbedSocial">Widget by EmbedSocial<span>→</span></a></div>


    <!-- faq -->
<div  *ngIf="false" class="faqsection">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8  justify-content-center">
                <h2 class="faqtitle">FAQ</h2>
                <ul class="nav nav-tabs faq-main" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Car Rental</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false">Air Travel
                            Booking</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                            type="button" role="tab" aria-controls="contact" aria-selected="false">Hotel
                            Booking</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="corporate-tab" data-bs-toggle="tab" data-bs-target="#corporate"
                            type="button" role="tab" aria-controls="corporate" aria-selected="false">Corporate
                            Booking</button>
                    </li>
                </ul>

            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                        <!-- FAQ CAR SECTION START -->

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is car rental?
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Car rental is the process of renting a vehicle, typically a car, for a specified
                                        period from a car rental company. It is a popular option for travelers who want
                                        the flexibility and convenience of having their own transportation while
                                        traveling.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How do I rent a car?
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        To rent a car, you can contact a car rental company either online or in-person
                                        and provide them with the required information such as the pick-up and drop-off
                                        location, dates you need the car, and the type of car you want. You will also
                                        need to provide a valid driver's license and a credit card for payment.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        What types of cars can I rent?
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Car rental companies offer a wide variety of vehicles to choose from, including
                                        economy, compact, midsize, full-size, SUVs, luxury cars, and more. The type of
                                        car you choose will depend on your needs and preferences.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                                        What is the difference between budget car rental and luxury car rental?
                                    </button>
                                </h2>
                                <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Budget car rental is more affordable and offers basic features, while luxury car
                                        rental is more expensive and offers high-end features and luxury amenities such
                                        as leather seats, premium sound systems, and advanced safety features.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                                        Can I rent a car near me?
                                    </button>
                                </h2>
                                <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Yes, car rental companies have locations throughout many cities and regions,
                                        making it easy to rent a car near you. You can also search for car rental
                                        companies online and compare prices and services.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FAQ CAR SECTION END -->







                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                        <!-- Air Travell section start -->
                        <div class="accordion" id="accordionExample2">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading11">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                        What is air travel booking?
                                    </button>
                                </h2>
                                <div id="collapse11" class="accordion-collapse collapse show"
                                    aria-labelledby="heading11" data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                        Air travel booking is the process of reserving airline tickets for travel by
                                        air, either for domestic or international destinations. It is an essential part
                                        of travel planning for most people, as air travel is often the most efficient
                                        and convenient mode of transportation for long distances.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading22">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                        How do I book a flight?
                                    </button>
                                </h2>
                                <div id="collapse22" class="accordion-collapse collapse" aria-labelledby="heading22"
                                    data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                        You can book a flight online through airline websites or third-party travel
                                        booking websites. Simply select your departure and arrival cities, travel dates,
                                        and the number of passengers, and you will be provided with a list of available
                                        flights. You can also compare prices and flight options to find the best deal.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading33">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33">
                                        What are airfare deals and how can I find them?
                                    </button>
                                </h2>
                                <div id="collapse33" class="accordion-collapse collapse" aria-labelledby="heading33"
                                    data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                        Airfare deals are discounts or promotions on airline tickets offered by airlines
                                        or travel booking websites. You can find them by subscribing to airline
                                        newsletters or following them on social media, or by searching for them on
                                        travel booking websites. Some travel booking websites also offer price alerts to
                                        notify you of price drops and special deals.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading44">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse44" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        What is the difference between domestic and international flights?
                                    </button>
                                </h2>
                                <div id="collapse44" class="accordion-collapse collapse" aria-labelledby="heading44"
                                    data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                        Domestic flights are flights that operate within the same country, while
                                        international flights are flights that operate between different countries.
                                        International flights typically require additional travel documents such as
                                        passports and visas.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading55">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse55" aria-expanded="false" aria-controls="collapse55">
                                        How can I reserve airline tickets for a group?
                                    </button>
                                </h2>
                                <div id="collapse55" class="accordion-collapse collapse" aria-labelledby="heading55"
                                    data-bs-parent="#accordionExample2">
                                    <div class="accordion-body">
                                        You can reserve airline tickets for a group by contacting the airline directly
                                        or through a travel booking website that specializes in group travel. Group
                                        rates may be available, and you may also be able to arrange special services
                                        such as seating assignments and meals.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Air Travell section end -->
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                        <!-- Hotel Booking section start -->
                        <div class="accordion" id="accordionExample3">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading111">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse111" aria-expanded="true" aria-controls="collapse111">
                                        What is hotel booking?
                                    </button>
                                </h2>
                                <div id="collapse111" class="accordion-collapse collapse show"
                                    aria-labelledby="heading111" data-bs-parent="#accordionExample3">
                                    <div class="accordion-body">
                                        Hotel booking is the process of reserving hotel rooms for a specified period,
                                        generally on a daily or weekly basis, either through hotel websites or
                                        third-party travel booking websites. It is an important part of travel planning,
                                        as finding the right hotel can greatly enhance your travel experience.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading222">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse222" aria-expanded="false" aria-controls="collapse222">
                                        How do I book a hotel?
                                    </button>
                                </h2>
                                <div id="collapse222" class="accordion-collapse collapse" aria-labelledby="heading222"
                                    data-bs-parent="#accordionExample3">
                                    <div class="accordion-body">
                                        You can book a hotel online through hotel websites or third-party travel booking
                                        websites. Simply select your destination, travel dates, and the number of
                                        guests, and you will be provided with a list of available hotels. You can also
                                        compare prices and amenities to find the best deal.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading333">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse333" aria-expanded="false" aria-controls="collapse333">
                                        What types of hotels can I book?
                                    </button>
                                </h2>
                                <div id="collapse333" class="accordion-collapse collapse" aria-labelledby="heading333"
                                    data-bs-parent="#accordionExample3">
                                    <div class="accordion-body">
                                        Hotels can range from budget hotels to luxury hotels, and there are many options
                                        in between. You can choose
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- Hotel Booking section end -->



                    </div>
                    <div class="tab-pane fade" id="corporate" role="tabpanel" aria-labelledby="corporate-tab">



                        <!-- Corporate section start -->
                        <div class="accordion" id="accordionExample4">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading1111">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse1111" aria-expanded="true"
                                        aria-controls="collapse1111">
                                        What is corporate travel?
                                    </button>
                                </h2>
                                <div id="collapse1111" class="accordion-collapse collapse show"
                                    aria-labelledby="heading1111" data-bs-parent="#accordionExample4">
                                    <div class="accordion-body">
                                        Corporate travel refers to any travel that is arranged and paid for by a company
                                        or organization for business purposes. This type of travel may include attending
                                        conferences, meetings, training sessions, or visiting clients or business
                                        partners.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading2222">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse2222" aria-expanded="false"
                                        aria-controls="collapse2222">
                                        What are corporate travel management services?
                                    </button>
                                </h2>
                                <div id="collapse2222" class="accordion-collapse collapse" aria-labelledby="heading2222"
                                    data-bs-parent="#accordionExample4">
                                    <div class="accordion-body">
                                        Corporate travel management services are specialized services designed to help
                                        companies and organizations manage their business travel. These services include
                                        travel planning, booking flights, hotels, rental cars, and other travel-related
                                        services. They also manage travel expenses, develop travel policies, and provide
                                        reporting and analytics to help companies make informed decisions about their
                                        travel spend.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="heading3333">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapse3333" aria-expanded="false"
                                        aria-controls="collapse3333">
                                        What is business travel?
                                    </button>
                                </h2>
                                <div id="collapse3333" class="accordion-collapse collapse" aria-labelledby="heading3333"
                                    data-bs-parent="#accordionExample4">
                                    <div class="accordion-body">
                                        Business travel is a subset of corporate travel and refers specifically to
                                        travel that is undertaken for business purposes, such as attending meetings,
                                        conferences, or training sessions, or visiting clients or business partners.
                                        Business travel can be domestic or international and can involve multiple modes
                                        of transportation, including air, rail, and car.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- Corporate section end -->



                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="false" class="newslatters-content">
        <div class="row">
            <div class="col-md-6 p-0">
                <div class="news-bg">
                    <h3 class="newsletter-title">NEWSLETTER</h3>
                    <h3 class="stay-upto" data-aos="zoom-in" data-aos-duration="1000" data-aos-mirror="false"
                        data-aos-once="true">
                        Exclusive travel tips, giveaways and more!</h3>

                    <div class="row justify-content-center">
                        <div>
                            <!-- <form [formGroup]="newsLetter" (ngSubmit)="SubmitNews()" class="email-forms row">
                               <div class="col-md-8">
                                   <input type="email"  placeholder="Enter Your Email" [(ngModel)]="newsLetterObj.email" formControlName="email" class="form-control input-email"
                                       >
                                   <div class="error-container">
                                       <div class="error-message" *ngIf="newsLetter.get('email')?.invalid && (newsLetter.get('email')?.touched ||
                                        
                                       newsLetter.get('email')?.dirty)">
           
                                           <i class="fas fa-info-circle error-icon"></i>
           
                                           Email is Required
           
                                       </div>
           
                                   </div>
                               </div>
                               <div class="col-md-2">
                                   <button type="submit" class="btn btn-primary emil-subscribe mb-3">Subscribe</button>
                               </div>
                           </form> -->

                            <form [formGroup]="newsLetter" (ngSubmit)="SubmitNews()">
                                <div class="inside-email">
                                    <input placeholder="Enter Your Email" [(ngModel)]="newsLetterObj.email"
                                        formControlName="email" type="email" class="form-control" id="email"
                                        name="email">

                                </div>
                                <div class="inside-button">
                                    <button type="submit" class="submit-button">Subscribe</button>
                                </div>
                                <div class="error-container">
                                    <div class="error-message" *ngIf="newsLetter.get('email')?.invalid && (newsLetter.get('email')?.touched ||
                                        
                                       newsLetter.get('email')?.dirty)">
                                        <!-- 
                                       <i class="fas fa-info-circle error-icon"></i>
           
                                       Email is Required -->

                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-0">
                <div class="news-bg2">
                    <ul class="news-point">
                        <li class="addressing">
                            <a><i class="fas fa-map-marker-alt location-icon fa-2x"></i> <span class="news-txt">502-Iris
                                    Bay,Opp. JW Marriott Hotel, Business Bay, Dubai -UAE</span></a>
                        </li>
                        <li class="addressing">
                            <a><i class="fas fa-phone location-icon fa-2x"></i><span class="news-txt">+971
                                    585775469</span></a>
                        </li>
                        <li class="addressing">
                            <a><i class="fas fa-envelope location-icon fa-2x"></i> <span
                                    class="news-txt">support@devotiontourism.com</span></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



