<!-- nav-bar -->
<header class="container">

  <nav class="navbar-full1">
    <ul class="social-data ml-auto ">
      <!-- <li class=" social-icon-lists" routerLinkActive="active">
        <a href="mailto:support@devotiontourism.com" class=" icon-name"><img class="icon-inside"
            src="./../../../../assets/images/Group3.png" alt=""></a>
      </li> -->
      <!-- <li class="social-icon-lists " routerLinkActive="active">
        <a href="tel:+ 97144576077" class=" icon-name"><img class="icon-inside"
            src="./../../../../assets/images/icons-images/phone-bg.png" alt=""></a>
      </li> -->
      <li class=" social-icon-lists" routerLinkActive="active">
        <a class=" icon-name" href="https://www.youtube.com/@Devotiontravelandtourism" target="_blank"><img
            class="icon-inside" src="./../../../../assets/images/icons-images/youtube-icon.png" alt=""></a>
      </li>
      <li class=" social-icon-lists " routerLinkActive="active">
        <a href="https://www.linkedin.com/company/92558268/admin/" target="_blank" class="  icon-name"><img
            class="icon-inside" src="./../../../../assets/images/icons-images/linkedin-icon.png" alt=""></a>
      </li>
      <li class=" social-icon-lists" routerLinkActive="active">
        <a href="https://www.facebook.com/profile.php?id=100091695764298" target="_blank" class=" icon-name"><img
            class="icon-inside" src="./../../../../assets/images/icons-images/facebook-icon.png" alt=""></a>
      </li>
      <li class="social-icon-lists " routerLinkActive="active">

      </li>


      <li class=" social-icon-lists responceDesign ml-auto" routerLinkActive="active">
        <p (click)="sendEmail()" class="supportMail"> <span><img class="smsIcon"
              src="./../../../../assets/images/profile/Complete.svg" alt=""></span>support@devotiontourism.com</p>

      </li>

      <li class=" social-icon-lists responceDesign" routerLinkActive="active">
        <p (click)="makePhoneCall('+971 585775469')" class="supportMails"> <span><img class="smsIcons"
              src="./../../../../assets/images/profile/call.svg" alt=""></span>+971 585775469</p>
      </li>

      <li style="border-left: 1px solid #fff; border-right: 1px solid #fff;" class=" social-icon-lists responceDesign"
        routerLinkActive="active">
        <p (click)="makePhoneCall('+971 44488538')" class="supportMails"> <span><img class="smsIcons"
              src="./../../../../assets/images/bookings/landLine.svg" alt=""></span>+971 44488538</p>
      </li>

      <li class=" social-icon-lists responceDesign" routerLinkActive="active">
        <div class="nav-link dropdown-toggle currency-link" data-bs-toggle="dropdown" aria-expanded="false">
          {{selectedCurrency}}</div>
        <div class="dropdown-menu  currency-menu-top">
          <ul class="currency-box-top">
            <li *ngFor="let item of currencyList" class="currency-lists"
              [ngClass]="{'selected-currency': item.currencyCode === selectedCurrency}">
              <a [ngClass]="{'selected-currencyTxt': item.currencyCode === selectedCurrency}"
                class="dropdown-item currency-items" (click)="selectCurrency(item)">
                {{item.symbol}} {{item.currencyCode}}
              </a>
            </li>

          </ul>
        </div>
      </li>
      

      <li *ngIf="!isLogin" class=" social-icon-lists loginBtnsNew"
      routerLinkActive="active" data-bs-toggle="modal"
      data-bs-target="#exampleModal33">
      <p #tryToOpenLogin  class="supportMails suportLogin"><img class="smsIcons"
        src="./../../../../assets/images/bookings/login.png" alt="" >Login</p>
    </li>

    <li *ngIf="isLogin" class="social-icon-lists dropdown" routerLinkActive="active">
      <span style="padding: 0 !important; color: #fff;" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <!-- Use the 'ProfileImage' class for the image element -->
        <img class="ProfileIMge" *ngIf="profileImg" width="60px" height="60px" [src]="profileImg" alt="">
        <img class="ProfileIMge" *ngIf="!profileImg" width="60px" height="60px" src="./../../../../assets/images/dummyPic.avif" alt="">
      </span>
      <ul *ngIf="isLogin" class="dropdown-menu dropdown-menu-end myPackages mySideNav" aria-labelledby="navbarDropdown">
        <!-- Keep the rest of the code as is -->
        <li routerLink="/userProfile" class="pac-list"><a class="dropdown-item"><i class="fas fa-user-circle"></i> Profile</a></li>
        <li routerLink="/bookings" class="pac-list"><a class="dropdown-item"><i class="fas fa-business-time"></i> Bookings</a></li>
        <li (click)="logOut()" class="pac-list"><a class="dropdown-item"><i class="fas fa-sign-out-alt"></i> Logout</a></li>
      </ul>
    </li>
    

      <!-- <li class=" social-icon-lists ml-auto" routerLinkActive="active">
                <a href="https://www.linkedin.com/company/92558268/admin/" target="_blank" class="  icon-name"><img
                        class="icon-inside" src="./../../../../assets/images/icons-images/linkedin-icon.png" alt=""></a>
            </li>
            <li class=" social-icon-lists" routerLinkActive="active">
                <a  href="https://www.facebook.com/profile.php?id=100091695764298" target="_blank"
                    class=" icon-name"><img class="icon-inside"
                        src="./../../../../assets/images/icons-images/facebook-icon.png" alt=""></a>
            </li>
            <li class=" social-icon-lists" routerLinkActive="active">
                <a class=" icon-name" href="https://www.youtube.com/@Devotiontravelandtourism" target="_blank"><img class="icon-inside"
                        src="./../../../../assets/images/icons-images/youtube-icon.png" alt=""></a>
            </li>

            <li class=" social-icon-lists" routerLinkActive="active">
                <a class=" icon-name" href="https://pin.it/1O6HPbY" target="_blank"><img class="icon-inside"
                        src="./../../../../assets/images/pintrest.png" alt=""></a>
            </li> -->
    </ul>
  </nav>


  <nav (mouseenter)="leavePackages()" class="navbar navbar-expand-lg navbar-full">
    <a class="navbar-brand" (click)="setFlugIn()" routerLink="/"><img src="./../../../../assets/images/Logo.png"
        class="nalogo" alt="no-image"></a>
    <button class="navbar-toggler" (click)="expand()" id="testing" type="button" data-toggle="collapse"
      data-target="#cst" aria-controls="cst" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars" style="color: #AB823E; font-size: 20px;"></i>
    </button>
    <div class="collapse navbar-collapse" id="cst">
      <ul class="navbar-nav ml-auto custom-header">
        <li class="nav-item active" routerLinkActive="active">
          <a (click)="setFlugIn()" class="nav-link" routerLink="/">Home<span class="sr-only"
              data-toggle="collapse">(current)</span></a>
        </li>

        <li class="nav-item">
          <a class="nav-link" (click)="setFlugIn()" routerLink="/aboutus" data-toggle="collapse">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="setFlugIn()" routerLink="/corporatePackages" data-toggle="collapse">Corporate
            Packages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/globalVisalist" data-toggle="collapse">Global Visa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/Gallery" data-toggle="collapse">Gallery</a>
        </li>
        <!-- <li *ngIf="false" class="nav-item ">

          <div class="dropdown">
       
            <button style="outline: none;" class="btn nav-link dropbutton-inside dropdown-toggle" type="button"
              data-toggle="collapse" data-bs-toggle="modal" data-bs-target="#countrynames">
              Global visa
            </button>
          </div>
        </li> -->

        <li *ngIf="false" (click)="openVisaForm()" class="nav-item  ktm-mega-menu">
          <div class="nav-link dropdown-toggle" data-bs-toggle="dropdown"> Global visa </div>
          <!-- Mega Menu -->
          <div class="dropdown-menu mega-menu p-3">
            <div class="flagList">
              <div class="mdl" id="countrynames">
                <div>
                  <div class="countr-sec">
                    <div class="row">
                      <div class="col-md-2">
                        <a class="countrynames-sec">
                          <h6 class="country-name country-name2">America</h6>
                          <div *ngFor="let item of americaCountry" (click)="selectCOuntry(item)"
                            class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>

                        </a>
                      </div>
                      <div class="col-md-2">
                        <a class="countrynames-sec">

                          <h6 class="country-name">Asia</h6>

                          <div *ngFor="let item of AsiaCountry" (click)="selectCOuntry(item)" class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>

                        </a>
                      </div>
                      <div class="col-md-2">
                        <div class="countrynames-sec">
                          <h6 class="country-name">CIS</h6>

                          <div *ngFor="let item of CISCOuntry" (click)="selectCOuntry(item)" class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>




                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="countrynames-sec">
                          <h6 class="country-name">Europe</h6>


                          <div *ngFor="let item of Europe" (click)="selectCOuntry(item)" class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="countrynames-sec">
                          <h6 class="country-name">Middle East</h6>

                          <div *ngFor="let item of MiddleEast" (click)="selectCOuntry(item)" class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="countrynames-sec">
                          <h6 class="country-name">Oceania</h6>

                          <div *ngFor="let item of Oceania" (click)="selectCOuntry(item)" class="media country-sec">
                            <ul class="coutryENable">
                              <li class="listData">
                                <img class=" countryflag mr-2" [src]="item?.flag" alt="no-image">
                                <div class="media-body">
                                  <p class="countrytitle">{{item?.title}}</p>
                                </div>
                              </li>
                              <li>
                                <i *ngIf="item?.isActive ==='Enable'" style="font-size: 18px; color: green;"
                                  class="fas fa-check"></i>
                                <i *ngIf="item?.isActive !=='Enable'" style="font-size: 18px; color: red;"
                                  class="fas fa-times"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li   class="nav-item ">
                    <div class="dropdown">
                        <button style="outline: none;" class="btn nav-link dropbutton-inside dropdown-toggle dropbottons"
                            type="button" data-toggle="collapse" >
                           
                        </button>
                        
                    </div>
                  
                </li> -->


        <li *ngIf="false" class="nav-item dropdown">
          <!-- <div class="dropdown">
              
                        <a routerLink="/coming-soon" class="nav-link">Corporate Travel</a>

                    </div> -->

          <div class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Corporate
          </div>
          <ul class="dropdown-menu myPackges" aria-labelledby="navbarDropdown">
            <li routerLink="/coming-soon" class="pac-list"><a class="dropdown-item"><img
                  style="width: 18px; margin-right: 6px" src="./../../../../assets/images/headerIcons/bussinessmeet.png"
                  alt="">Business Tour</a></li>
            <li routerLink="/coming-soon" class="pac-list"><a class="dropdown-item"><img
                  style="width: 18px; margin-right: 6px" src="./../../../../assets/images/headerIcons/corporate.png"
                  alt=""> Corporate Tour</a></li>


          </ul>
        </li>
     

        <!-- <li
          class="nav-item active dropdown currency-item"
          routerLinkActive="active"
        >
          <div
            class="nav-link dropdown-toggle currency-link"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{selectedCurrency}}</div
          >
          <div class="dropdown-menu  currency-menu-top">
            <ul class="currency-box-top">
              <li *ngFor="let item of currencyList" class="currency-lists" [ngClass]="{'selected-currency': item.currencyCode === selectedCurrency}">
                <a [ngClass]="{'selected-currencyTxt': item.currencyCode === selectedCurrency}" class="dropdown-item currency-items" (click)="selectCurrency(item)">
                    {{item.symbol}} {{item.currencyCode}}
                </a>
            </li>
         
            </ul>
          </div>
        </li> -->
        <li class="nav-item dropdown">
          <div class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            More
          </div>
          <ul class="dropdown-menu myPackges" aria-labelledby="navbarDropdown">

            <li (click)="comingSooon()" class="pac-list"><a class="dropdown-item">Group Tours</a></li>
            <li (click)="comingSooon()" class="pac-list"><a class="dropdown-item">International Holidays</a></li>
            <li (click)="comingSooon()" class="pac-list"><a class="dropdown-item">Activities</a></li>
          </ul>

        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contactus" data-toggle="collapse">Contact Us</a>
        </li>
        <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/" data-toggle="collapse">Support</a>
                </li> -->
        <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="/carrer" data-toggle="collapse">Career</a>
                </li> -->
        <!-- <li *ngIf="!isLogin" class="nav-item">
          <a #tryToOpenLogin class="nav-link nav-link3 logins-link" data-bs-toggle="modal"
            data-bs-target="#exampleModal33"><button class="btlog">Log In</button></a>
        </li> -->
        <!-- <li  *ngIf="!isLogin"  class="nav-item">
                    <a routerLink="/register" class="nav-link nav-link3 logins-link"><button
                            class="btlog">Sign Up</button></a>
                </li> -->
        <!-- <li class="nav-item">

        </li>
        <li class="nav-item dropdown">
          <span class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" *ngIf="isLogin">
            <img class="ProfileIMge" *ngIf="profileImg" width="60px" height="60px" [src]="profileImg" alt="">
            <img class="ProfileIMge" *ngIf="!profileImg" width="60px" height="60px"
              src="./../../../../assets/images/dummyPic.avif" alt="">
          </span>

          <ul *ngIf="isLogin" class="dropdown-menu dropdown-menu-end myPackges mySIdenVa"
            aria-labelledby="navbarDropdown">
            <li routerLink="/userProfile" class="pac-list"><a class="dropdown-item"><i class="fas fa-user-circle"></i>
                Profile</a></li>
            <li routerLink="/bookings" class="pac-list"><a class="dropdown-item"><i class="fas fa-business-time"></i>
                Bookings</a></li>
            <li (click)="logOut()" class="pac-list"><a class="dropdown-item"><i class="fas fa-sign-out-alt"></i>
                Logout</a></li>

          </ul>
        </li> -->


      </ul>
    </div>
  </nav>

</header>


<!-- <mat-menu #menu="matMenu">
    <button routerLink="/userProfile" mat-menu-item>Profile</button>
    <button mat-menu-item>Bookings</button>
    <button  mat-menu-item>Sign Out</button>
</mat-menu> -->


<!-- Comming soon model -->
<div class="devo_content">
  <div class="modal fade" id="exampleModal5" style="z-index: 9999;" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">

    <div class="modal-dialog">

      <div class="modal-content">
        <div class="comings-soon">
          <div class="coming-info">
            <h1 class="coming-text">Coming soon</h1>
            <div class="okbtn">
              <button class="btn btn-infobg" data-bs-dismiss="modal" aria-label="Close">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
        </div> -->
      <button type="button" #CloseModels style="display: none;" class="btn-close" data-bs-dismiss="modal"
        aria-label="Close"></button>

      <div class="modal-body">

        <div class="login-component">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div *ngIf="!requestForOtp" class="loginsection">

                  <p class="textsign">Sign in or create an account</p>
                  <form [formGroup]="loginform" (ngSubmit)="onSubmit()">
                    <div class="mb-3">

                      <div class="MobileNumInside">
                        <div class="CountryCodeData">
                          <div class="dropdown DropDown">
                            <button (click)="autoFucos()" class="btn FormSelect dropdown-toggle" type="button"
                              id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <img *ngIf="!countryIsSelected" class="FlagImg"
                                src="./../../../../assets/images/country/aue.webp" alt="no-img">
                              <img *ngIf="countryIsSelected" class="FlagImg"
                                [src]="'data:image/png;base64,' + selectdImg" alt="no-img">

                              <p class="CallID"> {{callingCode}}</p>
                            </button>
                            <ul class="dropdown-menu DropDownMenu" aria-labelledby="dropdownMenuButton1">
                              <li class="DropDownLi">

                                <div class="InputSearch">
                                  <img class="SearchImgIcon" src="./../../../../assets/images/search.png" alt="no-data">


                                  <input #myInput formControlName="search" (keyup)="searchCOde($event)"
                                    [(ngModel)]="searchText" class="InputSearchData" type="search"
                                    placeholder="Select Country Name or Code">


                                </div>
                              </li>
                              <li (click)="autoFucos2()" *ngFor="let item of allMobileFlags"
                                (click)="getCountryID(item)" class="DropDownLi"><a class="dropdown-item DropDownItem">
                                  <img *ngIf="item.type === 'base'" class="FlagImg"
                                    [src]="'data:image/png;base64,' + item.flag">
                                  <img *ngIf="item.type === 'img'" class="FlagImg" [src]="item.flag">

                                  {{item?.name}}<span class="FlagSpan">+{{item?.callingCode}}</span></a></li>

                            </ul>
                          </div>

                          <input formControlName="mobile" #numberFocus (keyup)="loginMobileCheck($event)"
                            [(ngModel)]="enterMobile" class="InputNumber" type="tel" placeholder="Mobile Number">

                        </div>


                        <div *ngIf="isDetailsbox">
                          <div class="mb-3 mt-3">
                            <label for="exampleInputPassword1" class="form-label lblingData">Email</label>
                            <input formControlName="emailId" type="email" class="form-control emailCOde"
                              id="exampleInputPassword1">
                          </div>
                          <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label lblingData">First name</label>
                            <input formControlName="firstname" type="email" class="form-control emailCOde"
                              id="exampleInputPassword1">
                          </div>
                          <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label lblingData">Last name</label>
                            <input formControlName="lastname" type="email" class="form-control emailCOde"
                              id="exampleInputPassword1">
                          </div>
                        </div>
                      </div>



                    </div>
                    <p class="errormess" *ngIf="errorMessage">{{errorMessage}}</p>
                    <button *ngIf="!isDetailsRequired" (click)="isPopData()" type="button "
                      [disaibled]="isDetailsRequired" class="btlog">Continue</button>
                    <button *ngIf="isDetailsRequired" type="submit" [disaibled]="isDetailsRequired"
                      class="btlog">Continue</button>
                    <!-- <div *ngIf="!isLogin" class="gogleLogin"
                      style="text-align: center; display: flex; justify-content: center; ">
                     
                      <div id="g_id_onload"
                        data-client_id="722010324314-aeote52imn7na728t9ggb1goufb36p5p.apps.googleusercontent.com"
                        data-context="signin" data-ux_mode="popup"
                        data-login_uri="http://localhost:4000/api/auth/loginWithGoogle" data-nonce=""
                        data-itp_support="true">
                      </div>

                      <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline"
                        data-text="signin_with" data-size="large" data-logo_alignment="left">
                      </div>
                    </div> -->
                  </form>
                  <!-- 
                                    <div class="option-data">
                                        <hr class="line-data">
                                        <p class="para-data"><span class="option-paras">or use one of these
                                                options</span></p>
                                    </div>

                                    <div class="social-info">
                                        <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                                        <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                                    </div> -->

                  <p data-bs-dismiss="modal" routerLink="/privacyPolicy" class="para-content">By signing in you
                    agree with our
                    <span class="terms">Terms & Conditions</span> and <span class="terms">Privacy
                      Statement</span>
                  </p>

                </div>
                <div *ngIf="requestForOtp" class="loginsection">

                  <p class="textsign">Enter OTP</p>
                  <form [formGroup]="loginOtp" (ngSubmit)="submit()">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label conta-text">We sent OTP to
                        your contact number.</label>
                      <div class="mb-3">
                        <input placeholder="Enter OTP"
                          [ngClass]="{ 'is-invalid': loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched }"
                          type="email" formControlName="otp" class="form-control otpFOrm" id="exampleInputEmail1"
                          aria-describedby="emailHelp">
                        <div class="invalid-feedback"
                          *ngIf="loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched">
                          OTP is required.
                        </div>
                        <p *ngIf="closeResendButton" (click)="startTimer(60)" (click)="onSubmit()" class="resentOTP">
                          Resent OTP</p>
                        <p *ngIf="!closeResendButton" class="countDuration">{{timeDuration}}</p>
                        <p (click)="tryDiffNum()" class="tryDiffNum">Try Another Number</p>
                      </div>
                      <div class="invalid-feedback"
                        *ngIf="loginform.controls.mobile.invalid && loginform.controls.mobile.touched">
                        Mobile number is required.
                      </div>
                      <!-- <span class="onSubmit()">resend Otp</span> -->

                    </div>
                    <p class="errormess" *ngIf="errorMessage">{{errorMessage}}</p>
                    <button type="submit" class="btlog">Continue</button>
                  </form>

                  <!-- <div class="option-data">
                                        <hr class="line-data">
                                        <p class="para-data"><span class="option-paras">or use one of these
                                                options</span></p>
                                    </div>

                                    <div class="social-info">
                                        <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                                        <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                                    </div> -->

                  <p class="para-content">By signing in account, you agree with our
                    <span class="terms">Terms & Conditions</span> and <span class="terms">Privacy Policies</span>
                  </p>

                </div>
              </div>
            </div>


          </div>
        </div>




      </div>

    </div>
  </div>
</div>