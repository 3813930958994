import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { NgToastService } from 'ng-angular-popup';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: string = environment.baseURL;
  private url2: string = environment.baseURL2;
  private checkPrice = localStorage.getItem('selectedCurrency')
  private baseUrl = 'https://api.exchangerate-api.com/v4/latest/AED';
  // private url: string ='http://192.168.61.234:4000/v1/'
  // private url2: string ='http://192.168.61.234:4000/'
  constructor(private http: HttpClient,private toast: ToastrService, private router: Router) { }
  error(message:any) {
     
    // this.toast.error({detail:message})
    this.toast.error(message);
  }
  success(message:any) {
    this.toast.success(message);
  }

  getExchangeRates(): Observable<any> {
    return this.http.get<any>(this.baseUrl);
  }



  private user = new BehaviorSubject<string>('john');
  castUser = this.user.asObservable();

  private loginAccess = new BehaviorSubject<string>('john');
  loginCheck = this.loginAccess.asObservable();

  private loginGoogleAccess = new BehaviorSubject<string>('john');
  loginCheckGoogle = this.loginGoogleAccess.asObservable();


  private visaForm = new BehaviorSubject<string>('john');
  visaCheck = this.visaForm.asObservable();
  
  private selectedCurrency = new BehaviorSubject<string>(this.checkPrice);
  CheckCurrency = this.selectedCurrency.asObservable();

  editUser(newUser) {
    this.user.next(newUser);
  }


  getCurrency(currency){
    this.selectedCurrency.next(currency);
  }

  tryLogin(newUser){
    this.loginAccess.next(newUser);
  }

  
  getTestmonials(){
    return this.http.get(this.url2 + 'v1/activities/getTestmonials');

  }

  tryGoogleLogin(newUser){
    this.loginGoogleAccess.next(newUser);
  }


  tryVisa(data){
    this.visaForm.next(data);
  }

  allActivities() {
    return this.http.get(this.url2 + 'v1/activities/allActivities');
  }


  getToken() {
    return localStorage.getItem('token');
  }
  isLoggedin() {
    return !!localStorage.getItem('token');
  }
  logout() {
    localStorage.removeItem('token')
    this.router.navigateByUrl('/')
  }



  getIP() {
    return this.http.get('http://api.ipify.org/?format=json')
  }
  autoDetectCountry() {
    return this.http.get('http://ip-api.com/json')
  }

  userLogin(data: any) {
    return this.http.post(this.url2 + 'api/auth/userLogin', data);
  }
  newsLetter(data: any) {
    return this.http.post(this.url2 + 'api/users/newsLetter', data);
  }
  getipinfo() {
    return this.http.get(this.url2 + 'api/users/getipinfo');
  }

  latestBookings(){
    return this.http.get(this.url2 + 'api/users/latestBookings');

  }

  allCurrencyByCode(data:any){
    return this.http.get(this.url2 +`api/users/allCurrencyByCode?code=${data}`);
  }

  exchangePrice(value,currency){
    
    const exchangeRates = JSON.parse(localStorage.getItem('priceChange'));
    
    // Check if exchange rates are available
    if (!exchangeRates || exchangeRates.length === 0) {
      return of(null);
    }
    const exchangeRateObj = exchangeRates.find(rate => rate.currencyCode === currency);
    
    // Check if the exchange rate exists
    if (!exchangeRateObj || !exchangeRateObj.price) {
      return of(null);
    }

    // Calculate the exchanged price
    const exchangedPrice = value * exchangeRateObj.price;
    
    // Return the result as an observable
    return of(exchangedPrice);
  }


  allCurrencies(){
    return this.http.get(this.url2 +`api/users/allCurrencies`);

  }
  createThings(data: any) {
    return this.http.post(this.url2 + 'api/users/createThings', data);
  }

  contactus(data: any) {
    return this.http.post(this.url2 + 'api/users/contactUs', data);
  }
  allBlogs() {
    return this.http.get(this.url + 'blogs/allBlogs');
  }

  getBlogbyId(id: any) {
    return this.http.get(this.url + `blogs/blog/${id}`);
  }
  getAllIPDetails(data: any) {
    return this.http.post(this.url2 + 'api/users/getAllIPDetails', data);
  }
  bookFlight(data: any) {
    return this.http.post(this.url + 'bookFlight', data);
  }
  bookMultiFlight(data: any) {
    return this.http.post(this.url + 'bookFlightMulti', data);
  }
  bookHotel(data: any) {
    return this.http.post(this.url + 'bookHotel', data);
  }
  bookCar(data: any) {
    return this.http.post(this.url + 'bookCar', data);
  }
  countryInfo(data: any) {
    return this.http.post<any>(this.url2 + 'api/users/coutryInfo', data);
  }
  getCountries() {
    return this.http.get<any>(this.url2 + 'v1/country/getCountries');
  }

  uploadDoc(data: any) {
    return this.http.post(this.url + 'uploadss', data);
  }
  UploadResume(data: any) {
    return this.http.post(this.url2 + 'api/users/UploadResume', data);
  }

  careerForm(data: any) {
    return this.http.post(this.url2 + 'api/users/careerForm', data);
  }

  globalVisaReq(data: any) {
    return this.http.post(this.url2 + 'api/users/globalVisaReq', data);
  }

  register(data: any) {
    return this.http.post(this.url + 'registration', data);
  }

  login(data: any) {
    return this.http.post(this.url2 + 'api/auth/login', data);
  }
  validateLoginUser(data: any) {
    return this.http.post(this.url2 + 'api/auth/validateLoginUser', data);
  }
  loginMobileCheck(data: any) {
    return this.http.post(this.url2 + 'api/auth/loginMobileCheck', data);
  }

  headerFlags() {
    return this.http.get(this.url + 'country/getCountries');
  }
  getCountriesByID(id: any) {
    return this.http.get(this.url + `country/getCountriesByID/${id}`);
  }
  getContact(id: any) {
    return this.http.get(this.url + `contacts/getContact/${id}`);
  }
  allOffers() {
    return this.http.get(this.url2 + 'v1/offers/allOffers');
  }

  getmultiImages(id: any) {
    return this.http.get(this.url2 + `v1/offers/multi/${id}`);
  }

  getOfferById(id: any) {
    return this.http.get(this.url2 + `v1/offers/offer/${id}`);
  }
  
  getVisaByID(id:any) {
    return this.http.get(this.url2 +`v1/visa/getVisaByID/${id}`);
  }
  
  getVisaByCountry(id:any) {
    return this.http.get(this.url2 +`v1/visa/getVisaByCountry/${id}`);
  }
  getVisaList() {
    return this.http.get(this.url2 +`v1/visa/getVisaList`);
  }
  visaRequired(data:any) {
      
    return this.http.get(this.url2 +`v1/visa/visaRequired/${data.id}/${data.name}`);
  }
  
  VisaBooking(data:any) {
      
    return this.http.get(this.url2 +`v1/visa/VisaBooking/${data}`);
  }
  
  UseruploadVisaFlag(data:any) {
    return this.http.post(this.url2 +`v1/visa/UseruploadVisaFlag`,data);
  }
  getSubVisaId(data:any) {
    return this.http.post(this.url2 +`v1/visa/getSubVisaId`,data);
  }

  getPayForVIsa(data:any) {
    return this.http.post(this.url2 +`v1/visa/getPayForVIsa`,data);
  }
  getPayForPackage(data:any) {
    return this.http.post(this.url2 +`v1/packages/getPayForPackage`,data);
  }
  getBookingByID(data:any) {
    return this.http.get(this.url2 +`v1/packages/getBookingByID/${data}`,);
  }

  createSelectedOffer(data: any) {
    return this.http.post(this.url2 + 'v1/offers/createSelectedOffer', data);
  }

  getFaq(name: any) {
    return this.http.get(this.url2 + `v1/faqs/faq/${name}`);
  }

// Package FAQs////

createPackagefaq(data:any) {
  return this.http.post(this.url2 +`v1/packages/createPackagefaq}`,data);
}




  // SEARCH FLIGHTS

  searchOneWayFlight(data: any) {
    return this.http.post(this.url + 'flight/searchOneway', data);
  }
  dynamicSearch(data: any) {
    return this.http.post(this.url + 'flight/dynamicSearch', data);
  }
  searchTwoway(data: any) {
    return this.http.post(this.url + 'flight/searchTwoway', data);
  }
  searchTwoway2(data: any) {
    return this.http.post(this.url + 'flight/searchTwoway2', data);
  }


  orderValidation(data: any) {
    return this.http.post(this.url + 'flight/orderValidation', data);
  }

  FareQuote(data: any) {
    return this.http.post(this.url + 'flight/FareQuote', data);
  }
  FareRule(data: any) {
    return this.http.post(this.url + 'flight/FareRule', data);
  }
  getUserAllCodes() {
    return this.http.get(this.url + 'flight/getUserAllCodes');
  }
  Booking_Ticket(data: any) {
    return this.http.post(this.url + 'flight/Booking_Ticket', data);
  }
  Booking_Both(data: any) {
    return this.http.post(this.url + 'flight/returnBook', data);
  }
  myBooking() {
    return this.http.get(this.url + 'flight/myBooking');
  }
  getAllFailureTickets() {
    return this.http.get(this.url + 'flight/getAllFailureTickets');
  }
  bookFlightValidations(data) {
    return this.http.post(this.url + 'flight/bookFlightValidations', data);
  }
  orderVerification(data) {
    return this.http.post(this.url + 'flight/orderVerification', data);
  }
  validationForTicket(data) {
    return this.http.post(this.url + 'flight/validationForTicket', data);
  }

  getSegmentsInfo(data) {
    return this.http.post(this.url + 'flight/getSegmentsInfo', data);
  }
  searchNew(data:any) {
    return this.http.post(this.url + 'flight/searchNew', data);
  }


  Booking_Book(data: any) {
    return this.http.post(this.url + 'flight/Booking_Book', data);
  }
  GetBookingDetails(data: any) {
    return this.http.post(this.url + 'flight/GetBookingDetails', data);
  }
  GetAvailableBalance(data: any) {
    return this.http.post(this.url + 'flight/GetAvailableBalance', data);
  }

  GetPDFwithID(data: any) {
    return this.http.post(this.url + 'flight/generateReport', data);
  }
  DirectDownloadgenerateReport(data: any) {
    return this.http.post(this.url + 'flight/DirectDownloadgenerateReport', data);
  }
  downloadPDF(data: any) {
    return this.http.post(this.url + 'flight/downloadPDF', data);
  }
  getTicketByPNR(data: any) {
    return this.http.post(this.url + 'flight/getTicketByPNR', data);
  }
  emailToPdfFlight(data: any) {
    return this.http.post(this.url2 + 'api/users/emailToPdfFlight', data);
  }
  generateInvoice(data: any) {
    return this.http.post(this.url2 + 'api/users/generateInvoice', data);
  }


  downloadInvoice(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'YourAuthToken', // Replace with the actual authorization token
    });
    return this.http.post(this.url2 + 'api/users/downloadInvoice', data, { headers, responseType: 'blob' });
  }


  createEnquiry(data: any) {
    return this.http.post(this.url2 + 'api/users/createEnquiry', data);
  }
  Packagesenquiry(data: any) {
    return this.http.post(this.url2 + 'api/users/Packagesenquiry', data);
  }
 
  getGoogleReviews() {
    return this.http.get(this.url2 + 'api/users/getGoogleReviews');
  }
 
  // Website buffering while Loading svg files -->Login select country list
  getCountryFlags() {
    return this.http.get(this.url2 + 'api/users/getCountryFlags');
  }


  Inssearch(data:any) {
    return this.http.post(this.url2 + 'api/users/Inssearch',data);
  }
  downloadPDFs(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'YourAuthToken', // Replace with the actual authorization token
    });
    return this.http.post(this.url2 + 'api/users/downloadPDFFlight', data, { headers, responseType: 'blob' });
  }


  downloadPDFTicket(flightData: any): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob' as 'json', // Set the response type to 'blob'
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    // Make an HTTP POST request to the backend to trigger the PDF generation and download
    return this.http.post<Blob>(`${this.url2}api/users/downloadPDFFlight`, flightData, httpOptions);
  }



  saveTicket(data) {
    return this.http.post(this.url + 'flight/saveTicket', data);
  }
  getsearchLocationsData() {
    return this.http.get(this.url + 'flight/getsearchLocationsData');
  }
  searchLocationsData(data) {
    return this.http.post(this.url + 'flight/searchLocationsData',data);
  }
  searchLocationsListItems() {
    return this.http.get(this.url + 'flight/searchLocationsListItems');
  }
  searchTwo(data) {
    return this.http.post(this.url + 'flight/searchTwo', data);
  }
  multiWayValidations(data) {
    return this.http.post(this.url + 'flight/multiWayValidations', data);
  }
  failedBookings(data) {
    return this.http.post(this.url + 'flight/failedBookings', data);
  }

  CountryLists() {
    return this.http.get(this.url + 'packages/CountryLists');
  }
  getPackageCountries() {
    return this.http.get(this.url + 'packages/getPackageCountries');
  }
  getPay(data: any) {
    return this.http.post(this.url + 'flight/getPay', data);
  }
  paymentCheck(data: any) {
    return this.http.post(this.url + 'flight/paymentCheck', data);
  }
  VisapaymentCheck(data: any) {
    return this.http.post(this.url + 'visa/VisapaymentCheck', data);
  }
  getVisaBookigsByRefID(data: any) {
    return this.http.post(this.url + 'visa/getVisaBookigsByRefID', data);
  }
  getInvoiceForVIsa(data: any) {
    return this.http.post(this.url + 'visa/getInvoiceForVIsa', data);
  }
 
  PackagepaymentCheck(data: any) {
    return this.http.post(this.url + 'packages/PackagepaymentCheck', data);
  }
  getAllPackageBooking() {
    return this.http.get(this.url +`packages/getAllPackageBooking` );
  }
  getPackageInvoices(data) {
    return this.http.post(this.url +`packages/getPackageInvoices`,data );
  }
  getFlightIcon(name: any) {
    return this.http.get(this.url + `flight/getAirLineIcons/${name}`);
  }
  getProfile() {
    return this.http.get(this.url + 'profile/getProfile');
  }
  getOtpToChangeMobileNo(){
    return this.http.get(this.url + 'profile/getOtpToChangeMobileNo');
  }

  verifyOtpFOrMobile(data:any){
    return this.http.post(this.url +`profile/verifyOtpFOrMobile`,data );
  }
  verifyOtpFOrNewMobile(data:any){
    return this.http.post(this.url +`profile/verifyOtpFOrNewMobile`,data );
  }
  getOtpToAddNewMobileNo(data:any){
    return this.http.post(this.url +`profile/getOtpToAddNewMobileNo`,data );
  }
  getUserProfileList() {
    return this.http.get(this.url + 'profile/getUserProfileList');
  }
  updateProfiles(data) {
    return this.http.post(this.url + 'profile/updateProfiles',data);
  }
  edit_Profile(data) {
    return this.http.post(this.url + 'profile/edit_Profile',data);
  }
  deleteProfile(data) {
    return this.http.post(this.url + 'profile/deleteProfile',data);
  }
  userProfiles(data) {
    return this.http.post(this.url + 'profile/userProfiles',data);
  }
  updateProfile(data) {
    return this.http.post(this.url + 'profile/updateProfile', data);
  }
  uploadProfile(data) {
    return this.http.post(this.url + 'profile/uploadProfile', data);
  }
  calculate_profile_completion(data) {
    return this.http.post(this.url + 'profile/calculate_profile_completion', data);
  }

  bookingPersonsAllowed(data) {
    return this.http.post(this.url + 'flight/bookingPersonsAllowed', data);
  }

  inquiry(data: any) {
    return this.http.post(this.url2 + 'api/users/enquiry', data);
  }
  getsearchLocationsDataKey(data: any) {
    return this.http.post(this.url + 'flight/getsearchLocationsDataKey', data);
  }



  encryption(userData:any){
    let key =  environment.userKey;;
    let convertedInfo = CryptoJS.AES.encryption(userData,key).toString(); 
    return convertedInfo
  }
  decryption(userData:any){
      
    let key =  environment.userKey;
    return new Promise<string>((resolve, reject) => {
      const convertedInfo = CryptoJS.AES.decrypt(userData, key).toString();
      resolve(convertedInfo);
    });
  }

  getAllPackages() {
    return this.http.get(this.url + 'packages/getAllPackages');
  }
  getPackagesSegrigated() {
    return this.http.get(this.url + 'packages/getPackagesSegrigated');
  }

  getPackageByID(data:any) {
    return this.http.get(this.url + `/packages/getPackageByID/${data}`);
  }

  getAllNames() {
    return this.http.get(this.url + `/packages/getAllNames`);
  }
  getPackageByCountry(data:any) {
    return this.http.get(this.url + `/packages/getPackageByCountry/${data}`);
  }
  searchPackage(data:any) {
    return this.http.post(this.url + `/packages/searchPackage`,data);
  }
 
  getPackageByCountryFilters(data:any) {
    return this.http.get(this.url + `/packages/getPackageByCountryFilters/${data}`);
  }

  recordFIlters(data:any) {
    return this.http.post(this.url + `/packages/recordFIlters`,data);
  }
 
  packageEnquiry(data) {
    return this.http.post(this.url + `/packages/packageEnquiry`,data);
  }
  getCorporatePackages() {
    return this.http.get(this.url + `/packages/getCorporatePackages`);
  }
  getPackageImagesByID(data:any) {
    return this.http.get(this.url + `/packages/getPackageImagesByID/${data}`);
  }

  getGalleryItems(){
    return this.http.get(this.url2 + `api/users/getGalleryItems`);
  }

  downloadPackageInvoice(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'YourAuthToken', // Replace with the actual authorization token
    });
    return this.http.post(this.url + '/packages/downloadPackageInvoice', data, { headers, responseType: 'blob' });
  }
  getDownloadInvoiceForVIsa(data: any) {
    const headers = new HttpHeaders({
      Authorization: 'YourAuthToken', // Replace with the actual authorization token
    });
    return this.http.post(this.url + '/visa/getDownloadInvoiceForVIsa', data, { headers, responseType: 'blob' });
  }


  allGoogleReviews() {
    return this.http.get(this.url + 'blogs/allGoogleReviews');
  }

}
