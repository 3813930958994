<div class="allPages">
<div class="aboutus-section">
    <div class="slidemain-section">
        <div>
        <div class="owl-carousel owl-theme" id="slidemain">
          <div class="item active">
            <img class="imgsss22" src="./../../../assets/images/icons-images/banner1.png" alt="no-image">
          </div>
          <div class="item">
            <img class="imgsss22" src="./../../../assets/images/icons-images/banner2.png" alt="no-image">
          </div>
          <div class="item">
            <img class="imgsss22" src="./../../../assets/images/icons-images/banner3.png" alt="no-image">
          </div>
          <div class="item">
            <img class="imgsss22" src="./../../../assets/images/icons-images/banner4.png" alt="no-image">
          </div>
        </div>
      
      
        <div  class="maintext-sec">
          <div  class="maintext">
            <div class="videotext-section222">
                <div class="videotext-section">
                  
                    <h2 class="videoheadtxt" data-aos="zoom-in" data-aos-duration="1200" data-aos-mirror="false"
                        data-aos-once="true">Welcome to Devotion Tourism!</h2>
                    <p class="videoheadtxtsub">
                        We are a leading travel and tourism company that specializes in flight booking, hotel booking, and
                        corporate travel services. With our unwavering commitment to excellence and personalized service, we
                        strive to make your travel experiences truly memorable.
                    </p>
                </div>
            </div>
          </div>
      
        </div>
    </div>
      </div>
<!-- 
    <div class="video-sec">
        <video class="video2se" autoplay muted loop playsinline>
            <source src="../../../assets/images/video/videoplayback.mp4" type="video/mp4" loading="lazy" alt="devotion">
        </video>
    </div> -->

    <!-- <img class="videotextsubim" src="./../../../assets/images/aboutus/image2323.png" alt=""> -->

</div>
</div>
<div class="startyour-section">
    <div class="container">
        <div class="row ">
            <div class="col-md-6">
                <div class="mobile">
                    <img class="mobile-img" src="./../../../assets/images/mobile.png" alt="">
                    <div class="content">
                        Exceptional adventures, every time. Start designing your dream trip today.
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="startyour-rig">
                    <h2 class="startyourhead" data-aos="zoom-in" data-aos-duration="1200" data-aos-mirror="false"
                        data-aos-once="true">Start Your Journey Today!</h2>
                    <ul class="startyouri">
                        <li routerLink="/contactus"><img class="startim2"
                                src="./../../../assets/images/aboutus/group1111.png" alt="no-image">
                        </li>
                        <li routerLink="/contactus"><img class="startim2"
                                src="./../../../assets/images/aboutus/group2222.png" alt="no-image">
                        </li>
                        <li routerLink="/contactus"><img class="startim2"
                                src="./../../../assets/images/aboutus/group3333333.png" alt="no-image"></li>
                    </ul>
                    <p style="font-size: 14px;" class="atdevotion">
                        At Devotion Tourism, we understand that travel is not just about reaching a destination but also
                        about creating lasting memories. With our extensive expertise in the travel industry, we are
                        dedicated to providing you with exceptional services that cater to all your travel needs.
                        At Devotion Tourism, we are passionate about making your travel dreams come true. With our
                        unwavering
                        commitment to excellence, personalized service, and comprehensive solutions, we strive to
                        provide you with
                        an exceptional travel experience. Let us be your trusted travel partner and embark on a journey
                        of
                        unforgettable memories with Devotion Tourism. Contact us today to start planning your next
                        adventure!
                    </p>

                </div>
            </div>
        </div>
        <!-- <p class="atdevotion2">
            At Devotion Tourism, we are passionate about making your travel dreams come true. With our unwavering
            commitment to excellence, personalized service, and comprehensive solutions, we strive to provide you with
            an exceptional travel experience. Let us be your trusted travel partner and embark on a journey of
            unforgettable memories with Devotion Tourism. Contact us today to start planning your next adventure!
        </p> -->
    </div>
</div>

<!-- WhatWeDo -->
<div class="WhatWeDo-section">
    <div class="container">
        <h2 class="WhatWeDo" data-aos="zoom-in" data-aos-duration="1200" data-aos-mirror="false" data-aos-once="true">
            What We Do?</h2>
        <div class="row">
            <div class="col-md-4">
                <h2 class="whatwedoh2">
                    Flight Booking
                </h2>
                <div class="content">
                    <a>
                        <div class="content-overlay"></div>
                        <img class="content-image" src="./../../../assets/images/testimonial/fly-11.png" alt="">

                        <div class="content-details fadeIn-bottom">
                            <!-- <h3 class="content-title section9hedssd">Flight
                                Booking</h3> -->
                            <p class="content-text">With our user-friendly online platform, you can easily book flights
                                to any destination around the world. Whether you're planning a family vacation, a
                                romantic getaway, or a business trip, our team of experienced travel experts will assist
                                you in finding the best flight options that suit your preferences and budget. We also
                                offer exclusive deals and discounts on flights to make your travel more affordable and
                                convenient.</p>
                            <br> <button routerLink="/contactus" class="round" mat-raised-button><i
                                    class="fas fa-arrow-right"></i></button>
                        </div>
                    </a>
                </div>

                <!-- <div class="whatwedose">
                    <h2 class="whatwedoh">
                        Flight Booking
                    </h2>
                    <p class="whatwedopa">
                        With our user-friendly online platform, you can easily book flights to any destination around
                        the world. Whether you're planning a family vacation, a romantic getaway, or a business trip,
                        our team of experienced travel experts will assist you in finding the best flight options that
                        suit your preferences and budget. We also offer exclusive deals and discounts on flights to make
                        your travel more affordable and convenient.
                        <button routerLink="/contactus" class="round" mat-raised-button><i
                                class="fas fa-arrow-right"></i></button>
                    </p>

                </div> -->
            </div>

            <div class="col-md-4">

                <h2 class="whatwedoh2">
                    Hotel Booking
                </h2>
                <div class="content">

                    <a>
                        <div class="content-overlay"></div>
                        <img class="content-image" src="./../../../assets/images/testimonial/fly-22.png" alt="">

                        <div class="content-details fadeIn-bottom">
                            <!-- <h3 class="content-title section9hedssd">Flight
                                Booking</h3> -->
                            <p class="content-text">Finding the perfect accommodation for your trip is essential, and we
                                strive to provide you with a wide range of options to choose from. Our extensive network
                                of partnerships with hotels and resorts worldwide allows us to offer competitive rates
                                and exclusive deals for various types of accommodations, including luxury hotels,
                                budget-friendly hotels, boutique hotels, and more.</p>
                            <br> <button routerLink="/contactus" class="round" mat-raised-button><i
                                    class="fas fa-arrow-right"></i></button>
                        </div>
                    </a>
                </div>

                <!-- <div class="whatwedose">
                    <h2 class="whatwedoh">
                        Flight Booking
                    </h2>
                    <p class="whatwedopa">
                        With our user-friendly online platform, you can easily book flights to any destination around
                        the world. Whether you're planning a family vacation, a romantic getaway, or a business trip,
                        our team of experienced travel experts will assist you in finding the best flight options that
                        suit your preferences and budget. We also offer exclusive deals and discounts on flights to make
                        your travel more affordable and convenient.
                        <button routerLink="/contactus" class="round" mat-raised-button><i
                                class="fas fa-arrow-right"></i></button>
                    </p>

                </div> -->
            </div>

            <div class="col-md-4">


                <div class="content">
                    <h2 class="whatwedoh2">
                        Corporate
                        Travel
                    </h2>
                    <a>
                        <div class="content-overlay"></div>
                        <img class="content-image" src="./../../../assets/images/testimonial/fly-33.png" alt="">

                        <div class="content-details fadeIn-bottom">
                            <!-- <h3 class="content-title section9hedssd">Flight
                                Booking</h3> -->
                            <p class="content-text">We understand that business travel requires meticulous planning and
                                coordination. Our dedicated corporate travel team is well-equipped to handle all your
                                business travel needs, from booking flights and hotels to managing complex itineraries
                                and arranging for transportation. We strive to provide you with comprehensive solutions
                                that cater to your specific business travel requirements, while ensuring
                                cost-effectiveness and efficiency.</p>
                            <br> <button routerLink="/contactus" class="round" mat-raised-button><i
                                    class="fas fa-arrow-right"></i></button>
                        </div>
                    </a>
                </div>

                <!-- <div class="whatwedose">
                    <h2 class="whatwedoh">
                        Flight Booking
                    </h2>
                    <p class="whatwedopa">
                        With our user-friendly online platform, you can easily book flights to any destination around
                        the world. Whether you're planning a family vacation, a romantic getaway, or a business trip,
                        our team of experienced travel experts will assist you in finding the best flight options that
                        suit your preferences and budget. We also offer exclusive deals and discounts on flights to make
                        your travel more affordable and convenient.
                        <button routerLink="/contactus" class="round" mat-raised-button><i
                                class="fas fa-arrow-right"></i></button>
                    </p>

                </div> -->
            </div>

            <!-- <div class="col-md-4">
                <div class="whatwedose whatwedose2">
                  

                        <h2 class="whatwedoh">
                            Hotel Booking
                        </h2>
                        <p class="whatwedopa">
                            Finding the perfect accommodation for your trip is essential, and we strive to provide you
                            with
                            a wide range of options to choose from. Our extensive network of partnerships with hotels
                            and
                            resorts worldwide allows us to offer competitive rates and exclusive deals for various types
                            of
                            accommodations, including luxury hotels, budget-friendly hotels, boutique hotels, and more.
                            <br> <button routerLink="/contactus" class="round" mat-raised-button><i
                                    class="fas fa-arrow-right"></i></button>
                        </p>
                 
                </div>
            </div>
            <div class="col-md-4">
                <div class="whatwedose whatwedose3">
                    <h2 class="whatwedoh">
                        Corporate Travel
                    </h2>
                    <p class="whatwedopa">
                        We understand that business travel requires meticulous planning and coordination. Our dedicated
                        corporate travel team is well-equipped to handle all your business travel needs, from booking
                        flights and hotels to managing complex itineraries and arranging for transportation. We strive
                        to provide you with comprehensive solutions that cater to your specific business travel
                        requirements, while ensuring cost-effectiveness and efficiency.
                        <br> <button routerLink="/contactus" class="round" mat-raised-button><i
                                class="fas fa-arrow-right"></i></button>
                    </p>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- WhyChooseUs -->
<div class="WhyChooseUs-section">
    <h2 class="whytxt" data-aos="zoom-in" data-aos-duration="1200" data-aos-mirror="false" data-aos-once="true">Why
        Choose Us?</h2>
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="whytxtsec">
                    <img class="whytxtim" src="./../../../assets/images/aboutus/Group29.png" alt="no-image">
                    <h2 class="whytxthea">Personalized Service</h2>
                    <p class="whytxtsubhea">We believe that every traveler is unique, and we go the extra mile to
                        understand your preferences
                        and requirements. Our experienced travel experts provide personalized service and attention to
                        detail, ensuring that your travel plans are tailored to your needs and expectations.
                    </p>
                </div>
            </div>
            <div class="col-md">
                <div class="whytxtsec">
                    <img class="whytxtim" src="./../../../assets/images/aboutus/Group25.png" alt="no-image">
                    <h2 class="whytxthea">Extensive Network</h2>
                    <p class="whytxtsubhea">With our extensive network of partnerships with airlines, hotels, and other
                        travel service providers, we offer a wide range of options to choose from. This allows us to
                        provide you with competitive rates, exclusive deals, and a diverse range of travel options to
                        suit your budget and preferences.
                    </p>
                </div>
            </div>
            <div class="col-md">
                <div class="whytxtsec">
                    <img class="whytxtim" src="./../../../assets/images/aboutus/Group28.png" alt="no-image">
                    <h2 class="whytxthea">Convenience</h2>
                    <p class="whytxtsubhea">Our user-friendly online platform and dedicated customer support make your
                        travel booking experience easy, convenient, and hassle-free. From flight bookings to hotel
                        reservations, we strive to provide you with seamless and efficient services that save you time
                        and effort.
                    </p>
                </div>
            </div>
            <div class="col-md">
                <div class="whytxtsec">
                    <img class="whytxtim" src="./../../../assets/images/aboutus/Group27.png" alt="no-image">
                    <h2 class="whytxthea">Trustworthy and Reliable</h2>
                    <p class="whytxtsubhea">Devotion Tourism is a reputable and reliable travel and tourism company with
                        a track record of providing exceptional services to our clients. We are committed to maintaining
                        the highest standards of professionalism, integrity, and customer satisfaction, and strive to
                        exceed your expectations with our services.
                    </p>
                </div>
            </div>
            <div class="col-md">
                <div class="whytxtsec">
                    <img class="whytxtim" src="./../../../assets/images/aboutus/Group26.png" alt="no-image">
                    <h2 class="whytxthea">Comprehensive Solutions</h2>
                    <p class="whytxtsubhea">Whether you're planning a leisure trip, a business trip, or a corporate
                        event, we offer comprehensive travel solutions that cater to your specific needs. Our team of
                        experts will work closely with you to understand your requirements and provide you with
                        customized solutions that meet your travel goals.s.
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>



<!-- <app-profile></app-profile> -->
<!-- <div  class="container">
    <app-testimonial></app-testimonial>
</div> -->
 

