import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxIntlTelInputComponent } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ChangeDetectorRef } from '@angular/core';
import { bookTicket, bookTicketReturn } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { ViewEncapsulation } from '@angular/compiler';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
declare var bootstrap: any;

@Component({
  selector: 'app-flight-review',
  templateUrl: './flight-review.component.html',
  styleUrls: ['./flight-review.component.css'],
})
export class FlightReviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('staticModal') staticModal: ModalDirective;
  @ViewChild(NgxIntlTelInputComponent) phoneInput: NgxIntlTelInputComponent;

  @ViewChild('closebuyyer') closebuyyer!: ElementRef;
  @ViewChild('fareUpdate') fareUpdate!: ElementRef;
  @ViewChild('fareUpdateReturn') fareUpdateReturn!: ElementRef;
  @ViewChild('closebuyyer22') closebuyyer22!: ElementRef;
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;
  @ViewChild('dialogFareContent', { static: true }) dialogFareContent: TemplateRef<any>;
  isMobileNumber: any

  selectedCurrency: any

  isCountryCode: any
  isGettingResponce: boolean = false
  isAddress: any
  finalPriceModifyied: any
  isEmailId: any
  isNationality: any
  IsBaggageChanged: boolean
  totalTax: number = 0
  fareRuleInfo: any = []
  fareRuleInfoReturn: any = []
  updatemodifiedDataTwo: any
  updatemodifiedDataReturn: any
  OtherCharges: number = 0
  ServiceFee: number = 0
  isAddressInvalid: boolean
  currencyType = ''
  secondPassingder: any = []
  passportIssueCode: string
  totalTax2: number = 0
  OtherCharges2: number = 0
  updatemodifiedData: any
  ServiceFee2: number = 0
  currencyType2 = ''
  finalPrice2: number = 0
  IsDocumentFullDetailRequiredAtBook: boolean
  IsDocumentFullInfoReq: boolean
  IsDocumentInfoReqOnHold: boolean
  IsDocumentInfoReqOnTicket: boolean
  public today = new Date();
  IsPassportFullDetailRequiredAtBook: boolean
  IsPassportFullInfoReq: boolean
  IsPassportInfoReqOnHold: boolean
  IsPassportInfoReqOnTicket: boolean


  isNeedPassportDetailsOneWay: boolean = false
  isNeedPassportDetailsTwoWay: boolean = false


  IsPriceChanged: boolean
  Pageinfos: any
  savePath: any
  trackingId: any
  returnTrackingId: any
  tokenId: any
  returnTokenId: any
  paramsData: any;
  form: FormGroup;
  isCOnfirm: boolean = false

  // isVerified:any
  nationality: string
  @ViewChild('AddPassinger') AddPassinger!: ElementRef;
  @ViewChild('openCalnder') openCalnder!: ElementRef;
  @ViewChild('openCalnder2') openCalnder2!: ElementRef;
  dynamicControlNames: string[] = [];
  isPageLoaded: boolean = false
  @ViewChild('travellerDetails', { read: ElementRef }) travellerDetails: ElementRef;
  selectedDate: any
  bookTicketObj = new bookTicket()
  bookTicketReturnObj = new bookTicketReturn()
  // sourceTo:any =[]
  mobileNumber: any
  fareRule: any = []
  fareRule2: any = []
  review = false;
  PageInfo: any = []
  modifiedData: any = []
  modifiedData2: any = []
  ticketDetails: any = []
  fareRuleINfo: any = []
  fareBreak: any = []
  fareBreakReturn: any = []
  finalPrice: number = 0
  dynamicForm: FormGroup
  currencyTupe: string = 'USD'
  myGender: number = 1
  // IsPassportFullInfoReq: boolean
  minDate = new Date()
  segmentDetails: any
  BsDatepickerConfig: Partial<BsDatepickerConfig>
  BsDatepickerConfig2: Partial<BsDatepickerConfig>
  constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService, private service: AuthService, public modalRef: BsModalRef, private datePipe: DatePipe,
    private config: NgSelectConfig, private location: Location, private cdr: ChangeDetectorRef, private dialog: MatDialog, private renderer: Renderer2, private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService) {
      this.selectedCurrency = localStorage.getItem('selectedCurrency')
      this.CheckCurrency()
    form: FormGroup;
    // this.form = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   email: ['', [Validators.required, Validators.email]],
    //   countryCode: ['', Validators.required]
    // });
    // this.config.notFoundText = 'City not found';
    // this.config.bindValue = 'value';
    this.BsDatepickerConfig = Object.assign({}, {
      containerClass: 'theme-green',
      showWeekNumbers: false,
      minDate: new Date(),
      dateInputFormat: 'DD/MM/YYYY'
    });
    this.BsDatepickerConfig2 = Object.assign({}, {
      containerClass: 'theme-green',
      showWeekNumbers: false,
      maxDate: new Date(),
      dateInputFormat: 'DD/MM/YYYY'
    });
  }
  ngOnDestroy(): void {
    this.ngxService.stop()
    localStorage.removeItem('second')
  }

  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  ngAfterViewInit() {
    // this.scrollToSection(this.travellerDetails.nativeElement);
    // const buttons = document?.querySelectorAll('.accordion-button');

    // buttons.forEach((button) => {
    //   button.addEventListener('click', () => {
    //     button.querySelector('.accordion-icon').classList.toggle('collapsed');
    //   });
    // });

    // const accordion = new bootstrap.Accordion(document?.getElementById('accordionFlushExample'));
  }
  gstNo: boolean = false
  // Mobile start
  isClicked = false;
  totalPassengerCount2: number
  userProfileList: any = []
  dataCass: string = 'my-filed2'
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates];
  phoneForm: FormGroup
  PhoneNumberFormat = PhoneNumberFormat;
  isReturn = localStorage.getItem('myWay')

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.UnitedArabEmirates];
  }

  myInfo() {

    this.isClicked = true;
    let phone = this.phoneForm.value.phone.nationalNumber
    let countryCode = this.phoneForm.value.phone.dialCode
    localStorage.setItem('homePhone', phone)
    localStorage.setItem('countryCode', countryCode)
  }


  // Mobile end

  // select Country start///
  cars = [
    { id: 'IN', name: 'INDIA' },
    { id: 'USA', name: 'AMERICA' },
    { id: 'DEL', name: 'Delhi' },
    { id: 'TIR', name: 'Tirupati' },
    { id: 'DXB', name: 'Dubai' },
    { id: 'SYD', name: 'Sydney' },
  ];
  // select Country end///
  ngOnInit(): void {
    // alert(this.isVerified)


    window.scrollTo(0, 0);
    // this.openDialog()
    this.isGettingResponce = true
    // this.ngxService.start()
    this.isPageLoaded = true
    this.loadScripts()

    this.route.paramMap.subscribe((data: any) => {

      this.savePath = data
      localStorage.setItem('savePath', data)
      this.paramsData = JSON.parse(data.params.data);


      if (this.paramsData.review) {
        this.review = this.paramsData.review;

        this.fareRule = this.paramsData.data.pnrResponseList[0].fareQuote;
        this.ticketDetails = this.paramsData.data.pnrResponseList[0].fareQuote;
        this.getFareData(this.fareRule);
        this.fareRules()
      } else {
        this.fareRule = this.paramsData
        this.ticketDetails = this.paramsData

        this.getFareData(this.fareRule);
        this.fareRules()
      }
      this.bookTicketObj.IPAddress = this.ticketDetails.ipaddress
      this.bookTicketObj.TokenId = this.ticketDetails.TokenId
      this.bookTicketObj.TrackingId = this.ticketDetails.TrackingId
      // this.bookTicketObj.EndUserBrowserAgent = this.ticketDetails.EndUserBrowserAgent
      this.bookTicketObj.PointOfSale = this.ticketDetails.PointOfSale
      this.bookTicketObj.RequestOrigin = this.ticketDetails.RequestOrigin
      this.bookTicketObj.UserData = this.ticketDetails.UserData
      this.bookTicketObj.ResultId = this.ticketDetails.ResultId
      this.bookTicketObj.PNR = ''

      this.form = this.formBuilder.group({
        fields: this.formBuilder.array([])
      });
      localStorage.setItem('savePath', JSON.stringify(this.ticketDetails))

    })

    this.phoneForm = new FormGroup({
      phone: new FormControl(undefined, [Validators.required])
    });

    this.dynamicForm = this.formBuilder.group({
      formFields: this.formBuilder.array([Validators.required]) // Use a FormArray to dynamically add/remove form controls
    });
    if (this.isReturn === "Way2") {
      this.getFareData2()
      this.fareRulesReturn()
    }
    // this.addForm();
    this.totalNoOfPasingers()
    this.getUserProfileList()

    // this.addFormField()
  }


  get fields() {

    return this.form.get('fields') as FormArray;

  }

  addForm(id) {
    const formGroup = this.formBuilder.group({
      Title: ['Mr', Validators.required],
      Type: [id, Validators.required],
      Type2: [{ value: id, disabled: true }],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      DateOfBirth: ['', Validators.required],
      Email: ['', Validators.required],
      Mobile1: ['', Validators.required],
      Contact: ['', Validators.required],
      Gender: ['1', Validators.required],
      PassportNo: ['', Validators.required],
      PassportDocumentReq: [this.IsPassportInfoReqOnTicket],
      Mobile1CountryCode: [''],
      Nationality: ['', Validators.required],
      AddressLine1: [`502-iris Bay,Opp.JW Marriott Hotel, Business Bay - Dubai -UA`, Validators.required],
      Country: [],
      IdDetails: [],
      City: [],
      Nationality1: ['', Validators.required],
      IssueDate: [''],
      ExpiryDate: [''],
      IdType: ['1'],
      IssuedCountryCode: [],
      PassportExpiry: ['', Validators.required]

    });
    this.fields.push(formGroup);

  }

  removeForm(index: number) {
    this.fields.removeAt(index);
  }

  national(e) {
    this.nationality = e.target.value
  }
  onDateChange(event: Date): void {

    // Perform additional logic or update component properties
  }
  issueDate(event: Date): void {

    // Perform additional logic or update component properties
  }

  onSubmit() {


    // if (this.form.invalid) {
    //   this.form.markAllAsTouched();
    //   return;
    // }


    for (let i = 0; i < this.form.value.fields.length; i++) {



      let number = this.form.value.fields[i]?.Contact?.number
      this.form.value.fields[i].Mobile1 = this.form.value.fields[i]?.Contact?.number
      this.form.value.fields[i].Mobile1CountryCode = this.form.value.fields[i]?.Contact?.dialCode





      // this.form.value.fields[i].Fare = this.PageInfo.Fare
      // this.form.value.fields[i].FareBreakdown = this.PageInfo.FareBreakdown
      if (this.form.value.fields[i].Type == 1) {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType1[0]
      }
      if (this.form.value.fields[i].Type == 2) {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType2[0]
      }
      if (this.form.value.fields[i].Type == 3) {
        this.form.value.fields[i].Fare = {}
        this.form.value.fields[i].Fare = this.modifiedData.PassengerType3[0]
      }

      this.form.value.fields[i].PassportExpiry = this.form.value.fields[i].PassportExpiry
      this.form.value.fields[i].ExpiryDate = this.form.value.fields[i].PassportExpiry

      let Nationality = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      let Country = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      let City = Object.assign(
        {
          CountryCode: this.form.value.fields[i].Nationality1,
          CountryName: this.form.value.fields[i].Nationality1
        },
      )
      const date = new Date(this.form.value.fields[i].IssueDate);
      const normalDate = date.toDateString();
      const date2 = new Date(this.form.value.fields[i].ExpiryDate);
      const normalDate2 = date2.toDateString();
      let IdDetails = Object.assign(
        [
          {
            IdType: this.form.value.fields[i]?.IdType,
            IdNumber: this.form.value.fields[i].PassportNo,
            IssuedCountryCode: this.form.value.fields[i].IssuedCountryCode,
            IssueDate: this.form.value.fields[i].IssueDate,
            ExpiryDate: this.form.value.fields[i].ExpiryDate,
          }
        ]
      )

      this.form.value.fields[i].Nationality = Nationality
      this.form.value.fields[i].Country = Country
      this.form.value.fields[i].City = City
      this.form.value.fields[i].IdDetails = IdDetails


      if (this.form.value.fields[i].Type == 1) {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType1];
      }
      if (this.form.value.fields.Type == 2) {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType2];
      }
      if (this.form.value.fields.Type == 3) {
        this.form.value.fields.FareBreakdown = [this.modifiedData.PassengerType3];
      }



      if (!this.form.value.fields[0]?.Mobile1) {
        this.service.error('Mobile number required')
        return
      }


    }



  }

  loadScripts() {

    let load: any = localStorage.getItem('load')
    setTimeout(() => {
      if (load === 'true') {
        document.location.reload()


      }
    }, 0)
    localStorage.setItem('load', 'false')


    const dynamicScripts = [
      '../../assets/js/timer.js',
      '../../assets/js/carousel.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  totalNoOfPasingers() {

    let totalPassengerCount = 0;

    if (this.fareRule.FareBreakdown) {
      for (const fare of this.fareRule.FareBreakdown) {
        totalPassengerCount += fare.PassengerCount;
      }
    }
    this.totalPassengerCount2 = totalPassengerCount
    // alert(totalPassengerCount)
  }

  getFareData(data: any) {

    this.bookTicketObj.fareQuote = data
    this.service.FareQuote(data).subscribe(
      (res: any) => {
        this.bookTicketObj.fareQuoteResponce = res.data
        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)

          if (res.data.Errors[0].UserMessage === 'The selected fare has expired. Please choose another fare. Reference –') {
            this.closebuyyer.nativeElement.click()
            // this.router.navigate(['/home'])
            // this.closeDialog()
            this.isGettingResponce = false
          }

        }
        else {
          // this.closeDialog()
          this.isGettingResponce = false
          this.IsBaggageChanged = res.data.IsBaggageChanged
          // this.getFlightIcon(res.data.Result[0].ValidatingAirline)
          this.IsDocumentFullDetailRequiredAtBook = res.data.IsDocumentFullDetailRequiredAtBook
          this.IsDocumentFullInfoReq = res.data.IsDocumentFullInfoReq
          this.IsDocumentFullInfoReq = res.data.IsDocumentFullInfoReq
          this.IsDocumentInfoReqOnHold = res.data.IsDocumentInfoReqOnHold
          this.IsDocumentInfoReqOnTicket = res.data.IsDocumentInfoReqOnTicket
          this.IsPassportFullDetailRequiredAtBook = res.data.IsPassportFullDetailRequiredAtBook
          this.IsPassportFullInfoReq = res.data.IsPassportFullInfoReq
          this.IsPassportInfoReqOnHold = res.data.IsPassportInfoReqOnHold
          this.IsPassportInfoReqOnTicket = res.data.IsPassportInfoReqOnTicket
          this.isNeedPassportDetailsOneWay = res.data.IsPassportInfoReqOnTicket

          this.IsPriceChanged = res.data.IsPriceChanged
          this.isPageLoaded = false

          this.PageInfo = res.data.Result[0]
          this.trackingId = res.data.TrackingId;
          this.tokenId = res.data.TokenId
          this.fareBreak = res.data.Result[0].FareBreakdown

          for (let i = 0; i < this.fareBreak.length; i++) {
            this.totalTax += this.fareBreak[i].Tax;
            this.OtherCharges += this.fareBreak[i].OtherCharges;
            this.ServiceFee += this.fareBreak[i].ServiceFee;
            this.currencyType = this.fareBreak[i].Currency
          }

          this.finalPrice = res.data.Result[0].Fare.TotalFare
          this.currencyTupe = res.data.Result[0].Fare.AgentPreferredCurrency
          this.segmentDetails = this.PageInfo.Segments[0]

          let totalPassengerCount = 0
          res.data.Result[0].FareBreakdown.forEach((value) => {

            if (value.PassengerType === 1) {
              for (let i = 0; i < value.PassengerCount; i++) {
                this.addForm(1);
              }
            }
            if (value.PassengerType === 2) {
              for (let i = 0; i < value.PassengerCount; i++) {
                this.addForm(2);
              }
            }
            if (value.PassengerType === 3) {
              for (let i = 0; i < value.PassengerCount; i++) {
                this.addForm(3);
              }
            }
            totalPassengerCount += value.PassengerCount




          })
          // alert(totalPassengerCount)
          this.fareDevideByPassinger()
          this.totalPassengerCount2 = totalPassengerCount
          for (let i = 0; i < totalPassengerCount; i++) {

            // this.addForm()
          }
          // this.fareRules()
          if (res.data.Result[0].IsLcc == true) {
            // alert("is LCC true -->  Ticket LCC")

          }
          if (res.data.Result[0].IsLcc == false) {

            // alert("is LCC false go to book & -> ticket method")
          }
        }
      },
      (err)=>{
        this.router.navigateByUrl('/home')
      }
    )
  }
  getFareData2() {
    let data = localStorage.getItem('second')
    if (this.review) {
      this.review = this.paramsData.review;
      this.fareRule2 = this.paramsData.data.pnrResponseList[1].fareQuote;
      this.bookTicketReturnObj.fareQuote = this.fareRule2
    } else {
      this.fareRule2 = JSON.parse(data)
      this.bookTicketReturnObj.fareQuote = this.fareRule2
    }
    this.service.FareQuote(this.fareRule2).subscribe(
      (res: any) => {
        this.bookTicketReturnObj.fareQuoteResponce = res.data
        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
          if (res.data.Errors[0].UserMessage === 'The selected fare has expired. Please choose another fare. Reference –') {
            this.router.navigate(['/VIewReturnData'])
          }
        }
        else {
          this.IsPassportFullInfoReq = res.data.IsPassportFullInfoReq
          this.IsPassportInfoReqOnTicket = res.data.IsPassportInfoReqOnTicket
          this.isNeedPassportDetailsTwoWay = res.data.IsPassportInfoReqOnTicket
          this.Pageinfos = res.data.Result[0]
          this.returnTrackingId = res.data.TrackingId;
          this.returnTokenId = res.data.TokenId
          this.fareDevideByPassinger2(res.data.Result[0])
          this.fareBreakReturn = res.data.Result[0].FareBreakdown
          for (let i = 0; i < this.fareBreakReturn.length; i++) {
            this.totalTax2 += this.fareBreakReturn[i].Tax;
            this.OtherCharges2 += this.fareBreakReturn[i].OtherCharges;
            this.ServiceFee2 += this.fareBreakReturn[i].ServiceFee;
            this.currencyType2 = this.fareBreakReturn[i].Currency

          }

          this.finalPrice2 = res.data.Result[0].Fare.TotalFare
        }
      }
    )
  }
  fareDevideByPassinger() {
    const modifiedData = {
      PassengerType1: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
      PassengerType2: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
      PassengerType3: this.divideTotalFareByPassengerCount(this.PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
    };

    this.modifiedData = modifiedData
  }

  fareDevideByPassinger2(PageInfo: any) {

    const modifiedData2 = {
      PassengerType1: this.divideTotalFareByPassengerCount2(PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
      PassengerType2: this.divideTotalFareByPassengerCount2(PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
      PassengerType3: this.divideTotalFareByPassengerCount2(PageInfo.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
    };

    this.modifiedData2 = modifiedData2
  }



  divideTotalFareByPassengerCount(passengerData) {
    return passengerData.map(passenger => {
      return {
        ...passenger,
        Currency: passenger.Currency,
        PassengerType: passenger.PassengerType,
        PassengerCount: passenger.PassengerCount,
        TotalFare: passenger.TotalFare / passenger.PassengerCount,
        OtherCharges: passenger.OtherCharges / passenger.PassengerCount,
        AgentMarkup: passenger.AgentMarkup / passenger.PassengerCount,
        ServiceFee: passenger.ServiceFee / passenger.PassengerCount,
        BaseFare: passenger.BaseFare / passenger.PassengerCount,
        Tax: passenger.Tax / passenger.PassengerCount,
        PenaltyAmount: passenger.PenaltyAmount / passenger.PassengerCount,
        CreditCardCharge: passenger.CreditCardCharge / passenger.PassengerCount,

      };
    });

  }
  divideTotalFareByPassengerCount2(passengerData) {
    return passengerData.map(passenger => {
      return {
        ...passenger,
        Currency: passenger.Currency,
        PassengerType: passenger.PassengerType,
        PassengerCount: passenger.PassengerCount,
        TotalFare: passenger.TotalFare / passenger.PassengerCount,
        OtherCharges: passenger.OtherCharges / passenger.PassengerCount,
        AgentMarkup: passenger.AgentMarkup / passenger.PassengerCount,
        ServiceFee: passenger.ServiceFee / passenger.PassengerCount,
        BaseFare: passenger.BaseFare / passenger.PassengerCount,
        Tax: passenger.Tax / passenger.PassengerCount,
        PenaltyAmount: passenger.PenaltyAmount / passenger.PassengerCount,
        CreditCardCharge: passenger.CreditCardCharge / passenger.PassengerCount,

      };
    });

  }



  fareRules() {
    this.service.FareRule(this.fareRule).subscribe(
      (res: any) => {


        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
        }
        else {
          this.fareRuleInfo = res.data.FareRules[0][0]
        }
      }
    )
  }

  fareRulesReturn() {
    let data = localStorage.getItem('second')
    let details = JSON.parse(data)
    this.service.FareRule(details).subscribe(
      (res: any) => {


        if (res.data.IsSuccess == false) {
          this.service.error(res.data.Errors[0].UserMessage)
        }
        else {
          this.fareRuleInfoReturn = res.data.FareRules[0][0]
        }
      }
    )
  }

  ihaveGst(number: boolean) {
    this.gstNo = number
  }


  get formFields(): FormArray {

    return this.dynamicForm?.get('formFields') as FormArray;
  }

  removeFormField(index: number) {
    this.formFields.removeAt(index);
  }

  validationFunction() {

    for (let i = 0; i < this.form.value.fields.length; i++) {
      this.form.value.fields[i].Mobile1 = this.form.value.fields[0]?.Contact?.number
      this.form.value.fields[i].Mobile1CountryCode = this.form.value.fields[0]?.Contact?.dialCode
      this.isMobileNumber = this.form.value.fields[0]?.Contact?.number
      this.isCountryCode = this.form.value.fields[0]?.Contact?.dialCode

      if (typeof this.form.value.fields[i].DateOfBirth === 'string') {
        const dateString = this.form.value.fields[i].DateOfBirth;

        // Split the date string into day, month, and year
        const dateParts = dateString.split("-");
        const day = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // Months are zero-indexed in JavaScript
        const year = parseInt(dateParts[2]);

        // Create a Date object using the parsed values
        const formattedDate = new Date(year, month, day);

        // Format the Date object to the desired string representation
        const formattedDateString = formattedDate.toDateString() + " " + formattedDate.toTimeString() + " GMT+0530";

        this.form.value.fields[i].DateOfBirth = formattedDateString
      }
      const birthdayConvert = this.datePipe.transform(this.form.value.fields[i].DateOfBirth, 'yyyy-MM-dd');
      this.form.value.fields[i].DateOfBirth = birthdayConvert

      const PassportExpiryConvert = this.datePipe.transform(this.form.value.fields[i]?.PassportExpiry, 'yyyy-MM-dd');
      this.form.value.fields[i].PassportExpiry = PassportExpiryConvert


      const issuDateConvertConvert = this.datePipe.transform(this.form.value.fields[i]?.IssueDate, 'yyyy-MM-dd');
      this.form.value.fields[i].IssueDate = issuDateConvertConvert


      if (!this.form.value.fields[i].Title) {
        this.service.error('Title is required')
        return
      }
      if (!this.form.value.fields[i]?.Type) {
        this.service.error('Type is required')
        return
      }

      if (!this.form.value.fields[i].FirstName) {
        this.service.error('FirstName is required')
        return
      }
      if (this.form.value.fields[i].FirstName) {
        var pattern = /^[a-zA-Z\s]{2,}$/; // Updated regex pattern
        if (pattern.test(this.form.value.fields[i].FirstName)) {

        } else {
          this.service.error('FirstName should be at least Two characters long and allowed only characters');
          return;
        }
      }
      if (!this.form.value.fields[i].LastName) {
        this.service.error('LastName is required')
        return
      }
      if (this.form.value.fields[i].LastName) {
        var pattern = /^[a-zA-Z\s]{2,}$/; // Updated regex pattern
        if (pattern.test(this.form.value.fields[i].LastName)) {

        } else {
          this.service.error('LastName should be at least Two characters long and allowed only characters and spaces');
          return;
        }
      }
      if (!this.form.value.fields[i]?.DateOfBirth) {
        this.service.error('DateOfBirth is required')
        return
      }
      if (!this.form.value.fields[0].Mobile1) {
        this.service.error('Mobile number is required')
        return
      }

      const mobileRegex = /^\d{7,15}$/;

      if (!mobileRegex.test(this.form.value.fields[0].Mobile1)) {
        this.service.error('Mobile number should be 7 to 15 digits');
        return;
      }

      // if(this.form.value.fields[0].Mobile1){
      //   this.form.value.fields[i].Mobile1  =this.isMobileNumber
      //   this.form.value.fields[i].Mobile1CountryCode = this.isCountryCode
      // }
      // if (this.form.value.fields[i].Mobile1) {
      //   var pattern = /^\d{10}$/;
      //   if (pattern.test(this.form.value.fields[i].Mobile1)) {

      //   } else {
      //     this.service.error('Invalid mobile number')
      //     return
      //   }

      // }

      if (!this.form.value.fields[0]?.Email) {
        this.service.error('Email is required')
        return
      }

      if (this.form.value.fields[i]?.Email) {
        var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (pattern.test(this.form.value.fields[0]?.Email)) {

        } else {
          this.service.error('Invalid email address')
          return

        }

      }

      if (!this.form.value.fields[0]?.AddressLine1) {
        this.service.error('Address is required')
        return
      }
      if (!this.form.value.fields[0]?.Nationality1) {
        this.service.error('Nationality is required')
        return
      }
      if (this.form.value.fields[0]?.AddressLine1) {
        this.form.value.fields[i].AddressLine1 = this.form.value.fields[0]?.AddressLine1
      }
      if (this.form.value.fields[0]?.Nationality1) {
        this.form.value.fields[i].Nationality1 = this.form.value.fields[0]?.Nationality1
      }
      if (this.form.value.fields[0]?.Email) {
        this.form.value.fields[i].Email = this.form.value.fields[0]?.Email
      }
      if (this.form.value.fields[0].Mobile1) {
        this.form.value.fields[i].Mobile1 = this.form.value.fields[0]?.Mobile1
      }




      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) &&
        (this.form.value.fields[i].IssuedCountryCode == null ||
          this.form.value.fields[i].IssuedCountryCode == undefined ||
          this.form.value.fields[i].IssuedCountryCode === '')) {
        this.service.error('Issued CountryCode is required');
        return;
      }


      if (!this.form.value.fields[i]?.IdType) {
        this.service.error('IdType is required')
        return
      }

      if (this.form.value.fields[i]?.IdType == 3 && this.form.value.fields[i]?.PassportNo.length !== 10) {

        this.service.error(' Iqama number should be 10 Digits, ')
        return
      }



      if (this.form.value.fields.length !== this.totalPassengerCount2) {
        this.service.error('Please fill All the passinger Details ')
        return
      }


      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && !this.form.value.fields[i]?.PassportExpiry) {
        this.service.error('Passport Expiry is required');
        return;
      }

      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && !this.form.value.fields[i]?.PassportNo) {
        this.service.error('Passport No  required')
        return;
      }
      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && !this.form.value.fields[i]?.PassportNo) {
        this.service.error('Passport No  required')
        return;
      }

      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && this.form.value.fields[i].PassportNo) {
        if (/^[a-zA-Z0-9]{6,15}$/.test(this.form.value.fields[i].PassportNo)) {

        } else {
          this.service.error('Passport number invalid')
          return
        }

      }
      // if (this.form.value.fields[i]?.PassportNo.length < 6 &&  this.form.value.fields[i]?.PassportNo.length > 15 ) {
      //   this.service.error('Passport number 6 to 15 characters')
      //   return
      // }


      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && this.form.value.fields[i]?.PassportNo) {
        var pattern = /^[a-zA-Z0-9]+$/;
        let data = pattern.test(this.form.value.fields[i].PassportNo);
        if (data == true) {

        }
        else {
          this.service.error('Passport number not allowed Special characters')
          return
        }

      }



      if (this.form.value.fields[i].Type == 1) {
        const today = new Date(); // Get the current date
        const birthDate = new Date(this.form.value.fields[i].DateOfBirth); // Convert the user's input to a Date object

        // Calculate the age
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If the current month is less than the birth month or they are the same month but the current day is earlier,
        // subtract 1 from the calculated age
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 12) {
          this.service.error('Adult age should be 12 years above')
          return
        }

      }

      if (this.form.value.fields[i].Type == 1) {
        const today = new Date(); // Get the current date
        const birthDate = new Date(this.form.value.fields[i].DateOfBirth); // Convert the user's input to a Date object

        // Calculate the age
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If the current month is less than the birth month or they are the same month but the current day is earlier,
        // subtract 1 from the calculated age
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age > 120) {
          this.service.error('Adult age should not be 150 years above')
          return
        }

      }


      if (this.form.value.fields[i].Type == 2) {
        const today = new Date(); // Get the current date
        const birthDate = new Date(this.form.value.fields[i].DateOfBirth); // Convert the user's input to a Date object

        // Calculate the age
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If the current month is less than the birth month or they are the same month but the current day is earlier,
        // subtract 1 from the calculated age
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age <= 2 || age >= 12) {
          this.service.error('Child age should be between 2 and 12 years')
          return
        }





      }

      if (this.form.value.fields[i].Type == 3) {
        const birthday = this.form.value.fields[i].DateOfBirth;

        // Get the current date
        const currentDate = new Date();

        // Convert the birthday string to a Date object
        const birthDate = new Date(birthday);

        // Calculate the difference in months
        let totalMonths = (currentDate.getFullYear() - birthDate.getFullYear()) * 12 + (currentDate.getMonth() - birthDate.getMonth());

        // Adjust for the day of the month
        if (currentDate.getDate() < birthDate.getDate()) {
          totalMonths--;
        }


        if (totalMonths > 23) {
          this.service.error('Infant age should be 2 years below')
          return

        }



      }



      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && this.form.value.fields[i]?.PassportExpiry) {
        var today = new Date();

        // Calculate the minimum allowed date (today + 6 months)
        var minDate = new Date();
        minDate.setMonth(today.getMonth() + 6);
        // Get the user's input date
        var userInput = new Date(this.form.value.fields[i]?.PassportExpiry); // Replace this with your actual user input

        // Compare the user input date with the minimum allowed date
        if (userInput < minDate) {
          this.service.error('Passport should expire after 6 months of travel date.')
          return

        }
      }





      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && !this.form.value.fields[i]?.IssueDate) {
        this.service.error('Passport Issued Date Required')
        return
      }


      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay)) {
        const issueDate = this.form.value.fields[i]?.IssueDate;
        const birthdayDate = this.form.value.fields[i]?.DateOfBirth;

        if (!issueDate) {
          this.service.error('Passport Issued Date Required');
          return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const parsedIssueDate = new Date(issueDate);
        const parsedBirthdayDate = new Date(birthdayDate);

        if (parsedIssueDate > today) {
          this.service.error('Passport Issued Date cannot be in the future and Past date');
          return;
        }

        if (parsedIssueDate < parsedBirthdayDate) {
          this.service.error('Passport Issued Date cannot be before Birthday date');
          return;
        }
      }



      if ((this.isNeedPassportDetailsOneWay || this.isNeedPassportDetailsTwoWay) && this.form.value.fields[i].IssueDate == 'Invalid date') {
        this.service.error('Passport Issued Country Invalid date')
        return
      }





      else {

        if (i === this.form.value.fields.length - 1) {
          // alert('done..!')
          window.scrollTo(0, 0);
          this.submit()
        }

      }






    }
  }



  async submit() {
    this.onSubmit();
    // this.ngxService.start();
    // this.openDialog();
    this.IsPassportInfoReqOnTicket;

    try {
      const data: any = await this.service.validationForTicket(this.form.value.fields).toPromise();

      let IsDomestic = localStorage.getItem('IsDomestic');
      let PreferredDepartureTime = localStorage.getItem('PreferredDepartureTime');

      for (let i = 0; i < this.form.value.fields.length; i++) {
        if (i === 0) {
          this.form.value.fields[i].IsLeadPax = true;
        } else {
          this.form.value.fields[i].IsLeadPax = false;
        }
      }

      this.bookTicketObj.Itinerary = {
        baseFare: this.finalPrice,
        Segments: this.PageInfo.Segments[0],
        FareRules: this.PageInfo.FareRules,
        TokenId: this.tokenId,
        TrackingId: this.trackingId,
        Passenger: this.form.value.fields,
        Destination: this.PageInfo.Destination,
        Origin: this.PageInfo.Origin,
        ValidatingAirlineCode: this.PageInfo.ValidatingAirline,
        NonRefundable: this.PageInfo.NonRefundable,
        IsLcc: this.PageInfo.IsLcc,
        AirlineRemark: this.PageInfo.AirlineRemark,
        TripIndicator: this.PageInfo.TripIndicator,
        ResponseTime: this.PageInfo.ResponseTime,
        JourneyType: this.PageInfo.JourneyType,
        CreatedOn: new Date(),
        LastTicketDate: this.PageInfo.LastTicketDate,
        TicketAdvisory: this.PageInfo.TicketAdvisory,
        ValidatingAirline: this.PageInfo.ValidatingAirline,
        Airline: this.PageInfo.ValidatingAirline,
        ProviderRemarks: null,
        SearchType: this.PageInfo.JourneyType,
        StaffRemarks: null,
        TravelDate: PreferredDepartureTime,
        AgentRefNo: null,
        IsDomestic: IsDomestic,
        // PointOfSale: this.ticketDetails.PointOfSale,
        // RequestOrigin: this.ticketDetails.RequestOrigin,
        EarnedLoyaltyPoints: null,
      };

      localStorage.setItem('isLCC', this.PageInfo.IsLcc);
      localStorage.setItem('information', JSON.stringify(this.bookTicketObj));
      let condition = localStorage.getItem("myWay");

      if (condition === "Way2") {
        this.returnSubmit();
      }
      this.checkIsPriceUpdated()

    } catch (error) {
      this.service.error(error.error.message);
    }
  }


  returnSubmit() {

    // Create a deep copy of the Passenger array
    this.secondPassingder = []

    let pAassenferCopy = this.bookTicketObj.Itinerary.Passenger.map((passenger) => ({ ...passenger }));
    pAassenferCopy.forEach((value) => {
      if (value.Type === 1) {
        value.Fare = {};
        value.Fare = this.modifiedData2?.PassengerType1[0];
      }
      if (value.Type === 2) {
        value.Fare = {};
        value.Fare = this.modifiedData2?.PassengerType2[0];
      }
      if (value.Type === 3) {
        value.Fare = {};
        value.Fare = this.modifiedData2?.PassengerType3[0];
      }

      this.secondPassingder.push(value)


    });


    for (let i = 0; i < this.secondPassingder.length; i++) {
      if (i === 0) {
        this.form.value.fields[i].IsLeadPax = true;
      } else {
        this.form.value.fields[i].IsLeadPax = false;
      }
    }
    let IsDomestic = localStorage.getItem('IsDomestic2')
    let PreferredDepartureTime = localStorage.getItem('PreferredDepartureTime')
    let basicInfo = JSON.parse(localStorage.getItem('second'))

    this.bookTicketReturnObj.IPAddress = basicInfo.ipaddress
    this.bookTicketReturnObj.TokenId = basicInfo.TokenId
    this.bookTicketReturnObj.TrackingId = basicInfo.TrackingId
    // this.bookTicketReturnObj.EndUserBrowserAgent = basicInfo.EndUserBrowserAgent
    // this.bookTicketReturnObj.PointOfSale = basicInfo.PointOfSale
    // this.bookTicketReturnObj.RequestOrigin = basicInfo.RequestOrigin
    this.bookTicketReturnObj.UserData = basicInfo.UserData
    this.bookTicketReturnObj.ResultId = basicInfo.ResultId
    this.bookTicketReturnObj.PNR = ''
    this.bookTicketReturnObj.Itinerary = {
      baseFare: this.finalPrice2,
      Segments: this.Pageinfos.Segments[0],
      FareRules: this.Pageinfos.FareRules,
      Passenger: this.secondPassingder,
      Destination: this.Pageinfos.Destination,
      Origin: this.Pageinfos.Origin,
      ValidatingAirlineCode: this.Pageinfos.ValidatingAirline,
      NonRefundable: this.Pageinfos.NonRefundable,
      IsLcc: this.Pageinfos.IsLcc,
      AirlineRemark: this.Pageinfos.AirlineRemark,
      TripIndicator: this.Pageinfos.TripIndicator,
      ResponseTime: this.Pageinfos.ResponseTime,
      JourneyType: this.Pageinfos.JourneyType,

      CreatedOn: new Date(),
      LastTicketDate: this.Pageinfos.LastTicketDate,
      TicketAdvisory: this.Pageinfos.TicketAdvisory,
      ValidatingAirline: this.Pageinfos.ValidatingAirline,
      Airline: this.Pageinfos.ValidatingAirline,
      ProviderRemarks: null,
      SearchType: this.Pageinfos.JourneyType,
      StaffRemarks: null,
      TravelDate: PreferredDepartureTime,
      AgentRefNo: null,
      IsDomestic: IsDomestic,
      // PointOfSale: basicInfo.PointOfSale,
      // RequestOrigin: basicInfo.RequestOrigin,
      EarnedLoyaltyPoints: null,

    }
    localStorage.setItem('information2', JSON.stringify(this.bookTicketReturnObj))
    // this.finalData()
  }


  async paymentAgree() {
    // this.ngxService.start()
    // this.openDialog()
    this.isGettingResponce = true
    window.scrollTo(0, 0);
    let paymentObj: any = {}
    paymentObj.bookTickets = [];


    paymentObj.bookTickets.push(this.bookTicketObj);
    if (this.isReturn === "Way2") {
      paymentObj.finalPayment = this.PageInfo?.Fare?.NewTotalFare + this.Pageinfos?.Fare?.NewTotalFare;
      paymentObj.bookTickets.push(this.bookTicketReturnObj);

    }
    else {
      paymentObj.finalPayment = this.PageInfo.Fare.NewTotalFare
   
    }
    paymentObj.paymentMode = await localStorage.getItem('selectedCurrency')
    this.service.getPay(paymentObj).subscribe(
      (data: any) => {
        // this.closeDialog()
        localStorage.setItem('RF', data.rf)
        window.location.href = data.message._links.payment.href

      },
      (err) => {
        this.service.error(err.error.error)
        this.isGettingResponce = false
        // this.closeDialog()
      }
    )

  }


  getFlightIcon(data) {
    this.service.getFlightIcon(data).subscribe(
      (responce: any) => {

      }
    )
  }




  scrollToSection(element: HTMLElement) {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  CheckValue() {

    for (let i = 0; i < this.dynamicForm.value.formFields.length; i++) {

      let number = this.dynamicForm.value.formFields[i].Mobile1.number
      let objStr = this.dynamicForm.value.formFields[i].Mobile1.toString()
      let users1 = Object.assign([
        this.dynamicForm.value.formFields[i].Mobile1CountryCode = this.dynamicForm.value.formFields[i].Mobile1.dialCode,

        this.dynamicForm.value.formFields[i].Mobile1 = number,

      ])

    }


  }


  mobileNumbers(event) {
    this.mobileNumber = event.target.value
  }

  addDynamicControl(type: string) {
    const controlName = type + this.dynamicControlNames.length;
    const control = new FormControl('', Validators.required);
    this.form.addControl(controlName, control);
    this.dynamicControlNames.push(controlName);
  }

  onIntlTelInputChange(event: any) {
    const countryData = event?.value;
    if (countryData && countryData.dialCode) {
      this.form.patchValue({ countryCode: countryData.dialCode }, { emitEvent: false });
    }
  }

  consle() {


  }

  selectGender(e) {
    this.myGender = e
  }

  previeCheck() {
    this.isCOnfirm = true;
    // this.onSubmit()
    window.scrollTo(0, 0);

    // this.submit()
  }

  isIsEdit() {
    this.isCOnfirm = false;

  }

  isIsConfirmed() {
    window.scrollTo(0, 0);
    this.isCOnfirm = false;
    this.submit()
  }

  handleVerification(isVerified) {
    // Handle the event logic here

    if (isVerified === 'true') {
      this.isCOnfirm = false
    }
  }

  openCalnders() {
    this.openCalnder.nativeElement.click();
  }
  openCalnders2() {
    this.openCalnder2.nativeElement.click();
  }


  convertToCustomFormat(timeString: string): string {
    const [hours, minutes] = timeString.split(':');
    const formattedTime = `${hours}h:${minutes}m`;
    return formattedTime;
  }
  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color'
    });
  }

  openFareBox(): void {
    this.dialog.open(this.dialogFareContent, {
      panelClass: 'bg-color'
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
  animationCreated(animationItem: AnimationItem): void {
  }
  options: AnimationOptions = {
    path: './../../../../assets/images/flight_load.json',
  };

  back(): void {
    this.location.back()
    this.closebuyyer22.nativeElement.click()
  }



  async finalData() {
    let trueObject = []
    let falseObject = []
    let finaData = [];
    finaData.push(this.bookTicketObj);
    if (this.bookTicketReturnObj?.IPAddress) {
      finaData.push(this.bookTicketReturnObj);
    }

    for (let index = 0; index < finaData.length; index++) {
      const value = finaData[index];

      if (value.Itinerary.IsLcc === true) {
        this.paymentAgree()
        // Do something for IsLcc true case if needed
      } else {
        const bookingData: any = await this.service.Booking_Book(value).toPromise();
        // Handle the result of the API call here if needed
        if (index === 0) {
          this.bookTicketObj.PNR = bookingData.data.PNR
        }
        if (index === 1) {
          this.bookTicketReturnObj.PNR = bookingData.data.PNR
        }
        if (bookingData.data.ChangeInItinerary === null && bookingData.data.Errors.length < 0) {
          let falseobjectData = { value: bookingData.data.ChangeInItinerary.IsPriceChanged, index: index }
          falseObject.push(falseobjectData)
        }
        if (bookingData.data.Errors.length > 0) {
          this.service.error(bookingData.data.Errors[0].UserMessage)
          this.closeDialog()
          return
        }
        if (bookingData.data.ChangeInItinerary?.IsPriceChanged === true) {
          if (index === 0) {
            // this.UpdatefareDevideByPassinger(bookingData.Result[0].FareBreakdown)
          }
          if (index === 1) {
          }
          let objectData = { value: bookingData.data.ChangeInItinerary.IsPriceChanged, index: index }
          trueObject.push(objectData)
        }

      }
    }

    if (falseObject.length == 0 && trueObject.length == 0) {
      this.paymentAgree()
    }
  }





  updateCode() {
    this.bookTicketObj.Itinerary?.Passenger.forEach((value) => {
      if (value.Type === 1) {
        value.Fare = {};
        value.Fare = this.updatemodifiedData?.PassengerType1[0];
      }
      if (value.Type === 2) {
        value.Fare = {};
        value.Fare = this.updatemodifiedData?.PassengerType2[0];
      }
      if (value.Type === 3) {
        value.Fare = {};
        value.Fare = this.updatemodifiedData?.PassengerType3[0];
      }

    });
  }


  // One way Fare Updated ///

  async checkIsPriceUpdated() {

    let condition = localStorage.getItem("myWay");
    this.isGettingResponce = true
    if (condition === "Way1") {
      if (this.bookTicketObj.Itinerary.IsLcc === true) {
        this.paymentAgree()
        return
      } else {
        // this.openDialog()
        // this.isGettingResponce = true
        const bookingData: any = await this.service.Booking_Book(this.bookTicketObj).toPromise();
        debugger
        this.bookTicketObj.PNR = bookingData.data.PNR
        if (bookingData.data.ChangeInItinerary === null && bookingData.data.Errors.length <= 0) {
          this.paymentAgree()
          return
        }
        if (bookingData.data.Errors.length > 0) {
          this.service.error(bookingData.data.Errors[0].UserMessage)
          this.isGettingResponce = false
          return
        }
        else {
          // this.closeDialog()
          this.isGettingResponce = false
          this.fareUpdate.nativeElement.click()
          this.bookTicketObj.isPriceChangedKey = bookingData?.data.ChangeInItinerary?.IsPriceChanged
          if (bookingData?.data.ChangeInItinerary?.IsPriceChanged == true) {
            this.bookTicketObj.isPriceChangeObject = bookingData?.data?.Result[0]
          }

        }
      }
    }
    else {
      this.checkIsPriceUpdatedReturn()
    }
  }

  agreeWithNewPrice() {

    if (this.bookTicketObj.isPriceChangedKey == true) {
      // let fareData = this.bookTicketObj.isPriceChangeObject
      // const modifiedData = {
      //   PassengerType1: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
      //   PassengerType2: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
      //   PassengerType3: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
      // };

      // this.updatemodifiedData = modifiedData
      // this.bookTicketObj.Itinerary?.Passenger.forEach((value) => {
      //   if (value.Type === 1) {
      //     value.Fare = {};
      //     value.Fare = this.updatemodifiedData?.PassengerType1[0];
      //   }
      //   if (value.Type === 2) {
      //     value.Fare = {};
      //     value.Fare = this.updatemodifiedData?.PassengerType2[0];
      //   }
      //   if (value.Type === 3) {
      //     value.Fare = {};
      //     value.Fare = this.updatemodifiedData?.PassengerType3[0];
      //   }

      // });
      this.paymentAgree()

    }
  }
  agreeWithNewPriceTwo() {


    // if (this.bookTicketObj.isPriceChangedKey == true) {
    //   let fareData = this.bookTicketObj.isPriceChangeObject
    //   const modifiedData = {
    //     PassengerType1: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
    //     PassengerType2: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
    //     PassengerType3: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
    //   };

    //   this.updatemodifiedData = modifiedData
    //   this.bookTicketObj.Itinerary?.Passenger.forEach((value) => {
    //     if (value.Type === 1) {
    //       value.Fare = {};
    //       value.Fare = this.updatemodifiedData?.PassengerType1[0];
    //     }
    //     if (value.Type === 2) {
    //       value.Fare = {};
    //       value.Fare = this.updatemodifiedData?.PassengerType2[0];
    //     }
    //     if (value.Type === 3) {
    //       value.Fare = {};
    //       value.Fare = this.updatemodifiedData?.PassengerType3[0];
    //     }

    //   });


    // }
  }

  agreeWithNewPriceReturn() {

    if (this.bookTicketReturnObj.isPriceChangedKey == true) {
      // let fareData = this.bookTicketReturnObj.isPriceChangeObject
      // const modifiedData2 = {
      //   PassengerType1: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 1)),
      //   PassengerType2: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 2)),
      //   PassengerType3: this.updatedivideTotalFareByPassengerCount(fareData.FareBreakdown.filter(passenger => passenger.PassengerType === 3))
      // };

      // this.updatemodifiedDataTwo = modifiedData2
      // this.bookTicketReturnObj.Itinerary?.Passenger.forEach((value) => {
      //   if (value.Type === 1) {
      //     value.Fare = {};
      //     value.Fare =this.updatemodifiedDataTwo?.PassengerType1[0];
      //   }
      //   if (value.Type === 2) {
      //     value.Fare = {};
      //     value.Fare = this.updatemodifiedDataTwo?.PassengerType2[0];
      //   }
      //   if (value.Type === 3) {
      //     value.Fare = {};
      //     value.Fare = this.updatemodifiedDataTwo?.PassengerType3[0];
      //   }

      // });
      this.paymentAgree()

    }
  }

  updatedivideTotalFareByPassengerCount(passengerData) {
    return passengerData.map(passenger => {
      return {
        ...passenger,
        Currency: passenger.Currency,
        PassengerType: passenger.PassengerType,
        PassengerCount: passenger.PassengerCount,
        TotalFare: passenger.TotalFare / passenger.PassengerCount,
        OtherCharges: passenger.OtherCharges / passenger.PassengerCount,
        AgentMarkup: passenger.AgentMarkup / passenger.PassengerCount,
        ServiceFee: passenger.ServiceFee / passenger.PassengerCount,
        BaseFare: passenger.BaseFare / passenger.PassengerCount,
        Tax: passenger.Tax / passenger.PassengerCount,
        PenaltyAmount: passenger.PenaltyAmount / passenger.PassengerCount,
        CreditCardCharge: passenger.CreditCardCharge / passenger.PassengerCount,

      };

    });

  }

  // Two way Fare Updated ///


  async checkIsPriceUpdatedReturn() {
    let finaData = [];
    finaData.push(this.bookTicketObj);
    if (this.bookTicketReturnObj?.IPAddress) {
      finaData.push(this.bookTicketReturnObj);
    }

    let isLccAllTrue = false; // Flag to check if both objects have IsLcc as false
    let isPriceChangedAllFalse = true; // Flag to check if both objects have isPriceChangedKey as false

    for (let i = 0; i < finaData.length; i++) {
      if (finaData[i].Itinerary.IsLcc === true) {
        isLccAllTrue = true; // At least one object has IsLcc as true
      } else {
        // this.openDialog()
        const bookingData: any = await this.service.Booking_Book(finaData[i]).toPromise();
        finaData[i].PNR = bookingData.data.PNR;
        finaData[i].isPriceChangedKey = bookingData?.data.ChangeInItinerary?.IsPriceChanged;

        if (bookingData?.data.ChangeInItinerary?.IsPriceChanged === true) {
          finaData[i].isPriceChangeObject = bookingData?.data?.Result[0];
        }



        if (bookingData.data.ChangeInItinerary === null && bookingData.data.Errors.length <= 0) {
          isPriceChangedAllFalse = false; // At least one object has isPriceChangedKey as true
        } else if (bookingData.data.Errors.length > 0) {
          this.service.error(bookingData.data.Errors[0]?.UserMessage);
          this.closeDialog()
          return;
        } else {
          // this.fareUpdate.nativeElement.click()
        }
      }
    }

    if (isLccAllTrue) {
      this.paymentAgree();
    } else if (isPriceChangedAllFalse) {
      alert('priceChanged')
      this.closeDialog()
      this.fareUpdateReturn.nativeElement.click()
    } else {
      this.paymentAgree();

    }
  }


  getUserProfileList() {
    this.service.getUserProfileList().subscribe(
      (data: any) => {

        this.userProfileList = data
      }
    )
  }



  updateUserDetails(index: number, item: any) {
    debugger
    let genName = ''
    let code = ''
    if (item && item.mobileCode) {
      code = item.mobileCode.toLowerCase();
      // Rest of the code
    }
    const newPreferredCountries = [code];
    // Update the preferred countries
    this.phoneInput.preferredCountries = newPreferredCountries;

    // Manually trigger the update in the component
    this.phoneInput.ngOnInit();
    if (this.form.value.fields[index]?.Type == item.Type) {
      this.form.controls.fields['controls'][index].patchValue({
        FirstName: item.FirstName,
        LastName: item.LastName,
        Email: item.Email,
        // DateOfBirth: this.datePipe.transform(item.DateOfBirth, 'dd-MM-yyyy'),
        Title: item.Title,
        Gender: item.Gender,
        // Nationality1: item.Nationality1,
        Contact: item.mobileNumber,
      });


    }
    else {
      if (item.Type === '1') {
        genName = 'Adult'
      }
      if (item.Type === '2') {
        genName = 'Children'
      }
      if (item.Type === '3') {
        genName = 'Infant'
      }
      this.service.error(`Passinger type not matched,${item.FirstName} is ${genName}`)
    }

  }

}






