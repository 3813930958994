<div class="faeQuote2">



    <div class="container">
        <div class="row">
            <div class="col-md-9">
                flight details

                <button (click)="check()" class="btn btn-danger">Check</button>
                <button (click)="addFormField()" class="btn btn-success">Add Passinger</button>

           <div class="row justify-content-center">
            <div class="col-md-4">
                <form  [formGroup]="dynamicForm" >
                    <div formArrayName="formFields">
                        <div *ngFor="let field of formFields.controls; let i = index" class="contacItem"[formGroupName]="i">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Type</label>
                                <select formControlName="Type" class="form-select" aria-label="Default select example">
                                    <option selected value="0">NotSet  </option>
                                    <option  value=1>Adult </option>
                                    <option value=2>Child</option>
                                    <option value=3>Infant</option>
                                    <option value=4>Senior</option>
                                    <option value=5>Youth</option>
                                    
                                  </select>
                            </div>
                            <label for="exampleInputEmail1" class="form-label">Mr or Ms</label>
                            <select formControlName="Title" class="form-select" aria-label="Default select example">
                                <option selected value="Mr">Mr </option>
                                <option value="Miss">Miss</option>
                                <option value="Mrs">Mrs</option>
                              </select>
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label">First name</label>
                              <input  formControlName="FirstName" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Last name</label>
                                <input formControlName="LastName"  type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                            </div>
                             <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">email</label>
                                <input formControlName="Email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Mobile Number</label>
                                <input  formControlName="Mobile1" type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Mobile code</label>
                                <input  formControlName="Mobile1CountryCode" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">DateOfBirth	</label>
                                <input formControlName="DateOfBirth" type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Passport No	</label>
                                <input formControlName="PassportNo" type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Country Code	</label>
                                <input formControlName="CountryCode" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">

            
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Country Name	</label>
                                <input formControlName="CountryName" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                             <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">AddressLine1	</label>
                                <textarea formControlName="AddressLine1" type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"></textarea>
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Gender</label>
                                <select formControlName="Gender" class="form-select" aria-label="Default select example">
                                    <option selected value="0">NotSet  </option>
                                    <option  value="1">Male </option>
                                    <option value="2">Female</option>
                                  </select>
                            </div>
                         
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Details type</label>
                                <select formControlName="IdType" class="form-select" aria-label="Default select example">
                                    <option selected value=0>NotSet  </option>
                                    <option  value=1>Passport </option>
                                    <option value=2>aadhar</option>
                                  </select>
                            </div>
                            
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Id Number	</label>
                                <input formControlName="IdNumber" type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">IssuedCountryCode	</label>
                                <input formControlName="IssuedCountryCode" type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>

                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">IssueDate	</label>
                                <input formControlName="IssueDate" type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>

                              
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">ExpiryDate	</label>
                                <input formControlName="ExpiryDate" type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>
                              <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Passport Expiry	</label>
                                <input formControlName="PassportExpiry" type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                              </div>

                              <!-- <input type="hidden" formControlName="meal"> -->
                              
                          
                    
                            <button type="submit" (click)="submit()" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                  </form>
          
            </div>
           </div>

               

            </div>
      
        </div>
    </div>
</div>



