import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.css']
})
export class CarsComponent implements OnInit{
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadScripts()
  }
  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }
}
