<div class="login-component">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div *ngIf="!requestForOtp" class="loginsection">

                    <p class="textsign">Sign in or create an account</p>
                    <form [formGroup]="loginform" (ngSubmit)="onSubmit()">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label conta-text">Contact number</label>
                          <ngx-intl-tel-input [enablePlaceholder]="true" 
                          customPlaceholder="Mobile Number" formControlName="mobile"
                                              [ngClass]="{ 'is-invalid': loginform.controls.mobile.invalid && loginform.controls.mobile.touched }"
                                              [preferredCountries]="['in', 'gb']"></ngx-intl-tel-input>
                          <div class="invalid-feedback"
                               *ngIf="loginform.controls.mobile.invalid && loginform.controls.mobile.touched">
                            Mobile number is required.
                          </div>
                        </div>
                      
                        <button type="submit" class="btlog">Continue</button>
                      </form>
                 
                     <!-- <div class="option-data">
                        <hr class="line-data">
                        <p class="para-data"><span class="option-paras">or use one of these options</span></p>
                     </div>

                     <div class="social-info">
                        <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                        <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                     </div> -->

                     <p class="para-content">By signing in or creating an account, you agree with our <span class="terms">Terms & Conditions</span> and <span class="terms">Privacy Statement</span></p>

                </div>
                <div *ngIf="requestForOtp" class="loginsection">

                    <p class="textsign">Enter OTP</p>
                    <form [formGroup]="loginOtp" (ngSubmit)="submit()">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label conta-text">We sent OTP to your contact number.</label>
                          <div class="mb-3">
                            <input  placeholder="Enter OTP"
                            [ngClass]="{ 'is-invalid': loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched }"
                            type="email" formControlName="otp" class="form-control otpFOrm" id="exampleInputEmail1" aria-describedby="emailHelp">
                            <div class="invalid-feedback"
                            *ngIf="loginOtp.controls.otp.invalid && loginOtp.controls.otp.touched">
                         OTP is required.
                       </div>
                          </div>
                          <div class="invalid-feedback"
                               *ngIf="loginform.controls.mobile.invalid && loginform.controls.mobile.touched">
                            Mobile number is required.
                          </div>
                        </div>
                      
                        <button type="submit" class="btlog">Continue</button>
                      </form>
                 
                     <!-- <div class="option-data">
                        <hr class="line-data">
                        <p class="para-data"><span class="option-paras">or use one of these options</span></p>
                     </div>

                     <div class="social-info">
                        <img class="googleicon" src="./../../../assets/images/google.svg" alt="">
                        <img class="googleicon" src="./../../../assets/images/fbIcon.svg" alt="">
                     </div> -->

                     <p class="para-content">By signing in or creating an account, you agree with our <span class="terms">Terms & Conditions</span> and <span class="terms">Privacy Statement</span></p>

                </div>
            </div>
        </div>

       
    </div>
</div>



