<!-- ---  Pills Heading Start  --- -->
<div class="booking-trips">
    <div class="container">
        <!-- ---  Pills Start  --- -->
        <div class="booking-under-trips d-flex">
            <div *ngIf="isFlightSection" class="nav booking-trips-inside nav-pills me-3" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                <button class="nav-link booking-trips-heading active" id="v-pills-upcoming-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-upcoming" type="button" role="tab" aria-controls="v-pills-upcoming"
                    aria-selected="true"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png"
                        alt="no-data">Upcoming</button>
                <!-- <button class="nav-link booking-trips-heading" id="v-pills-cancelled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cancelled" type="button" role="tab" aria-controls="v-pills-cancelled" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg" alt="no-data">Cancelled Trips</button>
                <button class="nav-link booking-trips-heading" id="v-pills-completed-tab" data-bs-toggle="pill" data-bs-target="#v-pills-completed" type="button" role="tab" aria-controls="v-pills-completed" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png" alt="no-data">Completed Trips</button> -->
                <button class="nav-link booking-trips-heading" id="v-pills-failure-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-failure" type="button" role="tab" aria-controls="v-pills-failure"
                    aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg"
                        alt="no-data">Failed</button>
            </div>


            <div *ngIf="isPackagesSection" class="nav booking-trips-inside nav-pills me-3" id="v-pills-tab"
                role="tablist" aria-orientation="vertical">
                <button class="nav-link booking-trips-heading active" id="v-pills-upcoming-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-upcoming" type="button" role="tab" aria-controls="v-pills-upcoming"
                    aria-selected="true"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png"
                        alt="no-data">Upcoming</button>
                <!-- <button class="nav-link booking-trips-heading" id="v-pills-cancelled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cancelled" type="button" role="tab" aria-controls="v-pills-cancelled" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg" alt="no-data">Cancelled Trips</button>
            <button class="nav-link booking-trips-heading" id="v-pills-completed-tab" data-bs-toggle="pill" data-bs-target="#v-pills-completed" type="button" role="tab" aria-controls="v-pills-completed" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png" alt="no-data">Completed Trips</button> -->
                <button class="nav-link booking-trips-heading" id="v-pills-failure-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-failure" type="button" role="tab" aria-controls="v-pills-failure"
                    aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg"
                        alt="no-data">Failed</button>
                <button class="nav-link booking-trips-heading" id="v-pills-completed-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-completed" type="button" role="tab" aria-controls="v-pills-completed"
                    aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg"
                        alt="no-data">Completed</button>
            </div>



            <div *ngIf="isVisaSection" class="nav booking-trips-inside nav-pills me-3" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                <button class="nav-link booking-trips-heading active" id="v-pills-upcoming-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-upcoming" type="button" role="tab" aria-controls="v-pills-upcoming"
                    aria-selected="true"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png"
                        alt="no-data">Upcoming</button>
                <!-- <button class="nav-link booking-trips-heading" id="v-pills-cancelled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cancelled" type="button" role="tab" aria-controls="v-pills-cancelled" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg" alt="no-data">Cancelled Trips</button>
        <button class="nav-link booking-trips-heading" id="v-pills-completed-tab" data-bs-toggle="pill" data-bs-target="#v-pills-completed" type="button" role="tab" aria-controls="v-pills-completed" aria-selected="false"><img class="trip-icons" src="./../../../assets/images/bookings/flight.png" alt="no-data">Completed Trips</button> -->
                <button class="nav-link booking-trips-heading" id="v-pills-failure-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-failure" type="button" role="tab" aria-controls="v-pills-failure"
                    aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg"
                        alt="no-data">Failed</button>
                <button class="nav-link booking-trips-heading" id="v-pills-complete-tab" data-bs-toggle="pill"
                    data-bs-target="#v-pills-complete" type="button" role="tab" aria-controls="v-pills-complete"
                    aria-selected="false"><img class="trip-icons" src="./../../../assets/images/cancelFlight.svg"
                        alt="no-data">Completed</button>
            </div>


            <!-- <div class="select-menu">
                <div class="select-btn">
                    <span class="sBtn-text">All</span>
                    <i class="bx bx-chevron-down"></i>
                </div>
                <ul class="options">
                    <li class="option">
                        <img class="stay-img" src="./../../../assets./../../../assets/images/bookings/bookings/booking-5.png" alt="no-data">
                        <p class="option-text">Stays</p>
                    </li>
                    <li class="option">
                        <img class="stay-img" src="./../../../assets/images/bookings/booking-2.png" alt="no-data">
                        <p class="option-text">Flights</p>
                    </li>
                    <li class="option">
                        <img class="stay-img" src="./../../../assets/images/bookings/booking-4.png" alt="no-data">
                        <p class="option-text">Packages</p>
                    </li>
                    <li class="option">
                        <img class="stay-img" src="./../../../assets/images/bookings/booking-6.png" alt="no-data">
                        <p class="option-text">Cars</p>
                    </li>
                    <li class="option">
                        <img class="stay-img" src="./../../../assets/images/bookings/booking-7.png" alt="no-data">
                        <p class="option-text">Cruises</p>
                    </li>
                    <li class="option">
                        <img class="stay-img" src="./../../../assets/images/bookings/booking-8.png" alt="no-data">
                        <p class="option-text">Things to do</p>
                    </li>
                </ul>
            </div> -->

            <select (change)="selectTab($event)" class="form-select select-menu select-menuss"
                aria-label="Default select example">
                <option class="option" selected value="Flight">Flights</option>
                <option class="option" value="Package">Packages</option>
                <option class="option" value="Visa">Visa</option>
            </select>

        </div>
        <!-- ---  Pills End  --- -->
    </div>
</div>
<!-- ---  Pills Heading End  --- -->

<div *ngIf="isFlightSection" class="container">
    <!-- ---  Pills Content Start  --- -->
    <div class="tab-content" id="v-pills-tabContent">
        <!-- ---  Pill 1 Start  --- -->
        <div class="tab-pane fade show active" id="v-pills-upcoming" role="tabpanel"
            aria-labelledby="v-pills-upcoming-tab">
            <!-- ---  Pill 1 Content Start  --- -->
            <!-- ---  Top Content Start  --- -->
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myFlightBooking.length <= 0">No
                Bookings</p>
            <div>
                <div *ngFor="let data of myFlightBooking">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/bookings/book-1.png"
                                alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/bookings/book-1.png" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{data?.result?.Itinerary?.Origin}} <img class="arrow-img"
                                        src="./../../../assets/images/bookings/right-arrow.png"
                                        alt="no-data">{{data?.result?.Itinerary?.Destination}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: #AB823E;">Check Status</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">PNR :
                                    {{data?.result?.Itinerary?.PNR}}<img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data"><span
                                        class="way-last-para">Booking ID - {{data?.result?.Itinerary?.BookingId}}</span>
                                </p>
                                <p class="way-to-content">Created Date : {{data?.result?.Itinerary?.CreatedOn |
                                    date:'mediumDate'}} {{data?.result?.Itinerary?.CreatedOn | date:'shortTime'}}</p>

                            </li>
                            <li>
                                <button class="view-info" type="button" (click)="getStatus(data)">VIEW BOOKING</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
                    <div *ngFor="let segments of data?.result?.Itinerary?.Segments ;let i = index"
                        class="upcoming-trips-bottom">
                        <div class="upcoming-bottom-left">
                            <div class="upcoming-left-inside">
                                <p class="july-content">From</p>
                                <p class="july-date-content">{{segments?.DepartureTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Origin?.CityName}} </p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-content">To</p>
                                <p class="july-date-content">{{segments?.ArrivalTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Destination?.CityName}}</p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-date-content air-asia-con"><img class="booking-icons"
                                        src="./../../../assets/images/bookings/booking-2.png"
                                        alt="no-data">{{segments?.AirlineName}} {{segments?.Airline}}
                                    {{segments?.FlightNumber}}</p>
                                <p *ngFor="let passin of data.Itinerary?.Passenger" class="july-place-content"><img
                                        class="booking-icons" src="./../../../assets/images/bookings/booking-1.png"
                                        alt="no-data">{{passin?.FirstName}}</p>
                            </div>
                        </div>
                        <!-- <div *ngIf="i === data.Itinerary?.Segments.length - 1"  style="cursor: pointer;" (click)="generatePDF(data)" class="upcoming-bottom-right">
                    <img class="download-img" src="./../../../assets/images/bookings/download.png" alt="">
                    <a class="download-ticket">Download Ticket</a>
                </div> -->
                    </div>
                </div>
            </div>
            <!-- ---  Top Content End  --- -->

            <!-- ---  Bottom Content End  --- -->

            <!-- ---  Pill 1 Content End  --- -->
        </div>
        <!-- ---  Pill 1 End  --- -->

        <div class="tab-pane fade " id="v-pills-cancelled" role="tabpanel" aria-labelledby="v-pills-cancelled-tab">
            <!-- ---  Pill 1 Content Start  --- -->
            <!-- ---  Top Content Start  --- -->

            <!-- ---  Top Content End  --- -->

            <!-- ---  Bottom Content End  --- -->

            <!-- ---  Pill 1 Content End  --- -->
        </div>

        <div class="tab-pane fade " id="v-pills-completed" role="tabpanel" aria-labelledby="v-pills-completed-tab">
            <!-- ---  Pill 1 Content Start  --- -->
            <!-- ---  Top Content Start  --- -->
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myFlightBooking.length <= 0">No
                Completed </p>
            <div *ngIf="myFlightBooking.length > 0">
                <div *ngFor="let data of myFlightBooking">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/bookings/book-1.png"
                                alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/bookings/book-1.png" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{data?.Itinerary?.Origin}} <img class="arrow-img"
                                        src="./../../../assets/images/bookings/right-arrow.png"
                                        alt="no-data">{{data?.Itinerary?.Destination}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: #AB823E;">Check Status</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">PNR :
                                    {{data?.Itinerary?.PNR}}<img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data"><span
                                        class="way-last-para">Booking ID - {{data?.Itinerary?.BookingId}}</span></p>
                            </li>
                            <li>
                                <button class="view-info" type="button" (click)="getStatus(data)">VIEW BOOKING</button>
                            </li>
                        </ul>
                    </div>
                    <div *ngFor="let segments of data?.Itinerary?.Segments ;let i = index"
                        class="upcoming-trips-bottom">
                        <div class="upcoming-bottom-left">
                            <div class="upcoming-left-inside">
                                <p class="july-content">From</p>
                                <p class="july-date-content">{{segments?.DepartureTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Origin?.CityName}} </p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-content">To</p>
                                <p class="july-date-content">{{segments?.ArrivalTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Destination?.CityName}}</p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-date-content air-asia-con"><img class="booking-icons"
                                        src="./../../../assets/images/bookings/booking-2.png"
                                        alt="no-data">{{segments?.AirlineName}} {{segments?.Airline}}
                                    {{segments?.FlightNumber}}</p>
                                <p *ngFor="let passin of data.Itinerary?.Passenger" class="july-place-content"><img
                                        class="booking-icons" src="./../../../assets/images/bookings/booking-1.png"
                                        alt="no-data">{{passin?.FirstName}}</p>
                            </div>
                        </div>
                        <!-- <div *ngIf="i === data.Itinerary?.Segments.length - 1"  style="cursor: pointer;" (click)="generatePDF(data)" class="upcoming-bottom-right">
                    <img class="download-img" src="./../../../assets/images/bookings/download.png" alt="">
                    <a class="download-ticket">Download Ticket</a>
                </div> -->
                    </div>
                </div>
            </div>
            <!-- ---  Top Content End  --- -->

            <!-- ---  Bottom Content End  --- -->

            <!-- ---  Pill 1 Content End  --- -->
        </div>

        <div class="tab-pane fade " id="v-pills-failure" role="tabpanel" aria-labelledby="v-pills-failure-tab">
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myFailureFlightBooking.length <= 0">No
                Failure bookings</p>

            <div *ngFor="let data of myFailureFlightBooking">
                <div>
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/bookings/book-1.png"
                                alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/bookings/book-1.png" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{data?.Itinerary?.Itinerary?.Origin}} <img class="arrow-img"
                                        src="./../../../assets/images/bookings/right-arrow.png"
                                        alt="no-data">{{data?.Itinerary?.Itinerary?.Destination}}</p>

                                <p class="way-to-content"><a
                                        style="cursor: pointer; color: #AB823E; padding-right: 6px;">Check Error
                                        Status</a>
                                    <span
                                        *ngIf="!data.paymentDetails?._embedded?.payment[0]?.authResponse?.resultMessage"
                                        style="padding-right: 4px ; color: red;" class="way-last-para">Payment not
                                        done</span>
                                    <span
                                        *ngIf="data.paymentDetails?._embedded?.payment[0]?.authResponse?.resultMessage && data?.state !== 'CAPTURED'"
                                        style="padding-right: 4px ; color: red;"
                                        class="way-last-para">{{data.paymentDetails?._embedded?.payment[0]?.authResponse?.resultMessage}}</span>
                                    <span *ngIf="data.result?.Errors[0]?.UserMessage"
                                        style="padding-right: 4px ; color: red;"
                                        class="way-last-para">{{data.result?.Errors[0]?.UserMessage}}</span>
                                </p>
                                <p class="way-to-content">Created Date : {{data?.Itinerary?.Itinerary?.CreatedOn |
                                    date:'fullDate'}} {{data?.Itinerary?.Itinerary?.CreatedOn | date:'shortTime'}}</p>

                            </li>
                            <li>
                                <button class="view-info" type="button">Support</button>
                            </li>
                        </ul>
                    </div>
                    <div *ngFor="let segments of data?.Itinerary?.Itinerary?.Segments ;let i = index"
                        class="upcoming-trips-bottom">
                        <div class="upcoming-bottom-left">
                            <div class="upcoming-left-inside">
                                <p class="july-content">From</p>
                                <p class="july-date-content">{{segments?.DepartureTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Origin?.CityName}} </p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-content">To</p>
                                <p class="july-date-content">{{segments?.ArrivalTime |date:'mediumDate'}}</p>
                                <p class="july-place-content">{{segments?.Destination?.CityName}}</p>
                            </div>

                            <div class="upcoming-left-inside">
                                <p class="july-date-content air-asia-con"><img class="booking-icons"
                                        src="./../../../assets/images/bookings/booking-2.png"
                                        alt="no-data">{{segments?.AirlineName}} {{segments?.Airline}}
                                    {{segments?.FlightNumber}}</p>
                                <p *ngFor="let passin of data.Itinerary?.Itinerary?.Passenger"
                                    class="july-place-content"><img class="booking-icons"
                                        src="./../../../assets/images/bookings/booking-1.png"
                                        alt="no-data">{{passin?.FirstName}}</p>
                            </div>
                        </div>
                        <!-- <div *ngIf="i === data.Itinerary?.Segments.length - 1"  style="cursor: pointer;" (click)="generatePDF(data)" class="upcoming-bottom-right">
                        <img class="download-img" src="./../../../assets/images/bookings/download.png" alt="">
                        <a class="download-ticket">Download Ticket</a>
                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ---  Pills Content End  --- -->

</div>


<div *ngIf="isPackagesSection" class="container">
    <!-- ---  Pills Content Start  --- -->
    <div class="tab-content" id="v-pills-tabContent">
        <!-- ---  Pill 1 Start  --- -->
        <div class="tab-pane fade show active" id="v-pills-upcoming" role="tabpanel"
            aria-labelledby="v-pills-upcoming-tab">
            <!-- ---  Pill 1 Content Start  --- -->
            <!-- ---  Top Content Start  --- -->
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myPackageBookingsSuccess.length <= 0">No
                Bookings</p>
            <div>
                <div *ngFor="let item of myPackageBookingsSuccess">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <!-- {{item.packagesUserDetails[0]?.personalDetails?.firstname}} -->
                                <p class="way-to-place">{{item.packageDetails[0]?.title}} </p>
                                <p class="way-to-content"><span class="way-last-para">Booking ID - {{item.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date : {{item.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button (click)="packageBookingVIew(item._id)" class="view-info" type="button">VIEW BOOKING</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
               
                </div>
            </div>
            <!-- ---  Top Content End  --- -->

            <!-- ---  Bottom Content End  --- -->

            <!-- ---  Pill 1 Content End  --- -->
        </div>


        <div class="tab-pane fade " id="v-pills-failure" role="tabpanel" aria-labelledby="v-pills-failure-tab">
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myPackageBookingsFailure.length <= 0">No
                Failure Bookings</p>

            <div>
                <div *ngFor="let item of myPackageBookingsFailure">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{item.packageDetails[0]?.countryName}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: #c81303;">Failure</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">Package<img
                                        class="dot-img" src="./../../../assets/images/bookings/dot.png"
                                        alt="no-data"><span class="way-last-para">Booking ID - {{item.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date : {{item.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button  (click)="packageBookingVIew(item._id)"  class="view-info" type="button">VIEW BOOKING</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
           
                </div>
            </div>
        </div>

        <div class="tab-pane fade " id="v-pills-completed" role="tabpanel" aria-labelledby="v-pills-completed-tab">
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="completedBookings.length <= 0">No
                Completed Bookings</p>

            <div>
                <div *ngFor="let item of completedBookings">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/packagesImage.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{item.packageDetails[0]?.countryName}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: #005b1d;">Completed</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">Package<img
                                        class="dot-img" src="./../../../assets/images/bookings/dot.png"
                                        alt="no-data"><span class="way-last-para">Booking ID - {{item.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date : {{item.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button  (click)="packageBookingVIew(item._id)"  class="view-info" type="button">VIEW BOOKING</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
           
                </div>
            </div>
        </div>
    </div>

</div>


<div *ngIf="isVisaSection" class="container">
    <!-- ---  Pills Content Start  --- -->
    <div class="tab-content" id="v-pills-tabContent">
        <!-- ---  Pill 1 Start  --- -->
        <div class="tab-pane fade show active" id="v-pills-upcoming" role="tabpanel"
            aria-labelledby="v-pills-upcoming-tab">
            <!-- ---  Pill 1 Content Start  --- -->
            <!-- ---  Top Content Start  --- -->
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myVisaBookingsSuccess.length <= 0">No
            Bookings</p>
            <div>
                <div *ngFor="let visa of myVisaBookingsSuccess">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{visa.destinationCountry}} : {{visa.appledFor}}</p>
                                <p class="way-to-content"><span class="way-last-para">Booking ID - {{visa.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date :{{visa.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button class="view-info" (click)="visaBookingVIew(visa._id)" type="button">Check status</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
               
                </div>
            </div>
            <!-- ---  Top Content End  --- -->

            <!-- ---  Bottom Content End  --- -->

            <!-- ---  Pill 1 Content End  --- -->
        </div>


        <div class="tab-pane fade " id="v-pills-failure" role="tabpanel" aria-labelledby="v-pills-failure-tab">
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="myVisaBookingsFailure.length <= 0">No
            Failure Bookings</p>

            <div>
                <div *ngFor="let visa of myVisaBookingsFailure">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place">{{visa.destinationCountry}} : {{visa.appledFor}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: rgb(248, 14, 14)">Failure</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">Visa<img
                                        class="dot-img" src="./../../../assets/images/bookings/dot.png"
                                        alt="no-data"><span class="way-last-para">Booking ID - {{visa.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date :{{visa.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button class="view-info" (click)="visaBookingVIew(visa._id)" type="button">Check status</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
                
                </div>
            </div>
        </div>

        <div class="tab-pane fade " id="v-pills-complete" role="tabpanel" aria-labelledby="v-pills-complete-tab">
            <p style="text-align: center; font-size: 20px; margin: 30px;" *ngIf="CompleteduserVisa.length <= 0">No
            Completed Bookings</p>

            <div>
                <div *ngFor="let visa of CompleteduserVisa">
                    <div class="upcoming-trips">

                        <div class="book-images">
                            <img class="book-images-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">
                        </div>
                        <img class="book-img-inside" src="./../../../assets/images/m/VisaImages.svg" alt="no-data">

                        <ul class="way-to-journey">
                            <li>
                                <p class="way-to-place"> {{visa.destinationCountry}} :  {{visa.appledFor}}</p>
                                <p class="way-to-content"><a (click)="getStatus(data)"
                                        style="cursor: pointer; color: rgb(14, 72, 0)">Completed</a><img class="dot-img"
                                        src="./../../../assets/images/bookings/dot.png" alt="no-data">Visa<img
                                        class="dot-img" src="./../../../assets/images/bookings/dot.png"
                                        alt="no-data"><span class="way-last-para">Booking ID - {{visa.refId}}</span>
                                </p>
                                <p class="way-to-content">Created Date :{{visa.createdDate | date:'dd-MM-yyyy'}}</p>

                            </li>
                            <li>
                                <button class="view-info" (click)="visaBookingVIew(visa._id)" type="button">Check status</button>
                            </li>
                        </ul>
                        <div>

                        </div>

                    </div>
                
                </div>
            </div>
        </div>
    </div>

</div>