import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-google-reviews',
  templateUrl: './google-reviews.component.html',
  styleUrls: ['./google-reviews.component.css']
})
export class GoogleReviewsComponent implements OnInit{
  revideItems:any =[]
  constructor(private service:AuthService){}
  ngOnInit(): void {
    this.allGoogleReviews()
  }

  allGoogleReviews(){
    
    this.service.allGoogleReviews().subscribe(
      (data:any)=>{
        
          this.revideItems = data
      }
    )
    // this.service.getGoogleReviews().subscribe(
    //   (data:any)=>{
    //     console.log(data)
    //     this.revideItems = data
    //   }
    // )
  }


  openMaps(link) {
    window.open(link, '_blank');
  }

}
