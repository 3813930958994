<div class="faeQuote">



    <div class="container">
        <div class="row">
            <div class="col-md-9">
                flight details

                <button (click)="proceed()" class="btn btn-danger">Proceed</button>
            </div>
            <div style="color: aliceblue;" class="col-md-3">
                fare details details
                <p>total fare : {{fareDetails.Fare.TotalFare}}</p>
                <p>ServiceFee : {{fareDetails.Fare.ServiceFee}}</p>
                <p>BaseFare :{{ fareDetails.Fare.BaseFare}}</p>
                <p>Tax : {{fareDetails.Fare.Tax}}</p>
                <p>PenaltyAmount : {{fareDetails.Fare.PenaltyAmount}}</p>

            </div>
        </div>
    </div>
</div>