import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'priceExchange'
})
export class PriceExchangePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: number, selectedCurrency: string): Observable<number | null> {
    return this.authService.exchangePrice(value, selectedCurrency);
  }
}
