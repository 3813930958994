import { Component, OnInit,Input } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit{
  @Input() nameData:any
  separateDialCode = false;
  phoneForm:FormGroup
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedArabEmirates];
  constructor(){



  }
  ngOnInit(): void {
  this.loadScripts()
  this.phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  }
  options: AnimationOptions = {
    path: './../../../assets/images/career/careers.json',
  };
  animationCreated(animationItem: AnimationItem): void {

  }
  

  loadScripts() {
    
        let load:any = localStorage.getItem('load')
        if(load === 'true'){
          document.location.reload()
          localStorage.setItem('load','false')
        }
        const dynamicScripts = [
    
          "./../../../assets/js/carousel.js",
    
        ];
    
        for (let i = 0; i < dynamicScripts.length; i++) {
    
          const node = document.createElement('script');
    
          node.src = dynamicScripts[i];
    
          node.type = 'text/javascript';
    
          node.async = false;
    
          node.charset = 'utf-8';
    
          document.getElementsByTagName('head')[0].appendChild(node);
    
        }
    
      }
    	changePreferredCountries() {
		this.preferredCountries = [CountryISO.UnitedArabEmirates,];

   
	}

  newRefresh(){
    
  }
}
