import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { packagesEnquiry, selectedVIsa } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-visa-overview',
  templateUrl: './visa-overview.component.html',
  styleUrls: ['./visa-overview.component.css']
})
export class VisaOverviewComponent {
  selectedCurrency:any

  @ViewChild('inputFocusVisaOne') inputFocusVisaOne: ElementRef;
  @ViewChild('inputFocusVisaTwo') inputFocusVisaTwo: ElementRef;
  @ViewChild('inputFocusVisaThree') inputFocusVisaThree: ElementRef;
  @ViewChild('inputFocusVisaTwoDiv') inputFocusVisaTwoDiv: ElementRef;
  @ViewChild('inputFocusVisaThreeDiv') inputFocusVisaThreeDiv: ElementRef;
  visaOne: any = 'United Arab Emirates'
  visaTwo: any = 'India'
  visaThree: any = 'India'
  searchText1 = ''
  searchText2 = ''
  searchText3 = ''
  countryList: any = []
  selectedCity: any = 'Delhi';
  activeTabIndex: number = 0;
  visaId: any
  visaDetails: any = []
  sectionDetails: any = []
  allthePackages: any = []
  hotDeals: any = []
  totalPrice: number = 0
  localDeals: any = []
  isVisaRequired: boolean
  visaCOuntry: any = []
  carRental2: any = []
  selectedVIsaObj = new selectedVIsa()
  globalDeals: any = []
  isVisaLany: any
  packagesEnquiryObj = new packagesEnquiry()
  @ViewChild('myInput') inputElement: ElementRef;
  minDate: any
  citynames: any = [
    { name: "Delhi" },
    { name: "Mumbai" },
    { name: "Bengalure" },
    { name: "Pune" },
    { name: "Goa" },
    { name: "Chennai" },
    { name: "Jaipur" },
    { name: "Hyderabad" },
    { name: "Singapore" },
  ]
  constructor(private service: AuthService, private router: Router, private route: ActivatedRoute) {
    this.selectedCurrency = localStorage.getItem('selectedCurrency')
    this.CheckCurrency()
  }

  
  CheckCurrency(){
    this.service.CheckCurrency.subscribe(
      (data:any)=>{
        this.selectedCurrency = data
      }
    )
  }
  ngOnInit(): void {
    this.route.params.subscribe(
      (data: any) => {
          
        let paramData = JSON.parse(data.id)
        this.visaOne = paramData.iamTravelling
        this.visaTwo = paramData.iamCityzenOf
        this.visaThree = paramData.iamTravellingFrom
        this.visaCOuntry = paramData.iamTravelling
        if (paramData.iamTravelling === paramData.iamTravellingFrom) {
          this.router.navigateByUrl('/home')
          this.service.error('please check from country and to country should not be same')
        }
        let isVisa = {
          id: paramData.iamTravelling,
          name: paramData.iamTravellingFrom
        }
        localStorage.setItem('isVisa', JSON.stringify(isVisa))
        this.isVisaLany = isVisa
        this.visaRequired(isVisa)
        this.getVisaByCountry()
        this.getFaq('Visa')
      }
    )

    window.scrollTo(0, 0);
    this.loadScripts()
    this.selectCountry()

  }

  getVisaByCountry() {
    this.service.getVisaByCountry(this.visaCOuntry).subscribe(
      (data: any) => {

        if (data.response.length === 0) {
          this.router.navigateByUrl('/home')
          this.service.error('This visa not found')
          return
        }
        this.visaId = data.response[0]?._id

        this.getVisaByID()

      }
    )
  }
  visaRequired(data) {
    this.service.visaRequired(data).subscribe(
      (data: any) => {


        this.isVisaRequired = data.isVisaRequired
        this.visaId = data.response[0]?._id
        this.getVisaByID()

      }
    )
  }

  selectCitySearch(data: any) {

    this.citynames.filter((value) => {

      if (value.name === data.target.value) {
        this.citynames = value
      }
    })
  }
  selectedCityname(data: any) {
    this.selectedCity = data
    this.packagesEnquiryObj.PackageName = data
  }

  selectFliter() {

    setTimeout(() => {
      this.inputElement.nativeElement.focus()
    }, 100)

  }

  async submitData() {
    this.packagesEnquiryObj.packageDate = this.minDate
    if (!this.packagesEnquiryObj.AdultsCount) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.PackageName) {
      this.service.error('All fieids are required')
      return
    }
    if (!this.packagesEnquiryObj.packageDate) {
      this.service.error('All fieids are required')
      return
    }
    else {
      let access: any = this.service.isLoggedin()
      if (access) {
        try {
          let userSave: any = await this.service.Packagesenquiry(this.packagesEnquiryObj).toPromise()

          this.service.success(userSave.message)
          this.packagesEnquiryObj.AdultsCount = ''
          this.packagesEnquiryObj.PackageName = ''
          this.packagesEnquiryObj.packageDate = ''
          this.minDate = ''
        } catch (err) {
          this.service.error(err.error.message)
        }
      } else {
        // this.service.error('Please login')
        this.service.tryLogin('PleaseLogin')
      }
    }



  }


  loadScripts() {

    const dynamicScripts = [

      "./../../../assets/js/script.js",
      "./../../../assets/js/carousel.js"

    ];

    for (let i = 0; i < dynamicScripts.length; i++) {

      const node = document.createElement('script');

      node.src = dynamicScripts[i];

      node.type = 'text/javascript';

      node.async = false;

      node.charset = 'utf-8';

      document.getElementsByTagName('head')[0].appendChild(node);

    }

  }


  routeTOView(data: any) {
    this.router.navigateByUrl(`/packageView/${data.id}`)


  }


  getVisaByID() {
    this.service.getVisaByID(this.visaId).subscribe(
      (data: any) => {

        this.visaDetails = []
        this.selectedVIsaObj.visaId = data.responce[0]._id
        this.visaDetails = data.responce[0]

        this.sectionDetails = data.responce[0].visaInfo[0]
        this.totalPrice = this.sectionDetails.price
        this.selectedVIsaObj.suVisaId = this.sectionDetails._id

      }
    )
  }
  setActiveTab(index: number, item: any): void {

    this.activeTabIndex = index;
    this.selectedVIsaObj.suVisaId = item._id
    this.sectionDetails = item
    this.totalPrice = this.sectionDetails.price
    this.selectedServices = []
  }

  navigate() {

    let Access = this.service.isLoggedin()
     
    if (Access === true) {
      localStorage.setItem('selectedVisa', JSON.stringify(this.selectedVIsaObj))
      this.router.navigate(['/applyVisa', this.visaId])
    }
    else {
      // this.service.error('login required')
      this.service.tryLogin('PleaseLogin')
    }


  }
  selectedServices: any[] = [];

  toggleService(price: any, adServiceId: any, serviceName: any, serviceDescription: any, event: any) {
    // Convert serviceDescription to a number
    // this.selectedVIsaObj.suVisaId = adServiceId
    const numericServiceDescription = parseFloat(serviceDescription);

    this.totalPrice = price
    if (event.target.checked === true) {
      if (this.selectedServices.some(service => service.name === serviceName)) {
        // If the service is already selected, remove it
        this.selectedServices = this.selectedServices.filter(service => service.name !== serviceName);

      } else {
        // If the service is not selected, add it
        let data: any = {
          adServiceId: adServiceId,
          name: serviceName,
          price: numericServiceDescription
        };
        this.selectedServices.push(data);

      }
    } else {
      // If the checkbox is unchecked, remove the service
      this.selectedServices = this.selectedServices.filter(service => service.name !== serviceName);

    }
 
    this.selectedServices.forEach(item => {
      this.totalPrice += parseInt(item.price);
    });
    this.selectedVIsaObj.adSerices = this.selectedServices



  }

  getFaq(name: any) {
    console.clear()

    this.service.getFaq(name).subscribe(
      (data: any) => {
        this.carRental2 = data
      }
    )
  }

  selectOneVisa(item) {
    this.visaOne = item.name
    setTimeout(() => {
      this.inputFocusVisaTwoDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaTwo.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }

  selectTwoVisa(item) {
    this.visaTwo = item.name
    setTimeout(() => {
      this.inputFocusVisaThreeDiv.nativeElement.click()

    }, 10)
    setTimeout(() => {
      this.inputFocusVisaThree.nativeElement.focus()

    }, 30)
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  selectThreeVisa(item) {
    this.visaThree = item.name
    this.searchText1 = ''
    this.searchText2 = ''
    this.searchText3 = ''
  }
  submitVisaFor() {

    if (this.visaOne === this.visaThree) {
      this.service.error('please check from country and to country should not be same')
      return;
    }
    this.service.getVisaByCountry(this.visaOne).subscribe(
      (data: any) => {

        if (data.response.length === 0) {
          // this.router.navigateByUrl('/home')
          this.service.error('Visa not found')
          return
        }
        else {
          let info = {
            iamTravelling: this.visaOne,
            iamCityzenOf: this.visaTwo,
            iamTravellingFrom: this.visaThree
          }
          let share = JSON.stringify(info)
          this.router.navigateByUrl(`/visa-overview/${share}`)
        }


      }
    )

  }

  submitVisaForTargetted(data, country) {
    let info = {
      iamTravelling: data,
      iamCityzenOf: 'United Arab Emirates',
      iamTravellingFrom: country
    }
    let share = JSON.stringify(info)
    this.router.navigateByUrl(`/visa-overview/${share}`)
  }

  selectCountry() {
    this.service.CountryLists().subscribe(
      (data: any) => {
        this.countryList = data
      }
    )
  }
  setFocus(count) {
    if (count === '1') {
      setTimeout(() => {
        this.inputFocusVisaOne.nativeElement.focus()

      }, 10)
    }
    if (count === '2') {
      setTimeout(() => {
        this.inputFocusVisaTwo.nativeElement.focus()

      }, 10)
    }
    if (count === '3') {
      setTimeout(() => {
        this.inputFocusVisaThree.nativeElement.focus()

      }, 10)
    }
  }

}
