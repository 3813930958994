<div class="pacagesNew">
    <div class="home-page">
        <div class="stayBox">
          <div class="container">
            <div class="contents">
            
              
              <div class=" destination-search-below justify-content-center">
                <h1 class="packagees">Packages</h1>
                <div class="row justify-content-center">
                  <div class="col-md-4 col-lg-3 mb-2">
                    <div class="form-group form-boxes" >
             
                      <label for="cityInput" class="d-block city-label">TRAVELLING TO</label>
                      <!-- Example single danger button -->
                      <input placeholder="Country / City" [(ngModel)]="packagesEnquiryObj.PackageName" type="text" class="contntBox">
                      <!-- <div class="btn-group top-drpdown">
                        <div
                         (click)="selectFliter()"
                          class="btn  dropdown-toggle down-btn"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                        <p class="selectedCity">  {{selectedCity}}</p>
                      </div>
                        <ul class="dropdown-menu city-listsss">
                          <li>
                            <div class="input-group search-box mb-3">
                              <input
                                type="text"
                                #myInput
                                (keyup)="selectCitySearch($event)"
                                class="form-control search-input"
                                id="searchInput"
                                placeholder="Search..."
                              />
                            </div>
                          </li>
                          <li *ngFor="let item of citynames" (click)="selectedCityname(item.name)" class="CitysName"><a class="dropdown-item city-names"  >{{item.name}}</a></li>
                
                        </ul>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3 mb-2">
                    <div class="form-group form-boxes">
                      <label for="dateInput" class="city-label">TRAVELLING ON</label>
                      <input
                      style="color: #727b85 !important;"
                        type="date"
                        [min]="minDate"
                        [(ngModel)]="minDate"
                        class="form-control date-input"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-3 mb-2">
                    <div class="form-group form-boxes">
                      <label for="dateInput" class="city-label">Guests</label>
                      <input
                        type="number"
                        [(ngModel)]="this.packagesEnquiryObj.AdultsCount"
                        placeholder="Adults"
                        class="form-control box-data date-input"
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                  </div>

                </div>
               
              </div>
              <button (click)="submitData()" class="searchBtn">Enquire</button>
            </div>
          </div>
        </div>


<div class="DevoteExclusive">



        <div class="container">
            <!-- --  Exclusive Deals Start  -- -->
            <div class="ExclusiveDeals">
              <div class="ExclusiveDelasInside">
    
                <p class="ExclusiveDataTitle">Exclusive Deals</p>
    
                <ul class="nav nav-pills NavData mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item NavDataItem" role="presentation">
                    <button class="nav-link NavDataLink active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">HOT DEAL</button>
                  </li>
                  <li class="nav-item NavDataItem" role="presentation">
                    <button class="nav-link NavDataLink" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">LOCAL OFFERS</button>
                  </li>
                  <li class="nav-item NavDataItem" role="presentation">
                    <button class="nav-link NavDataLink" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">GLOBAL OFFERS</button>
                  </li>
                </ul>
              </div>
    
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <!-- Carousel Start -->
                  <div class="popular-locations">
  
                    <owl-carousel-o [options]="customOptions">
                        <ng-template *ngFor="let item of hotDeals" class="imageSlides" carouselSlide>
                            <div (click)="routeTOView(item)" class="popular-location-inside">
                                <img class="popular-location-img"  [src]="item.thumbnail" alt="">
                                <p class="PopularImgTitle">{{item.countryName}}</p>
                                <p class="PopularLocationPrice">Starting at <span class="PopularSpanPrice">$ {{item.price}}</span></p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>

                        </ng-template>  
        
                      </owl-carousel-o>
                  </div>
                  <!-- Carousel End -->
                </div>
    
    
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <!-- Carousel Start -->
                  <div class="popular-locations">
                    <div class="owl-carousel owl-popular-locations owl-theme">
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/japan.png" alt="">
                                <p class="PopularImgTitle">Japan</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/south-korea.png" alt="">
                                <p class="PopularImgTitle">Indonesia</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/tokyo.png" alt="">
                                <p class="PopularImgTitle">Maldives</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/australia.png" alt="">
                                <p class="PopularImgTitle">South Korea</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/bali.png" alt="">
                                <p class="PopularImgTitle">Bali</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/india.png" alt="">
                                <p class="PopularImgTitle">Australia</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <!-- Carousel End -->
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <!-- Carousel Start -->
                  <div class="popular-locations">
                    <div class="owl-carousel owl-popular-locations owl-theme">
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/japan.png" alt="">
                                <p class="PopularImgTitle">Japan</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/south-korea.png" alt="">
                                <p class="PopularImgTitle">Indonesia</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/tokyo.png" alt="">
                                <p class="PopularImgTitle">Maldives</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/australia.png" alt="">
                                <p class="PopularImgTitle">South Korea</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/bali.png" alt="">
                                <p class="PopularImgTitle">Bali</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
    
                        <div class="item">
                            <div class="popular-location-inside">
                                <img class="popular-location-img" src="./../../../assets/images/packagesDyamic/india.png" alt="">
                                <p class="PopularImgTitle">Australia</p>
                                <p class="PopularLocationPrice">Starting at $1,200.58</p>
                                <p class="PopularLocationData">Per Couple</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <!-- Carousel End -->
                </div>
              </div>
            </div>
            <!-- --  Exclusive Deals End  -- -->
    
            <!-- --  Browse By Experience Start  -- -->
            <div class="BrowseExperience">
              <div class="BrowseExpInside">
    
                <p class="BrowseExpTitle">Browse by Experience</p>
    
                <ul class="nav nav-pills Nav-1-Data mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item Nav-1-DataItem" role="presentation">
                    <button class="nav-link Nav-1-DataLink active" id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about" aria-selected="true">ALL DESTINATIONS</button>
                  </li>
                  <li class="nav-item Nav-1-DataItem" role="presentation">
                    <button class="nav-link Nav-1-DataLink" id="pills-services-tab" data-bs-toggle="pill" data-bs-target="#pills-services" type="button" role="tab" aria-controls="pills-services" aria-selected="false">INTERNATIONAL</button>
                  </li>
                  <li class="nav-item Nav-1-DataItem" role="presentation">
                    <button class="nav-link Nav-1-DataLink" id="pills-contact2-tab" data-bs-toggle="pill" data-bs-target="#pills-contact2" type="button" role="tab" aria-controls="pills-contact2" aria-selected="false">DOMESTIC</button>
                  </li>
                </ul>
              </div>
              <div class="BrowseUnderData">
                <div class="row">
                  <div class="col-md-6">
                    <div class="BrowseUnderLeft">
                      <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                          <div *ngFor="let item of allthePackages" class="BrowseLeftData">
                            <ul class="BrowseUnderList">
                              <li class="BrowseInsideList"><img class="BrowseProfileImg" [src]="item.thumbnail" alt="no-data"></li>
                              <li class="BrowseInsideList">
                                <div class="BrowseProfileData">
                                  <p class="BrowseProfileTitle">{{item.recommendedFor}}</p>
                                  <p class="BrowseProfileContent">{{item.countryName}}</p>
                                </div>
                              </li>
                            </ul>
                            <button class="BrowseProfileButton" type="button">₹ {{item.price}}</button>
                          </div>
    
                      
                        </div>
                        <div class="tab-pane fade" id="pills-services" role="tabpanel" aria-labelledby="pills-services-tab">
                          <h3>Services</h3>
                          <p>We offer a wide range of services...</p>
                        </div>
                        <div class="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact2-tab">
                          <h3>Contact Us</h3>
                          <p>Feel free to reach out to us...</p>
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="BrowserUnderRight">
                          <div class="BrowserInsideUnder">
                            <img class="BrowserRightImg" src="./../../../assets/images/packagesDyamic/TourA.png" alt="no-data">
                            <div class="BrowserTimeBottom">
                              <p class="BrowserTimeLocationTitle"><i class="fas fa-map-marker-alt BrowserTimeLocation"></i>Japan</p>
                              <p class="BrowserTimePrice">Starting @30,000</p>
                            </div>
                          </div>
                          <div class="BrowserInsideUnder BrowserInsideUnderA">
                            <img class="BrowserRightImg" src="./../../../assets/images/packagesDyamic/TourC.png" alt="no-data">
                            <div class="BrowserTimeBottom">
                              <p class="BrowserTimeLocationTitle"><i class="fas fa-map-marker-alt BrowserTimeLocation"></i>South Korea</p>
                              <p class="BrowserTimePrice">Starting @30,000</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="BrowserUnderRight">
                          <div class="BrowserInsideUnder BrowserInsideUnderB">
                            <img class="BrowserRightImg" src="./../../../assets/images/packagesDyamic/TourB.png" alt="no-data">
                            <div class="BrowserTimeBottom">
                              <p class="BrowserTimeLocationTitle"><i class="fas fa-map-marker-alt BrowserTimeLocation"></i>Maldives</p>
                              <p class="BrowserTimePrice">Starting @30,000</p>
                            </div>
                          </div>
                          <div class="BrowserInsideUnder BrowserInsideUnderA">
                            <img class="BrowserRightImg" src="./../../../assets/images/packagesDyamic/TourD.png" alt="no-data">
                            <div class="BrowserTimeBottom">
                              <p class="BrowserTimeLocationTitle"><i class="fas fa-map-marker-alt BrowserTimeLocation"></i>Dubai</p>
                              <p class="BrowserTimePrice">Starting @30,000</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
            <!-- --   Browse By Experience End  -- -->
    
            <!-- --  International Destinations Start  -- -->
            <div class="InternationalDestination">
              <div class="InterDestInside">
                <div class="InterDestInsideData">
                  <p class="InterDestTitle">International Destinations</p>
                  <ul class="nav nav-pills Nav-2-Data" id="pills-tab" role="tablist">
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink active" id="pills-island-tab" data-bs-toggle="pill" href="#pills-island" role="tab" aria-controls="pills-island" aria-selected="true">ISLAND</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-asia-tab" data-bs-toggle="pill" href="#pills-asia" role="tab" aria-controls="pills-asia" aria-selected="false">ASIA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-africa-tab" data-bs-toggle="pill" href="#pills-africa" role="tab" aria-controls="pills-africa" aria-selected="false">AFRICA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-australia-tab" data-bs-toggle="pill" href="#pills-australia" role="tab" aria-controls="pills-australia" aria-selected="false">AUSTRALIA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-middleeast-tab" data-bs-toggle="pill" href="#pills-middleeast" role="tab" aria-controls="pills-middleeast" aria-selected="false">MIDDLE EAST</a>
                    </li>
                  </ul>
                </div>
                <div class="ViewAllDestinations">
                  <a class="DataItemAnch" href="#">VIEW ALL DESTINATIONS</a>
    
                  <div class="InterDestInsideEmpty">
                    <div class="custom-nav-prev inter"><i class="fas fa-angle-left"></i></div>
                    <div class="custom-nav-next inter"><i class="fas fa-angle-right"></i></div>
                  </div>
                </div>
              </div>
    
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-island" role="tabpanel" aria-labelledby="pills-island-tab">
                  <!-- Carousel Start -->
                  <div class="InterTourism">
                    <owl-carousel-o [options]="international">
                      <ng-template *ngFor="let item of hotDeals" class="imageSlides" carouselSlide>
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" [src]="item.thumbnail" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">{{item.title}}</p>
                                <p class="InterTourismContent">{{item.nightsCount}} Nights / {{item.daysCount}} Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹ {{item.price}}</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Details</button></li>
                              </ul>
                          </div>
                      </div> 
                      </ng-template>  
      
                    </owl-carousel-o>
              
                  </div>
                  <!-- Carousel End -->
                </div>
                <div class="tab-pane fade" id="pills-asia" role="tabpanel" aria-labelledby="pills-asia-tab">
                  <h3>Asia Content</h3>
                  <p>This is the content for the Asia tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-africa" role="tabpanel" aria-labelledby="pills-africa-tab">
                  <h3>Africa Content</h3>
                  <p>This is the content for the Africa tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-australia" role="tabpanel" aria-labelledby="pills-australia-tab">
                  <h3>Australia Content</h3>
                  <p>This is the content for the Australia tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-middleeast" role="tabpanel" aria-labelledby="pills-middleeast-tab">
                  <h3>Middle East Content</h3>
                  <p>This is the content for the Middle East tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-viewall" role="tabpanel" aria-labelledby="pills-viewall-tab">
                  <h3>VIEW ALL DESTINATIONS Content</h3>
                  <p>This is the content for the VIEW ALL DESTINATIONS tab.</p>
                </div>
              </div>
            </div>
            <!-- --  International Destinations End  -- -->
    
            <!-- --  Domestic Destinations Start  -- -->
            <div class="InternationalDestination">
              <div class="InterDestInside">
                <div class="InterDestInsideData">
                  <p class="InterDestTitle">Domestic Destinations</p>
                  <ul class="nav nav-pills Nav-2-Data" id="pills-tab" role="tablist">
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink active" id="pills-island-tab" data-bs-toggle="pill" href="#pills-island" role="tab" aria-controls="pills-island" aria-selected="true">ISLAND</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-asia-tab" data-bs-toggle="pill" href="#pills-asia" role="tab" aria-controls="pills-asia" aria-selected="false">ASIA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-africa-tab" data-bs-toggle="pill" href="#pills-africa" role="tab" aria-controls="pills-africa" aria-selected="false">AFRICA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-australia-tab" data-bs-toggle="pill" href="#pills-australia" role="tab" aria-controls="pills-australia" aria-selected="false">AUSTRALIA</a>
                    </li>
                    <li class="nav-item Nav-2-DataItem" role="presentation">
                      <a class="nav-link Nav-2-DataLink" id="pills-middleeast-tab" data-bs-toggle="pill" href="#pills-middleeast" role="tab" aria-controls="pills-middleeast" aria-selected="false">MIDDLE EAST</a>
                    </li>
                  </ul>
                </div>
                <div class="ViewAllDestinations">
                  <a class="DataItemAnch" href="#">VIEW ALL DESTINATIONS</a>
    
                  <div class="InterDestInsideEmpty">
                    <div class="custom-nav-prev new"><i class="fas fa-angle-left"></i></div>
                    <div class="custom-nav-next new"><i class="fas fa-angle-right"></i></div>
                  </div>
                </div>
                
              </div>
    
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-island" role="tabpanel" aria-labelledby="pills-island-tab">
                  <!-- Carousel Start -->
                  <div class="InterTourism NewCarouselContainer">
                    <div class="owl-carousel OwlNewLocations owl-theme">
                        <div class="item">
                            <div class="InterTourismInside">
                                <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterA.png" alt="">
                                <div class="InterTourInside">
                                  <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                  <p class="InterTourismContent">5 Nights / 6 Days</p>
                                </div>
                                <ul class="InterTourUL">
                                  <li class="InterTourLI">
                                    <p class="InterLiStarting">Starting Price</p>
                                    <p class="InterLiPrice">₹30,000</p>
                                  </li>
                                  <li><button class="InterTourButton" type="button">Detail</button></li>
                                </ul>
                            </div>
                        </div> 
    
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterB.png" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                <p class="InterTourismContent">5 Nights / 6 Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹30,000</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Detail</button></li>
                              </ul>
                          </div>
                        </div> 
    
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterC.png" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                <p class="InterTourismContent">5 Nights / 6 Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹30,000</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Detail</button></li>
                              </ul>
                          </div>
                        </div> 
    
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterD.png" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                <p class="InterTourismContent">5 Nights / 6 Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹30,000</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Detail</button></li>
                              </ul>
                          </div>
                        </div> 
    
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterA.png" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                <p class="InterTourismContent">5 Nights / 6 Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹30,000</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Detail</button></li>
                              </ul>
                          </div>
                        </div> 
    
                        <div class="item">
                          <div class="InterTourismInside">
                              <img class="InterLocationImg" src="./../../../assets/images/packagesDyamic/InterB.png" alt="">
                              <div class="InterTourInside">
                                <p class="InterTourismTitle">Spectacular Phuket and Krabi</p>
                                <p class="InterTourismContent">5 Nights / 6 Days</p>
                              </div>
                              <ul class="InterTourUL">
                                <li class="InterTourLI">
                                  <p class="InterLiStarting">Starting Price</p>
                                  <p class="InterLiPrice">₹30,000</p>
                                </li>
                                <li><button class="InterTourButton" type="button">Detail</button></li>
                              </ul>
                          </div>
                        </div> 
                    </div>
                  </div>
                  <!-- Carousel End -->
                </div>
                <div class="tab-pane fade" id="pills-asia" role="tabpanel" aria-labelledby="pills-asia-tab">
                  <h3>Asia Content</h3>
                  <p>This is the content for the Asia tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-africa" role="tabpanel" aria-labelledby="pills-africa-tab">
                  <h3>Africa Content</h3>
                  <p>This is the content for the Africa tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-australia" role="tabpanel" aria-labelledby="pills-australia-tab">
                  <h3>Australia Content</h3>
                  <p>This is the content for the Australia tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-middleeast" role="tabpanel" aria-labelledby="pills-middleeast-tab">
                  <h3>Middle East Content</h3>
                  <p>This is the content for the Middle East tab.</p>
                </div>
                <div class="tab-pane fade" id="pills-viewall" role="tabpanel" aria-labelledby="pills-viewall-tab">
                  <h3>VIEW ALL DESTINATIONS Content</h3>
                  <p>This is the content for the VIEW ALL DESTINATIONS tab.</p>
                </div>
              </div>
            </div>
            <!-- --  Domestic Destinations End  -- -->
    
            <!-- --  Best Time Travel Start  -- -->
            <div class="BestTime">
              <p class="BestTimeTitle">Where to go When The Best time to travel...</p>
              <ul class="nav nav-pills BestTimePills" id="myTab" role="tablist">
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink active" id="jan-tab" data-bs-toggle="pill" href="#jan" role="tab" aria-controls="jan" aria-selected="true">JAN</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="feb-tab" data-bs-toggle="pill" href="#feb" role="tab" aria-controls="feb" aria-selected="false">FEB</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="mar-tab" data-bs-toggle="pill" href="#mar" role="tab" aria-controls="mar" aria-selected="false">MAR</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="apr-tab" data-bs-toggle="pill" href="#apr" role="tab" aria-controls="apr" aria-selected="false">APR</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="may-tab" data-bs-toggle="pill" href="#may" role="tab" aria-controls="may" aria-selected="false">MAY</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="jun-tab" data-bs-toggle="pill" href="#jun" role="tab" aria-controls="jun" aria-selected="false">JUN</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="jul-tab" data-bs-toggle="pill" href="#jul" role="tab" aria-controls="jul" aria-selected="false">JUL</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="aug-tab" data-bs-toggle="pill" href="#aug" role="tab" aria-controls="aug" aria-selected="false">AUG</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="sep-tab" data-bs-toggle="pill" href="#sep" role="tab" aria-controls="sep" aria-selected="false">SEP</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="oct-tab" data-bs-toggle="pill" href="#oct" role="tab" aria-controls="oct" aria-selected="false">OCT</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="nov-tab" data-bs-toggle="pill" href="#nov" role="tab" aria-controls="nov" aria-selected="false">NOV</a>
                </li>
                <li class="nav-item BestTimeItem" role="presentation">
                  <a class="nav-link BestTimeLink" id="dec-tab" data-bs-toggle="pill" href="#dec" role="tab" aria-controls="dec" aria-selected="false">DEC</a>
                </li>
              </ul>
    
              <div class="tab-content mt-2" id="myTabContent">
                <div class="tab-pane fade show active" id="jan" role="tabpanel" aria-labelledby="jan-tab">
                  <div class="BestTimeInside">
                    <div class="row">
                      <div class="col-md-3 col-sm-6">
                        <div class="BestTimeUnder">
                          <img class="BestTimeImg" src="../../../../assets/images/packagesDyamic/TravelA.png" alt="no-data">
                          <div class="BestTimeBottom">
                            <p class="BestTimeLocationTitle"><i class="fas fa-map-marker-alt BestTimeLocation"></i>India</p>
                            <p class="BestTimePrice">Starting @30,000</p>
                          </div>
                        </div>
                      </div>
    
                      <div class="col-md-3 col-sm-6">
                        <div class="BestTimeUnder">
                          <img class="BestTimeImg" src="../../../../assets/images/packagesDyamic/TravelB.png" alt="no-data">
                          <div class="BestTimeBottom">
                            <p class="BestTimeLocationTitle"><i class="fas fa-map-marker-alt BestTimeLocation"></i>Bali</p>
                            <p class="BestTimePrice">Starting @30,000</p>
                          </div>
                        </div>
                      </div>
    
                      <div class="col-md-3 col-sm-6">
                        <div class="BestTimeUnder">
                          <img class="BestTimeImg" src="../../../../assets/images/packagesDyamic/TravelC.png" alt="no-data">
                          <div class="BestTimeBottom">
                            <p class="BestTimeLocationTitle"><i class="fas fa-map-marker-alt BestTimeLocation"></i>England</p>
                            <p class="BestTimePrice">Starting @30,000</p>
                          </div>
                        </div>
                      </div>
    
                      <div class="col-md-3 col-sm-6">
                        <div class="BestTimeUnder">
                          <img class="BestTimeImg" src="../../../../assets/images/packagesDyamic/TravelD.png" alt="no-data">
                          <div class="BestTimeBottom">
                            <p class="BestTimeLocationTitle"><i class="fas fa-map-marker-alt BestTimeLocation"></i>Thailand</p>
                            <p class="BestTimePrice">Starting @30,000</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="feb" role="tabpanel" aria-labelledby="feb-tab">February Content</div>
                <div class="tab-pane fade" id="mar" role="tabpanel" aria-labelledby="mar-tab">March Content</div>
                <div class="tab-pane fade" id="apr" role="tabpanel" aria-labelledby="apr-tab">April Content</div>
                <div class="tab-pane fade" id="may" role="tabpanel" aria-labelledby="may-tab">May Content</div>
                <div class="tab-pane fade" id="jun" role="tabpanel" aria-labelledby="jun-tab">June Content</div>
                <div class="tab-pane fade" id="jul" role="tabpanel" aria-labelledby="jul-tab">July Content</div>
                <div class="tab-pane fade" id="aug" role="tabpanel" aria-labelledby="aug-tab">August Content</div>
                <div class="tab-pane fade" id="sep" role="tabpanel" aria-labelledby="sep-tab">September Content</div>
                <div class="tab-pane fade" id="oct" role="tabpanel" aria-labelledby="oct-tab">October Content</div>
                <div class="tab-pane fade" id="nov" role="tabpanel" aria-labelledby="nov-tab">November Content</div>
                <div class="tab-pane fade" id="dec" role="tabpanel" aria-labelledby="dec-tab">December Content</div>
              </div>
    
        
            </div>
            <!-- --  Best Time Travel End  -- -->
    
            <!-- --  Offers Start  -- -->
            <div class="Offers">
              <p class="OffersTitle">Offers</p>
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="OffersImg">
                    <img class="OffersImages" src="../../../../assets/images/packagesDyamic/OfferA.png" alt="no-img">
                    <div class="OffersInsideData">
                      <p class="OffersInsideTitle">Upto 20% OFF</p>
                      <p class="OffersInsideContent">Lorem ipsum is a type of placeholder or dummy text used in typesetting and graphic design for previewing layouts. It features scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder or dummy text used in typesetting and graphic design</p>
                    </div>
                  </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                  <div class="OffersImg">
                    <img class="OffersImages" src="../../../../assets/images/packagesDyamic/OfferB.png" alt="no-img">
                    <div class="OffersInsideData">
                      <p class="OffersInsideTitle">Upto 20% OFF</p>
                      <p class="OffersInsideContent">Lorem ipsum is a type of placeholder or dummy text used in typesetting and graphic design for previewing layouts. It features scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder or dummy text used in typesetting and graphic design</p>
                    </div>
                  </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                  <div class="OffersImg OffersImgA">
                    <img class="OffersImages" src="../../../../assets/images/packagesDyamic/OfferC.png" alt="no-img">
                    <div class="OffersInsideData">
                      <p class="OffersInsideTitle">Upto 20% OFF</p>
                      <p class="OffersInsideContent">Lorem ipsum is a type of placeholder or dummy text used in typesetting and graphic design for previewing layouts. It features scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder or dummy text used in typesetting and graphic design</p>
                    </div>
                  </div>
                </div>
    
                <div class="col-lg-3 col-md-6">
                  <div class="OffersImg OffersImgA">
                    <img class="OffersImages" src="../../../../assets/images/packagesDyamic/OfferD.png" alt="no-img">
                    <div class="OffersInsideData">
                      <p class="OffersInsideTitle">Upto 20% OFF</p>
                      <p class="OffersInsideContent">Lorem ipsum is a type of placeholder or dummy text used in typesetting and graphic design for previewing layouts. It features scrambled Latin text that emphasizes the design Lorem ipsum, a type of placeholder or dummy text used in typesetting and graphic design</p>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <!-- --  Offers End  -- -->
          </div>

      </div>
</div>