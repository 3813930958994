<div class="callndedCOmponent">
  <ng-template #dialogContent>
    <div>
      <span><i class="fas fa-times-circle closeIcon"></i></span> <br>
      <span class="failedTct">Your ticket booking is unsuccessful due to incomplete transaction</span> <br>
  
  
      <button (click)="gotoHome()" type="submit" class="search-hotels-btn">OK</button>
    </div>
  </ng-template>
</div>