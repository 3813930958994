<form #f="ngForm" [formGroup]="phoneForm">
    <div class="wrapper">
      <ngx-intl-tel-input
        [cssClass]=dataCass
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="false"
        [searchCountryFlag]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.UnitedArabEmirates"
        [maxLength]="15"
        [phoneValidation]="true"
        [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National"
        name="phone"
        formControlName="phone"
        (change)="myInfo()"
      >
      </ngx-intl-tel-input>
    </div>
 
  </form>