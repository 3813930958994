import { Component } from '@angular/core';

@Component({
  selector: 'app-global-packages',
  templateUrl: './global-packages.component.html',
  styleUrls: ['./global-packages.component.css']
})
export class GlobalPackagesComponent {

}
