<div class="loginmain-se register-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <div class="loginsection">
                    <img class="login-im" src="./../../../assets/images/Logo.png" alt="">
                    <h6 class="welcome">Registration</h6>
                    <img class="pleaseim" src="./../../../assets/images/line.svg" alt="no">
                    <div class="loginform-section loginform-section2">
                        <form  [formGroup]="registerform" (ngSubmit)="onSubmit()">
                            <div class="form-group regsectiongroup">
                                <input [(ngModel)]="registerObj.name" formControlName="name" type="email" class="form-control loginemailin" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Name">
                                <span><img class="regim" src="./../../../assets/images/regus.png" alt="no-image"></span>
                                <div class="error-container">
                                    <div class="error-message" *ngIf="registerform.get('name')?.invalid && (registerform.get('name')?.touched ||
                                        registerform.get('name')?.dirty)">
                                      <i class="fas fa-info-circle error-icon"></i>
                                      Name is Required
                                    </div>
                                  </div>
                            </div>
                            <div class="form-group regsectiongroup">
                                <input [(ngModel)]="registerObj.email" formControlName="email" type="email" class="form-control loginemailin" id="exampleInputPassword1"
                                    placeholder="email">
                                <span><img class="regim" src="./../../../assets/images/regemail.png"
                                        alt="no-image"></span>
                                        <div class="error-container">
                                            <div class="error-message" *ngIf="registerform.get('email')?.invalid && (registerform.get('email')?.touched ||
                                                registerform.get('email')?.dirty)">
                                              <i class="fas fa-info-circle error-icon"></i>
                                              Email is Required
                                            </div>
                                          </div>
                            </div>
                            <div class="form-group regsectiongroup">
                                <input [(ngModel)]="registerObj.password" formControlName="password" type="password" class="form-control loginemailin" id="exampleInputPassword1"
                                    placeholder="Password">
                                <span><img class="regim" src="./../../../assets/images/regpassword.png"
                                        alt="no-image"></span>
                                        <div class="error-container">
                                            <div class="error-message" *ngIf="registerform.get('password')?.invalid && (registerform.get('password')?.touched ||
                                                registerform.get('password')?.dirty)">
                                              <i class="fas fa-info-circle error-icon"></i>
                                              Password is Required
                                            </div>
                                          </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check" style="display: flex;">
                                        <input #inputEl (change)="onChange(inputEl.checked)" type="checkbox" class="form-check-input checkin" id="exampleCheck1">
                                        <label class="form-check-label remember" for="exampleCheck1">I agree to Accure
                                            Pay Terms and Conditions</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary loginbt">REGISTER YOUR ACCOUNT</button>
                            <div class="linedi"></div>
                            <p class="youdo">Already have an account?<span><a routerLink="/login"
                                        style="text-decoration: none;">Login</a></span></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
