import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { contactus } from 'src/app/models/model';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as AOS from 'aos';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  namePattern = '^[A-Za-z]+$';
  @ViewChild('myInput') inputElement: ElementRef;
  @ViewChild('numberFocus') numberFocus: ElementRef;
  selectService = null
  callingCode: string = '971';
  location ='./../../../assets/images/m/location.svg'
  allMobileFlags: any = []
  enterMobile: string
  isDetailsbox: any
  searchText: string
  countryIsSelected: boolean = false
  selectdImg: string = ''
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  @ViewChild('dialogContent', { static: true }) dialogContent: TemplateRef<any>;
  contactus: FormGroup;
  InputDataform: FormGroup;
  search: FormGroup;
  blockColor = '#b9975d';
  blockMargin = 0
  emailBg = '#fff'
  locationBg = '#fff'
  calBg = '#fff'
  formContent = 'FLIGHT'
  contactusObj: contactus = new contactus()
  constructor(private service: AuthService, private toastr: ToastrService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.contactusObj.type = 'FLIGHT'



    window.scrollTo(0, 0);
    AOS.init();
    this.contactus = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      name: new FormControl('', [Validators.required],),
      mobileNo: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      countryCode: new FormControl('971'),

    });

    this.search = new FormGroup({

      searching: new FormControl('971'),

    });

    this.InputDataform = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobileNo: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
    });


    // this.contactus.value.countryCode = ''
    this.getCountryFlags()
  }

  changeLocationIcon(){
    alert('')
      this.location =''
  }
  getType(data: any) {


  }

  currentStage: number = 1;
  name: string = '';
  email: string = '';
  mobileNo: string = '';
  subject: string = '';
  // Add other form fields as needed
  dots: number[] = [1, 2, 3, 4];

  next() {
    if (this.validateCurrentStage()) {
      this.currentStage++;
    }
  }

  validateCurrentStage(): boolean {
    if (this.currentStage === 1 && !this.name.trim()) {

      this.service.error('Name is required.')
      return false;
    }

    if (this.currentStage === 2) {
      if (!this.email.trim()) {
        this.service.error('Email is required.');
        return false;
      } else {
        // Regular expression for a simple email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email.trim())) {
          this.service.error('Please enter a valid email address.');
          return false;
        }
      }
    }
    if (this.currentStage === 3) {
      if (!this.mobileNo) {
        this.service.error('Please enter mobile number');
        return false;
      }
    }

    if (this.currentStage === 4) {
      if (!this.subject) {
        this.service.error('Please enter Subject');
        return false;
      }
    }
    // Add validation for other stages as needed
    return true;
  }

  submitForm() {
    console.log(this.InputDataform.value)
      if(!this.selectService){
        this.service.error("type required")
        return
      }
      if(!this.InputDataform.value.mobileNo){
        this.service.error("Mobile number required")
        return
      }
    const formData = {
      name:this.InputDataform.value.name,
      email: this.InputDataform.value.email,
      mobileNo: `${this.callingCode}-${this.InputDataform.value.mobileNo}`,
      subject: this.InputDataform.value.subject,
      type:this.selectService
      // Add other form fields as needed
    };
    this.service.contactus(formData).subscribe(
      (data: any) => {
        this.toastr.success(data.message)
        this.contactus.reset()
        this.openDialog()
        this.resetForm()
        this.InputDataform.reset()
        setTimeout(() => {
          // this.closeDialog()
          // this.router.navigateByUrl('/home')
        }, 10000)
      },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )

  }


  resetForm() {
    this.name = '';
    this.email = '';
    // Reset other form fields as needed
    this.currentStage = 1;
  }
  contactusForm() {


    this.contactusObj.mobileNo = this.callingCode + '-' + this.enterMobile
    this.contactusObj.countryCode = this.callingCode
    this.service.contactus(this.contactusObj).subscribe(
      (data: any) => {
        this.toastr.success(data.message)
        this.contactus.reset()
        this.openDialog()
        setTimeout(() => {
          this.closeDialog()
          this.router.navigateByUrl('/home')
        }, 10000)
      },
      (err) => {
        this.toastr.error(err.error.message)
      }
    )

  }
  chatOpen() {

    const elements = document.getElementsByClassName('header header--offline header--flat js-header');

  }

  onWeb() {
    this.blockColor = '#b9975d'
    this.calBg = '#fff'
    this.emailBg = '#fff'
    this.locationBg = '#fff'
    this.formContent = 'Flight'

  }
  CallUs() {
    this.calBg = '#b9975d'
    this.blockColor = '#fff'
    this.emailBg = '#fff'
    this.locationBg = '#fff'
    this.formContent = 'HOTEL'
  }
  emailUs() {
    this.emailBg = '#b9975d'
    this.blockColor = '#fff'
    this.calBg = '#fff'
    this.locationBg = '#fff'
    this.formContent = 'CAR RENTAL'
  }
  locationBgUs() {
    this.locationBg = '#b9975d'
    this.emailBg = '#fff'
    this.blockColor = '#fff'
    this.calBg = '#fff'
    this.formContent = 'GENERAL'
  }

  openDialog(): void {
    this.dialog.open(this.dialogContent, {
      panelClass: 'bg-color',
      width: '500px',
      height: '250px'
    });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  getCountryFlags() {
    this.service.getCountryFlags().subscribe(
      (data: any) => {

        // this.allMobileFlags =[]
        let allData = []
        for (const obj of data) {
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )
  }

  getCountryID(data) {

    this.callingCode = data.callingCode
    this.selectdImg = data.flag
    this.countryIsSelected = true
  }
  icons: any = './../../../assets/images/m/flightIc.png'
  selectItem(data, icon) {
    this.selectService = data
    this.contactusObj.type = data
    this.contactusObj.countryCode = this.callingCode
    this.icons = icon
    this.formContent = data
  }

  focusInput() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 100)
  }


  sendEmail() {
    const email = 'support@devotiontourism.com';
    const subject = 'Support Request';
    const body = 'Please help me with...';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }

  makePhoneCall() {
    const phoneNumber = '+97144576077';
    window.location.href = `tel:${phoneNumber}`;
  }
  makeYOutube() {
    const youtubeUrl = 'https://www.youtube.com/@Devotiontravelandtourism';
    // Use the navigateByUrl method to navigate to the specified URL
    window.open(youtubeUrl, '_blank');

  }

directLocation(){
  let location  = "https://www.google.com/maps/place/Iris+Bay+-+Business+Bay+-+Dubai+-+United+Arab+Emirates/@25.1858723,55.2582335,17z/data=!4m6!3m5!1s0x3e5f69d09681df43:0xc222838ff425a54!8m2!3d25.1859019!4d55.2602652!16s%2Fm%2F03d0mmb?entry=ttu"
  window.open(location, '_blank');
}

  makeLinked() {
    const youtubeUrl = 'https://www.linkedin.com/company/92558268/admin/';
    // Use the navigateByUrl method to navigate to the specified URL
    window.open(youtubeUrl, '_blank');

  }
  dataForm() {

  }
  autoFucos() {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 100)
  }
  autoFucos2() {
    setTimeout(() => {
      this.numberFocus.nativeElement.focus();
    }, 100)
  }
  searchCOde(event:any) {
    let data = {
      name: event.target.value
    }
    this.service.Inssearch(data).subscribe(
      (data: any) => {
        let allData = []

        for (const obj of data) {

          obj._id = null
          if (obj.flag.startsWith("http")) {
            obj.type = "img";
          } else {
            obj.type = "base";
            allData.push(obj)
          }


        }

        this.allMobileFlags = allData


      }
    )

  }

  checkType(data){
    console.log(data)
    this.contactusObj.type = data
    this.selectService =data
  }
  openWhatsApp() {
    // Replace '1234567890' with the desired phone number
    // and 'Hello' with the desired message
    const phoneNumber = '97144576077';
    const message = encodeURIComponent('Hello Devotion');
    
    // Create the WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open the WhatsApp URL
    window.open(whatsappUrl, '_blank');
  }

  openLinkedInPage() {
    window.open('https://www.linkedin.com/company/92558268/admin/', '_blank');
  }
  openFacebookPage() {
    window.open('https://www.facebook.com/profile.php?id=100091695764298', '_blank');
  }
  openYoutubePage() {
    window.open('https://www.youtube.com/@Devotiontravelandtourism', '_blank');
  }
  openPintrestPage() {
    window.open('https://pin.it/1O6HPbY', '_blank');
  }
  openInsta() {
    window.open('https://www.instagram.com/devotiontourism/', '_blank');
  }


}
